import { FC } from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import InputLabel from '../../InputLabel';
import RadioOption from '../../RadioOption';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import { ActivityFileCompletionType } from '../../../../generated/graphql';

export const PDF_EDITABILITY_LINK =
  'https://www.adobe.com/acrobat/resources/how-to-make-a-pdf-editable.html';

const FileCompletionSettings: FC<{
  watchCompletionType: ActivityFileCompletionType | undefined;
  isFilePdfEditable: boolean | null;
  isDocumentFile: boolean;
  isActuallyPdfFile: boolean;
}> = ({
  watchCompletionType,
  isFilePdfEditable,
  isDocumentFile,
  isActuallyPdfFile,
}) => {
  const { control } = useFormContext();

  return (
    <div className="mt-4">
      <InputLabel label="File settings" useNaturalLettering={true} />

      <Controller
        control={control}
        name="completionType"
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <RadioOption
              id="viewCompletionTypeOption"
              value={ActivityFileCompletionType.View}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              checked={field.value === ActivityFileCompletionType.View}
              labelContent={
                <>
                  <span className="font-bold">View: </span>I only want clients
                  to view this file
                </>
              }
            />

            <RadioOption
              id="uploadCompletionTypeOption"
              value={ActivityFileCompletionType.Upload}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              checked={field.value === ActivityFileCompletionType.Upload}
              disabled={!isDocumentFile}
              labelContent={
                <>
                  <span className="font-bold">Fill out & Return: </span>I want
                  clients to download, fill out, and send back.
                </>
              }
            />

            <RadioOption
              id="esignCompletionTypeOption"
              value={ActivityFileCompletionType.Esign}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              checked={field.value === ActivityFileCompletionType.Esign}
              disabled={!isActuallyPdfFile}
              labelContent={
                <>
                  <span className="font-bold">E-sign: </span>
                  All I need is a signature. (PDF only)
                </>
              }
            />
          </>
        )}
      />

      {/* Conditional content */}

      {[
        ActivityFileCompletionType.Upload,
        ActivityFileCompletionType.Esign,
      ].includes(watchCompletionType) &&
        isFilePdfEditable != null && (
          <>
            <div
              className={classNames(
                'mt-4 flex h-10 items-center',
                isFilePdfEditable ? 'text-green-100' : 'text-red-100',
              )}
            >
              {watchCompletionType === ActivityFileCompletionType.Upload &&
                (isFilePdfEditable ? (
                  <>
                    <CheckCircleIcon className="mr-2 h-5 w-5" />
                    <div>
                      It looks like this PDF{' '}
                      <span className="font-bold">is editable</span>. Great!
                    </div>
                  </>
                ) : (
                  <>
                    <ExclamationCircleIcon className="mr-2 h-5 w-5" />
                    <div>
                      The PDF you uploaded is{' '}
                      <span className="font-bold">read only</span> and can't be
                      filled out.
                    </div>
                  </>
                ))}
            </div>

            <div className="mt-4 h-10">
              <div className="flex flex-row items-start justify-start text-neutral-125">
                <SparklesIcon className="mr-2 h-5 w-5 flex-shrink-0" />
                <div className="text-caption">
                  {watchCompletionType === ActivityFileCompletionType.Upload &&
                  !isFilePdfEditable ? (
                    <>
                      Consider making your PDF{' '}
                      <span
                        onClick={() =>
                          window.open(PDF_EDITABILITY_LINK, '_blank')
                        }
                        className="cursor-pointer underline"
                      >
                        editable
                      </span>
                      , sending a document file, or using our{' '}
                      <span className="font-bold">Forms</span> feature to
                      capture client information, sign consents, and more.
                    </>
                  ) : (
                    <>
                      Homecoming's <span className="font-bold">Forms</span> are
                      the easiest way to capture client information, request
                      signed consents &#8212; and more.
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default FileCompletionSettings;
