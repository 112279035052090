import { useState, useEffect } from 'react';
import { PatientsQuery } from '../../../generated/graphql';

export default function useAlertState(
  invalidPatients: PatientsQuery['patients'],
) {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (invalidPatients.length > 0) {
      setShowAlert(true);
    }
    if (invalidPatients.length === 0 && showAlert) {
      setShowAlert(false);
    }
  }, [invalidPatients, showAlert]);

  return {
    showAlert,
    setShowAlert,
  };
}
