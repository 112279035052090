import { FC, useState } from 'react';
import classNames from 'classnames';

import { columnHeaders, sortOptions } from '../helpers';
import TableHeader from '../../../components/TableHeader';
import { ProgramInvoiceDataFragment } from '../../../../generated/graphql';
import InvoiceRow, { InvoiceRowMutationProps } from './InvoiceRow';
import {
  CommonAnalyticsEvent,
  AnalyticsPage,
  trackProviderEvent,
} from '../../../../lib/analytics';
import { ColumnHeaderType, ColumnOrder } from '../../../types/tables';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import EmptyTable from '../../../components/EmptyTable';
import IllustrationBanknotes from '../../../svgs/IllustrationBanknotes';

const InvoiceTable: FC<
  {
    programInvoices: ProgramInvoiceDataFragment[];
    programInvoicesLoading: boolean;
    hasAnyProgramInvoices: boolean; // programInvoices is a filtered set, this indicates if there are more
    invoiceOrder: ColumnOrder;
    setInvoiceOrder: React.Dispatch<React.SetStateAction<ColumnOrder>>;
    clearFilters?: () => void;
  } & InvoiceRowMutationProps
> = ({
  programInvoices,
  programInvoicesLoading,
  hasAnyProgramInvoices,
  invoiceOrder,
  setInvoiceOrder,
  clearFilters,
  ...invoiceRowProps
}) => {
  const navigate = useNavigate();

  const showProgramInvoices = !programInvoicesLoading && programInvoices;

  const hasProgramInvoices = programInvoices.length > 0;

  const [previousColumnClicked, setPreviousColumnClicked] =
    useState<ColumnHeaderType | null>();

  const handleHeaderClick = (column: ColumnHeaderType) => {
    const nextOrderIndex =
      (column.columnOrders!.findIndex((order) => order === invoiceOrder) + 1) %
      column.columnOrders!.length;
    const nextInvoiceOrder = column.columnOrders![nextOrderIndex];
    setPreviousColumnClicked(column);
    setInvoiceOrder(nextInvoiceOrder);
  };

  return (
    <div
      className={classNames(
        'duration-0 flex h-full max-h-full w-full min-w-full flex-col items-stretch justify-start overflow-y-scroll transition duration-150',
        !showProgramInvoices && 'opacity-0',
        showProgramInvoices && 'opacity-100',
      )}
    >
      <table className="divide min-w-full table-fixed">
        <thead className="sticky top-0 z-20 h-[40px] w-full border-b border-neutral-50 bg-white">
          <tr>
            {columnHeaders.map((column, i) => (
              <TableHeader
                key={`invoiceTableColumnHeader_${column.field}`}
                column={column}
                columnOrder={invoiceOrder}
                sortOptions={sortOptions}
                previousColumnClicked={previousColumnClicked}
                handleHeaderClick={handleHeaderClick}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {hasProgramInvoices &&
            programInvoices.map((programInvoice, index) => (
              <InvoiceRow
                key={`invoiceTableRow_${index}`}
                programInvoice={programInvoice}
                onClickDownloadInvoice={() => {
                  window.open(programInvoice.stripeInvoicePdfUrl);
                  trackProviderEvent(CommonAnalyticsEvent.TableRowClicked, {
                    originPage: AnalyticsPage.Payments,
                    action: 'Download invoice',
                  });
                }}
                {...invoiceRowProps}
              />
            ))}
        </tbody>
      </table>
      {!hasProgramInvoices &&
        (hasAnyProgramInvoices ? (
          <EmptyTable
            titleText="No invoices found"
            subTitleText="Try adjusting your filters or creating a new invoice."
            IconComponent={IllustrationBanknotes}
            iconClassName="text-green-100"
            onClickClearFilters={clearFilters}
          />
        ) : (
          <div className="mt-10 flex w-full flex-col items-center justify-center">
            <div className="mb-2 text-category font-medium text-green-150">
              No invoices yet
            </div>
            <p className="text-neutral-125">
              Get paid by creating and sending an invoice to a client.
            </p>
            <div className="mt-5">
              <Button
                title="Create invoice"
                onClick={() => navigate('/payments/new')}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default InvoiceTable;
