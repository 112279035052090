import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { ContentItem } from './ProfileInfoSection';

const PanelButton: FC<{
  contentItem: ContentItem;
}> = ({ contentItem }) => {
  const navigate = useNavigate();
  return (
    <Button
      noBackground
      onClick={() => contentItem.link && navigate(contentItem.link)}
      title={contentItem?.content}
      theme="secondary"
      noOutline
      iconPosition="right"
      size="small"
      className={classNames(
        'text-caption text-green-150',
        contentItem?.link && 'font-bold underline',
      )}
    />
  );
};

export default PanelButton;
