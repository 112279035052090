import classNames from 'classnames';

import { ProgramTemplateStatus } from '../../generated/graphql';

const ProgramStatus: React.FC<{
  status: ProgramTemplateStatus;
  hideIfActive?: boolean;
  hideStatus?: boolean;
}> = ({ status, hideIfActive = false, hideStatus = false }) => {
  const isActive = status === ProgramTemplateStatus.Active;

  if (hideStatus || (isActive && hideIfActive)) return null;

  return (
    <div
      className={classNames(
        'w-fit rounded-3xl border bg-white px-3 py-1 text-caption',
        isActive && 'border-green-125 text-green-125',
        status === ProgramTemplateStatus.InDevelopment &&
          'border-orange-125 text-orange-125',
      )}
    >
      <span className="block truncate font-normal">
        {status === ProgramTemplateStatus.InDevelopment
          ? 'Not active'
          : 'Active'}
      </span>
    </div>
  );
};

export default ProgramStatus;
