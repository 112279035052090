import { MeProviderQuery } from '../../generated/graphql';

export enum OnboardingStepName {
  Members = 'members',
  Programs = 'programs',
  Billing = 'billing',
  Confirmation = 'confirmation',
}

/** An undefined getIsComplete function signifies a step that will always show, regardless of meProvider state. */
export type OnboardingStep = {
  name: OnboardingStepName;
  getIsComplete?: (
    meProvider?: MeProviderQuery['meProvider'] | null,
  ) => boolean;
};

export type OnboardingPageProps = {
  stepIndex: number;
  steps: OnboardingStep[];
  goToNextStep: (stepIndex: number) => Promise<void>;
};
