import { SvgIconComponent } from '../types/svgs';

const CircleGreenCheck: SvgIconComponent = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <rect width="25" height="25" rx="12.5" fill="#AFE5B1" />
      <path
        d="M6.83097 13.346L6.63023 13.5302L6.83097 13.7144L10.1482 16.7583C10.6529 17.2214 11.4362 17.1921 11.9048 16.6927L18.9069 9.23158L19.0799 9.04723L18.8936 8.8763L17.7379 7.8158L17.5535 7.64663L17.3845 7.83114L11.5384 14.2133C11.2586 14.5188 10.784 14.5396 10.4786 14.2596L8.32465 12.2854L8.15563 12.1305L7.9867 12.2855L6.83097 13.346Z"
        fill="#004704"
        stroke="#004704"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CircleGreenCheck;
