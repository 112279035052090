import { Navigate, useLocation } from 'react-router-dom';
import { usePatientAuth } from '../contexts/PatientAuthContext';

/**
 * Catch-all Handler for client app within /client prefix.
 * We want to ensure that any uncaught routes within this prefix are redirected to the
 * client login and not the Provider login, which causes confusion for users.
 *
 * If unauthed, sends to the Unauthed app at /login/client
 *
 * If authed, sends to the root of the client app to be redirected by the app.
 * */
export default function PatientCatchAll() {
  const { patientTokenPayload } = usePatientAuth();
  const location = useLocation();

  if (!patientTokenPayload) {
    // Redirect them to the /login/client page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login/client" state={{ from: location }} replace />;
  }

  return <Navigate to="/client" replace />;
}
