import classNames from 'classnames';
import { FC } from 'react';
import { roundTo2Decimals } from '../../../../../lib/math';

export type ScoreProps = {
  score: number;
  scoreDifference?: number;
  categorization?: string | null;
  hasPercentage?: boolean;
  increasingScoreTowardsHealth?: boolean;
};

const Score: FC<ScoreProps> = ({
  score,
  scoreDifference = 0,
  categorization = '',
  hasPercentage = false,
  increasingScoreTowardsHealth = false,
}) => {
  const isTrendingHealthy =
    (increasingScoreTowardsHealth && scoreDifference > 0) ||
    (!increasingScoreTowardsHealth && scoreDifference < 0);

  const isTrendingUnhealthy =
    (increasingScoreTowardsHealth && scoreDifference < 0) ||
    (!increasingScoreTowardsHealth && scoreDifference > 0);

  return (
    <div className="mr-6 flex flex-col items-start justify-start">
      <div className="mb-2 flex flex-row items-center justify-start">
        <span className={'mr-2 text-big-label text-green-150'}>
          {roundTo2Decimals(score)}
          {hasPercentage && '%'}
        </span>
        {Boolean(scoreDifference) && (
          <div
            className={classNames(
              'flex flex-row items-center justify-between rounded-full px-3 py-1',
              isTrendingHealthy && 'bg-green-25 text-green-100',
              isTrendingUnhealthy && 'bg-red-25 text-red-100',
            )}
          >
            <span className="text-small-caption font-bold">
              {scoreDifference > 0 && '+'}
              {roundTo2Decimals(scoreDifference)}
              {hasPercentage && '%'}
            </span>
          </div>
        )}
      </div>
      {categorization && (
        <span className="text-left text-small-caption">{categorization}</span>
      )}
    </div>
  );
};

export default Score;
