import { FC } from 'react';

import { ProviderAssessmentsWithoutScheduleQuery } from '../../../../generated/graphql';
import {
  isFreeTextAssessmentQuestion,
  isMultiSelectAssessmentQuestion,
  isMultipleChoiceAssessmentQuestion,
  isMultipleChoiceScoredAssessmentQuestion,
  isRichTextAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isMultiSignatureAssessmentQuestion,
  isStatementAssessmentQuestion,
} from '../../../lib/assessments';
import SlideoverSection from '../../SlideoverSection';
import QuestionItem from '../QuestionItem';

const QuestionsSection: FC<{
  questions?:
    | ProviderAssessmentsWithoutScheduleQuery['assessments'][number]['questions']
    | ProviderAssessmentsWithoutScheduleQuery['forms'][number]['questions'];
  questionFrame?: string | null;
  title?: string;
}> = ({ questions, questionFrame, title = 'Questions' }) => {
  return (
    <SlideoverSection title={title}>
      {questionFrame && (
        <div className="mb-4 font-sans text-caption text-neutral-125">
          {questionFrame}
        </div>
      )}
      {questions?.map((question, index) => {
        if (isMultipleChoiceScoredAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={question.subscale}
            />
          );
        }
        if (
          isMultipleChoiceAssessmentQuestion(question) ||
          isMultiSelectAssessmentQuestion(question)
        ) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={
                isMultiSelectAssessmentQuestion(question)
                  ? 'Multiple select'
                  : 'Multiple choice'
              }
              answerOptions={question.answerOptions.map(
                (answerOption) => answerOption.answerOption,
              )}
            />
          );
        }
        if (isFreeTextAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale="Text"
            />
          );
        }
        if (isRichTextAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale="Text"
            />
          );
        }
        if (isSignatureAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={'Signature'}
            />
          );
        }
        if (isMultiSignatureAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={'Multiple signature'}
              assessmentSignatureUsers={question.assessmentSignatureUsers}
            />
          );
        }
        if (isStatementAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={'Statement'}
            />
          );
        }
        return null;
      })}
    </SlideoverSection>
  );
};

export default QuestionsSection;
