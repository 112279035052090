import { SvgIconComponent } from '../types/svgs';

// TODO: the sizing seems to be off on this icon (it's too small within the 24x24 box)
const Checkmark: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
    >
      <path
        d="M5.83097 12.846L5.63023 13.0302L5.83097 13.2144L9.14823 16.2583C9.65287 16.7214 10.4362 16.6921 10.9048 16.1927L17.9069 8.73158L18.0799 8.54723L17.8936 8.3763L16.7379 7.3158L16.5535 7.14663L16.3845 7.33114L10.5384 13.7133C10.2586 14.0188 9.78401 14.0396 9.47859 13.7596L7.32465 11.7854L7.15563 11.6305L6.9867 11.7855L5.83097 12.846Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default Checkmark;
