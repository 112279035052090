import { FC } from 'react';
import classNames from 'classnames';

import Button from '../Button';
import Modal from '../Modal';

type UnsavedChangesModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  aboveEverything?: boolean;
};

const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  isModalOpen,
  setClosed,
  onConfirm,
  aboveEverything = false,
}) => {
  return (
    <Modal
      name="UnsavedChanges"
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="small"
      aboveEverything
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-between px-10 pb-4',
        )}
      >
        <div className="flex flex-col items-center justify-start">
          <div className="mb-2 flex flex-col items-center justify-start text-center font-serif text-subtitle text-green-150">
            <span>You have unsaved changes</span>
          </div>
          <div className="text-center text-body">
            <div className="text-green-125">
              Changes you've made will be lost.
            </div>
          </div>
        </div>
        <div className="mt-10 mb-4 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-6"
            onClick={setClosed}
          />
          <Button
            title="Continue without saving"
            theme="primary"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UnsavedChangesModal;
