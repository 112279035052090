import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from '../../Checkbox';
import InputLabel from '../../InputLabel';

const ActivityUseSettings: FC = () => {
  const { control } = useFormContext();

  return (
    <div className="mt-4">
      <InputLabel label="Visibility settings" useNaturalLettering={true} />
      <Controller
        control={control}
        name="isOneTimeUse"
        render={({ field }) => {
          return (
            <>
              <Checkbox
                id="visibilitySettings"
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                checked={field.value}
                labelContent="For one-time use with this client only. Do not save in my Library."
                className="mt-2"
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default ActivityUseSettings;
