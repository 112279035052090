import { useEffect } from 'react';

const ROOT_ID = 'root';

/*
    This hook is used to remove "inert" from the root element that the HeadlessUI
    Dialog component adds when a modal is open. This allows for certain cases where you want
    part of the page to still be interactable even when the Dialog is open, which is the
    case for a blocking dialog in one page of the app below the nav bar, when we still want the
    nav bar accessible.
*/
export const useRemoveInert = ({
  isModalOpen,
  enabled,
}: {
  isModalOpen: boolean;
  enabled: boolean;
}) => {
  useEffect(() => {
    if (isModalOpen && enabled) {
      // timeout needed to make sure inert is removed after dialog is opened
      setTimeout(() => {
        // disable inert to make dialog interactive -> https://github.com/tailwindlabs/headlessui/discussions/666#discussioncomment-6183443
        const rootNode = document.getElementById(ROOT_ID);
        if (rootNode) rootNode.removeAttribute('inert');
      }, 100);
    }
  }, [isModalOpen, enabled]);
};
