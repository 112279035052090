import { ApolloError } from '@apollo/client';
import React, { useCallback, useState } from 'react';

import {
  MePatientDataFragment,
  PatientLoginResponse,
  useMePatientLazyQuery,
} from '../generated/graphql';

import {
  clearPatientTokenPayload,
  getPatientTokenPayload,
  storePatientTokenPayload,
} from '../lib/auth';

interface PatientAuthContextType {
  patientTokenPayload: PatientLoginResponse | null;
  updateTokenPayload: (tokenPayload: PatientLoginResponse | null) => void;
  authedPatient: MePatientDataFragment | null;
  isAuthedPatientLoading: boolean;
  authedPatientError?: ApolloError;
  refreshAuthedPatient: () => Promise<MePatientDataFragment>;
}

const PatientAuthContext = React.createContext<PatientAuthContextType>(null!);

export const PatientAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [patientTokenPayload, setPatientTokenPayload] =
    useState<PatientLoginResponse | null>(getPatientTokenPayload());

  const [authedPatient, setAuthedPatient] =
    useState<MePatientDataFragment | null>(null);

  const [
    mePatientQuery,
    { loading: isAuthedPatientLoading, error: authedPatientError },
  ] = useMePatientLazyQuery();

  const updateTokenPayload = (
    tokenPayload: PatientLoginResponse | null,
  ): void => {
    if (tokenPayload) {
      storePatientTokenPayload(tokenPayload);
      setPatientTokenPayload(tokenPayload);
    } else {
      clearPatientTokenPayload();
      setPatientTokenPayload(null);
    }
  };

  const refreshAuthedPatient = useCallback(async () => {
    const response = await mePatientQuery();
    const authedPatient = response.data?.mePatient;

    setAuthedPatient(authedPatient ?? null);

    return authedPatient;
  }, [mePatientQuery]);

  //   const setAllAuthState = (
  //     tokenPayload: TokenPayload,
  //     meProvider: MeProviderQuery['meProvider'],
  //   ) => {
  //     updateTokenPayload(tokenPayload);
  //     setAuthedProviderUser(meProvider);
  //     identifyProviderUser(tokenPayload);
  //     SentryHelpers.setUserScope();
  //   };

  //   const clearAllAuthState = () => {
  //     updateTokenPayload(null);
  //     setAuthedProviderUser(null);
  //     SentryHelpers.clearUserScope();
  //     resetProviderMixpanel();
  //     shutdownIntercom();
  //   };

  //   const logout = async () => {
  //     try {
  //       await providerUserLogout();
  //     } catch (err) {
  //       throw err;
  //     } finally {
  //       // Perform logout regardless if the server can't be reached.
  //       clearAllAuthState();
  //     }
  //   };

  return (
    <PatientAuthContext.Provider
      value={{
        patientTokenPayload,
        updateTokenPayload,
        authedPatient,
        isAuthedPatientLoading,
        authedPatientError,
        refreshAuthedPatient,
      }}
    >
      {children}
    </PatientAuthContext.Provider>
  );
};

export const usePatientAuth = () => {
  return React.useContext(PatientAuthContext);
};
