import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/outline';

import MenuButton from '../MenuButton';

const PatientScheduledEventMenu: React.FC<{
  onClickCancelEvent: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ onClickCancelEvent }) => {
  return (
    <div className="z-[10] flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <div className="cursor-pointer text-caption font-bold text-neutral-125/70 hover:text-neutral-150/80">
            Modify
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none">
            <MenuButton
              titleText="Cancel session"
              className="rounded-md text-neutral-150 hover:text-neutral-150"
              iconClassName="text-neutral-150"
              IconComponent={XCircleIcon}
              onClick={onClickCancelEvent}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default PatientScheduledEventMenu;
