import { FC } from 'react';
import { PatientNameDataFragment } from '../../../../../../generated/graphql';
import Avatar from '../../../../Avatar';
import Button from '../../../../Button';

interface PatientRowProps {
  patient: PatientNameDataFragment;
  handleRemovePatientFromProviderUser: (programId: any) => Promise<void>;
}

const PatientRow: FC<PatientRowProps> = ({
  patient,
  handleRemovePatientFromProviderUser,
}) => {
  return (
    <div className="flex h-[48px] w-full flex-row items-center justify-between text-caption text-green-150">
      <div className="flex flex-row items-center gap-x-4">
        <Avatar
          imageUrl={patient.profileImageMedia?.url}
          name={patient.firstName}
          size="medium"
        />
        <div className="flex flex-col items-start justify-start font-serif text-extra-small text-green-150">
          <div>
            {patient.firstName} {patient.lastName}
          </div>
        </div>
      </div>

      <Button
        size="smaller"
        title="Remove"
        noFocus
        theme="primary"
        onClick={async () =>
          await handleRemovePatientFromProviderUser(patient.programInstanceId)
        }
      />
    </div>
  );
};

export default PatientRow;
