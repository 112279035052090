import classNames from 'classnames';
import React from 'react';

const RadioOption: React.FC<
  {
    id: string;
    value: string;
    className?: string;
    labelContent?: React.ReactNode;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ id, value, className, labelContent, disabled, ...rest }) => {
  return (
    <div className={classNames('mt-2 flex', className)}>
      <input
        id={id}
        type="radio"
        value={value}
        className={classNames(
          'mr-3 h-5 w-5 rounded-full border-neutral-100 focus:ring-green-125',
          disabled
            ? 'cursor-not-allowed text-neutral-100'
            : 'cursor-pointer text-green-125',
        )}
        disabled={disabled}
        {...rest}
      />
      {labelContent && (
        <label
          className={classNames(
            'text-caption',
            disabled
              ? 'cursor-not-allowed text-neutral-100'
              : 'cursor-pointer text-neutral-125',
          )}
          htmlFor={id}
        >
          {labelContent}
        </label>
      )}
    </div>
  );
};

export default RadioOption;
