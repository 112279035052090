import { FC } from 'react';
import Avatar from '../../../components/Avatar';
import { PatientTableDataFragment } from '../../../../generated/graphql';
import Button from '../../../components/Button';

const SelectPatientRow: FC<{
  patient: PatientTableDataFragment;
  onKeep?: (patient: PatientTableDataFragment) => void;
  onRemove?: (patient: PatientTableDataFragment) => void;
  disabled?: boolean;
}> = ({ patient, onKeep, onRemove, disabled }) => {
  if (onKeep && onRemove) {
    throw new Error('onKeep and onRemove cannot both be provided');
  }

  return (
    <li className="flex w-full flex-row justify-between gap-x-2 py-2.5">
      <div className="flex flex-row items-center justify-start overflow-x-auto">
        <Avatar
          size="medium-plus"
          name={patient.name}
          imageUrl={patient?.profileImageMedia?.url}
        />
        <div className="ml-4 font-serif text-body text-green-150 line-clamp-2">
          {patient.name}
        </div>
      </div>
      <div>
        {onKeep && (
          <Button
            theme="primary"
            title="Keep active"
            onClick={() => onKeep(patient)}
            disabled={disabled}
          />
        )}
        {onRemove && (
          <Button
            theme="secondary-white"
            title="Remove"
            onClick={() => onRemove(patient)}
            disabled={disabled}
          />
        )}
      </div>
    </li>
  );
};

export default SelectPatientRow;
