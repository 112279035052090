import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { FC, useState, Fragment } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Patient,
  usePatientsV2NamesQuery,
  PatientLifecycleStatusV2,
} from '../../../generated/graphql';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { sortPatientsByAlphabeticalAscending } from '../../components/PatientItem';
import { useNavigate } from 'react-router-dom';

const PatientSelector: FC<{
  onChange: (patient: Patient) => void;
  value: Patient | null;
}> = ({ onChange, value }) => {
  const { authedProviderUser } = useAuth();
  const { data: patientsData } = usePatientsV2NamesQuery({
    variables: {
      patientLifecycleStatuses: [
        PatientLifecycleStatusV2.Invited,
        PatientLifecycleStatusV2.Active,
      ],
      assignedPatients: true,
    },
  });

  const patientsList = sortPatientsByAlphabeticalAscending(
    patientsData?.patientNames?.filter((patient) =>
      authedProviderUser?.hasPremiumAccess ? true : patient.isTestProgram,
    ),
  );

  const [query, setQuery] = useState('');

  const filteredPatients = patientsList.filter((patientOrTag) => {
    if (query) {
      return (
        patientOrTag?.firstName?.toLowerCase().includes(query.toLowerCase()) ||
        patientOrTag?.lastName?.toLowerCase().includes(query.toLowerCase())
      );
    }
    return true;
  });

  return (
    <Combobox
      as="div"
      className="relative mb-8 w-full text-body"
      onChange={onChange}
      value={value}
    >
      <Combobox.Input
        className={classNames(
          'relative flex w-full cursor-default flex-row items-center justify-start text-body placeholder:text-body',
          'max-h-14 rounded-md bg-neutral-50 p-4 text-left text-green-150 shadow-sm sm:text-caption',
          'border-0 placeholder:text-neutral-125/75 focus:border-green-50 focus:outline-none focus:ring-2 focus:ring-green-50',
        )}
        placeholder="Search clients"
        displayValue={(patient: Patient) =>
          patient ? `${patient?.firstName} ${patient?.lastName}` : undefined
        }
        onChange={(event) => setQuery(event.target.value)}
      />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronDownIcon
          className="h-5 w-5 text-neutral-125"
          aria-hidden="true"
        />
      </Combobox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full divide-y divide-neutral-75 overflow-auto rounded-lg border border-neutral-75  bg-white py-1 text-caption shadow-lg focus:outline-none focus:ring-0">
          {filteredPatients.map((patient) => {
            return (
              <Combobox.Option key={patient.programInstanceId} value={patient}>
                {({ active, selected }) => {
                  const patientName = `${patient?.firstName} ${patient?.lastName}`;
                  return (
                    <div
                      className={classNames(
                        'relative flex cursor-pointer select-none flex-row truncate py-3 px-5 text-green-150 hover:bg-neutral-25',
                        {
                          'bg-neutral-25': selected || active,
                        },
                      )}
                    >
                      <Avatar
                        size="small"
                        name={patientName}
                        imageUrl={patient?.profileImageMedia?.url}
                        className="mr-2"
                      />
                      {patientName}
                    </div>
                  );
                }}
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
};

const SelectPatientModal: FC<{ isOpen: boolean; setClosed: () => void }> = ({
  isOpen,
  setClosed,
}) => {
  const [selectedPatient, setSelectedPatient] = useState<null | Patient>(null);
  const navigate = useNavigate();

  return (
    <Modal overflow="auto" width="small" isOpen={isOpen} setClosed={setClosed}>
      <div className="mb-3 text-big-body">
        Select the client that you want to chat with:
      </div>
      <PatientSelector onChange={setSelectedPatient} value={selectedPatient} />

      <div className="flex flex-row justify-end">
        <Button
          title={
            selectedPatient ? `Chat with ${selectedPatient?.firstName}` : 'Chat'
          }
          disabled={!Boolean(selectedPatient)}
          onClick={() => {
            navigate(`/clients/${selectedPatient.programInstanceId}/messages`);
            setSelectedPatient(null);
            setClosed();
          }}
        />
      </div>
    </Modal>
  );
};

export default SelectPatientModal;
