import { FC, useEffect } from 'react';
import PageContainer from '../components/Containers/PageContainer';
import { HOMECOMING_PARTNER_HUB_BASE_URL } from '../lib/circle-community';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../contexts/AuthContext';
import classNames from 'classnames';
import Button from '../components/Button';

const Community: FC = () => {
  const { authedProviderUser, saveToken } = useAuth();
  const { loginWithPopup, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const launchReAuth = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          prompt: 'login',
          login_hint: authedProviderUser.email,
        },
      });
      const accessToken = await getAccessTokenSilently();
      saveToken(accessToken);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      (async () => {
        // If user is here and not authenticated, it's because they're coming from sign-up
        // and the access token came from the server and is not stored in the session.
        // Because the Community SSO depends on the authentication being stored in the session
        // we need to re-ask for authentication here in a pop-up. The best we can do to make it seamless
        // is to use the loginWithPopup method while providing the signed-in user's email as a hint.
        await launchReAuth();
      })();
    }
  }, [isAuthenticated]);

  return (
    <PageContainer
      containerClassName={classNames(
        'flex items-center justify-center',
        !isAuthenticated && 'h-screen',
      )}
      noPadding
    >
      {!isAuthenticated ? (
        <div className="flex flex-col items-center">
          <div className="font-serif text-small text-green-150">
            Just a quick step to join our community - please confirm your
            identity
          </div>
          <Button
            onClick={() => launchReAuth()}
            className="mt-10"
            theme="primary"
            title="Sign in"
          />
        </div>
      ) : (
        <iframe
          title="Community"
          style={{
            border: '0',
            boxShadow: 'none',
            width: '100%',
          }}
          className="h-[calc(100vh-64px)]"
          // automatic_login parameter is key for leveraging SSO from HC app
          // https://help.circle.so/p/sso-and-integrations/sso/auto-login-members-from-sso-to-your-community
          src={`${HOMECOMING_PARTNER_HUB_BASE_URL}?automatic_login=true`}
        />
      )}
    </PageContainer>
  );
};

export default Community;
