import { FC, useEffect } from 'react';

import ModalDialog from '../ModalDialog';
import InputGroup from '../InputGroup';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { activityTitleRegisterOptions } from '../../lib/form-validators';
import { MODAL_TRANSITION_DURATION } from '../Modal';

interface RenameActivityModalFormData {
  name: string;
}

type RenameActivityModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  clearModalData?: () => void;
  onSave: (newTitle: string) => void;
  isRenamingActivity: boolean;
  activityName: string;
};

const RenameActivityModal: FC<RenameActivityModalProps> = ({
  isModalOpen,
  setClosed,
  clearModalData,
  onSave,
  isRenamingActivity,
  activityName,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    clearErrors,
    setFocus,
  } = useForm<RenameActivityModalFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async (formData: RenameActivityModalFormData) =>
    onSave(formData.name);

  const onErrors: SubmitErrorHandler<RenameActivityModalFormData> = (
    errors,
  ) => {
    console.log('errors:', errors);
  };

  useEffect(() => {
    if (activityName) {
      setValue('name', activityName);
    }
  }, [activityName, setValue]);

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      clearErrors();
      clearModalData?.();
    }, MODAL_TRANSITION_DURATION);
  };

  // Focus the input after the modal transition is complete.
  // The timeout isn't the most elegant solution but is the only way I could get auto-focus
  // to work because otherwise the animation blocks it.
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setFocus('name');
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isModalOpen]);

  return (
    <ModalDialog
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="xs"
      title="Rename resource"
      primaryActionTitle={!isRenamingActivity ? 'Save' : 'Saving'}
      primaryActionOnClick={handleSubmit(onSubmit, onErrors)}
      primaryActionDisabled={isRenamingActivity}
      primaryActionOnEnter={true}
    >
      <div className="flex items-center justify-center pb-2">
        <InputGroup
          inputSize="small"
          labelHidden
          containerClassName="w-full"
          {...register('name', activityTitleRegisterOptions)}
          errorMessage={formErrors.name?.message}
        />
      </div>
    </ModalDialog>
  );
};

export default RenameActivityModal;
