import { SvgIconComponent } from '../types/svgs';

const Help: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.34315 2 5 3.34315 5 5V6C5 6.05666 5.00236 6.11278 5.00698 6.16825C3.83814 6.57811 3 7.69118 3 9V11C3 12.6569 4.34315 14 6 14H10C11.6569 14 13 12.6569 13 11V9C13 7.69118 12.1619 6.57811 10.993 6.16825C10.9976 6.11278 11 6.05666 11 6V5C11 3.34315 9.65685 2 8 2ZM9 6V5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5V6H9ZM7 8H6C5.44772 8 5 8.44772 5 9V11C5 11.5523 5.44772 12 6 12H10C10.5523 12 11 11.5523 11 11V9C11 8.44772 10.5523 8 10 8H9H7Z"
        fill="#00B20A"
      />
    </svg>
  );
};

export default Help;
