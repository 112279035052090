import { FC, RefAttributes } from 'react';
import classNames from 'classnames';
import { ArchiveIcon } from '@heroicons/react/outline';

import Button from '../../../components/Button';
import InputGroup from '../../../components/InputGroup';
import ErrorMessage from '../../../components/ErrorMessage';

type PanelData = {
  label: string;
  readOnly?: boolean;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
} & Partial<RefAttributes<HTMLInputElement>>;

const PanelRow: FC<PanelData> = ({
  label,
  readOnly = false,
  errorMessage,
  register = {},
  ...rest
}) => {
  return (
    <div className="mb-2 flex w-full flex-row items-center justify-between">
      <div className="text-caption text-neutral-125">{label}</div>
      <div className="flex w-3/5 min-w-[300px] flex-col">
        <InputGroup
          inputSize="small"
          label={label}
          labelHidden
          {...register}
          {...rest}
          className={classNames(
            'disabled:bg-transparent disabled:text-caption disabled:font-medium disabled:text-green-150',
            readOnly && 'px-0',
          )}
        />
        {errorMessage && (
          <ErrorMessage className="mt-2">{errorMessage}</ErrorMessage>
        )}
      </div>
    </div>
  );
};

const Panel: FC<{
  title: string;
  data?: PanelData[];
  onMobileInviteButtonClick?: () => void;
  hasArchiveClient?: boolean;
  setIsDeactivatePatientModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  title,
  data,
  onMobileInviteButtonClick,
  hasArchiveClient,
  setIsDeactivatePatientModalOpen,
}) => {
  return (
    <div className="mt-4 flex w-full flex-col items-start justify-start rounded-md border border-neutral-50 px-6 pb-3 pt-6">
      <span className="mb-3 text-body font-medium text-green-125">{title}</span>
      {data?.map((panelInfo, index) => {
        return <PanelRow key={index} {...panelInfo} />;
      })}
      {onMobileInviteButtonClick && (
        <Button
          title={`Send mobile app invite`}
          size="small"
          theme="secondary"
          onClick={onMobileInviteButtonClick}
        />
      )}
      {hasArchiveClient && (
        <div className="my-4 w-full">
          <Button
            title="Archive client"
            noBackground
            noOutline
            size="small"
            theme="secondary"
            className="text-caption font-bold"
            IconComponent={ArchiveIcon}
            iconClassName="text-green-100"
            onClick={() => setIsDeactivatePatientModalOpen?.(true)}
          />
        </div>
      )}
    </div>
  );
};

export default Panel;
