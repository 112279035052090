import {
  PatientQuery,
  PatientsQuery,
  ProgramOnboardingStatus,
  ProgramTemplatesQuery,
} from '../../../generated/graphql';
import { namesToOxfordCommaList } from '../../lib/copy';
import { ColumnHeaderType, ColumnOrder } from '../../types/tables';

export interface ProgramsObject {
  [key: string]: ProgramTemplatesQuery['programTemplates'][number];
}

export interface PatientsObject {
  [key: string]: PatientsQuery['patients'][number];
}

type OnboardingStatusInfo = {
  [key in ProgramOnboardingStatus]: {
    index: number;
    text: string;
    needsActionStatus?: boolean;
    isArchived?: boolean;
  };
};

interface OnboardingStatuses {
  [key: string]: boolean;
}

export const sortOptions = {
  newestCreated: { name: 'newestCreated', isAscending: true },
  clientAlphabeticalAscending: {
    name: 'clientAlphabeticalAscending',
    isAscending: true,
  },
  clientAlphabeticalDescending: {
    name: 'clientAlphabeticalDescending',
    isDescending: true,
  },
  programAlphabeticalAscending: {
    name: 'programAlphabeticalAscending',
    isAscending: true,
  },
  programAlphabeticalDescending: {
    name: 'programAlphabeticalDescending',
    isDescending: true,
  },
  activePatientStatus: { name: 'activePatientStatus', isAscending: true },
  inactivePatientStatus: { name: 'inactivePatientStatus', isDescending: true },
  lastCheckInDescending: {
    name: 'lastCheckInDescending',
    isDescending: true,
  },
  lastCheckInAscending: {
    name: 'lastCheckInAscending',
    isAscending: true,
  },
  lastCheckInDescendingTime: {
    name: 'lastCheckInDescendingTime',
    isDescending: true,
  },
  lastCheckInAscendingTime: {
    name: 'lastCheckInAscendingTime',
    isAscending: true,
  },
  checkInsCountAscending: {
    name: 'checkInsCountAscending',
    isAscending: true,
  },
  checkInsCountDescending: {
    name: 'checkInsCountDescending',
    isDescending: true,
  },
  checkInsCountDifferenceAscending: {
    name: 'checkInsCountDifferenceAscending',
    isAscending: true,
  },
  checkInsCountDifferenceDescending: {
    name: 'checkInsCountDifferenceDescending',
    isDescending: true,
  },
  checkInsAverageAscending: {
    name: 'checkInsAverageAscending',
    isAscending: true,
  },
  checkInsAverageDescending: {
    name: 'checkInsAverageDescending',
    isDescending: true,
  },
  checkInsAverageDifferenceAscending: {
    name: 'checkInsAverageDifferenceAscending',
    isAscending: true,
  },
  checkInsAverageDifferenceDescending: {
    name: 'checkInsAverageDifferenceDescending',
    isDescending: true,
  },
  activitiesCompletedAscending: {
    name: 'activitiesCompletedAscending',
    isAscending: true,
  },
  activitiesCompletedDescending: {
    name: 'activitiesCompletedDescending',
    isDescending: true,
  },
  journalsCompletedAscending: {
    name: 'journalsCompletedAscending',
    isAscending: true,
  },
  journalsCompletedDescending: {
    name: 'journalsCompletedDescending',
    isDescending: true,
  },
  contentCompletionAscending: {
    name: 'contentCompletionAscending',
    isAscending: true,
  },
  contentCompletionDescending: {
    name: 'contentCompletionDescending',
    isDescending: true,
  },
};

const statusOptions = [
  sortOptions.activePatientStatus,
  sortOptions.inactivePatientStatus,
  sortOptions.newestCreated,
];

export const PATIENT_ORDERS: Record<string, ColumnOrder[]> = {
  name: [
    sortOptions.clientAlphabeticalAscending,
    sortOptions.clientAlphabeticalDescending,
    sortOptions.newestCreated,
  ],
  program: [
    sortOptions.programAlphabeticalAscending,
    sortOptions.programAlphabeticalDescending,
    sortOptions.newestCreated,
  ],
  inviteSent: statusOptions,
  status: statusOptions,
  nextStep: statusOptions,
  lastCheckIn: [
    sortOptions.lastCheckInDescending,
    sortOptions.lastCheckInAscending,
    sortOptions.lastCheckInDescendingTime,
    sortOptions.lastCheckInAscendingTime,
    sortOptions.newestCreated,
  ],
  checkInsCount: [
    sortOptions.checkInsCountAscending,
    sortOptions.checkInsCountDescending,
    sortOptions.checkInsCountDifferenceAscending,
    sortOptions.checkInsCountDifferenceDescending,
    sortOptions.newestCreated,
  ],
  checkInsAverage: [
    sortOptions.checkInsAverageAscending,
    sortOptions.checkInsAverageDescending,
    sortOptions.checkInsAverageDifferenceAscending,
    sortOptions.checkInsAverageDifferenceDescending,
    sortOptions.newestCreated,
  ],
  activitiesCompleted: [
    sortOptions.activitiesCompletedAscending,
    sortOptions.activitiesCompletedDescending,
    sortOptions.newestCreated,
  ],
  journalsCompleted: [
    sortOptions.journalsCompletedAscending,
    sortOptions.journalsCompletedDescending,
    sortOptions.newestCreated,
  ],
  contentCompletion: [
    sortOptions.contentCompletionAscending,
    sortOptions.contentCompletionDescending,
    sortOptions.newestCreated,
  ],
};

export const PATIENT_STATUSES: OnboardingStatusInfo = {
  [ProgramOnboardingStatus.NeedsProgram]: {
    index: 1,
    text: 'Needs program',
    needsActionStatus: true,
  },
  [ProgramOnboardingStatus.NeedsActiveProgram]: {
    index: 2,
    text: 'Needs active program',
    needsActionStatus: true,
  },
  [ProgramOnboardingStatus.NeedsProgramInvite]: {
    index: 3,
    text: 'Needs app invite',
    needsActionStatus: true,
  },
  [ProgramOnboardingStatus.ProgramInviteSent]: {
    index: 4,
    text: 'App invite sent',
  },
  [ProgramOnboardingStatus.OnboardingStarted]: {
    index: 5,
    text: 'Client onboarding started',
  },
  [ProgramOnboardingStatus.OnboardingCompleted]: {
    index: 6,
    text: 'Client onboarding completed',
  },
  [ProgramOnboardingStatus.Archived]: {
    index: 6,
    text: 'Archived',
    isArchived: true,
  },
};

export const ONBOARDING_STATUSES = [
  {
    status: ProgramOnboardingStatus.NeedsProgram,
    defaultOnboardingStatus: true,
    preInviteOnboardingStatus: true,
  },
  {
    status: ProgramOnboardingStatus.NeedsActiveProgram,
    defaultOnboardingStatus: false,
    preInviteOnboardingStatus: true,
  },
  {
    status: ProgramOnboardingStatus.NeedsProgramInvite,
    defaultOnboardingStatus: true,
    preInviteOnboardingStatus: true,
  },
];

export const VALID_ONBOARDING_STATUSES: Record<string, OnboardingStatuses> = {
  assignToProgram: {
    NeedsProgram: true,
    NeedsActiveProgram: true,
    NeedsProgramInvite: true,
  },
  inviteToProgram: {
    NeedsProgramInvite: true,
    ProgramInviteSent: true,
  },
};

const columnHeaderOptions: Record<string, ColumnHeaderType> = {
  name: {
    field: 'name',
    headerName: 'Client',
    isSortable: true,
  },
  program: {
    field: 'program',
    headerName: 'Program',
    isSortable: true,
  },
  status: {
    field: 'status',
    headerName: 'Status',
    isSortable: true,
  },
  inviteSent: {
    field: 'inviteSent',
    headerName: 'Last invite sent',
    isSortable: true,
  },
  nextStep: {
    field: 'nextStep',
    headerName: 'Set-up step',
    isSortable: true,
  },
  lastCheckIn: {
    field: 'lastCheckIn',
    headerName: 'Last check-in score',
    isSortable: true,
  },
  checkInsCount: {
    field: 'checkInsCount',
    headerName: 'Check-ins (past 7 days)',
    isSortable: true,
  },
  contentCompletion: {
    field: 'contentCompletion',
    headerName: 'Content progress',
    isSortable: true,
  },
  checkInScoreAverage: {
    field: 'checkInsAverage',
    headerName: 'Check-in average (past 7 days)',
    isSortable: true,
  },
  activitiesCompleted: {
    field: 'activitiesCompleted',
    headerName: 'Activities completed',
    isSortable: true,
  },
  journalsCompleted: {
    field: 'journalsCompleted',
    headerName: 'Journal entries',
    isSortable: true,
  },
};

export const columnHeaders: Record<string, ColumnHeaderType[]> = {
  onboarding: [
    columnHeaderOptions.name,
    columnHeaderOptions.program,
    columnHeaderOptions.status,
    columnHeaderOptions.nextStep,
  ],
  invited: [
    columnHeaderOptions.name,
    columnHeaderOptions.program,
    columnHeaderOptions.inviteSent,
    columnHeaderOptions.nextStep,
  ],
  active: [
    columnHeaderOptions.name,
    columnHeaderOptions.program,
    columnHeaderOptions.lastCheckIn,
    columnHeaderOptions.checkInsCount,
    columnHeaderOptions.contentCompletion,
  ],
  archived: [
    columnHeaderOptions.name,
    columnHeaderOptions.program,
    columnHeaderOptions.status,
  ],
};

export const sortPatients = (
  patients: PatientsQuery['patients'] | null,
  patientOrder: ColumnOrder,
) => {
  if (patients) {
    return patients.sort(
      (
        patientA: PatientsQuery['patients'][number],
        patientB: PatientsQuery['patients'][number],
      ) => {
        const patientAFullName =
          `${patientA.firstName} ${patientA.lastName}`.toLowerCase();
        const patientBFullName =
          `${patientB.firstName} ${patientB.lastName}`.toLowerCase();

        const patientAProgramName = patientA?.programTemplate?.name || '';
        const patientBProgramName = patientB?.programTemplate?.name || '';

        const patientAOnboardingIndex =
          PATIENT_STATUSES[patientA.onboardingStatus].index;
        const patientBOnboardingIndex =
          PATIENT_STATUSES[patientB.onboardingStatus].index;

        const patientACheckInsCount =
          patientA?.checkInData?.last7DaysCheckInsCount || 0;
        const patientBCheckInsCount =
          patientB?.checkInData?.last7DaysCheckInsCount || 0;
        const patientACheckInsCountDifference =
          patientA?.checkInData?.last7DaysCheckInsCountDifference || 0;
        const patientBCheckInsCountDifference =
          patientB?.checkInData?.last7DaysCheckInsCountDifference || 0;

        const patientACheckInsAverage =
          patientA?.checkInData?.last7DaysAverage || 0;
        const patientBCheckInsAverage =
          patientB?.checkInData?.last7DaysAverage || 0;
        const patientACheckInsAverageDifference =
          patientA?.checkInData?.last7DaysAverageDifference || 0;
        const patientBCheckInsAverageDifference =
          patientB?.checkInData?.last7DaysAverageDifference || 0;

        const patientAContentCompletion =
          patientA?.tasksData?.contentCompletedPercentage || 0;
        const patientBContentCompletion =
          patientB?.tasksData?.contentCompletedPercentage || 0;

        const patientALastCheckIn =
          patientA?.checkInData?.lastCheckIn?.score || 0;
        const patientBLastCheckIn =
          patientB?.checkInData?.lastCheckIn?.score || 0;

        const patientALastCheckInTime =
          patientA?.checkInData?.lastCheckIn?.createdAt || '-';
        const patientBLastCheckInTime =
          patientB?.checkInData?.lastCheckIn?.createdAt || '-';

        switch (patientOrder) {
          case sortOptions.newestCreated:
            return patientA.createdAt >= patientB.createdAt ? -1 : 1;
          case sortOptions.clientAlphabeticalAscending:
            return patientAFullName < patientBFullName ? -1 : 1;
          case sortOptions.clientAlphabeticalDescending:
            return patientAFullName >= patientBFullName ? -1 : 1;
          case sortOptions.programAlphabeticalAscending:
            return patientAProgramName < patientBProgramName ? -1 : 1;
          case sortOptions.programAlphabeticalDescending:
            return patientAProgramName >= patientBProgramName ? -1 : 1;
          case sortOptions.activePatientStatus:
            if (patientA.onboardingStatus === patientB.onboardingStatus) {
              if (
                patientA.onboardingStatus ===
                ProgramOnboardingStatus.ProgramInviteSent
              ) {
                return patientA.onboardingLastProgramInviteSentAt <
                  patientB.onboardingLastProgramInviteSentAt
                  ? -1
                  : 1;
              }
              if (
                patientA.onboardingStatus ===
                ProgramOnboardingStatus.OnboardingCompleted
              ) {
                return patientA.onboardingCompletedAt <
                  patientB.onboardingCompletedAt
                  ? -1
                  : 1;
              }
              if (
                patientA.onboardingStatus === ProgramOnboardingStatus.Archived
              ) {
                return patientA.deactivatedAt < patientB.deactivatedAt ? -1 : 1;
              }
            }
            return patientAOnboardingIndex < patientBOnboardingIndex ? -1 : 1;
          case sortOptions.inactivePatientStatus:
            if (patientA.onboardingStatus === patientB.onboardingStatus) {
              if (
                patientA.onboardingStatus ===
                ProgramOnboardingStatus.ProgramInviteSent
              ) {
                return patientA.onboardingLastProgramInviteSentAt >=
                  patientB.onboardingLastProgramInviteSentAt
                  ? -1
                  : 1;
              }
              if (
                patientA.onboardingStatus ===
                ProgramOnboardingStatus.OnboardingCompleted
              ) {
                return patientA.onboardingCompletedAt >=
                  patientB.onboardingCompletedAt
                  ? -1
                  : 1;
              }
              if (
                patientA.onboardingStatus === ProgramOnboardingStatus.Archived
              ) {
                return patientA.deactivatedAt >= patientB.deactivatedAt
                  ? -1
                  : 1;
              }
            }
            return patientAOnboardingIndex >= patientBOnboardingIndex ? -1 : 1;

          case sortOptions.lastCheckInAscending:
            return patientALastCheckIn < patientBLastCheckIn ? -1 : 1;
          case sortOptions.lastCheckInDescending:
            return patientALastCheckIn >= patientBLastCheckIn ? -1 : 1;
          case sortOptions.lastCheckInAscendingTime:
            return patientALastCheckInTime < patientBLastCheckInTime ? -1 : 1;
          case sortOptions.lastCheckInDescendingTime:
            return patientALastCheckInTime >= patientBLastCheckInTime ? -1 : 1;

          case sortOptions.checkInsCountAscending:
            return patientACheckInsCount < patientBCheckInsCount ? -1 : 1;
          case sortOptions.checkInsCountDescending:
            return patientACheckInsCount >= patientBCheckInsCount ? -1 : 1;
          case sortOptions.checkInsCountDifferenceAscending:
            return patientACheckInsCountDifference <
              patientBCheckInsCountDifference
              ? -1
              : 1;
          case sortOptions.checkInsCountDifferenceDescending:
            return patientACheckInsCountDifference >=
              patientBCheckInsCountDifference
              ? -1
              : 1;

          case sortOptions.checkInsAverageAscending:
            return patientACheckInsAverage < patientBCheckInsAverage ? -1 : 1;
          case sortOptions.checkInsAverageDescending:
            return patientACheckInsAverage >= patientBCheckInsAverage ? -1 : 1;
          case sortOptions.checkInsAverageDifferenceAscending:
            return patientACheckInsAverageDifference <
              patientBCheckInsAverageDifference
              ? -1
              : 1;
          case sortOptions.checkInsAverageDifferenceDescending:
            return patientACheckInsAverageDifference >=
              patientBCheckInsAverageDifference
              ? -1
              : 1;

          case sortOptions.contentCompletionAscending:
            return patientAContentCompletion < patientBContentCompletion
              ? -1
              : 1;
          case sortOptions.contentCompletionDescending:
            return patientAContentCompletion >= patientBContentCompletion
              ? -1
              : 1;

          default:
            return patientA.createdAt >= patientB.createdAt ? -1 : 1;
        }
      },
    );
  }
  return [];
};

export const getProgramsObject = (
  programs: ProgramTemplatesQuery['programTemplates'],
) => {
  const programsObject: ProgramsObject = {};
  if (programs) {
    programs.forEach((program) => {
      if (program.id) {
        programsObject[program.id] = program;
      }
    });
  }
  return programsObject;
};

export const getPatientsObject = (patients: PatientsQuery['patients']) => {
  const patientsObject: PatientsObject = {};
  patients.forEach((patient) => {
    if (patient.programInstanceId) {
      patientsObject[patient.programInstanceId] = patient;
    }
  });
  return patientsObject;
};

export const getPatientsList = (patientsObject: PatientsObject) => {
  return Object.values(patientsObject);
};

export const getPatientCount = (patientsObject: PatientsObject) => {
  return getPatientsList(patientsObject).length;
};
export interface Filters {
  [key: string]: boolean;
}

export const filtersObject: Record<string, Filters> = {
  programs: {},
  statuses: {},
};

export const getEligibleFilters = (filters: Record<string, Filters>) => {
  const getEligibleFilter = (filter: Filters) =>
    Object.entries(filter)
      .filter((entry) => entry[1])
      .map((entry) => entry[0]);

  return {
    programFilter: Boolean(filters.programs)
      ? getEligibleFilter(filters.programs)
      : [],
    statusFilter: Boolean(filters.statuses)
      ? getEligibleFilter(filters.statuses)
      : [],
  };
};

export const validatePatientsForMutation = (
  patients: PatientQuery['patient'][] | PatientsQuery['patients'],
  status: 'assignToProgram' | 'inviteToProgram',
) => {
  const eligibleOnboardingStatuses = VALID_ONBOARDING_STATUSES[status];

  const invalidPatients: PatientsQuery['patients'] = [];
  const validProgramInstanceIds: string[] = [];

  patients.forEach((patient) => {
    if (eligibleOnboardingStatuses[patient.onboardingStatus]) {
      validProgramInstanceIds.push(patient?.programInstanceId);
    } else {
      invalidPatients.push(patient);
    }
  });

  return {
    validProgramInstanceIds,
    invalidPatients,
  };
};

export const getInvalidSentenceText = (
  invalidPatients: PatientsQuery['patients'],
) => {
  if (invalidPatients.length === 0) return '';

  const maxPatientsToDisplay = 5;

  const hasManyInvalidPatients = invalidPatients.length > maxPatientsToDisplay;

  const invalidPatientsCleaned = hasManyInvalidPatients
    ? invalidPatients.slice(0, maxPatientsToDisplay)
    : invalidPatients;

  let invalidPatientsText = namesToOxfordCommaList(
    invalidPatientsCleaned.map(
      (patient: PatientsQuery['patients'][number]) =>
        `${patient.firstName} ${patient.lastName}`,
    ),
  );

  if (hasManyInvalidPatients) {
    invalidPatientsText = invalidPatientsText
      .split('and')[0]
      .concat('and others');
  }

  return invalidPatientsText;
};

export const getSeatLimitAlertText = (
  clientSeatsRemaining: number,
  numberOfInvites: number,
): string => {
  if (clientSeatsRemaining === 0) {
    return `You have no more client seats remaining.`;
  }
  const clientSeatsRemainingChunk = `You have ${clientSeatsRemaining} client seat${
    clientSeatsRemaining > 1 ? 's' : ''
  } remaining and `;
  const invitationLimitChunk =
    numberOfInvites > 1
      ? `these ${numberOfInvites} invites are over that limit`
      : `this invite is over that limit`;
  return clientSeatsRemainingChunk + invitationLimitChunk;
};
