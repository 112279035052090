import classNames from 'classnames';
import { useState } from 'react';

import { ProviderUserMemberDataFragment } from '../../generated/graphql';
import { getTimeStamp } from '../lib/date';
import { ColumnHeaderType } from '../types/tables';
import MemberActionsMenu from './MemberActionsMenu';
import TableHeader from './TableHeader';

const columns: ColumnHeaderType[] = [
  {
    field: 'name',
    headerName: 'Team member',
  },
  {
    field: 'lastInviteSentAt',
    headerName: 'Invite sent',
  },
  {
    field: 'registeredAt',
    headerName: 'Joined',
  },
  {
    field: 'actions',
    headerName: 'Actions',
  },
];

type MembersTableProps = {
  providerUserMembers: ProviderUserMemberDataFragment[];
  refetchMembers: () => Promise<unknown>;
};

export default function MembersTable({
  providerUserMembers,
  refetchMembers,
}: MembersTableProps) {
  const [actionMenuMemberId, setActionMenuMemberId] = useState<string | null>(
    null,
  );

  return (
    <table className="relative mt-7 w-auto min-w-[800px] table-fixed divide-y divide-neutral-50">
      <thead>
        <tr>
          {columns.map((column) => (
            <TableHeader
              key={`table-header-${column.field}`}
              column={column}
              className="pl-3"
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {providerUserMembers.map((providerUserMember, index) => {
          return (
            <tr
              key={`members-row-${index}`}
              className="text-caption text-green-150 hover:bg-neutral-25"
            >
              <td>
                <div
                  className={classNames(
                    'mb-1 font-serif text-extra-small',
                    !providerUserMember.name && 'text-neutral-150/50',
                  )}
                >
                  {providerUserMember.name ?? '(Pending)'}
                </div>
                <div className="text-small-caption">
                  {providerUserMember.email}
                </div>
              </td>
              <td>
                {providerUserMember.lastInviteSentAt &&
                  getTimeStamp(providerUserMember.lastInviteSentAt)}
              </td>
              <td
                className={classNames(
                  !providerUserMember.registeredAt && 'text-neutral-150/50',
                )}
              >
                {providerUserMember.registeredAt
                  ? getTimeStamp(providerUserMember.registeredAt)
                  : '(Pending)'}
              </td>
              <td>
                <MemberActionsMenu
                  isOpen={actionMenuMemberId === providerUserMember.id}
                  providerUserMember={providerUserMember}
                  setIsOpen={(isOpen) =>
                    setActionMenuMemberId(isOpen ? providerUserMember.id : null)
                  }
                  refetchMembers={refetchMembers}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
