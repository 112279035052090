import { FC, useState } from 'react';
import { Transition } from '@headlessui/react';

import Profile from '../../svgs/Profile';
import Clinic from '../../svgs/Clinic';
import Help from '../../svgs/Help';
import Lock from '../../svgs/Lock';
import { DocumentDownloadIcon } from '@heroicons/react/outline';

import { ProviderUserRole } from '../../../generated/graphql';
import SettingsGroup, { ProviderDataList } from './SettingsGroup';
import ChangePasswordModal from './ChangePasswordModal';
import ResetOnboardingModal from './ResetOnboardingModal';
import Spinner from '../../svgs/Spinner';
import { defaultEnterTransitionProps } from '../../lib/animation';
import { getAssessmentsDataExport } from '../../../api/rest/assessments';
import { useAuth } from '../../../contexts/AuthContext';

const SettingsSection: FC = () => {
  const { authedProviderUser, isAuthedProviderUserLoading } = useAuth();

  const canExportAssessmentsData = authedProviderUser.role
    ? [ProviderUserRole.Owner, ProviderUserRole.Administrator].includes(
        authedProviderUser.role,
      )
    : false;

  const providerInfo = authedProviderUser;

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isResetOnboardingModalOpen, setIsResetOnboardingModalOpen] =
    useState(false);

  const downloadAssessmentsData = () => {
    getAssessmentsDataExport();
  };

  const yourPracticeList: ProviderDataList = [
    { data: providerInfo?.provider.name ?? '' },
  ];

  const profileList: ProviderDataList = [
    { label: 'Name', data: providerInfo?.name ?? '' },
    { label: 'Email', data: providerInfo?.email ?? '' },
    { label: 'Role', data: providerInfo?.jobTitle ?? '' },
  ];

  const securityList: ProviderDataList = [
    {
      data: 'Change password',
      onClick: () => setIsChangePasswordModalOpen(true),
    },
  ];

  const helpList: ProviderDataList = [
    { data: 'Contact Homecoming', link: 'mailto:support@homecoming.health' },
  ];

  const testingList: ProviderDataList = [
    {
      data: 'Reset Onboarding',
      onClick: () => setIsResetOnboardingModalOpen(true),
    },
  ];

  const assessmentsList: ProviderDataList = [
    {
      data: 'Export Assessments Data',
      onClick: () => downloadAssessmentsData(),
    },
  ];

  return (
    <>
      <div className="flex w-[615px] flex-col items-center justify-start">
        <Transition
          show={!isAuthedProviderUserLoading}
          className="w-full"
          {...defaultEnterTransitionProps}
        >
          <SettingsGroup
            sectionName="Your Practice"
            providerData={yourPracticeList}
            IconComponent={Clinic}
          />
          <SettingsGroup
            sectionName="Profile"
            providerData={profileList}
            IconComponent={Profile}
          />
          <SettingsGroup
            sectionName="Security"
            providerData={securityList}
            IconComponent={Lock}
          />
          <SettingsGroup
            sectionName="Help"
            providerData={helpList}
            IconComponent={Help}
          />
          {providerInfo?.provider.isTestProvider && (
            <SettingsGroup
              sectionName="Testing"
              providerData={testingList}
              IconComponent={Help}
            />
          )}
          {canExportAssessmentsData && (
            <SettingsGroup
              sectionName="Assessments"
              providerData={assessmentsList}
              IconComponent={DocumentDownloadIcon}
            />
          )}
        </Transition>

        {isAuthedProviderUserLoading && <Spinner className="mx-auto mt-4" />}
      </div>

      <ChangePasswordModal
        isModalOpen={isChangePasswordModalOpen}
        setClosed={() => setIsChangePasswordModalOpen(false)}
      />
      <ResetOnboardingModal
        isModalOpen={isResetOnboardingModalOpen}
        setClosed={() => setIsResetOnboardingModalOpen(false)}
        providerUserEmail={providerInfo?.email}
      />
    </>
  );
};

export default SettingsSection;
