import classNames from 'classnames';

type ErrorMessageProps = {
  className?: string;
  children?: React.ReactNode;
};

export default function ErrorMessage({
  className,
  children,
}: ErrorMessageProps) {
  if (!children) {
    return null;
  }

  return (
    <span
      className={classNames(
        'flex items-center text-small-caption text-red-100',
        className,
      )}
    >
      <span className="text-left">{children}</span>
    </span>
  );
}
