import { ApolloError, QueryResult } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import {
  Exact,
  MeProviderDataFragment,
  MeProviderQuery,
  SignUpConfig,
  TokenPayload,
  useMeProviderLazyQuery,
  useMeProviderSubscriptionStateLazyQuery,
  useMeProviderUserNotificationsLazyQuery,
  useMeProviderUserProfileImageUrlLazyQuery,
  useProviderSignUpMutation,
  useProviderUserInviteSignupMutation,
  useProviderUserLoginMutation,
  useProviderUserLogoutMutation,
} from '../generated/graphql';

import {
  aliasProvider,
  identifyProviderUser,
  resetProviderMixpanel,
} from '../lib/analytics';
import SentryHelpers from '../lib/sentry';

import {
  clearAuth0AccessToken,
  clearTokenPayload,
  getAuth0AccessToken,
  getIsAuthedV1,
  getTokenPayload,
  storeAuth0AccessToken,
  storeTokenPayload,
} from '../lib/auth';
import { UtmParams } from '../v2/hooks/useUtmTracking';
import { useAuth0 } from '@auth0/auth0-react';
import { signOutOfCircle } from '../v2/lib/circle-community';
import { useLocation } from 'react-router-dom';

interface SignUpResult {
  tokenPayload: TokenPayload;
  signUpConfig: Partial<SignUpConfig> | null;
}

interface AuthContextType {
  tokenPayload: TokenPayload | null;
  isAuthedV1: boolean;
  auth0AccessToken: string | null;
  saveToken: (auth0Token: string | null, isV2?: boolean) => void;
  authedProviderUser: MeProviderQuery['meProvider'] | null;
  isAuthedProviderUserLoading: boolean;
  authedProviderUserError?: ApolloError;
  hasInitialLoadError: boolean;
  setHasInitialLoadError: (value: boolean) => void;
  refreshAuthedProviderUser: () => Promise<
    QueryResult<
      MeProviderQuery,
      Exact<{
        [key: string]: never;
      }>
    >
  >;
  refreshAuthedProviderUserProfileImage: () => Promise<void>;
  refreshAuthedProviderUserNotifications: () => Promise<void>;
  refreshAuthedProviderUserSubscriptionState: () => Promise<void>;
  login: (email: string, password: string) => Promise<TokenPayload>;
  logout: () => Promise<void>;
  signUp: (
    email: string,
    password: string,
    name: string,
    emailOptIn?: boolean,
    signUpConfigSlug?: string,
    utmParams?: UtmParams,
  ) => Promise<SignUpResult>;
  inviteSignup: (
    inviteCode: string,
    name: string,
    password: string,
  ) => Promise<TokenPayload>;
  showUpgradeBanner: boolean;
  willHitClientLimit: (clientsToAdd?: number) => boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { logout: auth0Logout } = useAuth0();
  const location = useLocation();

  const isInCommunity = location.pathname.startsWith('/community');

  const [tokenPayload, setTokenPayload] = useState<TokenPayload | null>(
    getTokenPayload(),
  );
  // This is a super hacky way to kick the app state into V1 mode, when we first
  // fetch the user data with a valid Auth0 token. Because we don't have the V1/V2
  // status of the user at the time of auth anymore in Auth0, we have to wait until we fetch
  // the user data from the server to determine if the user is V1 or V2, and then we update this.
  // It's not great, but we only have 1 V1 user left at the time of this writing (Beond). We
  // should delete this, along with the tokenPayload when we remove the last V1 user.
  const [isAuthedV1, setIsAuthedV1] = useState<boolean>(
    getIsAuthedV1() ?? false,
  );

  const [auth0AccessToken, setAuth0AccessToken] = useState<string | null>(
    getAuth0AccessToken(),
  );

  const [authedProviderUser, setAuthedProviderUser] = useState<
    MeProviderQuery['meProvider'] | null
  >(null);
  const [hasInitialLoadError, setHasInitialLoadError] = useState(false);
  const { shutdown: shutdownIntercom } = useIntercom();

  const [
    meProvider,
    { loading: isAuthedProviderUserLoading, error: authedProviderUserError },
  ] = useMeProviderLazyQuery();

  const [meProviderUserProfileImageUrl] =
    useMeProviderUserProfileImageUrlLazyQuery();
  const [meProviderSubscriptionState] =
    useMeProviderSubscriptionStateLazyQuery();

  const [meProviderUserNotifications] =
    useMeProviderUserNotificationsLazyQuery();

  const [providerUserLogin] = useProviderUserLoginMutation();
  const [providerUserLogout] = useProviderUserLogoutMutation();
  const [providerUserInviteSignup] = useProviderUserInviteSignupMutation();
  const [providerSignUp] = useProviderSignUpMutation();

  const updateTokenPayload = (tokenPayload: TokenPayload | null): void => {
    if (tokenPayload) {
      storeTokenPayload(tokenPayload);
      setTokenPayload(tokenPayload);
    } else {
      clearTokenPayload();
      setTokenPayload(null);
    }
  };

  const updateAuth0AccessToken = (
    auth0AccessToken: string | null,
    isV1?: boolean,
  ): void => {
    if (auth0AccessToken) {
      storeAuth0AccessToken(auth0AccessToken, isV1);
      setAuth0AccessToken(auth0AccessToken);
      if (isV1) {
        setIsAuthedV1(true);
      }
    } else {
      clearAuth0AccessToken();
      setAuth0AccessToken(null);
    }
  };

  const refreshAuthedProviderUser = useCallback(async () => {
    const response = await meProvider();
    const authedProviderUser = response.data?.meProvider;

    // Post-Auth0 integration, we can only differentiate between V1 and V2 users by the field
    // on the authed ProviderUser. We don't get this at the time of auth alongside the token anymore
    // since we get the token from Auth0 and it doesn't make sense to transfer V1/V2 info there when there's
    // only one V1 user at the time of this writing (Beond). This is a hacky way to set the user's V1 status
    // alongside the authed token when the user is fetched from the server, which will kick the newly-authed user into V1.
    // When we remove the last V1 user, we should remove this.
    // Additionally, it's important to get the auth0 access token from the cookie here, since the cookie is set
    // in the auth call just before this, and is accessible directly from the cookie, but not yet in the state variable
    // 'auth0AccessToken' within this context. If we try to pull from there, the value won't be available yet in React state
    // and the V1 user will end up in V2 on authing (but a refresh will boot them to V1).
    const auth0accessTokenFromCookie = getAuth0AccessToken();
    if (
      authedProviderUser &&
      !authedProviderUser.isV2 &&
      auth0accessTokenFromCookie
    ) {
      updateAuth0AccessToken(auth0accessTokenFromCookie, true);
    }

    // Place any 3rd-party identity calls here on page refresh so that user data is always sent
    syncAuthedProviderUserAppState(authedProviderUser ?? null);

    return response;
  }, [meProvider]);

  const refreshAuthedProviderUserProfileImage = async (): Promise<void> => {
    if (authedProviderUser) {
      const response = await meProviderUserProfileImageUrl();
      const profileImageMedia = response.data?.meProvider?.profileImageMedia;
      const ownerProfileImageMedia =
        response.data?.meProvider?.provider.ownerProfileImageMedia;
      setAuthedProviderUser({
        ...authedProviderUser,
        profileImageMedia,
        provider: {
          ...authedProviderUser.provider,
          ownerProfileImageMedia,
        },
      });
    }
  };

  const refreshAuthedProviderUserNotifications = async (): Promise<void> => {
    if (authedProviderUser) {
      const response = await meProviderUserNotifications();
      const notifications = response.data?.meProvider?.notifications;
      if (notifications) {
        setAuthedProviderUser({
          ...authedProviderUser,
          notifications,
        });
      }
    }
  };

  const refreshAuthedProviderUserSubscriptionState =
    async (): Promise<void> => {
      if (authedProviderUser) {
        const response = await meProviderSubscriptionState();
        const meProvider = response.data?.meProvider;
        if (meProvider) {
          setAuthedProviderUser({
            ...authedProviderUser,
            ...meProvider,
            provider: {
              ...authedProviderUser.provider,
              ...meProvider.provider,
            },
          });
        }
      }
    };

  const login = async (
    email: string,
    password: string,
  ): Promise<TokenPayload> => {
    if (hasInitialLoadError) {
      setHasInitialLoadError(false);
    }

    const response = await providerUserLogin({
      variables: {
        email,
        password,
        isMobile: false,
      },
    });
    const tokenPayload = response.data?.providerUserLogin.tokenPayload;
    const meProvider = response.data?.providerUserLogin.meProvider ?? null;

    if (!tokenPayload) {
      throw new Error('No token payload');
    }

    saveToken(tokenPayload.authToken);
    syncAuthedProviderUserAppState(meProvider);

    return tokenPayload;
  };

  const syncAuthedProviderUserAppState = (
    meProvider: MeProviderDataFragment | null,
  ) => {
    if (meProvider) {
      setAuthedProviderUser(meProvider);
      identifyProviderUser(meProvider.id);
      SentryHelpers.setUserScope(meProvider);
    } else {
      setAuthedProviderUser(null);
      resetProviderMixpanel();
      SentryHelpers.clearUserScope();
      shutdownIntercom();
    }
  };

  const saveToken = (auth0Token: string | null) => {
    updateAuth0AccessToken(auth0Token);
    updateTokenPayload(null); // Clear out the old token payload
  };

  const logout = async () => {
    try {
      // Tell the server that we're logging out so that it can invalidate the token on its end.
      await providerUserLogout();
    } catch (err) {
      throw err;
    } finally {
      // Perform logout regardless if the server can't be reached.
      saveToken(null);

      await signOutOfCircle();
      // This redirects to Auth0's logout page, which then redirects back to the homepage.
      await auth0Logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
  };

  const signUp = async (
    email: string,
    password: string,
    name: string,
    emailOptIn?: boolean,
    signUpConfigSlug?: string,
    utmParams?: UtmParams,
  ): Promise<SignUpResult> => {
    const response = await providerSignUp({
      variables: {
        input: {
          email,
          name,
          password,
          emailOptIn,
          signUpConfigSlug,
          utmParams,
        },
      },
    });
    const tokenPayload = response.data?.providerSignUp.tokenPayload;
    const meProvider = response.data?.providerSignUp.meProvider ?? null;
    const signUpConfig = response.data?.providerSignUp.signUpConfig ?? null;

    if (!tokenPayload) {
      throw new Error('No token payload');
    }

    // Critical for connecting our Provider User ID further back in the funnel to the unauthed
    // part of the webapp, and further back to the marketing website.
    // Usually, using Mixpanel's new ID merge capacities, the `identify` call on its own would
    // be smart enough to auto-link the new Provider User ID to the unauthed webapp distinct ID. But
    // since, in the case of someone coming from the marketing website, we've already aliased
    // a previous distinct ID from the marketing website to the current webapp distinct ID, it
    // fails to do so automatically without this call. Without this call, a new User is created
    // in Mixpanel that is untethered from the previous ID cluster in the marketing website and
    // even the unauthed portion of this webapp.
    aliasProvider(tokenPayload.providerUserId);

    saveToken(tokenPayload.authToken);
    syncAuthedProviderUserAppState(meProvider);

    return { tokenPayload, signUpConfig };
  };

  const inviteSignup = async (
    inviteCode: string,
    name: string,
    password: string,
  ): Promise<TokenPayload> => {
    const response = await providerUserInviteSignup({
      variables: {
        inviteCode,
        name,
        password,
      },
    });
    const tokenPayload = response.data?.providerUserInviteSignup.tokenPayload;
    const meProvider =
      response.data?.providerUserInviteSignup.meProvider ?? null;

    if (!tokenPayload) {
      throw new Error('No token payload');
    }

    saveToken(tokenPayload.authToken);
    syncAuthedProviderUserAppState(meProvider);

    return tokenPayload;
  };

  const willHitClientLimit = useCallback(
    (clientsToAdd = 1) =>
      authedProviderUser &&
      !authedProviderUser.hasPremiumAccess &&
      authedProviderUser.provider.clientSeats &&
      authedProviderUser.provider.usedClientSeats + clientsToAdd >
        authedProviderUser.provider.clientSeats,
    [authedProviderUser],
  );

  const showUpgradeBanner = useMemo(
    () =>
      authedProviderUser &&
      !authedProviderUser.hasPremiumAccess &&
      !isInCommunity,
    [authedProviderUser, isInCommunity],
  );

  return (
    <AuthContext.Provider
      value={{
        tokenPayload,
        isAuthedV1,
        auth0AccessToken,
        saveToken,
        authedProviderUser,
        isAuthedProviderUserLoading,
        authedProviderUserError,
        hasInitialLoadError,
        setHasInitialLoadError,
        refreshAuthedProviderUser,
        refreshAuthedProviderUserProfileImage,
        refreshAuthedProviderUserNotifications,
        refreshAuthedProviderUserSubscriptionState,
        login,
        logout,
        signUp,
        inviteSignup,
        showUpgradeBanner,
        willHitClientLimit,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
