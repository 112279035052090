import { useEffect, useState } from 'react';
import {
  retrieveItem,
  retrieveUserItem,
  storeItem,
  storeUserItem,
} from '../lib/storage';

export default function useLocalStorageValue<T>(key: string, userId?: string) {
  const [value, setValue] = useState<T>(() =>
    userId ? retrieveUserItem<T>(userId, key) : retrieveItem<T>(key),
  );

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue ? JSON.parse(event.newValue) : null);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const setLocalStorageValue = (newValue: T) => {
    if (userId) {
      storeUserItem(userId, key, newValue);
    } else {
      storeItem(key, newValue);
    }
    setValue(newValue);
  };

  return [value, setLocalStorageValue] as const;
}
