export const acceptedImageFileExtensions = ['image/jpeg', 'image/png'];
export const acceptedAudioFileExtensions = [
  'audio/mpeg',
  'audio/m4a',
  'audio/x-m4a',
  'audio/mp4',
];
export const acceptedPdfFileExtensions = ['application/pdf'];
export const acceptedDocumentFileExtensions = [
  'text/plain',
  'application/msword', // Word 97-2003
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word 2007+
  'application/vnd.oasis.opendocument.text', // OpenDocument Text
  'application/pdf', // PDF
  'application/rtf', // Rich Text Format
  'application/vnd.ms-excel', // Excel 97-2003
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel 2007+
  'application/vnd.oasis.opendocument.spreadsheet', // OpenDocument Spreadsheet
  'application/vnd.ms-powerpoint', // PowerPoint 97-2003
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint 2007+
  'text/csv', // CSV
];

export const MAX_UPLOAD_SIZE_BYTES = 100 * 1000 * 1000;
