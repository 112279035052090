import { ApolloQueryResult } from '@apollo/client';
import {
  ActivityDataFragment,
  Exact,
  ProviderActivitiesQuery,
} from '../../generated/graphql';

export const enum ProviderActivityType {
  PDF = 'ActivityPDF',
  Link = 'ActivityLink',
  Audio = 'ActivityAudio',
  Image = 'ActivityImage',
  Assessment = 'ActivityAssessment',
  Text = 'ActivityText',
  Action = 'ActivityAction',
}

export function isProviderActivityPDF(
  providerActivity: ActivityDataFragment,
): boolean {
  return providerActivity?.__typename === ProviderActivityType.PDF;
}

export const ProgramActivityType = 'ProgramActivity';

export type RefetchProviderActivities = (
  variables?:
    | Partial<
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<ProviderActivitiesQuery>>;
