import { FC, useEffect, useState } from 'react';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';

import {
  PatientTableDataFragment,
  useDeactivatePatientsMutation,
} from '../../../../generated/graphql';

import Button from '../../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';

import { PatientsObject, getPatientsList } from '../helpers';
import { useAuth } from '../../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import ToastAlert from '../../../components/ToastAlert';
import { SparklesIcon, XIcon } from '@heroicons/react/outline';
import SelectPatientRow from './SelectPatientRow';
import { useLocation, useNavigate } from 'react-router-dom';
import { stripLeadingSlash } from '../../../lib/string';
import { DEFAULT_FREE_CLIENT_COUNT } from '../../../lib/constants';

const PatientCount: FC<{ countTitle: string; count: number | string }> = ({
  countTitle,
  count,
}) => (
  <div className="flex flex-row items-center text-caption">
    <div className="text-neutral-110">{countTitle}</div>
    <div className="ml-1 rounded-full bg-neutral-25 px-2 py-0.5 font-medium text-neutral-125">
      {count}
    </div>
  </div>
);

export type DeactivatePatientsFormData = {
  patients: PatientTableDataFragment[];
  patientsToKeep: PatientTableDataFragment[];
};

const TrialEndDeactivatePatientsModal: FC<{
  isOpen: boolean;
  setClosed: () => void;
  patientsObject: PatientsObject | null;
  onDeactivation?: (deactivatedProgramIds: string[]) => Promise<void>;
}> = ({ isOpen, setClosed, patientsObject, onDeactivation }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();

  const clientSeats =
    authedProviderUser?.provider?.clientSeats ?? DEFAULT_FREE_CLIENT_COUNT;

  const [deactivatePatientsMutation] = useDeactivatePatientsMutation();

  const deactivatePatientsForm = useForm<DeactivatePatientsFormData>({
    mode: 'onSubmit',
    defaultValues: {
      patients: [],
      patientsToKeep: [],
    },
  });

  const { reset: resetForm, watch, handleSubmit } = deactivatePatientsForm;

  const {
    fields: patients,
    append: appendPatient,
    remove: removePatient,
  } = useFieldArray({
    control: deactivatePatientsForm.control,
    name: 'patients',
  });

  const {
    fields: patientsToKeep,
    append: appendPatientToKeep,
    remove: removePatientToKeep,
  } = useFieldArray({
    control: deactivatePatientsForm.control,
    name: 'patientsToKeep',
  });

  useEffect(() => {
    if (isOpen && patientsObject) {
      const patientsList = patientsObject
        ? getPatientsList(patientsObject, false, true)
        : [];
      resetForm({
        patients: patientsList,
        patientsToKeep: [],
      });
    }
  }, [isOpen, patientsObject, resetForm]);

  const watchPatients = watch('patients')?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const watchPatientsToKeep = watch('patientsToKeep')?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    try {
      setLoading(true);
      const response = await deactivatePatientsMutation({
        variables: {
          input: {
            programInstanceIds: watchPatients.map(
              (patient) => patient.programInstanceId,
            ),
          },
        },
      });

      const deactivatedPatients =
        response?.data?.deactivatePatients.deactivatedPatients;

      if (patientsObject && deactivatedPatients?.length > 0) {
        const deactivatedProgramIds = deactivatedPatients.map(
          (patient) => patient.programInstanceId,
        );
        await onDeactivation?.(deactivatedProgramIds);
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="An error occurred while deactivating patients."
          level="error"
        />
      ));
    } finally {
      setLoading(false);
      closeAndResetModal();
    }
  };

  const handleErrors: SubmitErrorHandler<DeactivatePatientsFormData> = (
    errors,
  ) => {
    console.log('Error submitting form:', errors);
  };

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const handleKeepPatient = (patient: PatientTableDataFragment) => {
    removePatient(
      patients.findIndex(
        (p) => p.programInstanceId === patient.programInstanceId,
      ),
    );
    appendPatientToKeep(patient);
  };

  const handleRemovePatient = (patient: PatientTableDataFragment) => {
    removePatientToKeep(
      patientsToKeep.findIndex(
        (p) => p.programInstanceId === patient.programInstanceId,
      ),
    );
    appendPatient(patient);
  };

  const navigateToSubscribe = () => {
    navigate('/subscribe', {
      state: {
        stripeCheckoutPortalWebRedirectPath: stripLeadingSlash(
          location.pathname,
        ),
      },
    });
  };

  const maximumPatientsToKeepSelected =
    watchPatientsToKeep.length >= clientSeats;

  return (
    <Modal
      name="TrialEndDeactivatePatientsModal"
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      hasPadding={false}
      hasCloseRow={false}
      fetching={loading}
      width="large"
    >
      <FormProvider {...deactivatePatientsForm}>
        <form
          className="flex max-h-[80vh] min-h-[80vh] w-full flex-row items-stretch"
          onSubmit={handleSubmit(onSubmit, handleErrors)}
        >
          <div className="flex max-h-full min-h-full w-1/2 flex-col overflow-hidden p-4">
            <div className="flex h-32 w-full flex-row items-center justify-center py-8 px-12">
              <div className="text-caption text-neutral-125">
                <span
                  onClick={navigateToSubscribe}
                  className="cursor-pointer text-green-100 underline"
                >
                  Upgrade
                </span>{' '}
                to a paid plan to keep all your clients active. On the free
                plan, you can keep {clientSeats} clients active while the rest
                will be archived.
              </div>
            </div>

            <div className="flex-grow overflow-hidden px-12 pb-8">
              <div className="mb-5 flex w-full flex-row items-center justify-between">
                <div className="font-serif text-subtitle-small">
                  Existing clients
                </div>
                <PatientCount
                  countTitle="Total"
                  count={watchPatients.length.toString()}
                />
              </div>

              <ul className="no-scrollbar max-h-full w-full overflow-y-scroll">
                {watchPatients.map((patient) => (
                  <SelectPatientRow
                    key={patient.programInstanceId}
                    patient={patient}
                    onKeep={handleKeepPatient}
                    disabled={maximumPatientsToKeepSelected}
                  />
                ))}
              </ul>
            </div>
          </div>

          <div className="flex max-h-full min-h-full w-1/2 flex-col overflow-hidden bg-neutral-50 p-4">
            <div className="flex h-32 w-full flex-row items-start justify-end">
              <Button
                onClick={setClosed}
                size="small"
                title="Close"
                iconPosition="left"
                theme="secondary-white"
                IconComponent={XIcon}
              />
            </div>

            <div className="flex flex-grow flex-col justify-between overflow-hidden px-12 pb-8">
              <div className="flex w-full flex-grow flex-col items-start justify-start overflow-hidden pb-4">
                <div className="mb-5 flex w-full flex-row items-center justify-between">
                  <div className="font-serif text-small">Clients to keep</div>
                  <PatientCount
                    countTitle="Active"
                    count={`${watchPatientsToKeep.length}/${clientSeats}`}
                  />
                </div>

                <ul className="no-scrollbar max-h-full w-full overflow-y-scroll">
                  {watchPatientsToKeep.map((patient) => (
                    <SelectPatientRow
                      key={patient.programInstanceId}
                      patient={patient}
                      onRemove={handleRemovePatient}
                    />
                  ))}
                </ul>
              </div>

              <div className="flex w-full flex-col items-end justify-start">
                <Button
                  title="Confirm"
                  disabled={!maximumPatientsToKeepSelected}
                  className="mb-6 w-36"
                  onClick={handleSubmit(onSubmit, handleErrors)}
                />
                <div className="flex flex-row items-center justify-start text-small-caption text-neutral-125">
                  <SparklesIcon className="mr-2 h-6 w-6 flex-shrink-0" />
                  <div>
                    Details for archived clients will remain saved, and you can
                    reactivate them to work with or access their details again.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default TrialEndDeactivatePatientsModal;
