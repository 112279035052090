import { FC, ReactElement } from 'react';

import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline';
import IconButton from '../../components/IconButton';
import Button from '../../components/Button';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

const IntroTransitionDiv: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Transition
    appear={true}
    show={true}
    enter="transition-opacity duration-1000 ease-in"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-1000 ease-in"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
);

export interface IntroStepProps {
  nextStep: () => void;
  prevStep: () => void;
  isFinalStep?: boolean;
  stepCount: number;
  stepTitle: string | ReactElement;
  stepDescription: string | ReactElement;
  stepImage?: string;
  buttonElement?: (nextStep: () => void) => ReactElement;
  imageClassNames?: string;
}

const IntroStep: FC<IntroStepProps> = ({
  nextStep,
  prevStep,
  isFinalStep,
  stepCount,
  stepTitle,
  stepDescription,
  stepImage,
  buttonElement,
  imageClassNames,
}) => {
  // Expects Welcome Step is 0. If not, change this.
  const canGoBack = stepCount > 1;
  const canGoForward = !isFinalStep;

  return (
    <div className="flex h-screen flex-col items-center justify-start overflow-hidden px-10 lg:justify-between">
      <div className="flex flex-col items-center">
        <div className="mt-12 mb-1 text-center text-caption font-light text-neutral-125">
          <IntroTransitionDiv>{stepCount} of 5</IntroTransitionDiv>
        </div>
        <div className="mb-4 text-center font-serif text-title-medium font-light text-green-125">
          <IntroTransitionDiv>{stepTitle}</IntroTransitionDiv>
        </div>
        <div className="relative mb-6 max-w-[600px]">
          {canGoBack && (
            <IconButton
              aria-label="Carousel step back"
              IconComponent={ArrowNarrowLeftIcon}
              iconClassName="h-6 w-6 text-neutral-125"
              className="invisible absolute -left-[10%] flex h-10 w-10 items-center justify-center !rounded-full shadow-400 sm:visible lg:-left-1/4"
              onClick={prevStep}
            />
          )}
          {canGoForward && (
            <IconButton
              aria-label="Carousel step forward"
              IconComponent={ArrowNarrowRightIcon}
              iconClassName="h-6 w-6 text-neutral-125"
              className="invisible absolute -right-[10%] flex h-10 w-10 items-center justify-center !rounded-full shadow-400 sm:visible lg:-right-1/4"
              onClick={nextStep}
            />
          )}
          <div className="text-center text-paragraph text-neutral-125">
            <IntroTransitionDiv>{stepDescription}</IntroTransitionDiv>
          </div>
        </div>
        <IntroTransitionDiv>
          {(buttonElement && buttonElement(nextStep)) ?? (
            <Button
              theme={isFinalStep ? 'primary' : 'secondary'}
              title={isFinalStep ? 'Welcome to Homecoming' : 'Next'}
              className="mb-6 sm:mb-12"
              onClick={nextStep}
            />
          )}
        </IntroTransitionDiv>
      </div>
      {stepImage && (
        <div className="max-w-[1600px] lg:w-[75%]">
          <IntroTransitionDiv>
            <img
              src={stepImage}
              alt={`Intro Step ${stepCount}`}
              className={classNames('object-cover', imageClassNames)}
            />
          </IntroTransitionDiv>
        </div>
      )}
    </div>
  );
};

export default IntroStep;
