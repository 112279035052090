import { FC } from 'react';

const PractitionerInfoContainer: FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <div className="relative flex flex-col space-y-6 px-6 pb-6 pt-[4.5rem] md:flex-row md:space-y-0">
    {children}
  </div>
);

export default PractitionerInfoContainer;
