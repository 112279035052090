import { FC } from 'react';
import classNames from 'classnames';

import { SvgIconComponent } from '../types/svgs';

import Button from './Button';
import { PlusSmIcon } from '@heroicons/react/outline';

const EmptyProfileTab: FC<{
  patientFirstName: string;
  IconComponent: SvgIconComponent;
  titleText: string;
  subTitleText: string;
  iconClassName: string;
  onClickButton?: () => void;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  className?: string;
  ButtonComponent?: React.ReactNode;
}> = ({
  patientFirstName,
  IconComponent,
  titleText,
  subTitleText,
  iconClassName,
  onClickButton,
  buttonTitle,
  buttonDisabled,
  className,
  ButtonComponent,
}) => {
  return (
    <div
      className={classNames(
        'flex h-[calc(100vh-320px)] w-full flex-col items-center pt-16',
        className,
      )}
    >
      <IconComponent className={classNames('h-20', iconClassName)} />
      <span className="mt-5 mb-1 text-center text-body font-bold text-green-150">
        {`${patientFirstName}'s ${titleText}`}
      </span>
      <span className="w-[450px] text-center text-caption font-medium text-neutral-125/80">
        {subTitleText}
      </span>
      {onClickButton && (
        <Button
          theme="secondary"
          size="small"
          className="mt-4 px-6 font-bold"
          title={buttonTitle}
          onClick={onClickButton}
          disabled={buttonDisabled}
          IconComponent={PlusSmIcon}
          iconClassName="text-green-100"
        />
      )}
      {ButtonComponent && ButtonComponent}
    </div>
  );
};

export default EmptyProfileTab;
