import { SvgIconComponent } from '../types/svgs';

const ChevronRight: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5858 10.586C15.3668 11.3671 15.3668 12.6334 14.5858 13.4144L9.99 18.0102L8.576 16.5962L12.4689 12.7033C12.8594 12.3128 12.8594 11.6797 12.4689 11.2891L8.576 7.39623L9.99 5.99023L14.5858 10.586Z" />
    </svg>
  );
};

export default ChevronRight;
