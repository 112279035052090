import {
  ProviderUserMemberDataFragment,
  ProviderUserRole,
} from '../../generated/graphql';

export const sortProviderUserMembers = (
  members: ProviderUserMemberDataFragment[],
) => {
  return members?.sort((a, b) => {
    if (a.role === ProviderUserRole.Owner) {
      return -1;
    } else if (b.role === ProviderUserRole.Owner) {
      return 1;
    }
    if (a.deactivatedAt && b.deactivatedAt) {
      return (
        new Date(b.deactivatedAt).getTime() -
        new Date(a.deactivatedAt).getTime()
      );
    }
    if (a.registeredAt) {
      return -1;
    }
    if (b.registeredAt) {
      return 1;
    }
    if (a.deactivatedAt) {
      return 1;
    }
    if (b.deactivatedAt) {
      return -1;
    }
    return 0;
  });
};
