import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';

export const ScheduleAssessmentModuleContainer: React.FC<{
  title: string;
  children: ReactNode;
  hasUnsavedChanges?: boolean;
}> = ({ title, children, hasUnsavedChanges = false }) => (
  <div className="flex flex-col items-start justify-start text-green-150 sm:max-w-[500px] xl:w-1/2 xl:min-w-[339px] xl:max-w-[440px]">
    <div className="mb-3 flex w-full flex-row items-center justify-between">
      <span className="font-serif text-subtitle-small">
        <span>{title}</span>
      </span>
      {hasUnsavedChanges && (
        <div className="flex flex-row items-center pr-1">
          <span className="mr-1 text-right text-caption font-bold text-orange-100">
            Unsaved changes
          </span>
          <ExclamationCircleIcon className="h-5 w-5 text-orange-100" />
        </div>
      )}
    </div>
    <div className="flex flex-col items-center justify-start rounded-xl bg-white shadow-400 sm:w-[340px] xl:w-full">
      {children}
    </div>
  </div>
);
