import { useEffect, useRef, useState } from 'react';

import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';

import {
  CommentDataFragment,
  useMarkCommentsReadMutation,
  useProgramCommentsLazyQuery,
} from '../../../../generated/graphql';

import { defaultTransitionProps } from '../../../lib/animation';

import Spinner from '../../../svgs/Spinner';
import ToastAlert from '../../../components/ToastAlert';

import { usePatientProfileContext } from '..';
import Chat from '../../../components/Comments/Chat';
import { useAuth } from '../../../../contexts/AuthContext';
import classNames from 'classnames';

const Messages = () => {
  const params = useParams();
  const programId = params.programId!;
  const { patient, refetchPatient } = usePatientProfileContext();
  const { showUpgradeBanner } = useAuth();

  const [
    programCommentsQuery,
    { error: programCommentsError, loading: programCommentsLoading },
  ] = useProgramCommentsLazyQuery({
    variables: {
      programId,
    },
  });

  const [programComments, setProgramComments] = useState<CommentDataFragment[]>(
    [],
  );
  const [lastCommentDatetime, setLastCommentDatetime] = useState<Date>(
    new Date(),
  );

  const endOfMessagesFeedRef = useRef<boolean>(false);
  const [firstChunkFetched, setFirstChunkFetched] = useState<boolean>(false);

  const getNextChunkOfComments = async () => {
    if (endOfMessagesFeedRef.current || programCommentsLoading) {
      return;
    }
    const { data } = await programCommentsQuery({
      variables: {
        programId,
        before: lastCommentDatetime.toISOString(),
      },
    });
    setFirstChunkFetched(true);
    const newComments = data?.patient.comments ?? [];
    if (newComments.length > 0) {
      // If it is the first render, then just set the comments outright instead of prepending.
      // This helps prevent a double prepend on initial load while in React.StrictMode
      // in development due to the double render.
      if (programComments.length === 0) {
        setProgramComments(newComments);
      } else {
        setProgramComments((prevComments) => [...newComments, ...prevComments]);
      }
      setLastCommentDatetime(new Date(newComments[0].createdAt));
    } else {
      endOfMessagesFeedRef.current = true;
    }
  };

  useEffect(() => {
    if (programId) {
      getNextChunkOfComments();
    }
  }, [programId]);

  const onCommentAdded = async (newComment?: CommentDataFragment) => {
    if (!newComment) {
      return;
    }
    setProgramComments((prevComments) => [...prevComments, newComment]);
  };

  const onCommentDeleted = async (commentId?: string) => {
    if (!commentId) {
      return;
    }
    setProgramComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== commentId),
    );
  };

  useEffect(() => {
    if (programCommentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch messages."
          level="error"
        />
      ));
    }
  }, [programCommentsError]);

  const [markCommentsReadMutation] = useMarkCommentsReadMutation();

  useEffect(() => {
    const markMessagesRead = async () => {
      if (programId) {
        await markCommentsReadMutation({
          variables: {
            input: {
              programId,
            },
          },
        });
        await refetchPatient?.();
      }
    };

    markMessagesRead();
  }, [programId]);

  return (
    <>
      <Transition
        show={firstChunkFetched}
        {...defaultTransitionProps}
        className="-mt-6 flex h-full w-full flex-row items-start border-neutral-75"
      >
        <Chat
          patient={patient}
          comments={programComments}
          chatHeader={null}
          onCommentAdded={onCommentAdded}
          onCommentDeleted={onCommentDeleted}
          getNextChunkOfComments={getNextChunkOfComments}
          areCommentsLoading={programCommentsLoading}
          className={classNames(
            'pt-4',
            !showUpgradeBanner ? 'max-h-[700px]' : 'max-h-[644px]',
          )}
        />
      </Transition>

      {programCommentsLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default Messages;
