export enum AssessmentQuestionItemType {
  MultipleChoiceScoredAssessmentQuestion = 'MultipleChoiceScoredAssessmentQuestion',
  MultipleChoiceScoredAssessmentAnswer = 'MultipleChoiceScoredAssessmentAnswer',
  MultipleChoiceScoredAssessmentQuestionAnswerOption = 'MultipleChoiceScoredAssessmentQuestionAnswerOption',
}

export enum AssessmentCompletionDataTypename {
  MultipleChoiceSumScoredAssessmentCompletionData = 'MultipleChoiceSumScoredAssessmentCompletionData',
  HADSAssessmentCompletionData = 'HADSAssessmentCompletionData',
}
