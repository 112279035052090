import classNames from 'classnames';
import { FC } from 'react';
import { getTimeStamp } from '../../../../lib/date';

const ScheduleItem: FC<{
  shortName: string;
  startDate: string;
  completedAt?: string;
  notifiedAt?: string;
  dismissedAt?: string;
  isFirstAssessment: boolean;
  isLastAssessment?: boolean;
}> = ({
  shortName,
  startDate,
  completedAt,
  notifiedAt,
  dismissedAt,
  isFirstAssessment,
  isLastAssessment = false,
}) => {
  return (
    <div
      className={classNames(
        'w-full',
        !isLastAssessment && 'border-b border-neutral-50',
      )}
    >
      <div
        className={classNames(
          'flex flex-row items-start justify-start py-4',
          isFirstAssessment && 'pt-1',
          isLastAssessment && 'pb-0',
        )}
      >
        <div className="flex w-full flex-col items-start justify-start text-green-150">
          <span className="mb-1 text-small-label font-bold">
            {getTimeStamp(startDate, false)}
          </span>
          <span className="text-body">{shortName}</span>
          {((notifiedAt && !completedAt) || completedAt || dismissedAt) && (
            <span className="mt-1 text-small-label text-green-125">
              {notifiedAt &&
                !completedAt &&
                `Notified on ${getTimeStamp(notifiedAt, false)}`}
              {completedAt &&
                `Completed on ${getTimeStamp(completedAt, false)}`}
              {dismissedAt &&
                `Dismissed on ${getTimeStamp(dismissedAt, false)}`}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleItem;
