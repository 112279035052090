import { useState } from 'react';
import { Transition } from '@headlessui/react';

import { useProgramTemplatesQuery } from '../../../generated/graphql';

import Button from '../../components/Button';
import Alert from '../../components/Alert';
import TableTitle from '../../components/TableTitle';
import PageContainer from '../../components/Containers/PageContainer';

import { defaultEnterTransitionProps } from '../../lib/animation';

import Spinner from '../../svgs/Spinner';
import Plus from '../../svgs/Plus';

import ProgramTable from './ProgramTable';
import CreateProgramModal from './CreateProgramModal';

const ProgramManagement = () => {
  const {
    data: programsData,
    loading: isLoadingProgramTemplates,
    refetch: refetchProgramTemplates,
  } = useProgramTemplatesQuery({});

  const [isCreateProgramModalOpen, setCreateProgramModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const programTemplates = programsData?.programTemplates || null;

  return (
    <PageContainer>
      <Transition
        show={Boolean(programTemplates)}
        {...defaultEnterTransitionProps}
      >
        <TableTitle title="Your programs">
          <Button
            title="Add program"
            IconComponent={Plus}
            onClick={() => setCreateProgramModalOpen(true)}
          />
          <CreateProgramModal
            isModalOpen={isCreateProgramModalOpen}
            setClosed={() => setCreateProgramModalOpen(false)}
            onSuccess={() => {
              setAlertMessage('Your new program was created successfully.');
              refetchProgramTemplates();
            }}
          />
        </TableTitle>
        {alertMessage && (
          <div className="my-4">
            <Alert message={alertMessage} level="success" />
          </div>
        )}
        <ProgramTable programTemplates={programTemplates} />
      </Transition>
      {isLoadingProgramTemplates && <Spinner className="mx-auto mt-4" />}
    </PageContainer>
  );
};

export default ProgramManagement;
