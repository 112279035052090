import { Fragment, useState } from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';

import {
  XIcon,
  MenuIcon,
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
  MailIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';

import {
  ScheduledEventQuery,
  CalendarScheduledEventFragment,
  CalendlyEventDataFragment,
  ResendPatientInvitesMutationFn,
  UnlinkInviteeEmailFromProgramMutationFn,
} from '../../../../../generated/graphql';

import { ContextMenuPosition } from '../../../../types/contextMenu';

import Spinner from '../../../../svgs/Spinner';
import ArrowRightLong from '../../../../svgs/ArrowRightLong';

import {
  GetScheduledEvent,
  RefetchScheduledEventsData,
} from '../../../../types/scheduled-event';

import {
  CALENDLY_SCHEDULED_EVENTS_URL,
  ConferenceLocationTypeMap,
  getFirstInviteeData,
  isConferenceLocation,
  isNonConferenceLocation,
} from '../../../../lib/scheduled-events';
import { displayTime } from '../../../../lib/copy';
import { getRelativeTimestamp, getTimeStamp } from '../../../../lib/time';

import Button from '../../../../components/Button';
import Avatar from '../../../../components/Avatar';
import IconButton from '../../../../components/IconButton';
import ToastAlert from '../../../../components/ToastAlert';

import {
  MENU_OFFSET_Y_PROPORTION,
  MENU_OFFSET_X_PROPORTION,
} from '../../../../lib/window';

import { getDifferenceInMinutes } from '../helpers';
import ClientSyncMenu from './ClientSyncMenu';
import { useNavigate } from 'react-router-dom';

export const TIME_INCREMENT_UNIT = 5;
export const TIME_INCREMENT_OFFSET = 2;

const HEIGHT_PADDING = 10;

const ScheduledEventItem: React.FC<{
  scheduledEvent: CalendarScheduledEventFragment;
  selectedScheduledEvent?: CalendarScheduledEventFragment;
  setSelectedScheduledEvent: React.Dispatch<
    React.SetStateAction<CalendarScheduledEventFragment | undefined>
  >;
  columnClassIndex: Record<string, string>;
  getScheduledEvent: GetScheduledEvent;
  isLoadingScheduledEvent: boolean;
  scheduledEventData?: ScheduledEventQuery;
  setConfirmCancelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsInviteCalendlyInviteeToHomecomingModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsSyncCalendlyInviteeModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  calendlyEventTypes?: CalendlyEventDataFragment[];
  resendPatientInvitesMutation: ResendPatientInvitesMutationFn;
  refetchScheduledEventsData: RefetchScheduledEventsData;
  unlinkInviteeEmailToProgramMutation: UnlinkInviteeEmailFromProgramMutationFn;
}> = ({
  scheduledEvent,
  selectedScheduledEvent,
  setSelectedScheduledEvent,
  columnClassIndex,
  scheduledEventData,
  isLoadingScheduledEvent,
  getScheduledEvent,
  setConfirmCancelModalOpen,
  setIsInviteCalendlyInviteeToHomecomingModalOpen,
  setIsSyncCalendlyInviteeModalOpen,
  calendlyEventTypes,
  resendPatientInvitesMutation,
  refetchScheduledEventsData,
  unlinkInviteeEmailToProgramMutation,
}) => {
  const navigate = useNavigate();

  const [scheduledEventMenuPosition, setScheduledEventMenuPosition] =
    useState<ContextMenuPosition>({ x: 0, y: 0 });
  const [isScheduledEventMenuOpen, setIsScheduledEventMenuOpen] =
    useState(false);

  const screenHeight = document.body.scrollHeight;
  const screenWidth = document.body.scrollWidth;

  const menuPositionXRight = Boolean(
    screenWidth * MENU_OFFSET_X_PROPORTION < scheduledEventMenuPosition.x,
  );
  const menuPositionYAbove = Boolean(
    screenHeight * MENU_OFFSET_Y_PROPORTION < scheduledEventMenuPosition.y,
  );

  const completeScheduledEvent = scheduledEventData?.scheduledEvent;

  const isUpcomingEvent =
    DateTime.fromISO(scheduledEvent.endTime as string) > DateTime.now();

  const startTimeFormatted = DateTime.fromISO(
    scheduledEvent.startTime as string,
  );

  const endTimeFormatted = DateTime.fromISO(scheduledEvent.endTime as string);

  const eventDurationInMinutes = getDifferenceInMinutes(
    startTimeFormatted,
    endTimeFormatted,
  );

  const dayInMinutes = getDifferenceInMinutes(
    startTimeFormatted.startOf('day'),
    startTimeFormatted,
  );

  const invitee = getFirstInviteeData(scheduledEvent);

  const completeInvitee = completeScheduledEvent?.invitees?.[0];
  const clientEmail = completeInvitee?.email;
  const clientQuestionAndAnswers = completeInvitee?.questionsAndAnswers;

  const conferenceLocationJoinUrl =
    completeScheduledEvent?.location &&
    isConferenceLocation(completeScheduledEvent?.location) &&
    completeScheduledEvent?.location.joinUrl;

  const nonConferenceLocation =
    completeScheduledEvent?.location &&
    isNonConferenceLocation(completeScheduledEvent?.location) &&
    completeScheduledEvent?.location.location;

  const handleCloseEventDetail = (unselectAll = false) => {
    if (unselectAll) setSelectedScheduledEvent(undefined);
    setIsScheduledEventMenuOpen(false);
  };

  const handleClickScheduledEventCard = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedScheduledEvent(scheduledEvent);

    if (!isScheduledEventMenuOpen) {
      setScheduledEventMenuPosition({
        x: e.pageX,
        y: e.pageY,
      });
    }

    setIsScheduledEventMenuOpen(true);

    try {
      await getScheduledEvent({
        variables: {
          calendlyEventUri: scheduledEvent.calendlyEventUri,
        },
      });
    } catch (error) {
      setIsScheduledEventMenuOpen(false);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message="Error fetching scheduled event details"
        />
      ));
    }
    e.stopPropagation();
  };

  const eventBackgroundColor = calendlyEventTypes?.find(
    (calendlyEventType) =>
      calendlyEventType.calendlyEventTypeUri ===
      scheduledEvent.calendlyEventTypeUri,
  )?.color;

  const [resendInviteLoading, setResendInviteLoading] = useState(false);

  const [inviteResent, setInviteResent] = useState(false);

  const handleInviteOrResendInviteToInvitee = async () => {
    if (!invitee.onboardingLastProgramInviteSentAt) {
      setIsInviteCalendlyInviteeToHomecomingModalOpen(true);
    } else {
      try {
        setResendInviteLoading(true);
        const response = await resendPatientInvitesMutation({
          variables: {
            input: { programIds: [invitee.programInstanceId!] },
          },
        });
        const updatedPatients =
          response?.data?.resendPatientInvites?.updatedPatients;

        if (updatedPatients && updatedPatients?.length > 0) {
          await getScheduledEvent({
            variables: {
              calendlyEventUri: scheduledEvent.calendlyEventUri,
            },
          });
          setInviteResent(true);
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Homecoming invite resent to ${invitee.firstName}.`}
            />
          ));
        }
      } catch (err) {
        console.error(err);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      } finally {
        setResendInviteLoading(false);
      }
    }
  };

  const handleSyncCalendlyInviteeWithProgram = async () => {
    setIsSyncCalendlyInviteeModalOpen(true);
  };

  const [undoSyncLoading, setUndoSyncLoading] = useState(false);

  const handleUndoSync = async () => {
    const inviteeEmail = scheduledEvent.invitees?.[0].email;

    if (inviteeEmail) {
      try {
        setUndoSyncLoading(true);

        const response = await unlinkInviteeEmailToProgramMutation({
          variables: {
            inviteeEmail,
            programId: invitee.programInstanceId!,
          },
        });

        const unlinkInviteeEmailToProgramSuccess =
          response?.data?.unlinkInviteeEmailFromProgram;

        if (unlinkInviteeEmailToProgramSuccess) {
          await refetchScheduledEventsData();
          await getScheduledEvent({
            variables: {
              calendlyEventUri: scheduledEvent.calendlyEventUri,
            },
          });
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message="Successfully unlinked invitee email with existing client account."
            />
          ));
        }
      } catch (err) {
        console.log(err);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="error"
            message="Couldn't unlink invitee email with existing client account."
          />
        ));
      } finally {
        setUndoSyncLoading(false);
      }
    }
  };

  const startEndTime = `${getTimeStamp(
    completeScheduledEvent?.startTime as string,
    false,
  )} at ${displayTime(startTimeFormatted, 'h:mm')}–${displayTime(
    endTimeFormatted,
    't',
  )}`;

  const smallScheduledItem = eventDurationInMinutes < 30;

  return (
    <Menu as={Fragment}>
      <Menu.Button as={Fragment}>
        <li
          onClick={handleClickScheduledEventCard}
          className={classNames(
            'relative mt-px flex cursor-pointer',
            columnClassIndex[startTimeFormatted.day],
          )}
          style={{
            gridRow: `${
              TIME_INCREMENT_OFFSET + dayInMinutes / TIME_INCREMENT_UNIT
            } / span ${eventDurationInMinutes / TIME_INCREMENT_UNIT}`,
          }}
        >
          <div
            className={classNames(
              'group absolute inset-0 flex flex-col overflow-y-hidden rounded-md px-2 text-caption leading-4 text-white transition-colors duration-300',
              !calendlyEventTypes && 'border-netural-75 border',
              !eventBackgroundColor && 'bg-green-125',
              !smallScheduledItem && 'py-2',
            )}
            {...(eventBackgroundColor && {
              style: { backgroundColor: eventBackgroundColor },
            })}
          >
            <p className="font-medium">{invitee.fullName}</p>
            <p>{`${displayTime(startTimeFormatted, 'h:mm')}–${displayTime(
              endTimeFormatted,
              't',
            )}`}</p>
          </div>
        </li>
      </Menu.Button>
      <Transition
        as={Fragment}
        show={
          isScheduledEventMenuOpen &&
          scheduledEvent.calendlyEventUri ===
            selectedScheduledEvent?.calendlyEventUri
        }
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            ...(menuPositionXRight
              ? {
                  right: screenWidth - scheduledEventMenuPosition.x,
                }
              : {
                  left: scheduledEventMenuPosition.x + HEIGHT_PADDING,
                }),
            ...(menuPositionYAbove
              ? {
                  bottom: screenHeight - scheduledEventMenuPosition.y,
                }
              : {
                  top: scheduledEventMenuPosition.y + HEIGHT_PADDING,
                }),
          }}
          className={classNames(
            'absolute z-[60] mt-2 divide-y divide-neutral-50 rounded-md bg-white shadow-400 focus:outline-none',
            menuPositionXRight && 'right-0 origin-top-right',
            !menuPositionXRight && 'left-0 origin-top-left',
            isLoadingScheduledEvent && 'p-4',
          )}
          static
        >
          {isLoadingScheduledEvent ? (
            <Spinner className="h-5 w-5 text-neutral-125" />
          ) : (
            <div
              className="max-w-96 min-w-96 flex flex-col justify-start overflow-y-scroll px-6 pt-4 pb-6"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex w-full flex-row items-center justify-end">
                {invitee.programId && (
                  <ClientSyncMenu
                    onClickGoToProfile={() => {
                      navigate(`/clients/${invitee.programInstanceId}`);
                    }}
                    onClickUndoSync={handleUndoSync}
                    undoSyncLoading={undoSyncLoading}
                    showUndoSync={invitee.inviteeEmail !== invitee.programEmail}
                  />
                )}
                <IconButton
                  aria-label="Close event"
                  IconComponent={XIcon}
                  className="ml-1 h-7 w-7"
                  onClick={() => handleCloseEventDetail(true)}
                />
              </div>
              <div
                className="flex cursor-pointer flex-row items-center justify-start"
                onClick={() => {
                  if (invitee.programId) {
                    navigate(`/clients/${invitee.programInstanceId}`);
                  }
                }}
              >
                <Avatar
                  size="medium-plus"
                  imageUrl={invitee.profileImageUrl}
                  name={invitee.fullName}
                />
                <div className="ml-4 flex flex-col items-start justify-start">
                  <div className="text-category font-medium text-neutral-150">
                    {invitee.fullName}
                  </div>
                  <div className="text-caption text-neutral-125">
                    {completeScheduledEvent?.name}
                  </div>
                </div>
              </div>

              {!invitee.programId && (
                <div
                  className={classNames(
                    'mt-4 flex w-full flex-col items-start justify-start rounded-lg border px-4 py-3',
                    !inviteResent
                      ? 'border-transparent bg-orange-25/60'
                      : 'border-neutral-100',
                  )}
                >
                  <div className="mb-2 text-caption text-neutral-150">
                    {!invitee.onboardingLastProgramInviteSentAt
                      ? 'This client is not on Homecoming'
                      : `Invited to Homecoming ${getRelativeTimestamp(
                          invitee.onboardingLastProgramInviteSentAt,
                        )}`}
                  </div>
                  <div className="flex flex-col items-start justify-start">
                    {!invitee.onboardingLastProgramInviteSentAt && (
                      <Button
                        theme="alt"
                        title="Link with an existing account"
                        size="small"
                        IconComponent={SwitchHorizontalIcon}
                        iconPosition="left"
                        className="mb-1.5 px-0 py-0 text-neutral-125 hover:text-neutral-150"
                        onClick={handleSyncCalendlyInviteeWithProgram}
                      />
                    )}
                    <Button
                      theme="alt"
                      title={
                        !invitee.onboardingLastProgramInviteSentAt
                          ? `Send Homecoming invite to ${invitee.firstName}`
                          : `Resend invite to ${invitee.firstName}`
                      }
                      size="small"
                      IconComponent={!resendInviteLoading ? MailIcon : Spinner}
                      iconPosition="left"
                      className="px-0 py-0 text-neutral-125 hover:text-neutral-150 disabled:text-neutral-110"
                      onClick={handleInviteOrResendInviteToInvitee}
                      disabled={inviteResent}
                    />
                  </div>
                </div>
              )}

              <div className="mt-5 flex max-h-[400px] flex-col justify-start overflow-y-scroll">
                {startEndTime && (
                  <div className="mb-4 flex flex-row items-start justify-start">
                    <CalendarIcon className="mr-5 h-5 w-5 text-neutral-150" />
                    <div className="text-caption text-neutral-150">
                      {startEndTime}
                    </div>
                  </div>
                )}
                <div className="mb-4 flex flex-row items-start justify-start">
                  <UsersIcon className="mr-5 h-5 w-5 text-neutral-150" />
                  <div className="text-caption text-neutral-150">
                    {clientEmail}
                  </div>
                </div>
                {(conferenceLocationJoinUrl || nonConferenceLocation) && (
                  <div className="mb-4 flex flex-row items-start justify-start">
                    <LocationMarkerIcon className="mr-5 h-5 w-5 text-neutral-150" />
                    <div className="truncate text-caption text-neutral-150">
                      {conferenceLocationJoinUrl && (
                        <Button
                          title={`Link to ${
                            ConferenceLocationTypeMap[
                              completeScheduledEvent?.location.type
                            ]
                          } meeting`}
                          theme="alt"
                          size="small"
                          onClick={() =>
                            window.open(conferenceLocationJoinUrl, '_blank')
                          }
                          IconComponent={ArrowRightLong}
                          iconClassName="text-blue-125 hover:text-blue-150"
                          className="px-0 py-0 text-blue-125 hover:text-blue-150"
                          noFocus
                        />
                      )}
                      {nonConferenceLocation && (
                        <div className="text-caption text-neutral-150">
                          {nonConferenceLocation}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {Boolean(clientQuestionAndAnswers?.length) && (
                  <div className="flex flex-row items-start justify-start">
                    <MenuIcon className="mr-5 h-5 w-5 text-neutral-150" />
                    <div className="truncate text-caption text-neutral-150">
                      {clientQuestionAndAnswers?.map(
                        (questionAnswer, index) => (
                          <div
                            className="mb-3 flex w-80 flex-col items-start justify-start"
                            key={`${index}-${questionAnswer.answer}`}
                          >
                            <div className="mb-0.5 whitespace-pre-wrap">
                              {questionAnswer.question}
                            </div>
                            <div className="whitespace-pre-wrap font-medium">
                              {questionAnswer.answer}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
              {isUpcomingEvent && (
                <div className="mt-4 flex flex-row items-start justify-start">
                  <Button
                    theme="alt"
                    title="Reschedule in Calendly"
                    size="small"
                    className="mr-4 px-0 py-0 text-neutral-125 hover:text-neutral-150"
                    onClick={() => {
                      window.open(CALENDLY_SCHEDULED_EVENTS_URL, '_blank');
                    }}
                  />
                  <Button
                    theme="alt"
                    title="Cancel event"
                    size="small"
                    className="px-0 py-0 text-red-100 hover:text-red-125"
                    onClick={() => setConfirmCancelModalOpen(true)}
                  />
                </div>
              )}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ScheduledEventItem;
