import { FC } from 'react';
import classNames from 'classnames';
import { ASSESSMENTS_COLOR_MAP } from '../../../lib/colors';

const Score: FC<{
  score?: number;
  categorization?: string;
  color?: string;
  hasPercentage?: boolean;
  feedItem?: boolean;
}> = ({
  score,
  categorization,
  color = 'neutral',
  hasPercentage = false,
  feedItem = false,
}) => {
  if (!color) color = 'neutral';

  const outerColor = ASSESSMENTS_COLOR_MAP?.[color]?.outer;
  const middleColor = ASSESSMENTS_COLOR_MAP?.[color]?.middle;
  const defaultColor = ASSESSMENTS_COLOR_MAP?.[color]?.default;

  return (
    <div className="my-1 flex flex-row items-center justify-start">
      <div
        className={classNames(
          'mr-2 flex flex-row items-center justify-center rounded-full p-2',
          outerColor,
        )}
      >
        <div
          className={classNames(
            'flex flex-row items-center justify-center rounded-full p-2',
            middleColor,
          )}
        >
          <div
            className={classNames(
              'flex flex-row items-center justify-center rounded-full',
              feedItem ? 'text-body' : 'text-big-label',
              feedItem && !hasPercentage && 'h-6 w-6',
              feedItem && hasPercentage && 'h-8 w-8',
              !feedItem && !hasPercentage && 'h-12 w-12',
              !feedItem && hasPercentage && 'h-16 w-16',
              defaultColor,
            )}
          >
            {score}
            {hasPercentage && '%'}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <span
          className={classNames(
            'text-left text-green-150',
            feedItem ? 'text-caption' : 'text-body',
          )}
        >
          {categorization}
        </span>
      </div>
    </div>
  );
};

export default Score;
