import classNames from 'classnames';

type PageContainerProps = {
  children?: React.ReactNode;
  containerClassName?: string;
  noPadding?: boolean;
};

export default function PageContainer({
  children,
  containerClassName,
  noPadding = false,
}: PageContainerProps) {
  return (
    <div
      className={classNames(
        'flex w-full flex-col py-8',
        !noPadding && 'px-14',
        containerClassName,
      )}
    >
      {children}
    </div>
  );
}
