import React from 'react';
import classNames from 'classnames';
import { SignatureUser } from '../../../../../generated/graphql';
import {
  getColorForSignatureUserType,
  SignatureUserTypeLabel,
} from '../../../../lib/multiSignature';
import SignatureInputField from './SignatureInputField';

const SignatureUserInput: React.FC<{
  isSignatureUserInQuestion: boolean;
  signatureUserType: SignatureUser;
}> = ({ isSignatureUserInQuestion, signatureUserType }) => {
  const borderColor = getColorForSignatureUserType(
    isSignatureUserInQuestion ? signatureUserType : 'disabled',
  ).borderColor;

  const backgroundColor = getColorForSignatureUserType(
    isSignatureUserInQuestion ? signatureUserType : 'disabled',
  ).backgroundColor;

  return (
    <div className="pointer-events-none flex w-full items-center justify-between rounded-xl border border-neutral-50">
      <div
        className={classNames(
          'flex flex-col items-start justify-start rounded-l-xl border-l-[14px] p-4',
          borderColor,
        )}
      >
        <div
          className={classNames(
            'mb-4 rounded-xl px-4 py-2 text-body',
            isSignatureUserInQuestion ? 'text-neutral-125' : 'text-neutral-100',
            backgroundColor,
          )}
        >
          {SignatureUserTypeLabel[signatureUserType]}
        </div>
        <div
          className={classNames(
            'mb-3 text-caption font-medium',
            isSignatureUserInQuestion ? 'text-green-150/40' : 'text-neutral-75',
          )}
        >
          {signatureUserType === SignatureUser.PatientUser
            ? 'Client will add their name here to sign electronically'
            : 'Practitioner will add their name and role here to sign electronically'}
        </div>
        <SignatureInputField
          isSignatureUserInQuestion={isSignatureUserInQuestion}
          placeholder="Name"
        />
        {signatureUserType !== SignatureUser.PatientUser && (
          <SignatureInputField
            isSignatureUserInQuestion={isSignatureUserInQuestion}
            placeholder="Role"
            isRoleField
          />
        )}
      </div>
    </div>
  );
};

export default SignatureUserInput;
