import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/Containers/PageContainer';
import Tabs from '../../components/Tabs';

const Settings = () => {
  const tabList = [
    {
      route: 'team',
      name: 'Team',
    },
    {
      route: 'billing',
      name: 'Billing',
    },
    {
      route: '.',
      name: 'Settings',
    },
  ];

  return (
    <PageContainer containerClassName="items-center">
      <Tabs tabList={tabList} containerClassName="mb-8" />
      <Outlet />
    </PageContainer>
  );
};

export default Settings;
