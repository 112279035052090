import { Fragment, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { usePapaParse } from 'react-papaparse';
import { ParseResult } from 'papaparse';
import { CSVLink } from 'react-csv';

import { SpreadsheetData } from '../../../types/PatientManagement';
import ArrowRightLong from '../../../svgs/ArrowRightLong';
import Button from '../../Button';
import ChevronRight from '../../../svgs/ChevronRight';

const SAMPLE_PHONE_NUMBER = '(123) 456-7890';

export const HC_ADD_PATIENTS_TEMPLATE_CSV = [
  ['Name', 'Email', 'Phone Number', 'Tags'],
  [
    'Tim Cayho',
    'tim@homecoming.health',
    SAMPLE_PHONE_NUMBER,
    'Active, Integration',
  ],
  ['Maria Sanchez', 'maria@homecoming.health', SAMPLE_PHONE_NUMBER, 'Former'],
  ['Andy Zhu', 'andy@homecoming.health', SAMPLE_PHONE_NUMBER],
];

const getUniqueRowsByEmail = (spreadsheetData: SpreadsheetData) => {
  const emails = spreadsheetData.map((dataRow) => dataRow.email);

  return spreadsheetData.filter(
    (dataRow, index) =>
      !emails.includes(dataRow.email, index + 1) &&
      dataRow.name &&
      dataRow.email,
  );
};

const UseASpreadsheetMenu: React.FC<{
  setSpreadsheetData: React.Dispatch<React.SetStateAction<SpreadsheetData>>;
  setShowValidationWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationWarningText: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  setSpreadsheetData,
  setShowValidationWarning,
  setValidationWarningText,
}) => {
  const uploadASpreadsheetRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const downloadASpreadsheetRef =
    useRef() as React.MutableRefObject<HTMLSpanElement>;

  const { readString } = usePapaParse();

  function handleUploadSpreadsheet(e: React.ChangeEvent<HTMLInputElement>) {
    const fileList: FileList | null = e?.target?.files;

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const csvString = String(fileReader.result);
      try {
        readString(csvString, {
          worker: true,
          complete: (
            results: ParseResult<[string, string, string, string]>,
          ) => {
            const fieldResults = results.data.slice(1);
            const spreadsheetData = fieldResults.map((dataRow) => {
              return {
                name: dataRow[0],
                email: dataRow[1],
                phoneNumber: dataRow[2],
                tags: dataRow[3]?.split(',').map((tag) => {
                  return { name: tag.trim() };
                }),
              };
            });
            if (spreadsheetData.length > 1) {
              const uniqueRowByEmail = getUniqueRowsByEmail(spreadsheetData);
              if (uniqueRowByEmail.length !== spreadsheetData.length) {
                setValidationWarningText('Duplicate emails removed');
                setShowValidationWarning(true);
              }
              setSpreadsheetData(uniqueRowByEmail);
            }
          },
        });
      } catch (error) {
        setValidationWarningText('Error uploading spreadsheet');
        setShowValidationWarning(true);
      }
    };

    // using readAsBinaryString() to handle large CSVs
    const latestUploadedFile = fileList?.[fileList.length - 1];
    if (latestUploadedFile) fileReader.readAsBinaryString(latestUploadedFile);
  }

  const onFileInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <div className="z-10 flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <Button
            title="Import a spreadsheet"
            theme="secondary"
            IconComponent={ChevronRight}
            iconClassName="rotate-90"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-80 origin-top-right divide-y divide-neutral-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-2">
              <MenuButton
                titleText="Download a spreadsheet"
                subtitleText="Edit a template and export as CSV"
                iconStyle="rotate-90"
                onClick={() => {
                  downloadASpreadsheetRef.current!.click();
                }}
              />
              <MenuButton
                titleText="Upload the edited spreadsheet"
                subtitleText="Upload the CSV from your computer"
                iconStyle="-rotate-90"
                onClick={() => {
                  uploadASpreadsheetRef.current!.click();
                }}
              />
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <input
        ref={uploadASpreadsheetRef}
        type="file"
        accept=".csv"
        className="pointer-events-none absolute opacity-0"
        onChange={handleUploadSpreadsheet}
        onClick={onFileInputClick}
      />
      <CSVLink
        data={HC_ADD_PATIENTS_TEMPLATE_CSV}
        filename="homecoming-client-template.csv"
        className="pointer-events-none absolute opacity-0"
      >
        <span ref={downloadASpreadsheetRef} />
      </CSVLink>
    </div>
  );
};

export default UseASpreadsheetMenu;

const MenuButton: React.FC<{
  titleText: string;
  subtitleText: string;
  iconStyle: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ titleText, subtitleText, iconStyle, onClick }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            active && 'bg-neutral-50 '
          } text-sm group flex w-full items-center rounded-md px-2 py-2 text-green-125`}
          onClick={onClick}
        >
          <ArrowRightLong
            aria-hidden="true"
            className={classNames('mr-2 h-7 w-7 text-green-125', iconStyle)}
          />
          <div className="flex flex-col items-start justify-start">
            <span className="mb-1 text-caption font-bold">{titleText}</span>
            <span className="text-small-caption">{subtitleText}</span>
          </div>
        </button>
      )}
    </Menu.Item>
  );
};
