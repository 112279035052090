export const columnHeaders = [
  {
    field: 'order',
    headerName: '',
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'author',
    headerName: 'Author',
  },
  {
    field: 'select',
    headerName: '',
  },
];

export function getGroupTitleInputId(taskGroupId: string): string {
  return `group-title-${taskGroupId}`;
}
