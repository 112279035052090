import { FC, useState } from 'react';

import toast from 'react-hot-toast';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';

import {
  PatientLifecycleStatusV2,
  PatientNameDataFragment,
  useLinkInviteeEmailToProgramMutation,
  usePatientsV2NamesQuery,
} from '../../../../generated/graphql';

import { PatientRow } from '../../../types/PatientManagement';
import {
  GetScheduledEvent,
  RefetchScheduledEventsData,
} from '../../../types/scheduled-event';

import ArrowRightLong from '../../../svgs/ArrowRightLong';

import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import Button from '../../../components/Button';
import SelectMenu from '../../../components/SelectMenu';
import PatientItem, {
  sortPatientsByAlphabeticalAscending,
} from '../../../components/PatientItem';
import ToastAlert from '../../../components/ToastAlert';

type SyncCalendlyInviteeModalProps = {
  isModalOpen: boolean;
  openInviteCalendlyInviteeToHomecomingModal: () => void;
  setClosed: () => void;
  selectedInviteeData: PatientRow;
  refetchScheduledEventsData: RefetchScheduledEventsData;
  getScheduledEvent: GetScheduledEvent;
  selectedCalendlyEventUri?: string;
};

type InviteeLinkingInfo = {
  patient: PatientNameDataFragment;
};

const SyncCalendlyInviteeModal: FC<SyncCalendlyInviteeModalProps> = ({
  isModalOpen,
  openInviteCalendlyInviteeToHomecomingModal,
  setClosed,
  selectedInviteeData,
  refetchScheduledEventsData,
  getScheduledEvent,
  selectedCalendlyEventUri,
}) => {
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<InviteeLinkingInfo>({
    mode: 'onSubmit',
  });

  const [loading, setLoading] = useState(false);

  const { data: patientsData, loading: patientsLoading } =
    usePatientsV2NamesQuery({
      variables: {
        patientLifecycleStatuses: [PatientLifecycleStatusV2.Active],
      },
    });

  const patientsList = sortPatientsByAlphabeticalAscending(
    patientsData?.patientNames?.filter((patient) => !patient.isTestProgram), // remove opportunity to sync with demo account
  );

  const [linkInviteeEmailToProgramMutation] =
    useLinkInviteeEmailToProgramMutation();

  const handleErrors: SubmitErrorHandler<InviteeLinkingInfo> = (errors) => {
    console.log({ errors });
  };

  const onSubmit: SubmitHandler<InviteeLinkingInfo> = async (formData) => {
    try {
      setLoading(true);
      const response = await linkInviteeEmailToProgramMutation({
        variables: {
          programId: formData.patient.programInstanceId,
          inviteeEmail: selectedInviteeData?.email,
        },
      });

      const linkedInviteeEmailToProgramSuccess =
        response?.data?.linkInviteeEmailToProgram;

      if (linkedInviteeEmailToProgramSuccess) {
        await refetchScheduledEventsData();
        await getScheduledEvent({
          variables: {
            calendlyEventUri: selectedCalendlyEventUri!,
          },
        });
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully linked invitee email with existing client account.`}
          />
        ));
      }
      closeAndResetSyncCalendlyInviteeModal();
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Couldn't link invitee email with existing client account.`}
        />
      ));
      closeAndResetSyncCalendlyInviteeModal();
    }
  };

  const closeAndResetSyncCalendlyInviteeModal = () => {
    setClosed();
    setTimeout(() => {
      setLoading(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      name="SyncCalendlyInvitee"
      isOpen={isModalOpen}
      setClosed={closeAndResetSyncCalendlyInviteeModal}
      fetching={loading}
      width="small"
      overflow="auto"
    >
      <>
        <div className="mt-3 mb-5 flex flex-col items-center justify-center pr-16 pl-10">
          <div className="font-serif text-subtitle-small text-green-150">
            Link with an existing account
          </div>
        </div>
        <form className="flex h-auto w-full flex-col items-center justify-between px-9 pb-4">
          <div className="mb-4 flex h-full w-full flex-col items-center justify-between">
            <Controller
              name="patient"
              control={control}
              defaultValue={undefined}
              rules={{
                required: {
                  value: true,
                  message: 'Choose your client',
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectMenu
                    autofocus
                    hideLabel
                    label="Choose a client"
                    buttonClassName="max-h-12"
                    onChange={onChange}
                    fieldValue={value}
                    disabled={patientsLoading}
                    fieldOptions={patientsList ?? []}
                    SelectOptionComponent={PatientItem}
                    containerClassName="w-full"
                    placeholder={
                      patientsList ? 'Select a client' : 'Loading clients...'
                    }
                  />
                );
              }}
            />
          </div>
          <div className="mb-3 mt-1 flex w-full flex-col items-start justify-start">
            <div className="text-left text-caption text-green-150">
              If this isn’t one of your existing clients,{' '}
              <span
                className="cursor-pointer font-medium text-blue-100 hover:text-blue-125"
                onClick={() => {
                  openInviteCalendlyInviteeToHomecomingModal();
                  setClosed();
                }}
              >
                invite them to Homecoming.
              </span>
            </div>
          </div>
          <div className="mt-3 flex w-full flex-col items-center justify-center">
            <div className="flex w-full flex-row items-center justify-center">
              <Button
                title="Link accounts"
                theme="primary"
                IconComponent={ArrowRightLong}
                onClick={() => {
                  handleSubmit(onSubmit, handleErrors)();
                }}
              />
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default SyncCalendlyInviteeModal;
