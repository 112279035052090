import { FC, useRef, useState } from 'react';
import IconButton from '../IconButton';
import { PlusSmIcon } from '@heroicons/react/outline';
import Avatar from '../Avatar';
import {
  ProviderUserRole,
  useUpdateProviderStorefrontMutation,
  useUploadProviderUserProfileImageMutation,
} from '../../../generated/graphql';
import { useAuth } from '../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';
import Spinner from '../../svgs/Spinner';
import classNames from 'classnames';

const PractitionerBanner: FC<{
  editable?: boolean;
  avatarImageUrl?: string;
  avatarName?: string;
  bannerImageUrl?: string;
  onBannerImageChange?: (bannerImageUrl: string) => Promise<void>;
}> = ({
  editable = false,
  avatarImageUrl,
  avatarName,
  bannerImageUrl,
  onBannerImageChange,
}) => {
  const { authedProviderUser, refreshAuthedProviderUserProfileImage } =
    useAuth();
  const isProviderOwner = authedProviderUser?.role === ProviderUserRole.Owner;

  const [uploadProviderUserProfileImageMutation] =
    useUploadProviderUserProfileImageMutation();
  const [updateProviderStorefrontMutation] =
    useUpdateProviderStorefrontMutation();

  const profileImageFileInputRef = useRef<HTMLInputElement>();
  const bannerImageFileInputRef = useRef<HTMLInputElement>();

  const [isProfileImageUploadLoading, setIsProfileImageUploadLoading] =
    useState(false);
  const [isBannerImageUploadLoading, setIsBannerImageUploadLoading] =
    useState(false);

  const handleProfileImageFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const profileImageFile = e.target.files?.[0];

    if (profileImageFile) {
      try {
        setIsProfileImageUploadLoading(true);

        await uploadProviderUserProfileImageMutation({
          variables: {
            file: profileImageFile,
          },
        });

        await refreshAuthedProviderUserProfileImage();

        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Photo updated`}
          />
        ));
      } catch (err) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      } finally {
        setIsProfileImageUploadLoading(false);
      }
    }
  };

  const handleBannerImageFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const bannerImageFile = e.target.files?.[0];

    if (bannerImageFile) {
      try {
        setIsBannerImageUploadLoading(true);

        const { data } = await updateProviderStorefrontMutation({
          variables: {
            input: {
              bannerImageFile,
            },
          },
        });

        if (
          data?.updateProviderStorefront.bannerImageUrl &&
          onBannerImageChange
        ) {
          await onBannerImageChange(
            data.updateProviderStorefront.bannerImageUrl,
          );
        }

        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Banner updated`}
          />
        ));
      } catch (err) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      } finally {
        setIsBannerImageUploadLoading(false);
      }
    }
  };

  return (
    <div
      className={classNames(
        'relative h-40 rounded-lg bg-cover bg-center',
        !bannerImageUrl &&
          "bg-[url('assets/images/default-webpage-banner.png')]",
      )}
      style={{
        backgroundImage: bannerImageUrl ? `url(${bannerImageUrl})` : undefined,
      }}
    >
      {editable && (
        <>
          <IconButton
            IconComponent={PlusSmIcon}
            className="absolute top-4 right-4 h-10 w-10 justify-center !rounded-full bg-green-100"
            iconClassName="text-white h-6 w-6"
            aria-label="Change banner image"
            disabled={isBannerImageUploadLoading}
            onClick={() => {
              bannerImageFileInputRef.current!.click();
            }}
          />
          {!isBannerImageUploadLoading && (
            <input
              ref={bannerImageFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleBannerImageFileUpload}
            />
          )}
        </>
      )}
      {/* Profile / Avatar component */}
      <div className="absolute left-16 top-8">
        <div className="relative">
          <Avatar
            size="xxl"
            className="border-[6px] border-white"
            imageUrl={avatarImageUrl}
            name={avatarName}
          />
          {editable && isProviderOwner && (
            <>
              <IconButton
                IconComponent={
                  !isProfileImageUploadLoading ? PlusSmIcon : Spinner
                }
                className="absolute bottom-1 right-1 z-10 h-10 w-10 justify-center !rounded-full bg-green-100"
                iconClassName="text-white h-6 w-6"
                aria-label="Change profile image"
                disabled={isProfileImageUploadLoading}
                onClick={() => {
                  profileImageFileInputRef.current!.click();
                }}
              />
              {!isProfileImageUploadLoading && (
                <input
                  ref={profileImageFileInputRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleProfileImageFileUpload}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PractitionerBanner;
