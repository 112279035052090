import {
  ProgramInvoiceDataFragment,
  ProgramInvoiceStatus,
} from '../../../generated/graphql';
import { ColumnHeaderType, ColumnOrder } from '../../types/tables';

export const sortOptions: Record<string, ColumnOrder> = {
  amountAscending: { name: 'amountAscending', isAscending: true },
  amountDescending: { name: 'amountDescending', isDescending: true },
  statusAscending: { name: 'statusAscending', isAscending: true },
  statusDescending: { name: 'statusDescending', isDescending: true },
  nameAlphabeticalAscending: {
    name: 'nameAlphabeticalAscending',
    isAscending: true,
  },
  nameAlphabeticalDescending: {
    name: 'nameAlphabeticalDescending',
    isDescending: true,
  },
  newestCreated: { name: 'newestCreated', isAscending: true },
  oldestCreated: { name: 'oldestCreated', isDescending: true },
  dueDateAscending: { name: 'dueDateAscending', isAscending: true },
  dueDateDescending: { name: 'dueDateDescending', isDescending: true },
};

export const columnHeaders: ColumnHeaderType[] = [
  {
    field: 'amount',
    headerName: 'Amount',
    isSortable: true,
    columnOrders: [
      sortOptions.amountAscending,
      sortOptions.amountDescending,
      sortOptions.newestCreated,
    ],
  },
  {
    field: 'status',
    headerName: 'Status',
    isSortable: true,
    columnOrders: [
      sortOptions.statusAscending,
      sortOptions.statusDescending,
      sortOptions.newestCreated,
    ],
  },
  {
    field: 'stripeInvoiceId',
    headerName: 'Invoice number',
    isSortable: false,
  },
  {
    field: 'client',
    headerName: 'Client',
    isSortable: true,
    columnOrders: [
      sortOptions.nameAlphabeticalAscending,
      sortOptions.nameAlphabeticalDescending,
      sortOptions.newestCreated,
    ],
  },
  {
    field: 'created',
    headerName: 'Created',
    isSortable: true,
    columnOrders: [sortOptions.oldestCreated, sortOptions.newestCreated],
  },
  {
    field: 'dueAt',
    headerName: 'Due',
    isSortable: true,
    columnOrders: [
      sortOptions.dueDateAscending,
      sortOptions.dueDateDescending,
      sortOptions.newestCreated,
    ],
  },
  {
    field: '_',
    headerName: '',
    isSortable: false,
  },
];

export const INVOICE_STATUS_ASCENDING_ORDER: ProgramInvoiceStatus[] = [
  ProgramInvoiceStatus.Pending,
  ProgramInvoiceStatus.PastDue,
  ProgramInvoiceStatus.Paid,
  ProgramInvoiceStatus.Void,
];

export const sortInvoices = (
  invoices: ProgramInvoiceDataFragment[] | undefined,
  invoiceOrder: ColumnOrder,
) =>
  invoices
    ? invoices.sort(
        (
          invoiceA: ProgramInvoiceDataFragment,
          invoiceB: ProgramInvoiceDataFragment,
        ) => {
          switch (invoiceOrder) {
            case sortOptions.amountAscending:
              return invoiceA.amount - invoiceB.amount;
            case sortOptions.amountDescending:
              return invoiceB.amount - invoiceA.amount;

            case sortOptions.statusAscending:
              return (
                INVOICE_STATUS_ASCENDING_ORDER.findIndex(
                  (status) => status === invoiceA.status,
                ) -
                INVOICE_STATUS_ASCENDING_ORDER.findIndex(
                  (status) => status === invoiceB.status,
                )
              );
            case sortOptions.statusDescending:
              return (
                INVOICE_STATUS_ASCENDING_ORDER.findIndex(
                  (status) => status === invoiceB.status,
                ) -
                INVOICE_STATUS_ASCENDING_ORDER.findIndex(
                  (status) => status === invoiceA.status,
                )
              );

            case sortOptions.nameAlphabeticalAscending:
              return invoiceA.patient!.name!.toLowerCase() <
                invoiceB.patient!.name!.toLowerCase()
                ? -1
                : 1;
            case sortOptions.nameAlphabeticalDescending:
              return invoiceA.patient!.name!.toLowerCase() >=
                invoiceB.patient!.name!.toLowerCase()
                ? -1
                : 1;

            case sortOptions.oldestCreated:
              return invoiceA.createdAt < invoiceB.createdAt ? -1 : 1;
            case sortOptions.newestCreated:
              return invoiceA.createdAt >= invoiceB.createdAt ? -1 : 1;

            case sortOptions.dueDateAscending:
              return invoiceA.dueAt < invoiceB.dueAt ? -1 : 1;
            case sortOptions.dueDateDescending:
              return invoiceA.dueAt >= invoiceB.dueAt ? -1 : 1;

            default:
              return invoiceA.createdAt >= invoiceB.createdAt ? -1 : 1;
          }
        },
      )
    : [];
