import toast from 'react-hot-toast';
import ToastAlert from './index';

type ToastLevel = 'success' | 'error' | 'warning' | 'info';

const showToast = (message: string, level: ToastLevel = 'info') => {
  toast.custom(
    ({ visible }) => (
      <ToastAlert isVisible={visible} message={message} level={level} />
    ),
    { duration: level === 'error' ? 5000 : 3000 }, // Adjust duration as needed
  );
};

export const customToast = {
  success: (message: string) => showToast(message, 'success'),
  error: (message: string) => showToast(message, 'error'),
  warning: (message: string) => showToast(message, 'warning'),
  info: (message: string) => showToast(message, 'info'),
};
