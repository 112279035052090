import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import { FC, useState } from 'react';
import {
  CuratedProviderCollection,
  useCopyCollectionToLibraryMutation,
} from '../../generated/graphql';
import {
  trackProviderEvent,
  ProviderAnalyticsEvent,
} from '../../lib/analytics';
import Button from './Button';

const CopyToLibraryButton: FC<{
  collection: CuratedProviderCollection;
  theme: 'secondary-white' | 'primary';
  onAdded?: () => void;
}> = ({ collection, theme, onAdded }) => {
  const [added, setAdded] = useState(false);

  const [copyCollectionToLibraryMutation, { loading }] =
    useCopyCollectionToLibraryMutation();

  return (
    <Button
      IconComponent={added ? CheckIcon : PlusIcon}
      title={added ? 'Added!' : 'Add to Library'}
      theme={theme}
      iconPosition="left"
      disabled={loading || added}
      onClick={async () => {
        await copyCollectionToLibraryMutation({
          variables: {
            input: {
              providerCollectionId: collection.providerCollection.id,
            },
          },
        });

        setAdded(true);
        trackProviderEvent(ProviderAnalyticsEvent.CollectionCopied);
        onAdded?.();
      }}
    />
  );
};

export default CopyToLibraryButton;
