import React from 'react';
import { SparklesIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { StorefrontFormData } from '..';
import ProgramTagManager, {
  StagedProgramTagDataFragment,
} from '../../../components/ProgramTag/ProgramTagManager';
import {
  useMeProviderProgramTagsQuery,
  useUpdateProviderStorefrontMutation,
} from '../../../../generated/graphql';
import toast from 'react-hot-toast';
import ToastAlert from '../../../components/ToastAlert';

const TagModule: React.FC<{
  className?: string;
}> = ({ className }) => {
  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const [updateProviderStorefront] = useUpdateProviderStorefrontMutation();

  const { watch, setValue } = useFormContext<StorefrontFormData>();

  const watchIntakeTags = watch('intakeTags');

  const handleOnTagsChange = async (tags: StagedProgramTagDataFragment[]) => {
    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            intakeTags: tags.map((tag) => {
              const { name, id } = tag;
              return { name, id };
            }),
          },
        },
      });
      if (data) {
        setValue('intakeTags', data.updateProviderStorefront.intakeTags);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message="Tags updated."
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update tags."
          level="error"
        />
      ));
    }
  };

  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-hidden px-6 py-10 md:items-center',
        className,
      )}
    >
      <div className="flex flex-col md:w-1/2">
        <div className="mb-12 flex flex-row items-center space-x-2 md:space-x-4">
          <div className="w-36 flex-shrink-0 text-caption font-medium text-green-150">
            Select a tag for new prospective clients
          </div>
          <ProgramTagManager
            availableProgramTags={providerProgramTags}
            refreshAvailableProgramTags={refetchMeProviderProgramTags}
            selectedProgramTags={watchIntakeTags}
            onTagsChange={handleOnTagsChange}
            minimumProgramTagCount={1}
            maximumProgramTagCount={5}
            className="min-w-[220px]"
            alwaysIncludeAddTagButton
            wrapTags
          />
        </div>
        <div className="flex items-center text-neutral-125 md:w-full md:max-w-[500px]">
          <SparklesIcon className="mr-2 h-6 w-6 flex-shrink-0" />
          <div className="text-caption">
            <div>
              Homecoming automatically tags new leads with the tags you choose
              here, making them easy to find in your client directory. From
              there you can remove, add tags, or archive the client as you see
              fit.
            </div>
            <div className="mt-2">
              You can always manage your tags in your settings{' '}
              <a
                className="text-green-100 underline hover:text-green-125"
                href={'/settings/tags'}
                target="_blank"
                rel="nooopener noreferrer"
              >
                here
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagModule;
