import { FC } from 'react';
import { useStripeCheckoutPortalQuery } from '../../generated/graphql';
import Spinner from '../svgs/Spinner';
const StripeCheckoutRedirect: FC = () => {
  const { data } = useStripeCheckoutPortalQuery({
    variables: {
      webRedirectPath: 'home',
      choseAnnual: false,
    },
  });

  if (data?.stripeCheckoutPortal.url) {
    window.location.href = data.stripeCheckoutPortal.url;
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner />
    </div>
  );
};

export default StripeCheckoutRedirect;
