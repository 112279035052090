import classNames from 'classnames';
import Markdown from 'marked-react';
import { FC, ReactElement } from 'react';
import { AssessmentSignatureUser } from '../../../generated/graphql';

import { SignatureUserTypeLabel } from '../../lib/multiSignature';
import SignatureFlag from '../../svgs/SignatureFlag';

const QuestionItem: FC<{
  question: string;
  questionIndex: number;
  answer?: string;
  subscale?: string | null;
  answerOptions?: string[];
  className?: string;
  answerClassName?: string;
  assessmentSignatureUsers?: AssessmentSignatureUser[];
  additionalAnswerElem?: ReactElement;
}> = ({
  answer,
  question,
  subscale,
  questionIndex,
  answerOptions,
  className,
  answerClassName,
  assessmentSignatureUsers,
  additionalAnswerElem,
}) => {
  return (
    <div
      key={questionIndex}
      className={classNames(
        'flex flex-row items-start justify-start text-caption',
        answer ? 'mb-3' : 'mb-2',
        className,
      )}
    >
      <div className="mr-1 w-6 flex-none text-left font-bold">{`${
        questionIndex + 1
      }.`}</div>
      <div className="mb-1 flex flex-col items-start justify-start">
        <span className="break-words">
          <span className="mr-1 text-green-150">
            <Markdown>{question}</Markdown>
          </span>
          {subscale && (
            <span className="inline-flex items-center justify-between rounded-full bg-neutral-50 px-2 py-1 text-small-label font-normal text-neutral-150">
              {subscale}
            </span>
          )}
          {assessmentSignatureUsers && (
            <div className="mt-2 flex flex-col items-start justify-start px-2 py-1 text-small-caption font-medium text-neutral-150">
              {assessmentSignatureUsers.map((user) => (
                <div
                  key={user.signatureUserType}
                  className="mb-1.5 flex flex-row items-center justify-start"
                >
                  <SignatureFlag className="mr-1.5" />
                  {SignatureUserTypeLabel[user.signatureUserType]}
                </div>
              ))}
            </div>
          )}
        </span>
        {answerOptions && (
          <div className="mt-2 mb-1 flex flex-col items-start justify-start">
            {answerOptions.map((answer, index) => {
              return (
                <div
                  className="mb-0.5 flex flex-row items-center justify-start break-words"
                  key={index}
                >
                  <div className="h-[6px] min-w-[6px] rounded-full border border-neutral-125" />
                  <span className="ml-3 text-caption font-medium text-neutral-125">{`${answer}`}</span>
                </div>
              );
            })}
          </div>
        )}
        {(answer || additionalAnswerElem) && (
          <span
            className={classNames('break-words font-bold', answerClassName)}
          >
            {additionalAnswerElem && <div>{additionalAnswerElem}</div>}
            {answer && <Markdown>{answer}</Markdown>}
          </span>
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
