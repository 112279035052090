import { useState } from 'react';
import { PlusSmIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import PageContainer from '../../components/Containers/PageContainer';
import { useAuth } from '../../../contexts/AuthContext';
import EmptyIntegration from '../../components/EmptyIntegration';
import IllustrationBanknotes from '../../svgs/IllustrationBanknotes';
import Button from '../../components/Button';
import InvoiceTable from './Table';
import {
  ProgramInvoiceDataFragment,
  useProgramInvoicesQuery,
  useSendInvoiceReminderMutation,
  useVoidInvoiceMutation,
} from '../../../generated/graphql';
import ToastAlert from '../../components/ToastAlert';
import { ColumnOrder } from '../../types/tables';
import { sortOptions, sortInvoices } from './helpers';
import classNames from 'classnames';
import usePaintScreen from '../../hooks/usePaintScreen';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import InputGroup from '../../components/InputGroup';
import Search from '../../svgs/Search';
import CloseX from '../../svgs/CloseX';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

const Payments: React.FC = () => {
  const showPage = usePaintScreen(MODAL_TRANSITION_DURATION);
  const navigate = useNavigate();
  const { authedProviderUser, showUpgradeBanner } = useAuth();
  const hasStripeIntegrationSetup =
    authedProviderUser?.provider.stripeAccountId;

  const {
    data: programInvoicesData,
    loading: programInvoicesLoading,
    refetch: refetchProgramInvoices,
  } = useProgramInvoicesQuery({
    skip: !hasStripeIntegrationSetup,
  });

  const allProgramInvoices = programInvoicesData?.programInvoices || [];

  const [invoiceOrder, setInvoiceOrder] = useState<ColumnOrder>(
    sortOptions.newestCreated,
  );
  const [actionLoadingInvoiceId, setActionLoadingInvoiceId] = useState<
    string | null
  >(null);

  const [sendInvoiceReminder] = useSendInvoiceReminderMutation();
  const [voidInvoice] = useVoidInvoiceMutation();

  const [searchTermValue, setSearchTermValue] = useState('');
  const searchTerm = searchTermValue.toLowerCase().trim();

  const clearFilters = () => {
    setInvoiceOrder(sortOptions.newestCreated);
    setSearchTermValue('');
  };

  const programInvoicesList = allProgramInvoices.length
    ? sortInvoices(
        allProgramInvoices.filter(
          (programInvoice) =>
            programInvoice.patient.email.toLowerCase().includes(searchTerm) ||
            programInvoice.patient.name.toLowerCase().includes(searchTerm) ||
            programInvoice.stripeInvoiceNumber
              .toLowerCase()
              .includes(searchTerm),
        ),
        invoiceOrder,
      )
    : [];

  const hasProgramInvoicesList =
    programInvoicesList.length > 0 && !programInvoicesLoading;

  const showingAllProgramInvoices =
    programInvoicesList?.length ===
    (programInvoicesData
      ? Object.keys(programInvoicesData.programInvoices).length
      : 0);

  const handleSendInvoiceReminder = async (
    programInvoice: ProgramInvoiceDataFragment,
  ) => {
    try {
      const { data } = await sendInvoiceReminder({
        variables: {
          programInvoiceId: programInvoice.id,
        },
      });
      if (data?.sendInvoiceReminder) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully sent an invoice reminder to ${programInvoice.patient?.name}`}
          />
        ));
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  const handleVoidInvoice = async (
    programInvoice: ProgramInvoiceDataFragment,
  ) => {
    try {
      setActionLoadingInvoiceId(programInvoice.id);
      const { data } = await voidInvoice({
        variables: {
          programInvoiceId: programInvoice.id,
        },
      });
      if (data?.voidInvoice) {
        refetchProgramInvoices();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully voided invoice for ${programInvoice.patient?.name}`}
          />
        ));
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      setActionLoadingInvoiceId(null);
    }
  };

  const canSetupIntegrations =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  return (
    <PageContainer
      show={showPage}
      containerClassName={classNames(
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))]'
          : 'h-[calc(100vh-var(--top-nav-height))]',
        hasStripeIntegrationSetup && 'pb-0',
      )}
    >
      <div className="mb-6 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div className="font-serif text-subtitle text-green-150">
            Payments
          </div>
          {hasProgramInvoicesList && (
            <div className="ml-4">
              <span className="text-caption font-medium text-neutral-125">
                {programInvoicesList.length}{' '}
                {showingAllProgramInvoices ? 'total' : 'shown'}
              </span>
            </div>
          )}
        </div>

        {hasStripeIntegrationSetup && (
          <Button
            title="Create invoice"
            IconComponent={PlusSmIcon}
            iconPosition="left"
            onClick={() => navigate('/payments/new')}
          />
        )}
      </div>
      {hasStripeIntegrationSetup ? (
        <>
          <div className="mb-6 flex h-[56px] flex-row">
            <InputGroup
              labelHidden
              label="Search"
              placeholder="Search..."
              className="h-[44px] w-80 rounded-full pl-12 disabled:cursor-not-allowed"
              iconLeftClassName="text-neutral-125 ml-2"
              IconLeft={Search}
              IconRight={searchTermValue.length > 0 ? CloseX : null}
              iconRightOnClick={() => {
                setSearchTermValue('');
              }}
              iconRightClassName="text-neutral-125 mr-1"
              value={searchTermValue}
              onChange={(e) => {
                setSearchTermValue(e.target.value);
              }}
            />
          </div>
          <InvoiceTable
            hasAnyProgramInvoices={Boolean(allProgramInvoices.length)}
            programInvoices={programInvoicesList}
            programInvoicesLoading={programInvoicesLoading}
            onClickSendReminder={handleSendInvoiceReminder}
            onClickVoidInvoice={handleVoidInvoice}
            invoiceOrder={invoiceOrder}
            setInvoiceOrder={setInvoiceOrder}
            actionLoadingInvoiceId={actionLoadingInvoiceId}
            clearFilters={clearFilters}
          />
        </>
      ) : (
        <EmptyIntegration
          title={
            canSetupIntegrations
              ? 'Set up payments'
              : 'All your payments in one place'
          }
          description={
            canSetupIntegrations
              ? 'Connect your Stripe account to send invoices to clients and keep a log of your billing history.'
              : 'Your Stripe account needs to be connected by an administrator to start invoicing and tracking payments. Please contact your team members to enable these features.'
          }
          ctaPath="/integrations/stripe"
          illustration={
            <IllustrationBanknotes className="h-[80px] text-green-125" />
          }
        />
      )}
    </PageContainer>
  );
};

export default Payments;
