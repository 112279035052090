import { UseFieldArrayRemove } from 'react-hook-form';

export function removeEmptyFields(
  controlledFields: Record<string, string | Date | null>[],
  remove: UseFieldArrayRemove,
) {
  let fieldsToRemove = controlledFields
    .map((field, index) => index)
    .filter((fieldIndex) => {
      // Only remove fields whose values are all empty.
      return Object.entries(controlledFields[fieldIndex]).every(
        // id is a react-hook-form key that will always be present.
        ([key, value]) => !value || key === 'id',
      );
    });

  if (fieldsToRemove.length === controlledFields.length) {
    // If we're about to remove all fields, keep the first one even if it's empty.
    fieldsToRemove = fieldsToRemove.slice(1);
  }

  remove(fieldsToRemove);
}
