import { DateTime } from 'luxon';
import { RefObject } from 'react';

export const fiveMinutesInMilliseconds = 300000;

export const hours = [
  '12',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
];

export const getDaysFromAnchorDate = (
  anchorDate: DateTime,
  nowDate: DateTime,
) => {
  const columnClassIndex: Record<string, string> = {};
  const dayIndexes = [0, 1, 2, 3, 4, 5, 6];

  const weekDays = dayIndexes.map((dayCount) => {
    const weekDay = anchorDate.plus({ days: dayCount });
    columnClassIndex[weekDay.day] = `col-start-${dayCount + 1}`;

    const isToday = nowDate.hasSame(weekDay, 'day');

    return {
      dayName: weekDay.weekdayShort,
      dayNumber: weekDay.day,
      ...(isToday && { isToday: true }),
    };
  });

  return {
    weekDays,
    columnClassIndex,
  };
};

export const getDifferenceInMinutes = (
  startTime: DateTime,
  endTime: DateTime,
) => endTime.diff(startTime, ['minutes']).minutes;

export const getCalendarTitle = (
  anchorDate: DateTime,
  oneWeekFromAnchorDate: DateTime,
) => {
  if (anchorDate.hasSame(oneWeekFromAnchorDate, 'month')) {
    return `${anchorDate.monthLong} ${anchorDate.year}`;
  } else {
    if (anchorDate.year === oneWeekFromAnchorDate.year) {
      return `${anchorDate.monthShort} – ${oneWeekFromAnchorDate.monthShort} ${oneWeekFromAnchorDate.year}`;
    } else {
      return `${anchorDate.monthShort} ${anchorDate.year} – ${oneWeekFromAnchorDate.monthShort} ${oneWeekFromAnchorDate.year}`;
    }
  }
};

export const setTodayScrollPosition = (
  todayTimeBarRef: RefObject<HTMLLIElement>,
) => {
  if (todayTimeBarRef.current) {
    todayTimeBarRef.current.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }
};
