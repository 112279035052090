import Analytics, { AnalyticsInstance } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import Config from './config';
import { CommonAnalyticsEvent, ProviderAnalyticsEvent } from './analytics';

const DEBUG = false;

let analytics: AnalyticsInstance;

// Trialing this analytics library out now just for GTM, so this module abstracts
// the library away from the rest of the app, but if we like it and want to add more
// analytics through this library, we can refactor it out to be more general purpose.
const initGtm = (): void => {
  analytics = Analytics({
    plugins: [
      googleTagManager({
        containerId: Config.GTM_ID,
        ...(Config.IS_DEV && {
          preview: Config.GTM_PREVIEW_ENV,
          auth: Config.GTM_PREVIEW_AUTH,
        }),
      }),
    ],
    debug: DEBUG,
  });
};

const trackEvent = (
  event: CommonAnalyticsEvent | ProviderAnalyticsEvent,
  providerUserId: string,
  properties: Record<string, unknown> = {},
): void => {
  if (analytics) {
    analytics.track(event, {
      homecomingProviderUserId: providerUserId,
      ...properties,
    });
  }
};

const GtmHelpers = {
  initGtm,
  trackEvent,
};

export default GtmHelpers;
