import { FC } from 'react';
import StorefrontSignUp from './StorefrontSignUp';

const DEFAULT_ROLE_TITLE = 'Your professional designation or area of expertise';

const PractitionerInfoLeftColumn: FC<{
  providerName: string;
  providerStorefrontSlug?: string;
  roleTitle?: string;
  inEditMode?: boolean;
  editModeChildren?: React.ReactNode;
  signUpEnabled?: boolean;
}> = ({
  providerName,
  providerStorefrontSlug,
  roleTitle,
  inEditMode,
  editModeChildren,
  signUpEnabled = true,
}) => (
  <div className="flex min-w-[25rem] flex-col items-center pr-14">
    <div className="mb-2 text-center font-serif text-title-medium font-light text-neutral-125">
      {providerName}
    </div>
    <div className="mb-11">
      {!inEditMode ? (
        <div className="text-center text-caption text-neutral-125">
          {roleTitle || DEFAULT_ROLE_TITLE}
        </div>
      ) : (
        editModeChildren
      )}
    </div>
    {!inEditMode && (
      <StorefrontSignUp
        providerName={providerName}
        providerStorefrontSlug={providerStorefrontSlug}
        enabled={signUpEnabled}
        includeLoginLink={signUpEnabled}
      />
    )}
  </div>
);

export default PractitionerInfoLeftColumn;
