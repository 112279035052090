import { FC, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import Button from './Button';

type ButtonAction = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const BottomButtons: FC<{
  isVisible: boolean;
  actions: ButtonAction[];
}> = ({ isVisible, actions }) => {
  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-end sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4">
          <Transition
            show={isVisible}
            as={Fragment}
            enter="transform ease-out duration-50 transition"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto rounded-3xl">
              <div>
                <div className="flex items-start">
                  {actions.map((action, index) => {
                    const isFirstAction = index === 0;
                    const isLastAction = index === actions.length - 1;
                    return (
                      <Button
                        key={`${index}_${action.title}`}
                        onClick={action.onClick}
                        className={classNames(
                          'h-16 w-52 shadow-lg focus:ring-inset',
                          !action.className &&
                            isFirstAction &&
                            'rounded-tr-none rounded-br-none',
                          !action.className &&
                            isLastAction &&
                            'rounded-tl-none rounded-bl-none border-l border-neutral-50',
                          !action.className &&
                            !isFirstAction &&
                            !isLastAction &&
                            'rounded-none border-l border-neutral-50',
                          action.className,
                        )}
                        disabled={action.disabled}
                        title={action.title}
                        theme="primary"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default BottomButtons;
