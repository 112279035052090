import React, { useEffect, useState } from 'react';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import ModalDialog from '../../components/ModalDialog';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import InputGroup from '../../components/InputGroup';
import { HomecomingError, matchHomecomingError } from '../../../lib/errors';
import { ApolloError } from '@apollo/client';
import { FollowUpTemplateDataFragment } from '../../../generated/graphql';

interface NameFollowUpTemplateModalFormData {
  name: string;
}

const MIN_TEMPLATE_NAME_LENGTH = 3;
const MAX_TEMPLATE_NAME_LENGTH = 50;

const NameFollowUpTemplateModal: React.FC<{
  isOpen: boolean;
  setClosed: () => void;
  followUpTemplateName?: string;
  handleSaveFollowUpTemplate: (templateName: string) => void;
  // If both of these next fields are provided,
  // this modal will handle renaming instead of creating a new template
  existingFollowUpTemplate?: FollowUpTemplateDataFragment;
  handleRenameFollowUpTemplate?: (
    followUpTemplate: FollowUpTemplateDataFragment,
    newName: string,
  ) => void;
}> = ({
  isOpen,
  setClosed,
  followUpTemplateName,
  handleSaveFollowUpTemplate,
  existingFollowUpTemplate,
  handleRenameFollowUpTemplate,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset,
    resetField,
    setFocus,
    setError,
  } = useForm<NameFollowUpTemplateModalFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: followUpTemplateName ?? existingFollowUpTemplate?.name ?? '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData: NameFollowUpTemplateModalFormData) => {
    try {
      const newTemplateName = formData.name.trim();
      setIsLoading(true);
      if (existingFollowUpTemplate && handleRenameFollowUpTemplate) {
        await handleRenameFollowUpTemplate(
          existingFollowUpTemplate,
          newTemplateName,
        );
      } else {
        await handleSaveFollowUpTemplate(newTemplateName);
      }
      resetField('name', { defaultValue: newTemplateName }); // Necessary to reset default form value
      closeAndResetModal();
    } catch (error) {
      if (
        matchHomecomingError(
          error as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        setError('name', {
          message: 'A template with this name already exists.',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onErrors: SubmitErrorHandler<NameFollowUpTemplateModalFormData> = (
    errors,
  ) => {
    console.log('errors:', errors);
  };

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      reset({ name: followUpTemplateName ?? '' });
    }, MODAL_TRANSITION_DURATION);
  };

  // Ensure default name input stays synced with a provided mail template name
  useEffect(() => {
    setValue('name', followUpTemplateName ?? '');
  }, [followUpTemplateName, setValue]);

  // Auto-focus name input requires waiting for modal to animate open
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFocus('name');
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isOpen, setFocus]);

  return (
    <ModalDialog
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      width="xs"
      title={
        existingFollowUpTemplate
          ? 'Rename your mail template'
          : 'Name your mail template'
      }
      primaryActionTitle={!isLoading ? 'Save' : 'Saving'}
      primaryActionOnClick={handleSubmit(onSubmit, onErrors)}
      primaryActionDisabled={isLoading}
      primaryActionOnEnter={true}
    >
      <div className="flex h-16 flex-col">
        <InputGroup
          inputSize="small"
          labelHidden
          containerClassName="w-full"
          {...register('name', {
            required: {
              value: true,
              message: 'Please name your mail template',
            },
            minLength: {
              value: MIN_TEMPLATE_NAME_LENGTH,
              message: `Name must be at least ${MIN_TEMPLATE_NAME_LENGTH} characters`,
            },
            maxLength: {
              value: MAX_TEMPLATE_NAME_LENGTH,
              message: `Name must be less than ${MAX_TEMPLATE_NAME_LENGTH} characters`,
            },
          })}
          errorMessage={formErrors.name?.message}
          maxLength={MAX_TEMPLATE_NAME_LENGTH}
        />
      </div>
    </ModalDialog>
  );
};

export default NameFollowUpTemplateModal;
