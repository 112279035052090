import classNames from 'classnames';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef<
  HTMLInputElement,
  {
    id: string;
    className?: string;
    labelContent?: React.ReactNode;
    checkboxClassName?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      id,
      value,
      className,
      labelContent,
      disabled,
      checkboxClassName,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={classNames('flex', className)}>
        <input
          id={id}
          type="checkbox"
          ref={ref}
          className={classNames(
            'mr-2 h-5 w-5 rounded-md border-neutral-100 focus:ring-green-125',
            checkboxClassName,
            disabled
              ? 'cursor-not-allowed text-neutral-100'
              : 'cursor-pointer text-green-125',
          )}
          disabled={disabled}
          {...rest}
        />
        {labelContent && (
          <label
            className={classNames(
              'text-caption',
              disabled
                ? 'cursor-not-allowed text-neutral-100'
                : 'cursor-pointer text-neutral-125',
            )}
            htmlFor={id}
          >
            {labelContent}
          </label>
        )}
      </div>
    );
  },
);

export default Checkbox;
