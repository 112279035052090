import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import { useProviderAssessmentsQuery } from '../../../../generated/graphql';
import Spinner from '../../../svgs/Spinner';
import { defaultEnterTransitionProps } from '../../../lib/animation';
import ToastAlert from '../../../components/ToastAlert';
import AssessmentCard from './AssessmentCard';
import AssessmentTable from './AssessmentTable';

const ProgramAssessmentsTab = () => {
  const params = useParams();
  const programTemplateId = params.programTemplateId as string;

  const {
    data: providerAssessmentsData,
    loading: isLoadingProviderAssessments,
    error: providerAssessmentsError,
  } = useProviderAssessmentsQuery({
    variables: {
      programTemplateId,
    },
  });

  useEffect(() => {
    if (providerAssessmentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to load assessments."
          level="error"
        />
      ));
    }
  }, [providerAssessmentsError]);

  // Sorts so that the assessments that have scheduled items are listed first Explore
  const assessments = providerAssessmentsData?.providerAssessments.sort(
    (providerAssessmentA, providerAssessmentB) => {
      if (
        Boolean(
          providerAssessmentA.programTemplateAssessment?.scheduleItems.length,
        ) &&
        !Boolean(
          providerAssessmentB.programTemplateAssessment?.scheduleItems.length,
        )
      ) {
        return -1;
      } else {
        return 1;
      }
    },
  );

  // Filters out any assessments without schedule items for the table view.
  const assessmentsWithScheduleItems = assessments?.filter(
    (assessment) => assessment?.programTemplateAssessment?.scheduleItems.length,
  );

  return (
    <>
      <Transition
        show={Boolean(assessments) && !providerAssessmentsError}
        {...defaultEnterTransitionProps}
      >
        {assessmentsWithScheduleItems?.length ? (
          <AssessmentTable
            assessmentsWithScheduleItems={assessmentsWithScheduleItems}
          />
        ) : null}
        <div className="mt-12 mb-6 font-serif text-subtitle-small text-black">
          Explore assessments
        </div>
        <div className="flex flex-wrap gap-7">
          {assessments?.map((assessment) => (
            <Link
              to={`${assessment.id}/schedule`}
              key={assessment.id}
              className="focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50"
            >
              <AssessmentCard
                category={assessment.category}
                shortName={assessment.shortName}
                name={assessment.name}
                description={assessment.description}
                estimatedLengthInMinutes={
                  assessment.estimatedLengthInMinutes ?? undefined
                }
                hasIncludedBadge={Boolean(
                  assessment.programTemplateAssessment?.scheduleItems.length,
                )}
              />
            </Link>
          ))}
        </div>
      </Transition>

      {isLoadingProviderAssessments && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default ProgramAssessmentsTab;
