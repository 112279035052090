import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import Wavesurfer from 'wavesurfer.js';

import { getDurationMSS } from '../../lib/time';

import Spinner from '../../svgs/Spinner';

import { useWaveSurfer } from './useWaveSurfer';
import PlayPauseButton from './PlayPauseButton';

const PROGRESS_UPDATE_INTERVAL_MS = 50;

const AudioPlayer: FC<{
  playerId: string;
  duration?: number | null;
  mediaUrl?: string;
  audioBlob?: Blob | undefined;
  className?: string;
  children?: React.ReactNode;
  autoPlay?: boolean;
  defaultStyle?: boolean;
  triggerStop?: boolean;
  onReady?: (wavesurfer: Wavesurfer) => void;
}> = ({
  playerId,
  duration = 0,
  mediaUrl,
  audioBlob,
  className,
  children,
  autoPlay = false,
  defaultStyle = true,
  triggerStop,
  onReady,
}) => {
  // Prefix player ID with 'player-' to ensure that IDs that start with a nondigit char
  // Fix for this error https://stackoverflow.com/questions/37270787/uncaught-syntaxerror-failed-to-execute-queryselector-on-document
  playerId = 'player-' + playerId;
  const wavesurfer = useWaveSurfer(playerId);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayable, setIsPlayable] = useState(false);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(duration);

  useEffect(() => {
    if (wavesurfer) {
      if (mediaUrl) {
        wavesurfer.load(mediaUrl);
      } else if (audioBlob) {
        wavesurfer.loadBlob(audioBlob);
      }
      return () => {
        wavesurfer?.pause(); // pause on unmount
        setIsPlaying(false);
      };
    }
  }, [wavesurfer, mediaUrl, audioBlob]);

  useEffect(() => {
    if (isPlaying && triggerStop) {
      wavesurfer?.pause();
    }
  }, [triggerStop]);

  useEffect(() => {
    if (duration) {
      setSecondsElapsed(0);
      setSecondsRemaining(duration);
    }
  }, [duration]);

  wavesurfer?.on('ready', () => {
    setIsPlayable(true);
    if (autoPlay) {
      wavesurfer.play();
      setIsPlaying(true);
    }
    onReady && onReady(wavesurfer);
  });

  const onClickPlayPauseButton = async () => {
    wavesurfer?.playPause();
    setIsPlaying(!isPlaying);
  };

  let playbackInterval: NodeJS.Timer;

  wavesurfer?.on('play', () => {
    playbackInterval = setInterval(() => {
      const currentTimeInSeconds = Math.round(wavesurfer?.getCurrentTime());
      setSecondsElapsed(currentTimeInSeconds);
      if (duration) setSecondsRemaining(duration - currentTimeInSeconds);
    }, PROGRESS_UPDATE_INTERVAL_MS);
  });

  wavesurfer?.on('pause', () => {
    clearInterval(playbackInterval);
  });

  wavesurfer?.on('finish', () => {
    setIsPlaying(false);
  });

  wavesurfer?.on('seek', (progress: number) => {
    if (duration) {
      const currentTimeInSeconds = Math.ceil(progress * duration);
      setSecondsElapsed(currentTimeInSeconds);
      setSecondsRemaining(duration - currentTimeInSeconds);
    }
  });

  return (
    <div
      className={classNames(
        'relative flex w-full flex-row items-center justify-between',
        'rounded-lg border border-neutral-50',
        defaultStyle && 'h-[48px] px-4 py-8',
        className,
      )}
    >
      <PlayPauseButton
        isPlayable={isPlayable}
        isPlaying={isPlaying}
        onClick={onClickPlayPauseButton}
      />
      <div
        className={classNames(
          'text-netural-150 mr-1 min-w-[40px] text-caption',
          !isPlayable && 'text-neutral-100',
        )}
      >
        {getDurationMSS(secondsElapsed)}
      </div>
      <div id={playerId} className="w-full" />
      <div
        className={classNames(
          'text-netural-150 ml-3 min-w-[40px] text-caption',
          !isPlayable && 'text-neutral-100',
        )}
      >
        {getDurationMSS(secondsRemaining)}
      </div>
      {!isPlayable && (
        <div className="absolute top-0 left-0 flex h-full w-full flex-row items-center justify-center">
          <Spinner className="h-4 w-4 text-neutral-100" />
        </div>
      )}
      {children}
    </div>
  );
};

export default AudioPlayer;
