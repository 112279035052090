import { OnboardingPageProps } from '../types/onboarding';
import BillingCheckout from '../components/Billing/BillingCheckout';
import { useBillingInfoQuery } from '../../generated/graphql';
import Spinner from '../svgs/Spinner';

export default function OnboardingBilling({
  stepIndex,
  steps,
  goToNextStep,
}: OnboardingPageProps) {
  const { data: billingInfoData } = useBillingInfoQuery();

  const provider = billingInfoData?.meProvider?.provider;

  return (
    <div className="flex flex-col items-center justify-center py-8">
      {provider ? (
        <BillingCheckout
          isOnboarding
          paymentPlan={provider.paymentPlan}
          subscriptionTier={provider.subscriptionTier}
          clientSeats={provider.clientSeats}
          staffSeats={provider.staffSeats}
        />
      ) : (
        <div className="flex h-auto w-full flex-col items-center py-6">
          <Spinner />
        </div>
      )}
    </div>
  );
}
