import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';
import { RadioGroup, Transition } from '@headlessui/react';
import { Transition as HistoryTransition } from 'history';
import classNames from 'classnames';

import {
  AssessmentFocalEvent,
  AssessmentScheduleItemInput,
  ProgramTemplateAssessmentScheduleItem,
  useScheduleAssessmentDataQuery,
  useScheduleAssessmentMutation,
} from '../../../generated/graphql';

import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import SelectMenu from '../../components/SelectMenu';
import ToastAlert from '../../components/ToastAlert';
import { AssessmentMetaData } from '../../components/AssessmentMetaData';
import { defaultEnterTransitionProps } from '../../lib/animation';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import Spinner from '../../svgs/Spinner';
import Trash from '../../svgs/Trash';
import {
  BeforeOrAfterFocalEventFormOption,
  beforeOrAfterFocalEventFormOptions,
  BeforeOrAfterFocalEventOption,
  BeforeOrAfterOffsetNumberFormOption,
  beforeOrAfterOffsetNumberFormOptions,
  BeforeOrAfterOffsetPeriodFormOptionPlural,
  beforeOrAfterOffsetPeriodFormOptions,
  BeforeOrAfterOffsetPeriodFormOptionSingular,
  BeforeOrAfterOffsetPeriodOption,
  getAssessmentScheduleItemFromFormData,
  getRecurrenceTextFromAssessmentScheduleItem,
  getScheduleTextFromAssessmentScheduleItem,
  RecurrenceIntervalFormOption,
  recurrenceIntervalFormOptions,
  RecurrenceIntervalOption,
  SameDayFocalEventFormOption,
  sameDayFocalEventFormOptions,
  ScheduleTimingOption,
} from './helpers';
import PageContainer from '../../components/Containers/PageContainer';
import PanelContainer from '../../components/Containers/PanelContainer';
import MainContainer from '../../components/Containers/MainContainer';
import UnsavedChangesModal from '../../components/Modals/UnsavedChangesModal';
import useBlocker from '../../hooks/useBlocker';
import { ScheduleAssessmentModuleContainer } from './ScheduleAssessmentModuleContainer';
import { ScheduleTimingRadioOption } from './ScheduleTimingRadioOption';
import PreviewAssessmentSlideover from './PreviewAssessmentSlideover';

export interface ScheduleAssessmentFormData {
  scheduleTimingOption: ScheduleTimingOption;
  sameDayFocalEvent: AssessmentFocalEvent;
  beforeOrAfterOffsetNumber: number;
  beforeOrAfterOffsetPeriod: BeforeOrAfterOffsetPeriodOption;
  beforeOrAfterFocalEvent: BeforeOrAfterFocalEventOption;
  recurrenceInterval: RecurrenceIntervalOption;
  // Errors
  apiError?: string;
}

const ScheduleAssessment: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const programTemplateId = params.programTemplateId as string;
  const assessmentId = params.assessmentId as string;

  const [assessmentScheduleItems, setAssessmentScheduleItems] = useState<
    ProgramTemplateAssessmentScheduleItem[]
  >([]);
  const [
    isPreviewAssessmentSlideoverOpen,
    setIsPreviewAssessmentSlideoverOpen,
  ] = useState(false);

  const {
    data: scheduleAssessmentData,
    loading: isLoadingScheduleAssessmentData,
    error: scheduleAssessmentDataError,
  } = useScheduleAssessmentDataQuery({
    variables: {
      programTemplateId,
      assessmentId,
    },
  });

  const [
    scheduleAssessmentMutation,
    { loading: isScheduleAssessmentMutationLoading },
  ] = useScheduleAssessmentMutation();

  useEffect(() => {
    if (scheduleAssessmentDataError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  }, [scheduleAssessmentDataError]);

  const assessment = scheduleAssessmentData?.assessment;
  const initialScheduleItems =
    assessment?.programTemplateAssessment?.scheduleItems;

  // Copy initial schedule item data to state only once on load
  useEffect(() => {
    if (!isLoadingScheduleAssessmentData && initialScheduleItems) {
      setAssessmentScheduleItems(initialScheduleItems);
    }
  }, [isLoadingScheduleAssessmentData, initialScheduleItems]);

  const { control, handleSubmit, reset, watch } =
    useForm<ScheduleAssessmentFormData>({
      defaultValues: {
        scheduleTimingOption: ScheduleTimingOption.SameDay,
        sameDayFocalEvent: AssessmentFocalEvent.ClientActivation,
        beforeOrAfterOffsetNumber: 1,
        beforeOrAfterOffsetPeriod: BeforeOrAfterOffsetPeriodOption.Day,
        beforeOrAfterFocalEvent:
          BeforeOrAfterFocalEventOption.AfterClientActivation,
        recurrenceInterval: RecurrenceIntervalOption.DoesNotRepeat,
      },
    });

  const watchBeforeOrAfterOffsetNumber = watch('beforeOrAfterOffsetNumber');

  const addScheduleItem = (data: ScheduleAssessmentFormData) => {
    setHasUnsavedChanges(true);
    const newAssessmentScheduleItem =
      getAssessmentScheduleItemFromFormData(data);

    // Check and block for existing initial event scheduled for that time.
    // Even with a different recurrence interval, this would lead to duplicate assessments.
    const duplicateScheduleItemFields = ['focalEvent', 'timingOffset'];
    if (
      assessmentScheduleItems.some((scheduleItem) =>
        isEqual(
          pick(scheduleItem, duplicateScheduleItemFields),
          pick(newAssessmentScheduleItem, duplicateScheduleItemFields),
        ),
      )
    ) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="An event starting then has already been scheduled"
          level="warning"
        />
      ));
      return;
    }

    setAssessmentScheduleItems([
      newAssessmentScheduleItem,
      ...assessmentScheduleItems,
    ]);
    reset();
  };

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [hasUnsavedChanges],
  );

  useBlocker(blocker, hasUnsavedChanges);

  const removeScheduleItem = (index: number) => {
    setHasUnsavedChanges(true);
    const scheduleItemCopy = [...assessmentScheduleItems];
    scheduleItemCopy.splice(index, 1);
    setAssessmentScheduleItems(scheduleItemCopy);
  };

  const saveAssessmentScheduleToServer = async () => {
    // Types have the same shape as the input but need to be cleaned of __typename
    const assessmentScheduleItemsForInput: AssessmentScheduleItemInput[] =
      assessmentScheduleItems.map(
        ({ focalEvent, timingOffset, recurrenceInterval }) => ({
          focalEvent,
          timingOffset,
          recurrenceInterval,
        }),
      );

    try {
      const response = await scheduleAssessmentMutation({
        variables: {
          input: {
            programTemplateId,
            assessmentId: assessment!.id,
            assessmentScheduleItems: assessmentScheduleItemsForInput,
          },
        },
      });

      const updatedScheduleItems =
        response.data?.scheduleAssessment.updatedProgramTemplateAssessment
          .scheduleItems;

      if (updatedScheduleItems) {
        setHasUnsavedChanges(false);
        setAssessmentScheduleItems(updatedScheduleItems);
      }

      toast.custom(({ visible }) => (
        <ToastAlert isVisible={visible} message="Assessment schedule saved!" />
      ));

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  return (
    <>
      <PageContainer noPadding>
        <Transition
          show={Boolean(assessment) && !scheduleAssessmentDataError}
          {...defaultEnterTransitionProps}
        >
          {assessment && (
            <>
              <PanelContainer>
                <div className="mb-1 font-serif text-subtitle-small text-green-150">
                  {assessment?.shortName}
                </div>
                <div className="mb-6 font-sans text-small-caption font-bold text-neutral-125">
                  {assessment?.name}
                </div>
                <div className="mb-6 font-sans text-caption text-neutral-125">
                  {assessment?.description}
                </div>
                <AssessmentMetaData
                  category={assessment?.category}
                  estimatedLengthInMinutes={
                    assessment?.estimatedLengthInMinutes
                  }
                />
                <Button
                  title="Preview assessment"
                  IconComponent={ArrowRightLong}
                  theme="secondary"
                  className="mt-8"
                  onClick={() => setIsPreviewAssessmentSlideoverOpen(true)}
                />
              </PanelContainer>
              <MainContainer>
                <div className="flex gap-7 sm:flex-col sm:items-center xl:flex-row xl:items-start xl:justify-start">
                  <ScheduleAssessmentModuleContainer title="Add an event">
                    <form
                      className="flex min-h-[526px] w-full flex-col justify-between"
                      onSubmit={handleSubmit(addScheduleItem)}
                    >
                      <div className="flex w-full flex-col justify-start p-8 pt-6">
                        <div className="mb-6 flex flex-col">
                          <div className="mb-5 font-sans text-small-caption font-bold text-neutral-150">
                            Choose when clients receive the first assessment
                          </div>
                          <Controller
                            name="scheduleTimingOption"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                              const { onChange, value } = field;
                              return (
                                <RadioGroup
                                  value={value}
                                  onChange={onChange}
                                  className="space-y-6"
                                  disabled={isScheduleAssessmentMutationLoading}
                                >
                                  <ScheduleTimingRadioOption
                                    scheduleTimingOption={
                                      ScheduleTimingOption.SameDay
                                    }
                                    content={
                                      <div className="flex w-full items-center">
                                        <div className="ml-5 mr-4 text-caption text-green-150">
                                          Same day
                                        </div>
                                        <Controller
                                          name="sameDayFocalEvent"
                                          control={control}
                                          rules={{ required: true }}
                                          render={({ field }) => {
                                            const { onChange, value } = field;
                                            return (
                                              <SelectMenu
                                                fieldValue={value}
                                                onChange={onChange}
                                                fieldOptions={
                                                  sameDayFocalEventFormOptions
                                                }
                                                SelectOptionComponent={
                                                  SameDayFocalEventFormOption
                                                }
                                                label="Same day Focal Event"
                                                containerClassName="grow"
                                                hideLabel
                                                disabled={
                                                  isScheduleAssessmentMutationLoading
                                                }
                                                buttonClassName="max-h-[48px]"
                                              />
                                            );
                                          }}
                                        />
                                      </div>
                                    }
                                  />
                                  <ScheduleTimingRadioOption
                                    scheduleTimingOption={
                                      ScheduleTimingOption.BeforeOrAfter
                                    }
                                    content={
                                      <div className="ml-5 w-full">
                                        <div className="mb-4 flex items-center">
                                          <Controller
                                            name="beforeOrAfterOffsetNumber"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => {
                                              const { onChange, value } = field;
                                              return (
                                                <SelectMenu
                                                  fieldValue={value}
                                                  onChange={onChange}
                                                  fieldOptions={
                                                    beforeOrAfterOffsetNumberFormOptions
                                                  }
                                                  SelectOptionComponent={
                                                    BeforeOrAfterOffsetNumberFormOption
                                                  }
                                                  label="Before or After Offset"
                                                  containerClassName="mr-4 basis-1/2"
                                                  hideLabel
                                                  disabled={
                                                    isScheduleAssessmentMutationLoading
                                                  }
                                                  buttonClassName="max-h-[40px]"
                                                />
                                              );
                                            }}
                                          />
                                          <Controller
                                            name="beforeOrAfterOffsetPeriod"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => {
                                              const { onChange, value } = field;
                                              return (
                                                <SelectMenu
                                                  fieldValue={value}
                                                  onChange={onChange}
                                                  fieldOptions={
                                                    beforeOrAfterOffsetPeriodFormOptions
                                                  }
                                                  SelectOptionComponent={
                                                    watchBeforeOrAfterOffsetNumber ===
                                                    1
                                                      ? BeforeOrAfterOffsetPeriodFormOptionSingular
                                                      : BeforeOrAfterOffsetPeriodFormOptionPlural
                                                  }
                                                  label="Before or After Recurrence Interval"
                                                  containerClassName="basis-1/2"
                                                  hideLabel
                                                  disabled={
                                                    isScheduleAssessmentMutationLoading
                                                  }
                                                  buttonClassName="max-h-[40px]"
                                                />
                                              );
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <Controller
                                            name="beforeOrAfterFocalEvent"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => {
                                              const { onChange, value } = field;
                                              return (
                                                <SelectMenu
                                                  fieldValue={value}
                                                  onChange={onChange}
                                                  fieldOptions={
                                                    beforeOrAfterFocalEventFormOptions
                                                  }
                                                  SelectOptionComponent={
                                                    BeforeOrAfterFocalEventFormOption
                                                  }
                                                  label="Before or After Focal Event"
                                                  hideLabel
                                                  disabled={
                                                    isScheduleAssessmentMutationLoading
                                                  }
                                                  buttonClassName="max-h-[40px]"
                                                />
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    }
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="mb-2 mt-1 font-sans text-small-caption font-bold text-neutral-150">
                            Choose recurrence
                          </div>
                          <Controller
                            name="recurrenceInterval"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                              const { onChange, value } = field;

                              return (
                                <SelectMenu
                                  fieldValue={value}
                                  onChange={onChange}
                                  fieldOptions={recurrenceIntervalFormOptions}
                                  SelectOptionComponent={
                                    RecurrenceIntervalFormOption
                                  }
                                  label="Before or After Focal Event"
                                  hideLabel
                                  disabled={isScheduleAssessmentMutationLoading}
                                  buttonClassName="max-h-[40px]"
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col">
                        <div className="w-full border-b border-neutral-50" />
                        <div className="flex w-full flex-col items-center justify-start rounded-xl bg-white">
                          <Button
                            title="Add event"
                            type="submit"
                            IconComponent={ArrowRightLong}
                            theme="secondary"
                            className="flex w-full flex-row items-start justify-start rounded-t-none rounded-b-xl py-4 text-caption font-medium shadow-none hover:rounded-b-xl hover:bg-neutral-25"
                            noBackground
                            noOutline
                            iconClassName="text-green-100"
                            disabled={isScheduleAssessmentMutationLoading}
                          />
                        </div>
                      </div>
                    </form>
                  </ScheduleAssessmentModuleContainer>
                  <ScheduleAssessmentModuleContainer
                    title="Events"
                    hasUnsavedChanges={hasUnsavedChanges}
                  >
                    <div className="flex max-h-[526px] min-h-[526px] w-full flex-col justify-between">
                      <div className="flex flex-col pt-5">
                        <div className="mb-4 flex flex-row items-center justify-between px-8 text-small-caption font-bold text-neutral-150">
                          <div className="flex flex-row items-center justify-start">
                            <span>{`Scheduled ${assessment?.shortName} events`}</span>
                            <div
                              className={classNames(
                                'ml-2 items-center justify-center rounded-full bg-neutral-100/30 px-2 py-1 font-bold',
                                !(assessmentScheduleItems.length > 0) &&
                                  'invisible',
                              )}
                            >
                              {assessmentScheduleItems.length}
                            </div>
                          </div>
                        </div>
                        <div className="w-full border-b border-neutral-50" />
                        {assessmentScheduleItems.length > 0 ? (
                          <div className="max-h-[409px] divide-y divide-neutral-25 overflow-y-scroll px-8">
                            {assessmentScheduleItems.map((scheduleItem, i) => (
                              <div
                                key={i}
                                className="flex items-center justify-between py-4"
                              >
                                <div>
                                  <div className="mb-1 text-caption text-green-150">
                                    {getScheduleTextFromAssessmentScheduleItem(
                                      scheduleItem,
                                    )}
                                  </div>
                                  <div className="text-small-caption text-neutral-125">
                                    {getRecurrenceTextFromAssessmentScheduleItem(
                                      scheduleItem,
                                    )}
                                  </div>
                                </div>
                                <IconButton
                                  IconComponent={Trash}
                                  aria-label="Remove row"
                                  iconClassName="h-5 w-5"
                                  className="text-green-150"
                                  onClick={() => removeScheduleItem(i)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="px-8 pt-4 text-caption text-neutral-125">
                            No events scheduled
                          </div>
                        )}
                      </div>
                      <Button
                        title={`Save ${assessment?.shortName} schedule`}
                        theme="primary"
                        type="submit"
                        className="flex w-full flex-row items-start justify-start rounded-t-none rounded-b-xl py-4 text-caption font-medium shadow-none hover:rounded-b-xl"
                        noOutline
                        IconComponent={
                          !isScheduleAssessmentMutationLoading
                            ? ArrowRightLong
                            : Spinner
                        }
                        onClick={saveAssessmentScheduleToServer}
                        disabled={isScheduleAssessmentMutationLoading}
                      />
                    </div>
                  </ScheduleAssessmentModuleContainer>
                </div>
              </MainContainer>
            </>
          )}
        </Transition>

        {isLoadingScheduleAssessmentData && (
          <Spinner className="mx-auto mt-8" />
        )}
      </PageContainer>

      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => navTransition?.retry()}
      />

      {assessment && (
        <PreviewAssessmentSlideover
          onClose={() => setIsPreviewAssessmentSlideoverOpen(false)}
          isOpen={isPreviewAssessmentSlideoverOpen}
          assessment={assessment}
        />
      )}
    </>
  );
};

export default ScheduleAssessment;
