import { useState, useEffect } from 'react';
import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import { useSearchParams, useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo/logo-green-xl.png';
import ArrowRightLong from '../svgs/ArrowRightLong';
import Button from '../components/Button';
import InputGroup from '../components/InputGroup';
import PasswordSuggestions from '../components/PasswordSuggestions';
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal';

import {
  useValidateRecoveryCodeQuery,
  useChangePasswordWithRecoveryCodeMutation,
} from '../../generated/graphql';

import { checkPasswordStrength } from '../lib/password-strength';
import { getPasswordErrorMessage } from '../lib/form-validators';

interface ResetPasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recoveryCode = searchParams.get('recovery-code') || '';

  const { data: validateRecoveryCodeData } = useValidateRecoveryCodeQuery({
    variables: { recoveryCode },
  });

  useEffect(() => {
    if (!validateRecoveryCodeData?.validateRecoveryCode) {
      setResetEnabled(true);
    }
    if (validateRecoveryCodeData?.validateRecoveryCode === false) {
      setResetEnabled(false);
    }
  }, [validateRecoveryCodeData]);

  const [resetEnabled, setResetEnabled] = useState<boolean | null>(null);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  const [passwordStrengthResult, setPasswordStrengthResult] = useState<
    ZxcvbnResult | undefined
  >();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm<ResetPasswordFormData>({
    reValidateMode: 'onChange',
  });

  const [changePasswordWithRecoveryCodeMutation] =
    useChangePasswordWithRecoveryCodeMutation();

  const onSubmit: SubmitHandler<ResetPasswordFormData> = async (data) => {
    try {
      await changePasswordWithRecoveryCodeMutation({
        variables: {
          recoveryCode,
          newPassword: data.newPassword,
        },
      });
      setResetEnabled(null);
      setPasswordResetSuccess(true);
    } catch (err) {
      setError('confirmPassword', {
        type: 'Invalid code',
        message: 'Error resetting password',
      });
    }
  };

  const handleErrors: SubmitErrorHandler<ResetPasswordFormData> = (errors) => {
    console.log('errors:', errors);
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex grow flex-col items-center">
          <div className="my-24">
            <img src={logo} className="h-12 w-12" alt="logo" />
          </div>
          <div className="mb-3 font-serif text-subtitle text-green-150">
            Reset your password
          </div>
          {resetEnabled !== null && (
            <>
              {resetEnabled ? (
                <>
                  <div className="mb-12 font-sans text-body text-green-125">
                    Please enter a new password
                  </div>
                  <form
                    className="flex w-[405px] flex-col pb-24"
                    onSubmit={handleSubmit(onSubmit, handleErrors)}
                  >
                    <InputGroup
                      label="New password"
                      type="password"
                      containerClassName="mb-6"
                      errorMessage={errors.newPassword?.message}
                      passwordStrengthScore={passwordStrengthResult?.score}
                      required
                      {...register('newPassword', {
                        validate: (password) => {
                          const passwordStrengthResult =
                            checkPasswordStrength(password);
                          setPasswordStrengthResult(passwordStrengthResult);

                          return getPasswordErrorMessage(
                            passwordStrengthResult,
                          );
                        },
                      })}
                    />
                    <InputGroup
                      label="Confirm password"
                      type="password"
                      required
                      errorMessage={errors.confirmPassword?.message}
                      {...register('confirmPassword', {
                        required: true,
                        validate: (passwordValue: string) => {
                          if (watch('newPassword') !== passwordValue) {
                            return 'Your passwords do not match';
                          }
                        },
                      })}
                    />

                    <PasswordSuggestions
                      strengthResult={passwordStrengthResult}
                    />

                    <Button
                      title="Reset your password"
                      IconComponent={ArrowRightLong}
                      className="mt-10"
                      type="submit"
                    />
                  </form>
                </>
              ) : (
                <>
                  <span className="text-extra-small text-red-150">
                    Invalid password recovery code
                  </span>
                  <Button
                    title={'Reset your password again'}
                    className="mt-10"
                    IconComponent={ArrowRightLong}
                    onClick={() => setIsForgotPasswordModalOpen(true)}
                  />
                </>
              )}
            </>
          )}
          {passwordResetSuccess && (
            <>
              <span className="text-small text-green-150">
                You've successfully changed your password
              </span>
              <Button
                title={'Back to login'}
                className="mt-10"
                IconComponent={ArrowRightLong}
                onClick={() => navigate('/login')}
              />
            </>
          )}
        </div>
        <div>
          <div className="hidden h-full w-[426px] shrink bg-[url('assets/images/login-side-bg.png')] bg-cover lg:block" />
        </div>
      </div>
      <ForgotPasswordModal
        isModalOpen={isForgotPasswordModalOpen}
        setClosed={() => setIsForgotPasswordModalOpen(false)}
      />
    </>
  );
};

export default ResetPassword;
