import classNames from 'classnames';
import { FC } from 'react';
import { pillList, PillOptions } from '..';
import Pill from './Pill';

// TODO: make generalized <Pills /> component in  "/components"
const Pills: FC<{
  activePill: PillOptions;
  containerClassName?: string;
  setActivePill: React.Dispatch<React.SetStateAction<PillOptions>>;
}> = ({ activePill, containerClassName, setActivePill }) => (
  <div
    className={classNames(
      'flex w-full flex-row items-center justify-center rounded-lg bg-neutral-100 p-1',
      containerClassName,
    )}
  >
    {pillList.map((pillOption, pillIndex) => {
      const isLastPill = pillIndex === pillList.length - 1;
      return (
        <Pill
          key={pillIndex}
          pillCount={pillList.length}
          pillOption={pillOption}
          activePill={activePill}
          setActivePill={setActivePill}
          isLastPill={isLastPill}
        />
      );
    })}
  </div>
);

export default Pills;
