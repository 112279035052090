import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import {
  ProviderCollection,
  useCreateProviderCollectionMutation,
  useUpdateProviderCollectionMutation,
  useAddActivityToProviderCollectionMutation,
  ActivityDataFragment,
} from '../../../../generated/graphql';

import {
  MAX_TITLE_LENGTH,
  MIN_TEXT_LENGTH,
} from '../../../lib/form-validators';
import { onEnterKeyDown } from '../../../lib/form';

import { RefetchProviderActivities } from '../../../types/activity';
import { RefetchProviderCollections } from '../../../types/providerCollection';

import Spinner from '../../../svgs/Spinner';

import Button from '../../../components/Button';
import InputGroup from '../../../components/InputGroup';
import ToastAlert from '../../ToastAlert';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import { getProviderActivityData } from '../../../lib/providerActivity';

type CreateOrUpdateProviderCollectionModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  isCreatingNewProviderCollection: boolean;
  selectedProviderCollection: ProviderCollection | undefined;
  setSelectedProviderCollection: React.Dispatch<
    React.SetStateAction<ProviderCollection | undefined>
  >;
  refetchProviderCollections?: RefetchProviderCollections;
  selectedProviderActivity: ActivityDataFragment | undefined;
  addSelectedProviderActivityToCollection: boolean;
  setAddSelectedProviderActivityToCollection: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  refetchProviderActivities?: RefetchProviderActivities;
};

interface CreateOrUpdateProviderCollectionModalFormData {
  name: string;
}

const CreateOrUpdateProviderCollectionModalModal: FC<
  CreateOrUpdateProviderCollectionModalProps
> = ({
  isModalOpen,
  setClosed,
  isCreatingNewProviderCollection,
  selectedProviderCollection,
  setSelectedProviderCollection,
  refetchProviderCollections,
  selectedProviderActivity,
  addSelectedProviderActivityToCollection,
  setAddSelectedProviderActivityToCollection,
  refetchProviderActivities,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [
    createProviderCollectionMutation,
    { loading: createProviderCollectionMutationLoading },
  ] = useCreateProviderCollectionMutation();

  const [updateProviderCollectionMutation] =
    useUpdateProviderCollectionMutation();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors: formErrors },
    reset: resetForm,
  } = useForm<CreateOrUpdateProviderCollectionModalFormData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (!isCreatingNewProviderCollection && selectedProviderCollection?.name) {
      setValue('name', selectedProviderCollection?.name);
    } else {
      setValue('name', '');
    }
  }, [isModalOpen, isCreatingNewProviderCollection]);

  const watchName = watch('name');

  const [addActivityToProviderCollection] =
    useAddActivityToProviderCollectionMutation();

  const onSubmit: SubmitHandler<
    CreateOrUpdateProviderCollectionModalFormData
  > = async (formData: CreateOrUpdateProviderCollectionModalFormData) => {
    setSubmitting(true);
    const collectionName = watchName.trim();

    if (!collectionName) {
      setError(`name`, {
        type: 'Empty string',
        message: 'Collection needs a name',
      });
      setSubmitting(false);
      return;
    }

    if (isCreatingNewProviderCollection) {
      try {
        const { data } = await createProviderCollectionMutation({
          variables: {
            name: collectionName,
          },
        });

        if (data?.createProviderCollection) {
          closeAndResetCreateOrUpdateProviderCollectionModalModal();
          await refetchProviderCollections?.();
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Successfully added ${data?.createProviderCollection.name} to your library`}
            />
          ));

          const newProviderCollection = data?.createProviderCollection;
          setSelectedProviderCollection(newProviderCollection);

          if (
            addSelectedProviderActivityToCollection &&
            selectedProviderActivity
          ) {
            const providerActivity = getProviderActivityData(
              selectedProviderActivity,
            );

            if (
              providerActivity.activityId &&
              newProviderCollection.id &&
              selectedProviderActivity.activityType
            ) {
              const addActivityToProviderCollectionInput = {
                activityId: providerActivity.activityId,
                providerCollectionId: newProviderCollection.id,
                activityType: selectedProviderActivity.activityType,
              };

              try {
                const { data } = await addActivityToProviderCollection({
                  variables: {
                    input: addActivityToProviderCollectionInput,
                  },
                });

                if (data?.addActivityToProviderCollection) {
                  toast.custom(({ visible }) => (
                    <ToastAlert
                      isVisible={visible}
                      level="success"
                      message={`Added to collection.`}
                    />
                  ));

                  await refetchProviderActivities?.();
                  setSelectedProviderCollection(newProviderCollection);
                }
              } catch (err) {
                const errorMessage = `Couldn't add ${providerActivity?.activityTitle} to ${newProviderCollection.name}. Please try again later.`;

                toast.custom(({ visible }) => (
                  <ToastAlert
                    isVisible={visible}
                    message={errorMessage}
                    level="error"
                  />
                ));
              } finally {
                setAddSelectedProviderActivityToCollection(false);
              }
            }
          }
        }
      } catch (err) {
        setSubmitting(false);
        closeAndResetCreateOrUpdateProviderCollectionModalModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Couldn't add collection."
            level="error"
          />
        ));
      }
    } else if (selectedProviderCollection) {
      try {
        const { data } = await updateProviderCollectionMutation({
          variables: {
            input: {
              name: collectionName,
              providerCollectionId: selectedProviderCollection?.id,
            },
          },
        });

        if (data?.updateProviderCollection) {
          closeAndResetCreateOrUpdateProviderCollectionModalModal();
          await refetchProviderCollections?.();
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Successfully renamed ${data?.updateProviderCollection.name} in your library`}
            />
          ));
          setSelectedProviderCollection(data?.updateProviderCollection);
        }
      } catch (err) {
        setSubmitting(false);
        closeAndResetCreateOrUpdateProviderCollectionModalModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Couldn't rename collection."
            level="error"
          />
        ));
      }
    }
  };

  const handleErrors: SubmitErrorHandler<
    CreateOrUpdateProviderCollectionModalFormData
  > = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetCreateOrUpdateProviderCollectionModalModal = async () => {
    setClosed();
    setTimeout(() => {
      setSubmitting(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const nameRegister = register('name', {
    required: { value: true, message: 'Please give your collection a name' },
    minLength: {
      value: 1,
      message: `Please ensure the name is more than ${MIN_TEXT_LENGTH} characters`,
    },
    maxLength: {
      value: MAX_TITLE_LENGTH,
      message: `Please ensure the name is less than ${MAX_TITLE_LENGTH} characters`,
    },
  });

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetCreateOrUpdateProviderCollectionModalModal}
      width="small"
      overflow="auto"
    >
      <div className="px-10">
        <div className="mb-2 flex w-full flex-col items-center justify-center">
          <span className="font-serif text-subtitle text-green-150">
            {isCreatingNewProviderCollection ? 'Create a ' : 'Rename your '}
            collection
          </span>
        </div>

        <form className="flex w-full flex-col items-start justify-start">
          <InputGroup
            label="Collection name"
            labelHidden
            placeholder="Give your collection a name"
            inputSize="small"
            containerClassName="mt-5"
            className="w-[470px] overflow-hidden"
            iconRightClassName="cursor-pointer"
            useNaturalLettering
            onKeyDown={onEnterKeyDown}
            value={watchName}
            errorMessage={formErrors?.name?.message}
            {...nameRegister}
          />
          <Button
            className="mb-8 mt-10 flex w-full flex-row justify-center"
            title={
              !submitting
                ? `${
                    isCreatingNewProviderCollection ? `Create` : `Rename`
                  } collection`
                : `${
                    isCreatingNewProviderCollection ? `Creating` : `Renaming`
                  } collection...`
            }
            onClick={handleSubmit(onSubmit, handleErrors)}
            {...(submitting && {
              IconComponent: Spinner,
              iconClassName: 'h-[16px] w-[16px]',
            })}
            disabled={
              !Boolean(
                watchName &&
                  !formErrors?.name &&
                  !createProviderCollectionMutationLoading,
              ) || submitting
            }
          />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrUpdateProviderCollectionModalModal;
