import { FC } from 'react';
import classNames from 'classnames';

import { GlobeAltIcon } from '@heroicons/react/outline';

const CommunityResourcesButton: FC<{
  communityResourcesSelected: boolean;
  selectCommunityResources: () => void;
}> = ({ communityResourcesSelected, selectCommunityResources }) => (
  <div className="mb-2 w-full border-b border-neutral-75 py-2 pr-5">
    <div
      className={classNames(
        'flex min-h-[40px] w-full cursor-pointer flex-row items-center justify-start overflow-y-auto rounded-md pl-4 hover:rounded-md hover:bg-neutral-25',
        {
          'rounded-md bg-green-25/80 font-medium hover:bg-green-25':
            communityResourcesSelected,
        },
      )}
      onClick={selectCommunityResources}
    >
      <GlobeAltIcon className="ml-1 mr-2 h-4 w-4 text-orange-100" />
      <div className="text-caption font-medium text-neutral-150">Discover</div>
    </div>
  </div>
);

export default CommunityResourcesButton;
