import classNames from 'classnames';
import { FC } from 'react';

const QuestionItem: FC<{
  questionIndex: number;
  question: string;
  subscale?: string | null;
  answer?: string;
}> = ({ questionIndex, question, subscale, answer }) => {
  return (
    <div
      key={questionIndex}
      className={classNames(
        'flex flex-row items-start justify-start',
        answer ? 'mb-4' : 'mb-2',
      )}
    >
      <div className="mr-1 w-6 flex-none text-left font-bold">{`${
        questionIndex + 1
      }.`}</div>
      <div className="flex flex-col items-start justify-start">
        <span className="mb-0.5">
          <span>{`${question} `}</span>
          {subscale && (
            <span className="inline-flex items-center justify-between rounded-full bg-neutral-50 px-2 py-1 text-small-label font-normal text-neutral-150">
              {subscale}
            </span>
          )}
        </span>
        {answer && <span className="font-bold">{answer}</span>}
      </div>
    </div>
  );
};

export default QuestionItem;
