import { FC } from 'react';

import { ProgramTemplatesQuery } from '../../../../generated/graphql';
import TableHeader from '../../../components/TableHeader';
import { columnHeaders } from '../helpers';
import TableRow from './TableRow';

type ProgramTableProps = {
  programTemplates: ProgramTemplatesQuery['programTemplates'] | null;
};

const ProgramTable: FC<ProgramTableProps> = ({ programTemplates }) => {
  const hasPrograms = programTemplates && programTemplates.length > 0;

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden">
              <table className="min-w-full table-fixed divide-y divide-neutral-50">
                <thead>
                  <tr>
                    {columnHeaders.map((column, i) => {
                      const isFirstColumn = i === 0;
                      const isLastColumn = i === columnHeaders.length - 1;

                      return (
                        <TableHeader
                          key={`tableHeader_${column.field}`}
                          column={column}
                          className={isFirstColumn ? '!pl-3' : ''}
                          isFirstColumn={isFirstColumn}
                          isLastColumn={isLastColumn}
                        />
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {hasPrograms &&
                    programTemplates.map((programTemplate, programIndex) => {
                      return (
                        <TableRow
                          key={`tableRow_${programTemplate.id}`}
                          programTemplate={programTemplate}
                          programIndex={programIndex}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramTable;
