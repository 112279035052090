export enum DnDItemType {
  TASK = 'task',
}

export type DnDTaskItem = {
  index: number;
  id: string;
  groupId: string;
  idAttribute: string;
};

export type HoverPosition = 'top' | 'bottom';
