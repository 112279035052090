import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { LexicalEditor } from 'lexical';

import { ApolloQueryResult } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { Transition as HistoryTransition } from 'history';

import {
  BasicProviderNoteTemplateDataFragment,
  Exact,
  ProviderNoteTemplateDataFragment,
  ProviderNoteTemplatesQuery,
  useCreateProviderNoteTemplateMutation,
  useUpdateProviderNoteTemplateMutation,
} from '../../../../../generated/graphql';

import Spinner from '../../../../svgs/Spinner';

import useBlocker from '../../../../hooks/useBlocker';

import { MODAL_TRANSITION_DURATION } from '../../../../components/Modal';
import ToastAlert from '../../../../components/ToastAlert';
import ModalDialog from '../../../../components/ModalDialog';
import InputGroup from '../../../../components/InputGroup';
import Editor from '../../../../components/Editor';
import UnsavedChangesModal from '../../../../components/Modals/UnsavedChangesModal';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Avatar from '../../../../components/Avatar';

type RefetchProviderNoteTemplates = (
  variables?: Partial<
    Exact<{
      [key: string]: never;
    }>
  >,
) => Promise<ApolloQueryResult<ProviderNoteTemplatesQuery>>;

type NoteTemplateModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  clearModalData?: () => void;
  refetchProviderNoteTemplates: RefetchProviderNoteTemplates;
  selectedNoteTemplate?:
    | ProviderNoteTemplateDataFragment
    | BasicProviderNoteTemplateDataFragment;
  viewOnly?: boolean;
  createNewProgramNote: (title?: string, note?: string) => Promise<void>;
  providerOwnsTemplate;
};

interface NoteTemplateFormData {
  title: string;
  note: string;
  isSharedNoteTemplate: boolean;
}

function isProviderNoteTemplateDataFragment(
  template: any,
): template is ProviderNoteTemplateDataFragment {
  return template !== undefined && template.note !== undefined;
}

const NoteTemplateModal: FC<NoteTemplateModalProps> = ({
  isModalOpen,
  setClosed,
  clearModalData,
  refetchProviderNoteTemplates,
  selectedNoteTemplate,
  viewOnly = false,
  createNewProgramNote,
  providerOwnsTemplate,
}) => {
  const noteTemplateId = selectedNoteTemplate?.id;
  const title = selectedNoteTemplate?.title;
  const note = isProviderNoteTemplateDataFragment(selectedNoteTemplate)
    ? selectedNoteTemplate.note
    : '';

  const isSharedNoteTemplate = selectedNoteTemplate?.isSharedNoteTemplate;

  const isExistingNoteTemplate = noteTemplateId;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();

  const [
    createProviderNoteTemplateMutation,
    { loading: createProviderNoteTemplateMutationLoading },
  ] = useCreateProviderNoteTemplateMutation();

  const [
    updateProviderNoteTemplateMutation,
    { loading: updateProviderNoteTemplateMutationLoading },
  ] = useUpdateProviderNoteTemplateMutation();

  const editorRef = useRef<LexicalEditor | null>(null);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setFocus,
    formState: { isDirty, errors: validationErrors },
  } = useForm<NoteTemplateFormData>({
    mode: 'all',
    defaultValues: { title: '', note: '', isSharedNoteTemplate: false },
    values: {
      title: title ?? '',
      note: note ?? '',
      isSharedNoteTemplate: isSharedNoteTemplate ?? false,
    },
  });

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setFocus('title');
      }, 50);
    }
  }, [isModalOpen]);

  const closeAndResetNoteTemplateModal = async () => {
    setClosed();
    setTimeout(() => {
      clearModalData?.();
      setSubmitting(false);
      reset();
    }, MODAL_TRANSITION_DURATION);
  };

  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAndUsing, setIsSavingAndUsing] = useState(false);

  type NoteTemplateAction = 'use' | 'save' | 'saveAndUse';

  const onSubmit =
    (
      noteTemplateAction: NoteTemplateAction,
    ): SubmitHandler<NoteTemplateFormData> =>
    async (formData: NoteTemplateFormData) => {
      setSubmitting(true);

      const title = formData.title.trim();
      const note = formData.note;
      const isSharedNoteTemplate = formData.isSharedNoteTemplate;

      const providerNoteTemplateInput = {
        ...(noteTemplateId && { providerNoteTemplateId: noteTemplateId }),
        title,
        note,
        isSharedNoteTemplate,
      };

      try {
        if (isExistingNoteTemplate && noteTemplateId) {
          if (noteTemplateAction === 'use') {
            await createNewProgramNote(title, note);
            closeAndResetNoteTemplateModal();
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                level="success"
                message={`Successfully made a note from ${title}`}
              />
            ));
          } else {
            const { data } = await updateProviderNoteTemplateMutation({
              variables: {
                input: providerNoteTemplateInput,
              },
            });

            if (data?.updateProviderNoteTemplate) {
              refetchProviderNoteTemplates?.();
              closeAndResetNoteTemplateModal();
              toast.custom(({ visible }) => (
                <ToastAlert
                  isVisible={visible}
                  level="success"
                  message={`Successfully edited ${data.updateProviderNoteTemplate.title}`}
                />
              ));
              if (noteTemplateAction === 'saveAndUse') {
                const { title, note } = data?.updateProviderNoteTemplate;
                await createNewProgramNote(title, note);
              }
            }
          }
        } else {
          const { data } = await createProviderNoteTemplateMutation({
            variables: {
              input: providerNoteTemplateInput,
            },
          });

          if (data?.createProviderNoteTemplate) {
            refetchProviderNoteTemplates?.();
            closeAndResetNoteTemplateModal();

            if (noteTemplateAction === 'saveAndUse') {
              const { title, note } = data?.createProviderNoteTemplate;
              await createNewProgramNote(title, note);
              toast.custom(({ visible }) => (
                <ToastAlert
                  isVisible={visible}
                  level="success"
                  message={`Successfully edited and made a note from ${data.createProviderNoteTemplate.title}`}
                />
              ));
            } else {
              toast.custom(({ visible }) => (
                <ToastAlert
                  isVisible={visible}
                  level="success"
                  message={`Successfully edited ${data.createProviderNoteTemplate.title}`}
                />
              ));
            }
          }
        }
      } catch (err) {
        closeAndResetNoteTemplateModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      } finally {
        setSubmitting(false);
        setIsSaving(false);
        setIsSavingAndUsing(false);
      }
    };

  const handleErrors: SubmitErrorHandler<NoteTemplateFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setNavTransition(navTransition);
        setIsUnsavedChangesModalOpen(true);
      }
    },
    [isDirty],
  );

  useBlocker(blocker, isDirty);

  return (
    <ModalDialog
      title={
        !viewOnly
          ? `${isExistingNoteTemplate ? 'Edit' : 'Create'} a note template`
          : 'Shared note template'
      }
      isOpen={isModalOpen}
      setClosed={() => {
        if (isDirty) {
          setNavTransition(undefined);
          setIsUnsavedChangesModalOpen(true);
        } else {
          closeAndResetNoteTemplateModal();
        }
      }}
      bodyClassName="pb-0 pt-0"
      leftFooter={
        <div className="flex w-full flex-row items-center justify-between">
          {isExistingNoteTemplate && !providerOwnsTemplate ? (
            <>
              <div className="flex flex-row items-center text-neutral-125">
                <div className="mr-2.5">From </div>
                <div className="flex flex-row items-center">
                  <Avatar
                    size="small"
                    className="mr-1.5"
                    name={selectedNoteTemplate?.createdByProviderUser.name}
                    imageUrl={
                      selectedNoteTemplate?.createdByProviderUser
                        ?.profileImageMedia?.url
                    }
                  />
                  <div className="font-medium">
                    {selectedNoteTemplate?.createdByProviderUser?.name}
                  </div>
                </div>
              </div>
              <Button
                title={
                  !isSubmitting || !isSavingAndUsing
                    ? 'New note from template'
                    : 'Copying...'
                }
                onClick={() => {
                  setIsSavingAndUsing(true);
                  handleSubmit(onSubmit('use'), handleErrors)();
                }}
                disabled={Boolean(
                  isSaving ||
                    createProviderNoteTemplateMutationLoading ||
                    updateProviderNoteTemplateMutationLoading ||
                    isSubmitting ||
                    watch('title').trim() === '' ||
                    validationErrors.title ||
                    validationErrors.note,
                )}
                {...(isSubmitting && setIsSavingAndUsing
                  ? {
                      IconComponent: Spinner,
                      iconClassName: 'h-[16px] w-[16px]',
                    }
                  : {})}
              />
            </>
          ) : (
            <>
              <Controller
                control={control}
                name={'isSharedNoteTemplate'}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      id={'isSharedNoteTemplate'}
                      checked={value}
                      onChange={onChange}
                      className="mr-4"
                      labelContent="Share this template with my team"
                      disabled={isExistingNoteTemplate && !providerOwnsTemplate}
                    />
                  );
                }}
              />
              <div className="flex flex-row justify-end">
                <Button
                  theme="secondary"
                  title={
                    isExistingNoteTemplate
                      ? !isSubmitting || !isSavingAndUsing
                        ? 'Save changes and use template'
                        : 'Saving...'
                      : !isSubmitting || !isSavingAndUsing
                      ? `Save and use template`
                      : 'Saving...'
                  }
                  onClick={() => {
                    setIsSavingAndUsing(true);
                    handleSubmit(onSubmit('saveAndUse'), handleErrors)();
                  }}
                  disabled={Boolean(
                    isSaving ||
                      createProviderNoteTemplateMutationLoading ||
                      updateProviderNoteTemplateMutationLoading ||
                      isSubmitting ||
                      watch('title').trim() === '' ||
                      validationErrors.title ||
                      validationErrors.note,
                  )}
                  {...(isSubmitting && setIsSavingAndUsing
                    ? {
                        IconComponent: Spinner,
                        iconClassName: 'h-[16px] w-[16px]',
                      }
                    : {})}
                />
                <Button
                  className="ml-3"
                  title={
                    isExistingNoteTemplate
                      ? !isSubmitting || !isSaving
                        ? 'Save changes'
                        : 'Saving...'
                      : !isSubmitting || !isSaving
                      ? `Save note template`
                      : 'Adding...'
                  }
                  onClick={() => {
                    setIsSaving(true);
                    handleSubmit(onSubmit('save'), handleErrors)();
                  }}
                  disabled={Boolean(
                    isSavingAndUsing ||
                      createProviderNoteTemplateMutationLoading ||
                      updateProviderNoteTemplateMutationLoading ||
                      isSubmitting ||
                      watch('title').trim() === '' ||
                      validationErrors.title ||
                      validationErrors.note,
                  )}
                  {...(isSubmitting && isSaving
                    ? {
                        IconComponent: Spinner,
                        iconClassName: 'h-[16px] w-[16px]',
                      }
                    : {})}
                />
              </div>
            </>
          )}
        </div>
      }
    >
      <div className="flex w-full flex-col">
        <InputGroup
          required
          labelHidden
          autoFocus
          backgroundHidden
          label="Note template title"
          placeholder="Untitled note template"
          className="rounded-none border-0 border-b-2 px-0 pb-1 font-serif text-big-label text-neutral-150 disabled:cursor-text disabled:text-neutral-150"
          errorMessage={validationErrors.title?.message}
          {...register('title', {
            required: true,
          })}
          disabled={viewOnly}
        />

        <Controller name="note" control={control} render={() => <></>} />
        <Editor
          initialContentMarkdown={note}
          elKey={noteTemplateId}
          readOnly={viewOnly}
          placeholder="Write your note template..."
          className="text-neutral-150"
          contentClassName="h-[calc(100vh-400px)] overflow-y-auto"
          innerRef={editorRef}
          onChange={(editorState) => {
            editorState.read(() => {
              // Keep form field in sync with editor state for validation
              setValue('note', $convertToMarkdownString(TRANSFORMERS), {
                shouldDirty: true,
              });
            });
          }}
          onError={(error: Error) => {
            console.error(error);
          }}
        />
      </div>
      {/* Note: This modal must be nested within the main modal for stacking
        logic to function properly */}
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => {
          setIsUnsavedChangesModalOpen(false);
          closeAndResetNoteTemplateModal();
          navTransition?.retry();
        }}
      />
    </ModalDialog>
  );
};

export default NoteTemplateModal;
