import { FC } from 'react';
import classNames from 'classnames';

import Button from './Button';

import { ColumnHeaderType, ColumnOrder } from '../types/tables';
import ChevronRight from '../svgs/ChevronRight';
import { sortOptions as patientManagementSortOptions } from '../pages/PatientManagement/helpers';

const TableHeader: FC<{
  column: ColumnHeaderType;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  sortOptions?: Record<string, ColumnOrder>;
  handleHeaderClick?: (column: ColumnHeaderType) => void;
  className?: string;
  previousColumnClicked?: ColumnHeaderType | null | undefined;
  columnOrder?: ColumnOrder;
}> = ({
  column,
  sortOptions = patientManagementSortOptions,
  isFirstColumn = false,
  className = '',
  columnOrder,
  previousColumnClicked,
  handleHeaderClick,
}) => {
  const isSortable = column?.isSortable || false;

  const isSortOrderInColumn = Boolean(
    column.columnOrders?.find((order) => order.name === columnOrder?.name),
  );

  const isSortOrderNewestCreated =
    columnOrder?.name === sortOptions.newestCreated.name;

  let iconProps = {};
  if (isSortable && isSortOrderInColumn && !isSortOrderNewestCreated) {
    iconProps = {
      IconComponent: ChevronRight,
      iconClassName: classNames(
        'h-[16px] w-[16px] rotate-90',
        columnOrder?.isAscending
          ? 'hover:text-green-100 rotate-90'
          : columnOrder?.isDescending
          ? '-rotate-90 hover:text-green-100'
          : 'invisible',
      ),
    };
  }

  return (
    <th
      scope="col"
      className={classNames(
        'text-left font-semibold text-green-150',
        isFirstColumn && 'pl-0',
      )}
    >
      {isSortable ? (
        <Button
          onClick={() => handleHeaderClick && handleHeaderClick(column)}
          title={column.headerName}
          {...iconProps}
          noFocus
          className={classNames(
            'text-left text-green-150 hover:text-green-100',
            className,
          )}
          theme="secondary"
          size="extra-small"
          noBackground
          noOutline
        />
      ) : (
        <div className={classNames('text-left text-small-caption', className)}>
          {column.headerName}
        </div>
      )}
    </th>
  );
};

export default TableHeader;
