import { FC } from 'react';

import {
  AssessmentQuestionType,
  AssessmentSignatureUserInput,
} from '../../../../generated/graphql';

export type Question = {
  questionType: AssessmentQuestionType;
  isRequired: boolean;
  question: string;
  answerOptions: string[];
  assessmentUserOrderIndices: number[];
};

export type QuestionForm = {
  name: string;
  description: string;
  questions: Question[];
  assessmentSignatureUsers?: AssessmentSignatureUserInput[];
};

export const QuestionTypeValueHash = {
  [AssessmentQuestionType.MultipleChoice]: 'Multiple choice',
  [AssessmentQuestionType.MultiSelect]: 'Multiple select',
  [AssessmentQuestionType.FreeText]: 'Short text',
  [AssessmentQuestionType.RichText]: 'Long text',
  [AssessmentQuestionType.Signature]: 'Client signature',
  [AssessmentQuestionType.MultiSignature]: 'Multiple signature',
  [AssessmentQuestionType.Statement]: 'Statement (read only)',
};

export const SUPPORTED_QUESTION_TYPE_OPTIONS = Object.keys(
  QuestionTypeValueHash,
);

export const QuestionTypeItem: FC<{
  value:
    | AssessmentQuestionType.FreeText
    | AssessmentQuestionType.MultipleChoice;
}> = ({ value }) => (
  <span className="text-caption">{QuestionTypeValueHash[value]}</span>
);
