import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import Avatar from '../Avatar';
import { ProgramFollowUpQuery } from '../../../generated/graphql';
import { getRelativeTimestamp } from '../../lib/time';
import AudioPlayer from '../AudioPlayer';
import CommentActionsMenu from './CommentActionsMenu';
import { CommentPredicate } from './ConversationContainer';
import Linkifier from '../Linkifier';
import { useAuth } from '../../../contexts/AuthContext';

const Comment: FC<{
  comment: ProgramFollowUpQuery['programFollowUp']['comments'][number];
  onCommentDeleted?: (commentId?: string) => void;
  className?: string;
  canPerformActionsOnComment: CommentPredicate;
}> = ({ comment, onCommentDeleted, className, canPerformActionsOnComment }) => {
  const { authedProviderUser } = useAuth();

  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);

  const relativeCommentTimestamp = useMemo(() => {
    return getRelativeTimestamp(comment.createdAt);
  }, [comment.createdAt]);

  return (
    <div
      className={classNames('flex w-full flex-row gap-x-4', className)}
      onMouseEnter={() => setShowActionsMenu(true)}
      onMouseLeave={() => {
        setShowActionsMenu(false);
        setIsActionsMenuOpen(false);
      }}
    >
      <Avatar
        size="medium"
        imageUrl={comment.sentByProfileImageUrl}
        name={comment.sentByName ?? ''}
      />
      <div className="flex w-full flex-col">
        <div className="flex h-6 flex-row items-center gap-x-3">
          <div className="text-caption font-bold text-neutral-150">
            {comment.sentByName}
          </div>
          <div className="grow text-caption text-neutral-110">
            {relativeCommentTimestamp}
          </div>
          {canPerformActionsOnComment(comment, authedProviderUser?.id) && (
            <CommentActionsMenu
              commentId={comment.id}
              isOpen={isActionsMenuOpen}
              setIsOpen={setIsActionsMenuOpen}
              onCommentDeleted={onCommentDeleted}
              className={classNames(
                showActionsMenu ? 'visible' : 'invisible',
                'mr-4',
              )}
            />
          )}
        </div>
        {comment.voiceNote?.media.url ? (
          <div className="w-full">
            <AudioPlayer
              playerId={`comment-${comment.id}`}
              mediaUrl={comment.voiceNote.media.url}
              duration={comment.voiceNote.duration ?? 0}
              defaultStyle={false}
              triggerStop
              className={'visible mt-4 h-[48px] px-4 py-8'}
            />
          </div>
        ) : (
          <div className="w-full whitespace-pre-wrap break-words text-caption text-neutral-150">
            <Linkifier text={comment.text} />
          </div>
        )}
        {comment.imageMedia && (
          <div>
            <a
              href={comment.imageMedia.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={comment.imageMedia.url}
                alt="Attachment"
                className="my-2 max-h-[200px]"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
