import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const FeatureCell: FC<{
  value?: string | boolean | null;
  children?: ReactNode;
  className?: string;
}> = ({ value, children, className }) => {
  let content;

  if (value === true) {
    content = (
      <CheckCircleIcon className="h-5 w-5 text-orange-100" aria-hidden="true" />
    );
  } else if (value === false || value === null) {
    content = '-';
  } else {
    content = value;
  }

  if (children) {
    content = children;
  }

  return (
    <div
      className={classNames(
        'flex py-6 px-10 text-body text-green-150',
        className,
      )}
    >
      {content}
    </div>
  );
};

export default FeatureCell;
