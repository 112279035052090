import { FC } from 'react';
import classNames from 'classnames';
import { UseFieldArrayUpdate, UseFormSetValue } from 'react-hook-form';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusSmIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import {
  AssessmentSignatureUserInput,
  SignatureUser,
} from '../../../../generated/graphql';

import {
  Question,
  QuestionForm,
} from '../../../pages/FormBuilder/QuestionContainer/helpers';
import {
  getColorForSignatureUserType,
  SignatureUserTypeLabel,
} from '../../../lib/multiSignature';

import ModalDialog from '../../ModalDialog';
import IconButton from '../../IconButton';
import Button from '../../Button';

const SignatureSettingsModal: FC<{
  setClosed: () => void;
  isOpen: boolean;
  watchAssessmentSignatureUsers: AssessmentSignatureUserInput[];
  watchQuestions: Question[];
  setValue: UseFormSetValue<QuestionForm>;
  update: UseFieldArrayUpdate<QuestionForm, 'questions'>;
  currentQuestionIndex: number | null;
}> = ({
  isOpen,
  setClosed,
  watchAssessmentSignatureUsers,
  watchQuestions,
  setValue,
  update,
  currentQuestionIndex,
}) => {
  // Enforce rules: max 3, only 1 client, 1 sending practitioner
  const hasSendingProviderUser = watchAssessmentSignatureUsers.some(
    (user) => user.signatureUserType === SignatureUser.SendingProviderUser,
  );

  const addSignatureUser = (signatureUserType: SignatureUser): void => {
    if (
      (signatureUserType === SignatureUser.SendingProviderUser &&
        hasSendingProviderUser) ||
      watchAssessmentSignatureUsers.length >= 3
    ) {
      return;
    }

    const newAssessmentSignatureUser: AssessmentSignatureUserInput = {
      signatureUserType,
      orderIndex: watchAssessmentSignatureUsers.length,
    };

    const updatedAssessmentSignatureUsers = [
      ...watchAssessmentSignatureUsers,
      newAssessmentSignatureUser,
    ];

    const updatedQuestions = watchQuestions.map((question) => ({
      ...question,
      assessmentUserOrderIndices: [
        ...question.assessmentUserOrderIndices,
        newAssessmentSignatureUser.orderIndex,
      ].sort((a, b) => a - b),
    }));

    setValue('assessmentSignatureUsers', updatedAssessmentSignatureUsers);
    setValue('questions', updatedQuestions);
  };

  const removeSignatureUser = (orderIndexToRemove: number) => {
    const userToRemove = watchAssessmentSignatureUsers.find(
      (user) => user.orderIndex === orderIndexToRemove,
    );

    if (userToRemove?.signatureUserType === SignatureUser.PatientUser) {
      alert('Cannot remove the Client.');
      return;
    }

    const updatedAssessmentSignatureUsers = watchAssessmentSignatureUsers
      .filter((user) => user.orderIndex !== orderIndexToRemove)
      .map((user, index) => ({ ...user, orderIndex: index }));

    // Update questions to remove the index and adjust higher indices
    const updatedQuestions = watchQuestions.map((question) => {
      const updatedSigners = question.assessmentUserOrderIndices
        .filter(
          (assessmentUserOrderIndex) =>
            assessmentUserOrderIndex !== orderIndexToRemove,
        )
        .map((assessmentUserOrderIndex) =>
          assessmentUserOrderIndex > orderIndexToRemove
            ? assessmentUserOrderIndex - 1
            : assessmentUserOrderIndex,
        );
      return { ...question, assessmentUserOrderIndices: updatedSigners };
    });

    setValue('assessmentSignatureUsers', updatedAssessmentSignatureUsers);
    setValue('questions', updatedQuestions);
  };

  const moveSignatureUser = (orderIndex: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? orderIndex - 1 : orderIndex + 1;
    if (newIndex < 0 || newIndex >= watchAssessmentSignatureUsers.length)
      return;

    const updatedUsers = [...watchAssessmentSignatureUsers];
    const [movedUser] = updatedUsers.splice(orderIndex, 1);
    updatedUsers.splice(newIndex, 0, movedUser);

    const updatedQuestions = watchQuestions.map((question) => ({
      ...question,
      assessmentUserOrderIndices: question.assessmentUserOrderIndices.map(
        (index) => {
          if (index === orderIndex) return newIndex;
          if (index === newIndex) return orderIndex;
          return index;
        },
      ),
    }));

    setValue(
      'assessmentSignatureUsers',
      updatedUsers.map((user, index) => ({ ...user, orderIndex: index })),
    );
    setValue('questions', updatedQuestions);
  };

  const handleSave = () => {
    setClosed();
  };

  const maxUsersReached = watchAssessmentSignatureUsers.length >= 3;

  return (
    <ModalDialog
      title="Signer settings"
      width="small"
      isOpen={isOpen}
      setClosed={handleSave}
      bodyClassName="min-h-[400px] flex flex-col justify-start"
      leftFooter={
        <div className="flex w-full flex-row items-center justify-start text-small-caption text-neutral-125">
          <SparklesIcon className="mr-2 h-5 w-5" />
          <div className="w-5/6">
            The signing order is consistent throughout the form. Signers will be
            notified and receive the form after the previous signer has signed
            and completed the form. Max 3 signers per form.
          </div>
        </div>
      }
      primaryActionTitle="Save"
      primaryActionOnClick={handleSave}
      primaryActionProps={{
        size: 'small',
      }}
    >
      <div className="mb-3 text-caption">
        Specify the signers and set their completion order with the arrows:
      </div>
      <div>
        <div className="mb-4 flex w-full flex-row items-center justify-start">
          {Object.values(SignatureUser)
            .filter((userType) => userType !== SignatureUser.PatientUser)
            .map((userType) => (
              <Button
                key={userType}
                size="small"
                className="mr-3"
                iconPosition="left"
                theme="secondary"
                IconComponent={PlusSmIcon}
                title={SignatureUserTypeLabel[userType]}
                onClick={() => addSignatureUser(userType)}
                disabled={
                  (userType === SignatureUser.SendingProviderUser &&
                    hasSendingProviderUser) ||
                  maxUsersReached
                }
              />
            ))}
        </div>
        <div>
          {watchAssessmentSignatureUsers?.map(
            (assessmentSignatureUser, index) => (
              <div
                key={assessmentSignatureUser.orderIndex}
                className="flex w-full flex-row items-center justify-between"
              >
                <div className="mb-3 flex w-full items-center justify-between rounded-xl border border-neutral-50 pr-4">
                  <div
                    className={classNames(
                      'flex flex-grow items-center rounded-l-xl border-l-[14px] p-4',
                      getColorForSignatureUserType(
                        assessmentSignatureUser.signatureUserType,
                      ).borderColor,
                    )}
                  >
                    <div
                      className={classNames(
                        'mr-4 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md text-body text-neutral-125',
                        getColorForSignatureUserType(
                          assessmentSignatureUser.signatureUserType,
                        ).backgroundColor,
                      )}
                    >
                      {assessmentSignatureUser.orderIndex + 1}
                    </div>
                    <span className="flex-grow text-body text-neutral-125">
                      {
                        SignatureUserTypeLabel[
                          assessmentSignatureUser.signatureUserType
                        ]
                      }
                    </span>
                  </div>
                  {assessmentSignatureUser.signatureUserType !==
                    SignatureUser.PatientUser && (
                    <IconButton
                      IconComponent={TrashIcon}
                      aria-label="Remove form signer"
                      iconClassName="text-gray-500 h-5 w-5"
                      onClick={() =>
                        removeSignatureUser(assessmentSignatureUser.orderIndex)
                      }
                    />
                  )}
                </div>
                <div className="flex flex-col pb-2">
                  {index > 0 && (
                    <IconButton
                      IconComponent={ChevronUpIcon}
                      aria-label="Move form signer up"
                      iconClassName="text-gray-500 h-5 w-5"
                      className="mx-2"
                      onClick={() =>
                        moveSignatureUser(
                          assessmentSignatureUser.orderIndex,
                          'up',
                        )
                      }
                    />
                  )}
                  {index < watchAssessmentSignatureUsers.length - 1 && (
                    <IconButton
                      IconComponent={ChevronDownIcon}
                      aria-label="Move form signer down"
                      iconClassName="text-gray-500 h-5 w-5"
                      className="mx-2"
                      onClick={() =>
                        moveSignatureUser(
                          assessmentSignatureUser.orderIndex,
                          'down',
                        )
                      }
                    />
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </ModalDialog>
  );
};

export default SignatureSettingsModal;
