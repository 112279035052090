import { FC } from 'react';
import { MicrophoneIcon } from '@heroicons/react/outline';
import { getDurationMSS } from '../lib/time';

const VoiceNoteLabel: FC<{
  duration: number;
}> = ({ duration }) => {
  return (
    <div className="inline-flex flex-row items-center justify-center gap-x-1 rounded-full bg-blue-25 px-2 py-1">
      <MicrophoneIcon className="h-4 w-4 shrink-0 text-neutral-150" />
      <div className="text-small-caption font-medium text-neutral-150">
        {getDurationMSS(duration)}
      </div>
    </div>
  );
};

export default VoiceNoteLabel;
