import { UseFieldArrayRemove } from 'react-hook-form';
import { StagedProgramTagDataFragment } from '../components/ProgramTag/ProgramTagManager';

export function removeEmptyFields(
  controlledFields: Record<
    string,
    string | Date | null | StagedProgramTagDataFragment[]
  >[],
  remove: UseFieldArrayRemove,
) {
  let fieldsToRemove = controlledFields
    .map((field, index) => index)
    .filter((fieldIndex) => {
      // Only remove fields whose values are all empty.
      return Object.entries(controlledFields[fieldIndex]).every(
        // id is a react-hook-form key that will always be present.
        ([key, value]) =>
          !value || key === 'id' || (Array.isArray(value) && !value.length),
      );
    });

  if (fieldsToRemove.length === controlledFields.length) {
    // If we're about to remove all fields, keep the first one even if it's empty.
    fieldsToRemove = fieldsToRemove.slice(1);
  }

  remove(fieldsToRemove);
}

export const onEnterKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const isEnterKey = (key: string) => key === 'Enter';
export const isArrowUpKey = (key: string) => key === 'ArrowUp';
export const isBackspaceKey = (key: string) => key === 'Backspace';
export const isArrowDownKey = (key: string) => key === 'ArrowDown';

export const resizeTextArea = (
  ref: React.MutableRefObject<HTMLTextAreaElement | null>,
) => {
  if (ref.current && ref.current.style) {
    ref.current.style.height = 'auto';
    ref.current.style.height = `${ref.current.scrollHeight + 4}px`;
  }
};
