import classNames from 'classnames';
import { FC } from 'react';

import { FollowUpTemplateDataFragment } from '../../../../generated/graphql';
import {
  AnalyticsComponent,
  AnalyticsPage,
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';
import FollowUpTemplateCardActionButton from './FollowUpTemplateCardActionButton';

const FollowUpTemplateCard: FC<{
  followUpTemplate: FollowUpTemplateDataFragment;
  onApplyFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onRenameFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onDuplicateFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onDeleteFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  className?: string;
}> = ({
  followUpTemplate,
  onApplyFollowUpTemplate,
  onRenameFollowUpTemplate,
  onDuplicateFollowUpTemplate,
  onDeleteFollowUpTemplate,
  className,
}) => {
  const handleCardClick = () => {
    trackProviderEvent(ProviderAnalyticsEvent.MailTemplateApplied, {
      originPage: AnalyticsPage.FollowUpBuilder,
      originComponent: AnalyticsComponent.FollowUpDrawer,
      mailTemplateType: 'template',
    });
    onApplyFollowUpTemplate(followUpTemplate);
  };

  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-col rounded-lg bg-white p-5 shadow-100 hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-green-50',
        className,
      )}
      // Role, tab index, and onKeyDown are necessary to recreate button accessibility in div
      role="button"
      tabIndex={0}
      onClick={handleCardClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCardClick();
        }
      }}
    >
      <div className="flex items-center justify-between">
        <div className="text-caption font-medium text-neutral-150">
          {followUpTemplate.name}
        </div>
        <FollowUpTemplateCardActionButton
          followUpTemplate={followUpTemplate}
          onClickRenameTemplate={onRenameFollowUpTemplate}
          onClickDuplicateTemplate={onDuplicateFollowUpTemplate}
          onClickDeleteTemplate={onDeleteFollowUpTemplate}
        />
      </div>
    </div>
  );
};

export default FollowUpTemplateCard;
