import { FC, useState, useMemo } from 'react';
import classNames from 'classnames';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';

import {
  PatientLifecycleStatus,
  PatientQuery,
  PatientsQuery,
  useSendProgramInvitesToPatientsMutation,
} from '../../../../generated/graphql';

import omit from 'lodash.omit';

import Alert from '../../../components/Alert';

import Button from '../../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';

import { useNavigate } from 'react-router-dom';

import {
  PatientsObject,
  getPatientsObject,
  validatePatientsForMutation,
  getInvalidSentenceText,
  getSeatLimitAlertText,
} from '../helpers';

import useAlertState from '../useAlertState';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline';
import { PATIENTS_INVITED_BASE_PATH } from '..';

type InviteToProgramModalProps = {
  isModalOpen: boolean;
  singlePatient: PatientQuery['patient'] | null;
  patientsObject?: PatientsObject | null;
  isSinglePatient?: boolean;
  isPatientManagement?: boolean;
  selectedPatientsList: PatientsQuery['patients'];
  patientLifecycleStatus: PatientLifecycleStatus | null;
  setClosed: () => void;
  clearFilters?: () => void;
  refetchPatient?: () => Promise<unknown>;
  setPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
  setSelectedPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject>
  >;
};

const InviteToProgramModal: FC<InviteToProgramModalProps> = ({
  isModalOpen,
  singlePatient,
  patientsObject,
  isSinglePatient = false,
  isPatientManagement = false,
  selectedPatientsList,
  patientLifecycleStatus,
  setClosed,
  clearFilters,
  refetchPatient,
  setPatientsObject,
  setSelectedPatientsObject,
}) => {
  const { handleSubmit, reset: resetForm } = useForm({
    mode: 'onSubmit',
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [clientSeatError, setClientSeatError] = useState<boolean>(false);
  const [clientSeatErrorSeatsRemaining, setClientSeatErrorSeatsRemaining] =
    useState<number>(0);

  const [sendProgramInvitesToPatientsMutation] =
    useSendProgramInvitesToPatientsMutation();

  const { validProgramInstanceIds, invalidPatients } =
    validatePatientsForMutation(
      singlePatient ? [singlePatient] : selectedPatientsList,
      'inviteToProgram',
    );

  const invalidPatientsText = getInvalidSentenceText(invalidPatients);
  const { showAlert, setShowAlert } = useAlertState(invalidPatients);

  const buttonTitleText = useMemo(() => {
    return showAlert
      ? 'Send to eligible clients'
      : singlePatient
      ? `Send now to ${singlePatient?.firstName}`
      : 'Send now';
  }, [showAlert, singlePatient]);

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (!validProgramInstanceIds) return;
    const sendProgramInvitesToPatientsInput = {
      programInstanceIds: validProgramInstanceIds,
    };

    try {
      setLoading(true);
      const response = await sendProgramInvitesToPatientsMutation({
        variables: {
          input: sendProgramInvitesToPatientsInput,
        },
      });
      const updatedPatients =
        response?.data?.sendProgramInvitesToPatients?.updatedPatients;
      const clientSeatsRemaining =
        response?.data?.sendProgramInvitesToPatients.clientSeatLimitError
          ?.seatsRemaining;

      if (clientSeatsRemaining != null) {
        setClientSeatError(true);
        setClientSeatErrorSeatsRemaining(clientSeatsRemaining);
        setLoading(false);
        return;
      } else if (
        patientsObject &&
        updatedPatients &&
        updatedPatients?.length > 0
      ) {
        if (PatientLifecycleStatus.Onboarding === patientLifecycleStatus) {
          const updatedPatientsKeys = Object.keys(
            getPatientsObject(updatedPatients),
          );

          const remainingPatients = omit(patientsObject, updatedPatientsKeys);

          if (isPatientManagement && setPatientsObject)
            setPatientsObject(remainingPatients);
        } else if (
          patientsObject &&
          patientLifecycleStatus === PatientLifecycleStatus.Invited
        ) {
          const updatedPatientsObject = {
            ...patientsObject,
            ...getPatientsObject(updatedPatients),
          };

          if (isPatientManagement && setPatientsObject)
            setPatientsObject(updatedPatientsObject);
        }

        if (isPatientManagement && setSelectedPatientsObject && clearFilters) {
          setSelectedPatientsObject({});
          clearFilters();
          navigate(PATIENTS_INVITED_BASE_PATH);
        }
      } else if (isSinglePatient && refetchPatient) {
        await refetchPatient();
      }
      closeAndResetInviteToProgramModal();
      setLoading(false);
    } catch (err) {
      closeAndResetInviteToProgramModal();
      setLoading(false);
      alert(err);
    }
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetInviteToProgramModal = () => {
    setClosed();
    setTimeout(() => {
      setShowAlert(false);
      setClientSeatError(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetInviteToProgramModal}
      fetching={loading}
      width="small"
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-between px-10 pb-4',
        )}
      >
        <div className="flex flex-col items-center justify-start ">
          <div className="mb-2 font-serif text-subtitle text-green-150">
            Invite to program
          </div>
          <div className="font-sans text-small text-green-125">
            {singlePatient
              ? `Send an invite email to ${singlePatient?.firstName}`
              : `Send an invite email to ${validProgramInstanceIds.length} of your clients`}
          </div>
        </div>
        <form className="flex w-full flex-col items-center justify-end">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-96">
              {showAlert && !clientSeatError && (
                <Alert
                  message={`We cannot send ${invalidPatientsText} an invite
              since they either still need program assignment or already have already received their program invite.`}
                />
              )}
              {clientSeatError && (
                <Alert
                  message={''}
                  children={
                    <>
                      <div className={classNames('mb-2')}>
                        {getSeatLimitAlertText(
                          clientSeatErrorSeatsRemaining,
                          validProgramInstanceIds.length,
                        )}
                      </div>
                      <div>
                        You can keep inviting clients up to your seat limit or
                        reach out to us at support@homecoming.health to discuss
                        upgrading your plan!
                      </div>
                    </>
                  }
                />
              )}
            </div>
            {clientSeatError ? (
              <Button
                className="my-10"
                type="button"
                title="Back to your clients"
                theme="primary"
                IconComponent={ArrowNarrowLeftIcon}
                iconPosition="left"
                onClick={closeAndResetInviteToProgramModal}
              />
            ) : (
              <Button
                className="my-10"
                type="submit"
                title={buttonTitleText}
                theme="primary"
                IconComponent={ArrowNarrowRightIcon}
                onClick={handleSubmit(onSubmit, handleErrors)}
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InviteToProgramModal;
