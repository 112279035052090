import { SvgIconComponent } from '../types/svgs';

const StripeLogo: SvgIconComponent = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="36"
      fill="none"
      viewBox="0 0 87 36"
      className={className}
    >
      <path
        fill="#635BFF"
        fillRule="evenodd"
        d="M86.515 18.6c0-6.152-2.98-11.006-8.675-11.006-5.72 0-9.18 4.854-9.18 10.958 0 7.234 4.085 10.887 9.949 10.887 2.86 0 5.022-.65 6.657-1.562V23.07c-1.635.817-3.51 1.322-5.888 1.322-2.331 0-4.398-.817-4.662-3.653h11.751c0-.312.048-1.562.048-2.139zm-11.872-2.283c0-2.715 1.659-3.845 3.173-3.845 1.466 0 3.028 1.13 3.028 3.845h-6.2zm-15.26-8.723c-2.355 0-3.87 1.105-4.71 1.874l-.313-1.49h-5.287V36l6.008-1.273.024-6.801c.866.625 2.14 1.514 4.254 1.514 4.302 0 8.219-3.46 8.219-11.079-.024-6.97-3.99-10.766-8.195-10.766zm-1.442 16.558c-1.418 0-2.259-.505-2.836-1.13l-.024-8.916c.625-.697 1.49-1.177 2.86-1.177 2.187 0 3.701 2.451 3.701 5.6 0 3.22-1.49 5.623-3.7 5.623zM40.806 6.176l6.032-1.298V0l-6.032 1.273v4.903zm0 1.826h6.032V29.03h-6.032V8.002zm-6.464 1.779l-.385-1.779h-5.19V29.03h6.007V14.78c1.418-1.851 3.821-1.515 4.566-1.25V8.002c-.769-.288-3.58-.817-4.998 1.779zM22.326 2.787l-5.864 1.25-.024 19.25c0 3.556 2.667 6.176 6.224 6.176 1.97 0 3.413-.36 4.206-.793V23.79c-.77.313-4.566 1.418-4.566-2.139v-8.531h4.566V8.002h-4.566l.024-5.215zM6.08 14.107c0-.938.769-1.298 2.043-1.298 1.826 0 4.133.552 5.96 1.538V8.699c-1.995-.793-3.966-1.105-5.96-1.105C3.244 7.594 0 10.14 0 14.394c0 6.633 9.132 5.576 9.132 8.436 0 1.105-.961 1.466-2.307 1.466-1.995 0-4.542-.817-6.56-1.923v5.72a16.659 16.659 0 006.56 1.37c4.999 0 8.435-2.476 8.435-6.777-.024-7.162-9.18-5.888-9.18-8.58z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default StripeLogo;
