import { FC } from 'react';
import classNames from 'classnames';

import { SvgIconComponent } from '../../../types/svgs';

const EmptySection: FC<{
  IconComponent: SvgIconComponent;
  titleText: string;
  subTitleText: string;
  iconClassName: string;
  className?: string;
}> = ({ IconComponent, titleText, subTitleText, iconClassName, className }) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col items-center justify-center',
        className,
      )}
    >
      <IconComponent className={classNames('h-20', iconClassName)} />
      <span className="mt-5 mb-1 text-center text-body font-bold text-green-150">
        {titleText}
      </span>
      <span className="w-[300px] text-center text-caption font-medium text-neutral-125/80">
        {subTitleText}
      </span>
    </div>
  );
};

export default EmptySection;
