import { FC } from 'react';

import { ActivityType, AssessmentSchemaType } from '../../generated/graphql';
import ThumbnailAction from '../svgs/ThumbnailAction';
import ThumbnailAssessment from '../svgs/ThumbnailAssessment';
import ThumbnailAudio from '../svgs/ThumbnailAudio';
import ThumbnailForm from '../svgs/ThumbnailForm';
import ThumbnailImage from '../svgs/ThumbnailImage';
import ThumbnailLink from '../svgs/ThumbnailLink';
import ThumbnailPDF from '../svgs/ThumbnailPDF';
import ThumbnailPdfForm from '../svgs/ThumbnailPdfForm';
import ThumbnailText from '../svgs/ThumbnailText';
import ThumbnailPDFESign from '../svgs/ThumbnailPDFESign';

/*
  Gets the raw thumbnail SVG for a given activity type.
  For now, until we return Form as a separate ActivityType, assessmentSchemaType is needed.
*/
const ActivityThumbnail: FC<{
  activityType: ActivityType;
  assessmentSchemaType?: AssessmentSchemaType;
  className?: string;
}> = ({ activityType, assessmentSchemaType, className }) => {
  switch (activityType) {
    case ActivityType.Action:
      return <ThumbnailAction className={className} />;
    case ActivityType.Assessment:
      if (assessmentSchemaType === AssessmentSchemaType.Custom) {
        return <ThumbnailForm className={className} />;
      }
      return <ThumbnailAssessment className={className} />;
    case ActivityType.Audio:
      return <ThumbnailAudio className={className} />;
    case ActivityType.Esign:
      return <ThumbnailPDFESign className={className} />;
    case ActivityType.Image:
      return <ThumbnailImage className={className} />;
    case ActivityType.Link:
      return <ThumbnailLink className={className} />;
    case ActivityType.Pdf:
      return <ThumbnailPDF className={className} />;
    case ActivityType.PdfForm:
      return <ThumbnailPdfForm className={className} />;
    case ActivityType.Text:
      return <ThumbnailText className={className} />;
    default:
      return null;
  }
};

export default ActivityThumbnail;
