import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import {
  PatientLifecycleStatusV2,
  PatientsQuery,
} from '../../../../generated/graphql';

import { ColumnHeaderType } from '../../../types/tables';
import TableHeader from '../../../components/TableHeader';

import Loading from '../../Loading';

import { columnHeaders, getNextPatientOrder, sortOptions } from '../helpers';
import { useTableContext } from '../index';

import EmptyPatientTable from './EmptyPatientTable';
import InvitedRow from './Rows/InvitedRow';
import ActiveRow from './Rows/ActiveRow';
import ArchivedRow from './Rows/ArchivedRow';
import RestrictedRow from './Rows/ResctrictedRow';
import { InformationCircleIcon } from '@heroicons/react/solid';
import Tooltip from '../../../components/Tooltip';
import { useAuth } from '../../../../contexts/AuthContext';
import { stripLeadingSlash } from '../../../lib/string';
import { UserAddIcon } from '@heroicons/react/outline';
import { DEFAULT_FREE_CLIENT_COUNT } from '../../../lib/constants';

const ClientLeadTooltip = ({
  atClientLimit = false,
  onUpgradeClick,
}: {
  atClientLimit?: boolean;
  onUpgradeClick: () => void;
}) => {
  return (
    <div>
      {atClientLimit ? (
        <>
          <p>
            These clients have signed up to work with you from your webpage, but
            you've reached the limit of active clients on the Free plan.
          </p>
          <p>
            <span
              onClick={onUpgradeClick}
              className="cursor-pointer text-green-100 underline"
            >
              Upgrade
            </span>{' '}
            to a paid plan or archive an active client to start working with
            these new client leads.
          </p>
        </>
      ) : (
        <>
          <p>
            These clients have signed up to work with you from your webpage.
          </p>
          <p>
            To work with them, add them to your active clients by clicking the{' '}
            <UserAddIcon className="inline-block h-4 w-4 text-neutral-125" />{' '}
            button.
          </p>
        </>
      )}
    </div>
  );
};

const PatientTable = () => {
  const {
    nextStepsButtonsDisabled,
    patientLifecycleStatus,
    patientsCount,
    tableLoading,
    patientOrder,
    patientsList,
    restrictedPatientsList,
    clearFilters,
    handleSortOrderChange,
    setSinglePatient,
    addPatients,
    openResendInvitesModal,
    setIsSendAssessmentModalOpen,
    providerProgramTags,
    refetchMeProviderProgramTags,
    handleProgramTagsUpdate,
    handleUnarchiveClick,
    handleAddStorefrontClient,
  } = useTableContext();

  const { authedProviderUser, willHitClientLimit } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const globalCheckboxRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const hasPatients = patientsList && patientsList.length > 0;

  const globalCheckboxIndeterminate = false;

  useLayoutEffect(() => {
    if (patientLifecycleStatus !== PatientLifecycleStatusV2.Active) {
      if (globalCheckboxRef?.current?.indeterminate)
        globalCheckboxRef.current.indeterminate = globalCheckboxIndeterminate;
    }
  }, [globalCheckboxIndeterminate]);

  const [previousColumnClicked, setPreviousColumnClicked] =
    useState<ColumnHeaderType | null>();

  const handleHeaderClick = (column: ColumnHeaderType) => {
    if (
      column.field === 'name' ||
      column.field === 'unreadComments' ||
      column.field === 'nextStep' ||
      column.field === 'inviteSent'
    ) {
      const nextPatientOrder = getNextPatientOrder(patientOrder, column.field);
      setPreviousColumnClicked(column);
      handleSortOrderChange(nextPatientOrder);
    }
  };

  const columns =
    patientLifecycleStatus === PatientLifecycleStatusV2.Invited
      ? columnHeaders.invited
      : patientLifecycleStatus === PatientLifecycleStatusV2.Active
      ? columnHeaders.active
      : columnHeaders.archived;

  const TableRowComponent =
    patientLifecycleStatus === PatientLifecycleStatusV2.Invited
      ? InvitedRow
      : patientLifecycleStatus === PatientLifecycleStatusV2.Active
      ? ActiveRow
      : ArchivedRow;

  const isActiveLifecycleStatus =
    patientLifecycleStatus === PatientLifecycleStatusV2.Active;

  const hasRestrictedPatients = restrictedPatientsList?.length > 0;

  const navigateToPatientProfile = (
    patient: PatientsQuery['patients'][number],
    subRoute?: string,
  ) => {
    navigate(
      `/clients/${patient.programInstanceId}${subRoute ? `/${subRoute}` : ''}`,
    );
  };

  const navigateToPatientFollowUp = (
    patient: PatientsQuery['patients'][number],
  ) => {
    navigate(`/${patient.programInstanceId}/follow-ups/new`);
  };

  const navigateToPatientIntake = (
    patient: PatientsQuery['patients'][number],
  ) => {
    navigate(`/${patient.programInstanceId}/intake/new`);
  };

  const navigateToPatientMessages = (
    patient: PatientsQuery['patients'][number],
  ) => {
    navigateToPatientProfile(patient, 'messages');
  };

  const navigateToSubscribe = () => {
    navigate('/subscribe', {
      state: {
        stripeCheckoutPortalWebRedirectPath: stripLeadingSlash(
          location.pathname,
        ),
      },
    });
  };

  return (
    <div className="flex h-full min-w-full flex-col">
      {tableLoading ? (
        <Loading size="small" className="!h-[calc(100vh-16rem)]" />
      ) : hasPatients ? (
        <table
          className={classNames(
            'min-w-full table-fixed divide-y divide-neutral-50 transition duration-150',
          )}
        >
          <thead>
            <tr>
              <th className="w-[3.75rem]"></th>
              {columns.map((column, i) => {
                const isFirstColumn = i === 0;
                const isLastColumn = i === columns.length - 1;

                return (
                  <TableHeader
                    key={`tableHeader_${column.field}`}
                    column={column}
                    sortOptions={sortOptions}
                    isFirstColumn={isFirstColumn}
                    isLastColumn={isLastColumn}
                    handleHeaderClick={handleHeaderClick}
                    previousColumnClicked={previousColumnClicked}
                    columnOrder={patientOrder}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {patientsList?.map((patient, patientIndex) => {
              return (
                <TableRowComponent
                  key={`tableRow_${patient.programInstanceId}`}
                  patient={patient}
                  patientIndex={patientIndex}
                  isChecked={false}
                  openResendInvitesModal={openResendInvitesModal}
                  setIsSendAssessmentModalOpen={setIsSendAssessmentModalOpen}
                  setSinglePatient={setSinglePatient}
                  navigateToPatientProfile={navigateToPatientProfile}
                  navigateToPatientFollowUp={navigateToPatientFollowUp}
                  navigateToPatientIntake={navigateToPatientIntake}
                  navigateToPatientMessages={navigateToPatientMessages}
                  nextStepsButtonsDisabled={nextStepsButtonsDisabled}
                  providerProgramTags={providerProgramTags}
                  refetchProviderProgramTags={refetchMeProviderProgramTags}
                  handleProgramTagsUpdate={handleProgramTagsUpdate}
                  handleUnarchiveClick={handleUnarchiveClick}
                />
              );
            })}
            {isActiveLifecycleStatus && !authedProviderUser?.hasPremiumAccess && (
              <tr>
                <td colSpan={columns.length + 1} className="pt-12 pb-5">
                  <div className="flex items-center justify-center">
                    <div className="max-w-[30rem] text-center text-caption text-neutral-125">
                      {willHitClientLimit() &&
                        `You've reached the ${
                          authedProviderUser?.provider?.clientSeats ??
                          DEFAULT_FREE_CLIENT_COUNT
                        }-client limit on the Free Plan. `}
                      <span
                        onClick={navigateToSubscribe}
                        className="cursor-pointer text-green-100 underline"
                      >
                        Upgrade
                      </span>{' '}
                      to work with unlimited clients or archive an active client
                      to free up a spot.
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {isActiveLifecycleStatus && hasRestrictedPatients && (
              <>
                <tr className="my-5 h-12">
                  <td className="w-[3.75rem]"></td>
                  <td
                    className="flex flex-row items-center text-small-caption font-bold uppercase text-neutral-125"
                    colSpan={columns.length}
                  >
                    <div className="whitespace-nowrap text-caption font-medium text-neutral-125">
                      New Client Leads
                    </div>
                    <Tooltip
                      content={
                        <ClientLeadTooltip
                          atClientLimit={willHitClientLimit()}
                          onUpgradeClick={navigateToSubscribe}
                        />
                      }
                      className="w-96 normal-case"
                    >
                      <InformationCircleIcon className="ml-2 mb-0.5 h-5 w-5 text-orange-100" />
                    </Tooltip>
                  </td>
                </tr>
                {restrictedPatientsList?.map((patient) => {
                  return (
                    <RestrictedRow
                      key={`restrictedRow_${patient.programInstanceId}`}
                      patient={patient}
                      providerProgramTags={providerProgramTags}
                      refetchProviderProgramTags={refetchMeProviderProgramTags}
                      handleProgramTagsUpdate={handleProgramTagsUpdate}
                      handleAddStorefrontClient={handleAddStorefrontClient}
                    />
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      ) : (
        <div
          className={classNames(
            'z-2 flex !h-[calc(100vh-28rem)] w-full flex-col items-center justify-start',
          )}
        >
          <EmptyPatientTable
            tableType={patientLifecycleStatus}
            patientsCount={patientsCount}
            addPatients={addPatients}
            clearFilters={clearFilters}
          />
        </div>
      )}
    </div>
  );
};

export default PatientTable;
