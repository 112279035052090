import { FC } from 'react';
import {
  ActivityType,
  ProgramActivityDataFragment,
} from '../../generated/graphql';
import classNames from 'classnames';
import ActivityThumbnail from './ActivityThumbnail';

/*
  Gets the thumbnail for an existing ProgramActivity. Certain activities (images & links) can have
  a unique thumbnail media image, while the rest use their default activity thumbnail SVG.
*/
export const ProgramActivityThumbnail: FC<{
  programActivity: ProgramActivityDataFragment;
  className?: string;
}> = ({ programActivity, className }) => {
  let mediaUrl;

  switch (programActivity.activityType) {
    case ActivityType.Image:
      mediaUrl = programActivity.activityImage?.media.url;
      break;
    case ActivityType.Link:
      mediaUrl = programActivity.activityLink?.thumbnailMedia?.url;
      break;
  }

  return mediaUrl ? (
    <img
      src={mediaUrl}
      alt="Thumbnail preview"
      className={classNames(
        'flex-none overflow-hidden rounded-md object-cover',
        className,
      )}
    />
  ) : (
    <ActivityThumbnail
      activityType={programActivity.activityType}
      assessmentSchemaType={
        programActivity.programAssessment?.assessment.schemaType
      }
      className={classNames('flex-none rounded-md', className)}
    />
  );
};
