import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import {
  CalendlyImportStatus,
  useCalendlyImportStatusQuery,
  useImportCalendlyDataMutation,
  UpcomingScheduledEventsDocument,
  CalendarScheduledEventsDocument,
} from '../../../generated/graphql';
import Button from '../Button';
import ToastAlert from '../ToastAlert';
import { useAuth } from '../../../contexts/AuthContext';

const ImportStatusToast: React.FC = () => {
  const [successToastVisible, setSuccessToastVisible] = useState(false);

  const client = useApolloClient();
  const { authedProviderUser } = useAuth();
  const hasCalendlyIntegration =
    authedProviderUser?.provider.calendlyIntegration;

  const previousImportStatus = useRef<CalendlyImportStatus | null>();

  const {
    data: importStatusData,
    loading: isImportStatusLoading,
    error: importStatusError,
    refetch: refetchCalendlyImportStatus,
  } = useCalendlyImportStatusQuery({
    onCompleted: async (data) => {
      if (
        data.calendlyImportStatus === CalendlyImportStatus.Completed &&
        previousImportStatus.current &&
        previousImportStatus.current !== CalendlyImportStatus.Completed
      ) {
        setSuccessToastVisible(true);
        await client.refetchQueries({
          include: [
            UpcomingScheduledEventsDocument,
            CalendarScheduledEventsDocument,
          ],
        });
      }

      previousImportStatus.current = data.calendlyImportStatus;
    },
  });
  const [importCalendlyDataMutation, { loading: importCalendlyDataLoading }] =
    useImportCalendlyDataMutation();

  const navigate = useNavigate();

  const timeout = useRef<NodeJS.Timeout>();

  const pollNextImportStatus = () => {
    if (timeout?.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(async () => {
      const { data } = await refetchCalendlyImportStatus();
      if (data?.calendlyImportStatus === CalendlyImportStatus.Started) {
        // Poll again if the status is still "Started"
        pollNextImportStatus();
      }
    }, 5000);
  };

  useEffect(() => {
    if (!authedProviderUser?.provider.calendlyIntegration) {
      return;
    }

    if (
      importStatusData?.calendlyImportStatus === CalendlyImportStatus.Started
    ) {
      pollNextImportStatus();
    } else if (
      importStatusData?.calendlyImportStatus === CalendlyImportStatus.NotStarted
    ) {
      importCalendlyData();
    }
  }, [
    importStatusData?.calendlyImportStatus,
    authedProviderUser?.provider.calendlyIntegration,
  ]);

  const importCalendlyData = () => {
    importCalendlyDataMutation().then(() => {
      refetchCalendlyImportStatus();
      pollNextImportStatus();
    });
  };

  if (
    isImportStatusLoading ||
    importStatusData?.calendlyImportStatus ===
      CalendlyImportStatus.NotStarted ||
    (importStatusData?.calendlyImportStatus ===
      CalendlyImportStatus.Completed &&
      !successToastVisible)
  ) {
    return <></>;
  }

  return (
    <div className="fixed bottom-3 left-0 right-0 flex justify-center">
      {importStatusData?.calendlyImportStatus ===
        CalendlyImportStatus.Started && (
        <ToastAlert
          level="info"
          message="We’re importing your data from Calendly. This should only take a minute or two."
          isVisible
          isLoading
        />
      )}
      {importStatusData?.calendlyImportStatus ===
        CalendlyImportStatus.Completed && (
        <ToastAlert
          level="success"
          message="Your data has been imported from Calendly."
          isVisible
          rightElements={
            <>
              <Button
                title="View your schedule"
                noOutline
                noBackground
                theme="secondary"
                size="small"
                className="font-bold text-green-150"
                onClick={() => {
                  navigate('/schedule');
                  setSuccessToastVisible(false);
                }}
              />

              <Button
                title="Dismiss"
                noOutline
                noBackground
                theme="secondary"
                size="small"
                className="ml-3 font-bold text-green-150"
                onClick={() => setSuccessToastVisible(false)}
              />
            </>
          }
        />
      )}
      {importStatusData?.calendlyImportStatus ===
        CalendlyImportStatus.Failed && (
        <ToastAlert
          level="error"
          message="Failed to import your data from Calendly."
          isVisible
          rightElements={
            <Button
              title="Retry"
              noOutline
              noBackground
              theme="secondary"
              size="small"
              className="font-bold text-green-150"
              onClick={importCalendlyData}
              disabled={importCalendlyDataLoading}
            />
          }
        />
      )}
      {importStatusError && hasCalendlyIntegration && (
        <ToastAlert
          level="error"
          message="Couldn't get the status of the import of your Calendly data."
          isVisible
          rightElements={
            <Button
              title="Retry"
              noOutline
              noBackground
              theme="secondary"
              size="small"
              className="font-bold text-green-150"
              onClick={() => refetchCalendlyImportStatus()}
              disabled={isImportStatusLoading}
            />
          }
        />
      )}
    </div>
  );
};

export default ImportStatusToast;
