import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { fiveMinutesInMilliseconds } from '../pages/Schedule/Calendar/helpers';

// Used for updating the TodayTimeBar and refetching Upcoming Sessions on the Home page every 5 minutes

const now = DateTime.now();

export default function useIncrementNowDate() {
  const [nowDate, setNowDate] = useState(now);

  useEffect(() => {
    const nowInterval = setInterval(() => {
      const now = DateTime.now();
      setNowDate(now);
    }, fiveMinutesInMilliseconds);

    return () => {
      clearInterval(nowInterval);
    };
  }, []);

  return nowDate;
}
