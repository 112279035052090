import { FC } from 'react';
import IllustrationCheckmark from '../svgs/IllustrationCheckMark';

const SubmissionSuccessful: FC<{ messageOverride?: string }> = ({
  messageOverride,
}) => {
  return (
    <div className="flex flex-col items-center">
      <IllustrationCheckmark className="mt-14 mb-6 text-center text-green-100" />
      <div className="mb-3 text-center font-serif text-subtitle font-light text-neutral-150">
        Thanks!
      </div>
      <div className="w-2/3 text-center text-paragraph text-neutral-125">
        {messageOverride ? messageOverride : 'Your file was successfully sent.'}
      </div>
    </div>
  );
};

export default SubmissionSuccessful;
