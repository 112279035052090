import {
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

import classNames from 'classnames';

import { AlertLevel } from '../types/alerts';
import { SvgIconComponent } from '../types/svgs';
import IconButton from './IconButton';
import X from '../svgs/X';

const iconComponents: Record<AlertLevel, SvgIconComponent> = {
  error: XCircleIcon,
  warning: ExclamationCircleIcon,
  success: CheckCircleIcon,
  info: InformationCircleIcon,
};

type AlertProps = {
  className?: string;
  message: string;
  level?: AlertLevel;
  children?: React.ReactNode;
  onClickDismiss?: () => void;
};

const Alert: React.FunctionComponent<AlertProps> = ({
  className,
  message,
  level = 'error',
  children,
  onClickDismiss,
}) => {
  const IconComponent = iconComponents[level];

  return (
    <div
      className={classNames(
        'flex rounded-md p-4',
        level === 'error' && 'bg-red-25/30 text-red-125',
        level === 'warning' && 'bg-orange-25/30 text-orange-125',
        level === 'success' && 'bg-green-25/30 text-green-125',
        level === 'info' && 'bg-blue-25/30 text-blue-125',
        className,
      )}
    >
      <IconComponent
        className={classNames(
          'h-5 w-5',
          level === 'error' && 'text-red-100',
          level === 'warning' && 'text-orange-100',
          level === 'success' && 'text-green-100',
          level === 'info' && 'text-blue-100',
        )}
        aria-hidden="true"
      />

      <div className="ml-3 flex-1 text-caption">
        <div className="flex justify-between">
          <h3 className="font-sans font-medium">{message}</h3>

          {onClickDismiss && (
            <IconButton
              aria-label="Dismiss alert"
              IconComponent={X}
              iconClassName="h-5 w-5"
              hoverClassName="hover:bg-black/10"
              className="ml -mt-1 -mr-1 -mb-1 ml-1 self-start"
              onClick={onClickDismiss}
            />
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Alert;
