import classNames from 'classnames';
import IconButton from '../../../components/IconButton';
import { getTimeStamp } from '../../../lib/time';
import { ProgramNoteDataFragment } from '../../../../generated/graphql';
import Pin from '../../../svgs/Pin';
import { useState } from 'react';

const NotePill: React.FC<{
  isLastNote: boolean;
  programNoteId: string;
  programNote: ProgramNoteDataFragment;
  handleNotePillClick: () => void;
  handlePinNoteClick: (e: any) => void;
}> = ({
  isLastNote,
  programNoteId,
  programNote,
  handleNotePillClick,
  handlePinNoteClick,
}) => {
  const [showPin, setShowPin] = useState(false);

  return (
    <div className={classNames('w-full', isLastNote && 'mb-4')}>
      <div
        onClick={handleNotePillClick}
        onMouseEnter={() => setShowPin(true)}
        onMouseLeave={() => setShowPin(false)}
        className={classNames(
          'flex min-h-[40px] w-full cursor-pointer flex-row items-start justify-between overflow-y-auto rounded-md py-3 pl-4 pr-[16px] hover:rounded-md hover:bg-neutral-25',
          programNoteId === programNote.id &&
            'rounded-md bg-green-25/80 font-medium hover:bg-green-25',
        )}
      >
        <div className="flex w-[calc(100%-22px)] flex-col">
          <div className="truncate text-caption font-medium text-neutral-150">
            {programNote.title}
          </div>
          <div className="pt-1 text-small-label text-neutral-150">
            {getTimeStamp(programNote.createdAt, false)}
          </div>
        </div>
        {showPin || programNote?.isPinnedNote ? (
          <div className="flex flex-col justify-start">
            <IconButton
              aria-label={programNote?.isPinnedNote ? 'Unpin note' : 'Pin note'}
              IconComponent={Pin}
              onClick={handlePinNoteClick}
              className={classNames(
                programNote?.isPinnedNote
                  ? 'text-green-100 hover:text-neutral-110'
                  : 'text-neutral-110 hover:text-green-100',
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NotePill;
