import classNames from 'classnames';
import { FC } from 'react';

import { SvgIconComponent } from '../../types/svgs';
import Button from '../../components/Button';
import ChevronRight from '../../svgs/ChevronRight';

export type ProviderDataList = {
  data: string;
  label?: string;
  link?: string;
  onClick?: () => void;
}[];

const SettingsGroup: FC<{
  IconComponent: SvgIconComponent;
  sectionName: string;
  providerData: ProviderDataList;
}> = ({ IconComponent, sectionName, providerData }) => {
  const icon = IconComponent && (
    <IconComponent className="h-4 w-4 text-green-100" />
  );

  return (
    <div className="mb-8 flex w-full flex-row items-start justify-start">
      <div className="flex w-1/3 flex-row items-center justify-start">
        {icon}
        <span className="ml-2 text-body">{sectionName}</span>
      </div>
      <div className="flex w-2/3 flex-col items-start justify-start">
        {providerData.map((info, index) => {
          const firstInfo = index === 0;
          return (
            <div
              key={`settingsGroup_${info.label}`}
              className={classNames(
                'text-green-150" flex w-full flex-row items-center justify-between border-b border-neutral-50 pb-4 pl-4 text-caption',
                !firstInfo && 'py-4',
              )}
            >
              {Boolean(info.link || info.onClick) ? (
                <Button
                  title={info.data}
                  className="w-full justify-between font-bold text-green-150"
                  theme="secondary"
                  size="small"
                  IconComponent={ChevronRight}
                  iconClassName="text-green-150"
                  noBackground
                  noOutline
                  onClick={() => {
                    if (info.onClick) info.onClick();
                    if (info.link) window.location.href = info.link;
                  }}
                />
              ) : (
                <div className="flex flex-row items-center justify-start text-green-150">
                  {Boolean(info.label) && (
                    <span className="mr-2 font-bold">{info.label}:</span>
                  )}
                  <span
                    className={classNames(!Boolean(info.label) && 'font-bold')}
                  >
                    {info.data}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsGroup;
