import { FC, useRef, useState } from 'react';

import { MODAL_TRANSITION_DURATION } from '../Modal';
import AudioRecorderPlayer, {
  AudioRecorderPlayerRef,
} from '../AudioRecorderPlayer';
import { useAddVoiceNoteMutation } from '../../../generated/graphql';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';
import { ApolloError } from '@apollo/client';
import ModalDialog from '../ModalDialog';
import { RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import IconButton from '../IconButton';
import { isAbortError } from '../../../lib/errors';

const RecordVoiceNoteModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  onPost: (providerVoiceNoteId: string) => void;
}> = ({ isModalOpen, setClosed, onPost }) => {
  const audioRecorderPlayerRef = useRef<AudioRecorderPlayerRef | null>(null);

  const [addVoiceNoteMutation, { loading: addVoiceNoteLoading }] =
    useAddVoiceNoteMutation();
  const [providerVoiceNoteId, setProviderVoiceNoteId] = useState<
    string | undefined
  >();

  const [isRecording, setIsRecording] = useState(false);

  const hasRecordedVoiceNote = Boolean(providerVoiceNoteId);
  const abortAddVoiceNote = useRef(new AbortController());

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      onDiscardVoiceNote();
    }, MODAL_TRANSITION_DURATION);
  };

  const onAddVoiceNote = async (
    audioFile: File,
    audioDuration: number,
  ): Promise<string | void> => {
    setIsRecording(false);
    try {
      const { data } = await addVoiceNoteMutation({
        variables: {
          input: {
            file: audioFile,
            duration: audioDuration,
          },
        },
        context: { fetchOptions: { signal: abortAddVoiceNote.current.signal } },
      });

      if (data?.addVoiceNote.id) {
        setProviderVoiceNoteId(data.addVoiceNote.id);
        return data.addVoiceNote.id;
      }
    } catch (err) {
      if (isAbortError(err as ApolloError)) {
        return;
      }

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong while saving your voice note."
          level="error"
        />
      ));
    }
  };

  const onDiscardVoiceNote = async () => {
    audioRecorderPlayerRef.current?.resetRecorder();
    setIsRecording(false);
    abortAddVoiceNote.current?.abort();
    abortAddVoiceNote.current = new AbortController();
    setProviderVoiceNoteId(undefined);
  };

  return (
    <ModalDialog
      title="Record a voice note"
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="small"
      primaryActionTitle="Post"
      primaryActionOnClick={async () => {
        if (isRecording) {
          const id = await audioRecorderPlayerRef.current?.stopRecording();
          if (id) {
            onPost(id);
            closeAndResetModal();
          }
        } else {
          onPost(providerVoiceNoteId!);
          closeAndResetModal();
        }
      }}
      primaryActionDisabled={!hasRecordedVoiceNote && !isRecording}
      leftFooter={
        <div className="flex flex-row gap-4">
          <IconButton
            aria-label="Delete"
            IconComponent={TrashIcon}
            iconClassName="h-6 w-6 text-red-100"
            disabled={!hasRecordedVoiceNote}
            onClick={closeAndResetModal}
          />
          <IconButton
            aria-label="Re-record"
            IconComponent={RefreshIcon}
            iconClassName="h-6 w-6 text-green-100"
            disabled={!hasRecordedVoiceNote}
            onClick={() => {
              setProviderVoiceNoteId(undefined);
              audioRecorderPlayerRef.current?.startRecording();
            }}
          />
        </div>
      }
      bodyClassName="flex flex-row items-center justify-center"
    >
      <div className="flex min-h-[150px] w-full flex-col items-center justify-center">
        {isRecording ? (
          <div className="mb-6 text-neutral-150">
            Tap the red button to stop recording.
          </div>
        ) : hasRecordedVoiceNote ? (
          <div className="mb-6 text-neutral-150">
            Preview your voice note before posting.
          </div>
        ) : null}

        <AudioRecorderPlayer
          recordingEnabled={true}
          isUploading={addVoiceNoteLoading}
          hasUploaded={hasRecordedVoiceNote}
          onAdd={onAddVoiceNote}
          onDiscard={onDiscardVoiceNote}
          onRecord={() => setIsRecording(true)}
          filePrefix="voicenote"
          recordButtonSize="medium"
          hideDiscardButton={true}
          ref={audioRecorderPlayerRef}
          autostart={true}
        />
      </div>
    </ModalDialog>
  );
};

export default RecordVoiceNoteModal;
