import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Location, useLocation } from 'react-router-dom';

import PageContainer from '../components/Containers/PageContainer';
import Spinner from '../svgs/Spinner';

const LoginRedirect: React.FC = () => {
  const location = useLocation();
  const from = (location.state as { from?: Location })?.from ?? '/';

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    (async () => {
      try {
        await loginWithRedirect({
          appState: { from },
        });
      } catch (error) {
        setErrorMessage('Error logging in. Please try again.');
      }
    })();
  }, [isAuthenticated, loginWithRedirect, from]);

  return (
    <PageContainer containerClassName="h-screen items-center justify-center">
      {errorMessage ? errorMessage : <Spinner />}
    </PageContainer>
  );
};

export default LoginRedirect;
