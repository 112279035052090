import { FC, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';

import ScoreSection from './Assessments/ScoreSection';
import Slideover from './Slideover';
import SlideoverSection from './SlideoverSection';
import ToastAlert from './ToastAlert';
import { getTimeStamp } from '../lib/time';
import { defaultTransitionProps } from '../lib/animation';
import {
  AssessmentSchemaType,
  useProgramAssessmentQuery,
} from '../../generated/graphql';
import ResponsesSection from './Assessments/ResponsesSection';

const AssessmentCompletedSlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  programAssessmentId: string;
  patientFirstName?: string;
}> = ({ onClose, isOpen, patientFirstName, programAssessmentId }) => {
  const {
    data: completedProgramAssessmentData,
    error: completedProgramAssessmentError,
    loading: isLoadingCompletedProgramAssessment,
  } = useProgramAssessmentQuery({
    variables: {
      programAssessmentId,
    },
    skip: !programAssessmentId,
  });

  const completedProgramAssessment =
    completedProgramAssessmentData?.programAssessment;

  useEffect(() => {
    if (completedProgramAssessmentError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch completed assessment."
          level="error"
        />
      ));
    }
  }, [completedProgramAssessmentError]);

  const assessmentTimestampTitle =
    getTimeStamp(completedProgramAssessment?.completedAt, false) ?? '';

  const isCustomAssessment =
    completedProgramAssessment?.assessment.schemaType ===
    AssessmentSchemaType.Custom;

  return (
    <Slideover
      isOpen={isOpen}
      fetching={isLoadingCompletedProgramAssessment}
      title={completedProgramAssessment?.assessment.shortName ?? ''}
      subtitle={assessmentTimestampTitle}
      onClose={onClose}
      showContent={
        !isLoadingCompletedProgramAssessment &&
        !completedProgramAssessmentError &&
        Boolean(completedProgramAssessmentData)
      }
    >
      <Transition show={true} {...defaultTransitionProps}>
        {completedProgramAssessment && (
          <>
            {!isCustomAssessment && (
              <ScoreSection
                assessmentType={completedProgramAssessment?.assessment.type}
                title="Score"
                completedAssessment={completedProgramAssessment ?? undefined}
              />
            )}
            {Boolean(completedProgramAssessment?.additionalThoughts) && (
              <SlideoverSection
                title={`Notes from ${patientFirstName}`}
                textContent={
                  `"${completedProgramAssessment?.additionalThoughts}"` ??
                  undefined
                }
              />
            )}
            <ResponsesSection
              title="Responses"
              questionFrame={
                completedProgramAssessment?.assessment?.questionFrame ??
                undefined
              }
              answers={completedProgramAssessment?.answers}
            />
            <SlideoverSection
              title="About"
              textContent={completedProgramAssessment?.assessment?.description}
            />
          </>
        )}
      </Transition>
    </Slideover>
  );
};

export default AssessmentCompletedSlideover;
