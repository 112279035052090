import { FC } from 'react';

const ClinicLarge: FC<{ isActive: boolean }> = ({ isActive }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M4.44876 11.4862L15.4561 4.35247C15.787 4.13804 16.213 4.13804 16.5439 4.35247L27.5512 11.4862C27.8313 11.6677 28.0021 11.974 27.9949 12.3077C27.9456 14.5913 27.4383 23.5806 22.5452 26.2191C18.1417 28.5936 13.8583 28.5936 9.45483 26.2191C4.56172 23.5806 4.05441 14.5913 4.00507 12.3077C3.99786 11.974 4.16866 11.6677 4.44876 11.4862Z"
        fill={isActive ? '#E2FBE5' : '#EFEDEA'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12H15V15H12V17H15V20H17V17H20V15H17V12Z"
        fill={isActive ? '#00B20A' : '#68716E'}
      />
    </svg>
  );
};

export default ClinicLarge;
