import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import Pencil from '../svgs/Pencil';
import Trash from '../svgs/Trash';
import Spinner from '../svgs/Spinner';
import IconButton from './IconButton';

// TODO: Create a generic dropdown menu component.

type TaskGroupMenuProps = {
  isOpen: boolean;
  isDeleteLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRenameClick: (event: Event) => void;
  onDeleteClick: (event: Event) => void;
};

export default function TaskGroupMenu({
  isOpen,
  isDeleteLoading,
  setIsOpen,
  onRenameClick,
  onDeleteClick,
}: TaskGroupMenuProps) {
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <IconButton
          aria-label="Actions menu"
          IconComponent={DotsHorizontalIcon}
          iconClassName="h-4 w-4"
          className="ml-4"
        />
      </DropdownMenu.Trigger>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <DropdownMenu.Content
          forceMount
          className="mt-2 w-56 rounded-lg bg-white py-2 shadow-100 ring-1 ring-black ring-opacity-5 focus:outline-none"
          side="bottom"
          align="start"
        >
          <DropdownMenu.Item
            onSelect={onRenameClick}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-caption text-neutral-125 focus:bg-neutral-50 focus:outline-none"
          >
            <div>Rename group</div>
            <Pencil className="h-4 w-4" />
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={onDeleteClick}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-caption text-red-100 focus:bg-neutral-50 focus:outline-none"
          >
            <div>Delete group</div>
            {isDeleteLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <Trash className="h-4 w-4" />
            )}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </Transition>
    </DropdownMenu.Root>
  );
}
