import { FC, useState } from 'react';

import {
  Exact,
  MeProviderQuery,
  PatientDataPermission,
  ProviderUserMemberDataFragment,
  ProviderUserMembersV2Query,
  ProviderUserRole,
  useDeactivateProviderUserMutation,
  useDeleteProviderUserInviteMutation,
  useReactivateProviderUserMutation,
  useResendProviderUserInviteMutation,
  useUpdateProviderUserRoleMutation,
} from '../../../../../generated/graphql';
import TableHeader from '../../../../components/TableHeader';
import ConfirmDeactivateTeamMemberModal from '../Modals/ConfirmDeactivateTeamMemberModal';
import { ApolloQueryResult, QueryResult } from '@apollo/client';
import toast from 'react-hot-toast';
import ToastAlert from '../../../../components/ToastAlert';
import ConfirmReactivateTeamMemberModal from '../Modals/ConfirmReactivateTeamMemberModal';
import TeamMemberRow from './TeamMemberRow';
import { columns } from './helpers';
import classNames from 'classnames';
import { sortProviderUserMembers } from '../../../../lib/teamMembers';
import ConfirmResendTeamMemberInviteModal from '../Modals/ConfirmResendTeamMemberInviteModal';
import ConfirmDeleteTeamMemberInviteModal from '../Modals/ConfirmDeleteTeamMemberInviteModal';
import AssignPatientsModal from '../../../../components/Modals/ClientAssignmentModals/AssignPatientsModal';

export type RefetchTeamMembers = (
  variables?: Partial<
    Exact<{
      [key: string]: never;
    }>
  >,
) => Promise<ApolloQueryResult<ProviderUserMembersV2Query>>;

type RefetchAuthedProviderUser = () => Promise<
  QueryResult<
    MeProviderQuery,
    Exact<{
      [key: string]: never;
    }>
  >
>;

type TeamMembersTableProps = {
  providerUserMembers: ProviderUserMemberDataFragment[];
  refetchTeamMembers: RefetchTeamMembers;
  refreshAuthedProviderUser: RefetchAuthedProviderUser;
  seatLimitReached?: boolean;
  onSeatLimitReached?: () => void;
};

const TeamMembersTable: FC<TeamMembersTableProps> = ({
  providerUserMembers,
  refetchTeamMembers,
  refreshAuthedProviderUser,
  seatLimitReached = false,
  onSeatLimitReached,
}) => {
  const [selectedProviderUser, setSelectedProviderUser] =
    useState<ProviderUserMemberDataFragment>(null);
  const [isAssignPatientsModalOpen, setIsAssignPatientsModalOpen] =
    useState(false);
  const [
    isConfirmDeactivateTeamMemberModalOpen,
    setIsConfirmDeactivateTeamMemberModalOpen,
  ] = useState(false);
  const [
    isConfirmReactivateTeamMemberModalOpen,
    setIsConfirmReactivateTeamMemberModalOpen,
  ] = useState(false);
  const [
    isConfirmResendTeamMemberInviteModalOpen,
    setIsConfirmResendTeamMemberInviteModalOpen,
  ] = useState(false);
  const [
    isConfirmDeleteTeamMemberInviteModalOpen,
    setIsConfirmDeleteTeamMemberInviteModalOpen,
  ] = useState(false);

  const [
    deactivateProviderUserMutation,
    { loading: deactivateProviderUserMutationLoading },
  ] = useDeactivateProviderUserMutation();

  const [
    reactivateProviderUserMutation,
    { loading: reactivateProviderUserMutationLoading },
  ] = useReactivateProviderUserMutation();

  const [
    updateProviderUserRoleMutation,
    { loading: updateProviderUserRoleMutationLoading },
  ] = useUpdateProviderUserRoleMutation();

  const handleDeactivateTeamMember = async (providerUserId: string) => {
    try {
      const response = await deactivateProviderUserMutation({
        variables: {
          providerUserId,
        },
      });
      const updatedProviderUser = response.data?.deactivateProviderUser;
      if (updatedProviderUser) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Team member deactivated"
            level="success"
          />
        ));
        await refetchTeamMembers();
        await refreshAuthedProviderUser();
      }
    } catch (e) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong deactivating this team member. Please try again."
          level="error"
        />
      ));
    }
  };

  const handleReactivateTeamMember = async (providerUserId: string) => {
    try {
      const response = await reactivateProviderUserMutation({
        variables: {
          providerUserId,
        },
      });
      const updatedProviderUser = response.data?.reactivateProviderUser;
      if (updatedProviderUser) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Team member reactivated"
            level="success"
          />
        ));
        await refetchTeamMembers();
        await refreshAuthedProviderUser();
      }
    } catch (e) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong reactivating this team member. Please try again."
          level="error"
        />
      ));
    }
  };

  const [
    resendProviderInviteMutation,
    { loading: resendProviderInviteMutationLoading },
  ] = useResendProviderUserInviteMutation();

  const handleResendTeamMemberInvite = async (providerUserId: string) => {
    try {
      await resendProviderInviteMutation({
        variables: { providerUserId },
      });
      await refetchTeamMembers();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Successfully
        resent invite email!"
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message="Couldn't
        resend invite email. Try again later or contact support."
        />
      ));
    }
  };

  const [
    deleteProviderInviteMutation,
    { loading: deleteProviderInviteMutationLoading },
  ] = useDeleteProviderUserInviteMutation();

  const handleDeleteTeamMemberInvite = async (providerUserId: string) => {
    try {
      await deleteProviderInviteMutation({
        variables: { providerUserId },
      });
      await refetchTeamMembers();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Successfully
        deleted invite email!"
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message="Couldn't
        delete invite. Try again later or contact support."
        />
      ));
    }
  };

  const handleUpdateProviderUserRoleOrPatientDataPermissions = async (
    providerUserId: string,
    role: ProviderUserRole,
    patientDataPermission: PatientDataPermission,
  ) => {
    try {
      const response = await updateProviderUserRoleMutation({
        variables: {
          providerUserId,
          role,
          patientDataPermission,
        },
      });
      const updatedProviderUser = response.data?.updateProviderUserRole;
      if (updatedProviderUser) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Team member role updated"
            level="success"
          />
        ));
        await refetchTeamMembers();
        await refreshAuthedProviderUser();
      }
    } catch (e) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong updating this team member's role. Please try again."
          level="error"
        />
      ));
    }
  };

  const sortedProviderUserMembers =
    sortProviderUserMembers(providerUserMembers);

  return (
    <>
      <table className="mt-7 w-full table-fixed divide-y divide-neutral-50">
        <colgroup>
          <col className="w-1/3" />
          <col className="w-1/6" />
          <col className="w-1/6" />
          <col className="w-1/6" />
          <col className="w-1/6" />
        </colgroup>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <TableHeader
                key={`team-member-table-header-${column.field}`}
                column={column}
                className={classNames(
                  column.headerName === 'Assigned clients' && 'text-center',
                  column.headerName === 'Actions' && 'mr-2 text-right',
                )}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedProviderUserMembers.map((providerUserMember, index) => (
            <TeamMemberRow
              key={`team-members-row-${index}`}
              providerUserMember={providerUserMember}
              handleAssignPatients={() => {
                setSelectedProviderUser(providerUserMember);
                setIsAssignPatientsModalOpen(true);
              }}
              handleDeleteInvite={() => {
                setSelectedProviderUser(providerUserMember);
                setIsConfirmDeleteTeamMemberInviteModalOpen(true);
              }}
              handleResendInvite={() => {
                setSelectedProviderUser(providerUserMember);
                setIsConfirmResendTeamMemberInviteModalOpen(true);
              }}
              handleDeactivate={() => {
                setSelectedProviderUser(providerUserMember);
                setIsConfirmDeactivateTeamMemberModalOpen(true);
              }}
              handleReactivate={() => {
                if (seatLimitReached && onSeatLimitReached) {
                  onSeatLimitReached();
                  return;
                }
                setSelectedProviderUser(providerUserMember);
                setIsConfirmReactivateTeamMemberModalOpen(true);
              }}
              handleRoleOrPatientDataPermissionChange={
                handleUpdateProviderUserRoleOrPatientDataPermissions
              }
              isRoleChangeLoading={updateProviderUserRoleMutationLoading}
            />
          ))}
        </tbody>
      </table>
      <AssignPatientsModal
        isOpen={isAssignPatientsModalOpen}
        selectedProviderUser={selectedProviderUser}
        setClosed={() => setIsAssignPatientsModalOpen(false)}
        refetchTeamMembers={refetchTeamMembers}
      />
      <ConfirmDeactivateTeamMemberModal
        isOpen={isConfirmDeactivateTeamMemberModalOpen}
        setClosed={() => setIsConfirmDeactivateTeamMemberModalOpen(false)}
        onConfirm={async () => {
          await handleDeactivateTeamMember(selectedProviderUser.id);
          setIsConfirmDeactivateTeamMemberModalOpen(false);
        }}
        isDeactivating={deactivateProviderUserMutationLoading}
      />
      <ConfirmReactivateTeamMemberModal
        isOpen={isConfirmReactivateTeamMemberModalOpen}
        setClosed={() => setIsConfirmReactivateTeamMemberModalOpen(false)}
        onConfirm={async () => {
          await handleReactivateTeamMember(selectedProviderUser.id);
          setIsConfirmReactivateTeamMemberModalOpen(false);
        }}
        isReactivating={reactivateProviderUserMutationLoading}
      />
      <ConfirmResendTeamMemberInviteModal
        isOpen={isConfirmResendTeamMemberInviteModalOpen}
        setClosed={() => setIsConfirmResendTeamMemberInviteModalOpen(false)}
        onConfirm={async () => {
          await handleResendTeamMemberInvite(selectedProviderUser.id);
          setIsConfirmResendTeamMemberInviteModalOpen(false);
        }}
        isResending={resendProviderInviteMutationLoading}
      />
      <ConfirmDeleteTeamMemberInviteModal
        isOpen={isConfirmDeleteTeamMemberInviteModalOpen}
        setClosed={() => setIsConfirmDeleteTeamMemberInviteModalOpen(false)}
        onConfirm={async () => {
          await handleDeleteTeamMemberInvite(selectedProviderUser.id);
          setIsConfirmDeleteTeamMemberInviteModalOpen(false);
        }}
        isDeletingInvite={deleteProviderInviteMutationLoading}
      />
    </>
  );
};
export default TeamMembersTable;
