import { SvgIconComponent } from '../types/svgs';

const Profile: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M2 10.6923C2 9.20539 3.20539 8 4.69231 8H5C5 8 6 9.4 8 9.4C10 9.4 11 8 11 8H11.3077C12.7946 8 14 9.20539 14 10.6923V10.6923C14 13.0714 12.0714 15 9.69231 15H8H6.30769C3.92862 15 2 13.0714 2 10.6923V10.6923Z"
        fill="currentColor"
      />
      <path
        d="M11 4C11 5.65685 9.65685 8 8 8C6.34315 8 5 5.65685 5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Profile;
