import { FC } from 'react';
import faceImage from '../../../assets/images/hc-face.png';
import Button from '../../components/Button';
import ArrowRightLong from '../../svgs/ArrowRightLong';

const ResendInvitePanel: FC<{
  openInviteToProgramModal: () => void;
}> = ({ openInviteToProgramModal }) => {
  return (
    <div className="mt-9 flex flex-col items-center justify-start">
      <img src={faceImage} className="mt-2 mb-6 h-[150px]" alt="" />
      <span className="mb-4 font-serif text-subtitle-small text-green-150">
        Hang Tight!
      </span>
      <span className="mb-8 w-96 text-center text-caption text-neutral-125">
        We’ll start sharing insights when the client activates their account and
        engages with the Homecoming app.
      </span>
      <Button
        title="Resend invite"
        size="small"
        IconComponent={ArrowRightLong}
        onClick={openInviteToProgramModal}
      />
    </div>
  );
};

export default ResendInvitePanel;
