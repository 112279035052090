import { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import {
  PatientLifecycleStatus,
  Feature,
  PatientsQuery,
} from '../../../../generated/graphql';

import { ColumnHeaderType, ColumnOrder } from '../../../types/tables';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagContext';
import useKeyPressed from '../../../hooks/useKeyPressed';
import TableHeader from '../../../components/TableHeader';

import Loading from '../../Loading';

import {
  columnHeaders,
  PatientsObject,
  PATIENT_ORDERS,
  getPatientsObject,
} from '../helpers';
import { useTableContext } from '../index';

import EmptyTable from './EmptyTable';
import OnboardingRow from './Rows/OnboardingRow';
import InvitedRow from './Rows/InvitedRow';
import ActiveRow from './Rows/ActiveRow';
import ArchivedRow from './Rows/ArchivedRow';
import { getSelectionInterval, getNewSelectedPatients } from './helpers';

const PatientTable = () => {
  const {
    nextStepsButtonsDisabled,
    patientLifecycleStatus,
    selectedPatientsObject,
    selectedPatientsList,
    isPatientsSelected,
    patientsCount,
    tableLoading,
    patientOrder,
    patientsList,
    clearFilters,
    setPatientOrder,
    setSinglePatient,
    openAddPatientsModal,
    openAssignToProgramModal,
    openInviteToProgramModal,
    openFirstAppointmentDateModal,
    setSelectedPatientsObject,
  } = useTableContext();

  const { isFeatureEnabled } = useFeatureFlags();
  const isCreateEditingProgramTaskEnabled = isFeatureEnabled(
    Feature.ArticleCreationEditing,
  );
  const isPatientProfileEnabled = isFeatureEnabled(Feature.PatientProfile);
  const isAssessmentsEnabled = isFeatureEnabled(Feature.Assessments);

  const globalCheckboxRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const showPatientsList =
    (!tableLoading && patientsList && patientsList?.length > 0) || false;
  const showEmptyTable =
    !tableLoading && patientsList !== null && patientsList.length === 0;

  const [lastSelectedPatientIndex, setLastSelectedPatientIndex] =
    useState<number>(-1);

  const globalCheckboxChecked =
    patientsList !== null &&
    patientsList.length > 0 &&
    selectedPatientsList.length === patientsList.length;

  const globalCheckboxIndeterminate = selectedPatientsList.length > 0;

  useLayoutEffect(() => {
    if (patientLifecycleStatus !== PatientLifecycleStatus.Active) {
      if (globalCheckboxRef?.current?.indeterminate)
        globalCheckboxRef.current.indeterminate = globalCheckboxIndeterminate;
    }
  }, [globalCheckboxIndeterminate]);

  function toggleAllCheckboxes() {
    if (patientsList !== null) {
      setSelectedPatientsObject(
        globalCheckboxChecked || globalCheckboxIndeterminate
          ? {}
          : getPatientsObject(patientsList),
      );
    }
  }

  const [previousColumnClicked, setPreviousColumnClicked] =
    useState<ColumnHeaderType | null>();

  const handleHeaderClick = (column: ColumnHeaderType) => {
    function getNextPatientOrder(
      patientOrder: ColumnOrder,
      orderType:
        | 'name'
        | 'program'
        | 'status'
        | 'inviteSent'
        | 'nextStep'
        | 'lastCheckIn'
        | 'checkInsCount'
        | 'checkInsAverage'
        | 'activitiesCompleted'
        | 'journalsCompleted'
        | 'contentCompletion',
    ) {
      const nextOrderIndex =
        PATIENT_ORDERS[orderType].findIndex((order) => order === patientOrder) +
        1;
      if (PATIENT_ORDERS[orderType][nextOrderIndex])
        return PATIENT_ORDERS[orderType][nextOrderIndex];
      return PATIENT_ORDERS[orderType][0];
    }

    if (
      column.field === 'name' ||
      column.field === 'program' ||
      column.field === 'status' ||
      column.field === 'inviteSent' ||
      column.field === 'nextStep' ||
      column.field === 'lastCheckIn' ||
      column.field === 'checkInsCount' ||
      column.field === 'checkInsAverage' ||
      column.field === 'contentCompletion'
    ) {
      const nextPatientOrder = getNextPatientOrder(patientOrder, column.field);
      setPreviousColumnClicked(column);
      setPatientOrder(nextPatientOrder);
    }
  };

  const isShiftKeyPressed = useKeyPressed('Shift');

  const handleCheckboxInteraction = (
    event: React.ChangeEvent<HTMLInputElement>,
    patient: PatientsQuery['patients'][number],
    patientIndex: number,
  ) => {
    const isChecked = event.target.checked;

    const selectionInterval: PatientsObject = getSelectionInterval(
      patientIndex,
      lastSelectedPatientIndex,
      patientsList,
      isShiftKeyPressed,
    );

    const newSelectedPatients = getNewSelectedPatients(
      isChecked,
      selectionInterval,
      patient,
      selectedPatientsObject,
      isShiftKeyPressed,
      selectedPatientsList,
    );

    setSelectedPatientsObject(newSelectedPatients);
    setLastSelectedPatientIndex(patientIndex);
  };

  const columns =
    patientLifecycleStatus === PatientLifecycleStatus.Onboarding
      ? columnHeaders.onboarding
      : patientLifecycleStatus === PatientLifecycleStatus.Invited
      ? columnHeaders.invited
      : patientLifecycleStatus === PatientLifecycleStatus.Active
      ? columnHeaders.active
      : columnHeaders.archived;

  const TableRowComponent =
    patientLifecycleStatus === PatientLifecycleStatus.Onboarding
      ? OnboardingRow
      : patientLifecycleStatus === PatientLifecycleStatus.Invited
      ? InvitedRow
      : patientLifecycleStatus === PatientLifecycleStatus.Active
      ? ActiveRow
      : ArchivedRow;

  const navigate = useNavigate();

  const navigateToPatient = (patient: PatientsQuery['patients'][number]) => {
    if (isPatientProfileEnabled) {
      navigate(`/clients/${patient.programInstanceId}`);
    }
  };

  return (
    <div className="first-line mt-4 flex flex-col pb-16">
      <div className="relative min-w-full py-2">
        <table
          className={classNames(
            'min-w-full table-fixed divide-y divide-neutral-50 transition duration-150',
            !showPatientsList && 'opacity-0',
            showPatientsList && 'opacity-100',
          )}
        >
          <thead>
            <tr>
              <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                <input
                  ref={globalCheckboxRef}
                  checked={globalCheckboxChecked}
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125 sm:left-6"
                  onChange={toggleAllCheckboxes}
                />
              </th>
              {columns.map((column, i) => {
                const isFirstColumn = i === 0;
                const isLastColumn = i === columns.length - 1;

                return (
                  <TableHeader
                    key={`tableHeader_${column.field}`}
                    column={column}
                    isFirstColumn={isFirstColumn}
                    isLastColumn={isLastColumn}
                    handleHeaderClick={handleHeaderClick}
                    previousColumnClicked={previousColumnClicked}
                    columnOrder={patientOrder}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {patientsList?.map((patient, patientIndex) => {
              const foundCheckedPatient =
                patient?.programInstanceId &&
                selectedPatientsObject[patient?.programInstanceId];

              return (
                <TableRowComponent
                  key={`tableRow_${patient.programInstanceId}`}
                  patient={patient}
                  patientLifecycleStatus={patientLifecycleStatus}
                  patientIndex={patientIndex}
                  isChecked={Boolean(foundCheckedPatient)}
                  openAssignToProgramModal={openAssignToProgramModal}
                  openInviteToProgramModal={openInviteToProgramModal}
                  openFirstAppointmentDateModal={openFirstAppointmentDateModal}
                  handleCheckboxInteraction={handleCheckboxInteraction}
                  setSinglePatient={setSinglePatient}
                  navigateToPatient={navigateToPatient}
                  nextStepsButtonsDisabled={nextStepsButtonsDisabled}
                  isPatientsSelected={isPatientsSelected}
                  isPatientProfileEnabled={isPatientProfileEnabled}
                  isAssessmentsEnabled={isAssessmentsEnabled}
                  isCreateEditingProgramTaskEnabled={
                    isCreateEditingProgramTaskEnabled
                  }
                />
              );
            })}
          </tbody>
        </table>
        {(showEmptyTable || tableLoading) && (
          <div className="z-2 absolute inset-0 flex h-full w-full flex-col items-center justify-start bg-white">
            {showEmptyTable && (
              <EmptyTable
                tableType={patientLifecycleStatus}
                patientsCount={patientsCount}
                openAddPatientsModal={openAddPatientsModal}
                clearFilters={clearFilters}
              />
            )}
            {tableLoading && <Loading size="small" isCentered={false} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientTable;
