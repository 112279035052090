import { FC } from 'react';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';

import Button from '../Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../Modal';
import SelectMenu from '../SelectMenu';

import {
  AssignAssessmentModalDataQuery,
  useAssignAssessmentModalDataQuery,
  useAssignAssessmentMutation,
} from '../../../generated/graphql';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';

type AssignAssessmentModalProps = {
  programId: string;
  isModalOpen: boolean;
  setClosed: () => void;
};

interface AssignAssessmentModalFormData {
  assessment: AssignAssessmentModalDataQuery['providerAssessments'][number];
}

const AssignAssessmentModal: FC<AssignAssessmentModalProps> = ({
  programId,
  isModalOpen,
  setClosed,
}) => {
  const { data, loading } = useAssignAssessmentModalDataQuery();
  const [
    assignAssessmentMutation,
    { loading: assignAssessmentMutationLoading },
  ] = useAssignAssessmentMutation();

  const assessments = data?.providerAssessments;

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<AssignAssessmentModalFormData>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<AssignAssessmentModalFormData> = async (
    data: AssignAssessmentModalFormData,
  ) => {
    if (!data.assessment) return;

    try {
      const response = await assignAssessmentMutation({
        variables: {
          programId,
          assessmentId: data.assessment.id,
        },
      });
      if (response.data?.assignAssessment.id) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully sent ${data.assessment.shortName}!`}
          />
        ));
      }
    } catch (err) {
      console.error('Errors submitting:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      closeAndResetAssignAssessmentModal();
    }
  };

  const handleErrors: SubmitErrorHandler<AssignAssessmentModalFormData> = (
    errors,
  ) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetAssignAssessmentModal = () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const AssessmentItem: React.FC<{
    value: AssignAssessmentModalDataQuery['providerAssessments'][number];
  }> = ({ value }) => <div>{value.shortName}</div>;

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetAssignAssessmentModal}
      fetching={loading}
      width="small"
      overflow="auto"
    >
      <>
        <div className="flex flex-col items-center justify-start px-10">
          <div className="mb-1 font-serif text-subtitle text-green-150">
            Send assessment
          </div>
          <div className="font-sans text-small text-green-125">
            Choose an assessment to send
          </div>
        </div>
        <form className="flex w-full flex-col items-center justify-between">
          {assessments && (
            <Controller
              name="assessment"
              control={control}
              defaultValue={undefined}
              rules={{ required: true }}
              render={({ field }) => {
                const { onChange, value } = field;

                const fieldValue = value;

                return (
                  <SelectMenu
                    fieldValue={fieldValue}
                    onChange={onChange}
                    smallLabel
                    fieldOptions={assessments}
                    SelectOptionComponent={AssessmentItem}
                    containerClassName="w-96 mt-16 mb-32"
                    label="Assessment"
                  />
                );
              }}
            />
          )}

          <div className="flex flex-col items-center justify-end">
            <Button
              type="submit"
              title="Send assessment"
              theme="primary"
              className="my-10"
              disabled={assignAssessmentMutationLoading}
              IconComponent={ArrowRightLong}
              onClick={handleSubmit(onSubmit, handleErrors)}
            />
          </div>
        </form>
      </>
    </Modal>
  );
};

export default AssignAssessmentModal;
