import { SvgIconComponent } from '../types/svgs';

const ThumbnailHeadphones: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#F4F3F1" />
      <path
        d="M31 23C31 19.134 27.866 16 24 16C20.134 16 17 19.134 17 23M28 26.5V28.5C28 28.9647 28 29.197 28.0384 29.3902C28.1962 30.1836 28.816 30.8037 29.6094 30.9615C29.8026 31 30.0353 31 30.5 31C30.9647 31 31.197 31 31.3902 30.9615C32.1836 30.8037 32.8041 30.1836 32.9619 29.3902C33.0003 29.197 33 28.9647 33 28.5V26.5C33 26.0353 33.0003 25.8031 32.9619 25.6099C32.8041 24.8165 32.1836 24.1962 31.3902 24.0384C31.197 24 30.9647 24 30.5 24C30.0353 24 29.8026 24 29.6094 24.0384C28.816 24.1962 28.1962 24.8165 28.0384 25.6099C28 25.8031 28 26.0353 28 26.5ZM20 26.5V28.5C20 28.9647 20.0003 29.197 19.9619 29.3902C19.8041 30.1836 19.1836 30.8037 18.3902 30.9615C18.197 31 17.9642 31 17.4996 31C17.0349 31 16.8026 31 16.6094 30.9615C15.816 30.8037 15.1962 30.1836 15.0384 29.3902C15 29.197 15 28.9647 15 28.5V26.5C15 26.0353 15 25.8031 15.0384 25.6099C15.1962 24.8165 15.816 24.1962 16.6094 24.0384C16.8026 24 17.0349 24 17.4996 24C17.9642 24 18.197 24 18.3902 24.0384C19.1836 24.1962 19.8041 24.8165 19.9619 25.6099C20.0003 25.8031 20 26.0353 20 26.5Z"
        stroke="#68716E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailHeadphones;
