import { FC } from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { ProgramTagWithCountDataFragment } from '../../../generated/graphql';
import ProgramTagPill from './ProgramTagPill';
import { pluralize } from '../../lib/copy';

const SendToTagConfirmationModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  programTags: ProgramTagWithCountDataFragment[];
}> = ({ isModalOpen, setClosed, onConfirm, programTags }) => {
  return (
    <Modal
      name="SendToTagConfirmationModal"
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="small"
    >
      <div className="flex flex-col items-center justify-center px-12 py-10">
        <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
          Are you sure you want to send this mail to all clients in{' '}
          {pluralize(programTags.length, 'this tag', 'these tags', false)}?
        </div>
        <div className="mt-4 flex flex-wrap gap-x-2 gap-y-2">
          {programTags.map((programTag) => (
            <ProgramTagPill key={programTag.id} programTag={programTag} />
          ))}
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title="Send"
            theme="primary"
            onClick={onConfirm}
            // disabled={isArchivingResource}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SendToTagConfirmationModal;
