import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import classNames from 'classnames';
import { useStopwatch } from 'react-timer-hook';
import { XIcon } from '@heroicons/react/solid';

import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc';

import { getDurationSS } from '../../lib/time';

import Spinner from '../../svgs/Spinner';

import IconButton from '../IconButton';
import AudioPlayer from '../AudioPlayer';
import RecordButton, { RecordButtonSize } from './RecordButton';
import { getAudioDurationFromBlob } from '../../lib/audio';

const RECORDING_MAX_LENGTH = 60 * 5; // 5 minutes

interface AudioRecorderPlayerProps {
  recordingEnabled: boolean;
  recordingMaxLength?: number;
  isUploading?: boolean;
  audioMediaUrl?: string;
  hasUploaded?: boolean;
  filePrefix?: string;
  onAdd: (audioFile: File, audioDuration: number) => void;
  onDiscard?: () => void;
  onRecord?: () => void;
  containerClassName?: string;
  controlsClassName?: string;
  recordButtonSize?: RecordButtonSize;
  hideDiscardButton?: boolean;
  autostart?: boolean;
}

export interface AudioRecorderPlayerRef {
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<string | void>;
  resetRecorder: () => Promise<void>;
}

const AudioRecorderPlayer: React.ForwardRefExoticComponent<
  AudioRecorderPlayerProps & React.RefAttributes<AudioRecorderPlayerRef>
> = forwardRef<AudioRecorderPlayerRef, AudioRecorderPlayerProps>(
  (
    {
      recordingEnabled,
      recordingMaxLength = RECORDING_MAX_LENGTH,
      isUploading = false,
      audioMediaUrl,
      filePrefix = 'audio',
      onRecord,
      onAdd,
      onDiscard,
      containerClassName,
      controlsClassName,
      recordButtonSize,
      hideDiscardButton = false,
      autostart = false,
    },
    ref,
  ) => {
    const {
      start: startTimer,
      reset: resetTimer,
      minutes: timerMinutes,
      seconds: timerSeconds,
      isRunning: timerRunning,
    } = useStopwatch({
      autoStart: false,
    });
    const streamRef = useRef<MediaStream>();
    const recorderRef = useRef<RecordRTCPromisesHandler>();

    useImperativeHandle(ref, () => ({
      startRecording,
      stopRecording,
      resetRecorder,
    }));

    const [audioBlob, setAudioBlob] = useState<Blob | undefined>();
    const [audioDuration, setAudioDuration] = useState<number | undefined>();
    const [isRecording, setIsRecording] = useState(false);
    const [hasPermissionDenied, setHasPermissionDenied] = useState(false);

    const hasAudioInput = Boolean(audioBlob || audioMediaUrl);
    const isAudioPlayable = Boolean(hasAudioInput && !isRecording);

    const resetRecorder = async () => {
      setHasPermissionDenied(false);
      setAudioBlob(undefined);
      setAudioDuration(undefined);
      streamRef.current?.getTracks().forEach((track) => track.stop());
      streamRef.current = undefined;
      await recorderRef.current?.stopRecording();
      await recorderRef.current?.destroy();
      setIsRecording(false);
      resetTimer(new Date(), false);
    };

    const onDiscardAudio = () => {
      resetRecorder();
      onDiscard?.();
    };

    const startRecording = async () => {
      await resetRecorder();
      try {
        streamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorderRef.current = new RecordRTCPromisesHandler(streamRef.current, {
          type: 'audio',
          recorderType: RecordRTC.StereoAudioRecorder, // audio/wav
          numberOfAudioChannels: 2,
        });
        await recorderRef.current.startRecording();

        startTimer();
        setIsRecording(true);
        onRecord?.();
      } catch (error) {
        if ((error as Error).name === 'NotAllowedError') {
          // Permission denied
          setHasPermissionDenied(true);
        }
        console.error('Error starting recording', error);
      }
    };

    const stopRecording = async (): Promise<string | void> => {
      await recorderRef.current?.stopRecording();
      streamRef.current?.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      resetTimer(new Date(), false);

      const newAudioBlob = await recorderRef.current?.getBlob();
      if (newAudioBlob) {
        setAudioBlob(newAudioBlob);
        const audioFile = new File(
          [newAudioBlob],
          `${filePrefix}-${Date.now()}`,
          {
            type: newAudioBlob.type,
          },
        );
        const audioDuration = await getAudioDurationFromBlob(newAudioBlob);
        // If onAdd returns an ID, like a media or voice note ID, it can be
        // immediately accessed this way.
        return onAdd(audioFile, audioDuration);
      }
    };

    const onClickStartStopRecordButton = () => {
      if (!isRecording) {
        startRecording();
      } else {
        stopRecording();
      }
    };

    useEffect(() => {
      if (autostart) {
        startRecording();
      }
    }, [autostart]);

    // Stop recording after 5 minutes
    if (timerSeconds > recordingMaxLength) {
      stopRecording();
    }

    const recordControls = (
      <div
        className={classNames(
          'flex w-full flex-col items-center justify-center gap-4',
          controlsClassName,
        )}
      >
        <RecordButton
          isRecording={isRecording}
          recordingEnabled={recordingEnabled}
          onClick={onClickStartStopRecordButton}
          size={recordButtonSize}
        />
        {timerRunning && (
          <div className="text-netural-150 text-caption">
            <span>{`${timerMinutes}:${getDurationSS(timerSeconds)}`}</span>
          </div>
        )}
      </div>
    );

    return (
      <div
        className={classNames(
          'flex flex-col items-center justify-center',
          hasAudioInput && !isUploading && 'w-full',
          containerClassName,
        )}
      >
        {isAudioPlayable ? (
          isUploading ? (
            <div className="flex h-[66px] w-full flex-row items-center justify-center">
              <Spinner className="h-5 w-5 text-neutral-125" />
            </div>
          ) : (
            <AudioPlayer
              playerId="audio-recorder"
              audioBlob={audioBlob}
              mediaUrl={audioMediaUrl}
              duration={audioDuration}
              defaultStyle={false}
              triggerStop
              className="visible mt-4 h-[48px] px-4 py-8"
            >
              {!hideDiscardButton && (
                <div className="absolute -top-3 -right-3">
                  <IconButton
                    aria-label="Discard audio"
                    IconComponent={XIcon}
                    className="hover:rounded-full hover:bg-transparent focus:ring-0"
                    iconClassName="h-4 w-4 p-0.5 rounded-full bg-neutral-125 text-white hover:bg-neutral-150"
                    onClick={onDiscardAudio}
                  />
                </div>
              )}
            </AudioPlayer>
          )
        ) : hasPermissionDenied ? (
          <div>
            To record a voice memo, Homecoming needs access to your microphone.
            Open your browser settings and allow Homecoming to access your
            microphone.
          </div>
        ) : (
          recordControls
        )}
      </div>
    );
  },
);

export default AudioRecorderPlayer;
