import { useState, useEffect } from 'react';
import Wavesurfer from 'wavesurfer.js';

const neutral100 = '#DBD8D1';
const neutral125 = '#394642';
const neutral150 = '#232B29';

const wavePresets = {
  pixelRatio: 4,
  height: 32,
  barMinHeight: 4,
  barGap: 2,
  barWidth: 2,
  barRadius: 4,
  waveColor: neutral100,
  progressColor: neutral150,
  cursorColor: neutral125,
};

export const useWaveSurfer = (playerId: string) => {
  const containerId = `#${playerId}`;

  const [wavesurfer, setWavesurfer] = useState<Wavesurfer | undefined>();

  const initWaveSurfer = async (hasWaveFormElement: boolean) => {
    if (!hasWaveFormElement) {
      const wavesurfer = Wavesurfer.create({
        ...wavePresets,
        container: containerId,
      });
      setWavesurfer(wavesurfer);
    }
  };

  useEffect(() => {
    // prevents double <canvas> element dom insertion
    const hasChildElement = Boolean(
      document.getElementById(playerId)?.hasChildNodes(),
    );
    initWaveSurfer(hasChildElement);
  }, []);

  return wavesurfer;
};
