import { ChevronRightIcon } from '@heroicons/react/outline';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  AssessmentQuestionType,
  CompleteAssessmentAnswerInput,
  FreeTextAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  MultipleChoiceAssessmentQuestion,
  MultipleChoiceScoredAssessmentQuestion,
  MultipleChoiceScoredAssessmentQuestionAnswerOption,
  ProgramActivityDataFragment,
  ProgramAssessmentSignatureUserDataFragment,
  RichTextAssessmentQuestion,
  SignatureAssessmentQuestion,
  SignatureUser,
  useCompleteProgramActivityMutation,
  useProgramAssessmentQuery,
} from '../../../../generated/graphql';
import Button from '../../../components/Button';
import TextAreaGroup from '../../../components/TextAreaGroup';
import Spinner from '../../../svgs/Spinner';

import _ from 'lodash';
import Markdown from 'marked-react';
import useFormPersist from 'react-hook-form-persist';
import Editor from '../../../components/Editor';
import {
  isMultiSelectAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isMultiSignatureAssessmentQuestion,
} from '../../../lib/assessments';
import markdownToTxt from '../../../lib/markdown-to-txt';
import { formatCurrentDate } from '../../../lib/time';
import Checkbox from '../../../components/Checkbox';
import toast from 'react-hot-toast';
import ToastAlert from '../../../components/ToastAlert';
import CompletedSignature from '../../../components/CompletedSignature';
import SignatureDisplay from '../../CompleteProviderForm/SignatureDisplay';

type AssessmentQuestionWithAnswers =
  | MultipleChoiceAssessmentQuestion
  | MultipleChoiceScoredAssessmentQuestion
  | MultiSelectAssessmentQuestion;
type AssessmentQuestion =
  | FreeTextAssessmentQuestion
  | SignatureAssessmentQuestion
  | MultiSignatureAssessmentQuestion
  | RichTextAssessmentQuestion
  | AssessmentQuestionWithAnswers;

type AssessmentFormValues = {
  answers: {
    value: string;
    values: string[];
    signatureName?: string;
    signatureTermsAgreed?: boolean;
  }[];
  singleSignatureTermsAgreed?: boolean;
};

const Question: FC<{
  question: AssessmentQuestion;
  idx: number;
  register: UseFormRegister<AssessmentFormValues>;
  control: Control<AssessmentFormValues, unknown>;
  watch: UseFormWatch<AssessmentFormValues>;
  patientAssessmentSignatureUserId?: string;
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
}> = ({
  question,
  register,
  idx,
  control,
  watch,
  patientAssessmentSignatureUserId,
  programAssessmentSignatureUsers,
  currentSigner,
}) => {
  const signatureNamePath: `answers.${number}.signatureName` = `answers.${idx}.signatureName`;
  const signatureNameWatch = watch(signatureNamePath);

  switch (question.questionType) {
    case AssessmentQuestionType.FreeText:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
          <TextAreaGroup
            key={markdownToTxt(question.question)}
            {...register(`answers.${idx}.value`, {
              required: question.isRequired,
            })}
            label={question.question}
            labelHidden
          />
        </div>
      );
    case AssessmentQuestionType.RichText:
      question = question as RichTextAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
          <Controller
            control={control}
            name={`answers.${idx}.value`}
            rules={{
              required: {
                value: question.isRequired,
                message: 'Please enter your response',
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Editor
                  placeholder="Enter your response..."
                  className="text-neutral-150"
                  initialContentMarkdown={value}
                  allowLinks={false}
                  onChange={(editorState) => {
                    editorState.read(() => {
                      onChange($convertToMarkdownString(TRANSFORMERS));
                    });
                  }}
                  onError={(error: Error) => {
                    console.error(error);
                  }}
                />
              );
            }}
          />
        </div>
      );
    case AssessmentQuestionType.Statement:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
        </div>
      );

    case AssessmentQuestionType.Signature:
      question = question as SignatureAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
          <div className="mb-3 text-body font-medium">
            Type your name here to sign electronically:
          </div>
          <div className="font-cursive text-subtitle">{signatureNameWatch}</div>
          <input
            className="my-3 block w-full resize-none rounded border-2 border-transparent bg-neutral-50
              px-4 py-2 text-body text-green-150 transition-colors
              placeholder:text-neutral-125/75 read-only:text-neutral-125/75 focus:outline-none focus:ring-0"
            {...register(signatureNamePath, { required: question.isRequired })}
          />
          <div className="mb-6 text-caption">{formatCurrentDate()}</div>
        </div>
      );

    case AssessmentQuestionType.MultiSignature:
      question = question as MultiSignatureAssessmentQuestion;

      const assessmentSignatureUsersIds = question.assessmentSignatureUsers.map(
        ({ id }) => id,
      );

      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>

          <div className="pb-2">
            {programAssessmentSignatureUsers
              .sort(
                (a, b) =>
                  a.assessmentSignatureUser.orderIndex -
                  b.assessmentSignatureUser.orderIndex,
              )
              .filter(({ assessmentSignatureUser }) =>
                assessmentSignatureUsersIds.includes(
                  assessmentSignatureUser.id,
                ),
              )
              .map((programAssessmentSignatureUser) => {
                const {
                  id,
                  assessmentSignatureUser,
                  signedAt,
                  signatureName,
                  signatureRole,
                } = programAssessmentSignatureUser;

                const isCurrentSigner = currentSigner?.id === id;
                const isSignatureDisplayDisabled =
                  assessmentSignatureUser?.orderIndex >
                  currentSigner?.assessmentSignatureUser?.orderIndex;
                const hasSigned = Boolean(signedAt);

                return (
                  <div className="my-4" key={id}>
                    <SignatureDisplay
                      className="lg:w-2/3 lg:min-w-[200px]"
                      programAssessmentSignatureUser={
                        programAssessmentSignatureUser
                      }
                      disabled={isSignatureDisplayDisabled}
                    />
                    {!isCurrentSigner && hasSigned && (
                      <CompletedSignature
                        signatureName={signatureName}
                        signatureRole={signatureRole}
                        programAssessmentSignatureUser={
                          programAssessmentSignatureUser
                        }
                      />
                    )}
                    {isCurrentSigner && (
                      <>
                        <div className="mb-2 mt-6 text-caption font-medium">
                          Type your name here to sign electronically:
                        </div>

                        {signatureNameWatch && (
                          <CompletedSignature
                            signatureName={signatureNameWatch}
                            nowFormattedTimestamp={formatCurrentDate()}
                            hideNameAndRole
                          />
                        )}

                        <input
                          className="mb-6 mt-3 block w-full resize-none rounded border-2 border-transparent bg-neutral-50
                px-4 py-2 text-body text-green-150 transition-colors
                placeholder:text-neutral-125/75 read-only:text-neutral-125/75 focus:outline-none focus:ring-0"
                          {...register(signatureNamePath, {
                            required: question.isRequired,
                          })}
                        />
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      );

    case AssessmentQuestionType.MultipleChoiceScored:
    case AssessmentQuestionType.MultipleChoice:
      question = question as MultipleChoiceAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <div key={index} className="mb-2">
                <input
                  type="radio"
                  id={`answer-${idx}-${index}`}
                  value={answerOption}
                  {...register(`answers.${idx}.value`, {
                    required: question.isRequired,
                  })}
                  className={classNames(
                    'border-[1.5px] border-green-125 text-green-125 focus:ring-green-125',
                  )}
                />
                <label
                  className="ml-2 text-caption text-neutral-125"
                  htmlFor={`answer-${idx}-${index}`}
                >
                  {answerOption}
                </label>
              </div>
            );
          })}
        </div>
      );

    case AssessmentQuestionType.MultiSelect:
      question = question as MultiSelectAssessmentQuestion;
      return (
        <div>
          <div className="mb-3 text-big-body">
            <Markdown>{question.question}</Markdown>
          </div>
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <div key={index} className="mb-2">
                <Controller
                  name={`answers.${idx}.values`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <input
                        type="checkbox"
                        id={`answer-${index}`}
                        value={answerOption}
                        defaultChecked={value && value.includes(answerOption)}
                        className="h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125"
                        onChange={(e) => {
                          if (!value) {
                            onChange([answerOption]);
                          } else {
                            const newValue = e.target.checked
                              ? value.concat([answerOption])
                              : _.without(value, answerOption);

                            onChange(newValue);
                          }
                        }}
                      />
                    );
                  }}
                />

                <label
                  className="ml-2 text-caption text-neutral-125"
                  htmlFor={`answer-${index}`}
                >
                  {answerOption}
                </label>
              </div>
            );
          })}
        </div>
      );
  }

  return <div></div>;
};

const localStoreKey = (
  programActivity: ProgramActivityDataFragment,
): string => {
  return ['form', programActivity.id.toString()].join(':');
};

const ProgramAssessmentForm: FC<{
  programActivity: ProgramActivityDataFragment;
  breadcrumbLabel?: string;
  onComplete?: () => void;
  onClickBreadcrumb?: () => void;
}> = ({ programActivity, onComplete, onClickBreadcrumb, breadcrumbLabel }) => {
  const { watch, setValue, control, register, handleSubmit, formState } =
    useForm<AssessmentFormValues>();

  useFieldArray({
    control,
    name: 'answers',
    rules: { required: true },
  });

  const { clear: clearForm } = useFormPersist(localStoreKey(programActivity), {
    watch,
    setValue,
  });

  const { data, loading } = useProgramAssessmentQuery({
    variables: { programAssessmentId: programActivity?.programAssessment?.id },
    skip: !programActivity?.programAssessment?.id,
  });

  const programAssessment = data?.programAssessment;
  const programAssessmentSignatureUsers =
    data?.programAssessment?.programAssessmentSignatureUsers;
  const currentSigner = data?.programAssessment?.currentSigner;

  const formIsReadOnly =
    currentSigner &&
    currentSigner?.assessmentSignatureUser.signatureUserType &&
    currentSigner?.assessmentSignatureUser.signatureUserType !==
      SignatureUser.PatientUser;

  useEffect(() => {
    if (formIsReadOnly) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level={'warning'}
          message="It's not your turn to sign this form"
        />
      ));
    }
  }, [formIsReadOnly]);

  const questions = programAssessment?.assessment
    ?.questions as AssessmentQuestion[];

  const [completeProgramActivity] = useCompleteProgramActivityMutation();

  const onSubmit: SubmitHandler<AssessmentFormValues> = async (data) => {
    const answers: CompleteAssessmentAnswerInput[] = questions.map(
      (question, idx) => {
        const answerValue = data.answers[idx]?.value ?? '';
        const newAnswer: CompleteAssessmentAnswerInput = {
          question: question.question,
          questionType: question.questionType,
        };

        if (isSignatureAssessmentQuestion(question)) {
          newAnswer.signatureName = data.answers[idx]?.signatureName;
        } else if (isMultiSignatureAssessmentQuestion(question)) {
          newAnswer.signatureName = data.answers[idx]?.signatureName;
        } else {
          newAnswer.answer = answerValue;
        }

        // Add multiselect answers
        if (isMultiSelectAssessmentQuestion(question)) {
          const answers: string[] = data.answers[idx]?.values ?? [];
          newAnswer.answers = answers;
        }

        const answerOptions =
          (question as MultipleChoiceScoredAssessmentQuestion)?.answerOptions ??
          [];

        const answer = answerOptions.find(
          (option) => option.answerOption === answerValue,
        );

        // add scores
        const multipleChoiceSumAnswer =
          answer as MultipleChoiceScoredAssessmentQuestionAnswerOption;
        if (
          multipleChoiceSumAnswer &&
          !_.isNil(multipleChoiceSumAnswer.score)
        ) {
          newAnswer.score = multipleChoiceSumAnswer.score;
        }

        const multipleChoiceQuestion =
          question as MultipleChoiceScoredAssessmentQuestion;
        if (
          multipleChoiceQuestion &&
          !_.isNil(multipleChoiceQuestion.subscale)
        ) {
          newAnswer.subscale = multipleChoiceQuestion.subscale;
        }

        return newAnswer;
      },
    );

    await completeProgramActivity({
      variables: {
        input: {
          programActivityId: programActivity.id,
          assessmentAnswers: answers,
        },
      },
    });

    // // Clear persisted form values
    clearForm();

    onComplete?.();
  };

  if (loading) {
    return (
      <div className="mt-20 flex h-full w-full flex-row items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // Identify signature and multi-signature questions
  const signatureQuestionIndexes = questions
    ?.map((question, idx) => {
      if (
        question.questionType === AssessmentQuestionType.Signature ||
        question.questionType === AssessmentQuestionType.MultiSignature
      ) {
        return idx;
      }
      return null;
    })
    .filter((idx) => idx !== null) as number[];

  const requiresTermsAndConditions = signatureQuestionIndexes.length > 0;

  const handleSingleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = e.target.checked;
    signatureQuestionIndexes.forEach((idx) => {
      setValue(`answers.${idx}.signatureTermsAgreed`, isChecked);
    });
  };

  return (
    <div className="lg:p flex flex-row justify-center px-6">
      <div className="mt-6 flex w-full flex-col lg:w-[800px]">
        {onClickBreadcrumb && breadcrumbLabel && (
          <div className="mb-2 flex flex-row">
            <button
              onClick={onClickBreadcrumb}
              className="mr-1 text-caption font-medium text-green-100 hover:underline"
            >
              {breadcrumbLabel}
            </button>
            <ChevronRightIcon className="relative top-[1px] mx-1 h-4 w-4 text-neutral-150" />
            <span className="text-caption text-green-150">
              {programAssessment?.assessment.name}
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 font-serif text-subtitle">
            {programAssessment?.assessment.name}
          </div>
          <div className="mb-6 whitespace-pre-wrap break-words text-body">
            {programAssessment?.assessment.instructions ??
              programAssessment?.assessment.description}
          </div>
          <div className="grid gap-y-6">
            {questions?.map((question, idx) => {
              const hasError = Boolean(formState.errors?.answers?.[idx]);
              return (
                <div key={`${idx}_${question.question}`}>
                  <div
                    className={classNames(
                      'rounded-lg border border-neutral-75 p-4',
                      { 'border-red-100': hasError },
                    )}
                  >
                    {programAssessment?.assessment.questionFrame && (
                      <div className="mb-2 text-caption text-neutral-125">
                        {programAssessment?.assessment.questionFrame}
                      </div>
                    )}
                    <Question
                      key={`${idx}_${question.question}`}
                      question={question as AssessmentQuestion}
                      idx={idx}
                      register={register}
                      control={control}
                      watch={watch}
                      patientAssessmentSignatureUserId={
                        currentSigner?.assessmentSignatureUser.id
                      }
                      programAssessmentSignatureUsers={
                        programAssessmentSignatureUsers
                      }
                      currentSigner={currentSigner}
                    />
                  </div>
                  {hasError && (
                    <div className="mt-2 text-body text-red-100">
                      Please answer the question above
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {requiresTermsAndConditions && (
            <div className="mt-6">
              <Checkbox
                id="single-signature-terms-agreed"
                labelContent={
                  <div className="ml-2">
                    I agree to the electronic signature{' '}
                    <a
                      href="https://www.homecoming.health/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-100"
                    >
                      terms of service
                    </a>
                    .
                  </div>
                }
                onChange={handleSingleCheckboxChange}
                {...register('singleSignatureTermsAgreed', { required: true })}
              />
              {formState.errors.singleSignatureTermsAgreed && (
                <div className="mt-3 text-caption text-red-100">
                  You must agree to the electronic signature terms of service
                </div>
              )}
            </div>
          )}
          <div className="mt-6 mb-12 flex flex-row justify-end">
            <Button type="submit" title="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProgramAssessmentForm;
