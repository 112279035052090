import { SvgIconComponent } from '../types/svgs';

const ThumbnailLink: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#FFDCA6" />
      <path
        d="M21.8419 24.375C21.842 25.27 22.1846 26.1282 22.7943 26.761C23.404 27.3938 24.2309 27.7494 25.0931 27.7495H29.6918C30.1235 27.7573 30.5524 27.6758 30.9534 27.5097C31.3544 27.3436 31.7196 27.0963 32.0275 26.7821C32.3355 26.468 32.5801 26.0934 32.747 25.6801C32.914 25.2668 33 24.8232 33 24.375C33 23.9269 32.914 23.4832 32.747 23.07C32.5801 22.6567 32.3355 22.2821 32.0275 21.9679C31.7196 21.6538 31.3544 21.4065 30.9534 21.2404C30.5524 21.0743 30.1235 20.9928 29.6918 21.0006L28.4254 21M26.0451 24.375C26.0449 23.4801 25.7024 22.6219 25.0927 21.9891C24.483 21.3563 23.6561 21.0007 22.7938 21.0006L18.1952 21.0006C17.3427 21.016 16.5301 21.3783 15.9325 22.0094C15.3349 22.6406 15 23.4901 15 24.375C15 25.26 15.3349 26.1095 15.9325 26.7407C16.5301 27.3718 17.3427 27.7341 18.1952 27.7495H19.4598"
        stroke="#FFA41C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailLink;
