import { FC } from 'react';
import classNames from 'classnames';
import { ASSESSMENTS_COLOR_MAP } from '../../../lib/colors';

const InterpretationPill: FC<{
  color: string;
  min: number;
  max: number;
  categorization: string;
  hasPercentage?: boolean;
}> = ({ color, min, max, categorization, hasPercentage = false }) => {
  return (
    <div className="flex flex-row items-center justify-start text-small-caption">
      <div
        className={classNames(
          'mr-2 flex flex-col items-center whitespace-nowrap rounded-full px-4 py-1 font-bold text-neutral-150',
          ASSESSMENTS_COLOR_MAP?.[color]?.default,
          !hasPercentage ? 'w-[60px]' : 'w-[65px]',
        )}
      >
        {min}-{max}
        {hasPercentage && '%'}
      </div>
      <span className="text-neutral-125">{categorization}</span>
    </div>
  );
};

export default InterpretationPill;
