import { useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import {
  ProgramActivityDataFragment,
  useMarkCommentsReadMutation,
  useProgramFollowUpQuery,
} from '../../../../generated/graphql';

import { defaultTransitionProps } from '../../../lib/animation';
import { getWeekdayDate } from '../../../lib/time';
import Spinner from '../../../svgs/Spinner';

import AudioPlayer from '../../../components/AudioPlayer';

import { usePatientProfileContext } from '..';
import Chat from '../../../components/Comments/Chat';
import ProgramActivityCard from '../../../components/ProgramActivityCard';
import ProgramActivitySlideover from '../../../components/ProgramActivitySlideover';
import Linkifier from '../../../components/Linkifier';
import Avatar from '../../../components/Avatar';
import { useAuth } from '../../../../contexts/AuthContext';
import { customToast } from '../../../components/ToastAlert/customToast';

const FollowUpDetails = () => {
  const params = useParams();
  const programFollowUpId = params.programFollowUpId!;

  const { patient } = usePatientProfileContext();
  const { showUpgradeBanner } = useAuth();

  const [markCommentsReadMutation] = useMarkCommentsReadMutation();

  const {
    data: programFollowUpData,
    error: programFollowUpError,
    loading: programFollowUpLoading,
    refetch: refetchProgramFollowUp,
  } = useProgramFollowUpQuery({
    variables: {
      programFollowUpId,
    },
    onError: () => {
      customToast.error('Unable to fetch mail.');
    },
  });

  const selectedProgramFollowUp = programFollowUpData?.programFollowUp;

  const noteText = selectedProgramFollowUp?.providerNote;
  const voiceNote = selectedProgramFollowUp?.providerVoiceNote;

  useEffect(() => {
    const markFollowUpCommentsRead = async () => {
      if (
        selectedProgramFollowUp &&
        selectedProgramFollowUp.unreadCommentCount > 0
      ) {
        await markCommentsReadMutation({
          variables: {
            input: {
              programFollowUpId: selectedProgramFollowUp.id,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(selectedProgramFollowUp),
              fields: {
                unreadCommentCount: () => 0,
              },
            });
          },
        });
      }
    };

    markFollowUpCommentsRead();
  }, [selectedProgramFollowUp]);

  const [selectedProgramActivity, setSelectedProgramActivity] =
    useState<ProgramActivityDataFragment>();
  const [isProgramActivitySlideoverOpen, setIsProgramActivitySlideoverOpen] =
    useState<boolean>(false);

  return (
    <>
      <Transition
        show={Boolean(programFollowUpData && !programFollowUpError)}
        {...defaultTransitionProps}
        className="h-full overflow-y-auto xl:flex-grow"
      >
        {selectedProgramFollowUp && (
          <div className="flex h-full w-full flex-row items-start justify-start sm:flex-col xl:h-full xl:flex-row">
            <div className="flex h-full w-full flex-grow flex-col overflow-hidden text-neutral-150 shadow-none">
              <div className="flex h-full w-full flex-col overflow-y-auto overscroll-y-contain xl:flex-row">
                {/* Mail details */}
                <div className="flex w-full flex-col p-6 pb-12 xl:overflow-y-auto xl:overscroll-y-contain">
                  {/* Subject and date */}
                  <div className="mb-6 flex w-full flex-row items-center justify-between gap-x-6">
                    <div className="font-serif text-subtitle-small font-light line-clamp-3">
                      {selectedProgramFollowUp.subject ||
                        getWeekdayDate(selectedProgramFollowUp.createdAt)}
                    </div>
                    {selectedProgramFollowUp.subject && (
                      <div className="whitespace-nowrap text-small-caption text-neutral-110">
                        {getWeekdayDate(selectedProgramFollowUp.createdAt)}
                      </div>
                    )}
                  </div>

                  {/* Sender */}
                  <div className="mb-4 flex flex-row items-center gap-x-2">
                    <Avatar
                      size="medium"
                      imageUrl={selectedProgramFollowUp.sentByProfileImageUrl}
                      name={selectedProgramFollowUp.sentByName ?? ''}
                    />
                    <div className="text-caption font-bold text-neutral-125">
                      {selectedProgramFollowUp.sentByName}
                    </div>
                  </div>

                  {voiceNote && (
                    <AudioPlayer
                      playerId={voiceNote.id}
                      duration={voiceNote.duration}
                      mediaUrl={voiceNote.media.url}
                      className="mb-4"
                      triggerStop
                    />
                  )}
                  {noteText && (
                    <div className="mb-6 whitespace-pre-wrap break-words text-caption">
                      <Linkifier text={noteText} />
                    </div>
                  )}
                  {!noteText && !voiceNote && (
                    <div className="mb-6 flex w-full items-center justify-center whitespace-pre-wrap rounded-lg border border-neutral-75 p-4 text-caption font-medium text-neutral-100">
                      No note provided
                    </div>
                  )}
                  <div className="flex flex-col gap-y-4">
                    {selectedProgramFollowUp.programActivities.map(
                      (programActivity, index) => (
                        <ProgramActivityCard
                          key={`programActivityCard_${index}`}
                          programActivity={programActivity}
                          className={classNames(
                            index ===
                              selectedProgramFollowUp.programActivities.length -
                                1 && 'mb-6',
                          )}
                          onClickProgramActivity={(programActivity) => {
                            setSelectedProgramActivity(programActivity);
                            setIsProgramActivitySlideoverOpen(true);
                          }}
                        />
                      ),
                    )}
                  </div>
                </div>

                {/* Comments */}
                <div className="relative h-full w-full flex-grow xl:mb-0 xl:w-1/3 xl:min-w-[400px] xl:border-l xl:border-neutral-75">
                  <Chat
                    chatHeader="Comments"
                    patient={patient}
                    comments={selectedProgramFollowUp.comments}
                    programFollowUpId={selectedProgramFollowUp.id}
                    onCommentAdded={() => refetchProgramFollowUp()}
                    onCommentDeleted={() => refetchProgramFollowUp()}
                    className={
                      !showUpgradeBanner ? 'max-h-[563px]' : 'max-h-[507px]'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>

      <ProgramActivitySlideover
        isOpen={isProgramActivitySlideoverOpen}
        onClose={() => setIsProgramActivitySlideoverOpen(false)}
        programActivity={selectedProgramActivity}
      />
      {programFollowUpLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default FollowUpDetails;
