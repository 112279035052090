import { FC } from 'react';

export const DEFAULT_TAGLINE =
  'Tagline that encapsulates the support you offer';
export const DEFAULT_ABOUT =
  "Provide a succinct yet compelling narrative of your professional journey, highlighting your credentials, philosophy, and what sets you apart. Focus on why you're passionate about your niche and how your work transforms lives.";
export const DEFAULT_SERVICES =
  "Offer a clear, concise summary of your services, including session formats (individual, group, online), programs, and workshops, along with an overview of pricing. This section should invite inquiries and signal your openness to discuss how your offerings can be customized to meet clients' unique needs.";

const PractitionerInfoRightColumn: FC<{
  tagline?: string;
  about?: string;
  services?: string;
  inEditMode?: boolean;
  editModeTaglineChildren?: React.ReactNode;
  editModeAboutChildren?: React.ReactNode;
  editModeServicesChildren?: React.ReactNode;
}> = ({
  tagline,
  about,
  services,
  inEditMode,
  editModeTaglineChildren,
  editModeAboutChildren,
  editModeServicesChildren,
}) => (
  <div className="flex flex-grow flex-col space-y-12">
    {!inEditMode ? (
      <div className="text-pretty font-serif text-title font-light text-neutral-125">
        {tagline || DEFAULT_TAGLINE}
      </div>
    ) : (
      editModeTaglineChildren
    )}
    <div>
      <div className="mb-2 text-body font-medium text-neutral-150">About</div>
      {!inEditMode ? (
        <div className="text-caption text-neutral-125">
          {about || DEFAULT_ABOUT}
        </div>
      ) : (
        editModeAboutChildren
      )}
    </div>
    <div>
      <div className="mb-2 text-body font-medium text-neutral-150">
        Services
      </div>

      {!inEditMode ? (
        <div className="text-caption text-neutral-125">
          {services || DEFAULT_SERVICES}
        </div>
      ) : (
        editModeServicesChildren
      )}
    </div>
  </div>
);

export default PractitionerInfoRightColumn;
