import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Community from '../svgs/Community';
import ClinicLarge from '../svgs/ClinicLarge';
import hcLogo from '../../assets/images/logo/logo-green-xl.png';

const navigation = [
  {
    Icon: Community,
    name: 'Clients',
    path: '/clients',
  },
  {
    Icon: ClinicLarge,
    name: 'Programs',
    path: '/programs',
  },
];

export default function Sidebar() {
  const { pathname } = useLocation();

  return (
    <nav
      className="fixed z-30 flex h-screen w-24 flex-col border-r border-neutral-50 bg-white"
      aria-label="Sidebar"
    >
      <Link
        to="/"
        className="mb-5 flex h-16 flex-col items-center justify-center border-b border-neutral-50"
      >
        <img src={hcLogo} className="h-8 w-8" alt="logo" />
      </Link>
      {navigation.map((item) => {
        const { Icon, name, path } = item;
        const isActiveTab = pathname.startsWith(path);
        return (
          <Link
            key={name}
            to={path}
            className={classNames(
              'mb-5 flex flex-col items-center border-l-4 px-3 py-2 text-body',
              isActiveTab
                ? 'border-l-green-100 text-green-125'
                : 'border-transparent text-neutral-110',
            )}
          >
            <Icon isActive={isActiveTab} aria-hidden="true" />
            {name}
          </Link>
        );
      })}
    </nav>
  );
}
