import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import omit from 'lodash.omit';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';

import {
  PatientQuery,
  useDeactivatePatientsMutation,
} from '../../../../generated/graphql';

import Button from '../../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';

import { PatientsObject } from '../helpers';

type DeactivatePatientModalProps = {
  isSinglePatient?: boolean;
  singlePatient: PatientQuery['patient'];
  isModalOpen: boolean;
  patientsObject: PatientsObject | null;
  setClosed: () => void;
  setPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
  clearFilters?: () => void;
};

const DeactivatePatientModal: FC<DeactivatePatientModalProps> = ({
  isSinglePatient = false,
  singlePatient,
  isModalOpen,
  patientsObject,
  setClosed,
  setPatientsObject,
  clearFilters,
}) => {
  const { handleSubmit, reset: resetForm } = useForm({
    mode: 'onSubmit',
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [deactivatePatientsMutation] = useDeactivatePatientsMutation();

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (!singlePatient) return;

    try {
      setLoading(true);
      const response = await deactivatePatientsMutation({
        variables: {
          input: {
            programInstanceIds: [singlePatient.programInstanceId],
          },
        },
      });

      const deactivatedPatients =
        response?.data?.deactivatePatients.deactivatedPatients;

      if (
        patientsObject &&
        deactivatedPatients &&
        deactivatedPatients?.length > 0 &&
        !isSinglePatient
      ) {
        const deactivatedPatientIds = deactivatedPatients
          .map((patient) => patient.id)
          .filter(Boolean) as string[];

        const remainingPatients = omit(patientsObject, deactivatedPatientIds);

        setPatientsObject?.(remainingPatients);
        clearFilters?.();
      }

      closeAndResetDeactivatePatientModal();
      setLoading(false);
      if (isSinglePatient) {
        setTimeout(() => {
          navigate('/clients/active');
        }, 500);
      }
    } catch (err) {
      closeAndResetDeactivatePatientModal();
      setLoading(false);
      alert(err);
    }
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetDeactivatePatientModal = () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetDeactivatePatientModal}
      fetching={loading}
      width="small"
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-between px-10 pb-4',
        )}
      >
        <div className="flex flex-col items-center justify-start">
          <div className="mb-6 flex flex-col items-center justify-start text-center font-serif text-subtitle text-green-150">
            <span>Are you sure you want to archive</span>
            <span>
              {singlePatient.firstName} {singlePatient.lastName}?
            </span>
          </div>
          <div className="text-center text-body">
            <div className="text-green-125">
              {singlePatient.firstName} will continue to have access to the app
              and their program, but you’ll no longer be able to interact with
              them or see their data.
            </div>
          </div>
        </div>
        <form className="mt-10 mb-4 flex w-full flex-row items-center justify-center">
          <Button
            type="button"
            title="Cancel"
            theme="secondary"
            className="mr-6"
            onClick={closeAndResetDeactivatePatientModal}
          />
          <Button
            type="submit"
            title={`Archive ${singlePatient.firstName} ${singlePatient.lastName}`}
            theme="primary"
            onClick={handleSubmit(onSubmit, handleErrors)}
          />
        </form>
      </div>
    </Modal>
  );
};

export default DeactivatePatientModal;
