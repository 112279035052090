import { FC } from 'react';
import classNames from 'classnames';
import { MicrophoneIcon } from '@heroicons/react/outline';

export type RecordButtonSize = 'small' | 'medium';

const RecordButton: FC<{
  isRecording: boolean;
  recordingEnabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size?: RecordButtonSize;
}> = ({ isRecording, recordingEnabled, onClick, size }) => {
  return (
    <div
      className={classNames(
        'relative',
        size === 'medium' ? 'h-[48px] w-[48px]' : 'h-[36px] w-[36px]',
      )}
    >
      <button
        disabled={!recordingEnabled}
        onClick={(e) => {
          onClick(e);
          e.stopPropagation();
        }}
        className={classNames(
          'relative flex h-full w-full items-center justify-center rounded-full hover:transition-all',
          'disabled:bg-neutral-100 disabled:hover:bg-neutral-100',
          isRecording
            ? 'border-2 border-red-100 bg-white'
            : 'bg-neutral-125 hover:bg-neutral-150',
        )}
      >
        {isRecording ? (
          <>
            <div
              className={classNames(
                'rounded-sm bg-red-100',
                size === 'medium' ? 'h-3.5 w-3.5' : 'h-3 w-3',
              )}
            />
            <div
              className={classNames(
                'absolute z-[-1]  animate-ping rounded-full bg-red-100 opacity-75',
                size === 'medium' ? 'h-[32px] w-[32px]' : 'h-[24px] w-[24px]',
              )}
            />
          </>
        ) : (
          <MicrophoneIcon
            className={classNames(
              'text-white',
              size === 'medium' ? 'h-[20px] w-[20px]' : 'h-[18px] w-[18px]',
            )}
          />
        )}
      </button>
    </div>
  );
};

export default RecordButton;
