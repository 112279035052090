import {
  PatientsQuery,
  ProgramOnboardingStatus,
} from '../../../../generated/graphql';
import { getTimeStamp } from '../../../lib/date';
import {
  PATIENT_STATUSES,
  getPatientsObject,
  PatientsObject,
} from '../helpers';

export const getSelectionInterval = (
  patientIndex: number,
  lastSelectedPatientIndex: number,
  patientsList: PatientsQuery['patients'] | null,
  isShiftKeyPressed: boolean,
) => {
  let selectionInterval: PatientsQuery['patients'] = [];

  if (patientsList && isShiftKeyPressed && lastSelectedPatientIndex !== -1) {
    if (lastSelectedPatientIndex < patientIndex) {
      selectionInterval = patientsList.slice(
        lastSelectedPatientIndex,
        patientIndex + 1,
      );
    } else {
      selectionInterval = patientsList.slice(
        patientIndex,
        lastSelectedPatientIndex + 1,
      );
    }
  }
  return getPatientsObject(selectionInterval);
};

export const getNewSelectedPatients = (
  isChecked: boolean,
  selectionInterval: PatientsObject,
  patient: PatientsQuery['patients'][number],
  selectedPatientsObject: PatientsObject,
  isShiftKeyPressed: boolean,
  selectedPatientsList: PatientsQuery['patients'],
) => {
  const singlePatientObject: PatientsObject = {};
  if (patient.programInstanceId)
    singlePatientObject[patient?.programInstanceId] = patient;

  if (isChecked) {
    return {
      ...selectedPatientsObject,
      ...(isShiftKeyPressed ? selectionInterval : singlePatientObject), // check selectionInterval if shiftKeyDown
    };
  } else {
    const remainingSelectedPatients: PatientsObject = {};

    selectedPatientsList.forEach((selectedPatient) => {
      if (selectedPatient?.programInstanceId) {
        if (isShiftKeyPressed) {
          // uncheck selectionInterval if shiftKeyDown
          if (!selectionInterval[selectedPatient?.programInstanceId]) {
            remainingSelectedPatients[selectedPatient?.programInstanceId] =
              selectedPatient;
          }
        } else {
          if (
            patient?.programInstanceId &&
            selectedPatient?.programInstanceId !== patient?.programInstanceId
          ) {
            remainingSelectedPatients[selectedPatient?.programInstanceId] =
              selectedPatient;
          }
        }
      }
    });

    return remainingSelectedPatients;
  }
};

export const getOnboardingStatusText = (
  patient: PatientsQuery['patients'][number],
) => {
  const onboardingStatusDisplay = {
    title: PATIENT_STATUSES[patient.onboardingStatus].text,
    timestamp: '',
  };

  if (
    patient.onboardingStatus === ProgramOnboardingStatus.ProgramInviteSent ||
    patient.onboardingStatus === ProgramOnboardingStatus.OnboardingCompleted
  ) {
    return {
      ...onboardingStatusDisplay,
      timestamp: getTimeStamp(
        patient.onboardingStatus === ProgramOnboardingStatus.OnboardingCompleted
          ? patient.onboardingCompletedAt
          : patient.onboardingLastProgramInviteSentAt,
        false,
        true,
      ),
    };
  } else {
    return onboardingStatusDisplay;
  }
};
