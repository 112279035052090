import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import { ZxcvbnResult } from '@zxcvbn-ts/core';

import { checkPasswordStrength } from '../../lib/password-strength';
import { getPasswordErrorMessage } from '../../lib/form-validators';
import { useChangePasswordMutation } from '../../../generated/graphql';

import InputGroup from '../../components/InputGroup';
import Button from '../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import PasswordSuggestions from '../../components/PasswordSuggestions';

interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

const ChangePasswordModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
}> = ({ isModalOpen, setClosed }) => {
  const {
    handleSubmit,
    register,
    setError,
    reset: resetForm,
    formState: { errors },
  } = useForm<ChangePasswordData>({
    mode: 'onSubmit',
  });

  const [loading, setLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [passwordStrengthResult, setPasswordStrengthResult] = useState<
    ZxcvbnResult | undefined
  >();

  const [changePasswordMutation] = useChangePasswordMutation();

  const onSubmit: SubmitHandler<ChangePasswordData> = async (data) => {
    try {
      setLoading(true);
      const response = await changePasswordMutation({
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      });

      const changePasswordSuccess = response?.data?.changePassword as boolean;

      if (changePasswordSuccess) {
        setResetPasswordSuccess(true);
      }

      setLoading(false);
    } catch (err) {
      setError('oldPassword', {
        type: 'Incorrect password',
        message: 'Incorrect password',
      });
      setLoading(false);
    }
  };

  const closeAndResetChangePasswordModal = () => {
    setClosed();
    setTimeout(() => {
      setResetPasswordSuccess(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const handleErrors: SubmitErrorHandler<ChangePasswordData> = (errors) => {
    console.log('errors:', errors);
  };

  useEffect(() => {
    if (resetPasswordSuccess)
      setTimeout(() => {
        closeAndResetChangePasswordModal();
      }, 1500);
  }, [resetPasswordSuccess]);

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetChangePasswordModal}
      fetching={loading}
      width="small"
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-start px-8 pb-4',
        )}
      >
        <div className="flex w-3/4 flex-col items-center justify-start md:w-[450px]">
          <div
            className={classNames(
              'mb-2 text-center font-serif text-subtitle text-green-150',
              resetPasswordSuccess && 'pb-10',
            )}
          >
            {!resetPasswordSuccess
              ? 'Change your password'
              : "You've successfully changed your password"}
          </div>
        </div>
        {!resetPasswordSuccess && (
          <form className="flex w-3/4 flex-col items-center justify-center md:w-[500px]">
            <div className="w-full">
              <InputGroup
                label="Old password"
                type="password"
                errorLocation="right"
                errorMessage={errors.oldPassword?.message}
                containerClassName="my-6 w-full"
                required
                {...register('oldPassword', { required: true })}
              />
              <InputGroup
                label="New password"
                type="password"
                containerClassName="w-full"
                errorMessage={errors.newPassword?.message}
                passwordStrengthScore={passwordStrengthResult?.score}
                required
                {...register('newPassword', {
                  validate: (password) => {
                    const passwordStrengthResult =
                      checkPasswordStrength(password);
                    setPasswordStrengthResult(passwordStrengthResult);
                    return getPasswordErrorMessage(passwordStrengthResult);
                  },
                })}
              />

              <PasswordSuggestions strengthResult={passwordStrengthResult} />
            </div>

            <Button
              type="submit"
              title="Change password"
              className="my-10"
              IconComponent={ArrowRightLong}
              onClick={handleSubmit(onSubmit, handleErrors)}
            />
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
