import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment } from 'react';

import ChevronRight from '../svgs/ChevronRight';

interface SelectMenuProps<T> {
  onChange: (...event: unknown[]) => void;
  fieldValue: T;
  fieldOptions: T[];
  SelectOptionComponent?: FC<{
    value: T;
  }>;
  containerClassName?: string;
  buttonClassName?: string;
  label: string;
  smallLabel?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectMenu: FC<SelectMenuProps<any>> = ({
  onChange,
  fieldValue,
  fieldOptions,
  SelectOptionComponent,
  containerClassName = '',
  buttonClassName = ',',
  label,
  smallLabel = false,
  hideLabel = false,
  placeholder = 'Select',
  disabled = false,
}) => {
  return (
    <Listbox value={fieldValue} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className={containerClassName}>
          <Listbox.Label
            className={classNames(
              'mb-1 block font-bold text-green-125',
              {
                'text-small-action': smallLabel,
                'text-caption': !smallLabel,
              },
              hideLabel && 'hidden',
            )}
          >
            {label.toUpperCase()}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button
              className={classNames(
                'relative flex w-full cursor-default flex-row items-center justify-start',
                'max-h-14 rounded-md bg-neutral-50 py-4 pl-3 pr-10 text-left shadow-sm sm:text-caption',
                'focus:border-green-100 focus:outline-none focus:ring-2 focus:ring-green-100',
                buttonClassName,
              )}
            >
              {Boolean(fieldValue) ? (
                <div className="pl-2">
                  {SelectOptionComponent ? (
                    <SelectOptionComponent value={fieldValue} />
                  ) : (
                    <span className="text-extra-small">{fieldValue}</span>
                  )}
                </div>
              ) : (
                <span className="text-extra-small">{placeholder}</span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronRight className="rotate-90" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="ease-in duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <Listbox.Options className="text-base absolute z-10 mt-1 max-h-[300px] w-full list-none overflow-auto overflow-y-scroll rounded-md bg-white py-1 pl-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-caption">
                {fieldOptions?.map((fieldOption, index) => (
                  <Listbox.Option
                    key={`${index}_selectMenu`}
                    className={({ active }) =>
                      classNames(
                        'relative flex max-h-12 cursor-default flex-row items-center py-4 pl-5 pr-4',
                        active && 'bg-neutral-25',
                      )
                    }
                    value={fieldOption}
                  >
                    {SelectOptionComponent ? (
                      <SelectOptionComponent value={fieldOption} />
                    ) : (
                      <>{fieldOption}</>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu;
