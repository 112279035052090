import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useAuth } from '../../contexts/AuthContext';
import { ProviderUserMemberDataFragment } from '../../generated/graphql';
import { emailRegisterOptions } from '../lib/register-options';
import CloseX from '../svgs/CloseX';
import { AddMembersFormData, MemberField } from '../types/members';
import IconButton from './IconButton';
import InputGroup from './InputGroup';
import ToastAlert from './ToastAlert';

/* TODO: create a generic InputRow component */
type MemberInputRowProps = {
  fieldIndex: number;
  formFields: MemberField[];
  formContext: UseFormReturn<AddMembersFormData, object>;
  fieldArrayContext: UseFieldArrayReturn<AddMembersFormData, 'members', 'id'>;
  existingMembers: ProviderUserMemberDataFragment[];
};

export default function MemberInputRow({
  formFields,
  fieldIndex,
  formContext,
  fieldArrayContext,
  existingMembers,
}: MemberInputRowProps) {
  const { authedProviderUser } = useAuth();

  const {
    register,
    setFocus,
    formState: { errors: formErrors },
  } = formContext;
  const { insert, remove } = fieldArrayContext;

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const newUsedStaffSeats = existingMembers.length + formFields.length;
      const staffSeats = authedProviderUser?.provider.staffSeats;
      if (staffSeats != null && newUsedStaffSeats >= staffSeats) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="error"
            message={`Can't add more than ${staffSeats} members with your current plan.`}
          />
        ));
        return;
      }

      insert(fieldIndex + 1, { email: '' });
    } else if (
      event.key === 'Backspace' &&
      fieldIndex !== 0 &&
      !formFields[fieldIndex].email
    ) {
      remove(fieldIndex);
      // Delay setting focus so the current keypress does not delete a character in the newly focused field.
      setTimeout(() => {
        setFocus(`members.${fieldIndex - 1}.email`);
      }, 50);
    }
  };

  const validateEmail = (email: string) => {
    const isDuplicate = formFields.some(
      (field, index) => index < fieldIndex && field.email === email,
    );

    if (isDuplicate) {
      return 'Email has already been entered';
    }

    const isEmailInMembersList = existingMembers.some(
      (member) => member.email === email,
    );

    if (isEmailInMembersList) {
      return 'Already on your team';
    }

    return true;
  };

  return (
    <div className="relative mb-4 flex h-full w-full flex-row justify-between">
      <InputGroup
        label="Email"
        placeholder=""
        containerClassName="w-full"
        inputSize="small"
        labelHidden
        required
        errorMessage={formErrors.members?.[fieldIndex]?.email?.message}
        errorLocation="right"
        onKeyDown={onInputKeyDown}
        {...register(`members.${fieldIndex}.email`, {
          ...emailRegisterOptions,
          validate: validateEmail,
        })}
      />
      <IconButton
        IconComponent={CloseX}
        aria-label="Remove row"
        iconClassName="h-2.5 w-2.5"
        className="inset-y-0 -right-10 ml-1 p-3 md:absolute md:ml-0"
        disabled={formFields.length <= 1}
        onClick={() => remove(fieldIndex)}
      />
    </div>
  );
}
