import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { EditorState, LexicalEditor } from 'lexical';
import { useEffect } from 'react';

import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';

import './index.css';
import EditorTheme from './theme';
import classNames from 'classnames';

type EditorProps = {
  innerRef?: React.MutableRefObject<LexicalEditor | null>;
  readOnly?: boolean;
  initialContentMarkdown?: string;
  placeholder?: string;
  elKey?: string;
  className?: string;
  allowLinks?: boolean;
  contentClassName?: string;
  containerClassName?: string;
  onError?: (error: Error) => never | void;
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
};

// Using a helper to access the editor which requires the LexicalComposerContext provided by the LexicalComposer
// So `editor` can only be accessed from inside the LexicalComposer element
function EditorHelper({ innerRef, readOnly = false }: EditorProps) {
  const [editor] = useLexicalComposerContext();

  // Set the reference when the editor is mounted
  useEffect(() => {
    if (innerRef) innerRef.current = editor;
  }, [editor]);

  // After an editor is created, the readOnly mode can only be changed imperatively
  // See https://lexical.dev/docs/concepts/read-only
  useEffect(() => {
    editor.setEditable(!readOnly);
  }, [readOnly]);

  return null;
}

export default function Editor({
  initialContentMarkdown,
  placeholder = '',
  className = '',
  contentClassName = '',
  containerClassName = '',
  elKey = 'lexical',
  onChange = () => null,
  allowLinks = true,
  ...props
}: EditorProps) {
  const onError = props.onError
    ? props.onError
    : (error: Error) => {
        throw error;
      };

  const initialConfig = {
    // The editor theme
    namespace: 'lexical-editor',
    theme: EditorTheme,
    // Handling of errors during update
    onError,
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
    editorState: () =>
      initialContentMarkdown
        ? $convertFromMarkdownString(initialContentMarkdown, TRANSFORMERS)
        : null,
  };

  return (
    <div key={elKey} className={containerClassName}>
      <LexicalComposer initialConfig={initialConfig}>
        <div className={classNames('editor editor-container', className)}>
          {!props.readOnly && (
            <ToolbarPlugin
              showJustificationButtons={false}
              allowLinks={allowLinks}
            />
          )}
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className={classNames('editor-input', contentClassName)}
                />
              }
              placeholder={
                <div className="editor-placeholder">{placeholder}</div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <ClearEditorPlugin />
            <OnChangePlugin onChange={onChange} ignoreSelectionChange={true} />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <EditorHelper {...props} />
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
}
