import React, { FC, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useStripeCheckoutSuccessMutation } from '../../../generated/graphql';
import { useAuth } from '../../../contexts/AuthContext';
import Loading from '../../pages/Loading';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import GtmHelpers from '../../../lib/gtm';

const BillingSuccess: FC = () => {
  const [stripeCheckoutSuccess] = useStripeCheckoutSuccessMutation();

  const navigate = useNavigate();
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [searchParams] = useSearchParams();

  const redirect = () => {
    navigate('/settings/billing');
  };

  const checkout = async (checkoutSessionId: string) => {
    try {
      await stripeCheckoutSuccess({
        variables: {
          checkoutSessionId,
        },
      });
      try {
        GtmHelpers.trackEvent(
          ProviderAnalyticsEvent.ProviderSubscribed,
          authedProviderUser?.id ?? '',
        );
      } catch (err) {
        console.error(err);
      }
      await refreshAuthedProviderUser();
      redirect();
    } catch (err) {
      trackProviderEvent(ProviderAnalyticsEvent.ProviderSubscribeFailed, {
        reason: 'Server error',
      });
      alert(
        'Failed to connect your card for billing. Please refresh to try again or contact support@homecoming.health',
      );
      throw err;
    }
  };

  useEffect(() => {
    // Can't checkout until the authedProviderUser is loaded
    if (!authedProviderUser) {
      return;
    }

    const checkoutSessionId = searchParams.get('session_id');

    // If the user is already subscribed, or the session_id isn't present, we shouldn't be here - redirect
    if (authedProviderUser.isProviderSubscribed || !checkoutSessionId) {
      redirect();
      return;
    }

    // All clear, checkout and link the subscription to the user
    checkout(checkoutSessionId);
  }, [authedProviderUser]);

  return <Loading />;
};

export default BillingSuccess;
