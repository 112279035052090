import { Combobox } from '@headlessui/react';
import { StripeProduct } from '../../../generated/graphql';
import { formatCurrency } from '../../lib/currency';
import classNames from 'classnames';

export type SelectedProduct = Partial<StripeProduct>;

type ProductSearchboxProps = {
  name: string;
  setName: (name: string) => void;
  searchResults: StripeProduct[];
  selectedProduct: SelectedProduct | null;
  setSelectedProduct: (product: SelectedProduct | null) => void;
  className?: string;
};

const ProductSearchbox: React.FC<ProductSearchboxProps> = ({
  name,
  setName,
  searchResults,
  selectedProduct,
  setSelectedProduct,
  className,
}) => {
  return (
    <Combobox
      as="div"
      className="w-full"
      value={selectedProduct}
      onChange={setSelectedProduct}
      nullable
    >
      <div className="relative w-full">
        <Combobox.Input
          className={classNames(
            'w-full border-none p-0 py-1 text-caption focus:outline-none focus:ring-0',
            className,
          )}
          onChange={(event) => setName(event.target.value)}
          placeholder="Name of service"
          displayValue={(product: StripeProduct) => product?.name}
        />

        {name && searchResults.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full divide-y divide-neutral-75 overflow-auto rounded-lg bg-white py-1  text-caption shadow-lg focus:outline-none focus:ring-0">
            {searchResults.map((product) => (
              <Combobox.Option
                key={product.stripePriceId}
                value={product}
                className="relative cursor-pointer select-none py-3 px-5 hover:bg-neutral-25"
              >
                <div className="flex">
                  <span className="truncate text-green-150">
                    {product.name}
                  </span>
                  {product.price >= 0 && (
                    <span className="ml-2 truncate text-neutral-110">
                      ({formatCurrency(product.price)})
                    </span>
                  )}
                </div>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default ProductSearchbox;
