import { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import ModalDialog from '../../../ModalDialog';
import {
  Exact,
  MeProviderQuery,
  PatientDataFragment,
  PatientQuery,
  ProgramAssessmentSignatureUserInput,
  SignatureUser,
} from '../../../../../generated/graphql';

import {
  FollowUpForm,
  SavedActivityInputPreview,
} from '../../../../lib/followUp';
import { getColorForSignatureUserType } from '../../../../lib/multiSignature';
import ContentPreview from '../../../ContentPreview';
import Button from '../../../Button';
import { MODAL_TRANSITION_DURATION } from '../../../Modal';
import { UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import SelectMenu from '../../../SelectMenu';
import Alert from '../../../Alert';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../../../lib/auth';
import { PlusSmIcon } from '@heroicons/react/outline';
import AssignProviderUsersModal from '../../ClientAssignmentModals/AssignProviderUsersModal';
import { ApolloQueryResult } from '@apollo/client';
import {
  checkNotEnoughAssignedProviderUsers,
  initializeSelectedAssignedProviderUsers,
} from './helpers';

interface CustomSelectOptionProps {
  value: { value: string; label: string };
}

const CustomSelectOption: FC<CustomSelectOptionProps> = ({ value }) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-row items-center justify-start rounded-md',
      )}
    >
      {value.label}
    </div>
  );
};

type SelectedProgramAssessmentSignatureUser =
  ProgramAssessmentSignatureUserInput & {
    name: string;
  };

export type SavedActivityInputPreviewWithActivityIndex =
  SavedActivityInputPreview & {
    activityIndex: number;
  };

type ReviewSignerDetailsModalProps = {
  setClosed: () => void;
  isOpen: boolean;
  sendingProviderUser: MeProviderQuery['meProvider'] | null;
  multiSignatureActivities: SavedActivityInputPreviewWithActivityIndex[];
  patient?: PatientDataFragment;
  refetchPatient?: (
    variables?: Partial<Exact<{ programId: string }>>,
  ) => Promise<ApolloQueryResult<PatientQuery>>;
  update: UseFieldArrayUpdate<FollowUpForm, 'activities'>;
  remove: UseFieldArrayRemove;
};

const ReviewSignerDetailsModal: FC<ReviewSignerDetailsModalProps> = ({
  isOpen,
  setClosed,
  multiSignatureActivities,
  sendingProviderUser,
  patient,
  refetchPatient,
  update,
  remove,
}) => {
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [isAssignProviderUsersModalOpen, setIsAssignProviderUsersModalOpen] =
    useState(false);

  const currentActivity = multiSignatureActivities?.[currentActivityIndex];
  const assessmentSignatureUsers =
    currentActivity?.multiSignatureAssessmentConfig?.assessmentSignatureUsers;

  const [selectedAssignedProviderUsers, setSelectedAssignedProviderUsers] =
    useState<{ [key: string]: string }>({});

  const canAssignPractitioners =
    isProviderUserOwnerOrAdminAuthorized(sendingProviderUser);

  const resetAndSetClosed = () => {
    setTimeout(() => {
      setCurrentActivityIndex(0);
    }, MODAL_TRANSITION_DURATION);
    setClosed();
  };

  useEffect(() => {
    if (isOpen)
      initializeSelectedAssignedProviderUsers(
        assessmentSignatureUsers,
        sendingProviderUser,
        patient,
        setSelectedAssignedProviderUsers,
      );
  }, [isOpen, currentActivityIndex]);

  const handleAssignedProviderUserChange = (
    assessmentSignatureUserId: string,
    providerUserId: string,
  ) => {
    setSelectedAssignedProviderUsers((prev) => ({
      ...prev,
      [assessmentSignatureUserId]: providerUserId,
    }));
  };

  const programAssessmentSignatureUsers = assessmentSignatureUsers?.map(
    (assessmentSignatureUser) => {
      const baseUser: SelectedProgramAssessmentSignatureUser = {
        assessmentSignatureUserId: assessmentSignatureUser.id,
        name: '',
      };

      switch (assessmentSignatureUser.signatureUserType) {
        case SignatureUser.PatientUser:
          return {
            ...baseUser,
            name: patient?.name,
          };
        case SignatureUser.SendingProviderUser:
          return {
            ...baseUser,
            providerUserId: sendingProviderUser?.id,
            name: sendingProviderUser?.name,
          };
        case SignatureUser.AssignedProviderUser:
          const assignedProviderUserId =
            selectedAssignedProviderUsers[assessmentSignatureUser.id];

          const assignedProviderUser = patient?.assignedProviderUsers.find(
            (user) => user.id === assignedProviderUserId,
          );

          return {
            ...baseUser,
            providerUserId: assignedProviderUser?.id,
            name: assignedProviderUser?.name,
          };
        default:
          return baseUser;
      }
    },
  );

  const handleConfirm = () => {
    const programAssessmentSignatureUsers: ProgramAssessmentSignatureUserInput[] =
      assessmentSignatureUsers?.map(
        ({ id: assessmentSignatureUserId, signatureUserType }) => ({
          assessmentSignatureUserId,
          ...(signatureUserType !== SignatureUser.PatientUser && {
            providerUserId:
              selectedAssignedProviderUsers[assessmentSignatureUserId],
          }),
        }),
      );

    const updatedMultiSignatureActivity = {
      ...currentActivity,
      sendFollowUpActivityInputRaw: {
        ...currentActivity.sendFollowUpActivityInputRaw,
        programAssessmentSignatureUsers,
      },
    };

    update(currentActivity.activityIndex, updatedMultiSignatureActivity);
    setSelectedAssignedProviderUsers({});
    if (currentActivityIndex < multiSignatureActivities?.length - 1) {
      setCurrentActivityIndex(currentActivityIndex + 1);
    } else {
      resetAndSetClosed();
    }
  };

  const activityPreviewCardProps = currentActivity?.activityPreviewCardProps;

  const notEnoughAssignedProviders = checkNotEnoughAssignedProviderUsers(
    assessmentSignatureUsers,
    patient,
    sendingProviderUser,
  );

  return (
    <>
      {canAssignPractitioners && (
        <AssignProviderUsersModal
          isOpen={isAssignProviderUsersModalOpen}
          setClosed={() => {
            initializeSelectedAssignedProviderUsers(
              assessmentSignatureUsers,
              sendingProviderUser,
              patient,
              setSelectedAssignedProviderUsers,
            );
            setIsAssignProviderUsersModalOpen(false);
          }}
          selectedPatient={patient}
          refetchPatient={refetchPatient}
        />
      )}
      <ModalDialog
        title="Review signers"
        width="small"
        isBlocking={isAssignProviderUsersModalOpen}
        overflow="auto"
        isOpen={isOpen}
        setClosed={resetAndSetClosed}
        bodyClassName="min-h-[350px] flex flex-col justify-start"
        rightFooter={
          <div className="flex w-full flex-row items-center justify-end">
            <Button
              className="mr-3"
              title="Cancel"
              size="small"
              theme="secondary"
              onClick={resetAndSetClosed}
            />
            {notEnoughAssignedProviders && canAssignPractitioners && (
              <Button
                className="mr-3"
                title={`Assign practitioners to ${patient?.firstName}`}
                size="small"
                iconPosition="left"
                onClick={() => setIsAssignProviderUsersModalOpen(true)}
                IconComponent={PlusSmIcon}
              />
            )}
            {!notEnoughAssignedProviders && (
              <Button
                onClick={handleConfirm}
                title="Confirm signers"
                size="small"
              />
            )}
          </div>
        }
      >
        <div className="mb-5 text-caption">
          Review the list of signers and the signing order. These settings can
          be changed in the form stored in your Library.
        </div>

        {activityPreviewCardProps && (
          <ContentPreview
            className="mb-4 rounded-md border border-neutral-75 px-4 py-3 shadow-none"
            contentType={activityPreviewCardProps.contentType}
            title={activityPreviewCardProps.title}
            description={activityPreviewCardProps.description}
            previewThumbnailImageUrl={
              activityPreviewCardProps.previewThumbnailImageUrl
            }
            disableClick
            toggleActivityOrResourceLabel={false}
          />
        )}

        <div className="flex w-full flex-col items-center justify-start">
          {assessmentSignatureUsers?.map((assessmentSignatureUser, index) => {
            const {
              orderIndex,
              signatureUserType,
              id: assessmentSignatureUserId,
            } = assessmentSignatureUser;

            const { backgroundColor, borderColor } =
              getColorForSignatureUserType(signatureUserType);

            const isAssignedProviderUser =
              signatureUserType === SignatureUser.AssignedProviderUser;

            const selectedProviderUser = patient?.assignedProviderUsers.find(
              (user) =>
                user.id ===
                selectedAssignedProviderUsers[assessmentSignatureUserId],
            );

            const fieldOptions = patient?.assignedProviderUsers
              .filter(
                (user) =>
                  !Object.values(selectedAssignedProviderUsers).includes(
                    user.id,
                  ) ||
                  user.id ===
                    selectedAssignedProviderUsers[assessmentSignatureUserId],
              )
              .map((user) => ({
                value: user.id,
                label: user.name,
              }));

            const assignedNameField = {
              value:
                programAssessmentSignatureUsers[index]
                  ?.assessmentSignatureUserId,
              label: programAssessmentSignatureUsers[index]?.name,
            };
            const fieldValue = isAssignedProviderUser
              ? selectedProviderUser
                ? {
                    value: selectedProviderUser?.id,
                    label: selectedProviderUser?.name,
                  }
                : ''
              : assignedNameField;

            return (
              <div
                key={assessmentSignatureUserId}
                className="mb-3 flex w-full items-center justify-between rounded-xl border border-neutral-50"
              >
                <div
                  className={classNames(
                    'flex w-full flex-grow items-center rounded-l-xl border-l-[14px] p-4',
                    borderColor,
                  )}
                >
                  <div className="flex h-10 w-full items-center text-body text-neutral-125">
                    <div
                      className={classNames(
                        'mr-4 flex h-full w-10 flex-shrink-0 items-center justify-center rounded-md',
                        backgroundColor,
                      )}
                    >
                      {orderIndex + 1}
                    </div>
                    <SelectMenu
                      label=""
                      hideLabel
                      disabled={!isAssignedProviderUser}
                      fieldValue={fieldValue}
                      onChange={(value: { value: string; label: string }) =>
                        handleAssignedProviderUserChange(
                          assessmentSignatureUserId,
                          value.value,
                        )
                      }
                      fieldOptions={fieldOptions}
                      placeholder="Choose a team member"
                      SelectOptionComponent={CustomSelectOption}
                      buttonClassName={classNames(
                        'flex h-10 w-full items-center justify-start rounded-md',
                        backgroundColor,
                      )}
                      containerClassName={classNames(
                        'flex h-10 w-full items-center justify-start rounded-md',
                        backgroundColor,
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {notEnoughAssignedProviders && (
            <Alert
              level="warning"
              className="mt-4"
              message={`To send this form, you'll need to assign a team member to ${patient?.firstName} since their signature is required.`}
            />
          )}
        </div>
      </ModalDialog>
    </>
  );
};

export default ReviewSignerDetailsModal;
