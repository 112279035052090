import { useCallback, useEffect, useRef, useState } from 'react';

import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { LexicalEditor } from 'lexical';
import { RefreshIcon } from '@heroicons/react/outline';
import { Transition as HistoryTransition } from 'history';

import {
  ProgramNoteDataFragment,
  UpdateProgramNoteInput,
  useDeleteProgramNoteMutation,
  useUpdateProgramNoteMutation,
} from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';
import { defaultTransitionProps } from '../../../lib/animation';
import { getRelativeTimestamp } from '../../../lib/time';

import ToastAlert from '../../../components/ToastAlert';
import padlock from '../../../../assets/svgs/padlock.svg';

import { usePatientProfileContext } from '..';
import Editor from '../../../components/Editor';
import { useForm } from 'react-hook-form';
import InputGroup from '../../../components/InputGroup';
import Trash from '../../../svgs/Trash';
import debounce from 'lodash.debounce';
import useBlocker from '../../../hooks/useBlocker';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import IconButton from '../../../components/IconButton';
import Avatar from '../../../components/Avatar';
import Pin from '../../../svgs/Pin';
import classNames from 'classnames';
interface NoteFormData {
  title: string;
}
const SAVE_NOTE_DEBOUNCE = 1000;

const NoteDetails = () => {
  const params = useParams();
  const programNoteId = params.programNoteId!;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isNewNote = searchParams.get('new') === 'true';

  const {
    refetchProgramNotes,
    refetchPinnedNotes,
    selectedProgramNote,
    isSearching,
    isUnsavedChangesModalOpen,
    setIsUnsavedChangesModalOpen,
    setNavTransition,
  } = usePatientProfileContext();

  const [isSavingNote, setIsSavingNote] = useState(false);
  const [isSaveNotePending, setIsSaveNotePending] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);

  const blocker = useCallback((navTransition: HistoryTransition) => {
    if (!isUnsavedChangesModalOpen) {
      setNavTransition(navTransition);
      setIsUnsavedChangesModalOpen(true);
    }
  }, []);

  useBlocker(blocker, isSavingNote || isSaveNotePending);

  const editorRef = useRef<LexicalEditor | null>(null);

  const [deleteProgramNote, { loading: isDeleteProgramNoteLoading }] =
    useDeleteProgramNoteMutation();

  const [updateProgramNote, { loading: isUpdateProgramNoteLoading }] =
    useUpdateProgramNoteMutation();

  const {
    register,
    setValue,
    formState: { errors: validationErrors },
    setFocus,
  } = useForm<NoteFormData>({
    mode: 'onChange',
    defaultValues: { title: '' },
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus('title');
    }, 50);
  }, [programNoteId]);

  const deleteNote = async () => {
    try {
      await deleteProgramNote({
        variables: {
          programNoteId,
        },
      });

      navigate('../');
      // Force reload the page to refetch the data and use the parent select note logic
      navigate(0);
    } catch (error) {
      console.error(error);

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to delete the note."
          level="error"
        />
      ));
    }
  };

  const saveNote = async ({
    programNoteId,
    title,
    note,
  }: {
    programNoteId: string;
    title?: string;
    note?: string;
  }) => {
    setIsSavingNote(true);
    setIsSaveNotePending(false);
    try {
      const updateProgramNoteInput: UpdateProgramNoteInput = {
        programNoteId: programNoteId,
        ...(title && { title }),
        ...(note && { note }),
      };

      await updateProgramNote({
        variables: {
          input: updateProgramNoteInput,
        },
      });

      refetchProgramNotes?.();
      refetchPinnedNotes?.();
    } catch (error) {
      console.error(error);

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to update the note."
          level="error"
        />
      ));
    } finally {
      setIsSavingNote(false);
    }
  };

  const debouncedSaveNote = useCallback(
    debounce(saveNote, SAVE_NOTE_DEBOUNCE, {
      leading: false,
      trailing: true,
    }),
    [],
  );

  const status =
    isSavingNote || isSaveNotePending ? (
      <>
        <RefreshIcon className="mr-1 h-4 w-4" />
        Saving...
      </>
    ) : (
      <>
        Last saved {getRelativeTimestamp(selectedProgramNote?.updatedAt)}
        <img src={padlock} alt="private note" className="ml-1 h-4 w-4" />
      </>
    );

  // When a different note is selected
  useEffect(() => {
    if (!selectedProgramNote?.id) return;
    if (selectedProgramNote?.title) {
      setValue('title', selectedProgramNote.title);
    }
  }, [selectedProgramNote?.id]);

  const handlePinNote = async (programNote: ProgramNoteDataFragment) => {
    try {
      setIsSavingNote(true);
      setIsSaveNotePending(false);

      await updateProgramNote({
        variables: {
          input: {
            programNoteId: programNote.id,
            isPinnedNote: !programNote.isPinnedNote,
          },
        },
      });

      refetchProgramNotes?.();
      refetchPinnedNotes?.();

      const pinnedNoteMessage = `Your note '${programNote.title}' is pinned and will now appear at the top.`;
      const unpinnedNoteMessage = `Your note '${programNote.title}' is unpinned.`;

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={
            !programNote.isPinnedNote ? pinnedNoteMessage : unpinnedNoteMessage
          }
        />
      ));
    } catch (error) {
      console.error(error);

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to update the note."
          level="error"
        />
      ));
    } finally {
      setIsSavingNote(false);
    }
  };

  return (
    <>
      <Transition
        show={!!selectedProgramNote}
        {...defaultTransitionProps}
        className="h-full w-full"
      >
        <div className="flex h-full w-full flex-col pl-5">
          <div className="h-full w-full rounded-t-xl border-l border-r border-t border-neutral-75 px-8 pt-5">
            <div className="relative mb-3 flex w-full items-center justify-center">
              {selectedProgramNote && (
                <div className="absolute left-0 flex flex-row">
                  <Avatar
                    size="small"
                    imageUrl={
                      selectedProgramNote?.createdByProviderUser
                        .profileImageMedia?.url
                    }
                    name={selectedProgramNote?.createdByProviderUser.name}
                  />
                  <div className="ml-2 flex flex-col justify-center text-caption font-medium text-neutral-125">
                    {selectedProgramNote?.createdByProviderUser.name}
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center justify-center p-1 text-center text-small-caption text-neutral-125">
                {status}
              </div>
              <div className="absolute right-0">
                <div className="flex flex-row items-center">
                  <IconButton
                    disabled={isDeleteProgramNoteLoading}
                    onClick={() => setIsDeleteNoteModalOpen(true)}
                    aria-label="Delete note"
                    IconComponent={Trash}
                    iconClassName="h-4 w-4 text-neutral-125"
                  />
                  <IconButton
                    disabled={isUpdateProgramNoteLoading}
                    aria-label={
                      !selectedProgramNote?.isPinnedNote
                        ? 'Pin note'
                        : 'Unpin note'
                    }
                    IconComponent={Pin}
                    onClick={(e) => {
                      setIsSaveNotePending(true);
                      handlePinNote(selectedProgramNote);
                      e.stopPropagation();
                    }}
                    iconClassName="h-4 w-4"
                    className={classNames(
                      selectedProgramNote?.isPinnedNote
                        ? 'text-green-100 hover:text-neutral-110'
                        : 'text-neutral-110 hover:text-green-100',
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mb-6 flex w-full flex-col">
              <InputGroup
                autoFocus={isNewNote}
                required
                labelHidden
                backgroundHidden
                label="Note title"
                placeholder="Untitled note"
                className="rounded-none border-0 border-b-2 px-0 pb-1 font-serif text-big-label text-neutral-150"
                errorMessage={validationErrors.title?.message}
                {...register('title', {
                  required: 'Title is required',
                  onChange: (event) => {
                    if (selectedProgramNote?.id) {
                      debouncedSaveNote({
                        programNoteId: selectedProgramNote.id,
                        title: event.target.value,
                      });
                    }
                  },
                })}
              />
            </div>

            <Editor
              initialContentMarkdown={selectedProgramNote?.note}
              elKey={selectedProgramNote?.id}
              placeholder="Start your note..."
              className="text-neutral-150"
              contentClassName="overflow-y-auto max-h-[calc(100vh-420px)] pr-2"
              innerRef={editorRef}
              onChange={(editorState) => {
                if (isSearching) return;
                editorState.read(() => {
                  const updatedNote = $convertToMarkdownString(TRANSFORMERS);
                  if (
                    selectedProgramNote?.id &&
                    selectedProgramNote?.note !== updatedNote
                  ) {
                    setIsSaveNotePending(true);
                    debouncedSaveNote({
                      programNoteId: selectedProgramNote.id,
                      note: updatedNote,
                    });
                  }
                });
              }}
              onError={(error: Error) => {
                console.error(error);
              }}
            />
          </div>
        </div>
      </Transition>

      {!selectedProgramNote && <Spinner className="mx-auto mt-8" />}

      <ConfirmDeleteModal
        title="Would you like to delete this note?"
        actionButtonTitle="Delete note"
        isOpen={isDeleteNoteModalOpen}
        fetching={isDeleteProgramNoteLoading}
        setClosed={() => setIsDeleteNoteModalOpen(false)}
        performDelete={deleteNote}
      >
        You can't undo this action.
      </ConfirmDeleteModal>
    </>
  );
};

export default NoteDetails;
