import { FC } from 'react';
import { FeedType, PatientQuery } from '../../../generated/graphql';
import {
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import FeedPanel from './FeedPanel';

const FeedPanels: FC<{
  patient: PatientQuery['patient'];
  setIsFeedSlideoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFeed: React.Dispatch<React.SetStateAction<FeedType | null>>;
}> = ({ patient, setIsFeedSlideoverOpen, setSelectedFeed }) => {
  return (
    <div className="flex gap-7 sm:flex-col sm:items-center xl:mb-10 xl:flex-row xl:items-start xl:justify-start">
      <FeedPanel
        patient={patient}
        feedType={FeedType.CheckIns}
        seeMoreOnClick={() => {
          setSelectedFeed(FeedType.CheckIns);
          setIsFeedSlideoverOpen(true);
          trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
            originPage: 'Patient Profile',
            component: 'Feed Panel | Check-ins',
          });
        }}
      />
      <FeedPanel
        patient={patient}
        feedType={FeedType.Activities}
        seeMoreOnClick={() => {
          setSelectedFeed(FeedType.Activities);
          setIsFeedSlideoverOpen(true);
          trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
            originPage: 'Patient Profile',
            component: 'Feed Panel | Activities',
          });
        }}
      />
      <div />
    </div>
  );
};

export default FeedPanels;
