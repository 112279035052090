import React, { useEffect, useState } from 'react';
import { usePublicStorefrontQuery } from '../../../generated/graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE_URL } from '../../lib/constants';
import UnauthedHeader from '../../components/Headers/UnauthedHeader';
import PageContainer from '../../components/Containers/PageContainer';
import PractitionerBanner from '../../components/Storefront/PractitionerBanner';
import PractitionerInfoContainer from '../../components/Storefront/PractitionerInfoContainer';
import PractitionerInfoLeftColumn from '../../components/Storefront/PractitionerInfoLeftColumn';
import PractitionerInfoRightColumn from '../../components/Storefront/PractitionerInfoRightColumn';
import Loading from '../Loading';
import StorefrontIntakeCompleteModal from '../../components/Modals/StorefrontIntakeCompleteModal';

const PublicStorefront: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const slug = params.slug;
  const fromIntakeSuccess = (location.state as { fromIntakeSuccess?: boolean })
    ?.fromIntakeSuccess;

  const { data: storefrontData, error: storefrontError } =
    usePublicStorefrontQuery({
      variables: {
        slug,
      },
      skip: !slug,
    });

  const [
    isStorefrontIntakeCompleteModalOpen,
    setIsStorefrontIntakeCompleteModalOpen,
  ] = useState(false);

  if (!slug || storefrontError) {
    navigate(HOMEPAGE_URL, { replace: true });
  }

  const storefront = storefrontData?.publicStorefront;

  useEffect(() => {
    if (storefront && fromIntakeSuccess) {
      setIsStorefrontIntakeCompleteModalOpen(true);
    }
  }, [storefront, fromIntakeSuccess]);

  return (
    <div>
      <UnauthedHeader />
      {storefront ? (
        <>
          <PageContainer containerClassName="pt-24 pb-20 px-20 max-w-[1280px] mx-auto">
            <PractitionerBanner
              avatarImageUrl={storefront.providerProfileImageUrl}
              avatarName={storefront.providerName}
              bannerImageUrl={storefront.bannerImageUrl}
            />
            <PractitionerInfoContainer>
              <PractitionerInfoLeftColumn
                providerName={storefront.providerName}
                roleTitle={storefront.providerRoleTitle}
                providerStorefrontSlug={slug}
              />
              <PractitionerInfoRightColumn
                tagline={storefront.tagline}
                about={storefront.about}
                services={storefront.services}
              />
            </PractitionerInfoContainer>
          </PageContainer>
          <StorefrontIntakeCompleteModal
            isModalOpen={isStorefrontIntakeCompleteModalOpen}
            setClosed={() => setIsStorefrontIntakeCompleteModalOpen(false)}
            avatarImageUrl={storefront.providerProfileImageUrl}
            avatarName={storefront.providerName}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PublicStorefront;
