import ApiClient from './apiClient';

export const getAssessmentsDataExport = async (): Promise<void> => {
  const response = await ApiClient.post<Blob>('/assessments-data-export', {
    responseType: 'blob',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response);
  link.download = 'assessments-data-export.csv';
  link.click();
};
