import { FC } from 'react';
import classNames from 'classnames';
import { CATEGORY_TO_DESIGN_MAP } from '../pages/ProgramOverview/AssessmentTab/AssessmentCard';
import Clock from '../svgs/Clock';
import { AssessmentCategory } from '../../generated/graphql';

interface AssessmentMetaDataProps {
  className?: string;
  category: AssessmentCategory;
  estimatedLengthInMinutes?: number | null;
}

export const AssessmentMetaData: FC<AssessmentMetaDataProps> = ({
  className = '',
  category,
  estimatedLengthInMinutes,
}) => {
  return (
    <div className={classNames('flex items-baseline', className)}>
      {Boolean(CATEGORY_TO_DESIGN_MAP[category]) && (
        <div
          className={classNames(
            'mr-3 rounded-full py-1 px-3 font-sans text-small-action font-bold',
            CATEGORY_TO_DESIGN_MAP[category].backgroundColor,
            CATEGORY_TO_DESIGN_MAP[category].textColor,
          )}
        >
          {category}
        </div>
      )}
      {estimatedLengthInMinutes !== undefined && (
        <div>
          <Clock className="mr-1 inline h-4 w-4 text-green-100" />
          <span className="font-sans text-small-action font-bold text-green-125">
            {estimatedLengthInMinutes} min
          </span>
        </div>
      )}
    </div>
  );
};
