import { ZxcvbnResult } from '@zxcvbn-ts/core';

import { minPasswordStrengthScore } from '../lib/password-strength';
import Alert from './Alert';

type PasswordSuggestionsProps = {
  strengthResult?: ZxcvbnResult;
};

export default function PasswordSuggestions({
  strengthResult,
}: PasswordSuggestionsProps) {
  const suggestions = strengthResult?.feedback.suggestions;
  const score = strengthResult?.score;

  if (!suggestions || suggestions.length === 0) {
    return null;
  }

  return (
    <Alert
      message="Password suggestions"
      level={score && score >= minPasswordStrengthScore ? 'warning' : 'error'}
      className="mt-6"
    >
      <ul className="mt-2 list-disc">
        {suggestions.map((suggestion, index) => (
          <li key={index}>{suggestion}</li>
        ))}
      </ul>
    </Alert>
  );
}
