import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import toast from 'react-hot-toast';

import {
  ProgramTemplateTaskGroup,
  Task,
} from '../../../../../generated/graphql';

import { MoveTaskAndSaveCallback } from '../../../../types/tasks';
import {
  DnDItemType,
  DnDTaskItem,
  HoverPosition,
} from '../../../../types/drag-and-drop';

import {
  getDraggedItemToIndex,
  getHoverPosition,
} from '../../../../lib/drag-and-drop';

import IconButton from '../../../../components/IconButton';
import ToastAlert from '../../../../components/ToastAlert';
import Trash from '../../../../svgs/Trash';
import DragHandle from '../../../../svgs/DragHandle';
import Spinner from '../../../../svgs/Spinner';

function getTaskItemIdAttribute(taskId: string) {
  return `task-item-${taskId}`;
}

type TableRowProps = {
  task: Task;
  taskGroup: ProgramTemplateTaskGroup;
  taskIndex: number;
  className?: string;
  isSavingTaskMove: boolean;
  onClick?: () => void;
  onRemoveClick?: () => void;
  moveTaskAndSave: MoveTaskAndSaveCallback;
};

const TableRow: FC<TableRowProps> = ({
  task,
  taskIndex,
  taskGroup,
  className,
  isSavingTaskMove,
  onClick,
  onRemoveClick,
  moveTaskAndSave,
}) => {
  const [hoverPosition, setHoverPosition] = useState<HoverPosition | null>(
    null,
  );

  const rowRef = useRef<HTMLTableRowElement>(
    null,
  ) as MutableRefObject<HTMLTableRowElement | null>;

  const [{ isDragging }, drag] = useDrag<
    DnDTaskItem,
    unknown,
    { isDragging: boolean }
  >(
    () => ({
      type: DnDItemType.TASK,
      item: {
        index: taskIndex,
        id: task.id ?? '',
        groupId: taskGroup.id,
        idAttribute: getTaskItemIdAttribute(task.id ?? ''),
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => {
        return !isSavingTaskMove;
      },
    }),
    [taskIndex, taskGroup.id, task.id, isSavingTaskMove],
  );

  const [{ isHoveredOver }, drop] = useDrop<
    DnDTaskItem,
    void,
    { isHoveredOver: boolean }
  >({
    accept: DnDItemType.TASK,
    collect(monitor) {
      return {
        isHoveredOver: monitor.isOver(),
      };
    },
    hover(taskItem, monitor) {
      if (!rowRef.current) {
        return;
      }

      const hoveredItemRect = rowRef.current.getBoundingClientRect();
      const mousePosition = monitor.getClientOffset();

      if (!mousePosition) {
        return;
      }

      setHoverPosition(getHoverPosition(hoveredItemRect, mousePosition));
    },
    drop(draggedTaskItem) {
      if (!hoverPosition) {
        return;
      }

      const draggedItemIndex = draggedTaskItem.index;
      const droppedItemIndex = taskIndex;

      const toGroupId =
        draggedTaskItem.groupId === taskGroup.id ? undefined : taskGroup.id;

      const toIndex = getDraggedItemToIndex(
        draggedItemIndex,
        droppedItemIndex,
        hoverPosition,
        Boolean(toGroupId),
      );

      // Don't do anything if the task is dropped in the same position.
      if (toGroupId || draggedItemIndex !== toIndex) {
        moveTaskAndSave(
          draggedItemIndex,
          toIndex,
          draggedTaskItem.groupId,
          toGroupId,
        );
        draggedTaskItem.index = toIndex;
      }

      // The dropped item loses focus when dropped in a new position.
      // Focus the dropped item after the next tick, so there's time for it to render in the new position.
      setTimeout(
        () => document.getElementById(draggedTaskItem.idAttribute)?.focus(),
        0,
      );
    },
  });

  useEffect(() => {
    if (!isHoveredOver) {
      setHoverPosition(null);
    }
  }, [isHoveredOver]);

  if (!task) return null;

  return (
    <tr
      id={getTaskItemIdAttribute(task.id ?? '')}
      ref={(node) => {
        rowRef.current = node;
        return drag(drop(node));
      }}
      key={task.id}
      className={classNames(
        'group h-20 whitespace-nowrap border-b border-neutral-50 shadow-green-50 transition-shadow focus:outline-none',
        {
          'focus:bg-green-25/50': !isDragging,
          'shadow-top-line': hoverPosition === 'top',
          'shadow-bottom-line': hoverPosition === 'bottom',
        },
        className,
      )}
      onClick={onClick}
      tabIndex={0}
      role="button"
      aria-label={task.title ?? undefined}
    >
      {/* Use w-1 as a trick to have this cell fit the width of the content. */}
      <td className="w-1 pr-2">
        <div className="flex items-center font-medium text-green-150">
          <IconButton
            aria-label="Drag to move article"
            IconComponent={isSavingTaskMove ? Spinner : DragHandle}
            className={classNames(
              'cursor-grab opacity-0 transition-opacity hover:!bg-transparent focus:opacity-100 group-hover:opacity-100 group-focus:opacity-100',
              isDragging && '!opacity-0',
            )}
            disabled={isSavingTaskMove}
            iconClassName="w-5 h-5"
            onClick={(event) => {
              // Prevent the click from bubbling up to the row.
              event.stopPropagation();
              // TODO: replace toast with tooltip (fine for now).
              toast.custom(({ visible }) => (
                <ToastAlert
                  isVisible={visible}
                  level="info"
                  message={
                    <span>
                      <strong>Drag</strong> to move article
                    </span>
                  }
                />
              ));
            }}
          />
        </div>
      </td>
      <td className="pl-2">
        <div className="flex items-center font-medium text-green-150">
          <img
            src={task.media?.imageUrl || undefined}
            className={classNames(
              'mr-4 h-12 w-12 rounded-lg',
              isDragging && 'cursor-grabbing',
              taskGroup.isResourceGroup ? 'object-contain' : 'object-cover',
            )}
            alt=""
          />
          <div>{task.title}</div>
        </div>
      </td>
      <td className="text-neutral-125">
        {task.contentType?.replace('HTML ', '')}
      </td>
      <td className="text-neutral-125">{task.author}</td>
      <td>
        <IconButton
          aria-label="Delete article"
          onClick={(event) => {
            onRemoveClick?.();
            // Prevent the click from bubbling up to the row.
            event.stopPropagation();
          }}
          IconComponent={Trash}
          iconClassName="h-5 h-5 opacity-50 group-hover:opacity-100 group-focus:opacity-100"
        />
      </td>
    </tr>
  );
};

export default TableRow;
