import { getFirstName, getLastName } from '../../../../lib/copy';
import { getYYYYMMDDTimeStamp } from '../../../../lib/date';

import {
  PatientRow,
  PatientRowValues,
  NewPatientLeadType,
} from '../../../../types/PatientManagement';
interface FormattedValidatedData {
  [key: string]: NewPatientLeadType;
}

export const formatValidatedPatientLeads = (
  validatedData: PatientRowValues,
) => {
  const formatRow = (row: PatientRow, rowIndex: number) => {
    return {
      inputId: rowIndex,
      email: row.email.trim().toLowerCase(),
      firstName: getFirstName(row.name.trim()),
      lastName: getLastName(row.name.trim()),
      ...(row.firstAppointmentDate && {
        firstAppointmentDate: getYYYYMMDDTimeStamp(row.firstAppointmentDate),
      }),
    };
  };

  const formattedValidatedData: FormattedValidatedData = {};

  if (validatedData.patientRows?.length > 0)
    validatedData.patientRows.forEach((patientRow, index) => {
      formattedValidatedData[patientRow.email] = formatRow(patientRow, index);
    });

  return Object.values(formattedValidatedData);
};
