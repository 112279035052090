import { FC, useCallback, useRef, useState } from 'react';

import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';
import {
  CalendlyEventDataFragment,
  MePatientDataFragment,
} from '../../../../generated/graphql';
import useCalendly from '../../../hooks/useCalendly';
import classNames from 'classnames';
import CalendlyEventTypeCard from './CalendlyEventCard';
import { getCalendlyUrlForPatient } from './helpers';

type PatientCalendlyBookingModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  calendlyEventTypes?: CalendlyEventDataFragment[];
  authedPatient?: MePatientDataFragment;
  onCalendlyBooked?: () => Promise<void>;
};

const PatientCalendlyBookingModal: FC<PatientCalendlyBookingModalProps> = ({
  isModalOpen,
  setClosed,
  calendlyEventTypes,
  authedPatient,
  onCalendlyBooked,
}) => {
  const calendlyEmbedRef = useRef<HTMLDivElement>(null);
  const [showCalendlyWidget, setShowCalendlyWidget] = useState(false);

  const onCalendlyLoaded = useCallback(async () => {
    setShowCalendlyWidget(true);
  }, []);

  const onCalendlySubmit = useCallback(async () => {
    await onCalendlyBooked();
    setClosed();
    setShowCalendlyWidget(false);
  }, []);

  const { initCalendlyWidget } = useCalendly(
    calendlyEmbedRef,
    onCalendlyLoaded,
    onCalendlySubmit,
  );

  const setClosedAndResetState = () => {
    setClosed();
    setTimeout(() => {
      setShowCalendlyWidget(false);
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      name="PatientCalendlyBooking"
      isOpen={isModalOpen}
      setClosed={setClosedAndResetState}
      width="small"
    >
      <div className="flex flex-col items-center justify-between px-2 pb-4 md:px-10">
        <div className="flex flex-col items-center justify-start">
          <div className="font-sans-serif mb-6 flex flex-col items-center justify-start text-center text-subtitle text-neutral-150">
            <span>Book a session</span>
          </div>
        </div>
        <div className="my-2 w-full">
          {!showCalendlyWidget &&
            calendlyEventTypes?.map((calendlyEventType, index) => (
              <CalendlyEventTypeCard
                key={`CalendlyEventTypeCard_${index}`}
                calendlyEventType={calendlyEventType}
                setSchedulingUrlAndInitCalendly={() => {
                  const schedulingUrl = getCalendlyUrlForPatient(
                    calendlyEventType.schedulingUrl,
                    authedPatient,
                  );

                  initCalendlyWidget(schedulingUrl, {
                    name: authedPatient.name,
                    email: authedPatient.email,
                  });
                }}
              />
            ))}
          <div
            className={classNames(
              'h-0 w-full transition-all duration-500',
              showCalendlyWidget && 'h-[calc(86vh-128px)]',
            )}
            ref={calendlyEmbedRef}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PatientCalendlyBookingModal;
