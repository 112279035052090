import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import toast from 'react-hot-toast';
import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
  UseFieldArrayPrepend,
  FormProvider,
} from 'react-hook-form';

import { RefetchProviderActivities } from '../../../types/activity';
import {
  ActivityUseType,
  useAddActivityImageMutation,
} from '../../../../generated/graphql';

import CloseX from '../../../svgs/CloseX';
import ThumbnailImage from '../../../svgs/ThumbnailImage';

import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';

import {
  MIN_TEXT_LENGTH,
  MAX_TITLE_LENGTH,
} from '../../../lib/form-validators';

import { onEnterKeyDown } from '../../../lib/form';
import {
  acceptedImageFileExtensions,
  MAX_UPLOAD_SIZE_BYTES,
} from '../../../lib/media';

import Button from '../../Button';
import ToastAlert from '../../ToastAlert';
import InputGroup from '../../InputGroup';
import Spinner from '../../../svgs/Spinner';
import ErrorMessage from '../../ErrorMessage';
import UploadField from '../../UploadField';
import { FollowUpForm } from '../../../lib/followUp';
import { removeFileExtension } from '../../../lib/copy';
import { formatProviderActivityForFollowUp } from '../../../lib/providerActivity';
import ActivityUseSettings from '../FileSettingsModal/ActivityUseSettings';

type AddImageModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  refetchProviderActivities?: RefetchProviderActivities;
  followUpMode?: boolean;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  onAdded?: (id: string) => void;
};

interface AddImageModalFormData {
  newImageFile: FileList | undefined;
  imageFileTitle: string;
  isOneTimeUse: boolean;
}

const AddImageModal: FC<AddImageModalProps> = ({
  isModalOpen,
  setClosed,
  refetchProviderActivities,
  followUpMode = false,
  prependToFollowUp,
  onAdded,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [
    addActivityImageMutation,
    { loading: addActivityImageMutationLoading },
  ] = useAddActivityImageMutation();

  const addImageModalForm = useForm<AddImageModalFormData>({
    mode: 'onChange',
    defaultValues: {
      imageFileTitle: '',
      isOneTimeUse: followUpMode,
    },
  });

  const {
    control,
    watch,
    register,
    getFieldState,
    setValue,
    handleSubmit,
    formState: { errors: formErrors },
    reset: resetForm,
  } = addImageModalForm;

  const imageFileTitle = watch('imageFileTitle');
  const watchNewImageFile = watch('newImageFile');
  const newImageFileFieldState = getFieldState('newImageFile');

  const onSubmit: SubmitHandler<AddImageModalFormData> = async (formData) => {
    setSubmitting(true);

    const addActivityImageInput = {
      file: newImageFile,
      title: imageFileTitle,
      activityUseType: formData.isOneTimeUse
        ? ActivityUseType.OneTimeUse
        : ActivityUseType.Reusable,
    };

    try {
      const { data } = await addActivityImageMutation({
        variables: {
          input: addActivityImageInput,
        },
      });

      if (data?.addActivityImage) {
        const newActivityImage = data?.addActivityImage;
        closeAndResetAddImageModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully added ${newActivityImage.title} to your ${
              followUpMode ? 'follow-up' : 'library'
            }`}
          />
        ));

        if (refetchProviderActivities) await refetchProviderActivities();

        if (followUpMode && prependToFollowUp) {
          prependToFollowUp(
            formatProviderActivityForFollowUp(newActivityImage),
          );
        }

        onAdded?.(data?.addActivityImage?.id);
      }
    } catch (err) {
      closeAndResetAddImageModal();
      setSubmitting(false);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  const handleErrors: SubmitErrorHandler<AddImageModalFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetAddImageModal = async () => {
    setClosed();
    setTimeout(() => {
      resetForm();
      setSubmitting(false);
    }, MODAL_TRANSITION_DURATION);
  };

  const newImageFile = watchNewImageFile?.[0];
  const hasNewImageFile = Boolean(newImageFile);
  const hasValidImageFile = !newImageFileFieldState.error && hasNewImageFile;

  const [newImageUrl, setNewImageUrl] = useState<string>();

  const getImageForPreview = () => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event?.target?.result;
      if (result && typeof result === 'string') setNewImageUrl(result);
    };

    if (newImageFile) {
      reader.readAsDataURL(newImageFile);
    }
  };

  useEffect(() => {
    if (hasValidImageFile && newImageFile) {
      setValue('imageFileTitle', removeFileExtension(newImageFile)); // removes file extension
      getImageForPreview();
    }
  }, [hasValidImageFile, newImageFile]);

  return (
    <Modal
      name="AddImage"
      isOpen={isModalOpen}
      setClosed={closeAndResetAddImageModal}
      width="small"
      overflow="auto"
    >
      <div className="px-10">
        <div className="mb-6 flex w-full flex-col items-center justify-center">
          <span className="font-serif text-subtitle text-green-150">
            Add an image
          </span>
          <span className="text-caption text-green-125">
            Upload a JPG or PNG file and give it a title
          </span>
        </div>

        <form className="flex w-full flex-col items-start justify-start">
          <>
            <div
              className={classNames(
                'flex h-[68px] w-[460px] items-center justify-center rounded-md bg-neutral-25 text-caption text-neutral-125 transition-colors',
                !hasValidImageFile &&
                  'border border-dashed border-neutral-100 hover:border-green-100 hover:bg-green-25/30',
              )}
            >
              {!hasValidImageFile ? (
                <UploadField
                  readOnly
                  acceptedFileExtensions={acceptedImageFileExtensions.join(
                    ', ',
                  )}
                  inputId="newImageFile"
                  title="Upload a JPG or PNG file"
                  {...register('newImageFile', {
                    required: 'Please select a file to upload',
                    validate: (fileList) => {
                      if (fileList) {
                        const imageFile = fileList?.[0];
                        if (imageFile.size > MAX_UPLOAD_SIZE_BYTES) {
                          return "That file is a bit too big. Try one that's smaller than 100 MB";
                        } else if (
                          !acceptedImageFileExtensions.includes(imageFile.type)
                        ) {
                          return 'Please upload a JPG or PNG file';
                        }
                      }
                    },
                  })}
                />
              ) : (
                <div className="flex w-full flex-row items-start justify-between p-4">
                  <div className="flex w-11/12 flex-row items-center justify-start">
                    {newImageUrl ? (
                      <img
                        src={newImageUrl}
                        className="mr-4 h-[42px] w-[42px] flex-none rounded-md object-cover"
                        alt="Activity thumbnail"
                      />
                    ) : (
                      <ThumbnailImage className="mr-4 h-[42px] w-[42px] rounded-md" />
                    )}
                    <div className="flex w-[calc(100%-58px)] flex-col items-start justify-center">
                      <span className="w-full text-caption font-medium text-green-150 line-clamp-2">
                        {Boolean(imageFileTitle)
                          ? imageFileTitle
                          : newImageFile?.name}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setValue('newImageFile', undefined);
                      setValue('imageFileTitle', '');
                      setNewImageUrl('');
                    }}
                  >
                    <CloseX className="cursor-pointer" />
                  </div>
                </div>
              )}
            </div>
            <ErrorMessage className="mt-2">
              {formErrors.newImageFile?.message}
            </ErrorMessage>
            {hasNewImageFile && (
              <FormProvider {...addImageModalForm}>
                <Controller
                  name="imageFileTitle"
                  control={control}
                  rules={{
                    required: false,
                    minLength: {
                      value: MIN_TEXT_LENGTH,
                      message: `Title should be at least ${MIN_TEXT_LENGTH} characters long`,
                    },
                    maxLength: {
                      value: MAX_TITLE_LENGTH,
                      message: 'Title is too long',
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <InputGroup
                      label="Title"
                      useNaturalLettering
                      inputSize="small"
                      containerClassName="mt-3"
                      className="w-[460px] overflow-hidden"
                      onKeyDown={onEnterKeyDown}
                      onBlur={onBlur}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      value={value}
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                  )}
                />
                {followUpMode && <ActivityUseSettings />}
              </FormProvider>
            )}
          </>

          <div className="mb-8 mt-10 flex w-full flex-row justify-center">
            <Button
              title={
                !submitting
                  ? `Add to ${followUpMode ? 'follow-up' : 'library'}`
                  : 'Adding...'
              }
              onClick={handleSubmit(onSubmit, handleErrors)}
              {...(submitting && {
                IconComponent: Spinner,
                iconClassName: 'h-[16px] w-[16px]',
              })}
              disabled={Boolean(
                addActivityImageMutationLoading ||
                  formErrors.imageFileTitle ||
                  formErrors.newImageFile ||
                  !watchNewImageFile ||
                  !imageFileTitle,
              )}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddImageModal;
