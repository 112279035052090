import { Duration } from 'luxon';
import { ScheduleAssessmentFormData } from '.';
import {
  AssessmentFocalEvent,
  ProgramTemplateAssessmentScheduleItem,
} from '../../../generated/graphql';
import { capitalizeWords, NUMBER_TO_WORD } from '../../lib/copy';

export const OFFSET_MAX = 10;

export enum ScheduleTimingOption {
  SameDay = 'SameDay',
  BeforeOrAfter = 'BeforeOrAfter',
}

export enum BeforeOrAfterFocalEventOption {
  BeforeFirstSession = 'BeforeFirstSession',
  AfterFirstSession = 'AfterFirstSession',
  AfterClientActivation = 'AfterClientActivation',
}

export enum BeforeOrAfterOffsetPeriodOption {
  Day = 'D',
  Week = 'W',
  Month = 'M',
}

export enum RecurrenceIntervalOption {
  DoesNotRepeat = 'DoesNotRepeat',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
}

export const sameDayFocalEventFormOptions = Object.values(AssessmentFocalEvent);

export const SameDayFocalEventFormOption: React.FC<{
  value: AssessmentFocalEvent;
}> = ({ value }) => {
  let optionText = '';
  switch (value) {
    case AssessmentFocalEvent.ClientActivation:
      optionText = 'As joining Homecoming';
      break;
    case AssessmentFocalEvent.FirstSession:
      optionText = 'As first session date';
      break;
  }
  return <span>{optionText}</span>;
};

// Array from [1..OFFSET_MAX]
export const beforeOrAfterOffsetNumberFormOptions = Array.from(
  { length: OFFSET_MAX },
  (x, i) => i,
).map((i) => i + 1);

export const BeforeOrAfterOffsetNumberFormOption: React.FC<{
  value: number;
}> = ({ value }) => {
  const optionText = NUMBER_TO_WORD[value]
    ? capitalizeWords(NUMBER_TO_WORD[value])
    : value;
  return <span>{optionText}</span>;
};

export const beforeOrAfterOffsetPeriodFormOptions = Object.values(
  BeforeOrAfterOffsetPeriodOption,
);

export const BeforeOrAfterOffsetPeriodFormOptionSingular: React.FC<{
  value: BeforeOrAfterOffsetPeriodOption;
}> = ({ value }) => {
  let optionText = '';
  switch (value) {
    case BeforeOrAfterOffsetPeriodOption.Day:
      optionText = 'Day';
      break;
    case BeforeOrAfterOffsetPeriodOption.Week:
      optionText = 'Week';
      break;
    case BeforeOrAfterOffsetPeriodOption.Month:
      optionText = 'Month';
      break;
  }
  return <span>{optionText}</span>;
};

export const BeforeOrAfterOffsetPeriodFormOptionPlural: React.FC<{
  value: BeforeOrAfterOffsetPeriodOption;
}> = ({ value }) => {
  let optionText = '';
  switch (value) {
    case BeforeOrAfterOffsetPeriodOption.Day:
      optionText = 'Days';
      break;
    case BeforeOrAfterOffsetPeriodOption.Week:
      optionText = 'Weeks';
      break;
    case BeforeOrAfterOffsetPeriodOption.Month:
      optionText = 'Months';
      break;
  }
  return <span>{optionText}</span>;
};

export const beforeOrAfterFocalEventFormOptions = Object.values(
  BeforeOrAfterFocalEventOption,
);

export const BeforeOrAfterFocalEventFormOption: React.FC<{
  value: BeforeOrAfterFocalEventOption;
}> = ({ value }) => {
  let optionText = '';
  switch (value) {
    case BeforeOrAfterFocalEventOption.BeforeFirstSession:
      optionText = 'Before first session date';
      break;
    case BeforeOrAfterFocalEventOption.AfterFirstSession:
      optionText = 'After first session date';
      break;
    case BeforeOrAfterFocalEventOption.AfterClientActivation:
      optionText = 'After joining Homecoming';
      break;
  }
  return <span>{optionText}</span>;
};

export const recurrenceIntervalFormOptions = Object.values(
  RecurrenceIntervalOption,
);

export const RecurrenceIntervalFormOption: React.FC<{
  value: RecurrenceIntervalOption;
}> = ({ value }) => {
  let optionText = '';
  switch (value) {
    case RecurrenceIntervalOption.DoesNotRepeat:
      optionText = 'Does not repeat';
      break;
    case RecurrenceIntervalOption.Weekly:
      optionText = 'Repeats every week';
      break;
    case RecurrenceIntervalOption.Biweekly:
      optionText = 'Repeats every two weeks';
      break;
    case RecurrenceIntervalOption.Monthly:
      optionText = 'Repeats every month';
      break;
  }
  return <span>{optionText}</span>;
};

export const getAssessmentScheduleItemFromFormData = (
  formData: ScheduleAssessmentFormData,
): ProgramTemplateAssessmentScheduleItem => {
  let focalEvent: AssessmentFocalEvent;
  let timingOffset: string | null = null;

  switch (formData.scheduleTimingOption) {
    case ScheduleTimingOption.SameDay:
      focalEvent = formData.sameDayFocalEvent;
      break;
    case ScheduleTimingOption.BeforeOrAfter:
      focalEvent = getFocalEventFromBeforeOrAfterFocalEventOption(
        formData.beforeOrAfterFocalEvent,
      );
      timingOffset = getTimingOffsetDurationStringFromBeforeOrAfterData(
        formData.beforeOrAfterFocalEvent,
        formData.beforeOrAfterOffsetNumber,
        formData.beforeOrAfterOffsetPeriod,
      );
      break;
  }

  const recurrenceInterval =
    getRecurrenceIntervalDurationFromRecurrenceIntervalOption(
      formData.recurrenceInterval,
    );

  return {
    focalEvent,
    timingOffset,
    recurrenceInterval,
  };
};

const getRecurrenceIntervalDurationFromRecurrenceIntervalOption = (
  recurrenceInterval: RecurrenceIntervalOption,
): string | null => {
  switch (recurrenceInterval) {
    case RecurrenceIntervalOption.DoesNotRepeat:
      return null;
    case RecurrenceIntervalOption.Weekly:
      return 'P1W';
    case RecurrenceIntervalOption.Biweekly:
      return 'P2W';
    case RecurrenceIntervalOption.Monthly:
      return 'P1M';
  }
};

const getFocalEventFromBeforeOrAfterFocalEventOption = (
  beforeOrAfterFocalEvent: BeforeOrAfterFocalEventOption,
): AssessmentFocalEvent => {
  switch (beforeOrAfterFocalEvent) {
    case BeforeOrAfterFocalEventOption.BeforeFirstSession:
      return AssessmentFocalEvent.FirstSession;
    case BeforeOrAfterFocalEventOption.AfterFirstSession:
      return AssessmentFocalEvent.FirstSession;
    case BeforeOrAfterFocalEventOption.AfterClientActivation:
      return AssessmentFocalEvent.ClientActivation;
  }
};

const getTimingOffsetDurationStringFromBeforeOrAfterData = (
  beforeOrAfterFocalEvent: BeforeOrAfterFocalEventOption,
  beforeOrAfterOffsetNumber: number,
  beforeOrAfterOffsetPeriod: BeforeOrAfterOffsetPeriodOption,
): string => {
  const isNegative =
    beforeOrAfterFocalEvent ===
    BeforeOrAfterFocalEventOption.BeforeFirstSession;

  return `${
    isNegative ? '-' : ''
  }P${beforeOrAfterOffsetNumber}${beforeOrAfterOffsetPeriod}`;
};

export const getScheduleTextFromAssessmentScheduleItem = (
  assessmentScheduleItem: ProgramTemplateAssessmentScheduleItem,
): string => {
  let dayString: string;
  if (!assessmentScheduleItem.timingOffset) {
    dayString = 'Same day as';
  } else {
    let timingOffsetDuration = Duration.fromISO(
      assessmentScheduleItem.timingOffset,
    );
    const isDurationNegative = timingOffsetDuration.toMillis() < 0;
    if (isDurationNegative) {
      // Negate so that .toHuman doesn't produce a negative string/
      // Negation state has been pulled out into `isDurationNegative`
      timingOffsetDuration = timingOffsetDuration.negate();
    }
    dayString = `${timingOffsetDuration.toHuman()} ${
      isDurationNegative ? 'before' : 'after'
    }`;
  }

  let focalEventString: string;
  switch (assessmentScheduleItem.focalEvent) {
    case AssessmentFocalEvent.ClientActivation:
      focalEventString = 'joining Homecoming';
      break;
    case AssessmentFocalEvent.FirstSession:
      focalEventString = 'first session date';
      break;
  }

  return `${dayString} ${focalEventString}`;
};

export const getRecurrenceTextFromAssessmentScheduleItem = (
  assessmentScheduleItem: ProgramTemplateAssessmentScheduleItem,
): string => {
  switch (assessmentScheduleItem.recurrenceInterval) {
    case null:
    case undefined:
      return 'Does not repeat';
    case 'P1W':
      return 'Repeats every week';
    case 'P2W':
      return 'Repeats every two weeks';
    case 'P1M':
      return 'Repeats every month';
    default:
      return 'Repeats';
  }
};
