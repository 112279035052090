import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { LockClosedIcon } from '@heroicons/react/solid';

import { capitalizeSentence } from '../lib/copy';
import ErrorMessage from './ErrorMessage';
import Button from './Button';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { SvgIconComponent } from '../types/svgs';
import InputLabel from './InputLabel';

type InputGroupProps = {
  label: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  containerClassName?: string;
  inputSize?: 'medium' | 'small' | 'extra-small';
  labelHidden?: boolean;
  backgroundHidden?: boolean;
  errorMessage?: string | null;
  errorLocation?: 'bottom' | 'right' | 'none';
  isSearch?: boolean;
  IconRight?: SvgIconComponent | null;
  iconRightClassName?: string;
  IconLeft?: SvgIconComponent | null;
  iconLeftClassName?: string;
  readOnly?: boolean;
  passwordStrengthScore?: number;
  iconRightOnClick?: () => void;
  iconLeftOnClick?: () => void;
} & React.ComponentProps<'input'>;

const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>(
  (
    {
      label,
      required = false,
      placeholder = '',
      className,
      containerClassName,
      labelHidden,
      backgroundHidden = false,
      inputSize = 'medium',
      errorMessage,
      errorLocation = 'bottom',
      IconLeft = null,
      iconLeftClassName,
      iconLeftOnClick,
      IconRight = null,
      iconRightClassName,
      iconRightOnClick,
      readOnly,
      passwordStrengthScore,
      type,
      ...rest
    },
    ref,
  ) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(
      type === 'password',
    );

    const InputRightElement: React.FC = () => {
      if (errorLocation === 'right' && Boolean(errorMessage)) {
        return (
          <div className="pointer-events-auto flex flex-row items-center justify-center rounded-lg bg-white p-2 text-small-action font-bold text-red-100 shadow-100">
            {errorMessage}
          </div>
        );
      }

      if (readOnly) {
        return <LockClosedIcon className="h-4 w-4 text-neutral-125/50" />;
      }

      if (type === 'password') {
        return (
          <Button
            title={isPasswordHidden ? 'SHOW' : 'HIDE'}
            className="pointer-events-auto font-bold"
            theme="secondary"
            size="small"
            noBackground
            noOutline
            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
          />
        );
      }

      if (IconRight) {
        return (
          <IconRight className={iconRightClassName} aria-label="IconRight" />
        );
      }

      return null;
    };

    const InputLeftElement: React.FC = () => {
      if (IconLeft) {
        return <IconLeft className={iconLeftClassName} aria-label="IconLeft" />;
      }

      return null;
    };

    const inputId = label.toLowerCase();

    return (
      <div className={classNames('group flex flex-col', containerClassName)}>
        <InputLabel
          label={label}
          isHidden={labelHidden}
          className={
            Boolean(errorMessage) ? 'group-focus-within:text-red-125' : ''
          }
          inputRequired={required}
          htmlFor={inputId}
        >
          {type === 'password' && passwordStrengthScore !== undefined && (
            <PasswordStrengthIndicator strengthScore={passwordStrengthScore} />
          )}
        </InputLabel>

        {/* Input */}
        <div className="relative">
          <div
            onClick={iconLeftOnClick}
            className={classNames(
              'absolute inset-y-0 left-0 ml-3 flex items-center',
              iconLeftOnClick
                ? 'pointer-events-auto cursor-pointer'
                : 'pointer-events-none',
            )}
          >
            <InputLeftElement />
          </div>
          <input
            ref={ref}
            id={inputId}
            className={classNames(
              className,
              !className?.includes('rounded') && 'rounded', // solves passing a rounded function in since it defaults to rounded
              'block w-full border-2 border-transparent text-body transition-colors',
              'text-green-150 placeholder:text-neutral-125/75 read-only:text-neutral-125/75',
              'focus:outline-none focus:ring-0',
              backgroundHidden ? 'bg-transparent' : 'bg-neutral-50',
              inputSize === 'medium'
                ? 'py-4 px-4'
                : inputSize === 'small'
                ? 'py-3 px-4'
                : 'py-2 px-3',
              errorMessage
                ? 'selection:bg-red-50/50 focus:border-red-50'
                : 'selection:bg-green-50/50 focus:border-green-50',
            )}
            placeholder={placeholder ?? capitalizeSentence(label)}
            type={type === 'password' && !isPasswordHidden ? 'text' : type}
            readOnly={readOnly}
            {...rest}
          />

          <div
            onClick={iconRightOnClick}
            className={classNames(
              'absolute inset-y-0 right-0 mr-5 flex items-center',
              iconRightOnClick
                ? 'pointer-events-auto cursor-pointer'
                : 'pointer-events-none',
            )}
          >
            <InputRightElement />
          </div>
        </div>

        {/* Error message */}
        {errorLocation === 'bottom' && (
          <ErrorMessage className="mt-2">{errorMessage}</ErrorMessage>
        )}
      </div>
    );
  },
);

export default InputGroup;
