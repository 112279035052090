import { MePatientDataFragment } from '../../../../generated/graphql';
import { withSearchParams } from '../../../lib/url';

export const getCalendlyUrlForPatient = (
  calendlySchedulingUrl: string | null,
  patient: MePatientDataFragment | null,
) => {
  if (!calendlySchedulingUrl || !patient) {
    return '';
  }

  return withSearchParams(calendlySchedulingUrl, {
    name: patient.name,
    email: patient.email,
  });
};
