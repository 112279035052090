import { FC } from 'react';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

type ConfirmReactivateTeamMemberModalProps = {
  isOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  isReactivating: boolean;
};

const ConfirmReactivateTeamMemberModal: FC<
  ConfirmReactivateTeamMemberModalProps
> = ({ isOpen, setClosed, onConfirm, isReactivating }) => {
  return (
    <Modal isOpen={isOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to reactivate this team member?
          </div>
          <div className="text-center text-body text-neutral-125">
            Team members who are reactivated will be able to login and regain
            access to the Homecoming platform.
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title="Reactivate"
            theme="primary"
            onClick={onConfirm}
            disabled={isReactivating}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmReactivateTeamMemberModal;
