import { SvgIconComponent } from '../types/svgs';

const Plus: SvgIconComponent = ({ className }) => {
  return (
    <>
      <svg
        className={className}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 5C8.17783 5 7.91667 5.26117 7.91667 5.58333V6.91667C7.91667 7.46895 7.46895 7.91667 6.91667 7.91667H5.58333C5.26117 7.91667 5 8.17783 5 8.5C5 8.82217 5.26117 9.08333 5.58333 9.08333H6.91667C7.46895 9.08333 7.91667 9.53105 7.91667 10.0833V11.4167C7.91667 11.7388 8.17783 12 8.5 12C8.82217 12 9.08333 11.7388 9.08333 11.4167V10.0833C9.08333 9.53105 9.53105 9.08333 10.0833 9.08333H11.4167C11.7388 9.08333 12 8.82217 12 8.5C12 8.17783 11.7388 7.91667 11.4167 7.91667H10.0833C9.53105 7.91667 9.08333 7.46895 9.08333 6.91667V5.58333C9.08333 5.26117 8.82217 5 8.5 5Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Plus;
