import { SvgIconComponent } from '../../../types/svgs';

const Play: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fill="white"
        d="M15 10H13V22H15V10ZM19 10H17V22H19V10Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Play;
