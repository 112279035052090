import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { Transition } from '@headlessui/react';

import {
  useSendProviderUserInvitesMutation,
  useProviderUserMembersQuery,
} from '../../generated/graphql';

import { OnboardingPageProps } from '../types/onboarding';
import Spinner from '../svgs/Spinner';
import Button from '../components/Button';
import ArrowRightLong from '../svgs/ArrowRightLong';
import ErrorMessage from '../components/ErrorMessage';
import InputGroup from '../components/InputGroup';
import { useAuth } from '../../contexts/AuthContext';
import { markOnboardingStepCompleted } from '../lib/storage';
import InputLabel from '../components/InputLabel';
import { removeEmptyFields } from '../lib/form';
import AddRowButton from '../components/AddRowButton';
import MemberInputRow from '../components/MemberInputRow';
import toast from 'react-hot-toast';
import ToastAlert from '../components/ToastAlert';
import { pluralize } from '../lib/copy';
import { defaultEnterTransitionProps } from '../lib/animation';

type Member = { email: string };
type AddMembersFormData = {
  members: Member[];
};

export default function OnboardingMembers({
  stepIndex,
  steps,
  goToNextStep,
}: OnboardingPageProps) {
  const { authedProviderUser } = useAuth();

  const {
    data: membersData,
    loading: isLoadingMembers,
    error: membersError,
  } = useProviderUserMembersQuery();
  const [
    sendProviderUserInvitesMutation,
    { loading: isSendingInvites, error: sendInvitesError },
  ] = useSendProviderUserInvitesMutation();

  const formContext = useForm<AddMembersFormData>({
    mode: 'onSubmit',
    defaultValues: {
      members: [{ email: '' }],
    },
  });
  const {
    watch,
    control,
    clearErrors: clearFormErrors,
    handleSubmit,
  } = formContext;

  const fieldArrayContext = useFieldArray({
    name: 'members',
    control,
  });
  const { fields, append, remove } = fieldArrayContext;

  const watchedMembers = watch('members');
  // A "controlled" field array means that the fields reflect the up-to-date form data at all times.
  const controlledFields: Member[] = fields.map((field, index) => {
    return {
      ...field,
      ...watchedMembers[index],
    };
  });

  const handleAddRowClick = () => {
    const existingMembers = membersData?.providerUserMembers ?? [];
    const newUsedStaffSeats = existingMembers.length + controlledFields.length;
    const staffSeats = authedProviderUser?.provider.staffSeats;

    if (staffSeats != null && newUsedStaffSeats >= staffSeats) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Can't add more than ${staffSeats} members with your current plan.`}
        />
      ));
      return;
    }

    append({
      email: '',
    });
  };

  const onSubmit: SubmitHandler<AddMembersFormData> = async (formData) => {
    clearFormErrors();

    const providerUserInvites = formData.members.map((member) => ({
      email: member.email,
    }));
    await sendProviderUserInvitesMutation({
      variables: { providerUserInvites },
    });
    toast.custom(({ visible }) => (
      <ToastAlert
        isVisible={visible}
        message={`Successfully sent ${pluralize(
          providerUserInvites.length,
          'an invite',
          'invites',
          false,
        )} to ${pluralize(
          providerUserInvites.length,
          'new member',
          'new members',
        )}!`}
      />
    ));

    completeStep();
  };

  const completeStep = () => {
    markOnboardingStepCompleted(
      authedProviderUser!.email,
      steps[stepIndex].name,
    );
    goToNextStep(stepIndex);
  };

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center justify-center py-8 px-4 text-center">
      <h1 className="mb-3 text-subtitle text-green-150">
        Who else is on your team?
      </h1>
      <p className="mb-12 text-green-125">
        To get the most out of Homecoming, add a few team members to join the
        cause.
      </p>

      {isLoadingMembers ? (
        <Spinner />
      ) : (
        <Transition className="w-full" {...defaultEnterTransitionProps}>
          <InputLabel label="EMAIL" inputRequired />
          {membersData?.providerUserMembers.map((member, index) => (
            <div
              key={`existing-members.${index}`}
              className="mb-4 flex h-full w-full flex-row justify-between"
            >
              <InputGroup
                label="Email"
                containerClassName="w-full"
                inputSize="small"
                labelHidden
                readOnly
                value={member.email}
              />
            </div>
          ))}

          <form className="w-full" onSubmit={(e) => e.preventDefault()}>
            {controlledFields.map((field, fieldIndex) => (
              <MemberInputRow
                key={`members.${fieldIndex}`}
                formFields={controlledFields}
                fieldIndex={fieldIndex}
                formContext={formContext}
                fieldArrayContext={fieldArrayContext}
                existingMembers={membersData?.providerUserMembers ?? []}
              />
            ))}

            <AddRowButton onClick={handleAddRowClick} />
          </form>
        </Transition>
      )}

      <div className="mt-12" />

      <ErrorMessage className="mb-4">
        {membersError?.message || sendInvitesError?.message}
      </ErrorMessage>

      <Button
        title="Add team members"
        IconComponent={ArrowRightLong}
        disabled={isLoadingMembers || isSendingInvites}
        onClick={() => {
          removeEmptyFields(controlledFields, remove);
          handleSubmit(onSubmit)();
        }}
      />

      <Button
        title="Skip this step"
        onClick={completeStep}
        noBackground
        noOutline
        theme="secondary"
        className="mt-4"
      />
    </div>
  );
}
