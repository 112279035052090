import React from 'react';
import { useForm } from 'react-hook-form';
import InputGroup from '../../components/InputGroup';
import { EMAIL_REGEX, NAME_REGEX } from '../../lib/regex';
import Button from '../Button';
import {
  StorefrontPatientSignUpError,
  useStorefrontPatientSignUpMutation,
} from '../../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';

interface StorefrontSignUpFormData {
  fullName: string;
  email: string;
}

const StorefrontSignUp: React.FC<{
  providerName: string;
  enabled?: boolean;
  providerStorefrontSlug?: string;
  includeLoginLink?: boolean;
}> = ({
  providerName,
  enabled = true,
  providerStorefrontSlug,
  includeLoginLink = false,
}) => {
  const { updateTokenPayload } = usePatientAuth();
  const navigate = useNavigate();
  const [storefrontSignUp] = useStorefrontPatientSignUpMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isSubmitting: isFormSubmitting },
  } = useForm<StorefrontSignUpFormData>();

  const onSubmit = async (formData: StorefrontSignUpFormData) => {
    if (!providerStorefrontSlug || !enabled) {
      return;
    }

    try {
      const { data } = await storefrontSignUp({
        variables: {
          input: {
            email: formData.email,
            name: formData.fullName,
            storefrontSlug: providerStorefrontSlug,
          },
        },
      });

      const storefrontPatientSignUpData = data?.storefrontPatientSignUp;

      if (storefrontPatientSignUpData?.error) {
        const storefrontError = storefrontPatientSignUpData.error;
        switch (storefrontError) {
          case StorefrontPatientSignUpError.InvalidStorefrontSlug:
            setError('root.serverError', {
              message: `We couldn't find the provider you're trying to connect with. Please try again later.`,
            });
            break;
          case StorefrontPatientSignUpError.EmailAlreadyExistsOtherProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with another provider on Homecoming. Please provide another email to work with ${providerName}`,
            });
            break;
          case StorefrontPatientSignUpError.EmailAlreadyExistsThisProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with ${providerName}. We've sent you an email with a link to your client portal.`,
            });
            break;
          default:
            break;
        }
        return;
      }

      if (storefrontPatientSignUpData?.authToken) {
        updateTokenPayload({
          authSessionToken: storefrontPatientSignUpData.authToken,
        });
        navigate('/client/intake');
      }
    } catch (error) {
      setError('root.serverError', {
        message:
          "Something went wrong. Please contact support@homecoming.health and we'll help you get connected.",
      });
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mb-4 text-body font-medium text-neutral-150">
        Work with me
      </div>
      <form className="w-full">
        <InputGroup
          placeholder="Full name"
          autoComplete="name"
          type="text"
          inputSize="small"
          useNaturalLettering={true}
          containerClassName="mb-2"
          className="rounded-full !border border-neutral-75 !placeholder-neutral-100"
          backgroundHidden
          required
          disabled={!enabled}
          errorMessage={formErrors.fullName?.message}
          {...register('fullName', {
            required: 'Name is required',
            pattern: {
              value: NAME_REGEX,
              message: 'Please enter your first and last name',
            },
          })}
        />
        <InputGroup
          placeholder="Email"
          autoComplete="email"
          type="email"
          inputSize="small"
          useNaturalLettering={true}
          className="rounded-full !border border-neutral-75 !placeholder-neutral-100"
          backgroundHidden
          required
          disabled={!enabled}
          errorMessage={formErrors.email?.message}
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email',
            },
          })}
        />
        <Button
          title={`Get in touch`}
          className="mx-auto my-6 w-full disabled:cursor-default md:mt-8"
          type="submit"
          disabled={!enabled || isFormSubmitting}
          onClick={handleSubmit(onSubmit)}
        />
        <ErrorMessage className="mx-auto mb-4 text-center">
          {formErrors.root?.serverError?.message}
        </ErrorMessage>
      </form>
      {includeLoginLink && (
        <div>
          <div className="flex flex-col items-center space-y-1 text-small-caption font-medium text-neutral-125">
            <div>Already a client?</div>
            <div>
              <span
                onClick={() => navigate('/login/client')}
                className="cursor-pointer font-medium text-green-100 hover:underline"
              >
                Log in
              </span>{' '}
              to your client portal
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StorefrontSignUp;
