import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { SUPPORT_MAIL_TO_URL } from '../lib/url';

type EmptyIntegrationProps = {
  title: string;
  description: string;
  ctaPath?: string;
  ctaTitle?: string;
  illustration?: React.ReactNode;
};

const EmptyIntegration: React.FC<EmptyIntegrationProps> = ({
  title,
  description,
  ctaPath,
  ctaTitle = 'Get started',
  illustration,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full flex-1 items-center justify-center rounded-xl border border-neutral-75 bg-neutral-25 p-6">
      <div className="flex max-w-[550px] flex-col items-center justify-center text-center text-neutral-125">
        {illustration}
        <div className="mt-6 mb-2 text-category font-medium text-green-150">
          {title}
        </div>
        <p>{description}</p>

        {ctaTitle && ctaPath && (
          <Button
            title={ctaTitle}
            onClick={() => navigate(ctaPath)}
            className="mt-6"
          />
        )}

        <div className="mt-6">
          Need assistance or have any questions about this integration?
        </div>
        <a
          className="mt-1 font-medium text-blue-100 hover:text-blue-125"
          href={SUPPORT_MAIL_TO_URL}
        >
          Contact our support team.
        </a>
      </div>
    </div>
  );
};

export default EmptyIntegration;
