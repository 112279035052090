interface ImageOptimizerParams {
  height?: number;
}

/**
 * Adds Fastly image optimizer params to image URLs
 * See https://developer.fastly.com/reference/io/ for comprehensive documentation
 *
 * NOTE: the `ImageOptimizerParams` interface contains a tiny subset of the options that
 * image optimizer provides. Look at the docs! Add more if you need them!
 * @param url: the image url
 * @param params: the image optimizer parameters to include.
 * @returns
 */
export function addOptimizerParams(
  url: string | undefined,
  params: ImageOptimizerParams,
): string | null {
  if (!url) {
    return null;
  }

  const urlObj = new URL(url);

  for (const [key, value] of Object.entries(params)) {
    urlObj.searchParams.append(key, value);
  }

  return urlObj.toString();
}
