import { Auth0Client } from '@auth0/auth0-spa-js';
import Config from './config';

let auth0Client: Auth0Client | null = null;

const initAuth0 = async () => {
  auth0Client = new Auth0Client({
    domain: Config.REACT_APP_AUTH0_DOMAIN,
    clientId: Config.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: Config.REACT_APP_AUTH0_API_AUDIENCE,
      redirect_uri: Config.REACT_APP_AUTH0_REDIRECT_URI,
    },
  });
  await auth0Client.checkSession();
};

// Needed to log out of Auth0 outside the React context
const logout = async () => {
  if (!auth0Client) {
    await initAuth0();
  }

  await auth0Client.logout({
    openUrl: (url) => {
      window.location.replace(url);
    },
  });
};

const Auth0Helpers = {
  initAuth0,
  logout,
};

export default Auth0Helpers;
