import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import {
  PatientTask,
  FeedType,
  TaskType,
  CheckIn,
  usePatientFeedLazyQuery,
} from '../../../../generated/graphql';

import Button from '../../../components/Button';
import ToastAlert from '../../../components/ToastAlert';
import Slideover from '../../../components/Slideover';

import Spinner from '../../../svgs/Spinner';
import ArrowRightLong from '../../../svgs/ArrowRightLong';

import { getTimeStamp } from '../../../lib/date';
import FeedItem from '../FeedPanel/FeedItem';

const FeedSlideover: FC<{
  isOpen: boolean;
  programId: string;
  selectedFeed: FeedType | null;
  checkInsCount: number;
  activitiesCount: number;
  onClose: () => void;
}> = ({
  isOpen,
  programId,
  selectedFeed,
  checkInsCount,
  activitiesCount,
  onClose,
}) => {
  const [activityFeed, setActivityFeed] = useState<PatientTask[]>([]);
  const [checkInFeed, setCheckInFeed] = useState<CheckIn[]>([]);

  const [activityPaginationIndex, setActivityPaginationIndex] = useState(0);
  const [checkInPaginationIndex, setCheckInPaginationIndex] = useState(0);

  const isActivitiesFeed = selectedFeed === FeedType.Activities;
  const isCheckInsFeed = selectedFeed === FeedType.CheckIns;

  const allActivitiesShown = activityFeed.length >= activitiesCount;
  const allCheckInsShown = checkInFeed.length >= checkInsCount;

  const queryPaginationIndex =
    (isActivitiesFeed && activityPaginationIndex) ||
    (isCheckInsFeed && checkInPaginationIndex) ||
    0;

  const [
    getPatientFeed,
    {
      error: patientFeedError,
      data: patientFeedData,
      loading: isLoadingPatientFeed,
    },
  ] = usePatientFeedLazyQuery();

  const [feedError, setFeedError] = useState(false);
  useEffect(() => {
    if (patientFeedError && !isLoadingPatientFeed && !feedError) {
      setFeedError(true);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch patient feed."
          level="error"
        />
      ));
    }
  }, [isLoadingPatientFeed, patientFeedError]);

  const incrementPaginationIndex = () => {
    if (isActivitiesFeed) {
      setActivityPaginationIndex(activityPaginationIndex + 1);
    } else if (isCheckInsFeed) {
      setCheckInPaginationIndex(checkInPaginationIndex + 1);
    }
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (selectedFeed !== null && queryPaginationIndex === 0) {
      getPatientFeed({
        variables: {
          programId,
          feedType: selectedFeed,
          paginationIndex: queryPaginationIndex,
        },
      });
      if (!showContent && !patientFeedError) {
        setShowContent(true);
      }
      incrementPaginationIndex();
    }
  }, [programId, selectedFeed]);

  const patientActivities = patientFeedData?.patientFeed?.activities || null;
  const patientCheckIns = patientFeedData?.patientFeed?.checkIns || null;

  const handleSeeMoreButtonClick = async () => {
    if (selectedFeed) {
      await getPatientFeed({
        variables: {
          programId,
          feedType: selectedFeed,
          paginationIndex: queryPaginationIndex,
        },
      });
    }
    incrementPaginationIndex();
  };

  useEffect(() => {
    if (isActivitiesFeed && patientActivities !== null && !allActivitiesShown) {
      setActivityFeed([...activityFeed, ...patientActivities]);
    }
  }, [patientActivities]);

  useEffect(() => {
    if (isCheckInsFeed && patientCheckIns !== null && !allCheckInsShown) {
      setCheckInFeed([...checkInFeed, ...patientCheckIns]);
    }
  }, [patientCheckIns]);

  const seeMoreDisabled =
    (isActivitiesFeed && allActivitiesShown) ||
    (isCheckInsFeed && allCheckInsShown);

  let seeMoreIconProps = {};
  if (!seeMoreDisabled) {
    seeMoreIconProps = {
      IconComponent: ArrowRightLong,
      iconClassName: 'text-green-100',
    };
  } else {
    seeMoreIconProps = {};
  }

  const seeMoreButtonTitleText =
    (isActivitiesFeed && !allActivitiesShown) ||
    (isCheckInsFeed && !allCheckInsShown)
      ? 'See more'
      : isActivitiesFeed
      ? 'All activities shown'
      : 'All check-ins shown';

  return (
    <Slideover
      isOpen={isOpen}
      showContent={showContent}
      title={
        isActivitiesFeed ? 'Activities' : isCheckInsFeed ? 'Check-ins' : ''
      }
      onClose={onClose}
    >
      <div className="flex flex-col items-center justify-start rounded-2xl bg-white">
        {isActivitiesFeed &&
          activityFeed?.map((patientTask, patientTaskIndex) => {
            return (
              <FeedItem
                key={`FeedType.Activities_${patientTaskIndex}`}
                feedType={selectedFeed}
                feedItemType={patientTask.taskType}
                content={patientTask.title}
                timestamp={getTimeStamp(patientTask.createdAt, false, true)}
              />
            );
          })}
        {isCheckInsFeed &&
          checkInFeed?.map((checkIn, checkInIndex) => {
            return (
              <FeedItem
                key={`FeedType.CheckIns_${checkInIndex}`}
                feedType={selectedFeed}
                feedItemType={TaskType.CheckIn}
                checkInItem={checkIn}
              />
            );
          })}
        {isLoadingPatientFeed ? (
          <Spinner className="my-6 h-5 w-5" />
        ) : (
          <Button
            noOutline
            noBackground
            size="small"
            theme="secondary"
            {...seeMoreIconProps}
            disabled={seeMoreDisabled}
            title={seeMoreButtonTitleText}
            onClick={handleSeeMoreButtonClick}
            className={classNames(
              'flex w-full flex-row items-start justify-start',
              'rounded-none rounded-b-xl px-8 py-6 text-caption font-bold',
              'hover:rounded-b-xl hover:bg-neutral-25 disabled:hover:bg-white',
            )}
          />
        )}
      </div>
    </Slideover>
  );
};

export default FeedSlideover;
