import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/solid';

import Button from './Button';
import CloseX from '../svgs/CloseX';
import Spinner from '../svgs/Spinner';

import { defaultEnterTransitionProps } from '../lib/animation';

export const SLIDEOVER_TRANSITION_DURATION = 500;

const Slideover: FC<{
  isOpen: boolean;
  fetching?: boolean;
  children: React.ReactNode;
  titleComponent?: React.ReactNode;
  containerClassName?: string;
  showContent?: boolean;
  title: string;
  subtitle?: string;
  backButtonActive?: boolean;
  onBackButtonClick?: () => void;
  onClose: () => void;
}> = ({
  isOpen,
  children,
  titleComponent = null,
  containerClassName = '',
  fetching = false,
  showContent = true,
  title,
  subtitle = '',
  backButtonActive = false,
  onBackButtonClick,
  onClose,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-125 bg-opacity-50 backdrop-blur-[4px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md text-green-150">
                  <div className="flex w-full flex-col items-center justify-start border-b border-neutral-50 bg-white px-4 pt-4 pb-6 sm:px-6 ">
                    <div className="mb-2 flex w-full flex-row items-center justify-between">
                      <Button
                        onClick={onBackButtonClick}
                        title="BACK"
                        theme="secondary"
                        noBackground
                        noOutline
                        iconPosition="left"
                        size="small"
                        IconComponent={ChevronLeftIcon}
                        iconClassName="text-green-125"
                        className={classNames(
                          'text-small-caption font-black text-green-125 transition duration-150',
                          !backButtonActive && 'opacity-0',
                          backButtonActive && 'opacity-100',
                        )}
                      />
                      <Button
                        title="CLOSE"
                        iconPosition="right"
                        theme="secondary"
                        size="extra-small"
                        noBackground
                        noOutline
                        IconComponent={CloseX}
                        onClick={onClose}
                        className="text-green-125"
                        iconClassName="text-green-125"
                      />
                    </div>
                    <div className="flex w-full flex-col items-center justify-center">
                      <span className="mb-1 font-serif text-subtitle-small">
                        {title}
                      </span>
                      {subtitle && (
                        <span className="text-caption text-green-125">
                          {subtitle}
                        </span>
                      )}
                    </div>
                    {titleComponent}
                  </div>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white">
                    <div
                      className={classNames(
                        'relative flex-1 bg-neutral-50 px-4 pb-32 pt-6 sm:px-6',
                        containerClassName,
                      )}
                    >
                      {fetching && <Spinner className="mx-auto mt-4" />}
                      <Transition
                        show={showContent}
                        {...defaultEnterTransitionProps}
                      >
                        {children}
                      </Transition>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slideover;
