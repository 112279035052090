import { FC } from 'react';
import classNames from 'classnames';

const NoText: FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => (
  <span className={classNames('text-neutral-110/60', className)}>{text}</span>
);

export default NoText;
