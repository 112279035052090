import Button from '../Button';
import {
  AnalyticsComponent,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import { stripLeadingSlash } from '../../lib/string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';
import { useAuth } from '../../../contexts/AuthContext';
import { DEFAULT_FREE_CLIENT_COUNT } from '../../lib/constants';

const UpgradeBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();
  const { showUpgradeModal } = useGlobalModalsContext();

  const isPartner = authedProviderUser?.signUpConfig?.slug != null;
  const hasBeenSubscribed = authedProviderUser?.hasBeenSubscribed ?? false;
  const buttonTitle = 'Explore plans';

  const onUpgradeClick = () => {
    if (isPartner) {
      showUpgradeModal(AnalyticsComponent.MainHeader);
    } else {
      navigate('/subscribe', {
        state: {
          stripeCheckoutPortalWebRedirectPath: stripLeadingSlash(
            location.pathname,
          ),
        },
      });
    }
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: buttonTitle,
      buttonType: 'Subscribe',
      originComponent: AnalyticsComponent.UpgradeBanner,
    });
  };

  return (
    <div className="z-40 flex h-[var(--upgrade-banner-height)] w-full flex-row items-center justify-center bg-[url('assets/images/default-webpage-banner.png')] bg-cover bg-center px-20">
      <div className="text-caption font-medium text-neutral-0">
        Your {hasBeenSubscribed ? 'subscription' : 'trial'} has ended. Upgrade
        now to continue supporting more than{' '}
        {authedProviderUser?.provider.clientSeats ?? DEFAULT_FREE_CLIENT_COUNT}{' '}
        clients.
      </div>
      <Button
        title={buttonTitle}
        theme="secondary-white"
        size="small"
        onClick={onUpgradeClick}
        className="ml-4 text-green-125"
      />
    </div>
  );
};

export default UpgradeBanner;
