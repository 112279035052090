import { useCompleteOnboardingMutation } from '../../generated/graphql';
import Button from '../components/Button';
import ArrowRightLong from '../svgs/ArrowRightLong';
import CircleGreenCheck from '../svgs/CircleGreenCheck';
import starIllustration from '../../assets/images/hc-star.png';
import { OnboardingPageProps } from '../types/onboarding';
import { useAuth } from '../../contexts/AuthContext';
import ErrorMessage from '../components/ErrorMessage';

const ChecklistItem: React.FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <div className="my-1 flex flex-row items-center justify-start">
      <CircleGreenCheck />
      <span className="ml-2 text-body">{text}</span>
    </div>
  );
};

export default function OnboardingConfirmation({
  stepIndex,
  steps,
  goToNextStep,
}: OnboardingPageProps) {
  const [
    completeOnboarding,
    { loading: isCompleteOnboardingLoading, error: completeOnboardingError },
  ] = useCompleteOnboardingMutation();

  const {
    refreshAuthedProviderUser,
    isAuthedProviderUserLoading,
    authedProviderUserError,
  } = useAuth();

  const onConfirm = async () => {
    await completeOnboarding();
    await refreshAuthedProviderUser();
    // With the updated ProviderUser, they'll be navigated away automatically by the parent component
  };

  // TODO: this shouldn't be hardcoded, since it's not guaranteed to be accurate.
  // We can't currently use the steps variable since that gets re-generated
  // when we navigate away to Stripe and back again. Maybe we can cache the navigation state.
  const confirmationChecklist = [
    'Your account has been created.',
    "We've created your first program.",
    "You've connected a card for billing.",
  ];

  const error = completeOnboardingError || authedProviderUserError;

  return (
    <div className="flex flex-col items-center justify-center py-8">
      <img src={starIllustration} className="mb-1 h-[64px] w-[68px]" alt="" />
      <h1 className="text-subtitle">You're all set up</h1>
      <div className="my-6 flex w-[375px] flex-col items-start justify-center rounded-lg bg-neutral-50 px-4 py-3">
        {confirmationChecklist.map((checklistItem, checklistItemIndex) => {
          return (
            <ChecklistItem key={checklistItemIndex} text={checklistItem} />
          );
        })}
      </div>
      <h1 className="mt-4 mb-4 text-subtitle-small">
        Here's what happens next
      </h1>
      <div className="mb-8 w-[375px] text-caption">
        We’ll take you to your <span className="font-bold">Dashboard</span>,
        where you can add your clients. Then, you'll assign your clients to
        their programs and invite them to begin.
      </div>

      {error && (
        <ErrorMessage className="mb-4">
          <>error</>
        </ErrorMessage>
      )}

      <Button
        title="Ok, let's go"
        IconComponent={ArrowRightLong}
        onClick={onConfirm}
        disabled={isCompleteOnboardingLoading || isAuthedProviderUserLoading}
      />
    </div>
  );
}
