import { FC } from 'react';
import classNames from 'classnames';
import Button from '../../../../../components/Button';
import { PillOptions } from '..';

const widthMap: Record<number, string> = {
  1: 'w-full',
  2: 'w-1/2',
  3: 'w-1/3',
  4: 'w-1/4',
  5: 'w-1/5',
  6: 'w-1/6',
};

const Pill: FC<{
  pillOption: PillOptions;
  activePill: PillOptions;
  pillCount: number;
  isLastPill?: boolean;
  setActivePill: React.Dispatch<React.SetStateAction<PillOptions>>;
}> = ({
  pillOption,
  activePill,
  pillCount,
  setActivePill,
  isLastPill = false,
}) => (
  <Button
    title={pillOption}
    className={classNames(
      'h-7 rounded-[6px] text-small-caption text-green-150',
      widthMap[pillCount],
      !isLastPill && 'mr-1',
      activePill === pillOption &&
        'bg-neutral-25 font-bold hover:bg-neutral-25',
      activePill !== pillOption &&
        'bg-neutral-100 font-normal hover:bg-neutral-50',
    )}
    size="small"
    theme="secondary"
    onClick={() => setActivePill(pillOption)}
  />
);

export default Pill;
