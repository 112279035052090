import { Maybe, MeProviderQuery } from '../../generated/graphql';
import { OnboardingStep, OnboardingStepName } from '../types/onboarding';
import { getOnboardingStepCompleted } from './storage';

/** Return whether onboarding has been completed. */
export function getHasCompletedOnboarding(
  meProvider: Maybe<MeProviderQuery['meProvider']>,
) {
  return Boolean(meProvider?.onboardingCompletedAt);
}

/** Generate a list of onboarding steps based on the authedProviderUser's state. */
export function getOnboardingSteps(
  meProvider: Maybe<MeProviderQuery['meProvider']>,
): OnboardingStep[] {
  return [
    {
      name: OnboardingStepName.Members,
      getIsComplete: (meProvider?: MeProviderQuery['meProvider'] | null) => {
        const usedStaffSeats = meProvider?.provider.usedStaffSeats;
        const staffSeats = meProvider?.provider.staffSeats;
        if (
          usedStaffSeats != null &&
          staffSeats != null &&
          usedStaffSeats >= staffSeats
        ) {
          return true;
        }

        return meProvider
          ? getOnboardingStepCompleted(
              meProvider.email,
              OnboardingStepName.Members,
            )
          : false;
      },
    },
    {
      name: OnboardingStepName.Programs,
      getIsComplete: (meProvider?: MeProviderQuery['meProvider'] | null) =>
        meProvider
          ? getOnboardingStepCompleted(
              meProvider.email,
              OnboardingStepName.Programs,
            )
          : false,
    },
    {
      name: OnboardingStepName.Billing,
      getIsComplete: (meProvider?: MeProviderQuery['meProvider'] | null) =>
        Boolean(meProvider?.isProviderSubscribed),
    },
    {
      name: OnboardingStepName.Confirmation,
      getIsComplete: (meProvider?: MeProviderQuery['meProvider'] | null) =>
        Boolean(meProvider?.onboardingCompletedAt),
    },
    // Filter out already-completed steps.
  ].filter((step) => !step.getIsComplete?.(meProvider));
}
