import { ColumnHeaderType, ColumnOrder } from '../../types/tables';

import { ProviderActivityType } from '../../types/activity';

import {
  ProviderActivityObject,
  getProviderActivityData,
} from '../../lib/providerActivity';
import { ActivityDataFragment, ActivityType } from '../../../generated/graphql';
import {
  ProviderCollection,
  ProviderCollections,
} from '../../types/providerCollection';
import { isAssessmentAForm } from '../../lib/assessments';
import { ContentType, activityTypeToContentType } from '../../lib/followUp';

export const sortOptions: Record<string, ColumnOrder> = {
  newestCreated: { name: 'newestCreated', isAscending: true },
  oldestCreated: { name: 'oldestCreated', isDescending: true },
  titleAlphabeticalAscending: {
    name: 'titleAlphabeticalAscending',
    isAscending: true,
  },
  titleAlphabeticalDescending: {
    name: 'titleAlphabeticalDescending',
    isDescending: true,
  },
};

export const PROVIDER_ACTIVITY_ORDERS: Record<string, ColumnOrder[]> = {
  activity: [
    sortOptions.titleAlphabeticalAscending,
    sortOptions.titleAlphabeticalDescending,
    sortOptions.newestCreated,
  ],
  dateAdded: [sortOptions.oldestCreated, sortOptions.newestCreated],
};

const columnHeaderOptions: Record<string, ColumnHeaderType> = {
  activity: {
    field: 'activity',
    headerName: 'Resource',
    isSortable: true,
    columnOrders: [
      sortOptions.titleAlphabeticalAscending,
      sortOptions.titleAlphabeticalDescending,
      sortOptions.newestCreated,
    ],
  },
  dateAdded: {
    field: 'dateAdded',
    headerName: 'Date added',
    isSortable: true,
    columnOrders: [sortOptions.oldestCreated, sortOptions.newestCreated],
  },
  activityActions: {
    field: 'activityActions',
    headerName: '',
    isSortable: false,
  },
};

export const columnHeaders: Record<string, ColumnHeaderType[]> = {
  activity: [
    columnHeaderOptions.activity,
    columnHeaderOptions.dateAdded,
    columnHeaderOptions.activityActions,
  ],
};

const getProviderActivityTypeData = (
  providerActivity: ActivityDataFragment,
) => {
  const { createdAt, activityTitle } =
    getProviderActivityData(providerActivity);

  return {
    createdAt,
    activityTitle: activityTitle?.toLowerCase() ?? '',
  };
};

export const emptyFilterState = {
  [ActivityType.Link]: false,
  [ActivityType.Image]: false,
  [ActivityType.Pdf]: false,
  [ActivityType.Audio]: false,
  Form: false,
  [ActivityType.Assessment]: false,
  [ActivityType.Text]: false,
};

export const sortActivities = (
  activities: ActivityDataFragment[] | undefined,
  activityOrder: ColumnOrder,
) => {
  if (activities) {
    return activities.sort(
      (activityA: ActivityDataFragment, activityB: ActivityDataFragment) => {
        const activityAData = getProviderActivityTypeData(activityA);
        const activityBData = getProviderActivityTypeData(activityB);

        switch (activityOrder) {
          case sortOptions.oldestCreated:
            return activityAData.createdAt < activityBData.createdAt ? -1 : 1;
          case sortOptions.newestCreated:
            return activityAData.createdAt >= activityBData.createdAt ? -1 : 1;

          case sortOptions.titleAlphabeticalAscending:
            return activityAData.activityTitle < activityBData.activityTitle
              ? -1
              : 1;
          case sortOptions.titleAlphabeticalDescending:
            return activityAData.activityTitle >= activityBData.activityTitle
              ? -1
              : 1;

          default:
            return activityAData.createdAt >= activityBData.createdAt ? -1 : 1;
        }
      },
    );
  }
  return [];
};

export const sortCollections = (
  collections: ProviderCollections | undefined,
  order: ColumnOrder,
) => {
  if (collections) {
    return collections.sort(
      (collectionA: ProviderCollection, collectionB: ProviderCollection) => {
        const nameA = collectionA.name.toLowerCase();
        const nameB = collectionB.name.toLowerCase();
        switch (order) {
          case sortOptions.oldestCreated:
            return collectionA.createdAt < collectionB.createdAt ? -1 : 1;
          case sortOptions.newestCreated:
            return collectionA.createdAt >= collectionB.createdAt ? -1 : 1;

          case sortOptions.titleAlphabeticalAscending:
            return nameA < nameB ? -1 : 1;
          case sortOptions.titleAlphabeticalDescending:
            return nameA >= nameB ? -1 : 1;

          default:
            return collectionA.createdAt >= collectionB.createdAt ? -1 : 1;
        }
      },
    );
  }
  return [];
};

export const getSelectedProviderActivitiesList = (
  selectedProviderActivitiesObject: ProviderActivityObject,
) => Object.values(selectedProviderActivitiesObject);

export const contentTypeFilterValues = (showActionField = false) => {
  return Object.values(ContentType)
    .map((val) => val as ContentType)
    .filter(
      (type) =>
        // PdfForm and Esign are excluded b/c they are filtered alongside PDF as "Files"
        ![
          !showActionField && ContentType.Action,
          ContentType.PdfForm,
          ContentType.Esign,
        ].includes(type),
    );
};

interface Filters {
  selectedProviderCollection: ProviderCollection | undefined;
  searchTerm: string;
  contentTypeFilter?: ContentType | null;
}

export const doesCollectionMeetFilters = (
  providerCollection: ProviderCollection,
  filters: Filters,
): boolean => {
  const { searchTerm, contentTypeFilter, selectedProviderCollection } = filters;

  if (selectedProviderCollection || contentTypeFilter) {
    return false;
  }

  if (searchTerm) {
    return providerCollection.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  }

  return true;
};

export const doesActivityMeetFilters = (
  providerActivity: ActivityDataFragment,
  filters: Filters,
): boolean => {
  let providerActivityTitle = '';
  let providerActivityURL = '';

  const { contentTypeFilter, searchTerm, selectedProviderCollection } = filters;

  // Collection view
  if (
    selectedProviderCollection &&
    !providerActivity.providerCollectionIds.includes(
      selectedProviderCollection.id,
    )
  ) {
    return false;
  }

  // Search
  const isAssessment =
    providerActivity.__typename === ProviderActivityType.Assessment;

  if (isAssessment) {
    const isForm = isAssessmentAForm(providerActivity?.assessment);

    providerActivityTitle = isForm
      ? providerActivity?.assessment?.name.toLowerCase()
      : providerActivity?.assessment?.shortName.toLowerCase();
  } else if (
    providerActivity.__typename === ProviderActivityType.Image ||
    providerActivity.__typename === ProviderActivityType.PDF ||
    providerActivity.__typename === ProviderActivityType.Link ||
    providerActivity.__typename === ProviderActivityType.Audio ||
    providerActivity.__typename === ProviderActivityType.Text
  ) {
    providerActivityTitle = providerActivity?.title.toLowerCase();
  }

  if (providerActivity.__typename === ProviderActivityType.Link)
    providerActivityURL = providerActivity?.url;

  if (
    !providerActivityTitle.includes(searchTerm) &&
    !providerActivityURL.includes(searchTerm)
  ) {
    return false;
  }

  // Type filter
  if (contentTypeFilter) {
    const providerActivityContentType = activityTypeToContentType(
      providerActivity.activityType,
      isAssessment ? providerActivity.assessment?.schemaType : undefined,
    );
    if (contentTypeFilter !== providerActivityContentType) {
      return false;
    }
  }

  // Default view
  if (
    !selectedProviderCollection &&
    providerActivity.providerCollectionIds.length !== 0
  ) {
    return false;
  }

  return true;
};
