import { DateTime, DateTimeFormatOptions, Duration } from 'luxon';

const DAY_MONTH_FORMAT: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
};

const DATE_FORMAT: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

const DATE_WEEK_FORMAT: DateTimeFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const TIME_FORMAT: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit',
};

export const USER_LOCAL_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getYYYYMMDDTimeStamp = (timestamp: Date) => {
  return DateTime.fromJSDate(timestamp).toFormat('yyyy-MM-dd');
};

export const getTimeStamp = (
  timestamp: string,
  includesTime = true,
  relativeTime = false,
  includesYear = true,
  includesWeek = false,
) => {
  const dateTime = DateTime.fromISO(timestamp).setZone(USER_LOCAL_TIMEZONE);

  if (relativeTime) {
    const rawRelativeDate = dateTime
      .toRelative({ style: 'long' })
      ?.split('.')
      .join('');

    return rawRelativeDate ?? 'N/A';
  }

  const timeStampFormat = includesTime
    ? { ...DATE_FORMAT, ...TIME_FORMAT }
    : includesYear
    ? DATE_FORMAT
    : includesWeek
    ? DATE_WEEK_FORMAT
    : DAY_MONTH_FORMAT;

  return dateTime.toLocaleString(timeStampFormat);
};

export const getWeekdayDate = (timestamp: string) => {
  return DateTime.fromISO(timestamp)
    .setZone(USER_LOCAL_TIMEZONE)
    .toLocaleString({
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
};

export const getDurationMSS = (seconds?: number | null) => {
  if (!seconds) seconds = 0;
  return Duration.fromMillis(seconds * 1000).toFormat('m:ss');
};

export const getDurationSS = (seconds = 0) => {
  return Duration.fromMillis(seconds * 1000).toFormat('ss');
};

export const getRelativeTimestamp = (timestamp: string) =>
  DateTime.fromISO(timestamp).toRelative({ style: 'short' })?.replace('.', '');

export const isAfterDate = (date: Date, afterDate = new Date()) =>
  DateTime.fromJSDate(date) > DateTime.fromJSDate(afterDate);

export const daysUntil = (untilTimestamp: string) => {
  const now = DateTime.now();
  const untilDate = DateTime.fromISO(untilTimestamp);

  return Math.ceil(untilDate.diff(now, 'days').days);
};

export function formatCurrentDate(): string {
  const today = new Date();

  return today.toLocaleDateString('en-us', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}
