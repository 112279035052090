import toast from 'react-hot-toast';
import { FC, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import {
  AssessmentSchemaType,
  CompletedProgramAssessmentsDataQuery,
  LatestCompletedProgramAssessmentsQuery,
  useCompletedProgramAssessmentsDataLazyQuery,
} from '../../../../../generated/graphql';

import {
  AnalyticsComponent,
  AnalyticsPage,
  trackProviderComponentViewedEvent,
} from '../../../../../lib/analytics';

import { getTimeStamp } from '../../../../lib/time';
import { defaultTransitionProps } from '../../../../lib/animation';

import Slideover, {
  SLIDEOVER_TRANSITION_DURATION,
} from '../../../../components/Slideover';
import ToastAlert from '../../../../components/ToastAlert';
import SlideoverSection from '../../../../components/SlideoverSection';
import InterpretationSection from '../../../../components/Assessments/InterpretationSection';
import ScoreSection from '../../../../components/Assessments/ScoreSection';
import { getCompletedAssessmentGraphData } from '../../../../components/Assessments/helpers';
import { colors } from '../../../../lib/colors';
import { CustomAxisTick, CustomizedDot, CustomTooltip } from './CustomGraph';
import ResponsesSection from '../../../../components/Assessments/ResponsesSection';
import HistorySection from '../../../../components/Assessments/HistorySection';

const AssessmentsCompletedSlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  patientFirstName?: string;
  programId: string;
  selectedAssessment:
    | LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number]['assessment']
    | undefined;
}> = ({ onClose, isOpen, patientFirstName, programId, selectedAssessment }) => {
  const [shownAssessmentQandAIndex, setShownAssessmentQandAIndex] = useState<
    number | null
  >(null);

  const assessmentQandAShown = Boolean(shownAssessmentQandAIndex !== null);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setShownAssessmentQandAIndex(null);
      }, SLIDEOVER_TRANSITION_DURATION);
    }
  }, [isOpen]);

  const [
    getCompletedProgramAssessmentsData,
    {
      data: completedProgramAssessmentsData,
      error: completedProgramAssessmentsError,
      loading: isLoadingCompletedProgramAssessments,
    },
  ] = useCompletedProgramAssessmentsDataLazyQuery();

  useEffect(() => {
    if (selectedAssessment) {
      getCompletedProgramAssessmentsData({
        variables: {
          programId,
          assessmentId: selectedAssessment.id,
        },
      });
      trackProviderComponentViewedEvent(
        AnalyticsComponent.AssessmentSlideover,
        AnalyticsPage.ClientProfile,
        {
          assessmentType: selectedAssessment.type,
        },
      );
    }
  }, [selectedAssessment]);

  useEffect(() => {
    if (completedProgramAssessmentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch completed assessments."
          level="error"
        />
      ));
    }
  }, [completedProgramAssessmentsError]);

  const completedProgramAssessments =
    completedProgramAssessmentsData?.completedProgramAssessmentsByAssessment as CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'];

  const assessmentTimestampTitle =
    shownAssessmentQandAIndex !== null
      ? getTimeStamp(
          completedProgramAssessments[shownAssessmentQandAIndex].completedAt,
          false,
        )
      : '';

  const { graphData, hasSecondaryScore, graphTitle } =
    getCompletedAssessmentGraphData(completedProgramAssessments);

  const isCustomAssessment =
    selectedAssessment?.schemaType === AssessmentSchemaType.Custom;

  const title = isCustomAssessment
    ? selectedAssessment.name
    : selectedAssessment?.shortName ?? '';

  const subtitle = assessmentQandAShown
    ? assessmentTimestampTitle
    : !isCustomAssessment
    ? `${patientFirstName}'s ${selectedAssessment?.name}`
    : undefined;

  return (
    <Slideover
      isOpen={isOpen}
      fetching={isLoadingCompletedProgramAssessments}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      backButtonActive={assessmentQandAShown}
      onBackButtonClick={() => setShownAssessmentQandAIndex(null)}
      showContent={
        !isLoadingCompletedProgramAssessments &&
        !completedProgramAssessmentsError &&
        Boolean(completedProgramAssessmentsData)
      }
    >
      <Transition
        show={shownAssessmentQandAIndex === null}
        {...defaultTransitionProps}
      >
        {!isCustomAssessment && (
          <SlideoverSection
            title={graphTitle}
            className="h-80 pl-0"
            titleClassName="px-6"
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={graphData}
                margin={{ top: 5, left: -8, right: 15, bottom: 5 }}
                width={3}
                height={4}
              >
                <CartesianGrid stroke={colors.neutral[50]} vertical={false} />
                <XAxis
                  dataKey="timestamp"
                  tickLine={false}
                  axisLine={false}
                  tick={<CustomAxisTick isXAxis />}
                  dy={10}
                />
                <YAxis
                  dx={-20}
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomAxisTick isYAxis />}
                  domain={[
                    completedProgramAssessmentsData?.assessment?.scoreRange
                      ?.min ?? 0,
                    completedProgramAssessmentsData?.assessment?.scoreRange
                      ?.max ?? 100,
                  ]}
                />
                <Line
                  type="linear"
                  dataKey="primaryScore"
                  stroke={colors.neutral[100]}
                  dot={<CustomizedDot />}
                  activeDot={
                    <CustomizedDot
                      isActiveDot
                      setShownAssessmentQandAIndex={
                        setShownAssessmentQandAIndex
                      }
                    />
                  }
                  isAnimationActive={false}
                />
                {hasSecondaryScore && (
                  <Line
                    type="linear"
                    dataKey="secondaryScore"
                    stroke={colors.neutral[100]}
                    strokeDasharray="4 4"
                    dot={<CustomizedDot isSecondaryScore />}
                    activeDot={
                      <CustomizedDot
                        isActiveDot
                        setShownAssessmentQandAIndex={
                          setShownAssessmentQandAIndex
                        }
                        isSecondaryScore
                      />
                    }
                    isAnimationActive={false}
                  />
                )}
                <Tooltip cursor={false} content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </SlideoverSection>
        )}
        {!isCustomAssessment && (
          <InterpretationSection
            assessment={completedProgramAssessmentsData?.assessment}
          />
        )}
        <HistorySection
          completedProgramAssessments={completedProgramAssessments}
          assessmentType={selectedAssessment?.type}
          assessmentSchemaType={selectedAssessment?.schemaType}
          setShownAssessmentQandAIndex={setShownAssessmentQandAIndex}
        />
        <SlideoverSection
          title="About"
          textContent={completedProgramAssessmentsData?.assessment?.description}
        />
      </Transition>
      <Transition show={assessmentQandAShown} {...defaultTransitionProps}>
        {shownAssessmentQandAIndex !== null && (
          <>
            {!isCustomAssessment && (
              <ScoreSection
                assessmentType={selectedAssessment?.type}
                title="Score"
                completedAssessment={
                  completedProgramAssessments[shownAssessmentQandAIndex]
                }
              />
            )}
            {Boolean(
              completedProgramAssessments[shownAssessmentQandAIndex]
                .additionalThoughts,
            ) && (
              <SlideoverSection
                title={`Notes from ${patientFirstName}`}
                textContent={
                  `"${completedProgramAssessments[shownAssessmentQandAIndex].additionalThoughts}"` ??
                  undefined
                }
              />
            )}
            <ResponsesSection
              title="Responses"
              questionFrame={
                completedProgramAssessmentsData?.assessment?.questionFrame ??
                undefined
              }
              answers={
                completedProgramAssessments[shownAssessmentQandAIndex].answers
              }
            />
            <SlideoverSection
              title="About"
              textContent={
                completedProgramAssessmentsData?.assessment?.description
              }
            />
          </>
        )}
      </Transition>
    </Slideover>
  );
};

export default AssessmentsCompletedSlideover;
