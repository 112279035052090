import { SvgIconComponent } from '../types/svgs';

const Pin: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <path
        d="M5.33333 8.66725L1 13.0006M2.33333 5.32392L8.462 11.4526L9.59333 10.3213L9.33133 7.79658L13.0047 4.68125L9.10467 0.78125L5.98867 4.45458L3.46467 4.19258L2.33333 5.32392Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pin;
