import { FC } from 'react';
import Play from './Play';
import Pause from './Pause';
import classNames from 'classnames';

const PlayPauseButton: FC<{
  isPlayable: boolean;
  isPlaying: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ isPlayable, isPlaying, onClick }) => {
  const buttonStyle = classNames(
    'h-[32px] w-[32px]',
    isPlayable && 'text-neutral-125 hover:text-neutral-150',
    !isPlayable && 'text-neutral-100',
  );
  return (
    <button
      disabled={!isPlayable}
      className="mr-4 focus:outline-none focus:ring-0"
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
    >
      {!isPlaying && <Play className={buttonStyle} />}
      {isPlaying && <Pause className={buttonStyle} />}
    </button>
  );
};

export default PlayPauseButton;
