import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Transition } from '@headlessui/react';

import { useAuth } from '../contexts/AuthContext';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';

import { defaultTransitionProps } from '../v2/lib/animation';

import { GlobalModalsProvider } from '../contexts/GlobalModalsContext';
import IsMobileDeviceScreen from '../v2/components/IsMobileDeviceScreen';
import MainHeader from '../v2/components/Headers/MainHeader';
import ImportStatusToast from '../v2/components/ScheduledEvents/ImportStatusToast';
import Loading from '../v2/pages/Loading';
import useIsMobileDevice from '../v2/hooks/useIsMobileDevice';
import classNames from 'classnames';

const HIDE_HEADER_ROUTES = [
  '/billing/updated',
  '/billing/success',
  '/logout',
  '/intro',
  '/stripe-checkout',
];

function AuthedContainerV2() {
  const location = useLocation();

  const {
    auth0AccessToken,
    authedProviderUser,
    setHasInitialLoadError,
    refreshAuthedProviderUser,
    logout,
    showUpgradeBanner,
  } = useAuth();

  const isMobileDevice = useIsMobileDevice();

  const loadInitialAuthedProviderUser = async () => {
    const response = await refreshAuthedProviderUser();
    if (response.error && response.error.message !== 'Invalid bearer token') {
      // Don't show an error message for invalid token errors.
      setHasInitialLoadError(true);
      await logout();
    }
  };

  useEffect(() => {
    if (auth0AccessToken && !authedProviderUser) {
      // This will occur upon a page refresh.
      loadInitialAuthedProviderUser();
    }
  }, [auth0AccessToken, authedProviderUser]);

  if (!auth0AccessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hideHeader = HIDE_HEADER_ROUTES.includes(location.pathname);

  if (isMobileDevice) {
    return <IsMobileDeviceScreen />;
  }

  return (
    <FeatureFlagProvider>
      <GlobalModalsProvider>
        {/* Child pages will not be mounted until authedProviderUser is defined. */}
        <Transition
          show={Boolean(authedProviderUser)}
          {...defaultTransitionProps}
          className="relative flex min-h-screen flex-col"
        >
          {!hideHeader && <MainHeader />}

          <main
            className={classNames(
              !hideHeader
                ? showUpgradeBanner
                  ? 'mt-[calc(var(--top-nav-height)+var(--upgrade-banner-height))]'
                  : 'mt-[var(--top-nav-height)]'
                : '',
            )}
          >
            <Outlet />
          </main>

          <ImportStatusToast />
        </Transition>

        {!authedProviderUser && <Loading />}
      </GlobalModalsProvider>
    </FeatureFlagProvider>
  );
}

export default AuthedContainerV2;
