import { useAuth } from '../../contexts/AuthContext';
import { FC, useEffect } from 'react';

const Logout: FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    const logoutAndRedirect = async () => {
      try {
        // Underneath the hood, Auth0 will redirect to app root
        await logout();
      } catch (e) {
        console.error(e);
      }
    };
    logoutAndRedirect();
  }, []); // eslint-disable-line

  return null;
};

export default Logout;
