import { FC, Fragment } from 'react';
import classNames from 'classnames';

import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';

import MenuButton from '../MenuButton';
import IconButton from '../IconButton';
import Trash from '../../svgs/Trash';
import { useDeleteCommentMutation } from '../../../generated/graphql';
import { defaultMenuTransitionProps } from '../../lib/animation';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';

const CommentActionsMenu: FC<{
  commentId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCommentDeleted?: (commentId?: string) => void;
  className?: string;
}> = ({ commentId, isOpen, setIsOpen, onCommentDeleted, className }) => {
  const [deleteCommentMutation, { loading: deleteCommentMutationLoading }] =
    useDeleteCommentMutation();

  const onClickDelete = async () => {
    setIsOpen(false);
    try {
      await deleteCommentMutation({
        variables: {
          commentId,
        },
      });
      onCommentDeleted?.(commentId);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="success"
          message="Comment deleted."
        />
      ));
    } catch (err) {
      console.error('Errors deleting comment:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to delete comment."
          level="error"
        />
      ));
    }
  };

  return (
    <Menu as="div" className={classNames('relative', className)}>
      <Menu.Button as={Fragment}>
        <IconButton
          aria-label="Comment actions"
          IconComponent={DotsHorizontalIcon}
          className="h-6 w-6 hover:bg-neutral-50"
          onClick={(e) => {
            setIsOpen(!isOpen);
          }}
        />
      </Menu.Button>
      <Transition show={isOpen} as={Fragment} {...defaultMenuTransitionProps}>
        <Menu.Items
          className="absolute right-0 z-30 mt-2 w-64 divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none"
          static
        >
          <MenuButton
            titleText="Delete"
            iconClassName="text-neutral-125 h-4 w-4"
            className="rounded-md"
            IconComponent={Trash}
            disabled={deleteCommentMutationLoading}
            onClick={(e) => {
              onClickDelete();
            }}
          />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CommentActionsMenu;
