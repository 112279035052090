import { FC } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline';

import { UpcomingScheduledEventFragment } from '../../generated/graphql';

import { displayTime } from '../lib/copy';
import { getTimeStamp } from '../lib/time';

import {
  ConferenceLocationTypeMap,
  getFirstInviteeData,
  isConferenceLocation,
  isNonConferenceLocation,
} from '../lib/scheduled-events';

import Button from './Button';
import Avatar from './Avatar';
import Linkifier from './Linkifier';

const UpcomingEvent: FC<{
  upcomingEvent?: UpcomingScheduledEventFragment;
  isDemo?: boolean;
}> = ({ upcomingEvent, isDemo = false }) => {
  const navigate = useNavigate();
  const startTimeFormatted = DateTime.fromISO(
    upcomingEvent?.startTime as string,
  );
  const endTimeFormatted = DateTime.fromISO(upcomingEvent?.endTime as string);

  const invitee = getFirstInviteeData(upcomingEvent);

  const conferenceLocationJoinUrl =
    upcomingEvent?.location &&
    isConferenceLocation(upcomingEvent?.location) &&
    upcomingEvent?.location.joinUrl;

  const nonConferenceLocation =
    upcomingEvent?.location &&
    isNonConferenceLocation(upcomingEvent?.location) &&
    upcomingEvent?.location.location;

  return (
    <div
      className={classNames(
        'flex w-[354px] flex-col items-start justify-start truncate rounded-lg border border-neutral-75 p-6 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
        {
          'cursor-pointer opacity-50': isDemo,
        },
      )}
      onClick={() => {
        if (isDemo) {
          navigate('/integrations/calendly');
        }
      }}
    >
      <div className="mb-4 flex flex-row items-center justify-start">
        <Avatar
          imageUrl={invitee.profileImageUrl}
          name={invitee.fullName}
          size="medium-plus"
        />
        <div className="ml-4 flex flex-col items-start justify-start">
          <div className="text-category font-medium text-neutral-150">
            {invitee.fullName}
          </div>
          <div className="text-caption text-neutral-125">
            {upcomingEvent?.name}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start truncate">
        <CalendarIcon className="mr-5 h-5 w-5 text-neutral-150" />
        <div className="truncate text-caption font-medium text-neutral-150">
          {`${getTimeStamp(
            upcomingEvent?.startTime as string,
            false,
          )} at ${displayTime(startTimeFormatted, 'h:mm')}–${displayTime(
            endTimeFormatted,
            't',
          )}`}
        </div>
      </div>
      {(conferenceLocationJoinUrl || nonConferenceLocation) && (
        <div className="mt-2 flex w-full flex-row items-start justify-start truncate">
          <LocationMarkerIcon className="mr-5 h-5 w-5 min-w-[1.25rem] text-neutral-150" />
          <div className="truncate text-caption font-medium text-neutral-150">
            {conferenceLocationJoinUrl && (
              <Button
                title={`Link to ${
                  ConferenceLocationTypeMap[upcomingEvent?.location.type]
                } meeting`}
                theme="alt"
                size="small"
                onClick={(e) => {
                  window.open(conferenceLocationJoinUrl, '_blank');
                  e.stopPropagation();
                }}
                className="px-0 py-0 text-blue-125 hover:text-blue-150"
                noFocus
              />
            )}
            {nonConferenceLocation && (
              <div className="w-full truncate text-caption text-neutral-150">
                <Linkifier text={nonConferenceLocation} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvent;
