import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import toast from 'react-hot-toast';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FormProvider,
} from 'react-hook-form';

import {
  ActivityPdf,
  ActivityFileCompletionType,
  useActivityQuery,
  ActivityType,
  useEditActivityFileSettingsMutation,
  ActivityDataFragment,
} from '../../../../generated/graphql';

import ThumbnailPDF from '../../../svgs/ThumbnailPDF';

import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';

import Button from '../../Button';
import ToastAlert from '../../ToastAlert';
import Spinner from '../../../svgs/Spinner';

import { defaultEnterTransitionProps } from '../../../../v1/lib/animation';
import { Transition } from '@headlessui/react';
import ThumbnailPdfForm from '../../../svgs/ThumbnailPdfForm';
import ThumbnailPDFESign from '../../../svgs/ThumbnailPDFESign';
import FileCompletionSettings from './FileCompletionSettings';

export interface FileSettingsModalFormData {
  completionType: ActivityFileCompletionType;
}

const FileSettingsModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  // Either activityPdf or activityPdfId is required
  activityPdf?: ActivityPdf;
  activityPdfId?: string;
  onUpdated?: (updatedActivity: ActivityDataFragment) => void;
  followUpMode?: boolean;
}> = ({
  isModalOpen,
  setClosed,
  activityPdf,
  activityPdfId,
  onUpdated,
  followUpMode = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: activityPdfData, loading: loadingActivityPdfData } =
    useActivityQuery({
      variables: {
        activityId: activityPdfId,
        activityType: ActivityType.Pdf,
      },
      skip: !activityPdfId,
    });

  const selectedActivityPdf =
    activityPdf || (activityPdfData?.activity as ActivityPdf) || null;

  const isActuallyPdfFile = selectedActivityPdf?.media.isPdf;
  const isFilePdfEditable = selectedActivityPdf?.media.isPdf
    ? selectedActivityPdf?.media.isPdfEditable
    : null;
  const isDocumentFile = selectedActivityPdf?.media.isDocument;

  const [editActivityFileSettingsMutation] =
    useEditActivityFileSettingsMutation();

  const fileSettingsModalForm = useForm<FileSettingsModalFormData>({
    mode: 'onChange',
    defaultValues: {
      completionType: ActivityFileCompletionType.View,
    },
  });

  const watchCompletionType = fileSettingsModalForm.watch('completionType');

  const updateFileSettings = (selectedActivityPdf: ActivityPdf) => {
    fileSettingsModalForm.setValue(
      'completionType',
      selectedActivityPdf.completionType,
    );
  };

  // Set the completion type every time the selectedActivityPdf changes
  useEffect(() => {
    if (selectedActivityPdf) {
      updateFileSettings(selectedActivityPdf);
    }
  }, [selectedActivityPdf]);

  const onSubmit: SubmitHandler<FileSettingsModalFormData> = async (
    formData,
  ) => {
    setIsSubmitting(true);

    try {
      const editActivityFileSettingsInput = {
        activityId: selectedActivityPdf?.id,
        activityType: ActivityType.Pdf,
        completionType: formData.completionType,
      };

      const { data } = await editActivityFileSettingsMutation({
        variables: { input: editActivityFileSettingsInput },
      });

      const updatedActivity = data?.editActivityFileSettings;

      if (updatedActivity) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={'Successfully changed file settings'}
          />
        ));
        // Allow unhandled promise to not block the UI
        onUpdated?.(updatedActivity);
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={(err as Error).message}
          level="error"
        />
      ));
    } finally {
      closeAndResetModal();
      setIsSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<FileSettingsModalFormData> = (
    errors,
  ) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetModal = async () => {
    setClosed();
    setTimeout(() => {
      // Reset the form to the selected activity's completion type on close
      if (selectedActivityPdf.id !== activityPdf.id) {
        updateFileSettings(selectedActivityPdf);
      }
      setIsSubmitting(false);
    }, MODAL_TRANSITION_DURATION);
  };

  const ActivityThumbnail =
    watchCompletionType === ActivityFileCompletionType.Upload
      ? ThumbnailPdfForm
      : watchCompletionType === ActivityFileCompletionType.Esign
      ? ThumbnailPDFESign
      : ThumbnailPDF;

  return (
    <Modal
      name="EditActivityFileCompletionType"
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="small"
      overflow="auto"
    >
      <Transition
        show={Boolean(selectedActivityPdf || loadingActivityPdfData)}
        {...defaultEnterTransitionProps}
      >
        <div className="px-10">
          <div className="mb-6 flex w-full flex-col items-center justify-center">
            <div className="font-serif text-subtitle text-green-150">
              Modify file settings
            </div>
            <div className="text-caption text-green-125">
              Adjust what you want the client to do with this file
            </div>
          </div>

          <form className="flex w-full flex-col items-start justify-start">
            <>
              <div
                className={classNames(
                  'flex h-[68px] w-[460px] items-center justify-center rounded-md bg-neutral-25 text-caption text-neutral-125 transition-colors',
                )}
              >
                <div className="flex w-full flex-row items-start justify-between p-4">
                  <div className="flex w-11/12 flex-row items-center justify-start">
                    <ActivityThumbnail className="mr-4 h-[42px] w-[42px] rounded-md" />
                    <div className="flex w-[calc(100%-58px)] flex-col items-start justify-center">
                      <span className="w-full text-caption font-medium text-green-150 line-clamp-2">
                        {selectedActivityPdf?.title}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>

            <FormProvider {...fileSettingsModalForm}>
              <FileCompletionSettings
                watchCompletionType={watchCompletionType}
                isFilePdfEditable={isFilePdfEditable}
                isDocumentFile={isDocumentFile}
                isActuallyPdfFile={isActuallyPdfFile}
              />
            </FormProvider>

            <div className="mb-8 mt-10 flex w-full flex-row justify-center">
              <Button
                title={!isSubmitting ? 'Save changes' : 'Saving...'}
                onClick={fileSettingsModalForm.handleSubmit(
                  onSubmit,
                  handleErrors,
                )}
                {...(isSubmitting && {
                  IconComponent: Spinner,
                  iconClassName: 'h-[16px] w-[16px]',
                })}
                disabled={Boolean(isSubmitting)}
              />
            </div>
          </form>
        </div>
      </Transition>
    </Modal>
  );
};

export default FileSettingsModal;
