import React from 'react';
import classNames from 'classnames';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';

interface AvatarUser {
  id: string;
  name: string;
  profileImageMediaUrl?: string;
}

interface AvatarListProps {
  users: AvatarUser[];
  maxDisplayed?: number;
}

const AvatarTooltipUsers: React.FC<{ users: AvatarUser[] }> = ({ users }) => {
  return (
    <div className="flex flex-col gap-y-3">
      {users.map((user) => (
        <AvatarTooltipUser key={user.id} user={user} />
      ))}
    </div>
  );
};

const AvatarTooltipUser: React.FC<{ user: AvatarUser }> = ({ user }) => {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <Avatar
        size="small"
        name={user.name}
        imageUrl={user.profileImageMediaUrl}
      />
      <div className="text-caption text-neutral-150">{user.name}</div>
    </div>
  );
};

const AvatarList: React.FC<AvatarListProps> = ({ users, maxDisplayed = 3 }) => {
  const userCount = users.length;
  const visibleUsers = users.slice(0, maxDisplayed);
  const hiddenUsers = users.slice(maxDisplayed);
  const showCounter = userCount > maxDisplayed;

  return (
    <div className="flex items-center">
      {visibleUsers.map((user, index) => (
        <Tooltip
          key={`avatarListUser_${user.id}`}
          content={<AvatarTooltipUser user={user} />}
          placement="bottom"
          showArrow
        >
          <Avatar
            size="medium-plus"
            name={user.name}
            imageUrl={user.profileImageMediaUrl}
            className={classNames(
              'border-[2.5px] border-white',
              index !== 0 && '-ml-2',
            )}
            textClassName="bg-neutral-50 text-neutral-125"
            style={{ zIndex: 10 + index }}
          />
        </Tooltip>
      ))}
      {showCounter && (
        <Tooltip
          content={<AvatarTooltipUsers users={hiddenUsers} />}
          placement="bottom"
          showArrow
        >
          <Avatar
            size="medium-plus"
            name={`+${hiddenUsers.length}`}
            className={classNames('border-[2.5px] border-white', '-ml-2')}
            textClassName="bg-neutral-50 text-neutral-125 text-caption"
            useFullName
            style={{ zIndex: 10 + visibleUsers.length }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default AvatarList;
