import { DateTime, DateTimeFormatOptions } from 'luxon';

const DAY_MONTH_FORMAT: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
};

const DATE_FORMAT: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

const TIME_FORMAT: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit',
};

const USER_LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getYYYYMMDDTimeStamp = (timestamp: Date) => {
  return DateTime.fromJSDate(timestamp).toFormat('yyyy-MM-dd');
};

export const getTimeStamp = (
  timestamp: string,
  includesTime = true,
  relativeTime = false,
  includesYear = true,
) => {
  const dateTime = DateTime.fromISO(timestamp).setZone(USER_LOCAL_TIMEZONE);

  if (relativeTime) {
    const rawRelativeDate = dateTime
      .toRelative({ style: 'short' })
      ?.split('.')
      .join('');

    return rawRelativeDate ?? 'N/A';
  }

  const timeStampFormat = includesTime
    ? { ...DATE_FORMAT, ...TIME_FORMAT }
    : includesYear
    ? DATE_FORMAT
    : DAY_MONTH_FORMAT;

  return dateTime.toLocaleString(timeStampFormat);
};
