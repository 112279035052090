import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useInvitedProviderUserQuery } from '../../generated/graphql';

import Button from '../components/Button';
import ErrorMessage from '../components/ErrorMessage';
import InputGroup from '../components/InputGroup';
import PasswordSuggestions from '../components/PasswordSuggestions';
import { useAuth } from '../../contexts/AuthContext';
import { getPasswordErrorMessage } from '../lib/form-validators';
import { checkPasswordStrength } from '../lib/password-strength';
import { NAME_REGEX } from '../lib/regex';
import Logo from '../svgs/Logo';
import { HomecomingError, matchHomecomingError } from '../../lib/errors';
import { ApolloError } from '@apollo/client';

interface JoinSignupFormData {
  name: string;
  password: string;
}

const JoinSignup: React.FunctionComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('invite-code') || '';

  const [inviteCodeError, setInviteCodeError] = useState<string | null>(null);

  const providerUserInviteQuery = useInvitedProviderUserQuery({
    variables: { inviteCode: inviteCode },
    onError: (error) => {
      if (
        matchHomecomingError(
          error as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        setInviteCodeError(error.message);
      } else {
        navigate('/', { replace: true });
      }
    },
  });

  const email = providerUserInviteQuery.data?.invitedProviderUser.email || '';

  const [passwordStrengthResult, setPasswordStrengthResult] = useState<
    ZxcvbnResult | undefined
  >();
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors, isSubmitting },
    clearErrors,
  } = useForm<JoinSignupFormData>({
    reValidateMode: 'onChange',
  });

  const onSubmit = async (formData: JoinSignupFormData) => {
    try {
      clearErrors();
      setApiErrorMessage(null);
      await auth.inviteSignup(
        inviteCode,
        formData.name.trim(),
        formData.password,
      );

      navigate('/intro');
    } catch (err) {
      setApiErrorMessage((err as Error).message);
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center md:flex-row md:justify-start">
      <div className="flex flex-col items-center bg-white py-screen-5 md:max-w-[512px] md:flex-1 md:justify-center md:bg-neutral-25">
        <div className="flex max-w-[267px] flex-col items-center text-center md:items-start md:text-left">
          <div className="mb-8">
            <Logo className="h-[53px] w-[53px]" />
          </div>
          <div className="mb-4 font-serif text-title-medium font-light">
            Welcome to Homecoming
          </div>
          {inviteCodeError ? (
            <ErrorMessage className="mb-4 !text-big-category md:mb-48">
              {inviteCodeError}
            </ErrorMessage>
          ) : (
            <div className="mb-4 text-big-category md:mb-48">
              Enter your details to set up your account.
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center pb-screen-5 md:flex-1 md:justify-center md:pt-screen-5">
        {!inviteCodeError && (
          <form
            className="w-3/4 max-w-[488px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-10 w-full text-left">
              <InputGroup
                label="Your email address"
                type="email"
                autoComplete="username"
                inputSize="small"
                useNaturalLettering={true}
                containerClassName="mb-5"
                readOnly
                required
                value={email}
              />
              <InputGroup
                label="Your full name"
                placeholder="Your full name"
                autoComplete="name"
                inputSize="small"
                useNaturalLettering={true}
                containerClassName="mb-5"
                required
                errorMessage={validationErrors.name?.message}
                {...register('name', {
                  required: 'Name is required',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Please enter a valid name',
                  },
                })}
              />
              <InputGroup
                label="Create your password"
                inputType="password"
                inputSize="small"
                useNaturalLettering={true}
                containerClassName="mb-5"
                required
                errorMessage={validationErrors.password?.message}
                passwordStrengthScore={passwordStrengthResult?.score}
                {...register('password', {
                  validate: (password) => {
                    const passwordStrengthResult =
                      checkPasswordStrength(password);
                    setPasswordStrengthResult(passwordStrengthResult);

                    return getPasswordErrorMessage(passwordStrengthResult);
                  },
                })}
              />

              <PasswordSuggestions strengthResult={passwordStrengthResult} />
            </div>

            <ErrorMessage className="mb-4">{apiErrorMessage}</ErrorMessage>

            <Button
              title="Claim your account"
              className="mx-auto"
              disabled={isSubmitting || providerUserInviteQuery.loading}
              type="submit"
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default JoinSignup;
