import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useState } from 'react';
import Pencil from '../../svgs/Pencil';
import Plus from '../../svgs/Plus';
import ErrorMessage from '../ErrorMessage';

interface ImageUploadAreaProps {
  inputId: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onRemove: () => void;
  isReadOnly: boolean;
  imageUrl: Maybe<string>;
}

const ImageUploadArea: React.FC<ImageUploadAreaProps> = ({
  inputId,
  onChange,
  imageUrl,
  isReadOnly,
  onRemove,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isImageSet = Boolean(imageUrl);

  return (
    <div className="relative">
      {isImageSet && (
        <div
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          tabIndex={-1}
          className={classNames(
            'border-40 flex h-64 w-full items-center justify-center rounded-lg  bg-neutral-50/25 bg-cover bg-center',
            {
              'border-2': !isReadOnly,
              'border-green-50': isFocused,
              'border-white': !isFocused,
            },
          )}
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {!isReadOnly && isFocused && (
            <div className="absolute -right-20 flex justify-between rounded-full py-4 px-6 shadow-400">
              <div>
                <label htmlFor={inputId}>
                  <Pencil className="h-5 w-5 cursor-pointer text-neutral-125 hover:text-neutral-125/75" />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
      {!isImageSet && (
        <>
          <div className="focus:!ring-0' w-full flex-col items-center justify-center rounded-lg border border-dashed border-neutral-100 bg-neutral-50/25 p-6 text-center transition-colors hover:bg-green-25/50">
            <label className="h-full w-full cursor-pointer" htmlFor={inputId}>
              <div className="mb-4 flex w-full justify-center">
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-neutral-50">
                  <Plus className="h-8 w-8 text-green-100" />
                </div>
              </div>
              <div className="mb-4 text-caption text-green-150">
                Upload a header image
              </div>
              <div className="text-small-caption text-neutral-125">
                PNG or JPG (max 1600 x 800 px)
              </div>
            </label>
          </div>
          <div className="mt-2 flex justify-center">
            <ErrorMessage>Please select a header image</ErrorMessage>
          </div>
        </>
      )}
      <input
        id={inputId}
        type="file"
        className="hidden"
        accept=".jpg,.jpeg,.png"
        onChange={onChange}
        required
      />
    </div>
  );
};

export default ImageUploadArea;
