import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { defaultTransitionProps } from '../../lib/animation';
import Loading from '../../pages/Loading';

interface PageContainerProps {
  children?: React.ReactNode;
  containerClassName?: string;
  noPadding?: boolean;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  show?: boolean;
  loading?: boolean;
}

export default function PageContainer({
  children,
  containerClassName,
  noPadding = false,
  id,
  onClick,
  show = true,
  loading = false,
}: PageContainerProps) {
  return (
    <>
      {loading && <Loading />}
      <Transition
        as="div"
        id={id}
        className={classNames(
          'flex h-full w-full flex-grow flex-col',
          !noPadding && 'px-20 py-8',
          containerClassName,
        )}
        onClick={onClick}
        show={show}
        {...defaultTransitionProps}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {children}
      </Transition>
    </>
  );
}
