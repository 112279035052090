import { FC } from 'react';
import classNames from 'classnames';
import { ASSESSMENTS_COLOR_MAP } from '../../lib/colors';

const InlineScore: FC<{
  score?: number;
  categorization?: string;
  color?: string;
  hasPercentage?: boolean;
}> = ({ score, categorization, color = 'neutral', hasPercentage = false }) => {
  return (
    <div className="my-1 flex flex-row items-center justify-start">
      <div
        className={classNames(
          'mr-2.5 flex min-w-[36px] flex-row items-center justify-center rounded-full px-1 py-1 text-small-caption font-bold',
          ASSESSMENTS_COLOR_MAP?.[color]?.default ??
            ASSESSMENTS_COLOR_MAP.neutral.default,
        )}
      >
        {score}
        {hasPercentage && '%'}
      </div>
      <div className="text-caption font-medium text-neutral-150">
        {categorization}
      </div>
    </div>
  );
};

export default InlineScore;
