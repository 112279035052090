import { FC, useState } from 'react';

import {
  UseFormRegister,
  UseFormSetFocus,
  UseFieldArrayRemove,
  UseFieldArrayInsert,
  UseFormSetValue,
} from 'react-hook-form';

import DatePicker from 'react-datepicker';

import {
  PatientRowValues,
  PatientInputRowError,
  PatientRow,
} from '../../../../types/PatientManagement';

import {
  fullNameRegisterOptions,
  emailRegisterOptions,
} from '../../../../lib/register-options';

import InputGroup from '../../../../components/InputGroup';
import CloseX from '../../../../svgs/CloseX';
import IconButton from '../../../../components/IconButton';

type PatientInputFieldProps = PatientRow & { id: string };

const PatientInputRow: FC<{
  field?: PatientInputFieldProps;
  register: UseFormRegister<PatientRowValues>;
  rowIndex: number;
  hasManyFields: boolean;
  showValidation: boolean;
  errors?: PatientInputRowError;
  insert?: UseFieldArrayInsert<PatientRowValues, 'patientRows'> | undefined;
  remove: UseFieldArrayRemove;
  setFocus?: UseFormSetFocus<PatientRowValues>;
  setValue?: UseFormSetValue<PatientRowValues>;
  overflowContainerRef?: React.MutableRefObject<HTMLDivElement>;
  disabled: boolean;
}> = ({
  field,
  rowIndex,
  register,
  hasManyFields,
  showValidation = false,
  errors = undefined,
  insert,
  remove,
  setFocus,
  setValue,
  overflowContainerRef,
  disabled = false,
}) => {
  const nameRegister = register(
    `patientRows.${rowIndex}.name`,
    fullNameRegisterOptions,
  );

  const emailRegister = register(
    `patientRows.${rowIndex}.email`,
    emailRegisterOptions,
  );

  const firstAppointmentDateRegister = register(
    `patientRows.${rowIndex}.firstAppointmentDate`,
  );

  const [firstAppointmentDate, setFirstAppointmentDate] = useState<Date | null>(
    null,
  );

  const isNameFieldEmpty = field?.name === '';
  const isEmailFieldEmpty = field?.email === '';

  const overflowContainer = overflowContainerRef?.current;
  const hasScrollbar =
    overflowContainer &&
    overflowContainer.scrollHeight > overflowContainer.clientHeight;

  const removeRow = (rowIndex: number) => {
    setFirstAppointmentDate(null);
    remove(rowIndex);
  };

  const handleNameFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Backspace' &&
      isNameFieldEmpty &&
      isEmailFieldEmpty &&
      hasManyFields &&
      remove &&
      setFocus
    ) {
      remove([rowIndex]);
      setTimeout(() => {
        setFocus(`patientRows.${rowIndex - 1}.name`);
      }, 10); // TODO: use useEffect to get insert event better
    }
  };

  const handleEmailFieldKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Tab' && insert && setFocus) {
      insert(rowIndex + 1, {
        name: '',
        email: '',
        firstAppointmentDate: null,
      });

      if (hasScrollbar) {
        overflowContainerRef.current.scrollTop =
          overflowContainerRef.current.scrollHeight;
      }

      setTimeout(() => {
        setFocus(`patientRows.${rowIndex + 1}.name`);
      }, 1); // TODO: use useEffect to get insert event better
    }
    if (setFocus && e.key === 'Backspace' && isEmailFieldEmpty) {
      setFocus(`patientRows.${rowIndex}.name`);
    }
  };

  return (
    <div className="mb-4 flex h-full w-full flex-row justify-between">
      <InputGroup
        label="Name"
        required
        containerClassName="w-2/5"
        inputSize="small"
        labelHidden
        errorMessage={showValidation ? errors?.name?.message : null}
        errorLocation="right"
        onKeyDown={handleNameFieldKeyDown}
        disabled={disabled}
        {...nameRegister}
      />
      <div className="w-4" />
      <InputGroup
        label="Email"
        required
        containerClassName="w-2/5"
        inputSize="small"
        labelHidden
        errorMessage={showValidation ? errors?.email?.message : null}
        errorLocation="right"
        onKeyDown={handleEmailFieldKeyDown}
        disabled={disabled}
        {...emailRegister}
      />
      <div className="w-4" />
      <div className="w-1/5">
        <DatePicker
          selectsStart
          selected={firstAppointmentDate}
          startDate={firstAppointmentDate}
          previousMonthButtonLabel="←"
          nextMonthButtonLabel="→"
          popperClassName="mt-3"
          {...firstAppointmentDateRegister}
          onChange={(date: Date) => {
            if (date) {
              setFirstAppointmentDate(date);
              setValue?.(`patientRows.${rowIndex}.firstAppointmentDate`, date);
            }
          }}
        />
      </div>
      <IconButton
        IconComponent={CloseX}
        aria-label="Remove row"
        iconClassName="h-2.5 w-2.5"
        className="ml-1 p-3"
        disabled={!hasManyFields}
        onClick={() => removeRow(rowIndex)}
      />
    </div>
  );
};

export default PatientInputRow;
