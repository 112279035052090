import { OnboardingStepName } from '../types/onboarding';

const storeItem = (key: string, value: boolean | string): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

const retrieveItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
};

const clearItem = (key: string): void => {
  localStorage.removeItem(key);
};

const userIdKey = (userId: string, key: string) => `${userId}:${key}`;

const storeUserItem = (
  userId: string,
  key: string,
  value: boolean | string,
): void => {
  storeItem(userIdKey(userId, key), value);
};

const retrieveUserItem = <T>(userId: string, key: string): T | null => {
  return retrieveItem(userIdKey(userId, key));
};

const clearUserItem = (userId: string, key: string): void => {
  clearItem(userIdKey(userId, key));
};

export const markOnboardingStepCompleted = (
  userEmail: string,
  onboardingStep: OnboardingStepName,
): void => {
  storeUserItem(userEmail, onboardingStep, true);
};

export const getOnboardingStepCompleted = (
  userEmail: string,
  onboardingStep: OnboardingStepName,
): boolean => {
  return retrieveUserItem<boolean>(userEmail, onboardingStep) ?? false;
};

export const clearOnboardingStepCompleted = (
  userId: string,
  onboardingStep: OnboardingStepName,
): void => {
  clearUserItem(userId, onboardingStep);
};
