import { FC, useState } from 'react';
import { useProviderUserMembersV2Query } from '../../../../generated/graphql';
import { Transition } from '@headlessui/react';
import { defaultTransitionProps } from '../../../lib/animation';
import { PlusSmIcon } from '@heroicons/react/outline';
import Button from '../../../components/Button';
import AddTeamMembersModal from './Modals/AddTeamMembersModal';
import TeamMembersTable from './TeamMembersTable';
import ToastAlert from '../../../components/ToastAlert';
import toast from 'react-hot-toast';
import InputGroup from '../../../components/InputGroup';
import Search from '../../../svgs/Search';
import CloseX from '../../../svgs/CloseX';
import { useAuth } from '../../../../contexts/AuthContext';
import { AnalyticsPage } from '../../../../lib/analytics';
import { useGlobalModalsContext } from '../../../../contexts/GlobalModalsContext';

const TeamSection: FC = () => {
  const { showUpgradeModal } = useGlobalModalsContext();
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [searchTerm, setSearchTerm] = useState('');

  const [isAddTeamMembersModalOpen, setIsAddTeamMembersModalOpen] =
    useState(false);

  const {
    data: teamMembersData,
    loading: isLoadingTeamMembers,
    error: teamMembersError,
    refetch: refetchTeamMembers,
  } = useProviderUserMembersV2Query({
    onError(error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong getting your team members. Please try refreshing."
          level="error"
        />
      ));
    },
  });

  const teamMemberProviderUsers =
    teamMembersData?.providerUserMembers.filter(
      (providerUser) =>
        providerUser.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        providerUser.email.toLowerCase().includes(searchTerm.toLowerCase()),
    ) ?? [];

  const staffSeats = authedProviderUser?.provider?.staffSeats;
  const usedStaffSeats = teamMembersData?.providerUserMembers.filter(
    (providerUser) => !providerUser.deactivatedAt,
  ).length;

  const seatLimitReached = usedStaffSeats >= staffSeats;

  return (
    <>
      <Transition
        show={!isLoadingTeamMembers && !teamMembersError}
        {...defaultTransitionProps}
      >
        <div className="h-[80vh] overflow-auto pb-20">
          <div className="my-9 flex justify-between">
            <InputGroup
              labelHidden
              label="Search"
              placeholder="Search for a team member"
              className="h-[44px] w-80 rounded-full pl-12 disabled:cursor-not-allowed"
              containerClassName="mr-4"
              iconLeftClassName="text-neutral-125 ml-2"
              IconLeft={Search}
              IconRight={searchTerm.length > 0 ? CloseX : null}
              iconRightOnClick={() => {
                setSearchTerm('');
              }}
              iconRightClassName="text-neutral-125 mr-1"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <div className="flex items-center gap-x-5">
              {usedStaffSeats && staffSeats && (
                <div className="flex items-center gap-x-2">
                  <div className="text-small-caption text-neutral-110">
                    Available team seats
                  </div>
                  <div className="rounded-full bg-neutral-25 px-2 py-1 text-caption font-medium text-neutral-125">
                    {usedStaffSeats} / {staffSeats}
                  </div>
                </div>
              )}
              <Button
                title="Add Team Members"
                IconComponent={PlusSmIcon}
                iconPosition="right"
                theme="primary"
                onClick={() => {
                  if (seatLimitReached) {
                    showUpgradeModal(AnalyticsPage.SettingsTeam);
                    return;
                  }
                  setIsAddTeamMembersModalOpen(true);
                }}
              />
            </div>
          </div>

          <TeamMembersTable
            providerUserMembers={teamMemberProviderUsers}
            refetchTeamMembers={refetchTeamMembers}
            refreshAuthedProviderUser={refreshAuthedProviderUser}
            seatLimitReached={seatLimitReached}
            onSeatLimitReached={() => {
              showUpgradeModal(AnalyticsPage.SettingsTeam);
            }}
          />
        </div>
      </Transition>
      <AddTeamMembersModal
        isOpen={isAddTeamMembersModalOpen}
        existingTeamMembers={teamMemberProviderUsers}
        setClosed={() => setIsAddTeamMembersModalOpen(false)}
        refetchTeamMembers={refetchTeamMembers}
      />
    </>
  );
};

export default TeamSection;
