import { SvgIconComponent } from '../types/svgs';

// TODO: Consolidate CloseX and this X component
// This one is a newer version used in recent designs
// Actually we need an icon overhaul generally since the icons in the designs have changed
const X: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 18L18 6M6 6L18 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default X;
