import classNames from 'classnames';

type InputLabelProps = {
  label: string;
  className?: string;
  useNaturalLettering?: boolean;
  containerClassName?: string;
  isHidden?: boolean;
  children?: React.ReactNode;
} & React.ComponentProps<'label'>;

export default function InputLabel({
  label,
  className,
  useNaturalLettering = false,
  containerClassName,
  isHidden = false,
  children,
  ...rest
}: InputLabelProps) {
  return (
    <div
      className={classNames(
        isHidden
          ? 'sr-only'
          : 'mb-1 flex flex-row items-center justify-between',
        containerClassName,
      )}
    >
      <label
        className={classNames('text-caption text-green-125', className)}
        {...rest}
      >
        <span className={classNames(!useNaturalLettering && 'uppercase')}>
          {label}
        </span>
      </label>

      {children}
    </div>
  );
}
