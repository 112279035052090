import { FC } from 'react';

import { ProviderActivityType } from '../../../../types/activity';

import { getTimeStamp } from '../../../../lib/time';
import { isAssessmentAForm } from '../../../../lib/assessments';

import NoText from '../../../../components/NoText';

import { getProviderActivityData } from '../../../../lib/providerActivity';
import { ActivityDataFragment } from '../../../../../generated/graphql';

const ActivityDateAdded: FC<{
  providerActivity: ActivityDataFragment;
}> = ({ providerActivity }) => {
  const { createdAt } = getProviderActivityData(providerActivity);

  if (
    providerActivity.__typename === ProviderActivityType.Assessment &&
    !isAssessmentAForm(providerActivity.assessment)
  ) {
    return <NoText text="N/A" />;
  }

  return (
    <div className="text-netural-125 text-caption">
      {getTimeStamp(createdAt, false)}
    </div>
  );
};

export default ActivityDateAdded;
