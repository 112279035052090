import { FC } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline';

import { PatientScheduledEventFragment } from '../../../generated/graphql';

import { displayTime } from '../../lib/copy';
import { getTimeStamp } from '../../lib/time';

import {
  ConferenceLocationTypeMap,
  isConferenceLocation,
  isNonConferenceLocation,
} from '../../lib/scheduled-events';

import Button from '../Button';
import Avatar from '../Avatar';
import PatientScheduledEventMenu from './PatientScheduledEventMenu';

const PatientScheduledEvent: FC<{
  scheduledEvent?: PatientScheduledEventFragment;
  isDemo?: boolean;
  showRelativeTime?: boolean;
  onClickCancelEvent: () => void;
}> = ({
  scheduledEvent,
  isDemo = false,
  showRelativeTime,
  onClickCancelEvent,
}) => {
  const navigate = useNavigate();
  const startTimeFormatted = DateTime.fromISO(
    scheduledEvent?.startTime as string,
  );

  const endTimeFormatted = DateTime.fromISO(scheduledEvent?.endTime as string);

  const conferenceLocationJoinUrl =
    scheduledEvent?.location && isConferenceLocation(scheduledEvent?.location)
      ? scheduledEvent?.location.joinUrl
      : undefined;

  const nonConferenceLocation =
    scheduledEvent?.location &&
    isNonConferenceLocation(scheduledEvent?.location)
      ? scheduledEvent?.location.location
      : undefined;

  return (
    <div
      className={classNames(
        'flex w-full flex-col items-start justify-center rounded-lg p-6 shadow-100 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50 md:w-[354px]',
        {
          'cursor-pointer opacity-50': isDemo,
        },
        showRelativeTime && 'border-t-[12px] border-t-green-50',
      )}
      onClick={() => {
        if (isDemo) {
          navigate('/integrations/calendly');
        }
      }}
    >
      <div className="flex w-full flex-row items-start justify-between">
        <div className="mb-4 flex flex-row items-center justify-start">
          <Avatar
            imageUrl={scheduledEvent.hostProfileImageUrl}
            name={scheduledEvent.hostName}
            size="medium-large"
          />
          <div className="ml-4 flex flex-col items-start justify-start">
            {showRelativeTime && (
              <div className="text-caption font-bold capitalize text-green-100">
                {`${getTimeStamp(
                  scheduledEvent?.startTime as string,
                  false,
                  true,
                  false,
                  false,
                )}`}
              </div>
            )}
            <div className="text-category font-medium text-neutral-150">
              {scheduledEvent.hostName}
            </div>
            <div className="text-caption text-neutral-125">
              {scheduledEvent?.name}
            </div>
          </div>
        </div>
        <PatientScheduledEventMenu onClickCancelEvent={onClickCancelEvent} />
      </div>
      <div className="flex flex-row items-start justify-start">
        <CalendarIcon className="mr-5 h-5 w-5 text-neutral-150" />
        <div className="text-caption font-medium text-neutral-150">
          {`${getTimeStamp(
            scheduledEvent?.startTime as string,
            false,
          )} at ${displayTime(startTimeFormatted, 'h:mm')}–${displayTime(
            endTimeFormatted,
            't',
          )}`}
        </div>
      </div>
      {(conferenceLocationJoinUrl || nonConferenceLocation) && (
        <div className="mt-1.5 flex flex-row items-start justify-start">
          <LocationMarkerIcon className="mr-5 h-5 w-5 text-neutral-150" />
          <div className="truncate text-caption font-medium text-neutral-150">
            {conferenceLocationJoinUrl && (
              <Button
                title={`Link to ${
                  ConferenceLocationTypeMap[scheduledEvent?.location.type]
                } meeting`}
                theme="alt"
                size="small"
                onClick={(e) => {
                  window.open(conferenceLocationJoinUrl, '_blank');
                  e.stopPropagation();
                }}
                className="px-0 py-0 text-blue-125 hover:text-blue-150"
                noFocus
              />
            )}
            {nonConferenceLocation && (
              <div className="text-caption text-neutral-150">
                {nonConferenceLocation}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientScheduledEvent;
