import classNames from 'classnames';

import Button from './Button';
import connectTheDotsIlloSrc from '../../assets/images/hc-connect-the-dots-illo.png';
import Plus from '../svgs/Plus';

type EmptyPromptProps = {
  className?: string;
  title: string;
  body?: string;
  imageSrc?: string;
  imageSizeClassName?: string;
  noButton?: boolean;
  buttonTitle?: string;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function EmptyPrompt({
  className,
  title,
  body,
  noButton = false,
  buttonTitle,
  buttonOnClick,
  imageSrc,
  imageSizeClassName,
}: EmptyPromptProps) {
  return (
    <div
      className={classNames(
        'mx-auto flex max-w-md flex-col items-center text-center',
        className,
      )}
    >
      <img
        src={imageSrc || connectTheDotsIlloSrc}
        className={imageSizeClassName || 'h-[180px] w-[250px]'}
        alt=""
      />
      <h2 className="mt-7 mb-3 text-subtitle text-green-150">{title}</h2>
      <p className="mb-8 text-green-125">{body}</p>
      {!noButton && (
        <Button
          title={buttonTitle}
          IconComponent={Plus}
          theme="secondary"
          onClick={buttonOnClick}
        />
      )}
    </div>
  );
}
