import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getTokenPayload } from '../../lib/auth';
import Config from '../../lib/config';

class ApiClient {
  private client!: AxiosInstance;

  constructor() {
    this.init();
  }

  init() {
    this.client = axios.create({
      baseURL: `${Config.REACT_APP_HOMECOMING_API_BASE_URI}`,
    });

    this.client.interceptors.request.use((config) => {
      config.headers = this.getHeaders();
      return config;
    });

    // Transform every response in success and error states for convenience.
    this.client.interceptors.response.use((response) => {
      return response.data;
    });
  }

  get<Type>(
    url: string,
    config?: AxiosRequestConfig,
    params?: unknown,
  ): Promise<Type> {
    return this.client.get<void, Type>(url, config);
  }

  post<Type>(
    url: string,
    config?: AxiosRequestConfig,
    data?: unknown,
  ): Promise<Type> {
    return this.client.post<void, Type>(url, data, config);
  }

  put<Type>(
    url: string,
    config?: AxiosRequestConfig,
    data?: unknown,
  ): Promise<Type> {
    return this.client.put<void, Type>(url, data, config);
  }

  delete<Type>(url: string, config?: AxiosRequestConfig): Promise<Type> {
    return this.client.delete<void, Type>(url, config);
  }

  getHeaders(): Record<string, string> | undefined {
    const tokenPayload = getTokenPayload();
    return {
      ...(tokenPayload && {
        authorization: `Bearer ${tokenPayload.authToken}`,
      }),
    };
  }

  /** Callback when we receive a 401 error. Used to perform a logout. */
  onUnauthorized: (() => void) | null = null;
}

export default new ApiClient();
