import { FC } from 'react';
import classNames from 'classnames';

import { PatientsQuery } from '../../../../../generated/graphql';
import ProgramItem from '../../../../components/ProgramItem';
import ProgramOnboardingStatusItem from '../../../../components/ProgramOnboardingStatusItem';
import { getTimeStamp } from '../../../../lib/date';

type ArchivedRowProps = {
  isChecked: boolean;
  patient: PatientsQuery['patients'][number];
  patientIndex: number;
  isPatientsSelected?: boolean;
  isCreateEditingProgramTaskEnabled?: boolean;
  isPatientProfileEnabled?: boolean;
  isAssessmentsEnabled?: boolean;
  navigateToPatient?: (patient: PatientsQuery['patients'][number]) => void;
  handleCheckboxInteraction: (
    event: React.ChangeEvent<HTMLInputElement>,
    patient: PatientsQuery['patients'][number],
    patientIndex: number,
  ) => void;
};

const ArchivedRow: FC<ArchivedRowProps> = ({
  isChecked,
  patient,
  patientIndex,
  handleCheckboxInteraction,
}) => {
  return (
    <tr
      key={patient.email}
      className={classNames(
        'group h-[84px] whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
      )}
    >
      <td className="relative px-6 py-0 sm:w-16 sm:px-8">
        {isChecked && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-white" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125 sm:left-6"
          value={patient.email}
          checked={isChecked}
          onChange={(event) =>
            handleCheckboxInteraction(event, patient, patientIndex)
          }
        />
      </td>
      <td className="w-1/3 py-4 px-0">
        <div className={'mb-1 font-serif text-extra-small'}>
          {patient?.firstName + ' ' + patient?.lastName}
        </div>
        <div className="text-small-caption">{patient?.email}</div>
      </td>
      <td className="w-1/3">
        {patient?.programTemplate && (
          <ProgramItem
            programTemplateId={patient.programTemplate.id}
            programTemplateName={patient.programTemplate.name ?? undefined}
            programTemplateStatus={patient.programTemplate.status}
          />
        )}
      </td>
      <td className="w-1/3">
        {patient?.deactivatedAt ? (
          <ProgramOnboardingStatusItem
            onboardingStatus={patient.onboardingStatus}
          />
        ) : (
          <div className="text-caption text-neutral-125/60">
            Program complete
          </div>
        )}
        {patient?.deactivatedAt && (
          <div className="mt-2 text-small-caption">
            {getTimeStamp(patient?.deactivatedAt, false, true)}
          </div>
        )}
      </td>
    </tr>
  );
};

export default ArchivedRow;
