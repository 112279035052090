import { FC, useCallback } from 'react';

import {
  useFormContext,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
} from 'react-hook-form';

import classNames from 'classnames';

import { PatientRowValues, PatientRow } from '../../../types/PatientManagement';

import {
  fullNameRegisterOptions,
  emailRegisterOptions,
  phoneRegisterOptions,
} from '../../../lib/register-options';

import InputGroup from '../../InputGroup';
import CloseX from '../../../svgs/CloseX';
import IconButton from '../../IconButton';
import { HC_ADD_PATIENTS_TEMPLATE_CSV } from './UseASpreadsheetMenu';
import ProgramTagManager, {
  StagedProgramTagDataFragment,
} from '../../ProgramTag/ProgramTagManager';
import {
  Exact,
  MeProviderProgramTagsQuery,
  ProgramTagDataFragment,
} from '../../../../generated/graphql';
import { ApolloQueryResult } from '@apollo/client';
import { AnalyticsPage } from '../../../../lib/analytics';

type PatientInputFieldProps = PatientRow & { id: string };

const PatientInputRow: FC<{
  field?: PatientInputFieldProps;
  rowIndex: number;
  lastRowIndex: number;
  hasManyFields: boolean;
  insert: UseFieldArrayInsert<PatientRowValues, 'patientRows'>;
  remove: UseFieldArrayRemove;
  overflowContainerRef?: React.MutableRefObject<HTMLDivElement>;
  disabled: boolean;
  providerProgramTags: ProgramTagDataFragment[];
  refetchProviderProgramTags: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<MeProviderProgramTagsQuery>>;
}> = ({
  field,
  rowIndex,
  lastRowIndex,
  hasManyFields,
  insert,
  remove,
  overflowContainerRef,
  disabled = false,
  providerProgramTags,
  refetchProviderProgramTags,
}) => {
  const {
    watch,
    register,
    setFocus,
    formState: { errors, isSubmitted },
  } = useFormContext<PatientRowValues>();

  const rowErrors = errors?.patientRows?.[rowIndex];

  const nameRegister = register(
    `patientRows.${rowIndex}.name`,
    fullNameRegisterOptions,
  );

  const emailRegister = register(
    `patientRows.${rowIndex}.email`,
    emailRegisterOptions,
  );

  const phoneRegister = register(
    `patientRows.${rowIndex}.phoneNumber`,
    phoneRegisterOptions,
  );

  const tagsRegister = register(`patientRows.${rowIndex}.tags`);
  const tagsValue = watch(`patientRows.${rowIndex}.tags`);

  const handleOnTagsChange = useCallback(
    async (tags: StagedProgramTagDataFragment[]) => {
      const event = {
        target: {
          name: `patientRows.${rowIndex}.tags`,
          value: tags,
        },
      };
      tagsRegister.onChange(event);
    },
    [rowIndex],
  );

  const isNameFieldEmpty = field?.name === '';
  const isEmailFieldEmpty = field?.email === '';
  const isPhoneNumberFieldEmpty = field?.phoneNumber === '';

  const overflowContainer = overflowContainerRef?.current;
  const hasScrollbar =
    overflowContainer &&
    overflowContainer.scrollHeight > overflowContainer.clientHeight;

  const removeRow = (rowIndex: number) => {
    remove(rowIndex);
  };

  const handleNameFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Backspace' &&
      isNameFieldEmpty &&
      isEmailFieldEmpty &&
      isPhoneNumberFieldEmpty &&
      hasManyFields &&
      remove &&
      setFocus
    ) {
      remove([rowIndex]);
      setTimeout(() => {
        setFocus(`patientRows.${rowIndex - 1}.name`);
      }, 10); // TODO: use useEffect to get insert event better
    }
  };

  const handleEmailFieldKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Backspace' && isEmailFieldEmpty) {
      setFocus(`patientRows.${rowIndex}.name`);
    }
  };

  const handlePhoneFieldKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Tab' && setFocus && rowIndex === lastRowIndex) {
      insert(rowIndex + 1, {
        name: '',
        email: '',
        phoneNumber: '',
      });

      if (hasScrollbar) {
        overflowContainerRef.current.scrollTop =
          overflowContainerRef.current.scrollHeight;
      }

      setTimeout(() => {
        setFocus(`patientRows.${rowIndex + 1}.name`);
      }, 1); // TODO: use useEffect to get insert event better
    }
    if (e.key === 'Backspace' && isPhoneNumberFieldEmpty) {
      setFocus(`patientRows.${rowIndex}.email`);
    }
  };

  return (
    <div
      className={classNames(
        'flex w-full flex-row items-start justify-between gap-x-4',
        !rowErrors ? 'mb-4' : 'mb-2',
        rowErrors?.phoneNumber && 'min-h-[84px]',
        (rowErrors?.name || rowErrors?.email) && 'min-h-[68px]',
      )}
    >
      <InputGroup
        label="Name"
        placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[rowIndex + 1]?.[0] ?? ''}
        required
        containerClassName="w-1/4"
        inputSize="small"
        labelHidden
        errorMessage={isSubmitted ? rowErrors?.name?.message : null}
        errorLocation="bottom"
        onKeyDown={handleNameFieldKeyDown}
        disabled={disabled}
        {...nameRegister}
      />
      <InputGroup
        label="Email"
        placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[rowIndex + 1]?.[1] ?? ''}
        required
        containerClassName="w-1/4"
        inputSize="small"
        labelHidden
        errorMessage={isSubmitted ? rowErrors?.email?.message : null}
        errorLocation="bottom"
        onKeyDown={handleEmailFieldKeyDown}
        disabled={disabled}
        {...emailRegister}
      />
      <InputGroup
        label="Phone"
        placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[rowIndex + 1]?.[2] ?? ''}
        containerClassName="w-1/4"
        inputSize="small"
        labelHidden
        errorMessage={isSubmitted ? rowErrors?.phoneNumber?.message : null}
        onKeyDown={handlePhoneFieldKeyDown}
        disabled={disabled}
        {...phoneRegister}
      />
      <ProgramTagManager
        availableProgramTags={providerProgramTags}
        refreshAvailableProgramTags={refetchProviderProgramTags}
        selectedProgramTags={tagsValue}
        onTagsChange={handleOnTagsChange}
        analyticsPage={AnalyticsPage.AddClientsModal}
        className="w-1/4 max-w-[25%]"
      />
      <IconButton
        IconComponent={CloseX}
        aria-label="Remove row"
        iconClassName="h-2.5 w-2.5"
        className="mt-2.5 p-2"
        disabled={!hasManyFields}
        onClick={() => removeRow(rowIndex)}
      />
    </div>
  );
};

export default PatientInputRow;
