import React from 'react';
import Button from './Button';
import CloseX from '../svgs/CloseX';

const ClearFiltersButton: React.FC<{
  hasFilters: boolean;
  clearFilters: () => void;
}> = ({ hasFilters, clearFilters }) => {
  if (hasFilters)
    return (
      <Button
        title="Clear filters"
        theme="secondary"
        size="small"
        className="h-[44px]"
        IconComponent={CloseX}
        iconClassName="mt-0.5 h-2 w-2"
        onClick={clearFilters}
      />
    );
  return null;
};

export default ClearFiltersButton;
