import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../../lib/analytics';

/** By using a separate page for Calendly to redirect to, we have more control over how we receive the redirect.
 *
 *  Most notably, we can track whether the user canceled the integration flow.
 *  And generally we can differentiate between the user refreshing and the user being redirected
 *  with or without an authorization code.
 */
const CalendlyIntegrationCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');

  useEffect(() => {
    if (!authorizationCode) {
      trackProviderEvent(
        ProviderAnalyticsEvent.AddCalendlyIntegrationCancelled,
      );
    }
  }, [authorizationCode]);

  return (
    <Navigate
      to={{
        pathname: '/integrations/calendly',
        search: authorizationCode ? `?code=${authorizationCode}` : '',
      }}
      replace
    />
  );
};

export default CalendlyIntegrationCallback;
