import { phone } from 'phone';

import { acceptedAudioFileExtensions, MAX_UPLOAD_SIZE_BYTES } from './media';
import { NAME_REGEX, EMAIL_REGEX, URL_REGEX } from './regex';

export const MIN_NAME_LENGTH = 2;
export const MAX_NAME_LENGTH = 50;

// Removes trailing spaces
export const trimValue = (str: string) => str.trim();

export const providerNameRegisterOptions = {
  required: { value: true, message: 'Name is required' },
  pattern: {
    value: NAME_REGEX,
    message: 'Invalid organization name',
  },
  minLength: {
    value: MIN_NAME_LENGTH,
    message: `Name must be at least ${MIN_NAME_LENGTH} characters`,
  },
  maxLength: {
    value: MAX_NAME_LENGTH,
    message: `Name must be less than ${MAX_NAME_LENGTH} characters`,
  },
};

export const fullNameRegisterOptions = {
  required: { value: true, message: 'Name is required' },
  pattern: {
    value: NAME_REGEX,
    message: 'Invalid full name',
  },
  minLength: {
    value: MIN_NAME_LENGTH,
    message: `Name must be at least ${MIN_NAME_LENGTH} characters`,
  },
  maxLength: {
    value: MAX_NAME_LENGTH,
    message: `Name must be less than ${MAX_NAME_LENGTH} characters`,
  },
};

export const emailRegisterOptions = {
  required: { value: true, message: 'Email is required' },
  pattern: {
    value: EMAIL_REGEX,
    message: 'Invalid email',
  },
  setValueAs: trimValue,
};

export const linkRegisterOptions = {
  pattern: {
    value: URL_REGEX,
    message: 'Invalid link',
  },
};

export const phoneRegisterOptions = {
  setValueAs: (phoneNumber: string) => {
    const phoneInfo = phone(phoneNumber);

    if (!phoneInfo.isValid) return phoneNumber;

    return phoneInfo.phoneNumber;
  },
  validate: (phoneNumber: string | undefined) => {
    if (!phoneNumber) return;
    const { isValid } = phone(phoneNumber);

    if (!isValid) {
      return 'Unrecognized number format. Prefix the country code with "+" if necessary.';
    }
  },
};

export const audioFileRegisterOptions = {
  required: 'Please select a file to upload',
  validate: (fileList: FileList | undefined) => {
    if (fileList) {
      const audioFile = fileList?.[0];
      if (audioFile.size > MAX_UPLOAD_SIZE_BYTES) {
        return "That file is a bit too big. Try one that's smaller than 100 MB";
      } else if (!acceptedAudioFileExtensions.includes(audioFile.type)) {
        return 'Please upload an MP3 or M4A file';
      }
    }
  },
};
