import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  DotsHorizontalIcon,
  ReplyIcon,
  UserIcon,
} from '@heroicons/react/outline';

import IconButton from '../../../../components/IconButton';
import MenuButton from '../../../../components/MenuButton';
import Spinner from '../../../../svgs/Spinner';

const ClientSyncMenu: React.FC<{
  onClickGoToProfile: () => void;
  onClickUndoSync: () => Promise<void>;
  undoSyncLoading: boolean;
  showUndoSync: boolean;
}> = ({
  onClickGoToProfile,
  onClickUndoSync,
  undoSyncLoading,
  showUndoSync,
}) => {
  return (
    <div className="z-[1000] flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <IconButton
            aria-label="Calendar settings"
            IconComponent={DotsHorizontalIcon}
            iconClassName="text-neutral-125 h-5 w-5"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none">
            <MenuButton
              titleText="Go to profile"
              className="rounded-t-md"
              iconClassName="text-green-150"
              IconComponent={UserIcon}
              onClick={onClickGoToProfile}
            />
            {showUndoSync && (
              <MenuButton
                titleText="Undo sync"
                className="rounded-b-md"
                iconClassName="text-green-150"
                IconComponent={!undoSyncLoading ? ReplyIcon : Spinner}
                onClick={onClickUndoSync}
              />
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ClientSyncMenu;
