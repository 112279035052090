import { SvgIconComponent } from '../types/svgs';

const ThumbnailPdfForm: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#DBD8D1" />
      <path
        d="M19 33H29C29.5304 33 30.0391 32.7893 30.4142 32.4142C30.7893 32.0391 31 31.5304 31 31V21.414C30.9999 21.1488 30.8946 20.8945 30.707 20.707L25.293 15.293C25.1055 15.1055 24.8512 15.0001 24.586 15H19C18.4696 15 17.9609 15.2107 17.5858 15.5858C17.2107 15.9609 17 16.4696 17 17V31C17 31.5304 17.2107 32.0391 17.5858 32.4142C17.9609 32.7893 18.4696 33 19 33Z"
        stroke="#394642"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="2"
        width="44"
        height="44"
        rx="6"
        stroke="#F6D1FF"
        strokeWidth="4"
      />
      <path
        d="M24 32C24 27.5817 27.5817 24 32 24H46C47.1046 24 48 24.8954 48 26V40C48 44.4183 44.4183 48 40 48H26C24.8954 48 24 47.1046 24 46V32Z"
        fill="#F6D1FF"
      />
      <path
        d="M33.3327 31.6667H41.3327M41.3327 31.6667L38.666 29M41.3327 31.6667L38.666 34.3333M38.666 38.3333H30.666M30.666 38.3333L33.3327 41M30.666 38.3333L33.3327 35.6667"
        stroke="#B000D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailPdfForm;
