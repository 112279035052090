import { FC, useMemo } from 'react';
import classNames from 'classnames';

import Button from '../../../../components/Button';
import { getTimeStamp } from '../../../../lib/time';
import TestClientPill from '../../../../components/TestClientPill';
import Avatar from '../../../../components/Avatar';

import ChevronRight from '../../../../svgs/ChevronRight';
import DemoClientInfoButton from '../../../../components/DemoClientInfoButton';
import { useAuth } from '../../../../../contexts/AuthContext';
import { useGlobalModalsContext } from '../../../../../contexts/GlobalModalsContext';
import { AnalyticsPage } from '../../../../../lib/analytics';
import { PatientTableDataFragment } from '../../../../../generated/graphql';

type InvitedRowProps = {
  isChecked: boolean;
  patient: PatientTableDataFragment;
  patientIndex: number;
  isPatientProfileEnabled?: boolean;
  isAssessmentsEnabled?: boolean;
  nextStepsButtonsDisabled: boolean;
  openResendInvitesModal: () => void;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientTableDataFragment | null>
  >;
  navigateToPatientProfile: (
    patient: PatientTableDataFragment,
    subRoute?: string,
  ) => void;
};

const InvitedRow: FC<InvitedRowProps> = ({
  isChecked,
  patient,
  patientIndex,
  nextStepsButtonsDisabled,
  openResendInvitesModal,
  setSinglePatient,
  navigateToPatientProfile,
}) => {
  const { authedProviderUser } = useAuth();
  const { showUpgradeModal } = useGlobalModalsContext();

  const timestamp = useMemo(
    () => getTimeStamp(patient?.onboardingLastProgramInviteSentAt, false, true),
    [patient],
  );

  return (
    <tr
      key={patient.programInstanceId}
      className={classNames(
        'group cursor-pointer whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
      )}
      onClick={(event) => {
        navigateToPatientProfile?.(patient);
        event.stopPropagation();
      }}
    >
      <td className="w-1/3 py-4 px-0">
        <div className="flex flex-row items-center gap-x-4">
          <Avatar
            imageUrl={patient?.profileImageMedia?.url}
            name={patient?.firstName ?? ''}
            size="medium"
          />
          <div className="flex flex-col items-start justify-start font-serif text-extra-small">
            <div>{patient?.firstName + ' ' + patient?.lastName}</div>
            <div className="font-sans text-small-caption">{patient?.email}</div>
          </div>
          {patient?.isTestProgram && <TestClientPill />}
        </div>
      </td>
      <td className="w-1/3">
        {timestamp && (
          <div className="text-caption text-green-150">{timestamp}</div>
        )}
      </td>
      <td className="w-1/3">
        <div className="flex justify-between">
          <Button
            title={`${
              patient?.onboardingLastProgramInviteSentAt ? 'Resend' : 'Send'
            } ${patient.firstName} an invite`}
            theme="secondary"
            size="small"
            disabled={nextStepsButtonsDisabled}
            onClick={(event) => {
              if (
                !authedProviderUser?.hasPremiumAccess &&
                !patient?.isTestProgram
              ) {
                event.preventDefault();
                event.stopPropagation();
                showUpgradeModal(AnalyticsPage.ClientsTableInvited);
                return;
              }
              setSinglePatient(patient);
              openResendInvitesModal();
              event.stopPropagation();
            }}
          />
          {patient?.isTestProgram && <DemoClientInfoButton />}
        </div>
      </td>
      <td className="flex flex-row items-center justify-end">
        <Button
          noOutline
          noBackground
          theme="secondary"
          className="pt-2"
          IconComponent={ChevronRight}
        />
      </td>
    </tr>
  );
};

export default InvitedRow;
