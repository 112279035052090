import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import {
  Feature,
  useProgramTemplateNameQuery,
} from '../../../generated/graphql';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';

import Tabs from '../../components/Tabs';
import ToastAlert from '../../components/ToastAlert';
import { defaultEnterTransitionProps } from '../../lib/animation';
import PageContainer from '../../components/Containers/PageContainer';

const Program = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsEnabled = isFeatureEnabled(Feature.Assessments);

  const params = useParams();
  const programTemplateId = params.programTemplateId || '';

  const { data: programTemplateData, error: programTemplateError } =
    useProgramTemplateNameQuery({
      variables: {
        programTemplateId: programTemplateId,
      },
    });

  useEffect(() => {
    if (programTemplateError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  }, [programTemplateError]);

  const programTemplateName = programTemplateData?.programTemplate.name;

  const tabList = [
    {
      route: 'content',
      name: 'Content',
    },
    {
      route: 'assessments',
      name: 'Assessments',
    },
  ];

  return (
    <PageContainer>
      <Transition
        show={Boolean(programTemplateName && !programTemplateError)}
        {...defaultEnterTransitionProps}
      >
        <div className="mb-2 flex h-[48px] flex-row items-center justify-between">
          <div className="font-serif text-subtitle text-green-150">
            {programTemplateName}
          </div>
        </div>

        {isAssessmentsEnabled && (
          <div className="my-3 mb-2 flex h-[48px] w-full items-center justify-between">
            <Tabs tabList={tabList} fullWidth />
          </div>
        )}

        <Outlet />
      </Transition>
    </PageContainer>
  );
};

export default Program;
