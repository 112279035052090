import React from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { StorefrontFormData } from '..';
import RadioOption from '../../../components/RadioOption';
import { useCalendlyEventTypesQuery } from '../../../../generated/graphql';
import { useAuth } from '../../../../contexts/AuthContext';
import IntegrationCard from '../../Settings/IntegrationCard';
import CalendlyLogo from '../../../svgs/CalendlyLogo';
import toast from 'react-hot-toast';
import ToastAlert from '../../../components/ToastAlert';
import Alert from '../../../components/Alert';
import Spinner from '../../../svgs/Spinner';

const DiscoveryCallModule: React.FC<{
  setDiscoveryCallCalendlyEventTypeUri?: (
    calendlyEventTypeUri: string,
  ) => Promise<void>;
  removeDiscoveryCallCalendlyEventTypeUri?: () => Promise<void>;
  className?: string;
}> = ({
  setDiscoveryCallCalendlyEventTypeUri,
  removeDiscoveryCallCalendlyEventTypeUri,
  className,
}) => {
  const { authedProviderUser } = useAuth();

  const hasCalendlyIntegrated = Boolean(
    authedProviderUser?.provider?.calendlyIntegration,
  );

  const { data: calendlyEventTypeData, loading: calendlyEventTypesLoading } =
    useCalendlyEventTypesQuery({
      skip: !hasCalendlyIntegrated,
      onError: (error) => {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Failed to get Calendly event types."
            level="error"
          />
        ));
      },
    });

  const calendlyEventTypes = calendlyEventTypeData?.calendlyEventTypes ?? [];

  const { control } = useFormContext<StorefrontFormData>();

  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-hidden px-6 py-10 md:items-center',
        className,
      )}
    >
      <div className="flex flex-col md:w-1/2">
        {!hasCalendlyIntegrated ? (
          <IntegrationCard
            logo={<CalendlyLogo className="h-8" />}
            name="Calendly"
            description="Sync your calendar with Homecoming and allow clients to schedule sessions."
            isConnected={hasCalendlyIntegrated}
            integrationPath="/integrations/calendly"
          />
        ) : (
          <div>
            <div className="text-caption font-medium text-green-150">
              Choose discovery call event
            </div>
            <div className="text-caption text-neutral-125">
              Choose the event type that you’d like to be visible for you and
              your clients.
            </div>

            <div className="mt-5">
              {calendlyEventTypesLoading ? (
                <Spinner />
              ) : calendlyEventTypes.length === 0 ? (
                <Alert level="info" message="No event types found" />
              ) : (
                <form>
                  <Controller
                    control={control}
                    name="calendlyEventTypeUri"
                    render={({ field }) => (
                      <div className="space-y-6">
                        <RadioOption
                          id="eventType_none"
                          value={null}
                          name={field.name}
                          onChange={async (e) => {
                            field.onChange(e);
                            await removeDiscoveryCallCalendlyEventTypeUri?.();
                          }}
                          onBlur={field.onBlur}
                          checked={field.value == null}
                          labelContent={
                            <div className="flex items-center">
                              <span className="mx-3 inline-block h-4 w-4 rounded-full bg-neutral-110" />
                              No discovery call
                            </div>
                          }
                        />
                        {Boolean(calendlyEventTypes.length) && (
                          <div className="w-full border-b border-neutral-75"></div>
                        )}
                        {calendlyEventTypes.map((eventType, index) => (
                          <RadioOption
                            key={`calendlyEventType_${index}`}
                            id={`eventType_${eventType.name}`}
                            value={eventType.calendlyEventTypeUri}
                            name={field.name}
                            onChange={async (e) => {
                              field.onChange(e);
                              await setDiscoveryCallCalendlyEventTypeUri(
                                eventType.calendlyEventTypeUri,
                              );
                            }}
                            onBlur={field.onBlur}
                            checked={
                              field.value === eventType.calendlyEventTypeUri
                            }
                            labelContent={
                              <div className="flex items-center">
                                <span
                                  className="mx-3 inline-block h-4 w-4 rounded-full"
                                  style={{
                                    backgroundColor: eventType.color,
                                  }}
                                />
                                {eventType.name}
                              </div>
                            }
                          />
                        ))}
                      </div>
                    )}
                  />
                </form>
              )}
            </div>

            <div className="mt-6 border-t border-neutral-75 pt-5">
              <div className="text-caption text-neutral-125">
                If you need help with connecting Calendly with your Homecoming
                account, please reach out to us at{' '}
                <a
                  href="mailto:support@homcoming.health"
                  className="text-blue-100 underline"
                >
                  support@homcoming.health
                </a>
                .
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveryCallModule;
