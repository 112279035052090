import { useEffect } from 'react';

interface CalendlyWidget {
  initInlineWidget: (options: {
    url: string;
    parentElement: HTMLElement;
    prefill?: { name?: string; email?: string };
  }) => void;
}

declare global {
  interface Window {
    Calendly?: CalendlyWidget;
  }
}

function isCalendlyEvent(e) {
  return (
    e.origin === 'https://calendly.com' &&
    e.data.event &&
    e.data.event.indexOf('calendly.') === 0
  );
}

const CALENDLY_EVENT_TYPE_VIEWED = 'calendly.event_type_viewed';
const CALENDLY_EVENT_SCHEDULED = 'calendly.event_scheduled';

const useCalendly = (
  calendlyElementRef: React.MutableRefObject<HTMLDivElement>,
  onCalendlyEventTypeViewed?: () => Promise<void>,
  onCalendlyEventScheduled?: () => Promise<void>,
) => {
  const initCalendlyWidget = (
    schedulingUrl: string,
    prefill?: { name?: string; email?: string },
  ) => {
    // Use the existence of an inner iframe to check if the Calendly widget is already rendered
    const isCalendlyWidgetRendered = Boolean(
      calendlyElementRef.current?.querySelector('iframe'),
    );
    if (!calendlyElementRef.current || isCalendlyWidgetRendered) {
      return;
    }
    window.Calendly?.initInlineWidget({
      url: schedulingUrl,
      parentElement: calendlyElementRef.current,
      prefill,
    });
  };

  useEffect(() => {
    // Calendly uses postMessage to communicate with the parent window
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    const onMessage = async (event) => {
      if (!isCalendlyEvent(event)) {
        return;
      }
      console.info('Received Calendly event message:', event);
      switch (event?.data?.event) {
        case CALENDLY_EVENT_TYPE_VIEWED:
          await onCalendlyEventTypeViewed?.();
          break;
        case CALENDLY_EVENT_SCHEDULED:
          await onCalendlyEventScheduled?.();
          break;
        // Note: Can implement other event handlers here as needed
        default:
          break;
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  return {
    initCalendlyWidget,
  };
};

export default useCalendly;
