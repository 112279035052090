import { FC } from 'react';
import { Transition } from '@headlessui/react';

import Button from '../../components/Button';

const WelcomeStep: FC<{
  nextStep: () => void;
  firstName: string;
}> = ({ nextStep, firstName }) => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-1">
      <div className="mb-4 text-center font-serif text-title-medium font-light text-green-125">
        <Transition
          appear={true}
          show={true}
          enter="transition-opacity duration-1000 delay-[800ms] ease-in"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          Hi {firstName}
        </Transition>
      </div>
      <div className="mb-6 max-w-[575px] text-center text-paragraph text-neutral-125">
        <Transition
          appear={true}
          show={true}
          enter="transition-opacity duration-1000 delay-[1800ms] ease-in"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          Let's start with a tour of Homecoming to learn how you can support
          your clients and manage your practice.
        </Transition>
      </div>
      <Transition
        appear={true}
        show={true}
        enter="transition-opacity duration-1000 delay-[2800ms] ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <Button theme="secondary" title="Begin tour" onClick={nextStep} />
      </Transition>
    </div>
  );
};

export default WelcomeStep;
