import { FC, useCallback, useState } from 'react';

import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';

import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
  UseFieldArrayPrepend,
  FormProvider,
} from 'react-hook-form';

import {
  useLinkPreviewLazyQuery,
  useAddActivityLinkMutation,
  ActivityUseType,
} from '../../../../generated/graphql';

import {
  MAX_TITLE_LENGTH,
  MIN_TEXT_LENGTH,
} from '../../../lib/form-validators';
import { onEnterKeyDown } from '../../../lib/form';
import { linkRegisterOptions } from '../../../lib/register-options';

import CloseX from '../../../svgs/CloseX';
import Spinner from '../../../svgs/Spinner';

import Button from '../../Button';
import ToastAlert from '../../ToastAlert';
import InputLabel from '../../InputLabel';
import InputGroup from '../../InputGroup';
import TextAreaGroup from '../../TextAreaGroup';
import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';
import ThumbnailLink from '../../../svgs/ThumbnailLink';
import { RefetchProviderActivities } from '../../../types/activity';
import { FollowUpForm } from '../../../lib/followUp';
import { formatProviderActivityForFollowUp } from '../../../lib/providerActivity';
import ActivityUseSettings from '../FileSettingsModal/ActivityUseSettings';
import classNames from 'classnames';

const LINK_PREVIEW_DEBOUNCE_MS = 200;

type AddLinkModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  refetchProviderActivities?: RefetchProviderActivities;
  followUpMode?: boolean;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  onAdded?: (id: string) => void;
};

interface AddLinkModalFormData {
  linkUrl: string;
  linkTitle: string;
  linkThumbnailUrl?: string;
  isOneTimeUse: boolean;
}

const AddLinkModal: FC<AddLinkModalProps> = ({
  isModalOpen,
  setClosed,
  refetchProviderActivities,
  followUpMode = false,
  prependToFollowUp,
  onAdded,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [addActivityLinkMutation, { loading: addActivityLinkMutationLoading }] =
    useAddActivityLinkMutation();

  const [getLinkPreview, { loading: linkPreviewLoading }] =
    useLinkPreviewLazyQuery();

  const addLinkModalForm = useForm<AddLinkModalFormData>({
    mode: 'onChange',
    defaultValues: {
      linkUrl: '',
      linkTitle: '',
      linkThumbnailUrl: undefined,
      isOneTimeUse: followUpMode,
    },
  });

  const {
    control,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors: formErrors },
    reset: resetForm,
  } = addLinkModalForm;

  const watchLinkUrl = watch('linkUrl');
  const watchLinkTitle = watch('linkTitle');
  const watchLinkThumbnailUrl = watch('linkThumbnailUrl');

  const debouncedLinkPreview = useCallback(
    debounce(
      async (linkURL) => {
        try {
          const { data } = await getLinkPreview({
            variables: {
              linkURL,
            },
          });
          if (data?.linkPreview?.title) {
            setValue(
              'linkTitle',
              data.linkPreview.title.trim().slice(0, MAX_TITLE_LENGTH),
            );
          } else {
            setValue('linkTitle', '');
          }
          if (data?.linkPreview?.imageURL) {
            setValue('linkThumbnailUrl', data.linkPreview.imageURL);
          } else {
            setValue('linkThumbnailUrl', undefined);
          }
        } catch (err) {
          console.error('Link preview error received: ', err);
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              message="Couldn't fetch link previews."
              level="error"
            />
          ));
          setValue('linkTitle', '');
          setValue('linkThumbnailUrl', undefined);
        }
      },
      LINK_PREVIEW_DEBOUNCE_MS,
      { leading: true, trailing: true },
    ),
    [],
  );

  const onSubmit: SubmitHandler<AddLinkModalFormData> = async (
    formData: AddLinkModalFormData,
  ) => {
    setSubmitting(true);

    const addActivityLinkInput = {
      title: formData.linkTitle,
      url: formData.linkUrl,
      thumbnailImageURL: formData.linkThumbnailUrl,
      activityUseType: formData.isOneTimeUse
        ? ActivityUseType.OneTimeUse
        : ActivityUseType.Reusable,
    };

    try {
      const { data } = await addActivityLinkMutation({
        variables: {
          input: addActivityLinkInput,
        },
      });

      if (data?.addActivityLink) {
        const newActivityLink = data.addActivityLink;
        closeAndResetAddLinkModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully added ${newActivityLink.title} to your ${
              followUpMode ? 'follow-up' : 'library'
            }`}
          />
        ));

        if (refetchProviderActivities) await refetchProviderActivities();

        if (followUpMode && prependToFollowUp) {
          prependToFollowUp(formatProviderActivityForFollowUp(newActivityLink));
        }

        onAdded?.(data?.addActivityLink?.id);
      }
    } catch (err) {
      setSubmitting(false);
      closeAndResetAddLinkModal();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  const handleErrors: SubmitErrorHandler<AddLinkModalFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetAddLinkModal = async () => {
    setClosed();
    setTimeout(() => {
      setSubmitting(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const linkTitleRegister = register('linkTitle', {
    required: { value: true, message: 'Please give your link a title' },
    minLength: {
      value: MIN_TEXT_LENGTH,
      message: `Please ensure the title is more than ${MIN_TEXT_LENGTH} characters`,
    },
    maxLength: {
      value: MAX_TITLE_LENGTH,
      message: `Please ensure the title is less than ${MAX_TITLE_LENGTH} characters`,
    },
  });

  return (
    <Modal
      name="AddLink"
      isOpen={isModalOpen}
      setClosed={closeAndResetAddLinkModal}
      width="small"
      overflow="auto"
    >
      <div className="px-10">
        <div className="mb-2 flex w-full flex-col items-center justify-center">
          <span className="font-serif text-subtitle text-green-150">
            Add a link
          </span>
          <span className="text-caption text-green-125">
            Paste a link and confirm the title
          </span>
        </div>

        <form className="flex w-full flex-col items-start justify-start">
          <Controller
            name="linkUrl"
            control={control}
            rules={linkRegisterOptions}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <InputGroup
                label="Paste a link"
                useNaturalLettering
                inputSize="small"
                containerClassName="mt-2"
                className="w-[470px] overflow-hidden"
                iconRightClassName="cursor-pointer"
                IconRight={value ? CloseX : null}
                iconRightOnClick={() => {
                  resetForm();
                  onChange('');
                }}
                onKeyDown={onEnterKeyDown}
                onBlur={onBlur}
                value={value}
                onChange={(e) => {
                  const link = e.target.value;
                  if (!error && link && link.length > 1) {
                    setValue('linkUrl', link);
                    debouncedLinkPreview(link);
                  } else {
                    resetForm();
                  }
                  onChange(e);
                }}
                errorMessage={error?.message}
              />
            )}
          />
          {Boolean(watchLinkUrl) && (
            <div className="mt-4 h-24 w-full">
              {linkPreviewLoading ? (
                <div className="flex h-full items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="flex items-start justify-start">
                  <div className="mr-4 flex flex-col">
                    <InputLabel label="Thumbnail" useNaturalLettering />
                    {watchLinkThumbnailUrl ? (
                      <img
                        src={watchLinkThumbnailUrl}
                        alt="link preview thumbnail"
                        className="h-[88px] w-[88px] rounded-md object-cover"
                        onError={(e) => {
                          // If the image fails to load, consider it failed and wipe the fetched value
                          setValue('linkThumbnailUrl', undefined);
                        }}
                      />
                    ) : (
                      <ThumbnailLink className="h-[88px] w-[88px] rounded-md" />
                    )}
                  </div>
                  <TextAreaGroup
                    label="Title"
                    rows={3}
                    characterCounter
                    disabled={linkPreviewLoading || Boolean(formErrors.linkUrl)}
                    value={
                      !linkPreviewLoading ? watchLinkTitle : 'Loading data...'
                    }
                    onKeyDown={onEnterKeyDown}
                    currentLengthValue={watchLinkTitle?.length ?? 0}
                    maxLength={MAX_TITLE_LENGTH}
                    maxLengthValue={MAX_TITLE_LENGTH}
                    inputSize="small"
                    className="w-[367px] overflow-hidden"
                    errorMessage={formErrors.linkTitle?.message}
                    {...linkTitleRegister}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              'mb-8 flex w-full flex-col items-start justify-start',
              followUpMode && Boolean(watchLinkUrl) ? 'mt-8' : 'mt-12',
            )}
          >
            {followUpMode && Boolean(watchLinkUrl) && (
              <FormProvider {...addLinkModalForm}>
                <div className="mb-4">
                  <ActivityUseSettings />
                </div>
              </FormProvider>
            )}
            <div className="flex w-full flex-row justify-center">
              <Button
                title={
                  !submitting
                    ? `Add to ${followUpMode ? 'follow-up' : 'library'}`
                    : 'Adding...'
                }
                onClick={handleSubmit(onSubmit, handleErrors)}
                {...(submitting && {
                  IconComponent: Spinner,
                  iconClassName: 'h-[16px] w-[16px]',
                })}
                disabled={
                  !Boolean(
                    watchLinkUrl &&
                      watchLinkTitle &&
                      !formErrors.linkTitle &&
                      !formErrors.linkUrl &&
                      !addActivityLinkMutationLoading,
                  )
                }
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddLinkModal;
