import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import Button from '../../components/Button';
import {
  ProgramTemplateStatus,
  ProgramOnboardingStatus,
  PatientLifecycleStatus,
  ProgramTemplatesQuery,
} from '../../../generated/graphql';

import ProgramItem from '../../components/ProgramItem';

import { Filters } from './helpers';
import { ONBOARDING_STATUSES } from './helpers';
import Checkmark from '../../svgs/Checkmark';
import classNames from 'classnames';
import ProgramOnboardingStatusItem from '../../components/ProgramOnboardingStatusItem';
import { ChevronDownIcon } from '@heroicons/react/outline';

function getOnboardingStatusesForFilter(
  hasInDevelopmentPrograms: boolean,
  patientLifecycleStatus: PatientLifecycleStatus | null,
) {
  let eligibleOnboardingStatuses = ONBOARDING_STATUSES;

  if (!hasInDevelopmentPrograms) {
    eligibleOnboardingStatuses = ONBOARDING_STATUSES.filter(
      (onboardingStatus) => {
        return (
          onboardingStatus.status !== ProgramOnboardingStatus.NeedsActiveProgram
        );
      },
    );
  }

  if (patientLifecycleStatus === PatientLifecycleStatus.Onboarding) {
    eligibleOnboardingStatuses = ONBOARDING_STATUSES.filter(
      (onboardingStatus) => onboardingStatus.preInviteOnboardingStatus,
    );
  }

  return eligibleOnboardingStatuses;
}

const FilterMenu: React.FC<{
  patientLifecycleStatus: PatientLifecycleStatus | null;
  programTemplates: ProgramTemplatesQuery['programTemplates'];
  filters: Record<string, Filters>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, Filters>>>;
}> = ({ patientLifecycleStatus, programTemplates, filters, setFilters }) => {
  const FilterMenuButton: React.FC<{
    programTemplate?: ProgramTemplatesQuery['programTemplates'][number];
    status?: ProgramOnboardingStatus;
    iconStyle?: string;
  }> = ({ status, programTemplate }) => {
    const handleClick = () => {
      const filterUpdate: Record<string, boolean> = {};

      if (programTemplate) {
        filterUpdate[programTemplate.id] =
          !filters.programs[programTemplate.id];

        setFilters({
          ...filters,
          programs: {
            ...filterUpdate,
          },
        });
      } else if (status) {
        filterUpdate[status] = !filters.statuses[status];

        setFilters({
          ...filters,
          statuses: {
            ...filterUpdate,
          },
        });
      }
    };

    return (
      <Menu.Item>
        {({ active }) => (
          <button
            className={`${
              active && 'bg-neutral-50 '
            } flex w-full items-center justify-between rounded-md px-2 py-2 text-green-150`}
            onClick={handleClick}
          >
            {programTemplate && (
              <>
                <ProgramItem
                  programTemplateId={programTemplate.id}
                  programTemplateName={programTemplate.name ?? undefined}
                  programTemplateStatus={programTemplate.status}
                  disableClick
                  className="mr-2"
                />
                {Boolean(filters.programs[programTemplate.id]) && (
                  <Checkmark className="text-green-100" />
                )}
              </>
            )}
            {status && (
              <>
                <ProgramOnboardingStatusItem onboardingStatus={status} />
                {Boolean(filters.statuses[status]) && (
                  <Checkmark className="text-green-100" />
                )}
              </>
            )}
          </button>
        )}
      </Menu.Item>
    );
  };

  const hasInDevelopmentPrograms = programTemplates?.some(
    (programTemplate) =>
      programTemplate.status === ProgramTemplateStatus.InDevelopment,
  );

  const onboardingStatuses = getOnboardingStatusesForFilter(
    hasInDevelopmentPrograms,
    patientLifecycleStatus,
  );

  return (
    <div className="z-10 flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <Button
            title="Filter"
            theme="secondary"
            size="medium"
            noBackground
            className="font-normal"
            iconClassName="h-[18px] w-[18px] ml-2 text-green-100"
            IconComponent={ChevronDownIcon}
            iconPosition="right"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'absolute left-0 mt-2 divide-y divide-neutral-50 rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              hasInDevelopmentPrograms ? 'w-96' : 'w-80',
            )}
          >
            <div className="py-2 pt-1">
              <div className="px-2 py-2 text-small-caption font-medium text-green-150">
                Program
              </div>
              <div className="max-h-72 overflow-y-auto">
                {programTemplates?.map((programTemplate, index) => {
                  return (
                    <FilterMenuButton
                      programTemplate={programTemplate}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
            {patientLifecycleStatus === PatientLifecycleStatus.Onboarding && (
              <div className="py-2">
                <div className="px-2 py-2 text-small-caption font-medium text-green-150">
                  Status
                </div>
                {onboardingStatuses.map((onboardingStatus, index) => {
                  return (
                    <FilterMenuButton
                      status={onboardingStatus.status}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default FilterMenu;
