import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment } from 'react';

import ChevronRight from '../svgs/ChevronRight';

interface SelectMenuProps<T> {
  onChange: (...event: unknown[]) => void;
  fieldValue: T;
  fieldOptions?: T[];
  // Option to include header'd sections of options within the select menu
  fieldOptionSections?: {
    sectionHeader: string;
    fieldOptions: T[];
  }[];
  SelectOptionComponent?: FC<{
    value: T;
  }>;
  containerClassName?: string;
  optionsContainerClassName?: string;
  buttonClassName?: string;
  label: string;
  smallLabel?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autofocus?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectMenu: FC<SelectMenuProps<any>> = ({
  onChange,
  fieldValue,
  fieldOptions,
  fieldOptionSections,
  SelectOptionComponent,
  containerClassName = '',
  optionsContainerClassName = '',
  buttonClassName = ',',
  label,
  smallLabel = false,
  hideLabel = false,
  placeholder = 'Select',
  disabled = false,
  autofocus = false,
}) => {
  return (
    <Listbox value={fieldValue} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className={containerClassName}>
          <Listbox.Label
            className={classNames(
              'mb-1 block text-green-125',
              {
                'text-small-action': smallLabel,
                'text-caption': !smallLabel,
              },
              hideLabel && 'hidden',
            )}
          >
            {label}
          </Listbox.Label>
          <div className="relative w-full">
            <Listbox.Button
              className={classNames(
                'relative flex w-full cursor-default flex-row items-center justify-start',
                'max-h-14 rounded-md bg-neutral-50 py-4 pl-3 pr-10 text-left shadow-sm sm:text-caption',
                'focus:border-green-50 focus:outline-none focus:ring-2 focus:ring-green-50',
                buttonClassName,
              )}
              autoFocus={autofocus}
            >
              {Boolean(fieldValue) ? (
                <div
                  className={classNames(
                    'truncate pl-2',
                    optionsContainerClassName,
                  )}
                >
                  {SelectOptionComponent ? (
                    <SelectOptionComponent value={fieldValue} />
                  ) : (
                    <span className="truncate text-caption">{fieldValue}</span>
                  )}
                </div>
              ) : (
                <span className="text-caption text-neutral-125/75">
                  {placeholder}
                </span>
              )}
              {!disabled && (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronRight className="rotate-90" />
                </span>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="ease-in duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <Listbox.Options className="text-base absolute z-10 mt-1 max-h-[400px] w-full list-none overflow-auto rounded-md bg-white py-1 pl-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-caption">
                {fieldOptions?.map((fieldOption, index) => (
                  <Listbox.Option
                    key={`${index}_selectMenu`}
                    className={({ active }) =>
                      classNames(
                        'relative flex max-h-12 cursor-default flex-row items-center py-4 pl-5 pr-4',
                        active && 'bg-neutral-25',
                      )
                    }
                    value={fieldOption}
                  >
                    {SelectOptionComponent ? (
                      <SelectOptionComponent value={fieldOption} />
                    ) : (
                      <>{fieldOption}</>
                    )}
                  </Listbox.Option>
                ))}
                {fieldOptionSections?.map(
                  (fieldOptionSection, sectionIndex) => (
                    <div key={`${sectionIndex}_selectMenuSection`}>
                      <div
                        className={classNames(
                          'px-4 pb-1 pt-3 text-small-caption font-bold text-green-150',
                          sectionIndex !== 0 && 'border-t border-neutral-50',
                        )}
                      >
                        {fieldOptionSection.sectionHeader}
                      </div>
                      {fieldOptionSection.fieldOptions?.map(
                        (fieldOption, index) => (
                          <Listbox.Option
                            key={`${sectionIndex}_${index}_selectMenuOption`}
                            className={({ active }) =>
                              classNames(
                                'relative flex max-h-12 cursor-default flex-row items-center truncate p-4',
                                active && 'bg-neutral-25',
                              )
                            }
                            value={fieldOption}
                          >
                            {SelectOptionComponent ? (
                              <SelectOptionComponent value={fieldOption} />
                            ) : (
                              <>{fieldOption}</>
                            )}
                          </Listbox.Option>
                        ),
                      )}
                    </div>
                  ),
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu;
