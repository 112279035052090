import Cookies from 'js-cookie';
import {
  TokenPayload,
  PatientLoginResponse,
  MeProviderDataFragment,
  ProviderUserRole,
} from '../generated/graphql';
import { Environment } from '../v2/types/config';

const TOKEN_PAYLOAD_KEY = 'hctp';
const PATIENT_TOKEN_PAYLOAD_KEY = 'hctpc';
const AUTH0_ACCESS_TOKEN_KEY = 'hctpa0';

const storePayload = (
  payload: Record<string, string | boolean | number>,
  key: string,
  expires: number,
) => {
  Cookies.set(key, JSON.stringify(payload), {
    // Secure cookies don't work on localhost with Safari, so use non-secure cookies for development.
    secure: process.env.NODE_ENV !== Environment.Development,
    sameSite: 'lax',
    // Backup expiry. Back-end should take priority and expire on an equal
    // or more aggressive timeline.
    expires, // Days
  });
};

function getPayload<T>(key: string): T | null {
  const item = Cookies.get(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
}

export const storeTokenPayload = (tokenPayload: TokenPayload): void => {
  storePayload(tokenPayload, TOKEN_PAYLOAD_KEY, 7);
};

export const storePatientTokenPayload = (
  tokenPayload: PatientLoginResponse,
): void => {
  storePayload(tokenPayload, PATIENT_TOKEN_PAYLOAD_KEY, 1);
};

export const getTokenPayload = (): TokenPayload | null => {
  return getPayload<TokenPayload>(TOKEN_PAYLOAD_KEY);
};

export const getPatientTokenPayload = (): PatientLoginResponse | null => {
  return getPayload<PatientLoginResponse>(PATIENT_TOKEN_PAYLOAD_KEY);
};

export const clearTokenPayload = (): void => {
  Cookies.remove(TOKEN_PAYLOAD_KEY);
};

export const clearPatientTokenPayload = (): void => {
  Cookies.remove(PATIENT_TOKEN_PAYLOAD_KEY);
};

export const storeAuth0AccessToken = (
  accessToken: string,
  isV1?: boolean,
): void => {
  storePayload({ accessToken, isV1 }, AUTH0_ACCESS_TOKEN_KEY, 7);
};

export const getAuth0AccessToken = (): string | null => {
  return getPayload<{ accessToken: string }>(AUTH0_ACCESS_TOKEN_KEY)
    ?.accessToken;
};

export const getIsAuthedV1 = (): boolean | null => {
  return getPayload<{ isV1?: boolean }>(AUTH0_ACCESS_TOKEN_KEY)?.isV1 ?? null;
};

export const clearAuth0AccessToken = (): void => {
  Cookies.remove(AUTH0_ACCESS_TOKEN_KEY);
};

export const isProviderUserOwnerOrAdminAuthorized = (
  providerUser: MeProviderDataFragment | null,
) => {
  return [ProviderUserRole.Owner, ProviderUserRole.Administrator].includes(
    providerUser?.role,
  );
};
