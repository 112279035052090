import { SvgIconComponent } from '../../../types/svgs';

const Play: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fill="white"
        d="M12 21.1783C12 21.9674 12.8712 22.4456 13.5369 22.022L21.6742 16.8437C22.2917 16.4507 22.2917 15.5493 21.6742 15.1563L13.5369 9.97801C12.8712 9.55437 12 10.0326 12 10.8217V21.1783Z"
      />
    </svg>
  );
};

export default Play;
