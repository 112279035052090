import { FC } from 'react';
import ProviderProgramTagManager from '../../components/ProgramTag/ProviderProgramTagManager';

const TagsSection: FC = () => {
  return (
    <ProviderProgramTagManager
      fullPageMode
      className="mb-2 h-[80vh] overflow-y-scroll px-5 py-10"
    />
  );
};

export default TagsSection;
