import { WithOptional } from '../types/utils';
import Button from './Button';
import Modal, { ModalProps } from './Modal';

type ConfirmDeleteModalProps = {
  title?: string;
  actionButtonTitle?: string;
  performDelete: () => Promise<unknown>;
} & WithOptional<ModalProps, 'children'>;

export default function ConfirmDeleteModal({
  title = 'Are you sure you want to delete this?',
  actionButtonTitle = 'Delete',
  children,
  fetching,
  setClosed,
  performDelete,
  ...rest
}: ConfirmDeleteModalProps) {
  return (
    <Modal width="small" fetching={fetching} setClosed={setClosed} {...rest}>
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center px-4 text-center">
        <h1 className="font-serif text-subtitle text-green-150">{title}</h1>
        <div className="mt-4 mb-12 w-full text-center font-sans text-small text-green-125">
          {children || 'This cannot be undone.'}
        </div>

        <div className="mb-6 flex w-full flex-col items-stretch justify-between sm:flex-row sm:items-center">
          <Button
            title="Cancel"
            disabled={fetching}
            theme="secondary"
            onClick={setClosed}
            className="flex-1"
          />

          <Button
            title={actionButtonTitle}
            disabled={fetching}
            onClick={async () => {
              await performDelete();
              setClosed();
            }}
            className="mt-4 flex-1 sm:ml-4 sm:mt-0"
          />
        </div>
      </div>
    </Modal>
  );
}
