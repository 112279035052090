import { DeviceMobileIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import { useSignUpDeepLinkQuery } from '../../../generated/graphql';
import {
  CommonAnalyticsEvent,
  trackPatientEvent,
} from '../../../lib/analytics';
import classNames from 'classnames';

const DownloadAppBanner: FC<{
  className?: string;
}> = ({ className }) => {
  const { data: signUpDeepLink } = useSignUpDeepLinkQuery();

  const trackDownloadClick = () => {
    trackPatientEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: 'Download App',
    });
  };

  return (
    <a
      className={classNames(
        'flex items-center justify-center bg-neutral-50 px-6 py-3',
        className,
      )}
      href={signUpDeepLink?.signUpDeepLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackDownloadClick}
    >
      <DeviceMobileIcon className="mr-3 h-10 w-10 text-green-100" />
      <div className="flex flex-col">
        <div className="text-caption text-neutral-110">
          Get full access to Homecoming
        </div>
        <div className="text-body font-medium text-neutral-150">
          Download the app
        </div>
      </div>
    </a>
  );
};

export default DownloadAppBanner;
