import { ZxcvbnResult } from '@zxcvbn-ts/core';

import { minPasswordStrengthScore } from './password-strength';

export function getPasswordErrorMessage({
  password,
  score,
  feedback,
}: ZxcvbnResult) {
  if (!password) {
    return 'Password is required';
  }

  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  if (score >= minPasswordStrengthScore) {
    return;
  }

  return feedback.warning || 'Password is too weak';
}

export const maxWordLength = (textField: string) => {
  const hasOversizedWords = textField
    .split(' ')
    .some((word) => word.length > 40);

  return hasOversizedWords ? 'Words should be 40 characters or less' : true;
};

export const MIN_TEXT_LENGTH = 6;
export const MAX_TITLE_LENGTH = 100;
export const MAX_PROVIDER_NOTE_LENGTH = 180;

export const activityTitleRegisterOptions = {
  required: { value: true, message: 'Title is required' },
  minLength: {
    value: MIN_TEXT_LENGTH,
    message: `Title must be at least ${MIN_TEXT_LENGTH} characters long`,
  },
  maxLength: {
    value: MAX_TITLE_LENGTH,
    message: `Title must be ${MAX_TITLE_LENGTH} characters or less`,
  },
  validate: maxWordLength,
};
