/** HeadlessUI Transition component */
export const defaultTransitionProps = {
  appear: true,
  enter: 'transition-opacity duration-500 ease-out',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
};

export const defaultMenuTransitionProps = {
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
};

export const DEFAULT_TRANSITION_DURATION = 200;
