import React, { useState } from 'react';
import classNames from 'classnames';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import {
  ABOUT_MAX_LENGTH,
  ROLE_TITLE_MAX_LENGTH,
  SERVICES_MAX_LENGTH,
  StorefrontFormData,
  TAGLINE_MAX_LENGTH,
} from '..';
import InputGroup from '../../../components/InputGroup';
import TextAreaGroup from '../../../components/TextAreaGroup';
import { useAuth } from '../../../../contexts/AuthContext';
import PractitionerBanner from '../../../components/Storefront/PractitionerBanner';
import PractitionerInfoContainer from '../../../components/Storefront/PractitionerInfoContainer';
import PractitionerInfoLeftColumn from '../../../components/Storefront/PractitionerInfoLeftColumn';
import PractitionerInfoRightColumn, {
  DEFAULT_ABOUT,
  DEFAULT_SERVICES,
  DEFAULT_TAGLINE,
} from '../../../components/Storefront/PractitionerInfoRightColumn';
import EditControls from '../../../components/EditControls';

const InformationModule: React.FC<{
  onSubmit: SubmitHandler<StorefrontFormData>;
  onErrors: SubmitErrorHandler<StorefrontFormData>;
  bannerImageUrl?: string;
  onBannerImageChange?: (bannerImageUrl: string) => Promise<void>;
  className?: string;
}> = ({
  onSubmit,
  onErrors,
  bannerImageUrl,
  onBannerImageChange,
  className,
}) => {
  const { authedProviderUser } = useAuth();
  // Use account owner, because the storefront is, for now at least, only made for the account owner
  // not for each individual provider user of the account.
  const providerProfileImageUrl =
    authedProviderUser?.provider?.ownerProfileImageMedia?.url;
  const providerName = authedProviderUser?.provider?.name;

  const { register, handleSubmit, watch, resetField } =
    useFormContext<StorefrontFormData>();

  const watchRoleTitle = watch('roleTitle');
  const watchTagline = watch('tagline');
  const watchAbout = watch('about');
  const watchServices = watch('services');

  const [inEditMode, setInEditMode] = useState(false);

  const handleSaveChanges = () => {
    handleSubmit(onSubmit, onErrors)();
    setInEditMode(false);
  };

  const handleDiscardChanges = () => {
    resetField('roleTitle');
    resetField('tagline');
    resetField('about');
    resetField('services');
    setInEditMode(false);
  };

  return (
    <div
      className={classNames('relative overflow-hidden rounded-xl', className)}
    >
      <PractitionerBanner
        editable
        bannerImageUrl={bannerImageUrl}
        onBannerImageChange={onBannerImageChange}
        avatarName={providerName}
        avatarImageUrl={providerProfileImageUrl}
      />
      {/* Main area */}
      <PractitionerInfoContainer>
        <EditControls
          isEditing={inEditMode}
          onEditClick={() => setInEditMode(true)}
          handleSaveChanges={handleSaveChanges}
          handleDiscardChanges={handleDiscardChanges}
          useAbsolutePosition
        />

        {/* Left column */}
        <PractitionerInfoLeftColumn
          providerName={providerName}
          roleTitle={watchRoleTitle}
          inEditMode={inEditMode}
          editModeChildren={
            <InputGroup
              label="Title & Specialization"
              labelHidden
              placeholder={'Title & Specialization'}
              inputSize="extra-small"
              maxLength={ROLE_TITLE_MAX_LENGTH}
              characterCounter
              maxLengthValue={ROLE_TITLE_MAX_LENGTH}
              currentLengthValue={watchRoleTitle?.length}
              {...register('roleTitle', { maxLength: ROLE_TITLE_MAX_LENGTH })}
            />
          }
          signUpEnabled={false}
        />
        {/* Right column */}
        <PractitionerInfoRightColumn
          tagline={watchTagline}
          about={watchAbout}
          services={watchServices}
          inEditMode={inEditMode}
          editModeTaglineChildren={
            <InputGroup
              label="Tagline"
              labelHidden
              placeholder={DEFAULT_TAGLINE}
              inputSize="medium"
              maxLength={TAGLINE_MAX_LENGTH}
              characterCounter
              maxLengthValue={TAGLINE_MAX_LENGTH}
              currentLengthValue={watchTagline?.length}
              {...register('tagline', { maxLength: TAGLINE_MAX_LENGTH })}
            />
          }
          editModeAboutChildren={
            <TextAreaGroup
              label="About"
              labelHidden
              placeholder={DEFAULT_ABOUT}
              inputSize="small"
              maxLength={ABOUT_MAX_LENGTH}
              characterCounter
              maxLengthValue={ABOUT_MAX_LENGTH}
              currentLengthValue={watchAbout?.length}
              rows={4}
              {...register('about', { maxLength: ABOUT_MAX_LENGTH })}
            />
          }
          editModeServicesChildren={
            <TextAreaGroup
              label="Services"
              labelHidden
              placeholder={DEFAULT_SERVICES}
              inputSize="small"
              maxLength={SERVICES_MAX_LENGTH}
              characterCounter
              maxLengthValue={SERVICES_MAX_LENGTH}
              currentLengthValue={watchServices?.length}
              rows={4}
              {...register('services', { maxLength: SERVICES_MAX_LENGTH })}
            />
          }
        ></PractitionerInfoRightColumn>
      </PractitionerInfoContainer>
    </div>
  );
};

export default InformationModule;
