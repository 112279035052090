import { PhotographIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { FC, useRef } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '../../../contexts/AuthContext';
import { useUploadProviderUserProfileImageMutation } from '../../../generated/graphql';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import ToastAlert from '../../components/ToastAlert';
import Spinner from '../../svgs/Spinner';

const ProfilePhoto: FC = () => {
  const { refreshAuthedProviderUserProfileImage, authedProviderUser } =
    useAuth();
  const [uploadProviderUserProfileImageMutation, { loading }] =
    useUploadProviderUserProfileImageMutation();

  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const profileImage = e.target.files?.[0];

    if (profileImage) {
      try {
        await uploadProviderUserProfileImageMutation({
          variables: {
            file: profileImage,
          },
        });

        refreshAuthedProviderUserProfileImage();

        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Photo updated`}
          />
        ));
      } catch (err) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      }
    }
  };

  const hasExistingPhoto = authedProviderUser?.profileImageMedia?.url;

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4">
        <Avatar size="large" />
      </div>
      <div>
        <Button
          title={
            !loading
              ? !hasExistingPhoto
                ? 'Upload a photo'
                : 'Update your photo'
              : 'Uploading...'
          }
          className="font-bold"
          theme="alt"
          size="small"
          IconComponent={!loading ? PhotographIcon : Spinner}
          disabled={loading}
          iconPosition="left"
          iconClassName={classNames(
            'h-[22px] w-[22px]',
            !loading ? 'text-green-100' : 'text-green-150',
          )}
          noBackground
          noOutline
          onClick={() => {
            fileInputRef.current!.click();
          }}
        />
        {!loading && (
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={onFileUpload}
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePhoto;
