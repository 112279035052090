import { useState } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';

import Alert from '../../components/Alert';
import { useProviderUserMembersQuery } from '../../../generated/graphql';
import Spinner from '../../svgs/Spinner';
import TableTitle from '../../components/TableTitle';
import MembersTable from '../../components/MembersTable';
import Button from '../../components/Button';
import Plus from '../../svgs/Plus';
import AddMembersModal from './AddMembersModal';
import { useAuth } from '../../../contexts/AuthContext';

import ToastAlert from '../../components/ToastAlert';
import { defaultEnterTransitionProps } from '../../lib/animation';

export default function MembersSection() {
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false);

  const { authedProviderUser } = useAuth();

  const {
    data: membersData,
    loading: isLoadingMembers,
    error: membersError,
    refetch: refetchMembers,
  } = useProviderUserMembersQuery();

  const handleAddMembersClick = () => {
    const latestUsedStaffSeats = membersData?.providerUserMembers.length;
    const staffSeats = authedProviderUser?.provider.staffSeats;

    if (
      staffSeats != null &&
      latestUsedStaffSeats != null &&
      latestUsedStaffSeats >= staffSeats
    ) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Can't add more than ${staffSeats} members with your current plan.`}
        />
      ));
      return;
    }

    setIsAddMembersModalOpen(true);
  };

  return (
    <div className="max-w-full px-8 pb-16 md:max-w-4xl">
      <Transition
        show={!isLoadingMembers && !membersError}
        {...defaultEnterTransitionProps}
      >
        <TableTitle
          title="Your team members"
          shownRowsCount={membersData?.providerUserMembers.length}
        >
          <div className="flex flex-row items-center justify-end">
            <Button
              title="Add to team"
              IconComponent={Plus}
              onClick={handleAddMembersClick}
            />
          </div>
        </TableTitle>

        <MembersTable
          providerUserMembers={membersData?.providerUserMembers ?? []}
          refetchMembers={refetchMembers}
        />
      </Transition>

      {isLoadingMembers && <Spinner className="mx-auto mt-4" />}

      {membersError && <Alert message={membersError?.message} />}

      <AddMembersModal
        isOpen={isAddMembersModalOpen}
        existingMembers={membersData?.providerUserMembers ?? []}
        setClosed={() => setIsAddMembersModalOpen(false)}
        refetchMembers={refetchMembers}
      />
    </div>
  );
}
