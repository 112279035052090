import { FC, useState } from 'react';
import classNames from 'classnames';

import { useResetOnboardingMutation } from '../../../generated/graphql';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import { clearOnboardingStepCompleted } from '../../lib/storage';
import { OnboardingStepName } from '../../types/onboarding';

const ResetOnboardingModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  providerUserEmail?: string;
}> = ({ isModalOpen, setClosed, providerUserEmail }) => {
  const [loading, setLoading] = useState(false);

  const [resetOnboardingMutation] = useResetOnboardingMutation();

  const resetOnboarding = async () => {
    try {
      setLoading(true);
      if (providerUserEmail) {
        clearOnboardingStepCompleted(
          providerUserEmail,
          OnboardingStepName.Programs,
        );
      }
      await resetOnboardingMutation();
      setLoading(false);
      window.location.href = '/';
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const closeResetOnboardingModal = () => {
    setClosed();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeResetOnboardingModal}
      fetching={loading}
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-start px-8 pb-4',
        )}
      >
        <div className="flex w-3/4 flex-col items-center justify-start md:w-[450px]">
          <div className="mb-4 text-center font-serif text-subtitle text-green-150">
            Are you sure you want to reset this Provider and redo Onboarding?
          </div>
          <div className="mb-2 text-center text-body text-green-150">
            This test Provider account will lose its CC info and billing
            integration and will need to go through onboarding again
          </div>
        </div>
        <Button
          type="submit"
          title="Reset Onboarding"
          className="my-10"
          IconComponent={ArrowRightLong}
          onClick={resetOnboarding}
        />
      </div>
    </Modal>
  );
};

export default ResetOnboardingModal;
