import { FC } from 'react';
import classNames from 'classnames';
import Tooltip from '../../../components/Tooltip';
import Tag from '../../../svgs/Tag';
import { ProgramTagDataFragment } from '../../../../generated/graphql';

const TooltipTags: FC<{
  tags: ProgramTagDataFragment[];
}> = ({ tags }) => {
  return (
    <div className="flex flex-col gap-y-3">
      {tags.map((tag) => (
        <TooltipTag key={tag.id} tag={tag} />
      ))}
    </div>
  );
};

const TooltipTag: FC<{
  tag: ProgramTagDataFragment;
}> = ({ tag }) => {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <Tag className="h-6 w-6 shrink-0" />
      <div className="text-caption text-neutral-150">{tag.name}</div>
    </div>
  );
};

const RecipientTags: FC<{
  programTags: ProgramTagDataFragment[];
}> = ({ programTags }) => {
  return (
    <Tooltip
      content={<TooltipTags tags={programTags} />}
      placement="bottom"
      showArrow
    >
      <div
        className={classNames(
          'h-[2.5rem] shrink-0 grow-0 overflow-hidden rounded-full border-[2.5px] border-white bg-neutral-50 px-4 text-[1.375rem]',
        )}
      >
        <div
          className={classNames(
            'flex h-full w-full items-center justify-center gap-x-2 font-medium text-neutral-125',
          )}
        >
          <Tag className="h-6 min-w-[24px]" />
          <div>+{programTags.length}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default RecipientTags;
