import classNames from 'classnames';

const DayLabel: React.FC<{
  dayName: string;
  dayNumber: number;
  isToday?: boolean;
}> = ({ dayName, dayNumber, isToday }) => {
  return (
    <div className="flex flex-col items-center justify-start py-3">
      <div className="text-small-caption font-medium text-neutral-110">
        {dayName.toUpperCase()}
      </div>
      <div
        className={classNames(
          'mt-1 flex h-12 w-12 items-center justify-center text-big-label text-green-125',
          isToday && 'rounded-full bg-green-150 text-white',
        )}
      >
        {dayNumber}
      </div>
    </div>
  );
};

export default DayLabel;
