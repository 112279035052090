import { FC, useEffect, useRef, useState } from 'react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { ProgramTagColor } from '../../../generated/graphql';
import {
  ProgramTagFieldNameType,
  TagManagerFormData,
} from './ProviderProgramTagManager';
import { MailIcon, TrashIcon } from '@heroicons/react/outline';
import InputGroup from '../InputGroup';
import IconButton from '../IconButton';
import ProgramTagColorSelector from './ProgramTagColorSelector';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';

type ProgramTagRowProps = {
  field: FieldArrayWithId<TagManagerFormData, 'programTags', 'key'>;
  index: number;
  tagManagerForm: any;
  saveTag: (fieldName: ProgramTagFieldNameType) => void;
  handleMailAction: (
    field: FieldArrayWithId<TagManagerFormData, 'programTags', 'key'>,
  ) => void;
  handleDeleteAction: (
    field: FieldArrayWithId<TagManagerFormData, 'programTags', 'key'>,
    index: number,
  ) => void;
};

const ProgramTagRow: FC<ProgramTagRowProps> = ({
  field,
  index,
  saveTag,
  handleMailAction,
  handleDeleteAction,
}) => {
  const programTagRowRef = useRef<HTMLDivElement | null>(null);

  const tagManagerForm = useFormContext();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        programTagRowRef.current &&
        !programTagRowRef.current.contains(event.target as Node)
      ) {
        setShowConfirmDelete(false);
      }
    }

    // Attach the click event handler
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [programTagRowRef]);

  return (
    <>
      <div
        key={field.key}
        className="flex w-full items-center gap-x-3"
        ref={programTagRowRef}
      >
        <InputGroup
          label="Name"
          placeholder="Name"
          required
          inputSize="small"
          labelHidden
          maxLength={120}
          containerClassName="w-[200px] min-w-[200px] max-w-[400px]"
          {...tagManagerForm.register(`programTags.${index}.name`, {
            required: 'Tag name is required',
            setValueAs: (value) => value.trim(),
            onBlur: (e) => {
              saveTag(e.target.name);
            },
          })}
        />
        <InputGroup
          label="Description"
          placeholder="Description"
          inputSize="small"
          labelHidden
          maxLength={255}
          containerClassName="min-w-[400px] grow"
          {...tagManagerForm.register(`programTags.${index}.description`, {
            setValueAs: (value) => value.trim(),
            onBlur: (e) => {
              saveTag(e.target.name);
            },
          })}
        />
        <div className="w-[60px] flex-none">
          <Controller
            name={`programTags.${index}.color`}
            control={tagManagerForm.control}
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Tag description length limit reached.',
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <ProgramTagColorSelector
                selectedColor={value}
                onColorChange={(programTagColor: ProgramTagColor) => {
                  onChange(programTagColor);
                  saveTag(name);
                }}
              />
            )}
          />
        </div>
        <div className="flex w-[120px] flex-none flex-row gap-x-3">
          {!showConfirmDelete && (
            <IconButton
              IconComponent={MailIcon}
              aria-label="Mail"
              className="!rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75"
              iconClassName="h-5 w-5"
              disabled={!field.id || field.programCount === 0}
              onClick={() => handleMailAction(field)}
            />
          )}
          {showConfirmDelete ? (
            <Button
              title="Delete"
              size="small"
              aria-label="Confirm delete"
              IconComponent={TrashIcon}
              iconClassName="h-5 w-5 text-white"
              className="w-[120px] !rounded-full bg-red-100 py-2 px-4 hover:bg-red-125"
              onClick={() => {
                setShowConfirmDelete(false);
                handleDeleteAction(field, index);
              }}
            />
          ) : (
            <IconButton
              IconComponent={TrashIcon}
              aria-label="Delete"
              className="!rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75"
              iconClassName="h-5 w-5 text-red-100"
              onClick={() => setShowConfirmDelete(true)}
            />
          )}
        </div>
      </div>
      {tagManagerForm.formState.errors?.programTags?.[index]?.name && (
        <ErrorMessage>
          {tagManagerForm.formState.errors?.programTags?.[index]?.name?.message}
        </ErrorMessage>
      )}
    </>
  );
};

export default ProgramTagRow;
