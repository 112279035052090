import { FC } from 'react';
import isEmpty from 'lodash.isempty';

import { AssessmentType } from '../../../../generated/graphql';
import SlideoverSection from '../../SlideoverSection';
import { CompletedProgramAssessment, getScoreProps } from '../helpers';
import Score from './Score';

const ScoreSection: FC<{
  assessmentType?: AssessmentType | undefined;
  completedAssessment?: CompletedProgramAssessment | undefined;
  title?: string;
}> = ({ assessmentType, completedAssessment, title = 'Score' }) => {
  const { primary, secondary } = getScoreProps(
    completedAssessment,
    assessmentType,
  );

  return (
    <SlideoverSection title={title}>
      {!isEmpty(primary) && <Score {...primary} />}
      {!isEmpty(secondary) && <Score {...secondary} />}
    </SlideoverSection>
  );
};

export default ScoreSection;
