import { SvgIconComponent } from '../types/svgs';

// TODO: this SVG appears to be slightly off center. Also, we should be using standard sizing, with icons taken directly from Figma. Usually 24x24
const CloseX: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
    >
      <path d="M8.59 0L5.70711 2.88289C5.31658 3.27342 4.68342 3.27342 4.29289 2.88289L1.41 0L0 1.41L2.88289 4.29289C3.27342 4.68342 3.27342 5.31658 2.88289 5.70711L0 8.59L1.41 10L4.29289 7.11711C4.68342 6.72658 5.31658 6.72658 5.70711 7.11711L8.59 10L10 8.59L7.11711 5.70711C6.72658 5.31658 6.72658 4.68342 7.11711 4.29289L10 1.41L8.59 0Z" />
    </svg>
  );
};

export default CloseX;
