import { FC, Fragment } from 'react';
import classNames from 'classnames';

import { Menu, Transition } from '@headlessui/react';
import {
  CogIcon,
  DocumentDuplicateIcon,
  MinusSmIcon,
  PlusSmIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import {
  ProviderCollection,
  ProviderCollections,
} from '../../../types/providerCollection';
import { ContextMenuPosition } from '../../../types/contextMenu';

import MenuButton from '../../../components/MenuButton';
import {
  MENU_OFFSET_Y_PROPORTION,
  MENU_OFFSET_X_PROPORTION,
} from '../../../lib/window';
import { defaultMenuTransitionProps } from '../../../lib/animation';

import PencilSquare from '../../../svgs/PencilSquare';
import { isStandardAssessment } from '../../../lib/assessments';
import { ActivityDataFragment } from '../../../../generated/graphql';
import { isProviderActivityPDF } from '../../../types/activity';

const MENU_ROW_COUNT = 3;
const HEIGHT_PADDING = 10;
const MENU_WIDTH_PIXELS = 256;

type ActivityActionsMenuProps = {
  isOpen?: boolean;
  setIsOpen?: React.MouseEventHandler<HTMLButtonElement>;
  position: ContextMenuPosition;
  providerActivity?: ActivityDataFragment;
  providerCollections: ProviderCollections | undefined;
  selectedProviderCollection: ProviderCollection | undefined;
  addActivityToProviderCollection: (
    providerCollection: ProviderCollection,
    providerActivity: ActivityDataFragment,
  ) => Promise<void>;
  onClickRemoveActivityFromProviderCollection?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddActivityToProviderCollection?: React.MouseEventHandler<HTMLButtonElement>;
  onClickArchiveResource?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDuplicateResource?: React.MouseEventHandler<HTMLButtonElement>;
  onClickRenameResource?: React.MouseEventHandler<HTMLButtonElement>;
  onClickModifyFileSettings?: React.MouseEventHandler<HTMLButtonElement>;
  setSelectedProviderActivity: React.Dispatch<
    React.SetStateAction<ActivityDataFragment | undefined>
  >;
  setShowActivityActionsMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const ActivityActionsMenu: FC<ActivityActionsMenuProps> = ({
  isOpen,
  setIsOpen,
  providerActivity,
  providerCollections,
  selectedProviderCollection,
  addActivityToProviderCollection,
  onClickRemoveActivityFromProviderCollection,
  onClickAddActivityToProviderCollection,
  onClickArchiveResource,
  onClickDuplicateResource,
  onClickRenameResource,
  onClickModifyFileSettings,
  position,
  setShowActivityActionsMenu,
}) => {
  const screenHeight = document.body.scrollHeight;
  const screenWidth = document.body.scrollWidth;

  const menuPositionXRight = Boolean(
    screenWidth * MENU_OFFSET_X_PROPORTION < position.x,
  );
  const menuPositionYAbove = Boolean(
    screenHeight * MENU_OFFSET_Y_PROPORTION < position.y,
  );

  const positionXAdjusted = position.x + HEIGHT_PADDING;

  const providerCollectionCount = providerCollections?.length ?? 0;
  const threeOrLessCollectionOptions = Boolean(
    providerCollectionCount - 1 < MENU_ROW_COUNT, // -1 bc `selectedCollection` is filtered from list
  );

  const isAssessment = isStandardAssessment(providerActivity);
  const isFile = isProviderActivityPDF(providerActivity);

  return (
    <div className="z-30 flex">
      <Menu as="div" className="inline-block text-left">
        <Transition show={isOpen} as={Fragment} {...defaultMenuTransitionProps}>
          <Menu.Items
            className="absolute right-0 z-30 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md border border-neutral-100 bg-white shadow-lg focus:outline-none"
            style={{
              left: menuPositionXRight
                ? positionXAdjusted - MENU_WIDTH_PIXELS
                : positionXAdjusted,
              ...(menuPositionYAbove
                ? {
                    bottom: screenHeight - position.y,
                  }
                : {
                    top: position.y + HEIGHT_PADDING,
                  }),
            }}
            static
          >
            <Menu as={Fragment}>
              <Menu.Button as={Fragment}>
                <button
                  className="flex w-full flex-row items-center justify-between rounded-t-md px-3 py-4 text-caption text-green-125 hover:bg-neutral-50"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Add to collection
                  <PlusSmIcon className="h-[16px] w-[16px] text-green-150" />
                </button>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={classNames(
                    'absolute z-30 w-64 rounded-md border border-neutral-100 bg-white shadow-lg focus:outline-none',
                    menuPositionXRight ? 'right-[254px]' : '-right-[254px]',
                    !menuPositionYAbove && '-top-[1px]',
                    menuPositionYAbove &&
                      !selectedProviderCollection &&
                      'bottom-0',
                    menuPositionYAbove &&
                      selectedProviderCollection &&
                      threeOrLessCollectionOptions &&
                      '-top-[1px]',
                    menuPositionYAbove &&
                      selectedProviderCollection &&
                      !threeOrLessCollectionOptions &&
                      'bottom-0',
                  )}
                >
                  <MenuButton
                    titleText="Create a collection"
                    className="rounded-t-md border-b border-neutral-50"
                    onClick={(e) => {
                      onClickAddActivityToProviderCollection?.(e);
                      setShowActivityActionsMenu(false);
                      e.stopPropagation();
                    }}
                    iconClassName="text-green-150 h-[16px] w-[16px]"
                    IconComponent={PlusSmIcon}
                  />
                  <div className="max-h-72 overflow-y-auto">
                    {providerCollections
                      ?.filter(
                        (providerCollection) =>
                          providerCollection !== selectedProviderCollection,
                      )
                      .map((providerCollection, index) => {
                        const lastProviderCollection =
                          providerCollections.length === index - 1;

                        return (
                          <MenuButton
                            key={`${providerCollection.name}_${index}`}
                            titleText={providerCollection.name}
                            className={classNames(
                              lastProviderCollection && 'rounded-b-md',
                            )}
                            onClick={async (e) => {
                              e.stopPropagation();
                              setIsOpen?.(e);
                              if (providerActivity)
                                await addActivityToProviderCollection(
                                  providerCollection,
                                  providerActivity,
                                );
                            }}
                          />
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {selectedProviderCollection && (
              <MenuButton
                titleText="Remove from this collection"
                iconClassName="text-green-150 h-[16px] w-[16px]"
                IconComponent={MinusSmIcon}
                onClick={(e) => {
                  onClickRemoveActivityFromProviderCollection?.(e);
                  e.stopPropagation();
                  setShowActivityActionsMenu(false);
                }}
              />
            )}
            {!isAssessment && (
              <>
                <MenuButton
                  titleText="Duplicate"
                  iconClassName="text-green-150 h-[12px] w-[12px]"
                  IconComponent={DocumentDuplicateIcon}
                  onClick={(e) => {
                    onClickDuplicateResource?.(e);
                    setShowActivityActionsMenu(false);
                    e.stopPropagation();
                  }}
                />
                <MenuButton
                  titleText="Rename"
                  iconClassName="text-green-150 h-[12px] w-[12px]"
                  IconComponent={PencilSquare}
                  onClick={(e) => {
                    onClickRenameResource?.(e);
                    setShowActivityActionsMenu(false);
                    e.stopPropagation();
                  }}
                />
                {isFile && (
                  <MenuButton
                    titleText="Modify file settings"
                    iconClassName="text-green-150 h-[12px] w-[12px]"
                    IconComponent={CogIcon}
                    onClick={(e) => {
                      onClickModifyFileSettings?.(e);
                      setShowActivityActionsMenu(false);
                      e.stopPropagation();
                    }}
                  />
                )}
                <MenuButton
                  titleText="Delete resource"
                  iconClassName="text-red-100 h-[12px] w-[12px]"
                  className="rounded-b-md text-red-100"
                  activeText="text-red-100"
                  IconComponent={TrashIcon}
                  onClick={(e) => {
                    onClickArchiveResource?.(e);
                    setShowActivityActionsMenu(false);
                    e.stopPropagation();
                  }}
                />
              </>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ActivityActionsMenu;
