import { FC, useState } from 'react';
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import toast from 'react-hot-toast';

import Button from '../../../../../components/Button';
import ErrorMessage from '../../../../../components/ErrorMessage';
import { MODAL_TRANSITION_DURATION } from '../../../../../components/Modal';
import ToastAlert from '../../../../../components/ToastAlert';
import {
  PatientDataPermission,
  ProviderUserMemberDataFragment,
  ProviderUserRole,
  useSendProviderUserInvitesMutation,
} from '../../../../../../generated/graphql';
import { pluralize } from '../../../../../lib/copy';
import { removeEmptyFields } from '../../../../../lib/form';
import { AddTeamMembersFormData } from '../../../../../types/teamMembers';
import ModalDialog from '../../../../../components/ModalDialog';
import TeamMemberInputRow from './TeamMemberInputRow';
import { PlusSmIcon } from '@heroicons/react/outline';

type AddTeamMembersModalProps = {
  setClosed: () => void;
  isOpen: boolean;
  existingTeamMembers: ProviderUserMemberDataFragment[];
  refetchTeamMembers: () => Promise<unknown>;
};

export const defaultFieldSchema = {
  email: '',
  role: ProviderUserRole.Practitioner,
  patientDataPermission: PatientDataPermission.Assigned,
};

const AddTeamMembersModal: FC<AddTeamMembersModalProps> = ({
  isOpen,
  setClosed,
  existingTeamMembers,
  refetchTeamMembers,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [
    sendProviderUserInvitesMutation,
    { error: sendInvitesError, reset: resetProviderUserInvitesMutation },
  ] = useSendProviderUserInvitesMutation();

  const addTeamMembersForm = useForm<AddTeamMembersFormData>({
    mode: 'onSubmit',
    defaultValues: {
      members: [defaultFieldSchema],
    },
  });
  const {
    reset: resetForm,
    control,
    clearErrors: clearFormErrors,
    handleSubmit,
    watch,
  } = addTeamMembersForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members',
  });

  const onSubmit: SubmitHandler<AddTeamMembersFormData> = async (formData) => {
    clearFormErrors();
    setIsLoading(true);

    try {
      const providerUserInvites = formData.members;

      await sendProviderUserInvitesMutation({
        variables: { providerUserInvites },
      });

      await refetchTeamMembers();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully sent ${pluralize(
            providerUserInvites.length,
            'an invite',
            'invites',
            false,
          )} to ${pluralize(
            providerUserInvites.length,
            'new member',
            'new members',
          )}!`}
        />
      ));
      closeAndResetModal();
    } catch (err) {
      // Already displaying server error message in UI
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveRow = (index: number) => {
    remove(index);
  };

  const closeAndResetModal = async () => {
    setClosed();
    setTimeout(() => {
      resetForm();
      resetProviderUserInvitesMutation();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <ModalDialog
      title="Add Team Members"
      width="large"
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      hideFooter
      fetching={isLoading}
      className="min-h-[80vh]"
    >
      <FormProvider {...addTeamMembersForm}>
        <form className="px-10 pb-4" onSubmit={(e) => e.preventDefault()}>
          <div className="mb-2 flex w-full flex-row text-small-caption font-bold text-green-150">
            <div className="w-1/2">Email address</div>
            <div className="w-1/4">Role</div>
            <div className="w-1/4">Client health data</div>
            {/* Spacer for Remove button, otherwise will have to refactor to table */}
            <div className="w-[30px]" />
          </div>
          <div className="flex h-[60vh] w-full flex-col gap-y-3">
            {fields.map((field, fieldIndex) => (
              <TeamMemberInputRow
                key={`members.${fieldIndex}`}
                formFields={fields}
                fieldIndex={fieldIndex}
                handleRemoveRow={handleRemoveRow}
                existingTeamMembers={existingTeamMembers}
                watch={watch}
              />
            ))}

            <ErrorMessage className="mb-4">
              {sendInvitesError?.message}
            </ErrorMessage>
          </div>

          <div className="mt-6 flex w-full flex-row items-center justify-between">
            <Button
              title="Add Team Member"
              IconComponent={PlusSmIcon}
              iconPosition="left"
              theme="secondary"
              onClick={() =>
                append({
                  email: '',
                  role: ProviderUserRole.Practitioner,
                  patientDataPermission: PatientDataPermission.Assigned,
                })
              }
            />
            <div className="flex items-center gap-x-3">
              <div className="text-small-caption font-medium text-neutral-110">
                Your teammates will be invited by email.
              </div>
              <Button
                title="Done"
                theme="primary"
                disabled={isLoading}
                onClick={() => {
                  removeEmptyFields(fields, remove);
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default AddTeamMembersModal;
