import { FC } from 'react';
import { getTimeStamp } from '../../../lib/time';
import {
  ProgramInvoiceDataFragment,
  ProgramInvoiceStatus,
} from '../../../../generated/graphql';
import { formatCurrency } from '../../../lib/currency';
import classNames from 'classnames';
import Avatar from '../../../components/Avatar';
import InvoiceMenu from './InvoiceMenu';
import { camelCaseToCapitalizedWords } from '../../../lib/string';
import Spinner from '../../../svgs/Spinner';

export type InvoiceRowMutationProps = {
  onClickSendReminder: (progamInvoice: ProgramInvoiceDataFragment) => void;
  onClickVoidInvoice: (progamInvoice: ProgramInvoiceDataFragment) => void;
  actionLoadingInvoiceId: string | null;
};

const InvoiceRow: FC<
  {
    programInvoice: ProgramInvoiceDataFragment;
    onClickDownloadInvoice: (
      programInvoice: ProgramInvoiceDataFragment,
    ) => void;
  } & InvoiceRowMutationProps
> = ({
  programInvoice,
  onClickDownloadInvoice,
  onClickSendReminder,
  onClickVoidInvoice,
  actionLoadingInvoiceId,
}) => {
  const isActionLoading = actionLoadingInvoiceId === programInvoice.id;

  return (
    <tr className="h-[72px] text-ellipsis text-caption text-neutral-125 hover:bg-neutral-25">
      <td className="w-48">
        <span className="font-medium text-green-150">
          {formatCurrency(programInvoice.amount, programInvoice.currency)}{' '}
        </span>
        {programInvoice.currency.toUpperCase()}
      </td>

      <td className="w-16">
        <span
          className={classNames(
            'whitespace-nowrap rounded-full py-1 px-2 text-center',
            programInvoice.status === ProgramInvoiceStatus.Pending &&
              'bg-orange-25 text-neutral-150',
            programInvoice.status === ProgramInvoiceStatus.PastDue &&
              'bg-red-25 text-neutral-150',
            programInvoice.status === ProgramInvoiceStatus.Paid &&
              'bg-green-25 text-green-125',
            programInvoice.status === ProgramInvoiceStatus.Void &&
              'bg-neutral-100 text-neutral-150',
          )}
        >
          {camelCaseToCapitalizedWords(programInvoice.status)}
        </span>
      </td>

      <td className="w-[150px] text-neutral-110">
        {programInvoice.stripeInvoiceNumber}
      </td>

      <td className="min-w-xs">
        <div className="flex flex-row items-center justify-start">
          <Avatar
            size="medium"
            imageUrl={programInvoice.patient?.profileImageMedia?.url}
            name={programInvoice.patient?.name ?? 'Client'}
          />
          <div className="ml-4 flex flex-col items-start justify-start">
            <div className="text-caption font-medium text-neutral-150">
              {programInvoice.patient?.name}
            </div>
            <div className="text-small-caption text-neutral-125">
              {programInvoice.patient?.email}
            </div>
          </div>
        </div>
      </td>

      <td className="w-40">{getTimeStamp(programInvoice.createdAt, false)}</td>

      <td className="w-40">{getTimeStamp(programInvoice.dueAt, false)}</td>

      <td className="w-5">
        {isActionLoading ? (
          <Spinner className="h-5 w-5 text-neutral-125" />
        ) : (
          <InvoiceMenu
            onClickSendReminder={() => onClickSendReminder(programInvoice)}
            onClickDownloadInvoice={() =>
              onClickDownloadInvoice(programInvoice)
            }
            onClickVoidInvoice={() => onClickVoidInvoice(programInvoice)}
            invoiceStatus={programInvoice.status}
          />
        )}
      </td>
    </tr>
  );
};

export default InvoiceRow;
