import { Transition } from '@headlessui/react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useProgramFollowUpsQuery } from '../../../../generated/graphql';

import { defaultTransitionProps } from '../../../lib/animation';

import IllustrationMail from '../../../svgs/IllustrationMail';
import Spinner from '../../../svgs/Spinner';

import Button from '../../../components/Button';

import { usePatientProfileContext } from '..';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import { useEffect } from 'react';
import { customToast } from '../../../components/ToastAlert/customToast';
import FollowUpSelector from '../../../components/FollowUpSelector';

const FollowUps = () => {
  const navigate = useNavigate();
  const params = useParams();
  const programId = params.programId!;
  const programFollowUpId = params.programFollowUpId;

  useEffect(() => {
    if (!programFollowUpId && programFollowUpsData && !programFollowUpsLoading)
      navigate(-1);
  }, [programFollowUpId]);

  const { patient } = usePatientProfileContext();

  const {
    data: programFollowUpsData,
    error: programFollowUpsError,
    loading: programFollowUpsLoading,
    refetch: refetchProgramFollowUps,
  } = useProgramFollowUpsQuery({
    variables: {
      programId,
    },
    fetchPolicy: 'network-only', // Enable cache updates
    onCompleted: (data) => {
      const programFollowUps = data.programFollowUps;
      const isRootFetch = !programFollowUpId;
      if (isRootFetch && programFollowUps.length > 0) {
        navigate(`${programFollowUps[0].id}`);
      }
    },
    onError: (error) => {
      console.error('Error fetching follow-ups:', error);
      customToast.error('Unable to fetch your mail.');
    },
  });

  const programFollowUps = programFollowUpsData?.programFollowUps;

  return (
    <>
      <Transition
        show={Boolean(programFollowUpsData && !programFollowUpsError)}
        {...defaultTransitionProps}
        className="flex h-full w-full flex-grow flex-row items-start border-neutral-75"
      >
        <>
          {!Boolean(programFollowUps?.length) ? (
            <EmptyProfileTab
              patientFirstName={patient?.firstName ?? ''}
              IconComponent={IllustrationMail}
              iconClassName={'text-green-100'}
              titleText="mail"
              subTitleText="Share insights, helpful resources, and activities"
              onClickButton={() => {
                navigate(`/${patient?.programInstanceId}/intake/new`);
              }}
              buttonTitle={`Send mail to ${patient?.firstName}`}
            />
          ) : (
            <>
              <div className="flex h-full w-1/6 min-w-[188px] flex-col items-center justify-start border-r border-neutral-75">
                <div className="flex w-full flex-row items-center justify-center py-4 pr-5">
                  <Button
                    IconComponent={PlusSmIcon}
                    noFocus
                    title="Compose"
                    iconClassName="h-[20px] w-[20px]"
                    className="my-1 w-full justify-between rounded-md pl-5 text-left"
                    onClick={() => {
                      navigate(`/${patient?.programInstanceId}/follow-ups/new`);
                    }}
                  />
                </div>
                <div className="flex h-full w-full flex-col items-center justify-start gap-y-2 overflow-y-auto overscroll-y-contain pb-4 pr-4">
                  {programFollowUps?.map((programFollowUp) => (
                    <FollowUpSelector
                      key={`followUpSelector-${programFollowUp.id}`}
                      id={programFollowUp.id}
                      isIntake={programFollowUp.isIntake}
                      subject={programFollowUp.subject}
                      providerNote={programFollowUp.providerNote}
                      createdAt={programFollowUp.createdAt}
                      unreadCommentCount={programFollowUp.unreadCommentCount}
                      isSelected={programFollowUpId === programFollowUp.id}
                      onClick={() => navigate(programFollowUp.id)}
                    />
                  ))}
                </div>
              </div>
              <div className="h-full w-full overflow-hidden">
                <Outlet
                  context={{
                    patient,
                    refetchProgramFollowUps,
                  }}
                />
              </div>
            </>
          )}
        </>
      </Transition>

      {programFollowUpsLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default FollowUps;
