import { SvgIconComponent } from '../types/svgs';

const HelpQuestion: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.401 9.67801H8.39905V9.46801C8.44105 8.66068 8.78405 8.00735 9.42805 7.50801C10.0767 7.00401 10.9774 6.75201 12.13 6.75201C13.2874 6.75201 14.1787 6.99935 14.804 7.49401C15.4294 7.98868 15.7397 8.62101 15.735 9.39101C15.7304 9.87635 15.6324 10.2823 15.441 10.609C15.2497 10.9357 15 11.211 14.692 11.435C14.384 11.659 14.0574 11.862 13.712 12.044L13.572 12.121C13.3434 12.2423 13.1707 12.3753 13.054 12.52C12.9374 12.6647 12.879 12.9027 12.879 13.234V13.836H10.926V13.073C10.926 12.583 11.0147 12.184 11.192 11.876C11.374 11.568 11.6914 11.2927 12.144 11.05L12.494 10.868C12.8674 10.6673 13.1544 10.4597 13.355 10.245C13.5604 10.0303 13.663 9.74568 13.663 9.39101C13.663 9.02235 13.523 8.73068 13.243 8.51601C12.9677 8.30135 12.5874 8.19401 12.102 8.19401C11.6167 8.19401 11.2154 8.31301 10.898 8.55101C10.5807 8.78901 10.415 9.16468 10.401 9.67801ZM10.814 15.005H12.991V17H10.814V15.005Z"
        fill="currentColor"
      />
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default HelpQuestion;
