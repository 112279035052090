import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';

const options = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
    // Restricted words
    userInputs: ['homecoming'],
  },
};

zxcvbnOptions.setOptions(options);

export function checkPasswordStrength(password: string) {
  return zxcvbn(password);
}

export const minPasswordStrengthScore = 3;
