import { FC, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  usePublicPdfSubmissionQuery,
  useSubmitPdfFormMutation,
} from '../../generated/graphql';
import UnauthedHeader from '../components/Headers/UnauthedHeader';
import PageContainer from '../components/Containers/PageContainer';
import IllustrationWarning from '../svgs/IllustrationWarning';
import Loading from './Loading';
import { ContentType } from '../lib/followUp';
import { getTimeStamp } from '../lib/time';
import Button from '../components/Button';
import { DownloadIcon, UploadIcon } from '@heroicons/react/outline';
import IllustrationCheckmark from '../svgs/IllustrationCheckMark';
import ToastAlert from '../components/ToastAlert';
import toast from 'react-hot-toast';
import { MAX_UPLOAD_SIZE_BYTES } from '../lib/media';
import Spinner from '../svgs/Spinner';
import { useForm } from 'react-hook-form';
import ErrorMessage from '../components/ErrorMessage';
import ContentPreview from '../components/ContentPreview';

interface UploadFileFormData {
  uploadedFile: FileList | null;
}

const ClientPdfSubmission: FC = () => {
  const [searchParams] = useSearchParams();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const pdfSubmissionLinkToken = searchParams.get('token');

  const { register, formState, watch, handleSubmit, reset, getFieldState } =
    useForm<UploadFileFormData>({
      mode: 'onChange',
      defaultValues: {
        uploadedFile: null,
      },
    });

  const { ref: uploadedFileRef, ...uploadedFileRegister } = register(
    'uploadedFile',
    {
      required: 'Please select a file to upload',
      validate: (fileList) => {
        if (fileList) {
          const file = fileList?.[0];
          if (file.size > MAX_UPLOAD_SIZE_BYTES) {
            return "That file is a bit too big. Try one that's smaller than 100 MB";
          }
        }
      },
    },
  );

  const uploadedFile = watch('uploadedFile')?.[0];
  const hasValidUploadedFile =
    uploadedFile && !getFieldState('uploadedFile').error;

  const [hasFileBeenSubmitted, setHasFileBeenSubmitted] = useState(false);

  const { data, error, loading } = usePublicPdfSubmissionQuery({
    variables: {
      pdfSubmissionLinkToken: pdfSubmissionLinkToken!, // We know it exists because of the skip below
    },
    skip: !pdfSubmissionLinkToken,
  });

  const [submitPdfForm, { loading: submitPdfFormLoading }] =
    useSubmitPdfFormMutation();

  const publicPdfSubmissionData = data?.publicPdfSubmission;

  const onSubmit = async (formData: UploadFileFormData) => {
    const file = formData.uploadedFile?.item(0);
    if (file) {
      try {
        await submitPdfForm({
          variables: {
            pdfSubmissionLinkToken: pdfSubmissionLinkToken!,
            file,
          },
        });
        setHasFileBeenSubmitted(true);
      } catch (err) {
        console.error(err);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong submitting the file. Please try again and contact support@homecoming.health if issue continues."
            level="error"
          />
        ));
      }
    }
  };

  return (
    <>
      <UnauthedHeader />
      <PageContainer containerClassName="pt-16">
        {(!pdfSubmissionLinkToken || error) && (
          <div className="flex flex-col items-center">
            <IllustrationWarning className="mt-14 mb-6 text-center text-orange-100" />
            <div className="mb-3 text-center font-serif text-subtitle font-light text-neutral-150">
              Uh oh!
            </div>
            <div className="w-2/3 text-center text-paragraph text-neutral-125">
              The link to upload this document is invalid or expired. Please
              open the file form in your Homecoming mobile app and select
              “Complete on a computer” to send yourself a new email.
            </div>
          </div>
        )}
        {loading && <Loading />}
        {publicPdfSubmissionData &&
          (publicPdfSubmissionData.submittedAt || hasFileBeenSubmitted ? (
            <div className="flex flex-col items-center">
              <IllustrationCheckmark className="mt-14 mb-6 text-center text-green-100" />
              <div className="mb-3 text-center font-serif text-subtitle font-light text-neutral-150">
                Thanks!
              </div>
              <div className="w-2/3 text-center text-paragraph text-neutral-125">
                {publicPdfSubmissionData?.providerFirstName} received the file
                file you uploaded. You can close this window now.
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <div className="flex max-w-[512px] flex-col">
                <div className="mt-8 mb-2 font-serif text-subtitle-small text-green-150">
                  Download and submit “{publicPdfSubmissionData.title}”
                </div>
                <div className="mb-6 text-caption text-neutral-125">
                  To keep your information secure, this page will only be
                  available until{' '}
                  {getTimeStamp(publicPdfSubmissionData.expiresAt)}
                </div>
                <form>
                  <div className="divide-y divide-neutral-75 border-y border-neutral-75">
                    <div className="py-6">
                      <div className="mb-2 text-small-action font-bold uppercase text-green-100">
                        Step 1
                      </div>
                      <div className="mb-2 text-body font-medium text-green-150">
                        Download {publicPdfSubmissionData.title}
                      </div>
                      <div className="mb-5 text-caption text-neutral-125">
                        Click the button below to download the file to your
                        computer.
                      </div>
                      <Button
                        title="Download file"
                        theme="secondary"
                        IconComponent={DownloadIcon}
                        onClick={() => {
                          window.open(
                            publicPdfSubmissionData.pdfUrl,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      />
                    </div>
                    <div className="py-6">
                      <div className="mb-2 text-small-action font-bold uppercase text-green-100">
                        Step 2
                      </div>
                      <div className="mb-2 text-body font-medium text-green-150">
                        Upload completed {publicPdfSubmissionData.title}
                      </div>
                      <div className="mb-5 text-caption text-neutral-125">
                        Once you've downloaded and filled out the file above,
                        you can upload the completed version here.
                      </div>
                      {!hasValidUploadedFile ? (
                        <Button
                          title="Upload file"
                          theme="secondary"
                          IconComponent={
                            submitPdfFormLoading ? Spinner : UploadIcon
                          }
                          onClick={() => {
                            inputRef.current?.click();
                          }}
                          disabled={submitPdfFormLoading}
                        />
                      ) : (
                        <ContentPreview
                          contentType={ContentType.PdfForm}
                          title={uploadedFile.name}
                          disableClick
                          onClickCloseX={() => {
                            reset();
                          }}
                        />
                      )}
                      <input
                        type="file"
                        accept="*"
                        className="hidden"
                        ref={(e) => {
                          // Maintains React Hook Form ref, but additionally sets our own inputRef
                          // so that we can trigger the click event on the input from the button
                          uploadedFileRef(e);
                          inputRef.current = e;
                        }}
                        {...uploadedFileRegister}
                      />
                      <ErrorMessage className="mt-2">
                        {formState.errors.uploadedFile?.message}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div>
                    <Button
                      title="Submit completed file"
                      theme="primary"
                      {...(submitPdfFormLoading && {
                        IconComponent: Spinner,
                      })}
                      onClick={handleSubmit(onSubmit)}
                      disabled={!hasValidUploadedFile || submitPdfFormLoading}
                      className="mt-6 w-auto"
                    />
                  </div>
                </form>
              </div>
            </div>
          ))}
      </PageContainer>
    </>
  );
};

export default ClientPdfSubmission;
