import React, { FC, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useStripeCheckoutSuccessMutation } from '../../../generated/graphql';
import { useAuth } from '../../../contexts/AuthContext';
import Loading from '../../pages/Loading';

const BillingSuccess: FC = () => {
  const [stripeCheckoutSuccess] = useStripeCheckoutSuccessMutation();

  const navigate = useNavigate();
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [searchParams] = useSearchParams();

  const redirect = () => {
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo === 'onboarding') {
      navigate('/onboarding');
    } else {
      navigate('/settings/billing');
    }
  };

  const checkout = async (checkoutSessionId: string) => {
    try {
      await stripeCheckoutSuccess({
        variables: {
          checkoutSessionId,
        },
      });
      await refreshAuthedProviderUser();
      redirect();
    } catch (err) {
      alert(
        'Failed to connect your card for billing. Please refresh to try again or contact support@homecoming.health',
      );
      throw err;
    }
  };

  useEffect(() => {
    if (!authedProviderUser) {
      return;
    }

    const checkoutSessionId = searchParams.get('session_id');

    if (authedProviderUser.isProviderSubscribed || !checkoutSessionId) {
      redirect();
      return;
    }

    checkout(checkoutSessionId);
  }, [authedProviderUser]);

  return <Loading />;
};

export default BillingSuccess;
