import { FC } from 'react';
import { Transition } from '@headlessui/react';

import classNames from 'classnames';
import { BasicProviderUserMemberDataFragment } from '../../../../../../generated/graphql';
import { defaultTransitionProps } from '../../../../../lib/animation';
import Spinner from '../../../../../svgs/Spinner';
import ProviderUserRow from './ProviderUserRow';

type AssignedProviderUsersListProps = {
  providerUsers: BasicProviderUserMemberDataFragment[];
  handleRemoveProviderUserFromPatientFromPatient: (
    providerUserId: string,
  ) => Promise<void>;
};

const AssignedProviderUsersList: FC<AssignedProviderUsersListProps> = ({
  providerUsers,
  handleRemoveProviderUserFromPatientFromPatient,
}) => {
  return (
    <div className="max-h-[600px] w-full overflow-y-scroll">
      <Transition
        show={Boolean(providerUsers?.length)}
        {...defaultTransitionProps}
      >
        {providerUsers?.map((providerUser, index) => (
          <ProviderUserRow
            key={`provider-user-row-${index}`}
            providerUser={providerUser}
            handleRemoveProviderUserFromPatientFromPatient={
              handleRemoveProviderUserFromPatientFromPatient
            }
          />
        ))}
      </Transition>
      <Transition
        show={!Boolean(providerUsers?.length)}
        {...defaultTransitionProps}
      >
        <div
          className={classNames(
            'flex w-full flex-row',
            providerUsers?.length === undefined
              ? 'justify-center pt-4'
              : 'justify-start',
          )}
        >
          {providerUsers?.length === undefined ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <div className="justify-start text-caption text-neutral-125">
              None assigned
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};
export default AssignedProviderUsersList;
