import classNames from 'classnames';

import Pill from './Pill';

const PILL_CLASS_NAME = 'ml-[1px] first:ml-0';

interface PasswordStrengthIndicatorProps {
  /** Integer from 0-4 */
  strengthScore: number;
}

export default function PasswordStrengthIndicator({
  strengthScore,
}: PasswordStrengthIndicatorProps) {
  return (
    <div className="flex flex-row justify-end" aria-hidden>
      <Pill
        className={classNames(
          PILL_CLASS_NAME,
          strengthScore >= 0 ? 'bg-red-100' : 'bg-neutral-100',
        )}
      />
      <Pill
        className={classNames(
          PILL_CLASS_NAME,
          strengthScore >= 2 ? 'bg-orange-100' : 'bg-neutral-100',
        )}
      />
      <Pill
        className={classNames(
          PILL_CLASS_NAME,
          strengthScore >= 3 ? 'bg-green-50' : 'bg-neutral-100',
        )}
      />
      <Pill
        className={classNames(
          PILL_CLASS_NAME,
          strengthScore === 4 ? 'bg-green-100' : 'bg-neutral-100',
        )}
      />
    </div>
  );
}
