import { FC, useEffect, useState } from 'react';

import ModalDialog from '../../../components/ModalDialog';
import InputGroup from '../../../components/InputGroup';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import { useUpdateProviderUserMutation } from '../../../../generated/graphql';
import {
  MAX_NAME_LENGTH,
  fullNameRegisterOptions,
} from '../../../lib/register-options';

interface RenameProviderUserModalFormData {
  providerUserName: string;
}

const RenameProviderUserModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  providerUserName?: string;
  onComplete?: (newName: string) => Promise<void>;
}> = ({ isModalOpen, setClosed, providerUserName, onComplete }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset,
    resetField,
    setFocus,
  } = useForm<RenameProviderUserModalFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      providerUserName: providerUserName ?? '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [updateProviderUser] = useUpdateProviderUserMutation();

  const onSubmit = async (formData: RenameProviderUserModalFormData) => {
    try {
      setIsLoading(true);
      const { data } = await updateProviderUser({
        variables: {
          input: {
            name: formData.providerUserName,
          },
        },
      });
      if (data) {
        const newProviderUserName = data.updateProviderUser.name;
        resetField('providerUserName', { defaultValue: newProviderUserName }); // Necessary to reset default form value
        await onComplete?.(newProviderUserName);
        closeAndResetModal();
      }
    } catch (error) {
      console.error('Error updating name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onErrors: SubmitErrorHandler<RenameProviderUserModalFormData> = (
    errors,
  ) => {
    console.log('errors:', errors);
  };

  useEffect(() => {
    if (providerUserName) {
      setValue('providerUserName', providerUserName);
    }
  }, [providerUserName, setValue]);

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      reset();
    }, MODAL_TRANSITION_DURATION);
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setFocus('providerUserName');
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isModalOpen, setFocus]);

  return (
    <ModalDialog
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="xs"
      title="Change your name"
      primaryActionTitle={!isLoading ? 'Save' : 'Saving'}
      primaryActionOnClick={handleSubmit(onSubmit, onErrors)}
      primaryActionDisabled={isLoading}
      primaryActionOnEnter={true}
    >
      <div className="flex h-16 flex-col">
        <InputGroup
          inputSize="small"
          labelHidden
          containerClassName="w-full"
          {...register('providerUserName', fullNameRegisterOptions)}
          errorMessage={formErrors.providerUserName?.message}
          maxLength={MAX_NAME_LENGTH}
        />
      </div>
    </ModalDialog>
  );
};

export default RenameProviderUserModal;
