import { FC } from 'react';

import { ScheduleAssessmentDataQuery } from '../../../generated/graphql';

import Slideover from '../../components/Slideover';
import SlideoverSection from '../../components/SlideoverSection';
import InterpretationSection from '../../components/Assessments/InterpretationSection';
import QuestionsSection from '../../components/Assessments/QuestionsSection';
import { AssessmentQuestionItemType } from '../../lib/assessments';

const PreviewAssessmentSlideover: FC<{
  isOpen: boolean;
  onClose: () => void;
  assessment: ScheduleAssessmentDataQuery['assessment'];
}> = ({ isOpen, onClose, assessment }) => {
  const responseOptions =
    assessment?.questions[0]?.__typename ===
      AssessmentQuestionItemType.MultipleChoiceScoredAssessmentQuestion &&
    assessment?.questions[0]?.answerOptions.sort(
      (answerOptionA, answerOptionB) => {
        if (answerOptionA.score == null || answerOptionB.score == null) {
          return 0;
        }
        return answerOptionA.score - answerOptionB.score;
      },
    );

  return (
    <Slideover
      isOpen={isOpen}
      title="Preview assessment"
      subtitle={assessment.shortName}
      onClose={onClose}
    >
      {assessment.instructions && (
        <SlideoverSection
          title="Instructions"
          textContent={assessment.instructions}
        />
      )}
      <InterpretationSection assessment={assessment} />
      {responseOptions && (
        <SlideoverSection title="Response options">
          <div className="font-sans text-caption text-neutral-125">
            {responseOptions.map((responseOption, index) => {
              if (
                responseOption?.__typename ===
                AssessmentQuestionItemType.MultipleChoiceScoredAssessmentQuestionAnswerOption
              ) {
                return (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-start"
                  >
                    <span className="w-2">
                      {Boolean(responseOption?.score) && '+'}
                    </span>
                    <span className="mr-2 w-4 text-center">
                      {responseOption.score != null && responseOption.score}
                    </span>
                    <span>{responseOption.answerOption}</span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </SlideoverSection>
      )}
      <QuestionsSection
        questionFrame={assessment.questionFrame}
        questions={assessment.questions}
      />
    </Slideover>
  );
};

export default PreviewAssessmentSlideover;
