import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { capitalizeSentence } from '../lib/copy';
import ErrorMessage from './ErrorMessage';
import { SvgIconComponent } from '../types/svgs';
import InputLabel from './InputLabel';

type TextAreaGroupProps = {
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  inputSize?: 'medium' | 'small' | 'extra-small';
  labelHidden?: boolean;
  backgroundHidden?: boolean;
  errorMessage?: string | null;
  errorMessageClassName?: string;
  errorLocation?: 'bottom' | 'right' | 'none';
  isSearch?: boolean;
  IconRight?: SvgIconComponent | null;
  iconRightClassName?: string;
  IconLeft?: SvgIconComponent | null;
  iconLeftClassName?: string;
  readOnly?: boolean;
  iconRightOnClick?: () => void;
  iconLeftOnClick?: () => void;
  characterCounter?: boolean;
  currentLengthValue?: number;
  maxLengthValue?: number;
  noFocus?: boolean;
} & React.ComponentProps<'textarea'>;

const TextAreaGroup = forwardRef<HTMLTextAreaElement, TextAreaGroupProps>(
  (
    {
      label,
      required = false,
      disabled = false,
      placeholder = '',
      className,
      containerClassName,
      labelHidden,
      backgroundHidden = false,
      inputSize = 'medium',
      errorMessage,
      errorMessageClassName,
      errorLocation = 'bottom',
      IconLeft = null,
      iconLeftClassName,
      iconLeftOnClick,
      IconRight = null,
      iconRightClassName,
      iconRightOnClick,
      readOnly,
      characterCounter = false,
      currentLengthValue,
      maxLengthValue,
      noFocus = false,
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const InputBottomRightElement: React.FC = () => {
      if (characterCounter && focused) {
        return (
          <div className="text-small-caption text-neutral-125">
            {`${currentLengthValue}${
              maxLengthValue ? `/${maxLengthValue}` : ''
            }`}
          </div>
        );
      }

      return null;
    };

    const inputId = label.toLowerCase();

    const { onBlur, onFocus, ...remainder } = rest;

    return (
      <div className={classNames('group flex flex-col', containerClassName)}>
        <InputLabel
          label={label}
          isHidden={labelHidden}
          className={
            Boolean(errorMessage) ? 'group-focus-within:text-red-125' : ''
          }
          htmlFor={inputId}
          useNaturalLettering
        />

        {/* Input */}
        <div className="relative">
          <textarea
            ref={ref}
            id={inputId}
            disabled={disabled}
            className={classNames(
              className,
              !className?.includes('rounded') && 'rounded', // solves passing a rounded function in since it defaults to rounded
              'block w-full resize-none border-2 border-transparent text-body transition-colors',
              'text-green-150 placeholder:text-neutral-125/75 read-only:text-neutral-125/75',
              'focus:outline-none focus:ring-0',
              'disabled:cursor-not-allowed disabled:bg-neutral-25 disabled:text-neutral-25 disabled:placeholder:text-neutral-100',
              backgroundHidden ? 'bg-transparent' : 'bg-neutral-50',
              inputSize === 'medium'
                ? 'py-4 px-4'
                : inputSize === 'small'
                ? 'py-3 px-4'
                : 'py-2 px-3',
              errorMessage
                ? 'selection:bg-red-50/50'
                : 'selection:bg-green-50/50',
              !noFocus && errorMessage
                ? 'focus:border-red-50'
                : 'focus:border-green-50',
            )}
            placeholder={placeholder ?? capitalizeSentence(label)}
            readOnly={readOnly}
            {...remainder}
            onFocus={(e) => {
              onFocus && onFocus(e);
              setFocused(true);
            }}
            onBlur={(e) => {
              onBlur && onBlur(e);
              setFocused(false);
            }}
          />

          <div className="absolute inset-y-0 right-2 bottom-1 flex items-end">
            <InputBottomRightElement />
          </div>
        </div>

        {/* Error message */}
        {errorLocation === 'bottom' && (
          <ErrorMessage className={classNames('mt-2', errorMessageClassName)}>
            {errorMessage}
          </ErrorMessage>
        )}
      </div>
    );
  },
);

export default TextAreaGroup;
