import { FC } from 'react';
import classNames from 'classnames';
import Spinner from '../svgs/Spinner';

const Loading: FC<{
  size?: 'large' | 'small';
  isCentered?: boolean;
}> = ({ size = 'large', isCentered = true }) => {
  return (
    <div
      className={classNames(
        'flex h-screen flex-col items-center',
        isCentered && 'justify-center',
        !isCentered && 'justify-start',
      )}
    >
      <Spinner
        className={classNames(
          size === 'large' && 'h-8 w-8',
          size === 'small' && 'h-6 w-6',
        )}
      />
    </div>
  );
};

export default Loading;
