import { FC } from 'react';
import Linkify from 'react-linkify';

const Linkifier: FC<{ text: string }> = ({ text }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}
          onClick={(e) => e.stopPropagation()} // Stop event propagation if this link is clicked within another button/modal
        >
          {decoratedText}
        </a>
      )}
    >
      {text}
    </Linkify>
  );
};

export default Linkifier;
