import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import {
  useProgramTemplateQuery,
  Feature,
  ProgramTemplateTaskGroup,
  useMoveProgramTemplateTaskInGroupMutation,
  Task,
} from '../../../../generated/graphql';
import { CustomGraphQLError } from '../../../../lib/errors';

import { useFeatureFlags } from '../../../../contexts/FeatureFlagContext';

import { defaultEnterTransitionProps } from '../../../lib/animation';
import { moveItemInList } from '../../../lib/drag-and-drop';

import { MoveTaskAndSaveCallback } from '../../../types/tasks';

import Alert from '../../../components/Alert';
import EmptyPrompt from '../../../components/EmptyPrompt';
import ToastAlert from '../../../components/ToastAlert';
import Spinner from '../../../svgs/Spinner';
import ProgramTasksTable from './ProgramTasksTable';

const ProgramContentTab = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isCreateEditingProgramTaskEnabled = isFeatureEnabled(
    Feature.ArticleCreationEditing,
  );

  const navigate = useNavigate();
  const params = useParams();
  const programTemplateId = params.programTemplateId || '';

  // Override default fetch policies so we can use Apollo's state management features via the cache.
  const {
    data: programData,
    loading: isLoadingProgram,
    error: programError,
    refetch: refetchProgramTemplate,
  } = useProgramTemplateQuery({
    variables: {
      programTemplateId,
    },
    fetchPolicy: 'cache-first',
  });

  const [
    moveProgramTemplateTaskMutation,
    { loading: isMoveProgramTemplateTaskLoading },
  ] = useMoveProgramTemplateTaskInGroupMutation({
    fetchPolicy: 'network-only',
  });

  const taskGroups = programData?.programTemplate.taskGroups || [];

  const moveTaskAndSave: MoveTaskAndSaveCallback = async (
    fromTaskIndex,
    // When moving a task to a new group, toTaskIndex is within the context of the new group.
    toTaskIndex,
    fromGroupId,
    toGroupId?,
  ) => {
    /* First, modify a copy of the data to reflect the change. */
    const fromGroupIndex = taskGroups.findIndex(
      (group) => group.id === fromGroupId,
    );
    const toGroupIndex = toGroupId
      ? taskGroups.findIndex((group) => group.id === toGroupId)
      : -1;
    const isMovingToNewGroup = toGroupIndex !== -1;

    if (fromGroupIndex === -1) {
      return;
    }

    // Deep copy, so we can directly modify state.
    const taskGroupsCopy: ProgramTemplateTaskGroup[] = JSON.parse(
      JSON.stringify(taskGroups),
    );

    const task = taskGroupsCopy[fromGroupIndex].tasks?.[fromTaskIndex];
    if (!task) {
      return;
    }

    const taskGroupToBeUpdated = isMovingToNewGroup
      ? taskGroupsCopy[toGroupIndex]
      : taskGroupsCopy[fromGroupIndex];

    moveItemInList<Task>(
      fromTaskIndex,
      toTaskIndex,
      taskGroupsCopy[fromGroupIndex].tasks ?? [],
      isMovingToNewGroup ? taskGroupsCopy[toGroupIndex].tasks ?? [] : undefined,
    );

    // Update the tasks' weights according to their order.
    taskGroupToBeUpdated.tasks = taskGroupToBeUpdated.tasks?.map(
      (taskElement, index) => ({
        ...taskElement,
        weightInGroup: index,
      }),
    );

    const taskIdsByWeight =
      taskGroupToBeUpdated.tasks?.map((taskByWeight) => taskByWeight.id!) ?? [];

    const updatedTaskGroups = [taskGroupsCopy[fromGroupIndex]];
    if (isMovingToNewGroup) {
      updatedTaskGroups.push(taskGroupsCopy[toGroupIndex]);
    }

    /* Then, save those changes. */
    await saveTaskMove(task.id!, taskIdsByWeight, updatedTaskGroups, toGroupId);
  };

  const saveTaskMove = async (
    taskId: string,
    taskIdsByWeight: string[],
    updatedTaskGroups: ProgramTemplateTaskGroup[],
    newGroupId?: string,
  ) => {
    try {
      await moveProgramTemplateTaskMutation({
        variables: {
          input: {
            taskId,
            programTemplateId,
            taskIdsByWeight,
            newTaskGroupId: newGroupId,
          },
        },
        optimisticResponse: {
          moveProgramTemplateTaskInGroup: updatedTaskGroups,
        },
      });
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message="Failed to sync article position"
        >
          <span className="mt-2">{(err as CustomGraphQLError).message}</span>
        </ToastAlert>
      ));
    }
  };

  const addArticle = () => {
    navigate(`/programs/${programTemplateId}/task/new`);
  };

  const hasTasks = Boolean(
    taskGroups.some(
      (taskGroup) => taskGroup.tasks && taskGroup.tasks.length > 0,
    ),
  );

  return (
    <>
      <Transition
        show={Boolean(programData && !programError)}
        {...defaultEnterTransitionProps}
      >
        {hasTasks ? (
          <ProgramTasksTable
            programTemplateTaskGroups={taskGroups}
            programTemplateId={programTemplateId}
            isRefetchingProgramTemplate={isLoadingProgram}
            isSavingTaskMove={isMoveProgramTemplateTaskLoading}
            refetchProgramTemplate={refetchProgramTemplate}
            moveTaskAndSave={moveTaskAndSave}
          />
        ) : (
          <div className="mt-14">
            <EmptyPrompt
              title="This program is empty"
              {...(isCreateEditingProgramTaskEnabled
                ? {
                    body: 'Add an article to set this program in motion.',
                    buttonTitle: 'Add an article',
                    buttonOnClick: addArticle,
                  }
                : { noButton: true })}
            />
          </div>
        )}
      </Transition>

      {isLoadingProgram && <Spinner className="mx-auto mt-8" />}

      {programError && <Alert message={programError?.message} />}
    </>
  );
};

export default ProgramContentTab;
