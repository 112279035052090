import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';

import { useAuth } from './contexts/AuthContext';
import { usePatientAuth } from './contexts/PatientAuthContext';

import AppRoutes, { HomecomingVersion } from './AppRoutes';
import GtmHelpers from './lib/gtm';
import Auth0Helpers from './lib/auth0';
import Config from './lib/config';
import { Environment } from './v2/types/config';

GtmHelpers.initGtm();
Auth0Helpers.initAuth0();

const App: React.FC = () => {
  const { tokenPayload, auth0AccessToken, authedProviderUser, isAuthedV1 } =
    useAuth();
  const { authedPatient } = usePatientAuth();

  // Switching this flag to instead check more specifically for V1 auth, so that
  // only in the case of a clear flag set for V1 will we consider the user V1,
  const isV1 = Boolean(auth0AccessToken && isAuthedV1);

  const {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
    update: updateIntercom,
  } = useIntercom();

  useEffect(() => {
    if (Config.REACT_APP_ENVIRONMENT === Environment.Development) {
      return;
    }

    if (
      (tokenPayload && !tokenPayload.isV2) ||
      Boolean(authedPatient?.programInstanceId)
    ) {
      // Authed in V1 or Patient authed in Client Portal
      shutdownIntercom();
    } else {
      // Unauthed or provider authed in V2
      bootIntercom();
      if (tokenPayload?.isV2 && authedProviderUser) {
        // Authed in V2 and not in development environment
        updateIntercom({
          userId: authedProviderUser.id,
          email: authedProviderUser.email,
          name: authedProviderUser.name,
        });
      }
    }
  }, [tokenPayload, authedProviderUser, authedPatient]);

  return (
    <>
      <Toaster position="bottom-center" />
      <AppRoutes
        homecomingVersion={isV1 ? HomecomingVersion.V1 : HomecomingVersion.V2}
      />
    </>
  );
};

export default App;
