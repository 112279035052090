import { FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import hcLogo from '../../../../assets/images/logo/logo-green-xl.png';
import { useRequestPatientLoginTokenMutation } from '../../../../generated/graphql';
import Button from '../../../components/Button';
import InputGroup from '../../../components/InputGroup';
import { HOMEPAGE_URL } from '../../../lib/constants';
import { EMAIL_REGEX } from '../../../lib/regex';
import WordmarkLogo from '../../../svgs/WordmarkLogo';

interface EmailFormData {
  email: string;
}

const DEFAULT_TESTIMONIAL_QUOTE =
  'I spent so much time trying to cobble together a solution to keep track of all my client communication, plus take care of all the admin tasks like scheduling and billing. It was exhausting and took me out of the focused mindset I need to be an effective practitioner. Now, I can manage my whole business in one place, and it’s much easier to keep things on track.';
const DEFAULT_TESTIMONIAL_NAME = 'Jules Xenakis';
const DEFAULT_TESTIMONIAL_TITLE = 'Coach, Being True to You';

const PatientLogin: FC = () => {
  const [emailSentTo, setEmailSentTo] = useState<string | null>(null);

  const {
    register: registerEmailForm,
    handleSubmit: handleEmailFormSubmit,
    formState: { errors: emailFormErrors, isSubmitting: emailFormIsSubmitting },
    clearErrors,
    reset,
  } = useForm<EmailFormData>({
    mode: 'onSubmit',
  });

  const [requestPatientLoginToken] = useRequestPatientLoginTokenMutation();

  const onSubmit = async (formData: EmailFormData) => {
    await requestPatientLoginToken({
      variables: {
        input: {
          email: formData.email,
        },
      },
    });

    setEmailSentTo(formData.email);

    clearErrors();
    reset();
  };

  return (
    <div className="flex flex-col justify-start lg:min-h-screen lg:flex-row">
      <div className="flex h-[32px] flex-1 flex-wrap items-center justify-center gap-x-5 pt-screen-5 lg:absolute lg:top-7 lg:left-10 lg:p-0">
        <div>
          <a href={HOMEPAGE_URL} rel="noreferrer" target="_blank">
            <WordmarkLogo className="hidden h-[32px] lg:flex" />
            <img
              src={hcLogo}
              className="flex h-[32px] w-[32px] lg:hidden"
              alt="logo"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center pb-screen-5 pt-screen-5">
        <div className="mb-3 mt-3 max-w-[488px] px-4 text-center font-serif text-subtitle-small font-light text-neutral-150 md:mb-2 md:text-subtitle">
          Client Portal
        </div>
        <div className="mb-8 max-w-[488px] px-4 text-center text-caption">
          Log in to access all of the messages, notes, actions, and resources
          you’re working on with your practitioner.
        </div>

        <div className="w-3/4 max-w-[488px]">
          <form className="w-full">
            <>
              <div className="w-full text-left">
                <InputGroup
                  placeholder="Email address"
                  type="email"
                  autoComplete="username"
                  inputSize="small"
                  useNaturalLettering={true}
                  containerClassName="mb-2"
                  required
                  errorMessage={emailFormErrors.email?.message}
                  {...registerEmailForm('email', {
                    required: 'Email is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Please enter a valid email',
                    },
                  })}
                />
              </div>
              <Button
                title="Continue"
                className="mx-auto my-6 w-full md:mt-8"
                type="submit"
                disabled={emailFormIsSubmitting}
                onClick={handleEmailFormSubmit(onSubmit)}
              />
              {emailSentTo && (
                <div className="rounded-lg bg-green-25 p-4 text-center text-caption">
                  We've sent an email to {emailSentTo} containing a link to log
                  in to the Client Portal.
                </div>
              )}
            </>
          </form>
        </div>
      </div>
      <div className="w-full lg:w-[560px]">
        <div className="flex h-full w-full shrink flex-col items-center justify-center bg-cover p-8 lg:m-0 lg:bg-[url('assets/images/signup/side-bg.png')]">
          <div className="flex h-auto w-[348px] flex-col items-start justify-between rounded-md bg-white p-6 shadow-100">
            <div className="mb-4 text-body text-green-150">
              “{DEFAULT_TESTIMONIAL_QUOTE}”
            </div>
            <div className="flex flex-row items-center justify-start">
              <div className="mr-3">
                <div className="h-[50px] w-[50px] shrink bg-[url('assets/images/signup/julie-avatar.png')] bg-cover" />
              </div>
              <div className="flex flex-col items-start justify-center">
                <div className="text-caption font-bold text-green-150">
                  {DEFAULT_TESTIMONIAL_NAME}
                </div>
                <div className="text-caption text-green-150">
                  {DEFAULT_TESTIMONIAL_TITLE}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientLogin;
