import { FC } from 'react';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

type ConfirmDeleteTeamMemberInviteModalProps = {
  isOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  isDeletingInvite: boolean;
};

const ConfirmDeleteTeamMemberInviteModal: FC<
  ConfirmDeleteTeamMemberInviteModalProps
> = ({ isOpen, setClosed, onConfirm, isDeletingInvite }) => {
  return (
    <Modal isOpen={isOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to delete this team member invite?
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title="Delete invite"
            theme="primary"
            onClick={onConfirm}
            disabled={isDeletingInvite}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteTeamMemberInviteModal;
