import { useEffect, useState } from 'react';

const SMALL_SCREEN_SIZE = 640;

function detectMobileSize() {
  const screenWidth = window.screen.width;
  return screenWidth < SMALL_SCREEN_SIZE;
}

export default function useIsMobileDevice() {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(
    detectMobileSize(),
  );

  function handleResize() {
    setIsMobileDevice(detectMobileSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileDevice;
}
