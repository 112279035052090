import { FC } from 'react';
import { CheckIn, TaskType, FeedType } from '../../../../generated/graphql';

import CheckInItem from '../../../components/CheckInItem';

const FeedItem: FC<{
  feedType: FeedType | null;
  feedItemType: TaskType;
  content?: string;
  timestamp?: string;
  checkInItem?: CheckIn | undefined;
  hasBadge?: boolean;
}> = ({
  feedType,
  feedItemType,
  content,
  timestamp,
  checkInItem,
  hasBadge = false,
}) => {
  let title = '';

  if (feedType === FeedType.Activities) {
    if (feedItemType === TaskType.Educational) {
      title = 'Read';
    } else if (feedItemType === TaskType.Journal) {
      title = 'Journaled';
    } else {
      title = 'Completed';
    }
  }

  return (
    <div className="w-full border-b border-neutral-50">
      <div className="flex h-[84px] flex-row items-start justify-start py-4 px-8">
        {feedType === FeedType.Activities && (
          <div className="flex w-full flex-col items-start justify-start">
            <span className="mb-1 text-small-label font-bold text-blue-100">
              {title}
            </span>
            <span className="mb-1.5 w-full truncate text-caption text-green-150">
              {content}
            </span>
            <span className="text-small-label text-green-150">{timestamp}</span>
          </div>
        )}
        {feedType === FeedType.CheckIns && (
          <CheckInItem checkInItem={checkInItem} hasMoodLabel />
        )}
      </div>
    </div>
  );
};

export default FeedItem;
