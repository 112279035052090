import { FC } from 'react';

import Button from '../../components/Button';
import { ChevronRightIcon, PencilIcon } from '@heroicons/react/outline';
import Spinner from '../../svgs/Spinner';
import IconButton from '../../components/IconButton';

export type SettingsRow = {
  label: string;
  data?: string;
  link?: string;
  innerComponent?: React.ReactNode;
  onClick?: () => void;
  onClickEdit?: () => void;
  loading?: boolean;
};

const SettingsItem: FC<{
  settingsRow: SettingsRow;
}> = ({ settingsRow }) => {
  return (
    <div className="flex h-full w-full flex-row items-start justify-center border-b border-neutral-50 py-4">
      <div className="ml-5 flex h-8 w-5/12 items-center text-caption text-neutral-125">
        {settingsRow.label}
      </div>
      <div className="flex w-7/12 flex-col">
        <div className="flex h-8 w-full flex-row items-center justify-between text-caption text-green-150">
          {Boolean(settingsRow?.link || settingsRow?.onClick) ? (
            <Button
              title={settingsRow?.data}
              className="justify-between font-bold text-green-150"
              theme="secondary"
              size="small"
              IconComponent={settingsRow.loading ? Spinner : ChevronRightIcon}
              iconClassName="text-green-100"
              noBackground
              noOutline
              onClick={() => {
                if (settingsRow?.onClick) settingsRow?.onClick();
                if (settingsRow?.link) window.location.href = settingsRow?.link;
              }}
            />
          ) : (
            <div className="flex flex-row items-center justify-start font-bold text-green-150">
              {settingsRow?.data}
            </div>
          )}
          {settingsRow?.onClickEdit && (
            <IconButton
              IconComponent={PencilIcon}
              className="h-8 w-8 justify-center !rounded-full bg-white"
              iconClassName="h-5 w-5"
              aria-label={`Edit ${settingsRow.label}`}
              onClick={settingsRow.onClickEdit}
            />
          )}
        </div>
        {settingsRow?.innerComponent}
      </div>
    </div>
  );
};

export default SettingsItem;
