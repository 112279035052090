import classNames from 'classnames';
import { SvgIconComponent } from '../types/svgs';

const UpdateButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  IconComponent?: SvgIconComponent;
  theme: 'green' | 'orange' | 'blue' | 'red';
  text: string;
  className?: string;
}> = ({ onClick, IconComponent, theme, text, className }) => {
  const icon = IconComponent && (
    <IconComponent
      className={classNames(
        'mr-1 h-3.5 w-3.5 flex-shrink-0',
        theme === 'orange' && 'text-orange-150',
        theme === 'green' && 'text-green-150',
        theme === 'blue' && 'text-blue-150',
        theme === 'red' && 'text-neutral-150',
      )}
    />
  );

  return (
    <button
      className={classNames(
        'mr-2 flex items-center rounded-full py-1.5 px-2.5 pr-3 text-center text-small-caption',
        theme === 'green' && 'bg-green-25 text-green-150 hover:bg-green-50/40',
        theme === 'orange' && 'bg-orange-25 text-orange-150 hover:bg-orange-50',
        theme === 'blue' && 'bg-blue-25/60 text-blue-150 hover:bg-blue-25',
        theme === 'red' && 'bg-red-25 text-neutral-150 hover:bg-red-50/60',
        className,
      )}
      onClick={onClick}
    >
      {icon}
      <div className="line-clamp-2">{text}</div>
    </button>
  );
};

export default UpdateButton;
