import { FC } from 'react';
import classNames from 'classnames';

import Button from './Button';

import { ColumnHeaderType, ColumnOrder } from '../types/tables';
import { sortOptions } from '../pages/PatientManagement/helpers';
import ChevronRight from '../svgs/ChevronRight';

const TableHeader: FC<{
  column: ColumnHeaderType;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  handleHeaderClick?: (column: ColumnHeaderType) => void;
  className?: string;
  previousColumnClicked?: ColumnHeaderType | null | undefined;
  columnOrder?: ColumnOrder;
}> = ({
  column,
  isFirstColumn = false,
  className = '',
  columnOrder,
  previousColumnClicked,
  handleHeaderClick,
}) => {
  const isSortable = column?.isSortable || false;

  let iconProps = {};
  if (isSortable) {
    iconProps = {
      IconComponent: ChevronRight,
      iconClassName: classNames(
        'h-[16px] w-[16px] rotate-90',
        column === previousColumnClicked &&
          columnOrder?.isAscending &&
          'hover:text-green-100 rotate-90',
        column === previousColumnClicked &&
          columnOrder?.isDescending &&
          '-rotate-90 hover:text-green-100',
        columnOrder === sortOptions.newestCreated &&
          'fill-neutral-100 invisible',
        column !== previousColumnClicked && 'invisible',
      ),
    };
  }

  return (
    <th
      scope="col"
      className={classNames(
        'font-semibold text-green-150',
        isFirstColumn && 'pl-0',
        className,
      )}
    >
      {isSortable ? (
        <Button
          onClick={() => handleHeaderClick && handleHeaderClick(column)}
          title={column.headerName}
          {...iconProps}
          className="text-left text-green-150 hover:text-green-100"
          theme="secondary"
          size="extra-small"
          noBackground
          noOutline
        />
      ) : (
        <div className="text-left text-small-caption font-bold">
          {column.headerName}
        </div>
      )}
    </th>
  );
};

export default TableHeader;
