import { FC, useState } from 'react';
import classNames from 'classnames';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import { useForgotPasswordMutation } from '../../../generated/graphql';

import ArrowRightLong from '../../svgs/ArrowRightLong';

import InputGroup from '../InputGroup';
import Button from '../Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../Modal';

import { emailRegisterOptions } from '../../lib/register-options';
interface ForgotPasswordForm {
  email: string;
}

const ForgotPasswordModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
}> = ({ isModalOpen, setClosed }) => {
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    reset: resetForm,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    mode: 'onSubmit',
  });

  const [forgotPasswordMutation] = useForgotPasswordMutation();

  const onSubmit: SubmitHandler<ForgotPasswordForm> = async (data) => {
    try {
      setLoading(true);

      const response = await forgotPasswordMutation({
        variables: {
          email: data.email,
        },
      });

      const forgotPasswordSuccess = response?.data?.forgotPassword as boolean;

      if (forgotPasswordSuccess) {
        setResetEmailSent(true);
      }

      setLoading(false);
    } catch (err) {
      setError('email', {
        type: 'Invalid email',
        message: 'Email not found',
      });
      setLoading(false);
    }
  };

  const handleErrors: SubmitErrorHandler<ForgotPasswordForm> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetForgotPasswordModal = () => {
    setClosed();
    setTimeout(() => {
      setResetEmailSent(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      name="ForgotPassword"
      isOpen={isModalOpen}
      setClosed={closeAndResetForgotPasswordModal}
      fetching={loading}
      width="small"
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-start px-2 pb-4 md:px-8',
        )}
      >
        <div className="flex w-full flex-col items-center justify-start md:w-[450px]">
          <div className="mb-2 text-center font-serif text-subtitle text-green-150">
            {!resetEmailSent
              ? 'Reset your password'
              : 'Reset password email sent'}
          </div>
          <div className="text-center font-sans text-extra-small text-green-125">
            {!resetEmailSent
              ? "Enter your email address, and we'll send a link to reset your password."
              : 'Check your email for a reset password link'}
          </div>
        </div>
        <form className="flex w-full flex-col items-center justify-center md:w-[500px]">
          <InputGroup
            label="Email"
            type="email"
            errorLocation="right"
            errorMessage={errors.email?.message}
            readOnly={resetEmailSent}
            containerClassName="mt-6 mb-12 w-full"
            required
            {...register('email', emailRegisterOptions)}
          />
          {resetEmailSent && (
            <div className="mb-4 text-center font-sans text-small text-green-125">
              Didn't receive an email?
            </div>
          )}
          <Button
            type="submit"
            title={
              !resetEmailSent
                ? 'Send password reset email'
                : 'Send password reset email again'
            }
            className="mb-10"
            IconComponent={ArrowRightLong}
            onClick={handleSubmit(onSubmit, handleErrors)}
          />
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
