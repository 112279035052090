import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import {
  ProgramTemplateTaskGroup,
  Task,
  useCreateTaskGroupMutation,
  useDeleteTaskGroupMutation,
  useRemoveTaskFromProgramTemplateMutation,
} from '../../../../../generated/graphql';
import { CustomGraphQLError } from '../../../../../lib/errors';

import { MoveTaskAndSaveCallback } from '../../../../types/tasks';
import { pluralize } from '../../../../lib/copy';

import TableHeader from '../../../../components/TableHeader';
import ToastAlert from '../../../../components/ToastAlert';
import DashedBoxButton from '../../../../components/DashedBoxButton';
import { MODAL_TRANSITION_DURATION } from '../../../../components/Modal';
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal';
import Plus from '../../../../svgs/Plus';

import TaskGroupTableRows from './TaskGroupTableRows';
import { columnHeaders, getGroupTitleInputId } from './helpers';

type ProgramTasksTableProps = {
  programTemplateTaskGroups: ProgramTemplateTaskGroup[] | undefined | null;
  programTemplateId: string;
  isRefetchingProgramTemplate: boolean;
  isSavingTaskMove: boolean;
  refetchProgramTemplate: () => Promise<unknown>;
  moveTaskAndSave: MoveTaskAndSaveCallback;
};

const ProgramTasksTable: FC<ProgramTasksTableProps> = ({
  programTemplateTaskGroups,
  programTemplateId,
  isRefetchingProgramTemplate,
  isSavingTaskMove,
  refetchProgramTemplate,
  moveTaskAndSave,
}) => {
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [taskGroupToBeDeleted, setTaskGroupToBeDeleted] =
    useState<ProgramTemplateTaskGroup | null>(null);
  const [isDeleteTaskGroupLoading, setIsDeleteTaskGroupLoading] =
    useState(false);
  const [isRemoveTaskModalOpen, setIsRemoveTaskModalOpen] = useState(false);
  const [taskToBeRemoved, setTaskToBeRemoved] = useState<Task | null>(null);
  const [isRemoveTaskLoading, setIsRemoveTaskLoading] = useState(false);

  const [createTaskGroup, { loading: isCreateTaskGroupLoading }] =
    useCreateTaskGroupMutation();
  const [deleteTaskGroupMutation] = useDeleteTaskGroupMutation();
  const [removeTaskFromProgramTemplateMutation] =
    useRemoveTaskFromProgramTemplateMutation();

  const focusAndSelectGroupTitleInput = (taskGroupId: string | undefined) => {
    if (!taskGroupId) {
      return;
    }

    const groupTitleInput = document.getElementById(
      getGroupTitleInputId(taskGroupId),
    ) as HTMLInputElement;
    groupTitleInput?.focus();
    groupTitleInput?.select();
  };

  const addTaskGroup = async () => {
    try {
      const newTaskGroupResponse = await createTaskGroup({
        variables: {
          input: {
            programTemplateId,
            title: 'New group',
          },
        },
      });
      const newTaskGroupId = newTaskGroupResponse.data?.createTaskGroup.id;

      await refetchProgramTemplate();
      // Give the new group's title input time to render before focusing it.
      setTimeout(() => focusAndSelectGroupTitleInput(newTaskGroupId), 0);
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={(err as CustomGraphQLError).message}
        />
      ));
    }
  };

  const openDeleteGroupModal = (taskGroup: ProgramTemplateTaskGroup) => {
    setIsDeleteGroupModalOpen(true);
    setTaskGroupToBeDeleted(taskGroup);
  };

  const deleteTaskGroup = async (taskGroupId: string) => {
    setIsDeleteTaskGroupLoading(true);

    try {
      await deleteTaskGroupMutation({
        variables: { taskGroupId },
      });
      await refetchProgramTemplate();

      toast.custom(({ visible }) => (
        <ToastAlert isVisible={visible} message="Successfully deleted group!" />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={(err as CustomGraphQLError).message}
        />
      ));
    } finally {
      setIsDeleteTaskGroupLoading(false);
    }
  };

  const openRemoveTaskModal = (task: Task) => {
    setIsRemoveTaskModalOpen(true);
    setTaskToBeRemoved(task);
  };

  const removeTaskFromProgramTemplate = async (taskId: string) => {
    setIsRemoveTaskLoading(true);

    try {
      await removeTaskFromProgramTemplateMutation({
        variables: { taskId, programTemplateId },
      });
      await refetchProgramTemplate();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Successfully deleted article!"
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={(err as CustomGraphQLError).message}
        />
      ));
    } finally {
      setIsRemoveTaskLoading(false);
    }
  };

  const hasTaskGroups =
    programTemplateTaskGroups && programTemplateTaskGroups.length > 0;

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden">
              <table className="min-w-full table-fixed">
                <thead>
                  <tr>
                    {columnHeaders.map((column, i) => {
                      const isFirstColumn = i === 0;

                      return (
                        <TableHeader
                          key={`tableHeader_${column.field}`}
                          column={column}
                          className={isFirstColumn ? '!pl-3' : ''}
                        />
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {hasTaskGroups &&
                    programTemplateTaskGroups.map((taskGroup, index) => (
                      <TaskGroupTableRows
                        key={taskGroup.id}
                        groupIndex={index}
                        taskGroup={taskGroup}
                        programTemplateId={programTemplateId}
                        isDeleteTaskGroupLoading={isDeleteTaskGroupLoading}
                        isSavingTaskMove={isSavingTaskMove}
                        openDeleteGroupModal={openDeleteGroupModal}
                        deleteTaskGroup={deleteTaskGroup}
                        openRemoveTaskModal={openRemoveTaskModal}
                        moveTaskAndSave={moveTaskAndSave}
                      />
                    ))}

                  <tr>
                    <td colSpan={columnHeaders.length} className="p-0">
                      <DashedBoxButton
                        title="Add a group"
                        IconComponent={Plus}
                        onClick={addTaskGroup}
                        disabled={
                          isCreateTaskGroupLoading ||
                          isRefetchingProgramTemplate
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete task group modal */}
      <ConfirmDeleteModal
        title="Are you sure you want to delete this group?"
        actionButtonTitle="Delete group"
        isOpen={isDeleteGroupModalOpen}
        fetching={isDeleteTaskGroupLoading}
        setClosed={() => {
          setIsDeleteGroupModalOpen(false);
          setTimeout(() => {
            setTaskGroupToBeDeleted(null);
          }, MODAL_TRANSITION_DURATION);
        }}
        performDelete={() => deleteTaskGroup(taskGroupToBeDeleted?.id ?? '')}
      >
        This cannot be undone.{' '}
        {pluralize(
          taskGroupToBeDeleted?.tasks?.length ?? 0,
          'article',
          'articles',
        )}{' '}
        within this group will also be permanently deleted.
      </ConfirmDeleteModal>

      {/* Delete article modal */}
      <ConfirmDeleteModal
        title="Are you sure you want to delete this article?"
        actionButtonTitle="Delete article"
        isOpen={isRemoveTaskModalOpen}
        fetching={isRemoveTaskLoading}
        setClosed={() => {
          setIsRemoveTaskModalOpen(false);
          setTimeout(() => {
            setTaskToBeRemoved(null);
          }, MODAL_TRANSITION_DURATION);
        }}
        performDelete={() =>
          removeTaskFromProgramTemplate(taskToBeRemoved?.id ?? '')
        }
      />
    </>
  );
};

export default ProgramTasksTable;
