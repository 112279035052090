import { ArchiveIcon, PaperAirplaneIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import Button from '../../../components/Button';
import PanelContainer from '../../../components/Containers/PanelContainer';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagContext';
import {
  Feature,
  PatientQuery,
  ProgramOnboardingStatus,
} from '../../../../generated/graphql';
import { getTimeStamp } from '../../../lib/date';
import ProfileInfoSection, { PatientDataList } from './ProfileInfoSection';

const ProfilePanel: FC<{
  patient: PatientQuery['patient'] | null;
  setIsFirstAppointmentDateModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsDeactivatePatientModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsAssignAssessmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAssessmentsEnabled: boolean;
  isActiveProfile: boolean;
}> = ({
  patient,
  setIsFirstAppointmentDateModalOpen,
  setIsDeactivatePatientModalOpen,
  setIsAssignAssessmentModalOpen,
  isAssessmentsEnabled,
  isActiveProfile,
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentAssignmentEnabled = isFeatureEnabled(
    Feature.AssessmentAssignment,
  );

  const profileList: PatientDataList = [
    {
      label: 'Email',
      content: patient?.email ?? '-',
    },
  ];

  const programList: PatientDataList = [
    {
      label: 'Name',
      content: patient?.programTemplate?.name ?? '-',
      link: `/programs/${patient?.programTemplate?.id}`,
    },
    {
      label: 'Invited',
      content: patient?.onboardingLastProgramInviteSentAt
        ? getTimeStamp(
            patient?.onboardingLastProgramInviteSentAt,
            patient?.onboardingStatus ===
              ProgramOnboardingStatus.ProgramInviteSent,
          )
        : '-',
    },
    {
      label: 'Activated',
      content: patient?.startTime
        ? getTimeStamp(patient?.startTime, false)
        : '-',
    },
    {
      label: 'Ends',
      content: patient?.endTime ? getTimeStamp(patient?.endTime, false) : '-',
    },
  ];

  const sessionsList: PatientDataList = [
    {
      label: 'First session date',
      content: Boolean(patient?.firstAppointmentAt)
        ? getTimeStamp(patient?.firstAppointmentAt, false)
        : 'Add date',
      isButtonItem: true,
      isFirstSessionDate: true,
      isFirstSessionDateEditable: Boolean(patient?.firstAppointmentAt),
    },
  ];

  return (
    <PanelContainer>
      <div className="mb-6 flex w-full flex-row items-start justify-start pr-6">
        <div className="flex w-full flex-col items-start justify-start border-b border-neutral-50 pb-3">
          <div className="font-serif text-subtitle text-green-150">
            {`${patient?.firstName} ${patient?.lastName}`}
          </div>
        </div>
      </div>
      <ProfileInfoSection name="About" patientData={profileList} />
      <ProfileInfoSection name="Program" patientData={programList} />
      {isAssessmentsEnabled && (
        <ProfileInfoSection
          name="Sessions"
          patientData={sessionsList}
          setIsFirstAppointmentDateModalOpen={
            setIsFirstAppointmentDateModalOpen
          }
        />
      )}
      {isActiveProfile && isAssessmentAssignmentEnabled && (
        <Button
          title="Send assessment"
          noBackground
          noOutline
          size="small"
          theme="secondary"
          className="mb-3 text-caption font-bold"
          IconComponent={PaperAirplaneIcon}
          iconClassName="text-green-100 rotate-90"
          onClick={() => setIsAssignAssessmentModalOpen(true)}
        />
      )}
      <Button
        title="Archive"
        noBackground
        noOutline
        size="small"
        theme="secondary"
        className="text-caption font-bold"
        IconComponent={ArchiveIcon}
        iconClassName="text-green-100"
        onClick={() => setIsDeactivatePatientModalOpen(true)}
      />
    </PanelContainer>
  );
};

export default ProfilePanel;
