import { useEffect } from 'react';

export default function useOnKeyPress(
  key: string,
  callback: (event: KeyboardEvent) => void,
  ref?: React.RefObject<HTMLElement>,
  isActive = false,
) {
  useEffect(() => {
    const handleKeyPress: EventListener = (evt: Event): void => {
      const event = evt as KeyboardEvent;
      if (event.key === key) {
        callback(event);
      }
    };

    // Optionally scope the event listener to a specific element
    const element: HTMLElement | Document = ref?.current || document;

    if (isActive) {
      element.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      element.removeEventListener('keydown', handleKeyPress);
    };
  }, [key, callback, isActive]);
}
