import { FC } from 'react';

import {
  ProviderBillingInfo,
  SubscriptionTier,
} from '../../../generated/graphql';

import Button from '../Button';
import Arrow from '../../svgs/ArrowRightLong';
import { useNavigate } from 'react-router-dom';

const BillingSubscribed: FC<{
  billingInfo: ProviderBillingInfo;
  subscriptionTier?: SubscriptionTier | null;
  clientSeats?: number | null;
  usedClientSeats?: number | null;
  linkToCustomerPortal: () => void;
}> = ({
  billingInfo,
  subscriptionTier,
  clientSeats,
  usedClientSeats,
  linkToCustomerPortal,
}) => {
  const isMonthly = Boolean(subscriptionTier);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-start">
      <div className="mb-8 text-center font-serif text-subtitle-small">
        You're currently subscribed to Homecoming
      </div>

      <div className="mb-8 rounded-xl bg-green-25 px-6 py-6 text-caption text-green-150">
        {billingInfo ? (
          <>
            {isMonthly && (
              <>
                <div className="mb-4">
                  You're on Homecoming's{' '}
                  <span className="font-bold">{subscriptionTier}</span> plan
                </div>
              </>
            )}
            Your next invoice will be billed on
            <div className="mt-1 mb-2 font-sans text-big-label text-green-100">
              {new Date(billingInfo.nextBillingDate).toLocaleDateString(
                'en-US',
                {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                },
              )}
            </div>
            {!isMonthly && (
              <>
                <div>
                  You're currently on track to have{' '}
                  <b>{billingInfo.forecastedActivePatientCount}</b> active
                  clients, and to be billed
                </div>
                <div className="my-2 font-sans text-big-label text-green-100">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(billingInfo.forecastedCost)}
                </div>
              </>
            )}
            {isMonthly ? (
              <div className="mt-4 font-sans text-caption">
                You're currently using <b>{usedClientSeats}</b> active client
                seats out of <b>{clientSeats}</b> total available
              </div>
            ) : (
              <div className="mt-4 font-sans text-caption">
                Note: The final amount is subject to change if more clients are
                added before then.
              </div>
            )}
          </>
        ) : (
          <>
            Failed to retrieve billing info. Please contact us at
            support@homecoming.health for details.
          </>
        )}
      </div>
      <div className="flex w-full flex-row items-center justify-center">
        <Button
          title="Your active clients"
          IconComponent={Arrow}
          iconPosition="left"
          iconClassName="rotate-180"
          className="mr-4"
          theme="secondary"
          onClick={() => {
            navigate('/clients/active');
          }}
        />
        <Button
          title="View past invoices"
          IconComponent={Arrow}
          theme="primary"
          onClick={() => {
            linkToCustomerPortal();
          }}
        />
      </div>
    </div>
  );
};

export default BillingSubscribed;
