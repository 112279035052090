import { SvgIconComponent } from '../types/svgs';

const Help: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.27193 1.58041L8 1.40418L7.72807 1.58041L1.72833 5.46876L1.50421 5.61401L1.50031 5.88105L2.00026 5.88835C1.50031 5.88105 1.50031 5.88119 1.50031 5.88136L1.5003 5.8818L1.50029 5.88307L1.50024 5.88716L1.50012 5.90145L1.50001 5.9537C1.50009 5.99874 1.5006 6.06384 1.50227 6.14658C1.50561 6.31197 1.51361 6.54822 1.5322 6.8358C1.5693 7.40974 1.64895 8.19419 1.82006 9.03084C1.99065 9.8649 2.25566 10.7686 2.67165 11.5719C3.08715 12.3741 3.66976 13.1073 4.4901 13.5497C6.83996 14.8168 9.16004 14.8168 11.5099 13.5497C12.3302 13.1073 12.9129 12.3741 13.3283 11.5719C13.7443 10.7686 14.0094 9.8649 14.1799 9.03084C14.3511 8.19419 14.4307 7.40974 14.4678 6.8358C14.4864 6.54822 14.4944 6.31197 14.4977 6.14657C14.4994 6.06384 14.4999 5.99874 14.5 5.9537L14.4999 5.90145L14.4998 5.88716L14.4997 5.88307L14.4997 5.8818L14.4997 5.88136C14.4997 5.88119 14.4997 5.88105 13.9997 5.88835L14.4997 5.88105L14.4958 5.61401L14.2717 5.46876L8.27193 1.58041Z"
        stroke="#8ADD8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5H7V7L5 7V9H7V11H9V9H11V7L9 7V5Z"
        fill="#00B20A"
      />
    </svg>
  );
};

export default Help;
