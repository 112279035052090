import { FC, Fragment, useState } from 'react';

import { Menu, Portal } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { DownloadIcon, MailIcon, XIcon } from '@heroicons/react/outline';
import IconButton from '../../../components/IconButton';
import MenuButton from '../../../components/MenuButton';
import { ProgramInvoiceStatus } from '../../../../generated/graphql';
import { usePopper } from 'react-popper';

type InvoiceMenuProps = {
  onClickSendReminder: React.MouseEventHandler<HTMLButtonElement>;
  onClickDownloadInvoice: React.MouseEventHandler<HTMLButtonElement>;
  onClickVoidInvoice: React.MouseEventHandler<HTMLButtonElement>;
  invoiceStatus: ProgramInvoiceStatus;
};

const InvoiceMenu: FC<InvoiceMenuProps> = ({
  onClickSendReminder,
  onClickDownloadInvoice,
  onClickVoidInvoice,
  invoiceStatus,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const canRemindOrVoid = [
    ProgramInvoiceStatus.PastDue,
    ProgramInvoiceStatus.Pending,
  ].includes(invoiceStatus);

  return (
    <div className="z-10 flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <IconButton
            aria-label="Invoice actions"
            IconComponent={DotsHorizontalIcon}
            iconClassName="h-4 w-4"
            className="hover:bg-neutral-75"
            onClick={(e) => e.stopPropagation()}
            ref={setReferenceElement}
          />
        </Menu.Button>

        <Portal>
          <Menu.Items
            className="absolute right-0 z-20 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {canRemindOrVoid && (
              <MenuButton
                titleText="Send reminder"
                iconClassName="text-neutral-125"
                className="rounded-t-md text-neutral-125"
                IconComponent={MailIcon}
                onClick={(e) => {
                  onClickSendReminder?.(e);
                  e.stopPropagation();
                }}
              />
            )}
            <MenuButton
              titleText="Download invoice"
              iconClassName="text-neutral-125"
              className="rounded-t-md text-neutral-125"
              IconComponent={DownloadIcon}
              onClick={(e) => {
                onClickDownloadInvoice?.(e);
                e.stopPropagation();
              }}
            />
            {canRemindOrVoid && (
              <MenuButton
                titleText="Void invoice"
                iconClassName="text-red-100"
                className="rounded-b-md text-red-100"
                activeText="text-red-100"
                IconComponent={XIcon}
                onClick={(e) => {
                  onClickVoidInvoice?.(e);
                  e.stopPropagation();
                }}
              />
            )}
          </Menu.Items>
        </Portal>
      </Menu>
    </div>
  );
};

export default InvoiceMenu;
