import { FC, useEffect, useState } from 'react';

import ModalDialog from '../../../components/ModalDialog';
import InputGroup from '../../../components/InputGroup';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import { useUpdateProviderMutation } from '../../../../generated/graphql';
import {
  MAX_NAME_LENGTH,
  providerNameRegisterOptions,
} from '../../../lib/register-options';

interface RenameProviderModalFormData {
  providerName: string;
}

const RenameProviderModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  providerName?: string;
  onComplete?: (newName: string) => Promise<void>;
}> = ({ isModalOpen, setClosed, providerName, onComplete }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset,
    resetField,
    setFocus,
  } = useForm<RenameProviderModalFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      providerName,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [updateProvider] = useUpdateProviderMutation();

  const onSubmit = async (formData: RenameProviderModalFormData) => {
    try {
      setIsLoading(true);
      const { data } = await updateProvider({
        variables: {
          input: {
            name: formData.providerName,
          },
        },
      });
      if (data) {
        const newProviderName = data.updateProvider.name;
        resetField('providerName', { defaultValue: newProviderName }); // Necessary to reset default form value
        await onComplete?.(newProviderName);
        closeAndResetModal();
      }
    } catch (error) {
      console.error('Error updating provider:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onErrors: SubmitErrorHandler<RenameProviderModalFormData> = (
    errors,
  ) => {
    console.log('errors:', errors);
  };

  useEffect(() => {
    if (providerName) {
      setValue('providerName', providerName);
    }
  }, [providerName, setValue]);

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      reset();
    }, MODAL_TRANSITION_DURATION);
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setFocus('providerName');
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isModalOpen, setFocus]);

  return (
    <ModalDialog
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="xs"
      title="Rename organization"
      primaryActionTitle={!isLoading ? 'Save' : 'Saving'}
      primaryActionOnClick={handleSubmit(onSubmit, onErrors)}
      primaryActionDisabled={isLoading}
      primaryActionOnEnter={true}
    >
      <div className="flex h-16 flex-col">
        <InputGroup
          inputSize="small"
          labelHidden
          containerClassName="w-full"
          {...register('providerName', providerNameRegisterOptions)}
          errorMessage={formErrors.providerName?.message}
          maxLength={MAX_NAME_LENGTH}
        />
      </div>
    </ModalDialog>
  );
};

export default RenameProviderModal;
