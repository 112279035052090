import { FC } from 'react';
import toast from 'react-hot-toast';
import { useStripeCheckoutPortalLazyQuery } from '../../../generated/graphql';

import upgradeModalImage from '../../../assets/images/upgrade-modal.png';

import Button from '../Button';
import Modal from '../Modal';
import ToastAlert from '../ToastAlert';
import CloseX from '../../svgs/CloseX';
import {
  AnalyticsComponent,
  ProviderAnalyticsEvent,
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
} from '../../../lib/analytics';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { HomecomingError, matchHomecomingError } from '../../../lib/errors';
import { ApolloError } from '@apollo/client';
import { DEFAULT_FREE_CLIENT_COUNT } from '../../lib/constants';

type UpgradeModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  stripeCheckoutPortalWebRedirectPath?: string;
  originPage?: AnalyticsPage | string;
  isBlocking?: boolean;
  handleSelectActiveClients?: () => void;
};

const UpgradeModal: FC<UpgradeModalProps> = ({
  isModalOpen,
  setClosed,
  stripeCheckoutPortalWebRedirectPath,
  originPage,
  isBlocking = false,
  handleSelectActiveClients,
}) => {
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();
  const hasBeenSubscribed = authedProviderUser?.hasBeenSubscribed ?? false;

  const isPartner = authedProviderUser?.signUpConfig?.slug != null;

  const [getStripeCheckoutPortal, { loading: stripeCheckoutPortalLoading }] =
    useStripeCheckoutPortalLazyQuery();

  const goToStripeCheckoutPortal = async () => {
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: buttonCta,
      originPage,
      originComponent: AnalyticsComponent.Modal,
    });

    try {
      const { data } = await getStripeCheckoutPortal({
        variables: {
          webRedirectPath: stripeCheckoutPortalWebRedirectPath,
        },
      });

      if (data?.stripeCheckoutPortal.url) {
        window.location.href = data.stripeCheckoutPortal.url;
      }
    } catch (err) {
      console.error('Error getting Stripe Checkout Portal:', err);

      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        // Shouldn't be able to get here, but just in case
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="You're already subscribed."
            level="error"
          />
        ));
        trackProviderEvent(ProviderAnalyticsEvent.ProviderCheckoutFailed, {
          reason: 'Already subscribed',
          reactivate: true,
        });
        navigate('/');
        return;
      }

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong. Please contact us at support@homecoming.health."
          level="error"
        />
      ));
      trackProviderEvent(ProviderAnalyticsEvent.ProviderCheckoutFailed, {
        reason: 'Server error',
        reactivate: true,
      });
    }
  };

  const goToSubscribePage = () => {
    setClosed();
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: buttonCta,
      originPage,
      originComponent: AnalyticsComponent.Modal,
    });
    navigate('/subscribe', { state: { stripeCheckoutPortalWebRedirectPath } });
  };

  const headerText = isBlocking
    ? 'Keep the momentum going with our paid plans'
    : hasBeenSubscribed
    ? 'Ready to reactivate?'
    : 'Run your practice without limits';
  const bodyText = isBlocking
    ? `To continue working with unlimited clients, upgrade to a paid plan. Otherwise, our free plan includes ${
        authedProviderUser?.provider.clientSeats ?? DEFAULT_FREE_CLIENT_COUNT
      } active clients; you can choose who stays active while the rest get archived.`
    : hasBeenSubscribed
    ? 'Reactivate your subscription to continue working with unlimited clients, collaborating with team members, and accessing all features designed to enhance your workflow.'
    : 'Upgrade now to work with unlimited clients, collaborate with team members, and access all features designed to enhance your workflow.';
  const buttonCta = hasBeenSubscribed
    ? 'Reactivate subscription'
    : 'Explore plans';
  const onButtonClick = isPartner
    ? goToStripeCheckoutPortal
    : goToSubscribePage;

  return (
    <Modal
      name="Upgrade"
      originPage={originPage}
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="medium"
      hasCloseRow={false}
      hasPadding={false}
      isBlocking={isBlocking}
      shiftDownForNav={isBlocking}
    >
      <div className="flex h-[517px] w-[794px] flex-row items-center">
        {!isBlocking && (
          <button className="absolute top-8 right-8" onClick={setClosed}>
            <CloseX className="h-2.5 w-2.5 text-green-150" />
          </button>
        )}
        <div className="flex h-full w-1/2 flex-col items-center justify-center bg-neutral-25 bg-[url('assets/images/reactivate-modal-bg.png')] bg-cover p-12 ">
          <img
            src={upgradeModalImage}
            height="286"
            width="318"
            alt="Upgrade your plan"
          />
        </div>
        <div className="flex h-full w-1/2 flex-col justify-center py-9 pl-9 pr-14">
          <div>
            <div className="mb-3 text-big-label font-medium text-neutral-150">
              {headerText}
            </div>
            <div className="mb-6 flex text-caption text-neutral-125">
              {bodyText}
            </div>
            <Button
              title={buttonCta}
              theme="primary"
              disabled={stripeCheckoutPortalLoading}
              onClick={onButtonClick}
            />
            {isBlocking && handleSelectActiveClients && (
              <Button
                title="Select active clients"
                theme="secondary"
                onClick={handleSelectActiveClients}
                className="mt-3"
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
