import classNames from 'classnames';
import useIsMobileDevice from '../hooks/useIsMobileDevice';
import MobileNotReady from '../svgs/MobileNotReady';
import useFixPage from '../hooks/useFixPage';

const IsMobileDeviceScreen: React.FunctionComponent = () => {
  const isMobileDevice = useIsMobileDevice();
  useFixPage(isMobileDevice);

  return (
    <div
      className={classNames(
        'absolute z-[1000] flex h-screen w-screen items-center justify-center bg-neutral-50 p-8',
        isMobileDevice ? 'md:hidden' : 'hidden',
      )}
    >
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex h-1/3 w-full items-center justify-center rounded-t-xl bg-gradient-to-br from-blue-50/70 to-green-50/70">
          <MobileNotReady />
        </div>
        <div className="flex h-2/3 w-full flex-col items-start justify-start rounded-b-xl bg-white p-8">
          <div className="mb-6 text-big-label font-bold text-neutral-150">
            Manage your practice from anywhere
          </div>
          <div className="text-body text-neutral-150">
            To access Homecoming on your phone, download the Homecoming
            Companion app and log in as a practitioner.
          </div>
          <div className="my-5 flex flex-row gap-x-3">
            <div className="flex-1">
              <a href="https://apps.apple.com/us/app/homecoming-companion/id1574364465?itsct=apps_box_badge&amp;itscg=30200">
                <img
                  className="h-[60px]"
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630540800"
                  alt="Download Homecoming the App Store"
                />
              </a>
            </div>
            <div className="flex-1">
              <a href="https://play.google.com/store/apps/details?id=com.axismundilabs.homecoming&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className="h-[60px]"
                  alt="Download Homecoming on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
          <div className="text-body text-neutral-150">
            <span className="font-bold">Note: </span>
            certain functionality is only available via a desktop web browser.
            Visit{' '}
            <a
              className="text-blue-125"
              href="https://homecoming.health"
              target="_blank"
              rel="noreferrer"
            >
              homecoming.health
            </a>{' '}
            to log in and pick up where you left off!
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsMobileDeviceScreen;
