type ProviderCollectionsColumnProps = {
  children?: React.ReactNode;
};

export default function ProviderCollectionsColumn({
  children,
}: ProviderCollectionsColumnProps) {
  return (
    <div className="flex h-full min-w-[215px] flex-col items-start justify-start border-r border-neutral-75">
      {children}
    </div>
  );
}
