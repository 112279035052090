import classNames from 'classnames';
import { forwardRef } from 'react';

import Button, { ButtonProps } from './Button';

type DashedBoxButtonProps = ButtonProps;

const DashedBoxButton = forwardRef<HTMLButtonElement, DashedBoxButtonProps>(
  ({ className, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        iconPosition="left"
        theme="secondary"
        noOutline
        noBackground
        className={classNames(
          'mt-12 w-full rounded-none border border-dashed border-neutral-100 p-12 transition-colors focus:border-green-50 focus:bg-green-25/50 focus:!ring-0',
          className,
        )}
        {...rest}
      />
    );
  },
);

export default DashedBoxButton;
