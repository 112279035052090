import { SvgIconComponent } from '../types/svgs';

const SignatureFlagRole: SvgIconComponent = () => {
  return (
    <svg width="19" height="40" viewBox="0 0 19 40" fill="none">
      <rect width="19" height="28" fill="#FFA41C" />
      <path d="M9.5 39L9.45698e-07 28L19 28L9.5 39Z" fill="#FFA41C" />
    </svg>
  );
};

export default SignatureFlagRole;
