import { forwardRef } from 'react';
import classNames from 'classnames';

import { SvgIconComponent } from '../types/svgs';

type IconButtonProps = {
  IconComponent: SvgIconComponent;
  ['aria-label']: string;
  iconClassName?: string;
} & React.ComponentProps<'button'>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ IconComponent, iconClassName, className, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          'group rounded p-1 text-green-150 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-50 disabled:text-neutral-100',
          disabled && 'cursor-not-allowed hover:bg-transparent',
          className,
        )}
        disabled={disabled}
        title={rest['aria-label']}
        {...rest}
      >
        <IconComponent className={classNames(iconClassName)} />
      </button>
    );
  },
);

export default IconButton;
