import React, { useMemo } from 'react';

import { Feature, useEnabledFeaturesQuery } from '../generated/graphql';

interface FeatureFlagContextType {
  enabledFeatures: string[] | undefined;
  isFeatureEnabled: (feature: Feature) => boolean;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextType>(null!);

export const FeatureFlagProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: enabledFeaturesQueryData } = useEnabledFeaturesQuery();
  const enabledFeatures = enabledFeaturesQueryData?.enabledFeatures;

  const featureSet = useMemo(() => new Set(enabledFeatures), [enabledFeatures]);

  const isFeatureEnabled = (feature: Feature) => {
    return featureSet.has(feature);
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        enabledFeatures,
        isFeatureEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  return React.useContext(FeatureFlagContext);
};
