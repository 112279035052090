import React, { FC } from 'react';

const FormBlock: FC<{
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ children, onClick }) => (
  <div
    onClick={onClick}
    className="mb-6 flex h-auto w-[720px] flex-col rounded-xl bg-white px-6 pt-4 pb-8 shadow-100"
  >
    {children}
  </div>
);

export default FormBlock;
