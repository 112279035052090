import { FC, useState } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

import { ProgramActivityDataFragment, UserType } from '../../generated/graphql';
import { programActivityTitle } from '../lib/programActivity';

import {
  getProgramActivityStatus,
  ProgramActivityStatus,
  ProgramActivityStatusOption,
} from './ProgramActivityStatus';
import Linkifier from './Linkifier';
import { ProgramActivityThumbnail } from './ProgramActivityThumbnail';
import { SparklesIcon } from '@heroicons/react/outline';

/*
    Component for an already-created activity/resource in the context of a program.
    Backed by the ProgramActivity back-end entity and ProgramActivityDataFragment GQL type.
    Differs from the existing ActivityPreviewCard in that it has been created and has
    a backing DB entity with state and is clickable to view the activity/resource details.
*/

const ProgramActivityCard: FC<{
  programActivity: ProgramActivityDataFragment;
  onClickProgramActivity: (
    programActivity: ProgramActivityDataFragment,
  ) => void;
  className?: string;
  userType?: UserType;
  patientMode?: boolean;
  isLastCard?: boolean;
}> = ({
  programActivity,
  onClickProgramActivity,
  className,
  userType = UserType.ProviderUser,
  patientMode = false,
  isLastCard = false,
}) => {
  const { providerNote } = programActivity;
  const title = programActivityTitle(programActivity);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const programActivityStatus = getProgramActivityStatus(
    programActivity,
    userType,
  );
  const isPendingSignature =
    programActivityStatus === ProgramActivityStatusOption.PendingSignature;

  const handleMouseEnter = () => patientMode && setIsHoverActive(true);
  const handleMouseLeave = () => patientMode && setIsHoverActive(false);
  const handleClick = () =>
    !isPendingSignature && onClickProgramActivity(programActivity);

  const signatureMessage =
    "This form still needs other signatures. When it's your turn to sign, you'll receive a notification.";

  return (
    <div
      className={classNames(
        'w-full cursor-pointer border-neutral-75 p-4 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
        patientMode
          ? !isLastCard && 'border-b'
          : 'group rounded-lg border bg-white hover:bg-neutral-25',
        isPendingSignature && 'cursor-default',
        className,
      )}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!patientMode && isPendingSignature && (
        <div className="mb-5 flex w-full flex-row items-start justify-start rounded-md bg-neutral-25 p-4 group-hover:bg-neutral-50">
          <SparklesIcon className="mr-2 h-5 w-5 text-neutral-125" />
          <div className="w-full text-caption text-neutral-125">
            {signatureMessage}
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex items-center',
          !patientMode && providerNote && 'border-b border-neutral-50 pb-4',
        )}
      >
        <ProgramActivityThumbnail
          programActivity={programActivity}
          className="mr-4 h-12 w-12"
        />
        {!patientMode ? (
          <div className="grow text-caption font-medium text-neutral-150 line-clamp-2">
            {title}
          </div>
        ) : (
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col items-start justify-center">
              <div className="grow text-caption font-medium text-neutral-150 line-clamp-2">
                {title}
              </div>
              <div className="whitespace-pre-wrap break-words text-caption text-neutral-125 line-clamp-1">
                {providerNote}
              </div>
            </div>
            {isPendingSignature ? (
              <div className="relative">
                <ExclamationCircleIcon className="h-5 w-5 text-orange-100" />
                {isHoverActive && (
                  <div className="absolute -left-48 flex w-56 flex-col items-center whitespace-normal rounded-md bg-white px-5 py-4 text-left shadow-lg md:-top-5 md:left-10">
                    <SparklesIcon className="mt-1 h-8 w-8 text-neutral-125" />
                    <span className="text-netural-125 mt-1.5 mb-1 text-caption">
                      {signatureMessage}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              isHoverActive && (
                <ChevronDownIcon className="h-5 w-5 -rotate-90 text-green-100" />
              )
            )}
          </div>
        )}
        {!patientMode && (
          <ProgramActivityStatus
            programActivity={programActivity}
            className="ml-4 flex-none"
            userType={userType}
          />
        )}
      </div>
      {!patientMode && providerNote && (
        <div className="mt-4 whitespace-pre-wrap break-words text-caption text-neutral-125">
          <Linkifier text={providerNote} />
        </div>
      )}
    </div>
  );
};

export default ProgramActivityCard;
