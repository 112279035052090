import { FC } from 'react';
import classNames from 'classnames';
import { RadioGroup } from '@headlessui/react';
import { Controller, FieldError, useForm, useWatch } from 'react-hook-form';

import {
  ProgramTemplatesQuery,
  useCreateProgramTemplateMutation,
  useProgramTemplatesQuery,
} from '../../../../generated/graphql';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import SelectMenu from '../../../components/SelectMenu';
import InputGroup from '../../../components/InputGroup';

import ArrowRightLong from '../../../svgs/ArrowRightLong';

const MAX_PROGRAM_LENGTH_MONTHS = 6;

enum ContentOrigin {
  Baseline = 'baseline',
  Clone = 'clone',
  None = 'none',
}

interface CreateProgramFormData {
  name: string;
  programLengthInMonths: string;
  contentOrigin: ContentOrigin;
  cloneProgramTemplate?: ProgramTemplatesQuery['programTemplates'][number];
  // Errors
  apiError?: string;
}

type FormErrors = {
  [Property in keyof CreateProgramFormData]?: FieldError;
};

const getErrorMsg = (formErrors: FormErrors): string | undefined => {
  if (formErrors.name?.type === 'required') {
    return 'Program name required';
  } else if (formErrors.programLengthInMonths?.type === 'required') {
    return 'Program duration required';
  } else if (formErrors.contentOrigin?.type === 'required') {
    return 'You need to choose the starter content for this program';
  } else if (formErrors.apiError) {
    return formErrors.apiError.message || 'Something went wrong';
  } else if (formErrors.cloneProgramTemplate?.type === 'required') {
    return 'Please select the program you want to duplicate';
  }
};

interface CreateProgramModalProps {
  isModalOpen: boolean;
  setClosed: () => void;
  onSuccess: () => void;
}

const ProgramLengthOption: FC<{ value: number }> = ({ value }) => {
  return (
    <span>
      {value} {value === 1 ? 'month' : 'months'}
    </span>
  );
};

const ProgramTemplateOption: FC<{
  value: ProgramTemplatesQuery['programTemplates'][number];
}> = ({ value }) => {
  return <span>{value.name}</span>;
};

const ContentOption: FC<{
  value: string;
  label: string;
  description: string;
}> = ({ value, label, description }) => {
  return (
    <RadioGroup.Option value={value}>
      {({ checked, active }) => (
        <div
          className={classNames(
            'relative mb-1 flex cursor-pointer items-start rounded-md p-3',
            {
              'bg-green-25': checked,
              'hover:bg-green-25': !checked,
            },
          )}
        >
          <div className="flex h-5 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="radio"
              checked={checked}
              readOnly
              className="border-neutral h-4 w-4 text-green-125 focus:ring-green-125"
            />
          </div>
          <div className="text-sm ml-5">
            <div className="cursor-pointer font-serif text-extra-small">
              {label}
            </div>
            <p
              id="comments-description"
              className="font-sans-serif text-small-caption"
            >
              {description}
            </p>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  );
};

const CreateProgramModal: FC<CreateProgramModalProps> = ({
  isModalOpen,
  setClosed,
  onSuccess,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setError,
    reset,
    control,
  } = useForm<CreateProgramFormData>({
    defaultValues: { contentOrigin: ContentOrigin.Baseline },
    mode: 'onChange',
  });

  const { data: programsData } = useProgramTemplatesQuery();
  const programTemplates = programsData?.programTemplates || [];

  const [createProgramTemplate] = useCreateProgramTemplateMutation();

  const onSubmit = async (submittedData: CreateProgramFormData) => {
    try {
      const {
        name,
        programLengthInMonths,
        contentOrigin,
        cloneProgramTemplate,
      } = submittedData;

      await createProgramTemplate({
        variables: {
          input: {
            name,
            programLengthInMonths: parseInt(programLengthInMonths),
            useBaselineContent: contentOrigin === ContentOrigin.Baseline,
            cloneProgramTemplateId:
              contentOrigin === ContentOrigin.Clone
                ? cloneProgramTemplate?.id
                : undefined,
          },
        },
      });

      setClosed();
      onSuccess();
      reset();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError('apiError', {
        message: err.toString(),
      });
    }
  };

  // This should be a list from [1..MAX_PROGRAM_LENGTH_MONTHS]
  const programLengthOptions = Array.from(
    { length: MAX_PROGRAM_LENGTH_MONTHS },
    (x, i) => i,
  ).map((i) => i + 1);

  const contentOrigin = useWatch({ control, name: 'contentOrigin' });
  const isCloneSelected = contentOrigin === ContentOrigin.Clone;

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="small"
      overflow="auto"
    >
      <div>
        <div className="mb-10 px-10">
          <div className="mb-3 w-full text-center font-serif text-subtitle text-green-150">
            Add a program
          </div>
          <div className="font-sans-serif w-full text-center text-extra-small text-green-125">
            Create a new program for your clients
          </div>
        </div>
        <form className="w-full px-9 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <InputGroup
              label="Name"
              containerClassName="w-full"
              inputSize="small"
              required
              {...register('name', { required: true })}
            />
          </div>
          <div>
            <Controller
              name="programLengthInMonths"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { onChange, value } = field;

                return (
                  <SelectMenu
                    fieldValue={value}
                    onChange={onChange}
                    fieldOptions={programLengthOptions}
                    SelectOptionComponent={ProgramLengthOption}
                    label="Program Length"
                    smallLabel
                    containerClassName="my-4"
                  />
                );
              }}
            />
          </div>
          <div>
            <label
              htmlFor="Content"
              className="text-small-action text-green-125"
            >
              <span className="font-bold">CONTENT</span>
            </label>
          </div>

          <Controller
            name="contentOrigin"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              const { onChange, value } = field;

              return (
                <RadioGroup value={value} onChange={onChange}>
                  <ContentOption
                    label="Ketamine Preparation"
                    description="Provided by Homecoming"
                    value={ContentOrigin.Baseline}
                  />
                  <ContentOption
                    label="Start from scratch"
                    description="Work with the Homecoming team to add custom materials"
                    value={ContentOrigin.None}
                  />
                  <ContentOption
                    label="Duplicate an existing program"
                    description="Make a copy of existing program that you can modify"
                    value={ContentOrigin.Clone}
                  />
                </RadioGroup>
              );
            }}
          />
          {isCloneSelected && (
            <div className="mt-2">
              <Controller
                name="cloneProgramTemplate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  const { onChange, value } = field;

                  return (
                    <SelectMenu
                      fieldValue={value}
                      onChange={onChange}
                      fieldOptions={programTemplates}
                      SelectOptionComponent={ProgramTemplateOption}
                      placeholder="Select a program"
                      label=""
                      containerClassName="ml-11"
                    />
                  );
                }}
              />
            </div>
          )}

          <div className="flex flex-col items-center justify-center">
            <div className="my-4 text-red-125">
              {
                // There's something about the CreateProgramFormData interface having a field
                // that's not a primitive (i.e. cloneProgramTemplate is of the type ProgramTemplate)
                // that causes the typechecker to barf on this line.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getErrorMsg(formErrors as any)
              }
            </div>
            <div className="">
              <Button
                title="Create new program"
                type="submit"
                IconComponent={ArrowRightLong}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateProgramModal;
