import { FC } from 'react';
import classNames from 'classnames';
import { getProviderActivityData } from '../../../../lib/providerActivity';
import { ActivityDataFragment } from '../../../../../generated/graphql';

const ActivitySummary: FC<{
  providerActivity: ActivityDataFragment;
  followUpMode?: boolean;
}> = ({ providerActivity, followUpMode = false }) => {
  const { activityTitle, activitySubtitle, activityImage, ActivityThumbnail } =
    getProviderActivityData(providerActivity);

  return (
    <div className="flex items-center">
      {Boolean(activityImage) ? (
        <img
          src={activityImage}
          className="mr-4 h-[36px] w-[36px] flex-none rounded-lg object-cover"
          alt="Activity thumbnail"
        />
      ) : (
        ActivityThumbnail && (
          <ActivityThumbnail className="mr-4 h-[36px] w-[36px] flex-none rounded-lg" />
        )
      )}
      <div className="flex-col text-green-150">
        <div
          className={classNames(
            'font-medium',
            !followUpMode ? 'line-clamp-2' : 'line-clamp-1',
          )}
        >
          {activityTitle}
        </div>
        {activitySubtitle && (
          <div className="text-small-caption text-neutral-125 line-clamp-2">
            {activitySubtitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitySummary;
