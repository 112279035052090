import { FC } from 'react';
import { XIcon } from '@heroicons/react/outline';

import Modal from '../Modal';
import Avatar from '../Avatar';
import Button from '../Button';
import IconButton from '../IconButton';

const FollowUpIntroModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  todayFormatted: string;
}> = ({ isModalOpen, setClosed, todayFormatted }) => {
  return (
    <Modal
      name="FollowUpIntro"
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="medium"
      hasCloseRow={false}
      hasPadding={false}
    >
      <div className="flex h-[62vh] flex-row items-center">
        <div className="flex h-full w-1/2 flex-col items-center justify-center bg-gradient-to-br from-blue-50/70 to-green-50/70 p-12">
          <div className="relative flex h-auto w-[280px] flex-col items-start justify-start rounded-lg bg-white px-8 py-10 text-neutral-150 shadow-100">
            <div className="absolute -top-5 left-8 rounded-full bg-white p-1">
              <Avatar size="medium" />
            </div>
            <span className="mb-0.5 text-caption font-medium text-green-100">
              Latest follow-up
            </span>
            <span className="mb-3 font-serif text-subtitle text-neutral-150">
              {todayFormatted}
            </span>
            <span className="mb-6 text-caption text-neutral-150">
              Hey Maria, I hope you’re doing well. I’m sharing the activities
              and resources that we discussed in our last session. If you can,
              journal about it. Wishing you a brave...
            </span>
            <div className="flex flex-row items-center justify-start text-small-caption font-bold">
              <span className="mr-1">1 activity</span>
              <div className="h-1 w-1 rounded-full bg-neutral-150"></div>
              <span className="ml-1">2 resources</span>
            </div>
          </div>
        </div>
        <div className="flex h-full w-1/2 flex-col justify-between">
          <div className="flex w-full flex-row items-center justify-end p-6">
            <IconButton
              aria-label="Delete option"
              IconComponent={XIcon}
              className="hover:bg-neutral-50"
              iconClassName="h-5 w-5"
              onClick={setClosed}
            />
          </div>
          <div className="flex w-full flex-col items-start justify-start p-10 pr-20">
            <span className="mb-2 text-caption font-medium text-neutral-110">
              Welcome to follow-ups
            </span>
            <span className="min-w-[200px] text-big-label font-medium text-green-150">
              Share your insights, resources, and activities for your clients
              between sessions
            </span>
          </div>
          <div className="flex w-full flex-row items-center justify-end border-t border-neutral-50 p-6">
            <Button title="Get started" theme="primary" onClick={setClosed} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FollowUpIntroModal;
