import React, { FC } from 'react';
import Modal from '../Modal';
import Avatar from '../Avatar';

interface StorefrontIntakeCompleteModalProps {
  isModalOpen: boolean;
  setClosed: () => void;
  avatarImageUrl?: string;
  avatarName?: string;
}

const StorefrontIntakeCompleteModal: FC<StorefrontIntakeCompleteModalProps> = ({
  isModalOpen,
  setClosed,
  avatarImageUrl,
  avatarName,
}) => {
  return (
    <Modal
      name="StorefrontIntakeComplete"
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="small"
    >
      <div className="flex flex-col items-center justify-center px-12 py-10">
        <Avatar
          size="xl"
          imageUrl={avatarImageUrl}
          name={avatarName}
          className="mb-6"
        />
        <div className="mb-3 w-2/3 text-center font-serif text-subtitle-small text-green-150">
          Thank you for reaching out!
        </div>
        <div className="text-center text-caption text-neutral-125">
          Your details are in good hands. We will carefully review your
          submission and will be in touch soon with your next steps.
        </div>
      </div>
    </Modal>
  );
};

export default StorefrontIntakeCompleteModal;
