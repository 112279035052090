import classNames from 'classnames';
import { FC, useEffect } from 'react';
import Modal from '../../../components/Modal';
import AppStoreLinks from '../../../components/AppStoreLinks';
import {
  AnalyticsComponent,
  AnalyticsPage,
  trackPatientComponentViewedEvent,
} from '../../../../lib/analytics';

const UnsupportedModal: FC<{ isOpen: boolean; setClosed: () => void }> = ({
  isOpen,
  setClosed,
}) => {
  useEffect(() => {
    if (isOpen) {
      trackPatientComponentViewedEvent(
        AnalyticsComponent.Modal,
        AnalyticsPage.ClientFollowUpView,
        {
          modalName: 'Unsupported Functionality Modal',
        },
      );
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setClosed={setClosed} width="small">
      <div
        className={classNames(
          'flex flex-col items-center justify-between px-10 pb-4',
        )}
      >
        <div className="flex flex-col items-center justify-start">
          <div className="mb-4 flex flex-col items-center justify-start text-center font-serif text-subtitle text-green-150">
            <span>Continue in the App</span>
          </div>
          <div className="text-center text-body">
            <div className="text-green-125">
              In order to complete this assessment, you'll need to download the
              Homecoming client companion app.
            </div>
          </div>
          <div className="mt-3">
            <AppStoreLinks />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UnsupportedModal;
