import { useState, useEffect } from 'react';

export default function usePaintScreen(duration = 500) {
  const [paintScreen, setPaintScreen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPaintScreen(true);
    }, duration);
  }, []);

  return paintScreen;
}
