import { FC, Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';

type ValidationWarningProps = {
  isOpen: boolean;
  warningText: string;
  fadeOut?: boolean;
  setShowValidationWarning: React.Dispatch<React.SetStateAction<boolean>>;
};

const ValidationWarning: FC<ValidationWarningProps> = ({
  isOpen,
  warningText,
  fadeOut = false,
  setShowValidationWarning,
}) => {
  useEffect(() => {
    if (isOpen && fadeOut)
      setTimeout(() => setShowValidationWarning(false), 4000);
  }, [isOpen, fadeOut, setShowValidationWarning]);

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transform ease-out duration-400 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transform ease-in duration-200 transition"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        onClick={() => setShowValidationWarning(false)}
        className="min-w-[210px] rounded-xl bg-white shadow-100"
      >
        <div className="flex h-full w-full flex-row items-center justify-between rounded-xl bg-orange-25/60 px-4 py-2 text-center">
          <span className="text-caption font-bold text-orange-150">
            {warningText}
          </span>
        </div>
      </div>
    </Transition>
  );
};

export default ValidationWarning;
