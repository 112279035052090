import { FC } from 'react';

import { ScheduleAssessmentDataQuery } from '../../../../generated/graphql';
import { AssessmentQuestionItemType } from '../../../lib/assessments';
import SlideoverSection from '../../SlideoverSection';

import { CompletedProgramAssessment } from '../helpers';
import QuestionItem from './QuestionItem';

const QuestionsSection: FC<{
  questions?: ScheduleAssessmentDataQuery['assessment']['questions'];
  completedAssessment?: CompletedProgramAssessment;
  questionFrame?: string | null;
  title?: string;
}> = ({
  questions,
  completedAssessment,
  questionFrame,
  title = 'Questions',
}) => {
  return (
    <SlideoverSection title={title}>
      {questionFrame && (
        <div className="mb-4 font-sans text-caption text-neutral-125">
          {questionFrame}
        </div>
      )}
      <div className="font-sans text-caption text-neutral-125">
        {questions?.map((question, index) => {
          if (
            question.__typename ===
            AssessmentQuestionItemType.MultipleChoiceScoredAssessmentQuestion
          ) {
            return (
              <QuestionItem
                key={index}
                questionIndex={index}
                question={question.question}
                subscale={question.subscale}
              />
            );
          }
          return null;
        })}
        {completedAssessment?.answers?.map((answer, index) => {
          if (
            answer.__typename ===
            AssessmentQuestionItemType.MultipleChoiceScoredAssessmentAnswer
          ) {
            return (
              <QuestionItem
                key={index}
                questionIndex={index}
                question={answer.question}
                answer={answer.answer}
                subscale={answer?.subscale}
              />
            );
          }
          return null;
        })}
      </div>
    </SlideoverSection>
  );
};

export default QuestionsSection;
