import { XYCoord } from 'react-dnd';
import { HoverPosition } from '../types/drag-and-drop';

/** Returns the relative position of the mouse within the hovered item. */
export function getHoverPosition(
  hoveredItemRect: DOMRect,
  mousePosition: XYCoord,
): HoverPosition {
  const mouseToRectTopDistance = mousePosition.y - hoveredItemRect.top;
  const hoveredItemRectHeight = hoveredItemRect.bottom - hoveredItemRect.top;

  if (mouseToRectTopDistance < hoveredItemRectHeight / 2) {
    return 'top';
  } else {
    return 'bottom';
  }
}

/** Returns the index that a dragged item should go to after a drop event. */
export function getDraggedItemToIndex(
  draggedItemIndex: number,
  droppedItemIndex: number,
  hoverPosition: HoverPosition,
  itemNotInList = false,
): number {
  if (itemNotInList) {
    return hoverPosition === 'top' ? droppedItemIndex : droppedItemIndex + 1;
  }

  if (draggedItemIndex < droppedItemIndex) {
    // Moving downwards.
    return hoverPosition === 'top' ? droppedItemIndex - 1 : droppedItemIndex;
  }

  if (draggedItemIndex > droppedItemIndex) {
    // Moving upwards.
    return hoverPosition === 'top' ? droppedItemIndex : droppedItemIndex + 1;
  }

  // Not moving.
  return draggedItemIndex;
}

export function moveItemInList<T>(
  fromIndex: number,
  toIndex: number,
  fromList: T[],
  toList?: T[],
) {
  const item = fromList[fromIndex];
  // Remove the item from the original list.
  fromList.splice(fromIndex, 1);

  // Re-insert item in the original list or a new list, at the correct index.
  (toList || fromList).splice(toIndex, 0, item);
}
