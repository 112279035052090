import classNames from 'classnames';
import { FC } from 'react';
import Linkifier from './Linkifier';

const SlideoverSection: FC<{
  children?: React.ReactNode;
  title?: string;
  textContent?: string;
  className?: string;
  titleClassName?: string;
}> = ({ children = <></>, title, textContent, className, titleClassName }) => {
  return (
    <div
      className={classNames(
        'mb-5 flex flex-col items-start justify-start rounded-xl bg-white px-6 py-4',
        className,
      )}
    >
      {title && (
        <div
          className={classNames(
            'mb-3 text-body font-bold text-green-150',
            titleClassName,
          )}
        >
          {title}
        </div>
      )}
      {textContent && (
        <span className="whitespace-pre-wrap break-words text-caption text-neutral-125">
          <Linkifier text={textContent} />
        </span>
      )}
      {children}
    </div>
  );
};

export default SlideoverSection;
