import { NAME_REGEX, EMAIL_REGEX } from './regex';

export const fullNameRegisterOptions = {
  required: { value: true, message: 'Name is required' },
  pattern: {
    value: NAME_REGEX,
    message: 'Invalid full name',
  },
};

export const emailRegisterOptions = {
  required: { value: true, message: 'Email is required' },
  pattern: {
    value: EMAIL_REGEX,
    message: 'Invalid email',
  },
};
