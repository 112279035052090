import { FC, useMemo } from 'react';
import classNames from 'classnames';

import {
  PatientsQuery,
  ProgramOnboardingStatus,
} from '../../../../../generated/graphql';

import Button from '../../../../components/Button';
import ProgramItem from '../../../../components/ProgramItem';
import { EmptyProgramItem } from '../../../../components/EmptyProgramItem';
import ProgramOnboardingStatusItem from '../../../../components/ProgramOnboardingStatusItem';

import ArrowRightLong from '../../../../svgs/ArrowRightLong';
import { getOnboardingStatusText } from '../helpers';

type OnboardingRowProps = {
  isChecked: boolean;
  patient: PatientsQuery['patients'][number];
  patientIndex: number;
  isPatientsSelected: boolean;
  isCreateEditingProgramTaskEnabled?: boolean;
  isPatientProfileEnabled?: boolean;
  isAssessmentsEnabled?: boolean;
  nextStepsButtonsDisabled: boolean;
  openAssignToProgramModal: () => void;
  openInviteToProgramModal: () => void;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientsQuery['patients'][number] | null>
  >;
  navigateToPatient?: (patient: PatientsQuery['patients'][number]) => void;
  handleCheckboxInteraction: (
    event: React.ChangeEvent<HTMLInputElement>,
    patient: PatientsQuery['patients'][number],
    patientIndex: number,
  ) => void;
};

const OnboardingRow: FC<OnboardingRowProps> = ({
  isChecked,
  patient,
  patientIndex,
  nextStepsButtonsDisabled,
  openAssignToProgramModal,
  openInviteToProgramModal,
  handleCheckboxInteraction,
  setSinglePatient,
}) => {
  const { timestamp } = useMemo(
    () => getOnboardingStatusText(patient),
    [patient],
  );

  return (
    <tr
      key={patient.programInstanceId}
      className={classNames(
        'group whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
      )}
    >
      <td className="relative px-6 py-0 sm:w-16 sm:px-8">
        {isChecked && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-white" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125 sm:left-6"
          value={patient.email}
          checked={isChecked}
          onChange={(event) =>
            handleCheckboxInteraction(event, patient, patientIndex)
          }
        />
      </td>
      <td className="w-1/4 py-4 px-0">
        <div className={'mb-1 font-serif text-extra-small'}>
          {patient?.firstName + ' ' + patient?.lastName}
        </div>
        <div className="text-small-caption">{patient?.email}</div>
      </td>
      <td className="w-1/4">
        {patient?.programTemplate ? (
          <ProgramItem
            programTemplateId={patient.programTemplate.id}
            programTemplateName={patient.programTemplate.name ?? undefined}
            programTemplateStatus={patient.programTemplate.status}
          />
        ) : (
          <EmptyProgramItem />
        )}
      </td>
      <td className="w-1/4">
        <ProgramOnboardingStatusItem
          onboardingStatus={patient.onboardingStatus}
        />
        {timestamp && (
          <div className="mt-2 text-small-caption">{timestamp}</div>
        )}
      </td>

      <td className="w-1/4">
        {(patient?.onboardingStatus === ProgramOnboardingStatus.NeedsProgram ||
          patient?.onboardingStatus ===
            ProgramOnboardingStatus.NeedsActiveProgram) && (
          <Button
            IconComponent={ArrowRightLong}
            title={`Assign to ${
              patient?.onboardingStatus ===
              ProgramOnboardingStatus.NeedsActiveProgram
                ? 'active '
                : ''
            }program`}
            theme="secondary"
            size="small"
            disabled={nextStepsButtonsDisabled}
            onClick={() => {
              setSinglePatient(patient);
              openAssignToProgramModal();
            }}
          />
        )}
        {patient?.onboardingStatus ===
          ProgramOnboardingStatus.NeedsProgramInvite && (
          <Button
            IconComponent={ArrowRightLong}
            title="Send invite email"
            theme="secondary"
            size="small"
            disabled={nextStepsButtonsDisabled}
            onClick={() => {
              setSinglePatient(patient);
              openInviteToProgramModal();
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default OnboardingRow;
