import { useEffect } from 'react';

// For use on pages including 1+ scrolls components to
// imitate a richer app window experience and prevent
// the whole page from scrolling

export default function useFixPage(isFixed = true) {
  useEffect(() => {
    if (isFixed) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.height = '';
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.height = '';
      document.body.style.overflow = '';
    };
  }, []);
}
