import React from 'react';
import classNames from 'classnames';
import {
  ProgramAssessmentSignatureUserDataFragment,
  SignatureUser,
} from '../../../generated/graphql';
import { getColorForSignatureUserType } from '../../lib/multiSignature';

interface SignatureDisplayProps {
  programAssessmentSignatureUser: ProgramAssessmentSignatureUserDataFragment;
  className?: string;
  disabled?: boolean;
}

const SignatureDisplay: React.FC<SignatureDisplayProps> = ({
  programAssessmentSignatureUser,
  className,
  disabled = false,
}) => {
  const signatureUserType =
    programAssessmentSignatureUser?.assessmentSignatureUser?.signatureUserType;

  const orderIndex =
    programAssessmentSignatureUser?.assessmentSignatureUser?.orderIndex;

  const name =
    signatureUserType === SignatureUser.PatientUser
      ? programAssessmentSignatureUser?.patientName
      : programAssessmentSignatureUser?.providerUser?.name;

  const { backgroundColor, disabledBackgroundColor } =
    getColorForSignatureUserType(signatureUserType);

  return (
    <div
      className={classNames(
        'mb-3 flex h-10 w-full flex-grow items-center text-body',
        !disabled ? 'text-neutral-125' : 'text-neutral-150/30',
        className,
      )}
    >
      <div
        className={classNames(
          'mr-4 flex h-full w-10 flex-shrink-0 items-center justify-center rounded-md',
          !disabled ? backgroundColor : disabledBackgroundColor,
        )}
      >
        {orderIndex + 1}
      </div>
      <div
        className={classNames(
          'min-h-10 flex h-full w-full items-center justify-start rounded-md p-4',
          !disabled ? backgroundColor : disabledBackgroundColor,
        )}
      >
        {name}
      </div>
    </div>
  );
};

export default SignatureDisplay;
