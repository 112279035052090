import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ScheduleTimingOption } from './helpers';

export const ScheduleTimingRadioOption: React.FC<{
  scheduleTimingOption: ScheduleTimingOption;
  content: ReactNode;
}> = ({ scheduleTimingOption, content }) => (
  <RadioGroup.Option value={scheduleTimingOption}>
    {({ checked, active }) => (
      <div
        className={classNames('relative flex cursor-pointer items-baseline')}
      >
        <input
          id="scheduleTiming"
          aria-describedby="comments-description"
          name="comments"
          type="radio"
          checked={checked}
          readOnly
          className="border-1 h-3 w-3 border-green-125 text-green-125 checked:before:bg-green-125 focus:ring-green-50"
        />
        {content}
      </div>
    )}
  </RadioGroup.Option>
);
