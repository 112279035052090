import {
  PatientDataFragment,
  PatientNameDataFragment,
} from '../../generated/graphql';

const PatientItem: React.FC<{
  value: PatientNameDataFragment;
}> = ({ value }) => (
  <span className="text-caption">{`${value.firstName} ${value.lastName}`}</span>
);

export default PatientItem;

export type PatientOrPatientNameData =
  | PatientDataFragment
  | PatientNameDataFragment;

export const sortPatientsByAlphabeticalAscending = (
  patients: PatientOrPatientNameData[] | undefined,
) => {
  if (patients)
    return patients.sort(
      (
        patientA: PatientOrPatientNameData,
        patientB: PatientOrPatientNameData,
      ) => {
        const patientAFullName =
          `${patientA.firstName} ${patientA.lastName}`.toLowerCase();
        const patientBFullName =
          `${patientB.firstName} ${patientB.lastName}`.toLowerCase();

        return patientAFullName < patientBFullName ? -1 : 1;
      },
    );
  return [];
};
