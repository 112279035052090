import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { isClientUrl } from '../v2/lib/url';

/** Container for unauthenticated routes such as login and signup,
 * in which the user will be redirected to home if they are authenticated.
 *
 * Public routes can be placed elsewhere.
 * */
export default function UnauthedContainer() {
  const { auth0AccessToken } = useAuth();

  // Even if the user has practitioner credentials, only redirect
  // them if it's not a client page
  if (auth0AccessToken && !isClientUrl()) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
