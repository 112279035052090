import classNames from 'classnames';
import { ASSESSMENTS_COLOR_MAP } from '../../../../lib/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomizedDot = (props: any) => {
  const {
    isSecondaryScore,
    isActiveDot,
    cx,
    cy,
    payload,
    setShownAssessmentQandAIndex,
  } = props;

  const diameter = 12;
  const radius = diameter / 2;
  const outerDiameter = diameter * 2;

  const fillColor =
    ASSESSMENTS_COLOR_MAP?.[
      payload.primaryColor
        ? !isSecondaryScore
          ? payload.primaryColor
          : payload.secondaryColor
        : 'neutral'
    ]?.defaultHex;

  if (isActiveDot)
    return (
      <svg
        x={cx - diameter}
        y={cy - diameter}
        width={outerDiameter}
        height={outerDiameter}
        className="cursor-pointer"
        viewBox={`0 0 ${outerDiameter} ${outerDiameter}`}
        onClick={() =>
          setShownAssessmentQandAIndex(payload.completedProgramAssessmentIndex)
        }
      >
        <circle
          opacity="0.2"
          cx={diameter}
          cy={diameter}
          r={diameter}
          fill={fillColor}
        />
        <circle
          opacity="0.5"
          cx={diameter}
          cy={diameter}
          r={radius * 1.5}
          fill={fillColor}
        />
        <circle cx={diameter} cy={diameter} r={radius} fill={fillColor} />
      </svg>
    );

  return (
    <svg
      x={cx - radius}
      y={cy - radius}
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <rect width={diameter} height={diameter} rx={radius} fill={fillColor} />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTooltip = (props: any) => {
  const { payload } = props;

  if (payload && payload?.length) {
    return (
      <div className="flex h-8 w-8 flex-col items-center justify-center rounded-lg bg-white shadow-100">
        <span className="text-small-caption text-green-125">
          {payload[0]?.payload?.primaryScore && payload[0].payload.primaryScore}
        </span>
        {payload[1]?.payload?.secondaryScore && (
          <span className="text-small-caption text-green-125">
            {payload[1].payload.secondaryScore &&
              payload[1].payload.secondaryScore}
          </span>
        )}
      </div>
    );
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomAxisTick = ({ x, y, payload, isXAxis }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={isXAxis ? 20 : 3}
        dx={isXAxis ? -10 : -20}
        className={classNames(
          'text-small-label text-neutral-125',
          isXAxis && 'font-bold',
        )}
      >
        {payload.value}
      </text>
    </g>
  );
};
