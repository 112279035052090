import { FC, Fragment, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  SparklesIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Combobox, Transition } from '@headlessui/react';
import _ from 'lodash';
import { ApolloQueryResult } from '@apollo/client';

import {
  Exact,
  PatientDataFragment,
  PatientDataPermission,
  PatientQuery,
  useAddPatientsToProviderUsersMutation,
  useProviderUserMembersV2Query,
  useRemovePatientsFromProviderUsersMutation,
} from '../../../../../generated/graphql';

import AssignedProviderUsersList from './AssignedProviderUsersList';
import { AssignProviderUsersFormData } from '../../../../types/teamMembers';
import ToastAlert from '../../../../components/ToastAlert';
import { sortProviderUserMembers } from '../../../../lib/teamMembers';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Avatar from '../../../../components/Avatar';

type AssignProviderUsersModalProps = {
  isOpen: boolean;
  setClosed: () => void;
  selectedPatient?: PatientDataFragment;
  refetchPatient: (
    variables?: Partial<
      Exact<{
        programId: string;
      }>
    >,
  ) => Promise<ApolloQueryResult<PatientQuery>>;
};

const AssignProviderUsersModal: FC<AssignProviderUsersModalProps> = ({
  isOpen,
  setClosed,
  selectedPatient,
  refetchPatient,
}) => {
  const assignProviderUsersForm = useForm<AssignProviderUsersFormData>({
    mode: 'onSubmit',
    defaultValues: {
      providerUsers: [],
    },
  });

  const {
    reset: resetForm,
    control,
    watch,
    setValue,
  } = assignProviderUsersForm;

  const watchProviderUsers = watch('providerUsers');

  const [providerUserQuery, setProviderUserQuery] = useState('');

  const [addPatientsToProviderUsers] = useAddPatientsToProviderUsersMutation();

  const [removePatientsFromProviderUsers] =
    useRemovePatientsFromProviderUsersMutation();

  const patientProviderUsers = selectedPatient?.assignedProviderUsers;

  const { data: teamMembersData } = useProviderUserMembersV2Query({
    variables: {
      activeProviderUsersOnly: true,
    },
    onError(error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong getting your team members. Please try refreshing."
          level="error"
        />
      ));
    },
  });

  const providerUsersList = sortProviderUserMembers(
    teamMembersData?.providerUserMembers?.filter(
      (providerUser) =>
        providerUser?.patientDataPermission !== PatientDataPermission.None,
    ),
  );

  const watchedProviderUserIds = watchProviderUsers
    ?.map((providerUser) => providerUser.id)
    ?.filter(Boolean);

  const patientProviderUsersIds =
    patientProviderUsers?.map((providerUser) => providerUser.id) || [];

  const filteredProviderUsers = providerUsersList
    ?.filter((providerUser) => {
      return (
        !watchedProviderUserIds.includes(providerUser.id) &&
        !patientProviderUsersIds.includes(providerUser.id)
      );
    })
    ?.filter((providerUser) => {
      return providerUser?.name
        ?.toLowerCase()
        .includes(providerUserQuery.toLowerCase());
    });

  const handleAssignProviderUsers = async () => {
    try {
      await addPatientsToProviderUsers({
        variables: {
          input: {
            programIds: [selectedPatient?.programInstanceId],
            providerUserIds: watchedProviderUserIds,
          },
        },
      });

      setValue('providerUsers', []);

      await refetchPatient();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully assigned ${
            watchedProviderUserIds.length
          } practitioner${
            Boolean(watchedProviderUserIds.length > 1) ? 's' : ''
          } to ${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Error assigning ${watchedProviderUserIds.length} practitioners`}
        />
      ));
    }
  };

  const handleRemoveProviderUserFromPatientFromPatient = async (
    providerUserId: string,
  ) => {
    try {
      await removePatientsFromProviderUsers({
        variables: {
          input: {
            providerUserIds: [providerUserId],
            programIds: [selectedPatient?.programInstanceId],
          },
        },
      });

      await refetchPatient();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully removed practitioner from ${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Error removing practitioner from ${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
        />
      ));
    }
  };

  const closeAndResetModal = async () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
    // await refetchPatient();
  };

  return (
    <Modal
      width="large"
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      hasPadding={false}
      hasCloseRow={false}
      aboveEverything
    >
      <FormProvider {...assignProviderUsersForm}>
        <form
          className="flex min-h-[80vh] w-full flex-row items-stretch"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex min-h-full w-1/2 flex-col items-start p-8">
            <div className="mt-11 mb-5 flex w-full flex-row items-center justify-between">
              <div className="font-serif text-subtitle-small">
                {selectedPatient?.firstName} {selectedPatient?.lastName}'s care
                team
              </div>
              <div className="flex flex-row items-center justify-start text-caption text-neutral-125">
                <div>Assigned</div>
                <div className="ml-2 rounded-full bg-neutral-25 px-2 py-[2px] font-medium">
                  {selectedPatient?.assignedProviderUsers.length}
                </div>
              </div>
            </div>
            <AssignedProviderUsersList
              providerUsers={selectedPatient?.assignedProviderUsers}
              handleRemoveProviderUserFromPatientFromPatient={
                handleRemoveProviderUserFromPatientFromPatient
              }
            />
          </div>
          <div className="flex min-h-full w-1/2 flex-col items-start justify-between bg-neutral-50 p-8 pt-4">
            <div className="flex w-full flex-col items-start justify-start">
              <div className="mb-6 flex h-[40px] w-full flex-row items-center justify-end">
                <Button
                  onClick={setClosed}
                  size="small"
                  title="Close"
                  iconPosition="left"
                  theme="secondary-white"
                  IconComponent={XIcon}
                />
              </div>
              <div className="mb-4 flex flex-row items-center justify-between">
                <div className="font-serif text-small">
                  Assign new practitioners{' '}
                  {Boolean(watchProviderUsers.length) && (
                    <span>({watchProviderUsers.length})</span>
                  )}
                </div>
              </div>
              <Controller
                name="providerUsers"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: {
                    value: true,
                    message: 'Choose your practitioners',
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <Combobox
                        as="div"
                        className="relative mb-5 w-full text-body"
                        value={value}
                        onChange={(patients) => {
                          setProviderUserQuery('');
                          onChange(patients);
                        }}
                        immediate
                        multiple
                      >
                        <Combobox.Input
                          className={classNames(
                            'relative flex w-full cursor-default flex-row items-center justify-start text-body placeholder:text-body',
                            'max-h-12 rounded-md bg-white p-4 text-left text-green-150 shadow-sm sm:text-caption',
                            'border-0 placeholder:text-neutral-125/75 focus:border-green-50 focus:outline-none focus:ring-2 focus:ring-green-50',
                          )}
                          onChange={(event) =>
                            setProviderUserQuery(event.target.value)
                          }
                          placeholder="Search for a practitioner"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-neutral-125"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setProviderUserQuery('')}
                        >
                          <Combobox.Options className="no-scrollbar absolute z-10 mt-1 max-h-60 w-full divide-y divide-neutral-75 overflow-auto rounded-lg border border-neutral-75  bg-white py-1 text-caption shadow-lg focus:outline-none focus:ring-0">
                            {filteredProviderUsers.map((providerUser) => (
                              <Combobox.Option
                                key={providerUser.id}
                                value={providerUser}
                              >
                                {({ active, selected }) => {
                                  return (
                                    <div
                                      className={classNames(
                                        'relative flex cursor-pointer select-none flex-row truncate py-3 px-5 text-green-150 hover:bg-neutral-25',
                                        {
                                          'bg-neutral-25': selected || active,
                                        },
                                      )}
                                    >
                                      <Avatar
                                        size="small"
                                        name={providerUser.name}
                                        imageUrl={
                                          providerUser?.profileImageMedia?.url
                                        }
                                        className="mr-2"
                                      />
                                      {providerUser.name}
                                    </div>
                                  );
                                }}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </Transition>
                      </Combobox>

                      {Boolean(watchProviderUsers.length) && (
                        <ul className="no-scrollbar mb-4 flex max-h-[450px] w-full flex-row flex-wrap gap-2 overflow-y-scroll">
                          {watchProviderUsers.map((providerUser) => (
                            <li
                              key={providerUser.id}
                              className="relative flex w-full flex-row justify-between gap-x-2 rounded-full bg-neutral-50 py-1.5"
                            >
                              <div className="flex flex-row items-center justify-start">
                                <Avatar
                                  size="medium"
                                  name={providerUser.name}
                                  imageUrl={
                                    providerUser?.profileImageMedia?.url
                                  }
                                />
                                <div className="relative ml-3 font-serif text-category">
                                  {providerUser.name}
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  onChange(
                                    _.without(watchProviderUsers, providerUser),
                                  );
                                }}
                              >
                                <TrashIcon
                                  className={
                                    'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                                  }
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div className="flex w-full flex-col items-end justify-start">
              <Button
                title="Confirm"
                disabled={!Boolean(watchProviderUsers.length)}
                className="mb-4 w-36"
                onClick={handleAssignProviderUsers}
              />
              <div className="flex flex-row items-center justify-start text-small-caption text-neutral-125">
                <SparklesIcon className="mr-3 h-10 w-10" />
                <div>
                  When you confirm a new practitioner assignment, Homecoming
                  notifies the practitioner they've been paired.
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AssignProviderUsersModal;
