import { FC, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';

import {
  PatientLifecycleStatusV2,
  PatientTableDataFragment,
  useResendPatientInvitesMutation,
} from '../../../generated/graphql';

import Button from '../Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../Modal';

import {
  PatientsObject,
  getPatientsObject,
} from '../../pages/PatientManagement/helpers';

import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { PATIENTS_ACTIVE_BASE_PATH } from '../../pages/PatientManagement';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';

type ResendInvitesProps = {
  isModalOpen: boolean;
  singlePatient: PatientTableDataFragment | null;
  patientsObject?: PatientsObject | null;
  isSinglePatient?: boolean;
  isPatientManagement?: boolean;
  patientLifecycleStatus: PatientLifecycleStatusV2 | null;
  setClosed: () => void;
  clearFilters?: () => void;
  refetchPatient?: () => Promise<void>;
  setPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
};

const ResendInvitesModal: FC<ResendInvitesProps> = ({
  isModalOpen,
  singlePatient,
  patientsObject,
  isSinglePatient = false,
  isPatientManagement = false,
  patientLifecycleStatus,
  setClosed,
  clearFilters,
  refetchPatient,
  setPatientsObject,
}) => {
  const { handleSubmit, reset: resetForm } = useForm({
    mode: 'onSubmit',
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [resendPatientInvitesMutation] = useResendPatientInvitesMutation();

  const programIds = (singlePatient ? [singlePatient] : []).map(
    (patient) => patient.programInstanceId,
  );

  const actionText = singlePatient?.onboardingLastProgramInviteSentAt
    ? 'Resend'
    : 'Send';

  const buttonTitleText = useMemo(() => {
    return singlePatient
      ? `${actionText} now to ${singlePatient?.firstName}`
      : `${actionText} now`;
  }, [singlePatient]);

  const isDemoClient = singlePatient?.isTestProgram;

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (!programIds) return;
    const sendProgramInvitesToPatientsInput = {
      programIds,
    };

    try {
      setLoading(true);
      const response = await resendPatientInvitesMutation({
        variables: {
          input: sendProgramInvitesToPatientsInput,
        },
      });
      const updatedPatients =
        response?.data?.resendPatientInvites?.updatedPatients;

      if (isDemoClient) {
        trackProviderEvent(ProviderAnalyticsEvent.DemoClientInvited, {
          resend: Boolean(singlePatient?.onboardingLastProgramInviteSentAt),
        });
      }

      if (patientsObject && updatedPatients && updatedPatients?.length > 0) {
        if (
          patientsObject &&
          patientLifecycleStatus === PatientLifecycleStatusV2.Invited
        ) {
          const updatedPatientsObject = {
            ...patientsObject,
            ...getPatientsObject(updatedPatients),
          };

          if (isPatientManagement && setPatientsObject)
            setPatientsObject(updatedPatientsObject);
        }

        if (isPatientManagement && clearFilters) {
          clearFilters();
          navigate(PATIENTS_ACTIVE_BASE_PATH);
        }
      } else if (isSinglePatient && refetchPatient) {
        await refetchPatient();
      }
    } catch (err) {
      console.error(err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      closeAndResetResendInvitesModal();
      setLoading(false);
    }
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetResendInvitesModal = () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      name="ResendInvites"
      isOpen={isModalOpen}
      setClosed={closeAndResetResendInvitesModal}
      fetching={loading}
      width="small"
    >
      <div className="flex flex-col items-center justify-between px-10 pb-4">
        <div className="flex flex-col items-center justify-start ">
          <div className="mb-2 font-serif text-subtitle text-green-150">
            {singlePatient ? `${actionText} app invite` : 'Resend app invites'}
          </div>
          <div className="font-sans text-small text-green-125">
            {singlePatient
              ? `${actionText} an app invite email to ${singlePatient?.firstName}`
              : `Resend app invite emails to ${programIds.length} of your clients`}
          </div>
        </div>
        <form className="flex w-full flex-col items-center justify-end">
          <div className="flex flex-col items-center justify-center">
            <Button
              className="my-10"
              type="submit"
              title={buttonTitleText}
              theme="primary"
              IconComponent={ArrowNarrowRightIcon}
              onClick={handleSubmit(onSubmit, handleErrors)}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ResendInvitesModal;
