import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';
import { ProgramOnboardingStatus } from '../../generated/graphql';
import { PATIENT_STATUSES } from '../pages/PatientManagement/helpers';
import Button from './Button';

const ProgramOnboardingStatusItem: React.FC<{
  onboardingStatus: ProgramOnboardingStatus;
  isCloseablePill?: boolean;
  className?: string;
  clearFilter?: () => void;
}> = ({
  onboardingStatus,
  isCloseablePill = false,
  className,
  clearFilter,
}) => {
  return (
    <>
      {!isCloseablePill && (
        <div className="flex flex-row items-center justify-start">
          <div
            className={classNames(
              'flex w-fit flex-row items-center justify-between text-caption font-normal text-neutral-150',
              isCloseablePill &&
                'rounded-full bg-neutral-50 px-3 py-1 hover:bg-neutral-100/70',
              className,
            )}
          >
            <span className="block text-caption text-green-150">
              {PATIENT_STATUSES[onboardingStatus].text}
            </span>
          </div>
        </div>
      )}
      {isCloseablePill && (
        <Button
          title={PATIENT_STATUSES[onboardingStatus].text}
          onClick={clearFilter}
          className="h-[30px] text-caption font-normal text-green-150"
          iconPosition="right"
          IconComponent={XIcon}
          iconClassName="h-[12px] w-[12px] text-green-150 hover:text-green-150/70"
          theme="secondary"
          size="small"
        />
      )}
    </>
  );
};

export default ProgramOnboardingStatusItem;
