import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/solid';

import IconButton from '../../components/IconButton';

const FirstTreatmentDatePopover: React.FC<{
  patientFirstName: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ patientFirstName, onClick }) => {
  return (
    <div className="z-10 flex">
      <Menu as="div" className="relative flex">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <button
            className="absolute bottom-8 left-8 flex w-72 flex-col whitespace-normal rounded-md bg-white p-5 text-left shadow-lg hover:bg-neutral-25"
            onClick={onClick}
          >
            <span className="text-body font-bold text-green-150">
              Missing first session date
            </span>
            <span className="text-netural-125 my-4 text-caption">
              {`To ensure all future assessments are delivered, please add ${patientFirstName}'s first
              session date.`}
            </span>
            <div className="flex w-full flex-row items-center justify-start">
              <span className="mr-1.5 text-caption font-bold text-green-150">
                Add date
              </span>
              <PlusIcon className="h-5 w-5 text-green-100" />
            </div>
          </button>
        </Transition>
        <Menu.Button as={Fragment}>
          <IconButton
            IconComponent={ExclamationCircleIcon}
            aria-label="Missing first session date"
            iconClassName="h-5 w-5 text-orange-100 mb-0.5"
            className="hover:bg-transparent focus:ring-0"
            onClick={(e) => e.stopPropagation()}
          />
        </Menu.Button>
      </Menu>
    </div>
  );
};

export default FirstTreatmentDatePopover;
