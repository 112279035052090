import { FC } from 'react';
import PatientRow from './PatientRow';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { PatientNameDataFragment } from '../../../../../../generated/graphql';
import { defaultTransitionProps } from '../../../../../lib/animation';
import Spinner from '../../../../../svgs/Spinner';

type AssignedPatientsTableProps = {
  patients: PatientNameDataFragment[];
  handleRemovePatientFromProviderUser: (programId: any) => Promise<void>;
};

const AssignedPatientsTable: FC<AssignedPatientsTableProps> = ({
  patients,
  handleRemovePatientFromProviderUser,
}) => {
  return (
    <div className="max-h-[600px] w-full overflow-y-scroll">
      <Transition show={Boolean(patients?.length)} {...defaultTransitionProps}>
        {patients?.map((patient, index) => (
          <PatientRow
            key={`patient-row-${index}`}
            patient={patient}
            handleRemovePatientFromProviderUser={
              handleRemovePatientFromProviderUser
            }
          />
        ))}
      </Transition>
      <Transition show={!Boolean(patients?.length)} {...defaultTransitionProps}>
        <div
          className={classNames(
            'flex w-full flex-row',
            patients?.length === undefined
              ? 'justify-center pt-4'
              : 'justify-start',
          )}
        >
          {patients?.length === undefined ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <div className="justify-start text-caption text-neutral-125">
              None assigned
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};
export default AssignedPatientsTable;
