import { FC } from 'react';

import Button from '../Button';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import {
  ProviderPaymentPlan,
  SubscriptionTier,
  useStripeCheckoutPortalLazyQuery,
} from '../../../generated/graphql';
import ErrorMessage from '../ErrorMessage';

const BillingCheckout: FC<{
  isOnboarding: boolean;
  paymentPlan: ProviderPaymentPlan;
  subscriptionTier?: SubscriptionTier | null;
  clientSeats?: number | null;
  staffSeats?: number | null;
}> = ({
  isOnboarding,
  paymentPlan,
  subscriptionTier,
  clientSeats,
  staffSeats,
}) => {
  const [
    getStripeCheckoutPortal,
    { loading: stripeLoading, error: stripeError },
  ] = useStripeCheckoutPortalLazyQuery();

  const goToStripeCheckoutPortal = async () => {
    const { data } = await getStripeCheckoutPortal({
      variables: {
        isOnboarding,
      },
    });

    if (data?.stripeCheckoutPortal.url) {
      window.location.href = data.stripeCheckoutPortal.url;
    }
  };

  const isMonthly = Boolean(subscriptionTier);

  return (
    <div className="flex flex-col items-center justify-start">
      <div className="mb-4 font-serif text-subtitle text-green-150">
        Can we get your card?
      </div>
      <div className="mb-8 text-center font-sans text-body text-green-125">
        Before sharing Homecoming with your clients, we'll need to get your
        billing set up.
      </div>
      <div className="mb-12 flex w-[360px] flex-col rounded-3xl bg-white py-9 px-6 shadow-2xl">
        <div className="mb-3 font-sans text-big-label text-green-100">
          How billing works
        </div>
        <div className="mb-8 border-b border-neutral-100 pb-8 font-sans text-caption text-neutral-125">
          {isMonthly ? (
            <>
              <div>
                You've chosen Homecoming's{' '}
                <span className="font-bold">{subscriptionTier}</span> plan
              </div>
              <div className="flex flex-col items-center">
                <div className="my-6">
                  <div className="text-neutral-150">
                    <div className="mb-1 mr-2.5 inline-block w-7 text-right text-big-label">
                      {clientSeats}
                    </div>
                    <div className="inline-block text-body">
                      active client seats
                    </div>
                  </div>
                  <div className="text-neutral-150">
                    <div className="mr-2.5 inline-block w-7 text-right text-big-label ">
                      {staffSeats}
                    </div>
                    <div className="inline-block text-body">
                      team member seat
                      {staffSeats && staffSeats > 1 && 's'}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                You can see how many active clients and team member seats you
                have available at any time in the Dashboard's Settings panel.
              </div>
            </>
          ) : (
            <>
              <div className="mb-2">
                We'll charge you once a month for each active client within one
                of your programs.
              </div>
              <div className="mb-2">
                A client becomes active once they accept their invitation to
                sign up and start using the Homecoming app, and becomes inactive
                after the program length you've defined with us.
              </div>
              <div>
                You can see how many active clients you have at any time in the
                Dashboard's Settings panel.
              </div>
            </>
          )}
        </div>
        <div className="mb-2 font-sans text-big-label text-neutral-150">
          {isMonthly ? (
            <span>${paymentPlan.monthlyFee} / month</span>
          ) : (
            <span>${paymentPlan.pmpmFee} / client / month</span>
          )}
        </div>
        <div className="mb-3 font-sans text-body text-neutral-125">
          +${paymentPlan.upfrontFee} setup fee
        </div>
        {!subscriptionTier && paymentPlan.upfrontFee === 0 && (
          <div className="font-sans text-caption text-green-125">
            You won't be charged until you add your clients.
          </div>
        )}
      </div>

      <ErrorMessage className="mb-4">{stripeError?.message}</ErrorMessage>

      <Button
        title="Set up billing"
        theme="primary"
        disabled={stripeLoading}
        IconComponent={ArrowRightLong}
        onClick={goToStripeCheckoutPortal}
      />
    </div>
  );
};

export default BillingCheckout;
