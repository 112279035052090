import { FC, useRef, useState } from 'react';

import toast from 'react-hot-toast';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  CalendarScheduledEventFragment,
  PatientScheduledEventFragment,
  useCancelCalendlyEventMutation,
} from '../../../generated/graphql';

import { RefetchScheduledEventsData } from '../../types/scheduled-event';

import { resizeTextArea } from '../../lib/form';

import Modal from '../Modal';
import Button from '../Button';
import TextAreaGroup from '../TextAreaGroup';
import ToastAlert from '../ToastAlert';

type ConfirmCancelModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  selectedScheduledEvent?:
    | CalendarScheduledEventFragment
    | PatientScheduledEventFragment;
  setSelectedScheduledEvent: React.Dispatch<
    React.SetStateAction<
      CalendarScheduledEventFragment | PatientScheduledEventFragment | undefined
    >
  >;
  refetchScheduledEventsData: RefetchScheduledEventsData;
  patientCancelMode?: boolean;
};

type EventCancellationForm = {
  reasonForCancellation: string;
};

const ConfirmCancelModal: FC<ConfirmCancelModalProps> = ({
  isModalOpen,
  setClosed,
  selectedScheduledEvent,
  setSelectedScheduledEvent,
  refetchScheduledEventsData,
  patientCancelMode,
}) => {
  const {
    watch,
    register,
    setValue,
    reset: resetForm,
    formState: { errors },
    handleSubmit,
  } = useForm<EventCancellationForm>({
    mode: 'onSubmit',
    defaultValues: {
      reasonForCancellation: '',
    },
  });

  const reasonForCancellationFieldRef = useRef<HTMLTextAreaElement | null>(
    null,
  );

  const watchReasonForCancellation = watch('reasonForCancellation');

  const { ref: reasonForCancellationRef, ...reasonForCancellationRegister } =
    register('reasonForCancellation', {
      required: false,
      onBlur: () => {
        setValue('reasonForCancellation', watchReasonForCancellation.trim());
        resizeTextArea(reasonForCancellationFieldRef);
      },
      onChange: () => {
        resizeTextArea(reasonForCancellationFieldRef);
      },
    });

  const closeAndResetModal = () => {
    resetForm();
    setClosed();
  };

  const [
    cancelCalendlyEventMutation,
    { loading: cancelCalendlyEventMutationLoading },
  ] = useCancelCalendlyEventMutation();

  const [submitting, setSubmitting] = useState(false);

  const handleConfirmCancelEvent: SubmitHandler<
    EventCancellationForm
  > = async () => {
    if (selectedScheduledEvent?.calendlyEventUri) {
      try {
        setSubmitting(true);

        let reasonForCancellation = watchReasonForCancellation.trim();

        if (patientCancelMode) {
          reasonForCancellation +=
            '\n\n(This session was cancelled by your client)';
        }

        await cancelCalendlyEventMutation({
          variables: {
            calendlyEventUri: selectedScheduledEvent?.calendlyEventUri,
            reasonForCancellation,
          },
        });
        await refetchScheduledEventsData();
      } catch (error) {
        const errorMessage = (error as Error).message;
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message={errorMessage}
            level="warning"
          />
        ));
      } finally {
        setSelectedScheduledEvent(undefined);
        closeAndResetModal();
        setSubmitting(false);
      }
    }
  };

  const handleErrors: SubmitErrorHandler<EventCancellationForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  return (
    <Modal
      name="UnsavedChanges"
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      width="small"
      fetching={submitting}
    >
      <div className="flex flex-col items-center justify-between px-10 pb-4">
        <div className="flex flex-col items-center justify-start">
          <div className="mb-2 flex flex-col items-center justify-start text-center text-subtitle text-neutral-150">
            Cancel session
          </div>
          <div className="text-center text-body text-green-125">
            Please confirm that you'd like to cancel this event.
          </div>
        </div>
        <TextAreaGroup
          placeholder={'Provide a reason for cancelling...'}
          label="reasonForCancellation"
          value={watchReasonForCancellation}
          rows={3}
          labelHidden
          inputSize="small"
          className="focused:border-green-50 mt-6 h-auto"
          containerClassName="w-full"
          errorMessage={errors.reasonForCancellation?.message}
          disabled={cancelCalendlyEventMutationLoading}
          {...reasonForCancellationRegister}
          ref={(event) => {
            reasonForCancellationRef(event);
            reasonForCancellationFieldRef.current = event;
          }}
        />
        <div className="mt-12 mb-4 flex w-full flex-row items-center justify-center">
          <Button
            title="Don't cancel"
            theme="secondary"
            className="mr-6"
            onClick={closeAndResetModal}
          />
          <Button
            title="Cancel event"
            theme="primary"
            onClick={handleSubmit(handleConfirmCancelEvent, handleErrors)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCancelModal;
