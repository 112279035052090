type PageTitleProps = {
  title: string;
  shownRowsCount?: number;
  selectedRowsCount?: number;
  children?: React.ReactNode;
  preTitleElement?: React.ReactNode;
  postTitleElement?: React.ReactNode;
};

export default function PageTitle({
  title,
  shownRowsCount,
  selectedRowsCount,
  children,
  preTitleElement,
  postTitleElement,
}: PageTitleProps) {
  return (
    <div className="mb-4 flex flex-row items-center justify-between">
      <div className="flex flex-row items-center justify-start">
        {preTitleElement}
        <div className="mr-4 font-serif text-subtitle text-green-150">
          {title}
        </div>
        {postTitleElement}
        {Boolean(shownRowsCount && shownRowsCount > 0) && (
          <span className="-mb-1 text-caption text-neutral-125">
            <span className="font-bold">{shownRowsCount}</span>
            {' shown'}
          </span>
        )}
        {Boolean(selectedRowsCount && selectedRowsCount > 0) && (
          <span className="ml-2 -mb-1 text-caption text-neutral-125">
            <span className="font-bold">{selectedRowsCount}</span>
            {' selected'}
          </span>
        )}
      </div>
      <div className="flex flex-row items-center justify-end">{children}</div>
    </div>
  );
}
