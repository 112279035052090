import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Listbox, Switch } from '@headlessui/react';
import classNames from 'classnames';
import {
  CALENDLY_ENTERPRISE_PLAN_URL,
  DEFAULT_FREE_CLIENT_COUNT,
} from '../../lib/constants';
import ToastAlert from '../ToastAlert';
import toast from 'react-hot-toast';
import {
  SubscriptionTierV2,
  useStripeCheckoutPortalLazyQuery,
  useStripeCustomerPortalLazyQuery,
} from '../../../generated/graphql';
import { ApolloError } from '@apollo/client';
import { useAuth } from '../../../contexts/AuthContext';
import {
  ProviderAnalyticsEvent,
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
} from '../../../lib/analytics';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { BillingState } from '../Billing/BillingSection';
import { getTimeStamp } from '../../lib/time';
import Button from '../Button';
import { HomecomingError, matchHomecomingError } from '../../../lib/errors';
import FeatureCell from './FeatureCell';
import Config from '../../../lib/config';
import MobilePricingCard from './MobilePricingCard';

export enum BillingPeriodOption {
  Annual = 'Annual',
  Monthly = 'Monthly',
}

export interface BillingPeriodInfo {
  period: BillingPeriodOption;
  monthlyPrice: number;
  annualPrice?: number;
  teamBaseMonthlyPrice: number;
  teamBaseAnnualPrice?: number;
  teamPerSeatMonthlyPrice: number;
  teamPerSeatAnnualPrice?: number;
  label?: string;
}

export const BILLING_PERIODS: Record<BillingPeriodOption, BillingPeriodInfo> = {
  [BillingPeriodOption.Annual]: {
    period: BillingPeriodOption.Annual,
    monthlyPrice: 39,
    annualPrice: 468,
    teamBaseMonthlyPrice: 79,
    teamBaseAnnualPrice: 948,
    teamPerSeatMonthlyPrice: 31,
    teamPerSeatAnnualPrice: 372,
    label: 'Save 20%',
  },
  [BillingPeriodOption.Monthly]: {
    period: BillingPeriodOption.Monthly,
    monthlyPrice: 49,
    teamBaseMonthlyPrice: 99,
    teamPerSeatMonthlyPrice: 39,
    teamPerSeatAnnualPrice: 468,
  },
};

const CurrentPlanButtonLabel: FC = () => {
  return (
    <Button
      title="Current plan"
      className="w-full !cursor-auto !bg-green-25 !text-green-125"
      disabled={true}
    />
  );
};

const TEAM_BASE_SEAT_COUNT = 3;

// 3-10 seats
export const TEAM_SEAT_COUNT_OPTIONS = Array.from(
  { length: 8 },
  (_, index) => index + TEAM_BASE_SEAT_COUNT,
);

export type FeatureRow = {
  title: string;
  freeValue: string | boolean | null;
  individualValue: string | boolean | null;
  teamValue: string | boolean | null;
  enterpriseValue: string | boolean;
  freeValueMobile?: string;
  individualValueMobile?: string;
  teamValueMobile?: string;
  enterpriseValueMobile?: string;
};

const Subscription: FC<{
  isPricingEmbed?: boolean;
}> = ({ isPricingEmbed = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const stripeCheckoutPortalWebRedirectPath = (
    location.state as { stripeCheckoutPortalWebRedirectPath?: string }
  )?.stripeCheckoutPortalWebRedirectPath;
  // Can be sent using state (preferred) but also query params (for redirects)
  const isOnboarding = Boolean(
    (location.state as { isOnboarding?: string })?.isOnboarding ??
      searchParams.get('isOnboarding') ??
      false,
  );

  const { authedProviderUser } = useAuth();

  const isSubscribed = authedProviderUser?.isProviderSubscribed ?? false;
  const isTrialing = authedProviderUser?.isTrialing ?? false;
  const trialEndsAt =
    isTrialing && authedProviderUser ? authedProviderUser.trialEndsAt : null;
  const subscriptionEndsAt =
    authedProviderUser?.provider.subscriptionEndsAt ?? null;
  const currentSubscriptionTier =
    authedProviderUser?.provider.subscriptionTierV2;

  const billingState =
    isSubscribed && !subscriptionEndsAt
      ? BillingState.Subscribed
      : isSubscribed && subscriptionEndsAt
      ? BillingState.SubscriptionEnding
      : isTrialing
      ? BillingState.Trialing
      : BillingState.Unsubscribed;

  const subscriptionStatus =
    billingState === BillingState.Subscribed
      ? 'Subscribed'
      : billingState === BillingState.SubscriptionEnding
      ? `Subscribed until ${getTimeStamp(
          subscriptionEndsAt,
          false,
          false,
          true,
        )}`
      : billingState === BillingState.Trialing
      ? `Trialing until ${getTimeStamp(trialEndsAt, false, false, true)}`
      : 'Not subscribed';

  const [getStripeCheckoutPortal, { loading: stripeCheckoutPortalLoading }] =
    useStripeCheckoutPortalLazyQuery();

  const [selectedBillingPeriodOption, setSelectedBillingPeriodOption] =
    useState<BillingPeriodOption>(BillingPeriodOption.Annual);

  const [teamSeatCount, setTeamSeatCount] = useState<number>(
    authedProviderUser?.provider?.subscriptionTierV2 === SubscriptionTierV2.Team
      ? authedProviderUser.provider.staffSeats
      : TEAM_BASE_SEAT_COUNT,
  );

  const hasSeatCountChanged =
    authedProviderUser?.provider?.subscriptionTierV2 ===
      SubscriptionTierV2.Team &&
    teamSeatCount !== authedProviderUser.provider.staffSeats;

  const selectedBillingPeriod = BILLING_PERIODS[selectedBillingPeriodOption];
  const annualSelected =
    selectedBillingPeriodOption === BillingPeriodOption.Annual;

  const switchSelectedBillingPeriod = (
    previousBillingPeriod: BillingPeriodOption,
  ) => {
    if (previousBillingPeriod === BillingPeriodOption.Annual) {
      setSelectedBillingPeriodOption(BillingPeriodOption.Monthly);
    } else {
      setSelectedBillingPeriodOption(BillingPeriodOption.Annual);
    }
  };

  const goToStripeCheckoutPortal = async (
    tierSelection: SubscriptionTierV2,
  ) => {
    let extraSeatCount = undefined;
    if (tierSelection === SubscriptionTierV2.Team) {
      extraSeatCount = teamSeatCount - TEAM_BASE_SEAT_COUNT;
    }

    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      originPage: AnalyticsPage.Subscribe,
      buttonName: 'Subscribe',
      billingPeriodSelected: annualSelected
        ? BillingPeriodOption.Annual
        : BillingPeriodOption.Monthly,
      tierSelected: tierSelection,
      extraSeatCount,
    });

    try {
      const { data, error } = await getStripeCheckoutPortal({
        variables: {
          webRedirectPath: stripeCheckoutPortalWebRedirectPath ?? 'subscribe',
          choseAnnual: annualSelected,
          isOnboarding,
          tierSelection,
          extraSeatCount,
        },
      });

      if (error) {
        throw error;
      }

      if (data?.stripeCheckoutPortal.url) {
        window.location.href = data.stripeCheckoutPortal.url;
      }
    } catch (err) {
      console.error('Error getting Stripe Checkout Portal:', err);

      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        // Shouldn't be able to get here, but just in case
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="You're already subscribed."
            level="error"
          />
        ));
        trackProviderEvent(ProviderAnalyticsEvent.ProviderCheckoutFailed, {
          reason: 'Already subscribed',
        });
        navigate('/');
        return;
      }

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong. Please contact us at support@homecoming.health."
          level="error"
        />
      ));
      trackProviderEvent(ProviderAnalyticsEvent.ProviderCheckoutFailed, {
        reason: 'Server error',
      });
    }
  };

  const [getStripeCustomerPortal, { loading: stripeCustomerPortalLoading }] =
    useStripeCustomerPortalLazyQuery();

  const goToStripeCustomerPortal = async () => {
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      originPage: AnalyticsPage.Subscribe,
      buttonName: 'Upgrade / Downgrade',
      billingPeriodSelected: annualSelected
        ? BillingPeriodOption.Annual
        : BillingPeriodOption.Monthly,
    });

    try {
      const { data } = await getStripeCustomerPortal();

      if (data?.stripeCustomerPortal.url) {
        window.location.href = data.stripeCustomerPortal.url;
      }
    } catch (err) {
      console.error('Error getting Stripe Customer Portal:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong. Please contact us at support@homecoming.health."
          level="error"
        />
      ));
    }
  };

  const goToCorrectStripePortal = async (tierSelection: SubscriptionTierV2) => {
    if (
      [SubscriptionTierV2.Premium, SubscriptionTierV2.Team].includes(
        currentSubscriptionTier,
      )
    ) {
      goToStripeCustomerPortal();
    } else {
      goToStripeCheckoutPortal(tierSelection);
    }
  };

  const featureRows: FeatureRow[] = [
    {
      title: 'Extra team member seat cost',
      freeValue: null,
      individualValue: null,
      teamValue: `$${
        selectedBillingPeriod.teamPerSeatMonthlyPrice
      }/seat billed ${annualSelected ? 'annually' : 'monthly'}`,
      enterpriseValue: 'Custom pricing',
    },
    {
      title: 'Clients',
      freeValue: `${DEFAULT_FREE_CLIENT_COUNT} clients`,
      freeValueMobile: `Limit of ${DEFAULT_FREE_CLIENT_COUNT} clients`,
      individualValue: 'Unlimited',
      teamValue: 'Unlimited',
      enterpriseValue: 'Unlimited',
    },
    {
      title: 'Practitioner dashboard and mobile app',
      freeValue: true,
      individualValue: true,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Client portal and mobile app',
      freeValue: true,
      individualValue: true,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Webpage for scheduling and intake',
      freeValue: true,
      individualValue: true,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Forms and digital signatures',
      freeValue: true,
      individualValue: true,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Team access roles and permissions',
      freeValue: null,
      individualValue: null,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Individual assignment of clients to team members',
      freeValue: null,
      individualValue: null,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Launch & migration support',
      freeValue: null,
      individualValue: null,
      teamValue: true,
      enterpriseValue: true,
    },
    {
      title: 'Support',
      freeValue: 'Chat & email within 2 business days',
      freeValueMobile: 'Support through chat & email within 2 business days',
      individualValue: 'Chat & email within 2 business days',
      individualValueMobile:
        'Support through chat & email within 2 business days',
      teamValue: 'Chat, email, Zoom within 1 business day',
      teamValueMobile:
        'Support through chat, email, Zoom within 1 business day',
      enterpriseValue: 'Chat, email, Zoom within 1 business day',
      enterpriseValueMobile:
        'Support through chat, email, Zoom within 1 business day',
    },
    {
      title: 'Bulk seat pricing',
      freeValue: null,
      individualValue: null,
      teamValue: null,
      enterpriseValue: true,
    },
    {
      title: 'Dedicated account manager',
      freeValue: null,
      individualValue: null,
      teamValue: null,
      enterpriseValue: true,
    },
    {
      title: 'Custom implementation',
      freeValue: null,
      individualValue: null,
      teamValue: null,
      enterpriseValue: true,
    },
  ];

  const extraSeatCount = teamSeatCount - TEAM_BASE_SEAT_COUNT;

  const teamEffectiveMonthlyPrice =
    selectedBillingPeriod.teamBaseMonthlyPrice +
    extraSeatCount * selectedBillingPeriod.teamPerSeatMonthlyPrice;
  const teamAnnualPrice =
    selectedBillingPeriod.teamBaseAnnualPrice +
    extraSeatCount * selectedBillingPeriod.teamPerSeatAnnualPrice;
  const teamSavings =
    BILLING_PERIODS[BillingPeriodOption.Monthly].teamBaseMonthlyPrice * 12 +
    BILLING_PERIODS[BillingPeriodOption.Monthly].teamPerSeatMonthlyPrice *
      extraSeatCount *
      12 -
    teamAnnualPrice;

  return (
    <div
      className={classNames(
        'flex flex-col items-center px-10 sm:justify-center',
        !isPricingEmbed ? 'py-20' : 'pb-10',
      )}
    >
      <div className="mb-8 flex flex-row items-center gap-x-3">
        <Switch
          checked={annualSelected}
          onChange={() =>
            switchSelectedBillingPeriod(selectedBillingPeriodOption)
          }
          className={classNames(
            'relative inline-flex h-[48px] w-[209px] flex-shrink-0 cursor-pointer rounded-full',
            'focus:outline-none focus:ring-2 focus:ring-blue-50 focus:ring-offset-2',
            isPricingEmbed ? 'bg-white' : 'bg-neutral-50',
          )}
        >
          <div
            className={classNames(
              annualSelected ? 'translate-x-full' : 'translate-x-0',
              'pointer-events-none absolute top-1 left-1 inline-block h-[40px] w-[101px] transform rounded-full bg-blue-25 ring-0 transition duration-200 ease-in-out',
            )}
          ></div>
          <div
            className={classNames(
              'absolute top-1 left-0.5 flex h-[40px] w-[101px] items-center justify-center text-body font-medium text-neutral-150',
            )}
            aria-hidden="true"
          >
            Monthly
          </div>
          <div
            className={classNames(
              'absolute top-1 right-0.5 flex h-[40px] w-[101px] items-center justify-center text-body font-medium text-neutral-150',
            )}
            aria-hidden="true"
          >
            Annual
          </div>
        </Switch>
        <div
          className={classNames(
            'w-[80px] rounded bg-green-100 px-2 py-1 text-action font-bold uppercase text-white',
            annualSelected ? 'opacity-100' : 'opacity-50',
          )}
        >
          Save 20%
        </div>
      </div>
      <div
        className={classNames(
          isPricingEmbed
            ? 'hidden rounded-xl bg-white p-5 opacity-[98%] lg:grid'
            : 'grid',
          'mt-8 min-w-[77rem] max-w-[77rem] grid-cols-5 divide-x divide-neutral-50 rounded-md border border-neutral-50',
        )}
      >
        {/* Title row */}
        <div className="p-5 font-serif text-subtitle font-light text-neutral-150">
          Plans
        </div>
        <div className="relative p-5 font-serif text-subtitle font-light text-neutral-150">
          Free
        </div>
        <div className="relative p-5 font-serif text-subtitle font-light text-neutral-150">
          Individual
          {isTrialing && (
            <div className="absolute -top-8 left-0 flex h-8 w-full items-center justify-center rounded-t-md bg-orange-50 text-center font-sans text-small-caption font-medium text-orange-125">
              {subscriptionStatus}
            </div>
          )}
        </div>
        <div className="p-5 font-serif text-subtitle font-light text-neutral-150">
          Team
        </div>
        <div className="p-5 font-serif text-subtitle font-light text-neutral-150">
          Enterprise
        </div>

        {/* Pricing row */}
        <div className="flex items-center gap-x-2 p-5" />
        <div className="flex flex-col p-5">
          <div className="flex h-[60px] items-center gap-x-2">
            <div className="text-[2.872rem] font-medium leading-tight text-neutral-150">
              $0
            </div>
            <div className="max-w-[141px] text-caption font-medium text-neutral-110">
              Free forever
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5">
          <div className="flex h-[60px] items-center gap-x-2">
            <div className="text-[2.872rem] font-medium leading-tight text-neutral-150">
              ${selectedBillingPeriod.monthlyPrice}
            </div>
            <div className="max-w-[141px] text-caption font-medium text-neutral-110">
              USD/month <br />
              billed {annualSelected ? 'annually' : 'monthly'}
            </div>
          </div>

          <div className="mt-1 flex h-6 items-center gap-x-4">
            {annualSelected && (
              <>
                <div className="rounded bg-green-100 px-2 py-1 text-action font-bold uppercase text-white">
                  Save $
                  {BILLING_PERIODS[BillingPeriodOption.Monthly].monthlyPrice *
                    12 -
                    selectedBillingPeriod.annualPrice}
                </div>
                <div className="text-caption font-medium text-neutral-110">
                  ${selectedBillingPeriod.annualPrice} annually
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col p-5">
          <div className="flex h-[60px] items-center gap-x-2">
            <div className="text-[2.872rem] font-medium leading-tight text-neutral-150">
              ${teamEffectiveMonthlyPrice}
            </div>
            <div className="max-w-[145px] font-sans text-caption font-medium text-neutral-110">
              USD/month <br />
              billed {annualSelected ? 'annually' : 'monthly'}
            </div>
          </div>

          <div className="mt-1 flex h-6 items-center gap-x-4">
            {annualSelected && (
              <>
                <div className="rounded bg-green-100 px-2 py-1 text-action font-bold uppercase text-white">
                  Save ${teamSavings}
                </div>
                <div className="text-caption font-medium text-neutral-110">
                  ${teamAnnualPrice} annually
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center p-5">
          <div className="text-title-medium font-medium leading-tight text-neutral-150">
            Let's talk
          </div>
        </div>

        {/* CTA row */}
        <div className="p-5" />
        <div className="p-5" />
        <div className="p-5">
          {currentSubscriptionTier === SubscriptionTierV2.Premium ? (
            <CurrentPlanButtonLabel />
          ) : (
            <Button
              title={
                isPricingEmbed
                  ? 'Start 30-day free trial'
                  : currentSubscriptionTier === SubscriptionTierV2.Team
                  ? 'Downgrade'
                  : 'Subscribe'
              }
              className="w-full bg-blue-125 hover:bg-blue-100"
              onClick={() => {
                if (isPricingEmbed) {
                  window.open(
                    `${Config.REACT_APP_WEB_APP_URL}/sign-up`,
                    '_blank',
                    'noopener noreferrer',
                  );
                } else {
                  goToCorrectStripePortal(SubscriptionTierV2.Premium);
                }
              }}
              disabled={
                stripeCheckoutPortalLoading || stripeCustomerPortalLoading
              }
            />
          )}
        </div>
        <div className="p-5">
          {currentSubscriptionTier === SubscriptionTierV2.Team &&
          !hasSeatCountChanged ? (
            <CurrentPlanButtonLabel />
          ) : (
            <Button
              title={
                isPricingEmbed
                  ? 'Start 30-day free trial'
                  : currentSubscriptionTier === SubscriptionTierV2.Premium
                  ? 'Upgrade'
                  : hasSeatCountChanged
                  ? 'Update seats'
                  : 'Subscribe'
              }
              className="w-full bg-blue-125 hover:bg-blue-100"
              onClick={() => {
                if (isPricingEmbed) {
                  window.open(
                    `${Config.REACT_APP_WEB_APP_URL}/sign-up`,
                    '_blank',
                    'noopener noreferrer',
                  );
                } else {
                  goToCorrectStripePortal(SubscriptionTierV2.Team);
                }
              }}
              disabled={
                stripeCheckoutPortalLoading || stripeCustomerPortalLoading
              }
            />
          )}
        </div>
        <div className="p-5">
          <Button
            title="Schedule a call"
            className="w-full bg-blue-125 hover:bg-blue-100"
            onClick={() => {
              trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                originPage: AnalyticsPage.Subscribe,
                buttonName: 'Enterprise - Schedule a call',
              });
              window.open(
                CALENDLY_ENTERPRISE_PLAN_URL,
                '_blank',
                'noopener noreferrer',
              );
            }}
          />
        </div>

        {/* Seat selection row */}
        <div className="py-6 px-10 text-body text-green-150">
          Team member seats
        </div>
        <div className="py-6 px-8">
          <div className="flex justify-center rounded-full bg-neutral-25 py-2 px-11 text-center text-body font-medium text-neutral-150">
            1 seat
          </div>
        </div>
        <div className="py-6 px-8">
          <div className="flex justify-center rounded-full bg-neutral-25 py-2 px-11 text-center text-body font-medium text-neutral-150">
            1 seat
          </div>
        </div>
        <div className="py-6 px-8">
          <Listbox value={teamSeatCount} onChange={setTeamSeatCount}>
            <Listbox.Button className="flex w-full justify-center rounded-full bg-neutral-25 py-2 px-11 text-center text-body font-medium text-neutral-150">
              {teamSeatCount} seats <ChevronDownIcon className="ml-2 h-5 w-5" />
            </Listbox.Button>
            <Listbox.Options className="absolute z-50 w-[15rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none">
              {TEAM_SEAT_COUNT_OPTIONS.map((seatCount, i) => (
                <Listbox.Option
                  key={`seatCountOption-${seatCount}`}
                  value={seatCount}
                  className={({ active }) =>
                    classNames(
                      'cursor-pointer px-5 py-3 text-caption text-neutral-125',
                      active && 'bg-neutral-25',
                      {
                        'rounded-t-md': active && i === 0, // round top if it's the first item
                        'rounded-b-md':
                          active && i === TEAM_SEAT_COUNT_OPTIONS.length - 1, // round bottom if it's the last item
                      },
                    )
                  }
                >
                  {seatCount} seats
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="py-6 px-8">
          <div className="flex justify-center rounded-full bg-neutral-25 py-2 px-11 text-center text-body font-medium text-neutral-150">
            11+ seats
          </div>
        </div>

        {/* Feature rows */}
        {featureRows.map((row, i) => (
          <Fragment key={`featureRow_${i}`}>
            <FeatureCell value={row.title} className="text-left" />
            <FeatureCell
              value={row.freeValue}
              className="justify-center text-center"
            />
            <FeatureCell
              value={row.individualValue}
              className="justify-center text-center"
            />
            <FeatureCell
              value={row.teamValue}
              className="justify-center text-center"
            />
            <FeatureCell
              value={row.enterpriseValue}
              className="justify-center text-center"
            />
          </Fragment>
        ))}
      </div>

      {isPricingEmbed && (
        <div className="flex flex-col items-center justify-start gap-y-6 lg:hidden">
          <MobilePricingCard
            selectedBillingPeriod={selectedBillingPeriod}
            annualSelected={annualSelected}
            featureRows={featureRows}
            planValue="freeValue"
          />
          <MobilePricingCard
            selectedBillingPeriod={selectedBillingPeriod}
            annualSelected={annualSelected}
            featureRows={featureRows}
            planValue="individualValue"
          />
          <MobilePricingCard
            selectedBillingPeriod={selectedBillingPeriod}
            annualSelected={annualSelected}
            featureRows={featureRows}
            planValue="teamValue"
            teamEffectiveMonthlyPrice={teamEffectiveMonthlyPrice}
            teamSavings={teamSavings}
            teamAnnualPrice={teamAnnualPrice}
            teamSeatCount={teamSeatCount}
            setTeamSeatCount={setTeamSeatCount}
          />
          <MobilePricingCard
            selectedBillingPeriod={selectedBillingPeriod}
            annualSelected={annualSelected}
            featureRows={featureRows}
            planValue="enterpriseValue"
          />
        </div>
      )}
    </div>
  );
};

export default Subscription;
