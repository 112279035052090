import { createContext, ReactNode, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsPage } from '../lib/analytics';
import { stripLeadingSlash } from '../v2/lib/string';

import UpgradeModal from '../v2/components/Modals/UpgradeModal';

interface GlobalModalsContextType {
  showUpgradeModal: (
    originPage?: AnalyticsPage | string,
    webRedirectPath?: string,
  ) => void;
}

const GlobalModalsContext = createContext<GlobalModalsContextType>(null!);

export const GlobalModalsProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const [
    stripeCheckoutPortalWebRedirectPath,
    setStripeCheckoutPortalWebRedirectPath,
  ] = useState<string | undefined>(undefined);
  const [originPage, setOriginPage] = useState<
    AnalyticsPage | string | undefined
  >(undefined);

  const showUpgradeModal = (
    originPage?: AnalyticsPage | string,
    webRedirectPath?: string,
  ) => {
    // Defaults to redirect back to current path
    setStripeCheckoutPortalWebRedirectPath(
      webRedirectPath ?? stripLeadingSlash(location.pathname),
    );
    setOriginPage(originPage ? originPage : undefined);
    setIsUpgradeModalOpen(true);
  };

  return (
    <GlobalModalsContext.Provider
      value={{
        showUpgradeModal,
      }}
    >
      {children}
      <UpgradeModal
        originPage={originPage}
        isModalOpen={isUpgradeModalOpen}
        setClosed={() => setIsUpgradeModalOpen(false)}
        stripeCheckoutPortalWebRedirectPath={
          stripeCheckoutPortalWebRedirectPath
        }
      />
    </GlobalModalsContext.Provider>
  );
};

export const useGlobalModalsContext = () => {
  const context = useContext(GlobalModalsContext);
  if (!context) {
    throw new Error(
      'useGlobalModalsContext must be used within a GlobalModalsProvider',
    );
  }
  return context;
};
