import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';

import { getHasCompletedOnboarding } from '../v1/lib/onboarding';
import { defaultEnterTransitionProps } from '../v1/lib/animation';
import WordmarkLogo from '../v1/svgs/WordmarkLogo';
import ChevronRight from '../v1/svgs/ChevronRight';
import Button from '../v1/components/Button';
import Sidebar from '../v1/components/Sidebar';
import SettingsMenu from '../v1/components/SettingsMenu';
import Loading from '../v1/pages/Loading';

function AuthedContainerV1() {
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    auth0AccessToken,
    authedProviderUser,
    setHasInitialLoadError,
    refreshAuthedProviderUser,
    logout,
  } = useAuth();

  const loadInitialAuthedProviderUser = async () => {
    const response = await refreshAuthedProviderUser();
    if (response.error && response.error.message !== 'Invalid bearer token') {
      // Don't show an error message for invalid token errors.
      setHasInitialLoadError(true);
      await logout();
    }
  };

  useEffect(() => {
    if (auth0AccessToken && !authedProviderUser) {
      // This will occur upon a page refresh.
      loadInitialAuthedProviderUser();
    }
  }, [auth0AccessToken, authedProviderUser]);

  const backButtonActive = Boolean(
    params?.programId || params?.programTemplateId,
  );

  if (!auth0AccessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // We also want to pass along search params on the initial URL (such
    // as UTM params) to the login page
    return (
      <Navigate
        to={`/login${location.search}`}
        state={{ from: location }}
        replace
      />
    );
  }

  const isOnboarding = location.pathname.startsWith('/onboarding');

  if (
    Boolean(authedProviderUser) &&
    !getHasCompletedOnboarding(authedProviderUser) &&
    !isOnboarding &&
    !location.pathname.includes('billing/success') &&
    !location.pathname.includes('logout')
  ) {
    return <Navigate to="/onboarding" replace />;
  }

  const hideHeader =
    location.pathname.includes('billing/success') ||
    location.pathname.includes('logout');

  const Header = isOnboarding ? (
    <header className="z-20 flex h-16 w-screen items-center justify-center bg-white">
      <div className="flex items-center px-12">
        <WordmarkLogo className="h-8" />
      </div>
    </header>
  ) : (
    <header
      className={classNames(
        'fixed z-20 flex h-16 w-screen flex-row items-center justify-between',
        'border-b border-neutral-50 bg-white pl-32 text-caption font-medium text-green-150',
      )}
    >
      <Button
        onClick={() => navigate(-1)}
        title="BACK"
        theme="secondary"
        noBackground
        noOutline
        iconPosition="left"
        IconComponent={ChevronRight}
        iconClassName="rotate-180 h-7 w-7 text-green-150"
        className={classNames(
          'ml-4 text-green-150 transition duration-150',
          !backButtonActive && 'opacity-0',
          backButtonActive && 'opacity-100',
        )}
      />

      <span>{authedProviderUser?.provider.name}</span>

      <div className="mr-12 flex items-center">
        <div className="mr-3">{authedProviderUser?.name}</div>
        <SettingsMenu
          isOpen={isSettingsMenuOpen}
          setIsOpen={setIsSettingsMenuOpen}
        />
      </div>
    </header>
  );

  return (
    <FeatureFlagProvider>
      {/* Child pages will not be mounted until authedProviderUser is defined. */}
      <Transition
        show={Boolean(authedProviderUser)}
        {...defaultEnterTransitionProps}
      >
        {!hideHeader && Header}
        {!isOnboarding && <Sidebar />}

        <div
          className={classNames(
            !hideHeader && 'pt-16',
            !isOnboarding && 'pl-24',
          )}
        >
          <Outlet />
        </div>
      </Transition>

      {!authedProviderUser && <Loading />}
    </FeatureFlagProvider>
  );
}

export default AuthedContainerV1;
