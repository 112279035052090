import { FC, useState } from 'react';
import { usePopper } from 'react-popper';

import { Listbox, Portal } from '@headlessui/react';
import {
  ArchiveIcon,
  MailIcon,
  RefreshIcon,
  TrashIcon,
  UserAddIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';

import {
  PatientDataPermission,
  ProviderUserMemberDataFragment,
  ProviderUserRole,
} from '../../../../../generated/graphql';

import ChevronRight from '../../../../svgs/ChevronRight';

import Avatar from '../../../../components/Avatar';
import IconButton from '../../../../components/IconButton';

import {
  PatientDataPermissionOptions,
  ProviderUserRoleOptions,
  getLabelFromPatientDataPermission,
} from './helpers';

interface TeamMemberRowProps {
  providerUserMember: ProviderUserMemberDataFragment;
  handleAssignPatients: () => void;
  handleDeleteInvite: () => void;
  handleResendInvite: () => void;
  handleReactivate: () => void;
  handleDeactivate: () => void;
  handleRoleOrPatientDataPermissionChange: (
    providerUserId: string,
    role: ProviderUserRole,
    patientDataPermission: PatientDataPermission,
  ) => void;
  isRoleChangeLoading: boolean;
}

const TeamMemberRow: FC<TeamMemberRowProps> = ({
  providerUserMember,
  handleAssignPatients,
  handleDeleteInvite,
  handleResendInvite,
  handleReactivate,
  handleDeactivate,
  handleRoleOrPatientDataPermissionChange,
  isRoleChangeLoading,
}) => {
  const onRoleChange = async (role: ProviderUserRole) => {
    return handleRoleOrPatientDataPermissionChange(
      providerUserMember.id,
      role,
      providerUserMember.patientDataPermission,
    );
  };

  const onPatientDataPermissionChange = async (
    patientDataPermission: PatientDataPermission,
  ) => {
    return handleRoleOrPatientDataPermissionChange(
      providerUserMember.id,
      providerUserMember.role,
      patientDataPermission,
    );
  };

  const [roleElement, setRoleElement] = useState(null);
  const [rolePopperElement, setRolePopperElement] = useState(null);

  const { styles: roleStyles, attributes: roleAttributes } = usePopper(
    roleElement,
    rolePopperElement,
    {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
    },
  );

  const [patientDataPermissionElement, setPatientDataPermissionElement] =
    useState(null);

  const [
    patientDataPermissionElementPopperElement,
    setPatientDataPermissionElementPopperElement,
  ] = useState(null);

  const {
    styles: patientDataPermissionStyles,
    attributes: patientDataPermissionAttributes,
  } = usePopper(
    patientDataPermissionElement,
    patientDataPermissionElementPopperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
    },
  );

  const inviteAccepted = Boolean(providerUserMember?.name);
  const hasPatientDataPermission =
    providerUserMember.patientDataPermission === PatientDataPermission.All ||
    providerUserMember.patientDataPermission === PatientDataPermission.Assigned;

  const canAssignPatients =
    !providerUserMember.deactivatedAt &&
    inviteAccepted &&
    hasPatientDataPermission;

  const isOwner = providerUserMember.role === ProviderUserRole.Owner;

  return (
    <tr className="text-caption text-green-150 hover:bg-neutral-25">
      <td>
        <div className="flex flex-row items-center gap-x-4">
          <Avatar
            imageUrl={providerUserMember.profileImageMedia?.url}
            name={providerUserMember.name ?? providerUserMember.email}
            size="medium"
          />
          <div
            className={
              (classNames(
                'flex flex-col items-start justify-start font-serif text-extra-small',
              ),
              providerUserMember.deactivatedAt
                ? 'text-neutral-110'
                : 'text-green-150')
            }
          >
            <div
              className={classNames(
                !providerUserMember.name && 'text-neutral-110',
              )}
            >
              {providerUserMember.name ?? '(Invite pending)'}
            </div>
            <div className="font-sans text-small-caption">
              {providerUserMember.email}
            </div>
          </div>
        </div>
      </td>
      <td className="text-caption text-neutral-110">
        {!providerUserMember.deactivatedAt && (
          <Listbox
            as="div"
            value={providerUserMember.role}
            onChange={onRoleChange}
            className="relative"
            disabled={providerUserMember.role === ProviderUserRole.Owner}
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  className="flex flex-row items-center rounded-md text-left"
                  ref={setRoleElement}
                >
                  <div className="text-caption text-neutral-110">
                    {providerUserMember.role}
                  </div>
                  {providerUserMember.role !== ProviderUserRole.Owner && (
                    <div className="flex items-center pl-2">
                      <ChevronRight
                        className={classNames(
                          open ? '-rotate-90' : 'rotate-90',
                        )}
                      />
                    </div>
                  )}
                </Listbox.Button>
                <Portal>
                  <Listbox.Options
                    className="absolute z-50 w-[24rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                    ref={setRolePopperElement}
                    style={roleStyles.popper}
                    {...roleAttributes.popper}
                  >
                    {ProviderUserRoleOptions.map((roleOption, index) => (
                      <Listbox.Option
                        key={`roleOption_${roleOption.role}`}
                        value={roleOption.role}
                        disabled={isRoleChangeLoading}
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer px-4 py-5',
                            active && 'bg-neutral-25',
                            {
                              'rounded-t-md': active && index === 0, // round top if it's the first item
                              'rounded-b-md':
                                active &&
                                index === ProviderUserRoleOptions.length - 1, // round bottom if it's the last item
                            },
                          )
                        }
                      >
                        <div className="text-body font-medium text-neutral-125">
                          {roleOption.role}
                        </div>
                        <div className="text-small-caption text-neutral-125">
                          {roleOption.description}
                        </div>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Portal>
              </>
            )}
          </Listbox>
        )}
      </td>

      <td className="text-caption text-neutral-110">
        {!providerUserMember.deactivatedAt && (
          <div className="flex flex-row items-center justify-start">
            <Listbox
              as="div"
              value={providerUserMember.patientDataPermission}
              onChange={onPatientDataPermissionChange}
              className="relative"
            >
              {({ open }) => {
                const { label, textColor } = getLabelFromPatientDataPermission(
                  providerUserMember.patientDataPermission,
                );

                return (
                  <>
                    <Listbox.Button
                      className="flex flex-row items-center rounded-md"
                      ref={setPatientDataPermissionElement}
                    >
                      <div
                        className={classNames(
                          'text-left text-small-caption font-medium',
                          textColor,
                        )}
                      >
                        {label}
                      </div>
                      <div className="flex items-center pl-2">
                        <ChevronRight
                          className={classNames(
                            open ? '-rotate-90' : 'rotate-90',
                          )}
                        />
                      </div>
                    </Listbox.Button>
                    <Portal>
                      <Listbox.Options
                        className="absolute z-50 w-[24rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                        ref={setPatientDataPermissionElementPopperElement}
                        style={patientDataPermissionStyles.popper}
                        {...patientDataPermissionAttributes.popper}
                      >
                        {PatientDataPermissionOptions.filter(
                          ({ patientDataPermission }) =>
                            providerUserMember.role !==
                              ProviderUserRole.Practitioner ||
                            patientDataPermission !==
                              PatientDataPermission.None,
                        ).map((patientDataPermissionOption, index) => (
                          <Listbox.Option
                            key={`patientDataPermissionOption_${patientDataPermissionOption.patientDataPermission}`}
                            value={
                              patientDataPermissionOption.patientDataPermission
                            }
                            disabled={isRoleChangeLoading}
                            className={({ active }) =>
                              classNames(
                                'cursor-pointer px-4 py-5',
                                active && 'bg-neutral-25',
                                {
                                  'rounded-t-md': active && index === 0, // round top if it's the first item
                                  'rounded-b-md':
                                    active &&
                                    index ===
                                      PatientDataPermissionOptions.length - 1, // round bottom if it's the last item
                                },
                              )
                            }
                          >
                            <div className="text-body font-medium text-neutral-125">
                              {patientDataPermissionOption.label}
                            </div>
                            <div className="text-small-caption text-neutral-125">
                              {patientDataPermissionOption.description}
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Portal>
                  </>
                );
              }}
            </Listbox>
          </div>
        )}
      </td>

      <td className="text-center text-caption text-neutral-125">
        {!providerUserMember.deactivatedAt && providerUserMember.name && (
          <button
            onClick={hasPatientDataPermission && handleAssignPatients}
            className={classNames(
              'rounded-full bg-neutral-50 px-2.5 py-1 font-medium ',
              hasPatientDataPermission
                ? 'hover:bg-neutral-100/75'
                : 'cursor-not-allowed',
            )}
          >
            {providerUserMember?.assignedPatients}
          </button>
        )}
      </td>

      <td className="flex flex-row items-center justify-end">
        {!inviteAccepted ? (
          <>
            <IconButton
              aria-label="Resend invite"
              className="ml-2 !rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75 disabled:hover:bg-neutral-50"
              iconClassName="h-5 w-5 text-neutral-125"
              IconComponent={MailIcon}
              onClick={handleResendInvite}
            />
            <IconButton
              aria-label="Delete invite"
              className="ml-2 !rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75 disabled:hover:bg-neutral-50"
              iconClassName="h-5 w-5 text-neutral-125"
              IconComponent={TrashIcon}
              onClick={handleDeleteInvite}
            />
          </>
        ) : (
          <>
            <IconButton
              IconComponent={UserAddIcon}
              aria-label="Assign clients"
              className="ml-2 !rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75 disabled:hover:bg-neutral-50"
              iconClassName="h-5 w-5 text-neutral-125"
              onClick={handleAssignPatients}
              disabled={!canAssignPatients}
            />
            {!providerUserMember.deactivatedAt ? (
              <IconButton
                aria-label="Deactivate"
                className="ml-2 !rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75 disabled:hover:bg-neutral-50"
                iconClassName="h-5 w-5 text-neutral-125"
                IconComponent={ArchiveIcon}
                onClick={handleDeactivate}
                disabled={isOwner}
              />
            ) : (
              <IconButton
                aria-label="Reactivate"
                className="ml-2 !rounded-full bg-neutral-50 py-2 px-4 hover:bg-neutral-100/75 disabled:hover:bg-neutral-50"
                iconClassName="h-5 w-5 text-neutral-125"
                IconComponent={RefreshIcon}
                onClick={handleReactivate}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default TeamMemberRow;
