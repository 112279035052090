import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, useEffect, useRef } from 'react';
import useKeyPressed from '../hooks/useKeyPressed';
import CloseX from '../svgs/CloseX';
import Spinner from '../svgs/Spinner';
import Button from './Button';

const CloseRow: FC<{
  closeButtonRef: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
}> = ({ closeButtonRef, onClick }) => (
  <div className="mb-10 flex w-full flex-row justify-end">
    <Button
      ref={closeButtonRef}
      onClick={onClick}
      title="CLOSE"
      iconPosition="left"
      IconComponent={CloseX}
      theme="secondary"
      size="extra-small"
      noBackground
    />
  </div>
);

export const MODAL_TRANSITION_DURATION = 200;

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  setClosed: () => void;
  fetching?: boolean;
  width?: 'small' | 'large';
  overflow?: 'hidden' | 'auto';
  className?: string;
};

const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  setClosed,
  fetching = false,
  width = 'large',
  overflow = 'hidden',
  className,
}) => {
  const isEscapeKeyPressed = useKeyPressed('Escape');
  useEffect(() => {
    if (isEscapeKeyPressed) setClosed();
  }, [isEscapeKeyPressed, setClosed]);

  /* https://headlessui.dev/react/dialog#managing-focus-within-your-dialog */
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={closeButtonRef}
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={setClosed}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-125 bg-opacity-50 backdrop-blur-[4px] transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                'relative inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-[1100px] sm:p-6 sm:align-middle',
                {
                  'w-[90%]': width === 'large',
                  'w-[600px]': width === 'small',
                  'overflow-hidden': overflow === 'hidden',
                },
                className,
              )}
            >
              <CloseRow
                onClick={() => setClosed()}
                closeButtonRef={closeButtonRef}
              />
              {children}
              <Transition
                show={fetching}
                as={Fragment}
                enter="transform ease-out duration-100 transition"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform ease-in duration-100 transition"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 z-40 flex h-full w-full items-center justify-center bg-neutral-25/80">
                  <Spinner className="h-6 w-6 text-green-150" />
                </div>
              </Transition>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
