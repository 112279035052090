import { SvgIconComponent } from '../types/svgs';

const ThumbnailAction: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#D8DEFF" />
      <path
        d="M20 24L23 27L28 21M32 19.2V28.8C32 29.9201 32.0002 30.4802 31.7822 30.908C31.5905 31.2844 31.2841 31.5902 30.9078 31.782C30.48 32 29.9203 32 28.8002 32H19.2002C18.0801 32 17.5196 32 17.0918 31.782C16.7155 31.5902 16.4097 31.2844 16.218 30.908C16 30.4802 16 29.9201 16 28.8V19.2C16 18.0798 16 17.5199 16.218 17.092C16.4097 16.7157 16.7155 16.4097 17.0918 16.218C17.5196 16 18.0801 16 19.2002 16H28.8002C29.9203 16 30.48 16 30.9078 16.218C31.2841 16.4097 31.5905 16.7157 31.7822 17.092C32.0002 17.5199 32 18.0798 32 19.2Z"
        stroke="#2D4BFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailAction;
