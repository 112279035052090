import {
  PatientTableDataFragment,
  ProgramSortOrder,
} from '../../../generated/graphql';
import { namesToOxfordCommaList } from '../../lib/copy';
import { isPatientNewFromStorefront } from '../../lib/patient';
import { ColumnHeaderType, ColumnOrder } from '../../types/tables';

export type PatientCallback = (patient: PatientTableDataFragment) => void;

export interface PatientsObject {
  [key: string]: PatientTableDataFragment;
}

export const sortOptions: Record<string, ColumnOrder> = {
  latestUnreadThenAlphabetical: {
    name: 'latestUnreadThenAlphabetical',
    isAscending: true,
  },
  newestCreated: { name: 'newestCreated', isAscending: true },
  clientAlphabeticalAscending: {
    name: 'clientAlphabeticalAscending',
    isAscending: true,
  },
  clientAlphabeticalDescending: {
    name: 'clientAlphabeticalDescending',
    isDescending: true,
  },
  programAlphabeticalAscending: {
    name: 'programAlphabeticalAscending',
    isAscending: true,
  },
  programAlphabeticalDescending: {
    name: 'programAlphabeticalDescending',
    isDescending: true,
  },
  activePatientStatus: { name: 'activePatientStatus', isAscending: true },
  inactivePatientStatus: { name: 'inactivePatientStatus', isDescending: true },
  activitiesCompletedAscending: {
    name: 'activitiesCompletedAscending',
    isAscending: true,
  },
  activitiesCompletedDescending: {
    name: 'activitiesCompletedDescending',
    isDescending: true,
  },
  latestUnreadCommentAtAscending: {
    name: 'latestUnreadCommentAtAscending',
    isAscending: true,
  },
  latestUnreadCommentAtDescending: {
    name: 'latestUnreadCommentAtDescending',
    isDescending: true,
  },
};

const statusOptions = [
  sortOptions.activePatientStatus,
  sortOptions.inactivePatientStatus,
  sortOptions.newestCreated,
];

export const columnOrderToProgramSortOrder = (
  columnOrder: ColumnOrder,
): ProgramSortOrder => {
  return columnOrder.name === 'clientAlphabeticalAscending'
    ? ProgramSortOrder.AlphabeticalAsc
    : columnOrder.name === 'clientAlphabeticalDescending'
    ? ProgramSortOrder.AlphabeticalDesc
    : columnOrder.name === 'latestUnreadCommentAtAscending'
    ? ProgramSortOrder.LatestUnreadCommentAtAsc
    : columnOrder.name === 'latestUnreadCommentAtDescending'
    ? ProgramSortOrder.LatestUnreadCommentAtDesc
    : ProgramSortOrder.AlphabeticalAsc;
};

const columnHeaderOptions: Record<string, ColumnHeaderType> = {
  name: {
    field: 'name',
    headerName: 'Client',
    isSortable: true,
    columnOrders: [
      sortOptions.clientAlphabeticalAscending,
      sortOptions.clientAlphabeticalDescending,
      sortOptions.newestCreated,
    ],
  },
  program: {
    field: 'program',
    headerName: 'Program',
    isSortable: true,
  },
  status: {
    field: 'status',
    headerName: 'Status',
    isSortable: true,
  },
  inviteSent: {
    field: 'inviteSent',
    headerName: 'Last invite sent',
    isSortable: true,
    columnOrders: statusOptions,
  },
  nextStep: {
    field: 'nextStep',
    headerName: 'Set-up step',
    isSortable: true,
    columnOrders: statusOptions,
  },
  sendInvite: {
    field: 'sendInvite',
    headerName: 'Send invite',
    isSortable: false,
  },
  sendFollowUp: {
    field: 'sendFollowUp',
    headerName: 'Actions',
    isSortable: false,
  },
  unreadComments: {
    field: 'unreadComments',
    headerName: 'Unread comments',
    isSortable: true,
    columnOrders: [
      sortOptions.latestUnreadCommentAtDescending,
      sortOptions.latestUnreadCommentAtAscending,
      sortOptions.newestCreated,
    ],
  },
  tags: {
    field: 'tags',
    headerName: 'Tags',
    isSortable: false,
  },
  actions: {
    field: 'actions',
    headerName: 'Actions',
    isSortable: false,
  },
  archivedAt: {
    field: 'archivedAt',
    headerName: 'Archived Date',
    isSortable: false,
  },
};

export function getNextPatientOrder(
  patientOrder: ColumnOrder,
  orderType: 'name' | 'unreadComments' | 'nextStep' | 'inviteSent',
) {
  const nextOrderIndex =
    columnHeaderOptions[orderType].columnOrders.findIndex(
      (order) => order.name === patientOrder.name,
    ) + 1;
  return (
    columnHeaderOptions[orderType].columnOrders[nextOrderIndex] ||
    columnHeaderOptions[orderType].columnOrders[0]
  );
}

export const columnHeaders: Record<string, ColumnHeaderType[]> = {
  invited: [
    columnHeaderOptions.name,
    columnHeaderOptions.inviteSent,
    columnHeaderOptions.sendInvite,
  ],
  active: [
    columnHeaderOptions.name,
    columnHeaderOptions.tags,
    columnHeaderOptions.unreadComments,
    columnHeaderOptions.sendFollowUp,
  ],
  archived: [
    columnHeaderOptions.name,
    columnHeaderOptions.archivedAt,
    columnHeaderOptions.actions,
  ],
};

export const sortPatients = (
  patients: PatientTableDataFragment[] | null,
  patientOrder: ColumnOrder,
) => {
  if (patients) {
    // First, use the sort order to sort the patients
    patients.sort(
      (
        patientA: PatientTableDataFragment,
        patientB: PatientTableDataFragment,
      ) => {
        const patientAFullName =
          `${patientA.firstName} ${patientA.lastName}`.toLowerCase();
        const patientBFullName =
          `${patientB.firstName} ${patientB.lastName}`.toLowerCase();

        const patientALatestUnreadCommentAt =
          patientA?.latestUnreadCommentAt ?? '-';
        const patientBLatestUnreadCommentAt =
          patientB?.latestUnreadCommentAt ?? '-';

        switch (patientOrder) {
          case sortOptions.newestCreated:
            return patientA.createdAt >= patientB.createdAt ? -1 : 1;
          case sortOptions.clientAlphabeticalAscending:
            return patientAFullName < patientBFullName ? -1 : 1;
          case sortOptions.clientAlphabeticalDescending:
            return patientAFullName >= patientBFullName ? -1 : 1;
          case sortOptions.latestUnreadCommentAtAscending:
            return patientALatestUnreadCommentAt < patientBLatestUnreadCommentAt
              ? -1
              : 1;
          case sortOptions.latestUnreadCommentAtDescending:
            return patientALatestUnreadCommentAt >=
              patientBLatestUnreadCommentAt
              ? -1
              : 1;
          case sortOptions.latestUnreadThenAlphabetical:
            if (
              patientALatestUnreadCommentAt !== patientBLatestUnreadCommentAt
            ) {
              return patientALatestUnreadCommentAt >
                patientBLatestUnreadCommentAt
                ? -1
                : 1;
            } else {
              return patientAFullName < patientBFullName ? -1 : 1;
            }

          default:
            return patientA.createdAt >= patientB.createdAt ? -1 : 1;
        }
      },
    );
    // Then, apply the overriding new patients sort on top
    return patients.sort((patientA, patientB) => {
      const isPatientANewFromStorefront = isPatientNewFromStorefront(patientA);
      const isPatientBNewFromStorefront = isPatientNewFromStorefront(patientB);

      if (isPatientANewFromStorefront && !isPatientBNewFromStorefront) {
        return -1;
      } else if (!isPatientANewFromStorefront && isPatientBNewFromStorefront) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  return [];
};

export const getPatientsObject = (
  patients: PatientTableDataFragment[],
): PatientsObject => {
  return patients.reduce((acc, patient) => {
    if (patient.programInstanceId) {
      acc[patient.programInstanceId] = patient;
    }
    return acc;
  }, {});
};

export const getPatientsList = (
  patientsObject: PatientsObject,
  hasPremiumAccess = true,
  filterOutTestPrograms = false,
) => {
  let patientsList = Object.values(patientsObject).filter(
    (patient) => hasPremiumAccess || !isPatientNewFromStorefront(patient),
  );
  if (filterOutTestPrograms) {
    patientsList = patientsList.filter((patient) => !patient.isTestProgram);
  }
  return patientsList;
};

export const getRestrictedPatientsList = (
  patientsObject: PatientsObject,
  hasPremiumAccess = true,
) => {
  return Object.values(patientsObject).filter(
    (patient) => !hasPremiumAccess && isPatientNewFromStorefront(patient),
  );
};

export const getPatientCount = (patientsObject: PatientsObject) => {
  return getPatientsList(patientsObject).length;
};
export interface Filters {
  [key: string]: boolean;
}

export const filtersObject: Record<string, Filters> = {
  programs: {},
  statuses: {},
};

export const getEligibleFilters = (filters: Record<string, Filters>) => {
  const getEligibleFilter = (filter: Filters) =>
    Object.entries(filter)
      .filter((entry) => entry[1])
      .map((entry) => entry[0]);

  return {
    programFilter: Boolean(filters.programs)
      ? getEligibleFilter(filters.programs)
      : [],
    statusFilter: Boolean(filters.statuses)
      ? getEligibleFilter(filters.statuses)
      : [],
  };
};

export const getInvalidSentenceText = (
  invalidPatients: PatientTableDataFragment[],
) => {
  if (invalidPatients.length === 0) return '';

  const maxPatientsToDisplay = 5;

  const hasManyInvalidPatients = invalidPatients.length > maxPatientsToDisplay;

  const invalidPatientsCleaned = hasManyInvalidPatients
    ? invalidPatients.slice(0, maxPatientsToDisplay)
    : invalidPatients;

  let invalidPatientsText = namesToOxfordCommaList(
    invalidPatientsCleaned.map(
      (patient: PatientTableDataFragment) =>
        `${patient.firstName} ${patient.lastName}`,
    ),
  );

  if (hasManyInvalidPatients) {
    invalidPatientsText = invalidPatientsText
      .split('and')[0]
      .concat('and others');
  }

  return invalidPatientsText;
};

export const getSeatLimitAlertText = (
  clientSeatsRemaining: number,
  numberOfInvites: number,
): string => {
  if (clientSeatsRemaining === 0) {
    return `You have no more client seats remaining.`;
  }
  const clientSeatsRemainingChunk = `You have ${clientSeatsRemaining} client seat${
    clientSeatsRemaining > 1 ? 's' : ''
  } remaining and `;
  const invitationLimitChunk =
    numberOfInvites > 1
      ? `these ${numberOfInvites} invites are over that limit`
      : `this invite is over that limit`;
  return clientSeatsRemainingChunk + invitationLimitChunk;
};
