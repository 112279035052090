export const isStringTrue = (str: string): boolean => {
  return str.toLowerCase() === 'true';
};

export const stripLeadingSlash = (str: string): string => {
  if (str.startsWith('/')) {
    return str.slice(1);
  }
  return str;
};

// Example usage:
// camelCaseToCapitalizedWords('camelCaseString') // returns 'Camel Case String'
export const camelCaseToCapitalizedWords = (camelCaseStr: string): string => {
  // Use a regular expression to match the camel-case string
  // The regex matches any uppercase character that is preceded by a lowercase character
  // and replaces it with a space followed by the same character
  const words = camelCaseStr.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Split the resulting string into an array of words
  const wordArray = words.split(' ');

  // Capitalize the first letter of each word and join the words back into a string
  const capitalizedWords = wordArray
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  // Return the resulting string
  return capitalizedWords;
};

export const fullNameFromFirstAndLast = (
  firstName?: string | null,
  lastName?: string | null,
): string => {
  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
};
