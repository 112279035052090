import { Transition } from '@headlessui/react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { FC, useEffect } from 'react';

import Button from '../Button';
import {
  ProgramTagColor,
  ProgramTagWithCountDataFragment,
  useCreateProgramTagMutation,
  useDeleteProgramTagMutation,
  useMeProviderProgramTagsWithCountLazyQuery,
  useUpdateProgramTagMutation,
} from '../../../generated/graphql';
import classNames from 'classnames';
import ToastAlert from '../ToastAlert';
import toast from 'react-hot-toast';
import {
  FormProvider,
  useFieldArray,
  useForm,
  FieldArrayWithId,
} from 'react-hook-form';
import { defaultTransitionProps } from '../../lib/animation';
import { StagedProgramTagDataFragment } from './ProgramTagManager';
import { useNavigate } from 'react-router-dom';
import ProgramTagRow from './ProgramTagRow';

export type TagManagerFormData = {
  programTags: StagedProgramTagDataFragment[];
};

export type ProgramTagFieldNameType =
  | 'programTags'
  | `programTags.${number}.color`
  | `programTags.${number}`
  | `programTags.${number}.id`
  | `programTags.${number}.description`
  | `programTags.${number}.__typename`
  | `programTags.${number}.name`;

type ProviderProgramTagManagerProps = {
  // If provided, use these tags instead of self-fetching
  initialProviderProgramTags?: ProgramTagWithCountDataFragment[];
  doneButtonOnClick?: () => void;
  fullPageMode?: boolean;
  className?: string;
};

const ProviderProgramTagManager: FC<ProviderProgramTagManagerProps> = ({
  initialProviderProgramTags,
  doneButtonOnClick,
  fullPageMode,
  className,
}) => {
  const navigate = useNavigate();

  const [createProgramTagMutation] = useCreateProgramTagMutation();
  const [updateProgramTagMutation] = useUpdateProgramTagMutation();
  const [deleteProgramTagMutation] = useDeleteProgramTagMutation();

  const [
    providerProgramTagsQuery,
    { loading: isLoadingProviderProgramTags, error: providerProgramTagsError },
  ] = useMeProviderProgramTagsWithCountLazyQuery();

  useEffect(() => {
    if (providerProgramTagsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong getting your tags. Please try refreshing."
          level="error"
        />
      ));
    }
  }, [providerProgramTagsError]);

  const tagManagerForm = useForm<TagManagerFormData>({
    mode: 'onBlur',
    defaultValues: async () => {
      if (initialProviderProgramTags) {
        return {
          programTags: initialProviderProgramTags,
        };
      }
      const { data } = await providerProgramTagsQuery();
      return { programTags: data.meProvider.provider.programTags };
    },
  });

  const { fields, append, update, remove } = useFieldArray({
    name: 'programTags',
    keyName: 'key',
    control: tagManagerForm.control,
  });

  const saveTag = async (fieldName: ProgramTagFieldNameType) => {
    if (tagManagerForm.getFieldState(fieldName).isDirty) {
      try {
        const index = parseInt(fieldName.split('.')[1]);
        const tag = tagManagerForm.getValues(`programTags.${index}`);
        if (!tag.name.trim()) {
          return;
        }
        if (!tag.id) {
          const { data } = await createProgramTagMutation({
            variables: {
              input: {
                name: tag.name,
                description: tag.description,
                color: tag.color,
              },
            },
          });
          if (data?.createProgramTag) {
            tagManagerForm.resetField(fieldName); // Resets dirty state of field
            update(index, { ...tag, ...data.createProgramTag });
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message="Tag created"
                level="success"
              />
            ));
          }
        } else {
          const { data } = await updateProgramTagMutation({
            variables: {
              input: {
                programTagId: tag.id,
                name: tag.name,
                description: tag.description,
                color: tag.color,
              },
            },
          });
          if (data?.updateProgramTag) {
            tagManagerForm.resetField(fieldName); // Resets dirty state of field
            update(index, { ...tag, ...data.updateProgramTag });
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message="Tag updated"
                level="success"
              />
            ));
          }
        }
      } catch (error) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message={
              error.message ||
              'Something went wrong saving your tag. Please try again.'
            }
            level="error"
          />
        ));
      }
    }
  };

  const handleDeleteAction = async (
    field: FieldArrayWithId<TagManagerFormData, 'programTags', 'key'>,
    index: number,
  ) => {
    const { id: programTagId } = field;

    if (!programTagId) {
      // If the tag hasn't been saved yet, just remove it from the list
      remove(index);
      return;
    }

    try {
      const { data } = await deleteProgramTagMutation({
        variables: {
          programTagId,
        },
      });
      if (data?.deleteProgramTag) {
        remove(index);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Tag deleted"
            level="success"
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong deleting your tag. Please try again."
          level="error"
        />
      ));
    }
  };

  const handleMailAction = async (
    field: FieldArrayWithId<TagManagerFormData, 'programTags', 'key'>,
  ) => {
    navigate(`/follow-ups/new`, {
      state: { initialProgramTags: [field] },
    });
  };

  useEffect(() => {
    // Callback form of watch (https://react-hook-form.com/api/useform/watch/)
    const subscription = tagManagerForm.watch(
      (value, { name, type }) =>
        // console.log(value, name, type)
        void 0,
    );
    return () => subscription.unsubscribe();
  }, [tagManagerForm.handleSubmit, tagManagerForm.watch]);

  return (
    <Transition
      show={!isLoadingProviderProgramTags && !providerProgramTagsError}
      {...defaultTransitionProps}
    >
      <FormProvider {...tagManagerForm}>
        <form className={classNames(className)}>
          <div className="h-full overflow-x-auto">
            <div className="mb-2 flex w-full gap-x-3 text-small-caption font-bold text-green-150">
              <div className="w-[200px] min-w-[200px] max-w-[400px]">Tag</div>
              <div className="min-w-[400px] grow">Description</div>
              <div className="w-[60px] flex-none">Color</div>
              <div className="w-[120px] flex-none">Actions</div>
            </div>

            <div
              className={classNames(
                'flex w-full flex-col gap-y-3',
                !fullPageMode && 'h-[60vh]',
              )}
            >
              {fields.map((field, index) => (
                <ProgramTagRow
                  key={field.key}
                  field={field}
                  index={index}
                  tagManagerForm={tagManagerForm}
                  saveTag={saveTag}
                  handleMailAction={handleMailAction}
                  handleDeleteAction={handleDeleteAction}
                />
              ))}
            </div>

            {fullPageMode && (
              <Button
                title="New tag"
                IconComponent={PlusSmIcon}
                iconPosition="left"
                theme="secondary"
                className="mt-6 ml-1"
                onClick={() =>
                  append({
                    name: '',
                    description: '',
                    color: ProgramTagColor.Gray,
                  })
                }
              />
            )}
          </div>
          <div className="mt-6 flex w-full flex-row items-center justify-between">
            {!fullPageMode && (
              <Button
                title="New tag"
                IconComponent={PlusSmIcon}
                iconPosition="left"
                theme="secondary"
                onClick={() =>
                  append({
                    name: '',
                    description: '',
                    color: ProgramTagColor.Gray,
                  })
                }
              />
            )}
            {doneButtonOnClick && (
              <Button
                title="Done"
                theme="primary"
                onClick={doneButtonOnClick}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </Transition>
  );
};

export default ProviderProgramTagManager;
