import classNames from 'classnames';
import { CheckInData } from '../../generated/graphql';

const CheckInGroup: React.FC<{
  itemType?: 'average' | 'count';
  data?: CheckInData;
  isEmpty?: boolean;
  hasDifferenceIndicator?: boolean;
}> = ({
  itemType,
  data = null,
  isEmpty = false,
  hasDifferenceIndicator = true,
}) => {
  if (!data || isEmpty) {
    return (
      <div className="flex flex-row items-center justify-start">
        <span className="block truncate text-caption text-green-150">
          No check-ins yet
        </span>
      </div>
    );
  }

  const {
    last7DaysAverage,
    last7DaysAverageDifference,
    last7DaysCheckInsCount,
    last7DaysCheckInsCountDifference,
  } = data;

  const last7DaysValue =
    itemType === 'average' ? last7DaysAverage : last7DaysCheckInsCount;
  const last7DaysDifference =
    itemType === 'average'
      ? last7DaysAverageDifference
      : last7DaysCheckInsCountDifference;

  const isLast7DaysDifferencePositive =
    last7DaysDifference && last7DaysDifference > 0;
  const isLast7DaysDifferenceNegative =
    last7DaysDifference && last7DaysDifference < 0;
  const isLast7DaysDifferenceZero = last7DaysDifference === 0;

  return (
    <div className="flex flex-row items-center justify-start">
      <div className="mr-3 flex flex-row items-center justify-start text-big-label">
        <span className="mr-2">{last7DaysValue}</span>
      </div>
      {hasDifferenceIndicator &&
        (last7DaysDifference || last7DaysDifference === 0) && (
          <div
            className={classNames(
              'w-fit rounded-full px-3 py-1 text-caption',
              isLast7DaysDifferencePositive && 'bg-green-50 text-green-150',
              isLast7DaysDifferenceNegative && 'bg-red-25 text-red-125',
              isLast7DaysDifferenceZero && 'bg-neutral-50 text-neutral-150',
            )}
          >
            <span className="block truncate font-bold">
              {isLast7DaysDifferencePositive && '+'}
              {last7DaysDifference}
            </span>
          </div>
        )}
    </div>
  );
};

export default CheckInGroup;
