import { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export interface TabProps {
  route: string;
  name: string;
  count?: number;
  isLastTab?: boolean;
}

const Tab: FC<TabProps> = ({ route, name, count, isLastTab }) => {
  return (
    <NavLink
      to={route}
      className={classNames(
        !isLastTab && 'mr-6',
        'rounded-full focus:outline-green-50',
      )}
      // Ensures that this Link isn't "active" when its descendant paths are matched
      end={route === '.'}
    >
      {({ isActive }) => (
        <>
          <div
            className={classNames(
              isActive && 'pt-[2px]',
              'mb-2 flex flex-col items-center justify-between px-2',
            )}
          >
            <div className="flex flex-row items-center justify-between rounded-xl px-3 py-2 hover:bg-neutral-25">
              <span
                className={classNames(
                  'font-sans text-caption text-neutral-150',
                  isActive && 'text-green-150',
                )}
              >
                {name}
              </span>
              {count !== undefined && (
                <div
                  className={classNames(
                    'min-h-6 min-w-6 ml-3 flex flex-row items-center justify-center rounded-full p-1 px-2 text-neutral-150',
                    isActive ? 'bg-green-25' : 'bg-neutral-100/30',
                  )}
                >
                  <span className="text-small-caption font-bold">{count}</span>
                </div>
              )}
            </div>
          </div>
          {isActive && (
            <div className="relative top-[1px] w-full border-t-2 border-green-100" />
          )}
        </>
      )}
    </NavLink>
  );
};

export default Tab;
