import { FC, Fragment } from 'react';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/solid';
import Button from '../../../components/Button';
import Pencil from '../../../svgs/Pencil';
import PanelButton from './PanelButton';

export type ContentItem = {
  content?: string;
  link?: string;
};

export type PatientDataList = {
  content?: string;
  contentList?: ContentItem[];
  label?: string;
  link?: string;
  isButtonItem?: boolean;
  isFirstSessionDate?: boolean;
  isFirstSessionDateEditable?: boolean;
  onClick?: () => void;
}[];

const ProfileInfoSection: FC<{
  name: string;
  patientData: PatientDataList;
  setIsFirstAppointmentDateModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({ name, patientData, setIsFirstAppointmentDateModalOpen }) => {
  return (
    <div className="mb-4 flex w-full flex-row items-start justify-start pr-6">
      <div className="flex w-full flex-col items-start justify-start border-b border-neutral-50 pb-3">
        <span className="mb-4 text-caption font-bold text-green-125">
          {name}
        </span>
        {patientData.map((data, index) => {
          const needsFirstSessionDate =
            data.isFirstSessionDate && !data.isFirstSessionDateEditable;

          return (
            <Fragment key={`profileInfoSection_${index}`}>
              <div className="mb-3 flex w-full flex-row items-start justify-between text-caption">
                <div className="flex flex-row items-center justify-start">
                  <span className="text-neutral-150">{data.label}</span>
                  {needsFirstSessionDate && (
                    <ExclamationCircleIcon className="ml-1.5 h-5 w-5 text-orange-100" />
                  )}
                </div>
                {data.isButtonItem && (
                  <Button
                    title={data.content}
                    noBackground
                    noOutline
                    size="small"
                    theme="secondary"
                    className="text-caption font-bold"
                    IconComponent={
                      data.isFirstSessionDateEditable ? Pencil : PlusIcon
                    }
                    iconClassName="text-green-100"
                    onClick={() => setIsFirstAppointmentDateModalOpen?.(true)}
                  />
                )}
                {data.link ? (
                  <PanelButton contentItem={data} />
                ) : (
                  <>
                    {!data.isButtonItem && (
                      <span className="truncate pl-6 font-bold text-green-150">
                        {data.content}
                      </span>
                    )}
                  </>
                )}
                {data.contentList && (
                  <div className="flex flex-col items-end justify-end">
                    {data.contentList.map((contentItem, index) => {
                      if (contentItem.link) {
                        return (
                          <PanelButton key={index} contentItem={contentItem} />
                        );
                      } else {
                        return (
                          <span
                            key={index}
                            className="font-bold text-green-150"
                          >
                            {contentItem.content}
                          </span>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
              {needsFirstSessionDate && (
                <div className="mb-3 w-full rounded-lg bg-orange-25/40 px-3 py-2 text-small-label font-medium">
                  {`Add a first session date to enable all future assessments`}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileInfoSection;
