import reflectiveCategoryImage from '../../../../assets/images/hc-star.png';
import diagnosticCategoryImage from '../../../../assets/images/hc-connect-the-dots-purple.png';
import psychedelicCategoryImage from '../../../../assets/images/hc-face.png';
import { AssessmentCategory } from '../../../../generated/graphql';
import { AssessmentMetaData } from '../../../components/AssessmentMetaData';
import Checkmark from '../../../svgs/Checkmark';

export const CATEGORY_TO_DESIGN_MAP: Record<
  AssessmentCategory,
  { textColor: string; backgroundColor: string; image: string }
> = {
  [AssessmentCategory.Reflective]: {
    textColor: 'text-blue-150',
    backgroundColor: 'bg-blue-25',
    image: reflectiveCategoryImage,
  },
  [AssessmentCategory.Diagnostic]: {
    textColor: 'text-purple-150',
    backgroundColor: 'bg-purple-25',
    image: diagnosticCategoryImage,
  },
  [AssessmentCategory.Psychedelic]: {
    textColor: 'text-orange-150',
    backgroundColor: 'bg-orange-25',
    image: psychedelicCategoryImage,
  },
  [AssessmentCategory.Custom]: {
    textColor: 'text-green-125',
    backgroundColor: 'bg-green-25',
    image: psychedelicCategoryImage,
  },
};

interface AssessmentCardProps {
  category: AssessmentCategory;
  shortName: string;
  name: string;
  description: string;
  estimatedLengthInMinutes?: number;
  hasIncludedBadge?: boolean;
}

const AssessmentCard: React.FC<AssessmentCardProps> = ({
  category,
  shortName,
  name,
  description,
  estimatedLengthInMinutes,
  hasIncludedBadge,
}) => {
  return (
    <div className="relative flex h-[255px] w-[406px] flex-row justify-between rounded-lg p-6 shadow-100 hover:bg-neutral-25">
      {hasIncludedBadge && (
        <div className="absolute top-6 right-6 font-sans text-small-label font-bold text-green-100">
          <Checkmark className="inline h-4 w-4"></Checkmark>
          <span>Included</span>
        </div>
      )}
      <div className="flex w-52 flex-col justify-between">
        <div>
          <div className="mb-1 font-sans text-big-label font-medium text-green-150">
            {shortName}
          </div>
          <div className="mb-2 font-sans text-small-caption font-bold text-green-150">
            {name}
          </div>
          <div className="font-sans text-small-caption text-neutral-125">
            {description}
          </div>
        </div>
        <AssessmentMetaData
          category={category}
          estimatedLengthInMinutes={estimatedLengthInMinutes}
        />
      </div>
      <div className="flex items-center justify-center">
        <img
          className="h-32 w-32 object-contain"
          src={CATEGORY_TO_DESIGN_MAP[category].image}
          alt={category}
        />
      </div>
    </div>
  );
};

export default AssessmentCard;
