import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export interface CustomGraphQLError extends GraphQLError {
  code?: string;
}

export enum HomecomingError {
  ResourceNotFound = 'ResourceNotFound',
  ResourceForbidden = 'ResourceForbidden',
  TokenInvalid = 'TokenInvalid',
}

export const matchHomecomingError = (
  err: ApolloError,
  matchError: HomecomingError,
) => {
  if ((err?.graphQLErrors?.[0] as CustomGraphQLError)?.code === matchError) {
    return err?.graphQLErrors?.[0];
  }
  return null;
};

export const isAbortError = (err: ApolloError) => {
  return err.networkError?.name === 'AbortError';
};
