import classNames from 'classnames';
import { forwardRef } from 'react';

import { SvgIconComponent } from '../types/svgs';

export type ButtonProps = {
  title?: string;
  subtitle?: string;
  theme?: 'primary' | 'secondary';
  IconComponent?: SvgIconComponent;
  iconPosition?: 'right' | 'left' | 'center';
  iconClassName?: string;
  size?: 'medium' | 'small' | 'extra-small';
  noBackground?: boolean;
  noOutline?: boolean;
  // Using the ComponentPropsWithoutRef type allows other components to be composed of a Button component
  // with the ability to pass Button props through without TypeScript complaining about a missing ref.
  // e.g. check out DashedBoxButton.tsx
} & React.ComponentPropsWithoutRef<'button'>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      title,
      subtitle,
      theme = 'primary',
      IconComponent,
      iconPosition = 'right',
      iconClassName,
      size = 'medium',
      className,
      disabled,
      noBackground = false,
      noOutline = false,
      ...rest
    },
    ref,
  ) => {
    const icon = IconComponent && (
      <IconComponent
        className={classNames(
          size === 'medium' && 'h-6 w-6',
          size === 'small' && 'h-4 w-4',
          size === 'extra-small' && 'h-2.5 w-2.5',
          iconPosition === 'left' && 'mr-2',
          iconPosition === 'right' && 'ml-2',
          iconClassName,
        )}
      />
    );

    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          'group flex flex-row items-center justify-center rounded-3xl font-sans font-medium focus:outline-none focus:ring-2 focus:ring-green-50',
          size === 'medium' && 'text-body',
          size === 'small' && 'text-caption',
          size === 'extra-small' && 'text-small-caption font-bold',
          !noOutline && size === 'medium' && 'py-3 px-6',
          !noOutline && size === 'small' && 'py-2 px-4',
          !noOutline && size === 'extra-small' && 'py-1 px-2',
          theme === 'primary' &&
            'text-white disabled:cursor-not-allowed disabled:text-white',
          !noBackground &&
            theme === 'primary' &&
            'bg-neutral-125 hover:bg-neutral-150 disabled:bg-neutral-125/75',
          theme === 'secondary' &&
            'text-neutral-125 disabled:cursor-not-allowed disabled:text-neutral-100',
          !noBackground &&
            theme === 'secondary' &&
            'bg-neutral-50 hover:bg-neutral-100 disabled:bg-neutral-50',
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        {iconPosition === 'left' && icon}
        <div
          className={classNames(
            subtitle && 'flex flex-col items-start justify-center',
          )}
        >
          {title}
          <span
            className={classNames(
              size === 'medium' && 'text-caption',
              size === 'small' && 'text-small-caption',
              size === 'extra-small' && 'text-small-action',
            )}
          >
            {subtitle}
          </span>
        </div>
        {(iconPosition === 'right' || iconPosition === 'center') && icon}
      </button>
    );
  },
);

export default Button;
