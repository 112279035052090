import { SvgIconComponent } from '../types/svgs';

const ThumbnailForm: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#F6D1FF" />
      <path
        d="M21 24H27M21 28H27M29 33H19C18.4696 33 17.9609 32.7893 17.5858 32.4142C17.2107 32.0391 17 31.5304 17 31V17C17 16.4696 17.2107 15.9609 17.5858 15.5858C17.9609 15.2107 18.4696 15 19 15H24.586C24.8512 15.0001 25.1055 15.1055 25.293 15.293L30.707 20.707C30.8946 20.8945 30.9999 21.1488 31 21.414V31C31 31.5304 30.7893 32.0391 30.4142 32.4142C30.0391 32.7893 29.5304 33 29 33Z"
        stroke="#B000D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailForm;
