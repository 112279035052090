import { useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';

import AddRowButton from '../../components/AddRowButton';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import InputLabel from '../../components/InputLabel';
import MemberInputRow from '../../components/MemberInputRow';
import Modal, { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import ToastAlert from '../../components/ToastAlert';
import { useAuth } from '../../../contexts/AuthContext';
import {
  ProviderUserMemberDataFragment,
  useSendProviderUserInvitesMutation,
} from '../../../generated/graphql';
import { pluralize } from '../../lib/copy';
import { removeEmptyFields } from '../../lib/form';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import { AddMembersFormData, MemberField } from '../../types/members';

type AddMembersModalProps = {
  isOpen: boolean;
  existingMembers: ProviderUserMemberDataFragment[];
  setClosed: () => void;
  refetchMembers: () => Promise<unknown>;
};

export default function AddMembersModal({
  isOpen,
  existingMembers,
  setClosed,
  refetchMembers,
}: AddMembersModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { authedProviderUser } = useAuth();

  const [
    sendProviderUserInvitesMutation,
    { error: sendInvitesError, reset: resetProviderUserInvitesMutation },
  ] = useSendProviderUserInvitesMutation();

  const formContext = useForm<AddMembersFormData>({
    mode: 'onSubmit',
    defaultValues: {
      members: [{ email: '' }],
    },
  });
  const {
    watch,
    reset: resetForm,
    control,
    clearErrors: clearFormErrors,
    handleSubmit,
  } = formContext;

  const fieldArrayContext = useFieldArray({
    name: 'members',
    control,
  });
  const { fields, append, remove } = fieldArrayContext;

  const watchedMembers = watch('members');
  // A "controlled" field array means that the fields reflect the up-to-date form data at all times.
  const controlledFields: MemberField[] = fields.map((field, index) => {
    return {
      ...field,
      ...watchedMembers[index],
    };
  });

  const handleAddRowClick = () => {
    const newUsedStaffSeats = existingMembers.length + controlledFields.length;
    const staffSeats = authedProviderUser?.provider.staffSeats;

    if (staffSeats != null && newUsedStaffSeats >= staffSeats) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Can't add more than ${staffSeats} members with your current plan.`}
        />
      ));
      return;
    }

    append({
      email: '',
    });
  };

  const onSubmit: SubmitHandler<AddMembersFormData> = async (formData) => {
    clearFormErrors();

    setIsLoading(true);
    try {
      const providerUserInvites = formData.members.map((member) => ({
        email: member.email,
      }));
      await sendProviderUserInvitesMutation({
        variables: { providerUserInvites },
      });
      await refetchMembers();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully sent ${pluralize(
            providerUserInvites.length,
            'an invite',
            'invites',
            false,
          )} to ${pluralize(
            providerUserInvites.length,
            'new member',
            'new members',
          )}!`}
        />
      ));
      closeAndReset();
    } catch (err) {
      // Already handling errors externally.
    }

    setIsLoading(false);
  };

  const closeAndReset = () => {
    setClosed();
    setTimeout(() => {
      resetForm();
      resetProviderUserInvitesMutation();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      isOpen={isOpen}
      setClosed={closeAndReset}
      fetching={isLoading}
      className="min-h-[80vh]"
    >
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center px-4 text-center">
        <h1 className="font-serif text-subtitle text-green-150">
          Add your team members
        </h1>
        <div className="mt-10 w-full">
          <InputLabel label="EMAIL" inputRequired />
        </div>
        <form className="mb-12 w-full" onSubmit={(e) => e.preventDefault()}>
          {controlledFields.map((field, fieldIndex) => (
            <MemberInputRow
              key={`members.${fieldIndex}`}
              formFields={controlledFields}
              fieldIndex={fieldIndex}
              formContext={formContext}
              fieldArrayContext={fieldArrayContext}
              existingMembers={existingMembers}
            />
          ))}

          <AddRowButton onClick={handleAddRowClick} />
        </form>

        <ErrorMessage className="mb-4">
          {sendInvitesError?.message}
        </ErrorMessage>

        <Button
          title="Add team members"
          IconComponent={ArrowRightLong}
          disabled={isLoading}
          onClick={() => {
            removeEmptyFields(controlledFields, remove);
            handleSubmit(onSubmit)();
          }}
          className="mb-6"
        />
      </div>
    </Modal>
  );
}
