import { FC, Fragment, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import {
  PlusSmIcon,
  DocumentIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';

import Button from '../../../../components/Button';
import MenuButton from '../../../../components/MenuButton';
import { defaultMenuTransitionProps } from '../../../../lib/animation';
import {
  BasicProviderNoteTemplateDataFragment,
  ProviderNoteTemplateDataFragment,
  useProviderNoteTemplateLazyQuery,
  useProviderNoteTemplatesQuery,
  useRemoveProviderNoteTemplateMutation,
} from '../../../../../generated/graphql';
import NoteTemplateModal from './NoteTemplateModal';
import ProviderNoteTemplateItem from './ProviderNoteTemplateItem';
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal';
import toast from 'react-hot-toast';
import ToastAlert from '../../../../components/ToastAlert';
import classNames from 'classnames';
import { MODAL_TRANSITION_DURATION } from '../../../../components/Modal';
import { usePatientProfileContext } from '../..';

type NoteActionsMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createNewProgramNote: (title?: string, note?: string) => Promise<void>;
  authedProviderUserId: string;
  emptyButton?: boolean;
};

export const providerUserOwnsTemplate = (
  providerUserId: string,
  providerNoteTemplate:
    | BasicProviderNoteTemplateDataFragment
    | ProviderNoteTemplateDataFragment,
) => {
  return providerUserId === providerNoteTemplate?.createdByProviderUser?.id;
};

const NoteActionsMenu: FC<NoteActionsMenuProps> = ({
  isOpen,
  setIsOpen,
  createNewProgramNote,
  authedProviderUserId,
  emptyButton = false,
}) => {
  const [isNoteTemplateModalOpen, setIsNoteTemplateModalOpen] = useState(false);
  const [isRemoveNoteTemplateModalOpen, setIsRemoveNoteTemplateModalOpen] =
    useState(false);

  const { selectedNoteTemplate, setSelectedNoteTemplate } =
    usePatientProfileContext();

  const [
    removeProviderNoteTemplateMutation,
    { loading: removeProviderNoteTemplateMutationLoading },
  ] = useRemoveProviderNoteTemplateMutation();

  const {
    data: providerNoteTemplatesData,
    refetch: refetchProviderNoteTemplates,
  } = useProviderNoteTemplatesQuery();

  const providerNoteTemplates =
    providerNoteTemplatesData?.providerNoteTemplates;

  const [providerNoteQuery] = useProviderNoteTemplateLazyQuery();

  const handleNewNoteFromNoteTemplate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    providerNoteTemplate: BasicProviderNoteTemplateDataFragment,
  ) => {
    e.stopPropagation();

    const { data: providerNoteData } = await providerNoteQuery({
      variables: {
        providerNoteTemplateId: providerNoteTemplate.id,
      },
    });

    setIsOpen(false);

    await createNewProgramNote(
      providerNoteTemplate.title,
      providerNoteData.providerNoteTemplate.note,
    );
  };

  const handleViewNoteTemplate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    providerNoteTemplate: BasicProviderNoteTemplateDataFragment,
  ) => {
    e.stopPropagation();

    const { data: providerNoteData } = await providerNoteQuery({
      variables: {
        providerNoteTemplateId: providerNoteTemplate.id,
      },
    });

    setIsOpen(false);
    setSelectedNoteTemplate(providerNoteData.providerNoteTemplate);
    setIsNoteTemplateModalOpen(true);
  };

  const handleRemoveNoteTemplate = async (selectedNoteTemplateId: string) => {
    try {
      const { data } = await removeProviderNoteTemplateMutation({
        variables: {
          providerNoteTemplateId: selectedNoteTemplateId,
        },
      });

      if (data?.removeProviderNoteTemplate) {
        refetchProviderNoteTemplates?.();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully removed note template`}
          />
        ));
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      setSelectedNoteTemplate(undefined);
    }
  };

  const providerUserOwnsSelectedNoteTemplate = providerUserOwnsTemplate(
    authedProviderUserId,
    selectedNoteTemplate,
  );

  return (
    <>
      <div className="flex-start relative z-30 flex">
        <Menu as="div" className="inline-block text-left">
          <Menu.Button as={Fragment}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                isOpen && setSelectedNoteTemplate(undefined);
                setIsOpen(!isOpen);
              }}
              title="Add a note"
              noFocus
              size="small"
              IconComponent={PlusSmIcon}
              iconClassName={classNames(
                'h-[20px] w-[20px]',
                emptyButton && 'text-green-100',
              )}
              {...(emptyButton
                ? { theme: 'secondary', className: 'mt-4 px-6 font-bold' }
                : {})}
            />
          </Menu.Button>
          <Transition
            show={isOpen}
            as={Fragment}
            {...defaultMenuTransitionProps}
          >
            <Menu.Items
              className={classNames(
                'absolute z-30 mt-2 w-64 rounded-lg bg-white shadow-lg focus:outline-none',
                !emptyButton ? 'left-0 origin-top-left' : '-left-12 origin-top',
              )}
            >
              <MenuButton
                titleText="New blank note"
                iconClassName="text-green-150 h-[12px] w-[12px]"
                className="rounded-t-md border-b border-neutral-100"
                activeText="font-medium"
                IconComponent={DocumentIcon}
                onClick={async (e) => {
                  setIsOpen(false);
                  e.stopPropagation();
                  await createNewProgramNote();
                }}
              />

              <MenuButton
                titleText="Create a note template"
                className={classNames(
                  providerNoteTemplates?.length > 0
                    ? 'border-b border-neutral-100'
                    : 'rounded-b-md',
                )}
                activeText="font-medium"
                onClick={(e) => {
                  setIsOpen(false);
                  setSelectedNoteTemplate(undefined);
                  setIsNoteTemplateModalOpen(true);
                  e.stopPropagation();
                }}
                iconClassName="text-green-150 h-[16px] w-[16px]"
                IconComponent={DocumentDuplicateIcon}
              />
              <Menu as={Fragment}>
                <div
                  className="max-h-48 overflow-y-auto"
                  onScroll={() => setSelectedNoteTemplate(undefined)}
                >
                  {providerNoteTemplates?.map((providerNoteTemplate, index) => (
                    <ProviderNoteTemplateItem
                      key={providerNoteTemplate.id}
                      providerNoteTemplate={providerNoteTemplate}
                      handleNewNoteFromNoteTemplate={
                        handleNewNoteFromNoteTemplate
                      }
                      isLastItem={index === providerNoteTemplates.length - 1}
                      isEditDeleteMenuOpen={
                        providerNoteTemplate.id === selectedNoteTemplate?.id
                      }
                      setSelectedNoteTemplate={setSelectedNoteTemplate}
                      handleViewNoteTemplate={handleViewNoteTemplate}
                      handleRemoveNoteTemplate={() =>
                        setIsRemoveNoteTemplateModalOpen(true)
                      }
                      isFirstOrSecondItem={index < 2}
                      sharedWithMe={
                        providerNoteTemplate?.isSharedNoteTemplate &&
                        !providerUserOwnsTemplate(
                          authedProviderUserId,
                          providerNoteTemplate,
                        )
                      }
                      ownedAndSharedFromMe={
                        providerNoteTemplate?.isSharedNoteTemplate &&
                        providerUserOwnsTemplate(
                          authedProviderUserId,
                          providerNoteTemplate,
                        )
                      }
                    />
                  ))}
                </div>
              </Menu>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <NoteTemplateModal
        isModalOpen={isNoteTemplateModalOpen}
        setClosed={() => {
          setIsNoteTemplateModalOpen(false);
          setTimeout(() => {
            setSelectedNoteTemplate(undefined);
          }, MODAL_TRANSITION_DURATION);
        }}
        refetchProviderNoteTemplates={refetchProviderNoteTemplates}
        selectedNoteTemplate={selectedNoteTemplate}
        createNewProgramNote={createNewProgramNote}
        providerOwnsTemplate={providerUserOwnsSelectedNoteTemplate}
        viewOnly={selectedNoteTemplate && !providerUserOwnsSelectedNoteTemplate}
      />
      <ConfirmDeleteModal
        title={`Would you like to ${
          providerUserOwnsSelectedNoteTemplate ? 'delete' : 'remove'
        } this note template?`}
        actionButtonTitle={`${
          providerUserOwnsSelectedNoteTemplate ? 'Delete' : 'Remove'
        } note template`}
        isOpen={isRemoveNoteTemplateModalOpen}
        fetching={removeProviderNoteTemplateMutationLoading}
        setClosed={() => {
          setIsRemoveNoteTemplateModalOpen(false);
          setTimeout(() => {
            setSelectedNoteTemplate(undefined);
          }, MODAL_TRANSITION_DURATION);
        }}
        performDelete={async () =>
          await handleRemoveNoteTemplate(selectedNoteTemplate?.id)
        }
      >
        {selectedNoteTemplate?.isSharedNoteTemplate && (
          <div className="flex flex-row items-center justify-center">
            <>
              {providerUserOwnsSelectedNoteTemplate
                ? 'This note template will be deleted for all team members since you are the template owner.'
                : 'This note template will only be removed from your workspace since you are not the template owner'}
            </>
          </div>
        )}
      </ConfirmDeleteModal>
    </>
  );
};

export default NoteActionsMenu;
