import { FC } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';

const ActivityAttachmentsLabel: FC<{
  activityCount: number;
}> = ({ activityCount }) => {
  return (
    <div className="inline-flex flex-row items-center justify-center gap-x-1 rounded-full bg-green-25 px-2 py-1">
      <PaperClipIcon className="h-4 w-4 shrink-0 text-neutral-150" />
      <div className="text-small-caption font-medium text-neutral-150">
        {activityCount}
      </div>
    </div>
  );
};

export default ActivityAttachmentsLabel;
