// TODO: resolveConfig from OG Tailwind colors in ./
export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  black: '#000000',
  green: {
    150: '#002D0A',
    125: '#004704',
    100: '#00B20A',
    50: '#8ADD8E',
    25: '#E2FBE5',
  },
  blue: {
    150: '#001275',
    125: '#0021DB',
    100: '#2D4BFF',
    50: '#9EADFF',
    25: '#D8DEFF',
  },
  purple: {
    150: '#461452',
    125: '#79198F',
    100: '#B000D9',
    50: '#E98FFF',
    25: '#F6D1FF',
  },
  red: {
    150: '#4D0B00',
    125: '#901500',
    100: '#E23213',
    50: '#F1785F',
    25: '#FAC5BD',
  },
  orange: {
    150: '#553300',
    125: '#804D00',
    100: '#FFA41C',
    50: '#FCCC80',
    25: '#FFDCA6',
  },
  silver: {
    100: '#F2F5F6',
  },
  neutral: {
    200: '#000F0A',
    150: '#232B29',
    125: '#394642',
    110: '#68716E',
    100: '#DBD8D1',
    50: '#F4F3F1',
    25: '#FAF9F8',
    0: '#FFFFFF',
  },
};

type AssessmentsColorType = {
  hover: string;
  outer: string;
  middle: string;
  default: string;
  defaultHex?: string;
};

export const ASSESSMENTS_COLOR_MAP: Record<string, AssessmentsColorType> = {
  neutral: {
    hover: 'hover:bg-neutral-100',
    outer: 'bg-neutral-100/20',
    middle: 'bg-neutral-100/50',
    default: 'bg-neutral-100',
    defaultHex: colors.neutral[100],
  },
  'red-25': {
    hover: 'hover:bg-red-25',
    outer: 'bg-red-25/20',
    middle: 'bg-red-25/50',
    default: 'bg-red-25',
    defaultHex: colors.red[25],
  },
  'red-50': {
    hover: 'hover:bg-red-50',
    outer: 'bg-red-50/20',
    middle: 'bg-red-50/50',
    default: 'bg-red-50',
    defaultHex: colors.red[50],
  },
  'orange-50': {
    hover: 'hover:bg-orange-50',
    outer: 'bg-orange-50/20',
    middle: 'bg-orange-50/50',
    default: 'bg-orange-50',
    defaultHex: colors.orange[50],
  },
  'green-25': {
    hover: 'hover:bg-green-25',
    outer: 'bg-green-25/20',
    middle: 'bg-green-25/50',
    default: 'bg-green-25',
    defaultHex: colors.green[25],
  },
  'green-50': {
    hover: 'hover:bg-green-50',
    outer: 'bg-green-50/20',
    middle: 'bg-green-50/50',
    default: 'bg-green-50',
    defaultHex: colors.green[50],
  },
  'blue-25': {
    hover: 'hover:bg-blue-25',
    outer: 'bg-blue-25/20',
    middle: 'bg-blue-25/50',
    default: 'bg-blue-25',
    defaultHex: colors.blue[25],
  },
  'purple-50': {
    hover: 'hover:bg-purple-50',
    outer: 'bg-purple-50/20',
    middle: 'bg-purple-50/50',
    default: 'bg-purple-50',
    defaultHex: colors.purple[50],
  },
};
