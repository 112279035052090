export const getAudioDurationFromURL = async (url: string): Promise<number> => {
  return new Promise((resolve) => {
    const audio = new Audio();
    audio.src = url;

    audio.addEventListener('loadedmetadata', () => {
      resolve(Math.round(audio.duration));
    });
  });
};

export const getAudioDurationFromBlob = async (blob: Blob): Promise<number> => {
  return new Promise((resolve) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(blob);

    audio.addEventListener('loadedmetadata', () => {
      resolve(Math.round(audio.duration));
    });
  });
};
