export const columnHeaders = [
  {
    field: 'name',
    headerName: 'Program',
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'patients',
    headerName: 'Active clients',
  },
  {
    field: 'duration',
    headerName: 'Length',
  },
];
