import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { CheckIcon } from '@heroicons/react/solid';

import {
  Feature,
  ProgramTemplateTaskGroup,
  Task,
  useRenameTaskGroupMutation,
} from '../../../../../generated/graphql';
import { CustomGraphQLError } from '../../../../../lib/errors';

import { useFeatureFlags } from '../../../../../contexts/FeatureFlagContext';

import Plus from '../../../../svgs/Plus';
import Spinner from '../../../../svgs/Spinner';

import { DnDItemType, DnDTaskItem } from '../../../../types/drag-and-drop';
import { MoveTaskAndSaveCallback } from '../../../../types/tasks';

import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import InputGroup from '../../../../components/InputGroup';
import ToastAlert from '../../../../components/ToastAlert';
import TaskGroupMenu from '../../../../components/TaskGroupMenu';

import TableRow from './TableRow';
import { columnHeaders, getGroupTitleInputId } from './helpers';

type TaskGroupTableRowsProps = {
  groupIndex: number;
  taskGroup: ProgramTemplateTaskGroup;
  programTemplateId: string;
  isDeleteTaskGroupLoading: boolean;
  isSavingTaskMove: boolean;
  openDeleteGroupModal: (taskGroup: ProgramTemplateTaskGroup) => void;
  deleteTaskGroup: (taskGroupId: string) => Promise<unknown>;
  openRemoveTaskModal: (task: Task) => void;
  moveTaskAndSave: MoveTaskAndSaveCallback;
};

export default function TaskGroupTableRows({
  groupIndex,
  taskGroup,
  programTemplateId,
  isDeleteTaskGroupLoading,
  isSavingTaskMove,
  openDeleteGroupModal,
  deleteTaskGroup,
  openRemoveTaskModal,
  moveTaskAndSave,
}: TaskGroupTableRowsProps) {
  const [groupTitle, setGroupTitle] = useState<string>(taskGroup.title ?? '');
  // Used to determine if a change has been made.
  const [previousGroupTitle, setPreviousGroupTitle] =
    useState<string>(groupTitle);
  const [isRenamingGroup, setIsRenamingGroup] = useState(false);
  const [isGroupMenuOpen, setIsGroupMenuOpen] = useState(false);

  const groupTitleInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [{ handlerId, isOverTitleRow }, drop] = useDrop<
    DnDTaskItem,
    void,
    { handlerId: unknown; isOverTitleRow: boolean }
  >({
    accept: DnDItemType.TASK,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOverTitleRow: monitor.isOver(),
      };
    },
    drop(draggedTaskItem) {
      const toGroupId =
        draggedTaskItem.groupId === taskGroup.id ? undefined : taskGroup.id;

      if (toGroupId || draggedTaskItem.index !== 0) {
        const fromIndex = draggedTaskItem.index;
        const toIndex = 0;
        moveTaskAndSave(fromIndex, toIndex, draggedTaskItem.groupId, toGroupId);

        draggedTaskItem.index = 0;
      }

      // The dropped item loses focus when dropped in a new position.
      // Focus the dropped item after the next tick, so there's time for it to render in the new position.
      setTimeout(
        () => document.getElementById(draggedTaskItem.idAttribute)?.focus(),
        0,
      );
    },
  });

  const { isFeatureEnabled } = useFeatureFlags();
  const isCreateEditingProgramTaskEnabled = isFeatureEnabled(
    Feature.ArticleCreationEditing,
  );

  const [
    renameTaskGroupMutation,
    { loading: isRenameTaskGroupLoading, error: renameTaskGroupError },
  ] = useRenameTaskGroupMutation();

  const onBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    event.returnValue = true;
    return true;
  }, []);

  useEffect(() => {
    // Block leaving the page with a browser "unsaved changes" alert if actively editing.
    // TODO: block react-router navigations too. react-router v6 does not currently support this, except via hackiness:
    // https://stackoverflow.com/questions/37145404/how-to-prevent-route-change-using-react-router
    if (isRenamingGroup) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isRenamingGroup]);

  const goToArticle = (task: Task | null | undefined) => {
    if (!task) return;
    navigate(`/programs/${programTemplateId}/task/${task.id}`);
  };

  const addArticle = () => {
    navigate(
      `/programs/${programTemplateId}/task/new?group-id=${
        taskGroup.id
        // Place the new article at the bottom of its group.
      }&weight-in-group=${taskGroup.tasks?.length ?? 0}`,
    );
  };

  const saveGroupTitle = async () => {
    // Trim whitespace on submit so editing is not interfered with.
    const trimmedGroupTitle = groupTitle.trim();
    setGroupTitle(trimmedGroupTitle);

    if (trimmedGroupTitle === previousGroupTitle && !renameTaskGroupError) {
      // Skip save.
      setIsRenamingGroup(false);
      return;
    }

    try {
      await renameTaskGroupMutation({
        variables: { taskGroupId: taskGroup.id, title: trimmedGroupTitle },
      });

      setIsRenamingGroup(false);

      toast.custom(({ visible }) => (
        <ToastAlert isVisible={visible} message="Successfully saved!" />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={(err as CustomGraphQLError).message}
        />
      ));
    }
  };

  const handleRenameClick = () => {
    // Wait for the next tick before focusing the input so the focus is not trapped within the menu.
    // This is a known issue: https://github.com/radix-ui/primitives/issues/622
    setTimeout(() => groupTitleInputRef.current?.focus(), 0);
  };

  const handleDeleteClick = async (event: Event) => {
    // Don't close dropdown menu until delete is complete.
    event.preventDefault();

    if (taskGroup.tasks && taskGroup.tasks.length > 0) {
      // Show a confirmation modal before deleting (if there are articles within the group)
      setIsGroupMenuOpen(false);
      // Allow the menu to return focus to the menu trigger before opening the modal, so focus goes to the modal.
      // TODO: Figure out a less hacky fix.
      // This timeout causes a flickering issue where the task row below the menu is briefly hovered before the modal is opened.
      setTimeout(() => openDeleteGroupModal(taskGroup), 130);
      return;
    }

    await deleteTaskGroup(taskGroup.id);
    setIsGroupMenuOpen(false);
  };

  return (
    <>
      <tr className="border-b-2 border-neutral-50 font-sans text-body font-medium text-neutral-125">
        <td
          ref={drop}
          colSpan={columnHeaders.length}
          className={classNames(
            'p-0 shadow-green-50 transition-shadow',
            groupIndex === 0 ? 'pt-7' : 'pt-12',
            isOverTitleRow && 'shadow-bottom-line',
          )}
          data-handler-id={handlerId}
        >
          <div className="flex items-center">
            <form
              className="flex-1"
              onSubmit={(event) => {
                event.preventDefault();
                // Will be saved on blur.
                groupTitleInputRef.current?.blur();
              }}
            >
              <InputGroup
                ref={groupTitleInputRef}
                id={getGroupTitleInputId(taskGroup.id)}
                className={classNames(
                  isRenamingGroup && !renameTaskGroupError && 'border-green-50',
                  renameTaskGroupError && 'border-red-50',
                )}
                label="Group title"
                type="text"
                required
                labelHidden
                backgroundHidden
                inputSize="extra-small"
                value={groupTitle}
                onChange={(event) => setGroupTitle(event.target.value)}
                onFocus={() => {
                  setPreviousGroupTitle(groupTitle);
                  setIsRenamingGroup(true);
                }}
                // Save on blur, then have other saving triggers (like pressing Enter or the Confirm button) cause a blur in order to save.
                // This way there's no duplicate saving.
                onBlur={saveGroupTitle}
                errorMessage={renameTaskGroupError?.message}
                errorLocation="none"
              />
            </form>

            {isRenamingGroup ? (
              <IconButton
                IconComponent={isRenameTaskGroupLoading ? Spinner : CheckIcon}
                aria-label="Confirm group name"
                iconClassName="h-4 w-4"
                className="ml-4"
                onClick={() => {
                  // Will be saved on blur.
                  groupTitleInputRef.current?.blur();
                }}
              />
            ) : (
              <TaskGroupMenu
                isOpen={isGroupMenuOpen}
                isDeleteLoading={isDeleteTaskGroupLoading}
                setIsOpen={setIsGroupMenuOpen}
                onRenameClick={handleRenameClick}
                onDeleteClick={handleDeleteClick}
              />
            )}
          </div>
        </td>
      </tr>

      {taskGroup.tasks?.map((task, idx) => {
        return (
          <TableRow
            key={`tableRow_${taskGroup.id + idx}`}
            task={task}
            taskGroup={taskGroup}
            taskIndex={idx}
            isSavingTaskMove={isSavingTaskMove}
            onClick={() => goToArticle(task)}
            onRemoveClick={() => openRemoveTaskModal(task)}
            moveTaskAndSave={moveTaskAndSave}
          />
        );
      })}

      {isCreateEditingProgramTaskEnabled && (
        <tr>
          <td colSpan={columnHeaders.length}>
            <Button
              title="Add an article"
              IconComponent={Plus}
              iconPosition="left"
              theme="secondary"
              noBackground
              noOutline
              className="mt-4 -ml-2"
              onClick={addArticle}
            />
          </td>
        </tr>
      )}
    </>
  );
}
