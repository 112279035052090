import classNames from 'classnames';
import { FC } from 'react';

const SlideoverSection: FC<{
  children?: React.ReactNode;
  title?: string | undefined;
  textContent?: string | undefined;
  className?: string;
  titleClassName?: string;
}> = ({
  children = <></>,
  title,
  textContent = '',
  className,
  titleClassName,
}) => {
  return (
    <div
      className={classNames(
        'mb-4 flex flex-col items-start justify-start rounded-xl bg-white p-6 pt-4',
        className,
      )}
    >
      {title && (
        <div
          className={classNames(
            'mb-4 text-body font-bold text-green-150',
            titleClassName,
          )}
        >
          {title}
        </div>
      )}
      {textContent && (
        <span className="text-small-caption text-neutral-125">
          {textContent}
        </span>
      )}
      {children}
    </div>
  );
};

export default SlideoverSection;
