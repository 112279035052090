import { SvgIconComponent } from '../types/svgs';

const Search: SvgIconComponent = ({ className }) => {
  return (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM12.9056 14.3199C11.551 15.3729 9.84871 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.84871 15.3729 11.551 14.3199 12.9056L17.6569 16.2426L16.2426 17.6569L12.9056 14.3199Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Search;
