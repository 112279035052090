import classNames from 'classnames';

type LibraryTableContainerProps = {
  children?: React.ReactNode;
  containerClassName?: string;
};

export default function LibraryTableContainer({
  children,
  containerClassName,
}: LibraryTableContainerProps) {
  return (
    <div
      className={classNames(
        // Note: 264px has been calibrated to just account for the height of preceding elements,
        // such that the table height doesn't overflow and cause another scroll outside of the inner
        // table scroll. We need to figure out a better way for these heights to be dynamic.
        'flex h-[calc(100vh-264px)] w-full flex-row items-start overflow-y-auto border-t border-neutral-75',
        containerClassName,
      )}
    >
      {children}
    </div>
  );
}
