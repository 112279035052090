import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, useEffect, useRef } from 'react';
import Spinner from '../svgs/Spinner';
import Button, { ButtonProps } from './Button';
import {
  AnalyticsComponent,
  AnalyticsPage,
  trackProviderComponentViewedEvent,
} from '../../lib/analytics';
import IconButton from './IconButton';
import ModalCloseX from '../svgs/ModalCloseX';
import useOnKeyPress from '../hooks/useOnKeyPress';

export type ModalDialogProps = {
  title?: string; // Used for analytics tracking
  originPage?: AnalyticsPage | string; // Used for analytics tracking
  children: React.ReactNode;
  isOpen: boolean;
  setClosed: () => void;
  fetching?: boolean;
  width?: 'xs' | 'small' | 'medium' | 'large';
  overflow?: 'hidden' | 'auto';
  className?: string;
  leftFooter?: React.ReactNode;
  rightFooter?: React.ReactNode;
  hideFooter?: boolean;
  primaryActionTitle?: string;
  primaryActionOnClick?: () => void;
  primaryActionDisabled?: boolean;
  primaryActionProps?: ButtonProps;
  primaryActionOnEnter?: boolean;
  bodyClassName?: string;
  isBlocking?: boolean;
};

const ModalDialog: FC<ModalDialogProps> = ({
  title,
  originPage,
  children,
  isOpen,
  setClosed,
  fetching = false,
  width = 'large',
  overflow = 'hidden',
  className,
  leftFooter,
  rightFooter,
  hideFooter = false,
  primaryActionTitle,
  primaryActionOnClick,
  primaryActionDisabled = false,
  primaryActionProps,
  primaryActionOnEnter = false,
  bodyClassName,
  isBlocking = false,
}) => {
  // ModalDialog analytics tracking. Needs 'name' prop to be passed in.
  useEffect(() => {
    if (title && isOpen) {
      trackProviderComponentViewedEvent(AnalyticsComponent.Modal, originPage, {
        modalName: title,
      });
    }
  }, [isOpen]);

  /* https://headlessui.dev/react/dialog#managing-focus-within-your-dialog */
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const modalContentRef = useRef(null);

  useOnKeyPress(
    'Enter',
    () => {
      if (primaryActionOnClick && primaryActionOnEnter) {
        primaryActionOnClick();
      }
    },
    modalContentRef,
    isOpen,
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={closeButtonRef}
        className="fixed inset-0 z-[51] overflow-y-auto" // z-index +1 above Slideover
        onClose={isBlocking ? () => void 0 : setClosed}
      >
        <div className="flex min-h-screen items-end justify-center pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-125 bg-opacity-50 backdrop-blur-[4px] transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modaldialog contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              ref={modalContentRef}
              className={classNames(
                'relative inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:max-w-[1100px] sm:align-middle',
                {
                  'w-[90%]': width === 'large',
                  'w-[800px]': width === 'medium',
                  'w-[600px]': width === 'small',
                  'w-[435px]': width === 'xs',
                  'overflow-hidden': overflow === 'hidden',
                },
                className,
              )}
            >
              {/* Header */}
              <div className="flex w-full flex-row items-center justify-between border-b border-b-neutral-75 py-3 px-6">
                <div className="font-medium text-green-150">{title}</div>
                <IconButton
                  aria-label="Close"
                  IconComponent={ModalCloseX}
                  iconClassName="h-6 w-6"
                  onClick={setClosed}
                />
              </div>

              {/* Body */}
              <div className={classNames('p-6', bodyClassName)}>{children}</div>

              {/* Footer */}
              {!hideFooter && (
                <div
                  className={classNames(
                    'flex w-full flex-row items-center border-t border-t-neutral-75 py-5 px-6',
                    leftFooter ? 'justify-between' : 'justify-end',
                  )}
                >
                  {leftFooter && leftFooter}
                  <div>
                    {primaryActionTitle && (
                      <Button
                        onClick={primaryActionOnClick}
                        title={primaryActionTitle}
                        disabled={primaryActionDisabled}
                        {...primaryActionProps}
                      />
                    )}
                    {rightFooter && rightFooter}
                  </div>
                </div>
              )}

              {/* Loading spinner */}
              <Transition
                show={fetching}
                as={Fragment}
                enter="transform ease-out duration-100 transition"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform ease-in duration-100 transition"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center bg-neutral-25/80">
                  <Spinner className="h-6 w-6 text-green-150" />
                </div>
              </Transition>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalDialog;
