import React, { ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core/lib/enums';
import { Portal } from '@headlessui/react';

const Tooltip: React.FC<{
  content: ReactNode;
  children: ReactNode;
  enabled?: boolean;
  placement?: Placement;
  showArrow?: boolean;
  className?: string;
}> = ({
  content,
  children,
  enabled = true,
  placement = 'bottom-start',
  showArrow = false,
  className,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const arrowElement = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { styles, attributes, state } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
    },
  );

  // Get the actual placement from Popper's state, which can dynamically change based on the viewport
  const actualPlacement = state?.placement || placement;

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div ref={setReferenceElement}>{children}</div>
      {enabled && isVisible && (
        <Portal>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={classNames(
              'relative z-50 w-64 rounded-lg bg-white p-5 text-caption font-normal text-neutral-125 shadow-400',
              className,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {content}
            {showArrow && (
              <div
                data-popper-arrow
                ref={arrowElement}
                className={classNames(
                  'absolute h-4 w-4 rotate-45 transform bg-white',
                  {
                    'top-0 left-1/2 -translate-x-1/2 -translate-y-1/2':
                      actualPlacement.startsWith('bottom'),
                    'bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2':
                      actualPlacement.startsWith('top'),
                    'right-0 top-1/2 translate-x-1/2 -translate-y-1/2':
                      actualPlacement.startsWith('left'),
                    'left-0 top-1/2 -translate-x-1/2 -translate-y-1/2':
                      actualPlacement.startsWith('right'),
                  },
                )}
              />
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Tooltip;
