import { FC } from 'react';

import ProviderProgramTagManager from '../ProgramTag/ProviderProgramTagManager';
import ModalDialog from '../ModalDialog';
import { ProgramTagWithCountDataFragment } from '../../../generated/graphql';

interface TagManagerModalProps {
  isModalOpen: boolean;
  setClosed: () => void;
  initialProviderProgramTags: ProgramTagWithCountDataFragment[];
  onClose: () => Promise<void>;
}

const TagManagerModal: FC<TagManagerModalProps> = ({
  isModalOpen,
  setClosed,
  initialProviderProgramTags,
  onClose,
}) => {
  const closeAndResetModal = () => {
    setClosed();
    onClose();
  };

  return (
    <ModalDialog
      title="Manage Tags"
      isOpen={isModalOpen}
      setClosed={closeAndResetModal}
      hideFooter
    >
      <ProviderProgramTagManager
        initialProviderProgramTags={initialProviderProgramTags}
        doneButtonOnClick={closeAndResetModal}
        className="px-10"
      />
    </ModalDialog>
  );
};

export default TagManagerModal;
