import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useBillingInfoQuery,
  useStripeCustomerPortalLazyQuery,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';

import Spinner from '../../svgs/Spinner';
import classNames from 'classnames';

import BillingCheckout from './BillingCheckout';
import BillingSubscribed from './BillingSubscribed';
import { Transition } from '@headlessui/react';
import { defaultEnterTransitionProps } from '../../lib/animation';

const BillingSection: FC = () => {
  const { authedProviderUser, isAuthedProviderUserLoading } = useAuth();
  const { pathname } = useLocation();

  const isSubscribed = authedProviderUser?.isProviderSubscribed ?? false;

  const { data: billingInfoData, loading: isBillingInfoLoading } =
    useBillingInfoQuery();

  const [getStripeCustomerPortal, { data: stripeCustomerPortalData }] =
    useStripeCustomerPortalLazyQuery();

  const billingInfo = billingInfoData?.meProvider?.billingInfo;
  const provider = billingInfoData?.meProvider?.provider;

  const isDataReady = !isAuthedProviderUserLoading && !isBillingInfoLoading;

  if (stripeCustomerPortalData?.stripeCustomerPortal.url) {
    window.location.href = stripeCustomerPortalData.stripeCustomerPortal.url;
  }

  const linkToCustomerPortal = () => {
    getStripeCustomerPortal();
  };

  return (
    <div className={classNames(pathname === '/billing' && 'py-11')}>
      <Transition
        show={isDataReady}
        className="w-full"
        {...defaultEnterTransitionProps}
      >
        {isSubscribed ? (
          <>
            {provider && billingInfo && (
              <BillingSubscribed
                linkToCustomerPortal={linkToCustomerPortal}
                billingInfo={billingInfo}
                subscriptionTier={provider.subscriptionTier}
                clientSeats={provider.clientSeats}
                usedClientSeats={provider.usedClientSeats}
              />
            )}
          </>
        ) : (
          <>
            {provider && (
              <BillingCheckout
                isOnboarding={false}
                paymentPlan={provider.paymentPlan}
                subscriptionTier={provider.subscriptionTier}
                clientSeats={provider.clientSeats}
                staffSeats={provider.staffSeats}
              />
            )}
          </>
        )}
      </Transition>

      {!isDataReady && <Spinner className="mx-auto mt-4" />}
    </div>
  );
};

export default BillingSection;
