import { FC, useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

import Spinner from '../svgs/Spinner';
import { SvgIconComponent } from '../types/svgs';
import { ActivityOrResourceOptions, ContentType } from '../lib/followUp';

import ThumbnailLink from '../svgs/ThumbnailLink';
import ThumbnailImage from '../svgs/ThumbnailImage';
import ThumbnailPDF from '../svgs/ThumbnailPDF';
import ThumbnailPdfForm from '../svgs/ThumbnailPdfForm';
import ThumbnailForm from '../svgs/ThumbnailForm';
import ThumbnailAction from '../svgs/ThumbnailAction';
import ThumbnailAudio from '../svgs/ThumbnailAudio';
import ThumbnailAssessment from '../svgs/ThumbnailAssessment';

import IconButton from './IconButton';
import ThumbnailText from '../svgs/ThumbnailText';
import ThumbnailPDFESign from '../svgs/ThumbnailPDFESign';

const DivOrButton: FC<{
  children?: React.ReactNode;
  className?: string;
  disableClick?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ children, className, disableClick, onClick }) => {
  if (onClick) {
    return (
      <button
        className={className}
        {...(!disableClick && {
          onClick: onClick,
        })}
        disabled={!onClick || disableClick}
      >
        {children}
      </button>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

const ContentPreview: FC<{
  contentType: ContentType;
  activityOrResourceOption?: ActivityOrResourceOptions;
  title: string;
  description?: string;
  previewThumbnailImageUrl?: string;
  className?: string;
  disableClick?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClickCloseX?: () => void;
  hasLoader?: boolean;
  toggleActivityOrResourceLabel?: boolean;
}> = ({
  contentType,
  activityOrResourceOption = ActivityOrResourceOptions.Activity,
  title,
  description,
  className,
  previewThumbnailImageUrl,
  disableClick = false,
  onClick,
  onClickCloseX,
  hasLoader = false,
  toggleActivityOrResourceLabel = false,
}) => {
  const [showXIcon, setShowXIcon] = useState(false);

  return (
    <DivOrButton
      className={classNames(
        'relative w-full rounded-xl shadow-100',
        'focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
        !disableClick && 'hover:bg-neutral-100/10',
        !activityOrResourceOption && 'p-4',
        activityOrResourceOption && 'p-0',
        className,
      )}
      {...(!disableClick && {
        onClick: onClick,
      })}
      disableClick={disableClick}
    >
      {onClickCloseX && (
        <div
          onMouseOver={() => {
            hasLoader && setShowXIcon(true);
          }}
          onMouseLeave={() => {
            hasLoader && setShowXIcon(false);
          }}
          className="absolute top-0 right-0 z-10 p-2"
        >
          <IconButton
            aria-label="Remove"
            IconComponent={hasLoader && !showXIcon ? Spinner : XIcon}
            className="hover:bg-neutral-50"
            iconClassName="h-3.5 w-3.5 self-start"
            onClick={(e) => {
              // Necessary otherwise close click bubbles up to fire main button onClick.
              e.stopPropagation();
              onClickCloseX();
            }}
          />
        </div>
      )}
      <div className="flex flex-row items-center justify-start">
        {(contentType === ContentType.Link ||
          contentType === ContentType.Image) && (
          <>
            {previewThumbnailImageUrl ? (
              <img
                src={previewThumbnailImageUrl}
                alt="link preview thumbnail"
                className={classNames(
                  'mr-4 overflow-hidden rounded-md object-cover',
                  activityOrResourceOption &&
                    'max-h-[60px] min-h-[60px] min-w-[60px] max-w-[60px]',
                  !activityOrResourceOption &&
                    'max-h-[48px] min-h-[48px] min-w-[48px] max-w-[48px]',
                )}
              />
            ) : (
              <Thumbnail
                ThumbnailImage={
                  contentType === ContentType.Link
                    ? ThumbnailLink
                    : ThumbnailImage
                }
                activityOrResourceOption={activityOrResourceOption}
              />
            )}
          </>
        )}
        {contentType === ContentType.PDF && (
          <Thumbnail
            ThumbnailImage={ThumbnailPDF}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.PdfForm && (
          <Thumbnail
            ThumbnailImage={ThumbnailPdfForm}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Esign && (
          <Thumbnail
            ThumbnailImage={ThumbnailPDFESign}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Audio && (
          <Thumbnail
            ThumbnailImage={ThumbnailAudio}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Assessment && (
          <Thumbnail
            ThumbnailImage={ThumbnailAssessment}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Form && (
          <Thumbnail
            ThumbnailImage={ThumbnailForm}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Action && (
          <Thumbnail
            ThumbnailImage={ThumbnailAction}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        {contentType === ContentType.Text && (
          <Thumbnail
            ThumbnailImage={ThumbnailText}
            activityOrResourceOption={activityOrResourceOption}
          />
        )}
        <div
          className={classNames(
            'flex w-[calc(100%-76px)] flex-col items-start justify-center',
          )}
        >
          {activityOrResourceOption && toggleActivityOrResourceLabel && (
            <div className="flex w-full flex-row items-center justify-between">
              <div
                className={classNames(
                  'text-left text-small-label font-bold',
                  activityOrResourceOption ===
                    ActivityOrResourceOptions.Activity && 'text-green-100',
                  activityOrResourceOption ===
                    ActivityOrResourceOptions.Resource && 'text-neutral-125',
                )}
              >
                {activityOrResourceOption.toUpperCase()}
              </div>
            </div>
          )}
          <div
            className={classNames(
              'w-full text-left text-caption font-bold text-green-150',
              description ? 'truncate' : 'line-clamp-2',
              activityOrResourceOption && 'mt-1 mb-0.5',
            )}
          >
            {title}
          </div>
          {description && (
            <div className="w-[96%] truncate text-left text-small-caption text-neutral-110">
              {description}
            </div>
          )}
        </div>
      </div>
    </DivOrButton>
  );
};

export default ContentPreview;

const Thumbnail: FC<{
  ThumbnailImage: SvgIconComponent;
  activityOrResourceOption?: ActivityOrResourceOptions;
}> = ({ ThumbnailImage, activityOrResourceOption }) => (
  <ThumbnailImage
    className={classNames(
      'mr-4 rounded-md',
      activityOrResourceOption &&
        'max-h-[60px] min-h-[60px] min-w-[60px] max-w-[60px]',
      !activityOrResourceOption &&
        'max-h-[48px] min-h-[48px] min-w-[48px] max-w-[48px]',
    )}
  />
);
