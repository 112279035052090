import React, { FC, useRef } from 'react';
import {
  MediaCategory,
  useCreateMediaFileMutation,
} from '../../../generated/graphql';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';
import IconButton from '../IconButton';
import { PhotographIcon } from '@heroicons/react/outline';

export type MediaInfo = { url: string; id: string };

const ImageUploadButton: FC<{ onSuccess: (info: MediaInfo) => void }> = ({
  onSuccess,
}) => {
  const [createMediaFile, { loading }] = useCreateMediaFileMutation();

  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];

    if (image) {
      try {
        const data = await createMediaFile({
          variables: {
            input: {
              file: image,
              category: MediaCategory.ModuleImage,
            },
          },
        });

        onSuccess(data.data?.createMediaFile);
      } catch (err) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Something went wrong."
            level="error"
          />
        ));
      }
    }
  };

  return (
    <>
      <IconButton
        aria-label="Upload image"
        IconComponent={PhotographIcon}
        className="h-10 w-10 text-neutral-125"
        disabled={loading}
        onClick={() => {
          fileInputRef.current!.click();
        }}
      />
      {!loading && (
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={onFileUpload}
        />
      )}
    </>
  );
};

export default ImageUploadButton;
