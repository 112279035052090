import { forwardRef } from 'react';

import { PlusSmIcon } from '@heroicons/react/outline';
import Button from './Button';

type AddRowButtonProps = {
  className?: string;
  title?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const AddRowButton = forwardRef<HTMLButtonElement, AddRowButtonProps>(
  ({ title = 'Add row', ...rest }, ref) => (
    <Button
      ref={ref}
      title={title}
      IconComponent={PlusSmIcon}
      iconPosition="left"
      theme="secondary"
      noBackground
      noOutline
      {...rest}
    />
  ),
);

export default AddRowButton;
