import { FC } from 'react';

import ClearFiltersButton from './ClearFiltersButton';
import classNames from 'classnames';
import { SvgIconComponent } from '../types/svgs';

const EmptyTable: FC<{
  titleText: string;
  subTitleText: string;
  IconComponent: SvgIconComponent;
  iconClassName: string;
  onClickClearFilters?: () => void;
  className?: string;
}> = ({
  titleText,
  subTitleText,
  IconComponent,
  iconClassName,
  onClickClearFilters,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center',
        className,
      )}
    >
      <IconComponent className={classNames('mb-4 h-20', iconClassName)} />
      <div className="mb-4 font-serif text-subtitle-small text-green-150">
        {titleText}
      </div>
      <div className="text-caption text-neutral-125">{subTitleText}</div>
      <div className="mt-6">
        <ClearFiltersButton
          hasFilters={Boolean(onClickClearFilters)}
          clearFilters={onClickClearFilters}
        />
      </div>
    </div>
  );
};

export default EmptyTable;
