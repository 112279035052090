import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import {
  useCalendlyEventTypesQuery,
  useScheduledEventLazyQuery,
  CalendarScheduledEventFragment,
  useCalendarScheduledEventsQuery,
  useResendPatientInvitesMutation,
  useUnlinkInviteeEmailFromProgramMutation,
} from '../../../../generated/graphql';

import useIncrementNowDate from '../../../hooks/useIncrementNowDate';

import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';
import ConfirmCancelModal from '../../../components/Modals/ConfirmCancelModal';

import DayLabel from './DayLabel';
import TimeLabel from './TimeLabel';
import TodayTimeBar from './TodayTimeBar';
import ScheduledEventItem from './ScheduledEventItem';
import CalendarSettingsMenu from './CalendarSettingsMenu';

import {
  hours,
  getCalendarTitle,
  getDaysFromAnchorDate,
  setTodayScrollPosition,
} from './helpers';
import { useAuth } from '../../../../contexts/AuthContext';
import { getFirstInviteeData } from '../../../lib/scheduled-events';
import InviteCalendlyInviteeToHomecomingModal from './InviteCalendlyInviteeToHomecomingModal';
import SyncCalendlyInviteeModal from './SyncCalendlyInviteeModal';

const Calendar: React.FC = () => {
  const nowDate = useIncrementNowDate();
  const todayTimeBarRef = useRef<HTMLLIElement>(null);

  const [selectedScheduledEvent, setSelectedScheduledEvent] = useState<
    CalendarScheduledEventFragment | undefined
  >();

  const selectedInvitee = getFirstInviteeData(selectedScheduledEvent);

  const [
    getScheduledEvent,
    { loading: isLoadingScheduledEvent, data: scheduledEventData },
  ] = useScheduledEventLazyQuery();

  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [
    isInviteCalendlyInviteeToHomecomingModalOpen,
    setIsInviteCalendlyInviteeToHomecomingModalOpen,
  ] = useState(false);
  const [isSyncCalendlyInviteeModalOpen, setIsSyncCalendlyInviteeModalOpen] =
    useState(false);

  const [anchorDate, setAnchorDate] = useState(
    nowDate.startOf('week').startOf('day'),
  );

  const oneWeekFromAnchorDate = anchorDate.plus({ weeks: 1 });
  const { weekDays, columnClassIndex } = getDaysFromAnchorDate(
    anchorDate,
    nowDate,
  );
  const showTodayTimeBar = weekDays.find((day) => day.isToday);

  const { authedProviderUser } = useAuth();

  useEffect(() => {
    setTodayScrollPosition(todayTimeBarRef);
  }, [todayTimeBarRef]);

  const [resendPatientInvitesMutation] = useResendPatientInvitesMutation();
  const { data: calendlyEventTypesData } = useCalendlyEventTypesQuery();

  const calendlyEventTypes = calendlyEventTypesData?.calendlyEventTypes;

  const { data: scheduledEventsData, refetch: refetchScheduledEventsData } =
    useCalendarScheduledEventsQuery({
      variables: {
        input: {
          minStartTime: anchorDate,
          maxStartTime: oneWeekFromAnchorDate,
          isCanceled: false,
        },
      },
    });

  const scheduledEvents = scheduledEventsData?.scheduledEvents;

  const lookBackUntil = DateTime.fromISO(
    authedProviderUser!.provider.calendlyIntegration!.lookBackUntil,
  );

  const [unlinkInviteeEmailToProgramMutation] =
    useUnlinkInviteeEmailFromProgramMutation();

  return (
    <>
      <div
        onClick={() => setSelectedScheduledEvent(undefined)}
        className="flex h-full flex-col"
      >
        <header className="flex flex-none items-center justify-between border-b border-neutral-75 px-0 pb-4">
          <div className="flex flex-row items-center justify-start">
            <Button
              title="Today"
              theme="secondary"
              className="mr-4"
              size="small"
              onClick={() => {
                setAnchorDate(nowDate.startOf('week').startOf('day'));
                setTimeout(() => {
                  setTodayScrollPosition(todayTimeBarRef);
                }, 50);
              }}
            />
            <IconButton
              aria-label="Previous week"
              IconComponent={ChevronLeftIcon}
              iconClassName="h-6 w-6 text-neutral-125"
              onClick={() => {
                setAnchorDate(anchorDate.minus({ weeks: 1 }));
              }}
              // Enforce cutoff date (meant to put reasonable bounds around importing events)
              disabled={anchorDate.minus({ weeks: 1 }) < lookBackUntil}
            />
            <IconButton
              aria-label="Next week"
              IconComponent={ChevronRightIcon}
              iconClassName="h-6 w-6 text-neutral-125"
              onClick={() => {
                setAnchorDate(oneWeekFromAnchorDate);
              }}
            />
            <h1 className="ml-4 font-sans text-subtitle-small text-green-150">
              {getCalendarTitle(anchorDate, oneWeekFromAnchorDate)}
            </h1>
          </div>
          <CalendarSettingsMenu />
        </header>
        <div
          className={classNames(
            'isolate flex flex-auto flex-col bg-white',
            !selectedScheduledEvent ? 'overflow-auto' : 'overflow-hidden',
          )}
        >
          <div className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
            <div className="sticky top-0 z-50 flex-none bg-white shadow-100 ring-1 ring-black ring-opacity-5 sm:pr-8">
              <div className="text-sm -mr-px hidden grid-cols-7 divide-x divide-neutral-75 border-r border-neutral-75 leading-6 text-neutral-125 sm:grid">
                <div className="col-end-1 w-14" />
                {weekDays.map((weekDayProps, index) => (
                  <DayLabel {...weekDayProps} key={`${index}-dayLabel`} />
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-neutral-75" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-neutral-75"
                  style={{ gridTemplateRows: 'repeat(48, minmax(2rem, 1fr))' }}
                >
                  <div className="row-end-1 h-7"></div>
                  {hours.map((hour, index) => (
                    <TimeLabel hour={hour} isAM key={`${index}-timeLabelAM`} />
                  ))}
                  {hours.map((hour, index) => (
                    <TimeLabel hour={hour} isPM key={`${index}-timeLabelPM`} />
                  ))}
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-neutral-75 sm:grid sm:grid-cols-7">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8" />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                  style={{
                    gridTemplateRows:
                      '1.75rem repeat(288, minmax(0, 1fr)) auto',
                  }}
                >
                  {showTodayTimeBar && (
                    <TodayTimeBar
                      todayTimeBarRef={todayTimeBarRef}
                      columnClassIndex={columnClassIndex}
                      nowDate={nowDate}
                    />
                  )}
                  {scheduledEvents?.map((scheduledEvent, index) => (
                    <ScheduledEventItem
                      key={`${index}-scheduledEvent`}
                      columnClassIndex={columnClassIndex}
                      scheduledEvent={scheduledEvent}
                      selectedScheduledEvent={selectedScheduledEvent}
                      setSelectedScheduledEvent={setSelectedScheduledEvent}
                      getScheduledEvent={getScheduledEvent}
                      isLoadingScheduledEvent={isLoadingScheduledEvent}
                      scheduledEventData={scheduledEventData}
                      setConfirmCancelModalOpen={setConfirmCancelModalOpen}
                      setIsInviteCalendlyInviteeToHomecomingModalOpen={
                        setIsInviteCalendlyInviteeToHomecomingModalOpen
                      }
                      setIsSyncCalendlyInviteeModalOpen={
                        setIsSyncCalendlyInviteeModalOpen
                      }
                      calendlyEventTypes={calendlyEventTypes}
                      refetchScheduledEventsData={refetchScheduledEventsData}
                      resendPatientInvitesMutation={
                        resendPatientInvitesMutation
                      }
                      unlinkInviteeEmailToProgramMutation={
                        unlinkInviteeEmailToProgramMutation
                      }
                    />
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteCalendlyInviteeToHomecomingModal
        isModalOpen={isInviteCalendlyInviteeToHomecomingModalOpen}
        setClosed={() => setIsInviteCalendlyInviteeToHomecomingModalOpen(false)}
        refetchScheduledEventsData={refetchScheduledEventsData}
        selectedInviteeData={{
          name: selectedInvitee.fullName!,
          email: selectedInvitee.email!,
          phoneNumber: '',
        }}
      />
      <SyncCalendlyInviteeModal
        isModalOpen={isSyncCalendlyInviteeModalOpen}
        openInviteCalendlyInviteeToHomecomingModal={() =>
          setIsInviteCalendlyInviteeToHomecomingModalOpen(true)
        }
        setClosed={() => setIsSyncCalendlyInviteeModalOpen(false)}
        refetchScheduledEventsData={refetchScheduledEventsData}
        getScheduledEvent={getScheduledEvent}
        selectedCalendlyEventUri={selectedScheduledEvent?.calendlyEventUri}
        selectedInviteeData={{
          name: selectedInvitee.fullName!,
          email: selectedInvitee.inviteeEmail!,
          phoneNumber: '',
        }}
      />
      <ConfirmCancelModal
        isModalOpen={confirmCancelModalOpen}
        setClosed={() => setConfirmCancelModalOpen(false)}
        selectedScheduledEvent={selectedScheduledEvent}
        setSelectedScheduledEvent={setSelectedScheduledEvent}
        refetchScheduledEventsData={refetchScheduledEventsData}
      />
    </>
  );
};

export default Calendar;
