import { FC } from 'react';
import classNames from 'classnames';
import { SvgIconComponent } from '../types/svgs';

const SectionLabel: FC<{
  title: string;
  className?: string;
  IconComponent?: SvgIconComponent;
  iconClassName?: string;
}> = ({ title, className, IconComponent, iconClassName }) => {
  return (
    <div className="mb-4 flex flex-row items-center justify-start text-green-150">
      {IconComponent && (
        <IconComponent className={classNames('mr-2 h-5 w-5', iconClassName)} />
      )}
      <div className={classNames('text-body font-medium', className)}>
        {title}
      </div>
    </div>
  );
};

export default SectionLabel;
