import { FC, useState, useMemo } from 'react';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';

import {
  PatientsQuery,
  ProgramTemplatesQuery,
  useAssignPatientsToProgramMutation,
} from '../../../../generated/graphql';

import { AssignToProgramValue } from '../../../types/PatientManagement';
import Button from '../../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import SelectMenu from '../../../components/SelectMenu';
import Alert from '../../../components/Alert';
import ProgramItem from '../../../components/ProgramItem';
import ArrowRightLong from '../../../svgs/ArrowRightLong';

import {
  PatientsObject,
  getPatientsObject,
  validatePatientsForMutation,
  getInvalidSentenceText,
} from '../helpers';

import useAlertState from '../useAlertState';

type AssigntoProgramProps = {
  patientsObject: PatientsObject | null;
  programTemplates: ProgramTemplatesQuery['programTemplates'];
  singlePatient: PatientsQuery['patients'][number] | null;
  selectedPatientsList: PatientsQuery['patients'];
  isModalOpen: boolean;
  setClosed: () => void;
  setPatientsObject: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
  setSelectedPatientsObject: React.Dispatch<
    React.SetStateAction<PatientsObject>
  >;
  clearFilters: () => void;
};

const AssignToProgramModal: FC<AssigntoProgramProps> = ({
  patientsObject,
  programTemplates,
  singlePatient,
  selectedPatientsList,
  isModalOpen,
  setClosed,
  setPatientsObject,
  setSelectedPatientsObject,
  clearFilters,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    reset: resetForm,
  } = useForm<AssignToProgramValue>({
    mode: 'onSubmit',
  });

  const programSelectField = watch('program');

  const [loading, setLoading] = useState(false);

  const [assignPatientsToProgramMutation] =
    useAssignPatientsToProgramMutation();

  const { validProgramInstanceIds, invalidPatients } =
    validatePatientsForMutation(
      singlePatient ? [singlePatient] : selectedPatientsList,
      'assignToProgram',
    );

  const invalidPatientsText = getInvalidSentenceText(invalidPatients);

  const { showAlert, setShowAlert } = useAlertState(invalidPatients);

  const buttonTitleText = useMemo(() => {
    return showAlert
      ? 'Assign to eligible clients'
      : singlePatient
      ? `Assign to ${singlePatient?.firstName}`
      : 'Assign to program';
  }, [showAlert, singlePatient]);

  const onSubmit: SubmitHandler<AssignToProgramValue> = async () => {
    if (!validProgramInstanceIds) return;
    const assignPatientsToProgramInput = {
      programTemplateId: programSelectField?.id,
      programInstanceIds: validProgramInstanceIds,
    };

    try {
      setLoading(true);
      const response = await assignPatientsToProgramMutation({
        variables: {
          input: assignPatientsToProgramInput,
        },
      });

      const updatedPatients =
        response?.data?.assignPatientsToProgram?.updatedPatients;

      if (updatedPatients && updatedPatients?.length > 0) {
        setSelectedPatientsObject(
          getPatientsObject([...updatedPatients, ...invalidPatients]),
        );

        const updatedPatientsObject = {
          ...patientsObject,
          ...getPatientsObject(updatedPatients),
        };

        setPatientsObject(updatedPatientsObject);
        clearFilters();
      }
      closeAndResetAssignToProgramModal();
      setLoading(false);
    } catch (err) {
      closeAndResetAssignToProgramModal();
      setLoading(false);
      alert(err);
    }
  };

  const handleErrors: SubmitErrorHandler<AssignToProgramValue> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetAssignToProgramModal = () => {
    setClosed();
    setTimeout(() => {
      setShowAlert(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  const PatientProgramItem: React.FC<{
    value: ProgramTemplatesQuery['programTemplates'][number];
  }> = ({ value }) => (
    <ProgramItem
      programTemplateId={value.id}
      programTemplateName={value.name ?? undefined}
      programTemplateStatus={value.status}
      disableClick
    />
  );

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetAssignToProgramModal}
      fetching={loading}
      width="small"
      overflow="auto"
    >
      <>
        <div className="flex flex-col items-center justify-start px-10">
          <div className="mb-1 font-serif text-subtitle text-green-150">
            Assign to program
          </div>
          <div className="font-sans text-small text-green-125">
            {singlePatient
              ? `Choose a program for ${singlePatient?.firstName}`
              : 'Choose a program for your clients'}
          </div>
        </div>
        <form className="flex w-full flex-col items-center justify-between">
          <Controller
            name="program"
            control={control}
            defaultValue={undefined}
            rules={{ required: true }}
            render={({ field }) => {
              const { onChange, value } = field;

              const fieldValue = value;

              return (
                <SelectMenu
                  fieldValue={fieldValue}
                  onChange={onChange}
                  smallLabel
                  fieldOptions={programTemplates}
                  SelectOptionComponent={PatientProgramItem}
                  containerClassName="w-96 mt-6 mb-8"
                  label="Program"
                />
              );
            }}
          />
          <div className="flex flex-col items-center justify-end">
            {showAlert && (
              <div className="-mt-28 mb-8 w-96">
                <Alert
                  message={`We cannot reassign ${invalidPatientsText} to a program
                because they have already received a program invite.`}
                />
              </div>
            )}
            <Button
              type="submit"
              title={buttonTitleText}
              theme="primary"
              className="my-10"
              disabled={!Boolean(programSelectField)}
              IconComponent={ArrowRightLong}
              onClick={handleSubmit(onSubmit, handleErrors)}
            />
          </div>
        </form>
      </>
    </Modal>
  );
};

export default AssignToProgramModal;
