import { useState, useEffect } from 'react';

export default function useKeyPressed(key: string) {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const handleKeyEvent = (event: KeyboardEvent, isDown: boolean) => {
    if (event.key === key) setKeyPressed(isDown);
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => handleKeyEvent(e, true), false);
    document.addEventListener('keyup', (e) => handleKeyEvent(e, false), false);
  });

  return keyPressed;
}
