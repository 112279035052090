import { FC } from 'react';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import IconButton from './IconButton';
import classNames from 'classnames';

const EditControls: FC<{
  isEditing: boolean;
  onEditClick: () => void;
  handleSaveChanges: () => void;
  handleDiscardChanges: () => void;
  useAbsolutePosition?: boolean;
}> = ({
  isEditing,
  onEditClick,
  handleSaveChanges,
  handleDiscardChanges,
  useAbsolutePosition = false,
}) => {
  return (
    <div
      className={classNames(useAbsolutePosition && 'absolute top-4 right-4')}
    >
      {!isEditing ? (
        <IconButton
          IconComponent={PencilIcon}
          className="h-8 w-8 justify-center !rounded-full bg-white drop-shadow-lg"
          iconClassName="h-6 w-6"
          aria-label="Edit information"
          onClick={onEditClick}
        />
      ) : (
        <div className="flex flex-row items-center space-x-4">
          <IconButton
            IconComponent={CheckIcon}
            className="h-8 w-8 justify-center !rounded-full bg-black drop-shadow-lg"
            iconClassName="text-white h-6 w-6"
            aria-label="Save changes"
            onClick={handleSaveChanges}
          />
          <IconButton
            IconComponent={XIcon}
            className="h-8 w-8 justify-center !rounded-full bg-black drop-shadow-lg"
            iconClassName="text-white h-6 w-6"
            aria-label="Discard changes"
            onClick={handleDiscardChanges}
          />
        </div>
      )}
    </div>
  );
};

export default EditControls;
