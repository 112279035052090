import { FC, useEffect } from 'react';

import Subscription from '../components/Subscription';
import PageContainer from '../components/Containers/PageContainer';
import blueLightningImage from '../../assets/images/pricing-embed.avif';

const SubscribeEmbed: FC = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      const intercomFrame = document.getElementById('intercom-frame');
      const intercomApp = document.getElementsByClassName(
        'intercom-lightweight-app',
      )[0];
      if (intercomFrame) {
        intercomFrame.remove();
      }
      if (intercomApp) {
        intercomApp.remove();
      }
      if (intercomFrame || intercomApp) {
        clearInterval(interval);
      }
    }, 200);

    // The cleanup function to clear the interval is correctly placed to run on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <PageContainer containerClassName="bg-[#eef1ec] relative">
      <div className="absolute inset-0 -top-48 z-0 hidden lg:block">
        <img
          src={blueLightningImage}
          alt="Blue Lightning Background"
          className="min-h-full min-w-full object-cover"
        />
      </div>
      <div className="z-10 mb-8 flex flex-col items-center">
        <div className="text-subtitle-small lg:text-subtitle">
          Our pricing and plans
        </div>
        <div className="mt-2 max-w-[500px] text-center text-caption font-light lg:text-category">
          We believe Homecoming should be accessible to practitioners at every
          stage of their journey.
        </div>
      </div>
      <Subscription isPricingEmbed />
    </PageContainer>
  );
};

export default SubscribeEmbed;
