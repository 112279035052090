import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import {
  AssessmentSchemaType,
  useCompletedPdfSubmissionsQuery,
  LatestCompletedProgramAssessmentsQuery,
  useLatestCompletedProgramAssessmentsQuery,
  ActivityType,
} from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';

import { getTimeStamp } from '../../../lib/time';
import { ContentType } from '../../../lib/followUp';
import { defaultTransitionProps } from '../../../lib/animation';

import ContentPreview from '../../../components/ContentPreview';

import { usePatientProfileContext } from '..';
import AssessmentCompletedSlideover from '../../../components/AssessmentCompletedSlideover';

import LatestAssessmentCards from './LatestAssessmentCards';
import AssessmentsCompletedSlideover from './AssessmentsCompletedSlideover';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import IllustrationNote from '../../../svgs/IllustrationNote';

const Assessments = () => {
  const params = useParams();
  const programId = params.programId || '';

  const { patient } = usePatientProfileContext();

  const {
    data: latestCompletedProgramAssessmentsData,
    error: latestCompletedProgramAssessmentsError,
    loading: latestCompletedProgramAssessmentsLoading,
  } = useLatestCompletedProgramAssessmentsQuery({
    variables: {
      programId,
    },
  });

  const {
    data: completedPdfSubmissionsData,
    error: completedPdfSubmissionsError,
    loading: completedPdfSubmissionsLoading,
  } = useCompletedPdfSubmissionsQuery({
    variables: {
      programId,
    },
  });

  const latestCompletedProgramAssessments =
    latestCompletedProgramAssessmentsData?.latestCompletedProgramAssessments;
  const latestCompletedStandardProgramAssessments =
    latestCompletedProgramAssessments?.filter(
      (programAssessment) =>
        programAssessment.assessment.schemaType !== AssessmentSchemaType.Custom,
    );
  const latestCompletedFormProgramAssessments =
    latestCompletedProgramAssessments?.filter(
      (programAssessment) =>
        programAssessment.assessment.schemaType === AssessmentSchemaType.Custom,
    );
  const completedPdfSubmissions =
    completedPdfSubmissionsData?.completedPdfSubmissions;

  const fileSubmissions = completedPdfSubmissions?.filter(
    (pdfSubmission) =>
      pdfSubmission.programActivity?.activityType === ActivityType.PdfForm,
  );

  const signedSubmissions = completedPdfSubmissions?.filter(
    (pdfSubmission) =>
      pdfSubmission.programActivity?.activityType === ActivityType.Esign,
  );

  const queriesLoading =
    latestCompletedProgramAssessmentsLoading || completedPdfSubmissionsLoading;

  // Both queries have to be loaded with no errors before showing the page
  const shouldShowPage =
    latestCompletedProgramAssessments != null &&
    completedPdfSubmissions != null &&
    !latestCompletedProgramAssessmentsError &&
    !completedPdfSubmissionsError;

  const hasResults =
    (latestCompletedProgramAssessments &&
      latestCompletedProgramAssessments.length > 0) ||
    (completedPdfSubmissions && completedPdfSubmissions.length > 0);

  const [
    isAssessmentsCompletedSlideoverOpen,
    setIsAssessmentsCompletedSlideoverOpen,
  ] = useState(false);
  const [
    isAssessmentCompletedSlideoverOpen,
    setIsAssessmentCompletedSlideoverOpen,
  ] = useState(false);

  const [selectedAssessment, setSelectedAssessment] = useState<
    | LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number]['assessment']
    | undefined
  >();
  const [selectedProgramAssessmentId, setSelectedProgramAssessmentId] =
    useState<string | undefined>();

  return (
    <>
      <Transition
        show={shouldShowPage}
        {...defaultTransitionProps}
        className="w-full pt-6"
      >
        {!hasResults ? (
          <EmptyProfileTab
            patientFirstName={patient?.firstName ?? ''}
            IconComponent={IllustrationNote}
            iconClassName={'text-green-100'}
            titleText="forms and assessments"
            subTitleText={`All the completed forms and assessments you send to ${patient?.firstName} through a follow-up will show up here.`}
          />
        ) : (
          <div className="flex flex-grow flex-col gap-y-10 overflow-y-scroll px-5 pb-10">
            {Boolean(signedSubmissions?.length) && (
              <div>
                <div className="mb-4 text-category font-medium text-green-150">
                  Signed Submissions
                </div>
                <div className="mt-6 flex w-full flex-wrap gap-7">
                  {signedSubmissions?.map((pdfSubmission, i) => (
                    <ContentPreview
                      key={`signedSubmissionContentPreview_${i}`}
                      contentType={ContentType.Esign}
                      className="max-w-[284px] !p-3"
                      title={pdfSubmission.programActivity.name}
                      description={`Signed ${getTimeStamp(
                        pdfSubmission.signedAt,
                        false,
                      )}`}
                      onClick={() => {
                        window.open(
                          pdfSubmission?.media?.url,
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
            {Boolean(fileSubmissions?.length) && (
              <div>
                <div className="mb-4 text-category font-medium text-green-150">
                  File Submissions
                </div>
                <div className="mt-6 flex w-full flex-wrap gap-7">
                  {fileSubmissions?.map((pdfSubmission, i) => (
                    <ContentPreview
                      key={`fileSubmissionContentPreview_${i}`}
                      contentType={ContentType.PdfForm}
                      className="max-w-[284px] !p-3"
                      title={pdfSubmission.programActivity.name}
                      description={`Completed ${getTimeStamp(
                        pdfSubmission.programActivity?.completedAt,
                        false,
                      )}`}
                      onClick={() => {
                        window.open(
                          pdfSubmission?.media?.url,
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!latestCompletedFormProgramAssessments?.length && (
              <div>
                <div className="mb-4 text-category font-medium text-green-150">
                  Forms
                </div>
                <LatestAssessmentCards
                  latestCompletedProgramAssessments={
                    latestCompletedFormProgramAssessments
                  }
                  setSelectedAssessment={setSelectedAssessment}
                  setSelectedProgramAssessmentId={
                    setSelectedProgramAssessmentId
                  }
                  setIsAssessmentsCompletedSlideoverOpen={
                    setIsAssessmentsCompletedSlideoverOpen
                  }
                  setIsAssessmentCompletedSlideoverOpen={
                    setIsAssessmentCompletedSlideoverOpen
                  }
                />
              </div>
            )}
            {!!latestCompletedStandardProgramAssessments?.length && (
              <div>
                <div className="mb-3.5 text-category font-medium text-green-150">
                  Assessments
                </div>
                <LatestAssessmentCards
                  latestCompletedProgramAssessments={
                    latestCompletedStandardProgramAssessments
                  }
                  setSelectedAssessment={setSelectedAssessment}
                  setSelectedProgramAssessmentId={
                    setSelectedProgramAssessmentId
                  }
                  setIsAssessmentsCompletedSlideoverOpen={
                    setIsAssessmentsCompletedSlideoverOpen
                  }
                  setIsAssessmentCompletedSlideoverOpen={
                    setIsAssessmentCompletedSlideoverOpen
                  }
                />
              </div>
            )}
          </div>
        )}
      </Transition>

      <AssessmentsCompletedSlideover
        onClose={() => setIsAssessmentsCompletedSlideoverOpen(false)}
        isOpen={isAssessmentsCompletedSlideoverOpen}
        patientFirstName={`${patient?.firstName}`}
        programId={patient?.programInstanceId}
        selectedAssessment={selectedAssessment}
      />

      <AssessmentCompletedSlideover
        isOpen={isAssessmentCompletedSlideoverOpen}
        onClose={() => setIsAssessmentCompletedSlideoverOpen(false)}
        programAssessmentId={selectedProgramAssessmentId ?? ''}
        patientFirstName={patient?.firstName ?? ''}
      />

      {queriesLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default Assessments;
