import classNames from 'classnames';
import { CheckIn } from '../../generated/graphql';
import { getTimeStamp } from '../lib/date';
import CircleCheck from '../svgs/CircleCheck';

const CheckInItem: React.FC<{
  checkInItem: CheckIn | undefined;
  hasMoodLabel?: boolean;
  hasBadge?: boolean;
}> = ({ checkInItem, hasMoodLabel = false, hasBadge = false }) => {
  const score = checkInItem?.score ?? 0;

  let foregroundClassName = '';
  let backgroundClassName = '';
  let label = '';

  const lowRange = 2;
  const mediumRange = 3.5;
  const highRange = 5;

  if (score > 0 && score <= lowRange) {
    foregroundClassName += 'text-red-100';
    backgroundClassName = 'bg-red-25';
    label = 'Low';
  }

  if (score > lowRange && score <= mediumRange) {
    foregroundClassName += 'text-orange-100';
    backgroundClassName = 'bg-orange-25';
    label = 'Medium';
  }

  if (score > mediumRange && score <= highRange) {
    foregroundClassName += 'text-green-100';
    backgroundClassName = 'bg-green-25';
    label = 'High';
  }

  return (
    <div className="flex flex-row items-start justify-start">
      {hasBadge && hasMoodLabel && (
        <div
          className={classNames('mr-3 rounded-3xl p-1', backgroundClassName)}
        >
          <CircleCheck className={classNames('h-6 w-6', foregroundClassName)} />
        </div>
      )}
      <div className="flex flex-col items-start justify-start">
        {hasMoodLabel && (
          <span
            className={classNames(
              foregroundClassName,
              'text-small-label font-bold',
            )}
          >
            {label}
          </span>
        )}
        <div className="my-1 flex flex-row items-center justify-start text-caption">
          <span className="mr-1.5 font-medium text-green-150">{score}</span>
          <span className="text-green-150/50">/5</span>
        </div>
        <span className="text-small-label">
          {getTimeStamp(checkInItem?.createdAt, false, true)}
        </span>
      </div>
    </div>
  );
};

export default CheckInItem;
