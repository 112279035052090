import { FC } from 'react';
import { ProgramTagWithCountDataFragment } from '../../../generated/graphql';
import classNames from 'classnames';
import { PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP } from '../../lib/colors';
import { TrashIcon } from '@heroicons/react/outline';
import { pluralize } from '../../lib/copy';
import Tag from '../../svgs/Tag';

const ProgramTagPill: FC<{
  programTag: ProgramTagWithCountDataFragment;
  onDelete?: (programTag: ProgramTagWithCountDataFragment) => void;
  className?: string;
}> = ({ programTag, onDelete, className }) => {
  return (
    <li
      key={programTag.id}
      className={classNames(
        'relative flex flex-row gap-x-2 rounded-full py-2 px-4',
        PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[programTag.color],
        className,
      )}
    >
      <Tag className="h-6 min-w-[24px]" />
      <div className="relative top-[2px]">
        {programTag.name}{' '}
        {`(${pluralize(programTag.programCount, 'client', 'clients')})`}
      </div>
      {onDelete && (
        <div
          onClick={() => {
            onDelete(programTag);
          }}
        >
          <TrashIcon
            className={
              'h-6 w-6 cursor-pointer rounded-full bg-white p-1 text-red-100 hover:bg-red-100 hover:text-white'
            }
          />
        </div>
      )}
    </li>
  );
};

export default ProgramTagPill;
