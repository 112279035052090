import { FC } from 'react';

import { CompletedProgramAssessmentsDataQuery } from '../../../generated/graphql';
import {
  isSignatureAssessmentAnswer,
  isMultipleChoiceScoredAssessmentAnswer,
  isMultipleChoiceAssessmentAnswer,
  isMultiSelectAssessmentAnswer,
  isFreeTextAssessmentAnswer,
  isRichTextAssessmentAnswer,
  isMultiSignatureAssessmentAnswer,
} from '../../lib/assessments';
import SlideoverSection from '../SlideoverSection';
import QuestionItem from './QuestionItem';
import CompletedSignature from '../CompletedSignature';

type AssessmentAnswers =
  CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'][number]['answers'];

const ResponsesSection: FC<{
  title?: string;
  questionFrame?: string;
  answers?: AssessmentAnswers;
}> = ({ title = 'Responses', questionFrame, answers }) => {
  return (
    <SlideoverSection title={title}>
      {questionFrame && Boolean(answers?.length) && (
        <div className="mb-4 font-sans text-caption text-neutral-125">
          {questionFrame}
        </div>
      )}
      {answers?.length === 0 && (
        <div className="text-caption font-medium text-neutral-110">
          This assessment is not complete
        </div>
      )}
      {answers?.map((answer, index) => {
        const isLastQuestion = index === answers.length - 1;

        if (isMultipleChoiceScoredAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              subscale={answer.subscale}
              answer={answer.answer}
              className={isLastQuestion ? 'mb-0' : ''}
            />
          );
        }
        if (isMultipleChoiceAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              answer={answer.answer}
              className={isLastQuestion ? 'mb-0' : ''}
            />
          );
        }
        if (isMultiSelectAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              answer={
                answer.answers.length
                  ? answer.answers.join(', ')
                  : 'Nothing selected'
              }
              className={isLastQuestion ? 'mb-0' : ''}
            />
          );
        }
        if (isFreeTextAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              answer={answer.answer}
              className={isLastQuestion ? 'mb-0' : ''}
            />
          );
        }
        if (isSignatureAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              className={isLastQuestion ? 'mb-0' : ''}
              answerClassName="font-normal"
              additionalAnswerElem={
                <CompletedSignature
                  signatureName={answer.signatureName}
                  signedAt={answer.signedAt}
                  size="small"
                  signatureRole="Client"
                />
              }
            />
          );
        }
        if (isMultiSignatureAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              className={isLastQuestion ? 'mb-0' : ''}
              answerClassName="font-normal"
              additionalAnswerElem={
                <>
                  {answer.signatures.map((signature, index) => (
                    <CompletedSignature
                      key={index}
                      signatureName={signature.signatureName}
                      signatureRole={signature.signatureRole}
                      signedAt={signature.signedAt}
                      className="mb-4"
                      size="small"
                    />
                  ))}
                </>
              }
            />
          );
        }
        if (isRichTextAssessmentAnswer(answer)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={answer.question}
              answer={answer.answer}
              className={isLastQuestion ? 'mb-0' : ''}
              answerClassName="!font-normal"
            />
          );
        }
        return null;
      })}
    </SlideoverSection>
  );
};

export default ResponsesSection;
