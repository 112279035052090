import { ProgramActivityDataFragment } from '../../../../generated/graphql';
import { ContentType, activityTypeToContentType } from '../../../lib/followUp';

interface Filters {
  searchTerm: string;
  contentTypeFilter?: ContentType | null;
}

export const doesActivityMeetFilters = (
  programActivity: ProgramActivityDataFragment,
  filters: Filters,
): boolean => {
  const programActivityTitle = programActivity?.name.toLowerCase();
  let programActivityURL = '';

  const { contentTypeFilter, searchTerm } = filters;

  // Search
  const isAssessment = programActivity?.programAssessment;

  if (programActivity?.activityLink)
    programActivityURL = programActivity?.activityLink.url;

  if (
    !programActivityTitle.includes(searchTerm) &&
    !programActivityURL.includes(searchTerm) &&
    !programActivity.providerNote.toLowerCase().includes(searchTerm)
  ) {
    return false;
  }

  // Type filter
  if (contentTypeFilter) {
    const providerActivityContentType = activityTypeToContentType(
      programActivity.activityType,
      isAssessment
        ? programActivity.programAssessment.assessment?.schemaType
        : undefined,
    );
    if (contentTypeFilter !== providerActivityContentType) {
      return false;
    }
  }

  return true;
};
