import { ArchiveIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import Logo from '../../svgs/Logo';
import { QuickActionProps } from './QuickAction';

const OnboardingNotification: FC<{
  step: QuickActionProps;
}> = ({ step }) => {
  return (
    <div
      className="flex w-full cursor-pointer flex-row items-center rounded-sm py-3 px-5 hover:bg-neutral-25 focus:bg-neutral-50 focus:outline-none"
      onClick={step.onClick}
    >
      <Logo className="mr-5 h-8 w-8 shrink-0 grow-0" />
      <div className="mr-10 flex-grow">
        <div className="mb-1 text-caption text-green-150 line-clamp-2">
          <span className="font-medium">{step.title}: </span>
          <span>{step.description}</span>
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          title={step.buttonTitle ?? 'Get Started'}
          theme="secondary"
          onClick={(e) => {
            e.stopPropagation();
            step.onClick();
          }}
        />
        <IconButton
          className="ml-5"
          iconClassName="w-5 h-5 text-green-100"
          IconComponent={ArchiveIcon}
          aria-label="Mark notification read"
          onClick={(e) => {
            e.stopPropagation();
            step.onDismiss();
          }}
        />
      </div>
    </div>
  );
};

export default OnboardingNotification;
