import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

type ErrorMessageProps = {
  className?: string;
  children?: React.ReactNode;
};

export default function ErrorMessage({
  className,
  children,
}: ErrorMessageProps) {
  if (!children) {
    return null;
  }

  return (
    <span
      className={classNames(
        'flex items-center text-caption text-red-100',
        className,
      )}
    >
      <ExclamationCircleIcon className="mr-1 h-4 w-4" />
      <span className="text-left">{children}</span>
    </span>
  );
}
