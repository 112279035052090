import { FC } from 'react';
import classNames from 'classnames';

import {
  TaskType,
  FeedType,
  PatientQuery,
} from '../../../../generated/graphql';
import Button from '../../../components/Button';
import CheckInGroup from '../../../components/CheckInGroup';

import { getTimeStamp } from '../../../lib/date';
import ArrowRightLong from '../../../svgs/ArrowRightLong';

import FeedStat from './FeedStat';
import FeedItem from './FeedItem';

const FeedPanel: FC<{
  feedType: FeedType;
  patient: PatientQuery['patient'] | null;
  seeMoreOnClick: () => void;
}> = ({ patient, feedType, seeMoreOnClick }) => {
  const isCheckInsFeed = feedType === FeedType.CheckIns;
  const isActivityFeed = feedType === FeedType.Activities;

  const feedStats = {
    left: {
      title: '',
      label: '',
      value: '',
      element: <></>,
    },
    right: {
      title: '',
      label: '',
      value: '',
      element: <></>,
    },
  };

  if (isActivityFeed) {
    feedStats.left.title = 'Completed';
    feedStats.left.label = 'All time';
    feedStats.left.value = `${patient?.tasksData?.activitiesCount ?? 0}`;
    feedStats.right.title = 'Content progress';
    feedStats.right.label = 'All time';
    feedStats.right.value = `${
      patient?.tasksData?.contentCompletedPercentage ?? 0
    }%`;
  } else if (isCheckInsFeed) {
    feedStats.left.title = 'Completed';
    feedStats.left.label = 'All time';
    feedStats.left.value = `${patient?.checkInData?.totalCheckIns ?? 0}`;
    feedStats.right.title = 'Completed';
    feedStats.right.label = 'Past 7 days';
    if (patient?.checkInData)
      feedStats.right.element = (
        <CheckInGroup
          data={patient?.checkInData}
          itemType="count"
          hasDifferenceIndicator={false}
        />
      );
  }

  return (
    <div className="flex w-1/2 min-w-[339px] max-w-[440px] flex-col items-start justify-start text-green-150">
      <span className="mb-3 font-serif text-subtitle-small">
        {isActivityFeed && <span>Activities</span>}
        {isCheckInsFeed && <span>Check-ins</span>}
      </span>
      <div className="flex flex-col items-center justify-start rounded-xl bg-white shadow-400 sm:w-[340px] xl:w-full">
        <div className="flex w-full flex-row items-start justify-start px-8 py-6">
          <FeedStat
            title={feedStats.left.title}
            label={feedStats.left.label}
            value={feedStats.left.value}
            element={feedStats.left.element}
          />
          <div className="w-20" />
          <FeedStat
            title={feedStats.right.title}
            label={feedStats.right.label}
            value={feedStats.right.value}
            element={feedStats.right.element}
          />
        </div>
        <div className="w-full border-b border-neutral-50" />
        <div className="flex w-full flex-col items-center justify-start">
          {isActivityFeed &&
            patient?.tasksData?.recent3Tasks?.map(
              (patientTask, patientTaskIndex) => {
                return (
                  <FeedItem
                    key={`FeedType.Activities_${patientTaskIndex}`}
                    feedType={feedType}
                    feedItemType={patientTask.taskType}
                    content={patientTask.title}
                    timestamp={getTimeStamp(patientTask.createdAt, false, true)}
                  />
                );
              },
            )}
          {isCheckInsFeed &&
            patient?.checkInData?.recent3CheckIns?.map(
              (checkInItem, checkInItemIndex) => {
                return (
                  <FeedItem
                    key={`FeedType.CheckIns_${checkInItemIndex}`}
                    feedType={feedType}
                    feedItemType={TaskType.CheckIn}
                    checkInItem={checkInItem}
                  />
                );
              },
            )}
        </div>
        <Button
          size="small"
          theme="secondary"
          title={`See more ${isActivityFeed ? 'activities' : 'check-ins'}`}
          onClick={seeMoreOnClick}
          className={classNames(
            'flex w-full flex-row items-start justify-start',
            'rounded-none rounded-b-xl px-8 py-6 text-caption font-medium',
            'hover:rounded-b-xl hover:bg-neutral-25',
          )}
          IconComponent={ArrowRightLong}
          iconClassName="text-green-100"
          noBackground
          noOutline
        />
      </div>
    </div>
  );
};

export default FeedPanel;
