import classNames from 'classnames';

type MainContainerProps = {
  children?: React.ReactNode;
  containerClassName?: string;
};

export default function MainContainer({
  children,
  containerClassName,
}: MainContainerProps) {
  return (
    <div
      className={classNames(
        'min-h-screen w-full bg-neutral-25 pt-10 pb-12 pr-8 pl-[444px]',
        containerClassName,
      )}
    >
      {children}
    </div>
  );
}
