import { FC } from 'react';
import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/outline';

import Config from '../../../lib/config';
import Button from '../Button';
import {
  FeatureRow,
  BILLING_PERIODS,
  BillingPeriodInfo,
  BillingPeriodOption,
  TEAM_SEAT_COUNT_OPTIONS,
} from '.';
import { CALENDLY_ENTERPRISE_PLAN_URL } from '../../lib/constants';

const MobilePricingCard: FC<{
  selectedBillingPeriod: BillingPeriodInfo;
  annualSelected: boolean;
  featureRows: FeatureRow[];
  planValue: 'freeValue' | 'individualValue' | 'teamValue' | 'enterpriseValue';
  teamEffectiveMonthlyPrice?: number;
  teamSavings?: number;
  teamAnnualPrice?: number;
  teamSeatCount?: number;
  setTeamSeatCount?: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  selectedBillingPeriod,
  annualSelected,
  featureRows,
  planValue,
  teamEffectiveMonthlyPrice,
  teamSavings,
  teamAnnualPrice,
  teamSeatCount,
  setTeamSeatCount,
}) => {
  const isFree = planValue === 'freeValue';
  const isIndividual = planValue === 'individualValue';
  const isTeam = planValue === 'teamValue';
  const isEnterprise = planValue === 'enterpriseValue';

  return (
    <div className="flex w-[365px] flex-col items-start justify-start rounded-xl bg-white px-6 shadow-100">
      <div className="relative py-5 font-serif text-subtitle font-light text-neutral-150">
        {isFree && 'Free'}
        {isIndividual && 'Individual'}
        {isTeam && 'Team'}
        {isEnterprise && 'Enterprise'}
      </div>
      {!isEnterprise ? (
        <div className="flex flex-col py-5">
          <div className="flex items-center gap-x-2">
            <div className="text-[2.872rem] font-medium leading-tight text-neutral-150">
              {isFree && `$0`}
              {isIndividual && `$${selectedBillingPeriod.monthlyPrice}`}
              {isTeam && `$${teamEffectiveMonthlyPrice}`}
            </div>
            <div className="max-w-[141px] text-caption font-medium text-neutral-110">
              USD/month <br />
              billed {annualSelected ? 'annually' : 'monthly'}
            </div>
          </div>
          {!isFree && (
            <div className="mt-1 flex h-6 items-center gap-x-4">
              {annualSelected && (
                <>
                  <div className="rounded bg-green-100 px-2 py-1 text-action font-bold uppercase text-white">
                    Save $
                    {isIndividual &&
                      BILLING_PERIODS[BillingPeriodOption.Monthly]
                        .monthlyPrice *
                        12 -
                        selectedBillingPeriod.annualPrice}
                    {isTeam && teamSavings}
                  </div>
                  <div className="text-caption font-medium text-neutral-110">
                    {isIndividual && `$${selectedBillingPeriod.annualPrice}`}{' '}
                    {isTeam && `$${teamAnnualPrice}`} annually
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="my-6 text-title-medium font-medium leading-tight text-neutral-150">
          Let's talk
        </div>
      )}

      {isIndividual && (
        <div className="flex w-full justify-center rounded-full bg-neutral-25 py-2 text-center text-body font-medium text-neutral-150">
          1 seat
        </div>
      )}
      {isTeam && (
        <div className="w-full py-2">
          <Listbox value={teamSeatCount} onChange={setTeamSeatCount}>
            <Listbox.Button className="flex w-full justify-center rounded-full bg-neutral-25 py-2 px-11 text-center text-body font-medium text-neutral-150">
              {teamSeatCount} seats <ChevronDownIcon className="ml-2 h-5 w-5" />
            </Listbox.Button>
            <Listbox.Options className="absolute z-50 w-[15rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none">
              {TEAM_SEAT_COUNT_OPTIONS.map((seatCount, i) => (
                <Listbox.Option
                  key={`seatCountOption-${seatCount}`}
                  value={seatCount}
                  className={({ active }) =>
                    classNames(
                      'cursor-pointer px-5 py-3 text-caption text-neutral-125',
                      active && 'bg-neutral-25',
                      {
                        'rounded-t-md': active && i === 0, // round top if it's the first item
                        'rounded-b-md':
                          active && i === TEAM_SEAT_COUNT_OPTIONS.length - 1, // round bottom if it's the last item
                      },
                    )
                  }
                >
                  {seatCount} seats
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      )}
      {isEnterprise && (
        <div className="flex w-full justify-center rounded-full bg-neutral-25 py-2 text-center text-body font-medium text-neutral-150">
          11+ seats
        </div>
      )}

      <div className="flex flex-col items-start justify-start gap-y-3 py-5">
        {featureRows
          .filter((featureRow) => featureRow[planValue])
          .map((featureRow) => (
            <div
              key={`${planValue}_${featureRow.title}`}
              className="flex flex-row items-center justify-start"
            >
              <CheckCircleIcon
                className="h-[20px] min-w-[20px] text-orange-100"
                aria-hidden="true"
              />
              <div className="ml-2 text-body text-neutral-150">
                {featureRow[planValue + 'Mobile'] ?? featureRow.title}
              </div>
            </div>
          ))}
      </div>
      <Button
        title={
          isFree
            ? 'Get started'
            : isEnterprise
            ? 'Schedule a call'
            : 'Start 30-day free trial'
        }
        className="mt-2 mb-8 w-full bg-blue-125 hover:bg-blue-100"
        onClick={() => {
          const uri = isEnterprise
            ? CALENDLY_ENTERPRISE_PLAN_URL
            : `${Config.REACT_APP_WEB_APP_URL}/sign-up`;

          window.open(uri, '_blank', 'noopener noreferrer');
        }}
      />
    </div>
  );
};

export default MobilePricingCard;
