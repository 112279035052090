import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ApolloError } from '@apollo/client';
import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  useSignUpConfigQuery,
  useValidateProviderUserEmailMutation,
} from '../../generated/graphql';
import {
  ProviderAnalyticsEvent,
  aliasProvider,
  trackProviderEvent,
} from '../../lib/analytics';
import { HomecomingError, matchHomecomingError } from '../../lib/errors';
import GtmHelpers from '../../lib/gtm';
import { addOptimizerParams } from '../../lib/images';
import Button from '../components/Button';
import ErrorMessage from '../components/ErrorMessage';
import InputGroup from '../components/InputGroup';
import PasswordSuggestions from '../components/PasswordSuggestions';
import { getPasswordErrorMessage } from '../lib/form-validators';
import { checkPasswordStrength } from '../lib/password-strength';
import { EMAIL_REGEX, NAME_REGEX } from '../lib/regex';

import Spinner from '../svgs/Spinner';
import WordmarkLogo from '../svgs/WordmarkLogo';
import hcLogo from '../../assets/images/logo/logo-green-xl.png';

import useUtmTracking from '../hooks/useUtmTracking';
import { HOMEPAGE_URL } from '../lib/constants';

interface EmailFormData {
  email: string;
}

interface SignUpFormData {
  name: string;
  password: string;
  emailOptIn: boolean;
}

export enum SignUpSteps {
  Email = 'Email',
  NameAndPasswordAndSubmit = 'NameAndPasswordAndSubmit',
}

const DEFAULT_TESTIMONIAL_QUOTE =
  'I spent so much time trying to cobble together a solution to keep track of all my client communication, plus take care of all the admin tasks like scheduling and billing. It was exhausting and took me out of the focused mindset I need to be an effective practitioner. Now, I can manage my whole business in one place, and it’s much easier to keep things on track.';
const DEFAULT_TESTIMONIAL_NAME = 'Jules Xenakis';
const DEFAULT_TESTIMONIAL_TITLE = 'Coach, Being True to You';

const SignUp: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [utmParams, clearUtmParams] = useUtmTracking();

  const [signUpStep, setSignUpStep] = useState(SignUpSteps.Email);

  const [validateProviderUserEmail] = useValidateProviderUserEmailMutation();

  const mixpanelDistinctId = searchParams.get('distinct_id');
  const emailParam = searchParams.get('email');

  const params = useParams();
  const signUpConfigSlug = params.slug;

  const { data: signUpConfigData, loading: signUpConfigLoading } =
    useSignUpConfigQuery({
      variables: { slug: signUpConfigSlug },
      skip: !signUpConfigSlug,
    });

  const signUpConfig = signUpConfigData?.signUpConfig;

  useEffect(() => {
    // Critical for connecting Mixpanel IDs further back in the funnel to the marketing website.
    // If the param is set, they're coming from the website, and this is what connects
    // their website Mixpanel-created distinct ID to the Mixpanel-created distinct ID
    // that was just created for them in the app website.
    if (mixpanelDistinctId) {
      aliasProvider(mixpanelDistinctId);
    }
  }, [mixpanelDistinctId]);

  useEffect(() => {
    if (
      signUpConfigSlug &&
      signUpConfigData &&
      !signUpConfigData?.signUpConfig
    ) {
      // Redirect to sign up page if the slug is invalid
      navigate('/sign-up');
    }
  }, [signUpConfigSlug, signUpConfigData, navigate]);

  const isCommunityHub = signUpConfig?.isCommunityHub;

  const [passwordStrengthResult, setPasswordStrengthResult] = useState<
    ZxcvbnResult | undefined
  >();
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [showLoginButton, setShowLoginButton] = useState(false);

  const {
    register: registerEmailForm,
    watch: watchEmailForm,
    handleSubmit: handleEmailFormSubmit,
    formState: { errors: emailFormErrors, isSubmitting: emailFormIsSubmitting },
    setValue,
  } = useForm<EmailFormData>({
    mode: 'onSubmit',
  });

  const email = watchEmailForm('email');

  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors, isSubmitting },
    clearErrors,
  } = useForm<SignUpFormData>({
    mode: 'onSubmit',
    defaultValues: {
      emailOptIn: true,
    },
  });

  const handleContinueButtonClick = async (formData: EmailFormData) => {
    try {
      await validateProviderUserEmail({
        variables: {
          email: formData.email.trim(),
        },
      });

      setApiErrorMessage(null);
      setShowLoginButton(false);
      setSignUpStep(SignUpSteps.NameAndPasswordAndSubmit);
    } catch (err) {
      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        setApiErrorMessage('An account with this email already exists.');
        setShowLoginButton(true);
        trackProviderEvent(ProviderAnalyticsEvent.ProviderSignUpFailed, {
          reason: 'Email already exists',
        });
        return;
      }
      setApiErrorMessage(
        "Something went wrong. Please contact support@homecoming.health and we'll help you get started.",
      );
      trackProviderEvent(ProviderAnalyticsEvent.ProviderSignUpFailed, {
        reason: 'Server error',
      });
    }
  };

  const onSubmit = async (formData: SignUpFormData) => {
    try {
      clearErrors();
      setApiErrorMessage(null);
      const { tokenPayload, signUpConfig } = await auth.signUp(
        email.trim(),
        formData.password,
        formData.name.trim(),
        formData.emailOptIn,
        signUpConfigSlug ? signUpConfigSlug : undefined,
        utmParams,
      );

      if (utmParams) {
        clearUtmParams();
      }

      try {
        GtmHelpers.trackEvent(
          ProviderAnalyticsEvent.ProviderSignedUp,
          tokenPayload.providerUserId,
        );
      } catch (err) {
        // Do nothing.
      }

      if (!tokenPayload.authToken) {
        // No access token here means that there was an issue creating the user in Auth0 or
        // getting the user's access token. This is a critical error that should be addressed,
        // but if it ever happens, the best thing to do is to redirect the the user to login, where
        // they will be able to try logging in on the Auth0 side with the credentials they've just entered,
        // and upon a successful login will sync the users and recover the sign-up.
        navigate('/login');
      }

      const navigateTo = isCommunityHub
        ? '/community-landing'
        : signUpConfig?.skipIntroCarousel
        ? '/home'
        : '/intro';
      navigate(navigateTo);
    } catch (err) {
      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        setApiErrorMessage('An account with this email already exists.');
        trackProviderEvent(ProviderAnalyticsEvent.ProviderSignUpFailed, {
          reason: 'Email already exists',
        });
        return;
      }
      setApiErrorMessage(
        "Something went wrong. Please contact support@homecoming.health and we'll help you get started.",
      );
      trackProviderEvent(ProviderAnalyticsEvent.ProviderSignUpFailed, {
        reason: 'Server error',
      });
    }
  };

  // Attempt auto-fill / submit of the first step if email is provided
  useEffect(() => {
    if (emailParam) {
      setValue('email', emailParam);
      handleEmailFormSubmit(handleContinueButtonClick)();
    }
  }, [emailParam]);

  // Show a spinner while we wait for the sign up config to avoid having text / elements
  // switch suddenly
  if (signUpConfigSlug && signUpConfigLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // Utilize image optimizer for additional logo media
  const logoUrl = addOptimizerParams(signUpConfig?.additionalLogoMedia?.url, {
    height: 160,
  });

  const logoLinkUrl = signUpConfig?.logoLinkUrl;

  const communitySidepanelBgUrl = signUpConfig?.sidepanelBgMedia?.url;
  const communityScreenshotMediaUrl =
    signUpConfig?.sidepanelCommunityMedia?.url;

  const isEmailStep = signUpStep === SignUpSteps.Email;

  const defaultHeaderText = isEmailStep
    ? 'Welcome to Homecoming'
    : 'Create your account';

  const communityJoinText = signUpConfig?.partnerName
    ? `Join ${signUpConfig?.partnerName} Community`
    : 'Join Homecoming Community';

  const headerText = isCommunityHub
    ? communityJoinText
    : signUpConfig?.headerText ?? defaultHeaderText;

  const subheaderText = isCommunityHub
    ? 'Includes free access to Homecoming for 30 days.'
    : signUpConfig?.subheaderText ??
      'Try Homecoming free for 30 days. No credit card required.';

  return (
    <div className="flex flex-col lg:min-h-screen lg:flex-row">
      {isCommunityHub ? (
        <div className="flex flex-1 flex-col flex-wrap items-center justify-center pt-screen-5 lg:absolute lg:top-7 lg:left-10 lg:z-10 lg:p-0">
          {logoUrl && (
            <a
              href={logoLinkUrl}
              rel="noreferrer"
              target="_blank"
              className={classNames(!logoLinkUrl && 'cursor-default')}
              onClick={(e) => {
                if (!logoLinkUrl) {
                  e.preventDefault();
                }
              }}
            >
              <img alt="Partner logo" className="w-[240px]" src={logoUrl} />
            </a>
          )}
          <div className="mt-2 flex flex-row items-center justify-center">
            <img src={hcLogo} className="mr-2 h-[18px]" alt="logo" />
            <div className="text-caption">
              In partnership with <span className="font-bold">Homecoming</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-[32px] flex-1 flex-wrap items-center justify-center gap-x-5 pt-screen-5 lg:absolute lg:top-7 lg:left-10 lg:p-0">
          <div>
            <a
              href={logoLinkUrl ?? HOMEPAGE_URL}
              rel="noreferrer"
              target="_blank"
            >
              <WordmarkLogo className="hidden h-[32px] lg:flex" />
              <img
                src={hcLogo}
                className="flex h-[32px] w-[32px] lg:hidden"
                alt="logo"
              />
            </a>
          </div>
          {logoUrl && (
            <div>
              <img alt="Partner logo" className="h-[40px]" src={logoUrl} />
            </div>
          )}
          {signUpConfig?.partnerName && (
            <div className="rounded-full bg-green-25 px-3 py-1 text-caption font-bold">
              {signUpConfig.partnerName}
            </div>
          )}
        </div>
      )}

      <div className="relative flex flex-1 flex-col items-center justify-center pb-screen-5 pt-screen-5">
        <div className="mb-3 mt-3 max-w-[488px] px-4 text-center font-serif text-subtitle-small font-light text-neutral-150 md:mb-2 md:text-subtitle">
          {headerText}
        </div>
        <div className="mb-8 max-w-[488px] px-4 text-center text-caption">
          {subheaderText}
        </div>

        <div className="w-3/4 max-w-[488px]">
          <form className="w-full">
            {isEmailStep && (
              <>
                <div className="w-full text-left">
                  <InputGroup
                    placeholder="Email address"
                    type="email"
                    autoComplete="username"
                    inputSize="small"
                    useNaturalLettering={true}
                    containerClassName="mb-2"
                    required
                    errorMessage={emailFormErrors.email?.message}
                    {...registerEmailForm('email', {
                      required: 'Email is required',
                      onChange: () => {
                        setShowLoginButton(false);
                        setApiErrorMessage(null);
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                </div>
                <Button
                  title={isCommunityHub ? communityJoinText : 'Create account'}
                  className="mx-auto mt-6 w-full md:mt-8"
                  type="submit"
                  disabled={emailFormIsSubmitting}
                  onClick={handleEmailFormSubmit(handleContinueButtonClick)}
                />
              </>
            )}
            {signUpStep === SignUpSteps.NameAndPasswordAndSubmit && (
              <>
                <div className="w-full text-left">
                  <InputGroup
                    placeholder="Full name"
                    autoComplete="name"
                    inputSize="small"
                    useNaturalLettering={true}
                    containerClassName="md:mb-6 mb-4"
                    required
                    errorMessage={validationErrors.name?.message}
                    {...register('name', {
                      required: 'Name is required',
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Please enter your first and last name',
                      },
                    })}
                  />
                  <InputGroup
                    placeholder="Password"
                    inputType="password"
                    inputSize="small"
                    useNaturalLettering={true}
                    containerClassName="md:mb-6 mb-4"
                    required
                    errorMessage={validationErrors.password?.message}
                    passwordStrengthScore={passwordStrengthResult?.score}
                    {...register('password', {
                      validate: (password) => {
                        const passwordStrengthResult =
                          checkPasswordStrength(password);
                        setPasswordStrengthResult(passwordStrengthResult);

                        return getPasswordErrorMessage(passwordStrengthResult);
                      },
                    })}
                  />

                  <PasswordSuggestions
                    strengthResult={passwordStrengthResult}
                  />
                </div>

                <div className="mt-8 flex md:mt-6">
                  <input
                    id="email-opt-in-input"
                    type="checkbox"
                    className="mr-2 h-5 w-5 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125"
                    {...register('emailOptIn')}
                  />
                  <label
                    className="cursor-pointer text-caption text-neutral-125"
                    htmlFor="email-opt-in-input"
                  >
                    Keep me updated with news and personalized offers
                  </label>
                </div>

                <Button
                  title="Create account"
                  className="mx-auto mt-6 w-full md:mt-10"
                  disabled={isSubmitting}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                />

                <div className="mt-4 text-center text-caption text-neutral-125 lg:hidden">
                  By creating an account, you agree to Homecoming's{' '}
                  <a
                    href="https://www.homecoming.health/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.homecoming.health/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              </>
            )}
          </form>

          <ErrorMessage className="mt-4">
            {apiErrorMessage}
            {showLoginButton && (
              <span
                onClick={() => navigate('/login')}
                className="ml-1 cursor-pointer font-medium text-red-100 hover:underline"
              >
                Log in?
              </span>
            )}
          </ErrorMessage>

          {!signUpConfig?.hideLoginLink && (
            <div className="mt-5 flex flex-row items-center justify-center text-caption">
              <div className="mr-2 text-neutral-125">
                Already have an account?
              </div>
              <div
                onClick={() => navigate('/login')}
                className="cursor-pointer font-medium text-green-100 hover:underline"
              >
                Log in
              </div>
            </div>
          )}
        </div>

        <div className="absolute bottom-5 hidden w-[400px] pb-2 text-center text-small-caption lg:block">
          By creating an account, you agree to Homecoming’s{' '}
          <a
            href="https://www.homecoming.health/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.homecoming.health/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      <div className="w-full lg:w-[560px]">
        <div
          className={classNames(
            'flex h-full w-full shrink flex-col items-center bg-cover lg:m-0',
            !isCommunityHub &&
              "justify-center p-8 lg:bg-[url('assets/images/signup/side-bg.png')]",
            isCommunityHub && 'justify-between',
          )}
          style={
            isCommunityHub && communitySidepanelBgUrl
              ? { backgroundImage: `url(${communitySidepanelBgUrl})` }
              : {}
          }
        >
          <div
            className={classNames(
              'flex h-auto flex-col items-start justify-between',
              !isCommunityHub &&
                'w-[348px] rounded-md bg-white p-8 text-green-150 shadow-100',
              isCommunityHub && 'w-full px-12 pt-20 text-white',
            )}
          >
            <div
              className={classNames(
                'mb-4',
                isCommunityHub ? 'text-category' : 'text-body',
              )}
            >
              “{signUpConfig?.testimonialQuote ?? DEFAULT_TESTIMONIAL_QUOTE}”
            </div>
            <div className="flex flex-row items-center justify-start">
              <div className="mr-3">
                {signUpConfig?.testimonialAvatarMedia?.url ? (
                  <img
                    className="h-[50px] w-[50px] rounded-full"
                    src={
                      addOptimizerParams(
                        signUpConfig?.testimonialAvatarMedia?.url,
                        { height: 50 },
                      )!
                    }
                    alt={signUpConfig?.testimonialName ?? 'Testimonial avatar'}
                  />
                ) : (
                  <div className="h-[50px] w-[50px] shrink bg-[url('assets/images/signup/julie-avatar.png')] bg-cover" />
                )}
              </div>
              <div
                className={classNames(
                  'flex flex-col items-start justify-center text-caption',
                  isCommunityHub ? 'text-white' : 'text-green-150',
                )}
              >
                <div className="font-bold">
                  {signUpConfig?.testimonialName ?? DEFAULT_TESTIMONIAL_NAME}
                </div>
                <div>
                  {signUpConfig?.testimonialTitle ?? DEFAULT_TESTIMONIAL_TITLE}
                </div>
              </div>
            </div>
          </div>
          {isCommunityHub && (
            <div
              className={classNames(
                'my-8 ml-6 h-full w-[calc(100%-24px)] bg-cover',
              )}
              style={
                communityScreenshotMediaUrl
                  ? { backgroundImage: `url(${communityScreenshotMediaUrl})` }
                  : {}
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
