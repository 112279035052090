import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { toast } from 'react-hot-toast';

import {
  PatientLifecycleStatus,
  ProgramOnboardingStatus,
  FeedType,
  Feature,
  usePatientQuery,
  useLatestCompletedProgramAssessmentsQuery,
  LatestCompletedProgramAssessmentsQuery,
} from '../../../generated/graphql';

import { defaultEnterTransitionProps } from '../../lib/animation';
import Spinner from '../../svgs/Spinner';
import ToastAlert from '../../components/ToastAlert';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';

// TODO move to components
import InviteToProgramModal from '../PatientManagement/Modals/InviteToProgramModal';
import DeactivatePatientModal from '../PatientManagement/Modals/DeactivatePatientModal';

import PageContainer from '../../components/Containers/PageContainer';
import MainContainer from '../../components/Containers/MainContainer';
import AssignAssessmentModal from '../../components/Modals/AssignAssessmentModal';
import FirstAppointmentDateModal from '../PatientManagement/Modals/FirstAppointmentDateModal';
import AssessmentsScheduleSlideover from './Slideovers/AssessmentsScheduleSlideover';
import AssessmentsCompletedSlideover from './Slideovers/AssessmentsCompletedSlideover';
import FeedSlideover from './Slideovers/FeedSlideover';
import ActivityFeeds from './FeedPanels';
import ResendInvitePanel from './ResendInvitePanel';
import ProfilePanel from './ProfilePanel';
import LatestAssessmentCards from './LatestAssessmentCards';
import { SelectedProgramAssessment } from './helpers';
import Button from '../../components/Button';
import ArrowRightLong from '../../svgs/ArrowRightLong';

const PatientProfile = () => {
  const params = useParams();
  const programId = params.programId || '';

  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsEnabled = isFeatureEnabled(Feature.Assessments);
  const isAssessmentAssignmentEnabled = isFeatureEnabled(
    Feature.AssessmentAssignment,
  );

  const [isInviteToProgramModalOpen, setIsInviteToProgramModalOpen] =
    useState(false);
  const [isFirstAppointmentDateModalOpen, setIsFirstAppointmentDateModalOpen] =
    useState(false);
  const [isDeactivatePatientModalOpen, setIsDeactivatePatientModalOpen] =
    useState(false);
  const [isAssignAssessmentModalOpen, setIsAssignAssessmentModalOpen] =
    useState(false);

  const {
    data: patientData,
    error: patientError,
    loading: patientLoading,
    refetch: refetchPatient,
  } = usePatientQuery({
    variables: {
      programId,
    },
  });

  const {
    data: latestCompletedProgramAssessmentsData,
    error: latestCompletedProgramAssessmentsError,
    loading: latestCompletedProgramAssessmentsLoading,
  } = useLatestCompletedProgramAssessmentsQuery({
    variables: {
      programId,
    },
  });

  useEffect(() => {
    if (patientError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch client."
          level="error"
        />
      ));
    }
  }, [patientError]);

  useEffect(() => {
    if (latestCompletedProgramAssessmentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch latest assessments."
          level="error"
        />
      ));
    }
  }, [latestCompletedProgramAssessmentsError]);

  const patient = patientData?.patient || null;
  const latestCompletedProgramAssessments =
    latestCompletedProgramAssessmentsData?.latestCompletedProgramAssessments as LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'];

  const [isFeedSlideoverOpen, setIsFeedSlideoverOpen] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState<FeedType | null>(null);

  const [
    isAssessmentsScheduleSlideoverOpen,
    setIsAssessmentsScheduleSlideoverOpen,
  ] = useState(false);
  const [
    isAssessmentsCompletedSlideoverOpen,
    setIsAssessmentsCompletedSlideoverOpen,
  ] = useState(false);
  const [selectedProgramAssessment, setSelectedProgramAssessment] = useState<
    SelectedProgramAssessment | undefined
  >();

  const isInvitedProfile =
    patient?.onboardingStatus === ProgramOnboardingStatus.ProgramInviteSent;
  const isActiveProfile =
    patient?.onboardingStatus === ProgramOnboardingStatus.OnboardingStarted ||
    patient?.onboardingStatus === ProgramOnboardingStatus.OnboardingCompleted;

  if (patient && !isInvitedProfile && !isActiveProfile) {
    return <Navigate to="/v1/clients" replace />;
  }

  return (
    <>
      <PageContainer noPadding>
        <Transition
          show={Boolean(
            patient &&
              !patientError &&
              latestCompletedProgramAssessments &&
              !latestCompletedProgramAssessmentsError,
          )}
          {...defaultEnterTransitionProps}
        >
          <ProfilePanel
            patient={patient}
            setIsFirstAppointmentDateModalOpen={
              setIsFirstAppointmentDateModalOpen
            }
            setIsDeactivatePatientModalOpen={setIsDeactivatePatientModalOpen}
            setIsAssignAssessmentModalOpen={setIsAssignAssessmentModalOpen}
            isAssessmentsEnabled={isAssessmentsEnabled}
            isActiveProfile={isActiveProfile}
          />
          <MainContainer>
            {isActiveProfile && (
              <>
                <ActivityFeeds
                  patient={patient}
                  setIsFeedSlideoverOpen={setIsFeedSlideoverOpen}
                  setSelectedFeed={setSelectedFeed}
                />
                {isAssessmentsEnabled && (
                  <div className="flex flex-col items-start justify-start sm:items-center">
                    <div className="flex justify-start sm:w-[340px] sm:flex-col xl:w-full xl:flex-row xl:items-center">
                      <span className="font-serif text-subtitle-small text-green-150 xl:mr-5">
                        Assessments
                      </span>
                      <Button
                        title={`See ${patient.firstName}'s schedule`}
                        theme="secondary"
                        size="small"
                        onClick={() =>
                          setIsAssessmentsScheduleSlideoverOpen(true)
                        }
                        className="mt-1 text-caption font-medium hover:underline sm:mb-1 sm:justify-start xl:mb-0"
                        IconComponent={ArrowRightLong}
                        iconClassName="text-green-100"
                        noOutline
                        noBackground
                      />
                    </div>
                    {!Boolean(latestCompletedProgramAssessments?.length) && (
                      <div className="mt-6 sm:w-[340px] xl:w-full">
                        <span className="text-extra-small text-neutral-125">
                          {patient?.firstName}'s completed assessments will
                          appear here...
                        </span>
                      </div>
                    )}
                    {Boolean(latestCompletedProgramAssessments?.length) && (
                      <LatestAssessmentCards
                        latestCompletedProgramAssessments={
                          latestCompletedProgramAssessments
                        }
                        setSelectedProgramAssessment={
                          setSelectedProgramAssessment
                        }
                        setIsAssessmentsCompletedSlideoverOpen={
                          setIsAssessmentsCompletedSlideoverOpen
                        }
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {isInvitedProfile && (
              <div className="flex flex-col items-center justify-start">
                <ResendInvitePanel
                  openInviteToProgramModal={() => {
                    setIsInviteToProgramModalOpen(true);
                  }}
                />
              </div>
            )}
          </MainContainer>
        </Transition>
        {(patientLoading || latestCompletedProgramAssessmentsLoading) && (
          <Spinner className={'mx-auto mt-8'} />
        )}
      </PageContainer>

      {patient && (
        <>
          {isInvitedProfile && (
            <InviteToProgramModal
              isSinglePatient
              isModalOpen={isInviteToProgramModalOpen}
              singlePatient={patient}
              patientLifecycleStatus={PatientLifecycleStatus.Invited}
              selectedPatientsList={[]}
              setClosed={() => setIsInviteToProgramModalOpen(false)}
              refetchPatient={refetchPatient}
            />
          )}
          <DeactivatePatientModal
            isSinglePatient
            singlePatient={patient}
            isModalOpen={isDeactivatePatientModalOpen}
            patientsObject={null}
            setClosed={() => setIsDeactivatePatientModalOpen(false)}
          />
          {isAssessmentsEnabled && (
            <FirstAppointmentDateModal
              patient={patient}
              isModalOpen={isFirstAppointmentDateModalOpen}
              setClosed={() => setIsFirstAppointmentDateModalOpen(false)}
              refetchPatient={refetchPatient}
            />
          )}
        </>
      )}
      {isActiveProfile && (
        <>
          <FeedSlideover
            isOpen={isFeedSlideoverOpen}
            onClose={() => setIsFeedSlideoverOpen(false)}
            programId={patient.programInstanceId}
            selectedFeed={selectedFeed}
            checkInsCount={patient?.checkInData?.totalCheckIns ?? 0}
            activitiesCount={patient?.tasksData?.activitiesCount ?? 0}
          />
          {isAssessmentsEnabled && (
            <>
              <AssessmentsScheduleSlideover
                onClose={() => setIsAssessmentsScheduleSlideoverOpen(false)}
                isOpen={isAssessmentsScheduleSlideoverOpen}
                programId={patient.programInstanceId}
                patientFirstName={`${patient?.firstName}`}
              />
              <AssessmentsCompletedSlideover
                onClose={() => setIsAssessmentsCompletedSlideoverOpen(false)}
                isOpen={isAssessmentsCompletedSlideoverOpen}
                title={selectedProgramAssessment?.shortName ?? ''}
                subtitle={`${patient?.firstName}'s ${selectedProgramAssessment?.name}`}
                patientFirstName={`${patient?.firstName}`}
                programId={patient.programInstanceId}
                selectedProgramAssessmentAssessmentId={
                  selectedProgramAssessment?.id
                }
                selectedProgramAssessmentType={selectedProgramAssessment?.type}
              />
              {isAssessmentAssignmentEnabled && (
                <AssignAssessmentModal
                  programId={programId}
                  isModalOpen={isAssignAssessmentModalOpen}
                  setClosed={() => setIsAssignAssessmentModalOpen(false)}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PatientProfile;
