import { FC, useState } from 'react';

import { CalendlyEventDataFragment } from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';

type CalendlyEventTypeCardProps = {
  calendlyEventType: CalendlyEventDataFragment;
  setSchedulingUrlAndInitCalendly: () => void;
};

const CalendlyEventTypeCard: FC<CalendlyEventTypeCardProps> = ({
  calendlyEventType,
  setSchedulingUrlAndInitCalendly,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="mb-4 flex w-full flex-col items-center justify-center"
      onClick={() => {
        setLoading(true);
        setSchedulingUrlAndInitCalendly();
      }}
    >
      <div className="flex w-full cursor-pointer flex-row items-center justify-between rounded-lg rounded-t-none border border-neutral-50 p-5 shadow-100 hover:bg-neutral-25">
        <div className="flex flex-col items-start justify-center">
          <div className="flex flex-row items-center justify-start">
            <div
              style={{ backgroundColor: calendlyEventType.color }}
              className="mr-3 h-4 w-4 rounded-full"
            />
            <div className="text-category font-medium">
              {calendlyEventType.name}
            </div>
          </div>
          {calendlyEventType.description && (
            <div className="mt-2 text-body">
              {calendlyEventType.description}
            </div>
          )}
        </div>
        {loading && <Spinner className="h-6 w-6" />}
      </div>
    </div>
  );
};

export default CalendlyEventTypeCard;
