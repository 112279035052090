import { $generateHtmlFromNodes } from '@lexical/html';
import classNames from 'classnames';
import { LexicalEditor } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  Feature,
  ProgramTemplateDocument,
  Task,
  TaskAudioData,
  useCreateProgramTaskMutation,
  useMeProviderQuery,
  useProgramTaskQuery,
  useUpdateMediaContentMutation,
  useUpdateMediaFileMutation,
  useUpdateProgramTaskMutation,
} from '../../../generated/graphql';

import Editor from '../../components/Editor';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';

import { Maybe } from 'graphql/jsutils/Maybe';
import Button from '../../components/Button';
import AudioUploadArea from '../../components/Editor/AudioUploadArea';
import ImageUploadArea from '../../components/Editor/ImageUploadArea';
import ErrorMessage from '../../components/ErrorMessage';
import PageContainer from '../../components/Containers/PageContainer';

const NEWLINE_REGEX = /(\r|\n|\t)+/g;
const BLACKLISTED_HTML_TAGS = ['html', 'body'];

function isEmpty(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}

// TODO: remove this once the sanitise HTML stuff has been implemented
function normalizeHtml(htmlStr: string) {
  let normStr = htmlStr;
  BLACKLISTED_HTML_TAGS.forEach((tag) => {
    normStr = normStr
      .replaceAll(`<${tag}>`, '')
      .replaceAll(`</${tag}>`, '')
      .trim();
  });
  return normStr.replace(NEWLINE_REGEX, '');
}

function getAudioDataFromTask(task: Maybe<Task>): Maybe<TaskAudioData> {
  if (!task) {
    return null;
  }

  const { media, content } = task;

  if (
    media?.audioMediaId &&
    media?.audioUrl &&
    content?.title &&
    content?.audioFileName &&
    content?.audioDuration
  ) {
    return {
      mediaId: media?.audioMediaId,
      url: media?.audioUrl,
      label: content?.title,
      fileName: content?.audioFileName,
      duration: content?.audioDuration,
    };
  }
}

interface EditSaveButtonProps {
  isReadOnly: boolean;
  isEditable: boolean;
  onClick: () => void;
}

const EditSaveButton: React.FC<EditSaveButtonProps> = ({
  isEditable,
  isReadOnly,
  onClick,
}) => {
  if (!isEditable) {
    return null;
  }

  const title = isReadOnly ? 'Edit article' : 'Save article';
  return <Button onClick={onClick} title={title} />;
};

export default function ProgramTask() {
  const navigate = useNavigate();
  const params = useParams();
  const taskId = params.taskId || '';
  const programTemplateId = params.programTemplateId || '';
  const [searchParams] = useSearchParams();
  const taskGroupId = searchParams.get('group-id');
  const weightInGroup = searchParams.get('weight-in-group')
    ? parseInt(searchParams.get('weight-in-group')!, 10)
    : 0;

  const isNewTask = taskId === 'new';
  // Open in edit mode for new tasks
  const [isReadOnly, setIsReadOnly] = useState(!isNewTask);
  const [contentHtml, setContentHtml] = useState('');
  const [selectedImage, setSelectedImage] = useState<File>();
  const [title, setTitle] = useState('');
  const [editorError, setEditorError] = useState<Error>();
  const [audioData, setAudioData] = useState<Maybe<TaskAudioData>>(null);

  const editorRef = useRef<LexicalEditor | null>(null);

  const { isFeatureEnabled } = useFeatureFlags();
  const isCreateEditingProgramTaskEnabled = isFeatureEnabled(
    Feature.ArticleCreationEditing,
  );

  const refetchProgramTemplateOptions = {
    refetchQueries: [
      {
        query: ProgramTemplateDocument,
        variables: { programTemplateId },
      },
    ],
  };
  const [updateProgramTaskMutation] = useUpdateProgramTaskMutation(
    refetchProgramTemplateOptions,
  );
  const [updateMediaContentMutation] = useUpdateMediaContentMutation();
  const [updateMediaFileMutation] = useUpdateMediaFileMutation(
    refetchProgramTemplateOptions,
  );
  const [createProgramTaskMutation] = useCreateProgramTaskMutation(
    refetchProgramTemplateOptions,
  );

  const { data: taskData, refetch: refetchProgramTask } = useProgramTaskQuery({
    variables: {
      programTaskId: taskId,
    },
    skip: isNewTask,
  });

  const task = taskData?.programTask;

  const { data } = useMeProviderQuery();
  const providerInfo = data?.meProvider;

  // Only allow editing if the task was created by the current provider and the feature is enabled
  const isEditable =
    isCreateEditingProgramTaskEnabled &&
    !!!editorError &&
    (isNewTask || task?.author === providerInfo?.provider?.name);

  const fetchHtml = useCallback(async () => {
    if (!task?.media?.htmlUrl) return;
    const htmlUrl = new URL(task.media?.htmlUrl);

    // Because we're fetching the HTML from the CDN, we need to explicitly include a
    // cache-busting query parameter so that we get the latest version
    const now = Date.now();
    htmlUrl.searchParams.append('hc-cache-bust', now.toString());

    const htmlFetch = await fetch(htmlUrl.toString());
    const html = await htmlFetch.text();
    const normalizedHtml = normalizeHtml(html);
    setContentHtml(normalizedHtml);
  }, [task]);

  // Fetch the HTML content of the task and set the audio data once task has been fetched
  useEffect(() => {
    if (!task || isEmpty(task)) return;
    setTitle(task.title || '');
    setAudioData(getAudioDataFromTask(task));
    fetchHtml();
  }, [task]);

  const updateContentHtml = async (newContentHtml: string) => {
    if (!task?.media?.htmlMediaId) return;
    await updateMediaContentMutation({
      variables: {
        mediaId: task?.media?.htmlMediaId,
        content: newContentHtml,
      },
    });
  };

  const exportEditorHtml = () =>
    new Promise<string | null>((resolve) => {
      const editor = editorRef.current;
      if (!editor) return resolve(null);
      editor.update(() => {
        // Null for selection to get the HTML for all the content not just the selection
        resolve($generateHtmlFromNodes(editor, null));
      });
    });

  const updateTask = async () => {
    if (selectedImage) {
      // Upload selected image
      await updateMediaFileMutation({
        variables: {
          mediaId: task?.media?.imageMediaId || '',
          file: selectedImage,
        },
      });
    }

    // Update task data
    await updateProgramTaskMutation({
      variables: {
        programTaskId: task?.id || '',
        input: {
          title,
          audioData,
        },
      },
    });

    const html = await exportEditorHtml();
    if (html) {
      await updateContentHtml(html);
    }

    // Do async
    refetchProgramTask();
  };

  const createTask = async (
    title: string,
    image: File,
    content: string,
    audioData: Maybe<TaskAudioData>,
  ) => {
    const createProgramTaskResult = await createProgramTaskMutation({
      variables: {
        programTemplateId,
        input: {
          title,
          image,
          content,
          taskGroupId,
          audioData,
          weightInGroup,
        },
      },
    });
    const newProgramTaskId = createProgramTaskResult?.data?.createProgramTask;
    if (!newProgramTaskId)
      return alert('Failed to create article. Please try again.');
    // Navigate to the newly created task
    navigate(`/programs/${programTemplateId}/task/${newProgramTaskId}`);
  };

  const getImageUrl = () => {
    if (selectedImage) {
      return URL.createObjectURL(selectedImage);
    }

    return task?.media?.imageUrl;
  };

  return (
    <PageContainer containerClassName="items-center">
      <div className="mt-4 flex w-full justify-end px-12">
        <EditSaveButton
          isReadOnly={isReadOnly}
          isEditable={isEditable}
          onClick={async () => {
            const isSaving = isReadOnly === false;
            if (isSaving) {
              if (isNewTask) {
                const content = await exportEditorHtml();
                if (!title || !selectedImage || !content) return;
                await createTask(title, selectedImage, content, audioData);
              } else {
                await updateTask();
              }
            }
            setIsReadOnly(!isReadOnly);
            // Reset selected image
            setSelectedImage(undefined);
          }}
        />
      </div>
      <div className="mt-8 flex h-full w-3/5 max-w-3xl flex-col">
        <div className="mb-4">
          <div className="mb-4 text-center font-serif text-green-150">
            {!isReadOnly ? (
              <>
                <input
                  type="text"
                  className="peer block w-full rounded-md border border-white text-center text-subtitle shadow-sm placeholder:text-neutral-100 hover:border-green-100 focus:border-green-100 focus:ring-green-100 peer-invalid:border-red-125"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <div className="mt-2 hidden justify-center peer-invalid:flex">
                  <ErrorMessage className="font-sans">
                    Please enter a title
                  </ErrorMessage>
                </div>
              </>
            ) : (
              <div className="text-subtitle">{title}</div>
            )}
          </div>
        </div>

        <div className="relative flex-1 px-4 sm:px-6">
          <div className="mb-6">
            <ImageUploadArea
              imageUrl={getImageUrl()}
              isReadOnly={isReadOnly}
              onRemove={() => setSelectedImage(undefined)}
              inputId="image-upload"
              onChange={(event) => {
                const file = event.target.files?.[0];
                if (!file) return;
                setSelectedImage(file);
              }}
            />
          </div>
          <div className="mb-6">
            <AudioUploadArea
              audioData={audioData}
              onSuccess={setAudioData}
              onRemove={() => setAudioData(null)}
              isReadOnly={isReadOnly}
            />
          </div>
          <div className={classNames({ hidden: editorError })}>
            <Editor
              innerRef={editorRef}
              readOnly={isReadOnly}
              initialContentHtml={contentHtml}
              onError={(error: Error) => {
                console.error(error);
                setEditorError(error);
              }}
            />
          </div>
          {!!editorError && (
            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: contentHtml }}
            />
          )}
        </div>
      </div>
    </PageContainer>
  );
}
