import { SvgIconComponent } from '../types/svgs';

const ThumbnailImage: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <rect width="48" height="48" rx="8" fill="#FAC5BD" />
      <path
        d="M16 28L20.586 23.414C20.9611 23.0391 21.4697 22.8284 22 22.8284C22.5303 22.8284 23.0389 23.0391 23.414 23.414L28 28M26 26L27.586 24.414C27.9611 24.0391 28.4697 23.8284 29 23.8284C29.5303 23.8284 30.0389 24.0391 30.414 24.414L32 26M26 20H26.01M18 32H30C30.5304 32 31.0391 31.7893 31.4142 31.4142C31.7893 31.0391 32 30.5304 32 30V18C32 17.4696 31.7893 16.9609 31.4142 16.5858C31.0391 16.2107 30.5304 16 30 16H18C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18V30C16 30.5304 16.2107 31.0391 16.5858 31.4142C16.9609 31.7893 17.4696 32 18 32Z"
        stroke="#E23213"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailImage;
