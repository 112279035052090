import { FC } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  MicrophoneIcon,
  ChatIcon,
  PaperClipIcon,
} from '@heroicons/react/outline';

import { PatientCardFollowUpDataFragment } from '../../../generated/graphql';

import { getTimeStamp } from '../../lib/time';
import { Duration } from 'luxon';

const PatientMailCard: FC<{
  programFollowUp?: PatientCardFollowUpDataFragment;
  isLatestFollowUp?: boolean;
}> = ({ programFollowUp, isLatestFollowUp = false }) => {
  const navigate = useNavigate();

  /** Returns a duration in seconds in MSS format. */
  function durationMSS(numSeconds: number): string {
    return Duration.fromMillis(numSeconds * 1000).toFormat('m:ss');
  }

  return (
    <div
      className={classNames(
        'flex h-[152px] w-full cursor-pointer flex-col items-start justify-start rounded-lg p-6 shadow-100 hover:bg-neutral-25 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50 md:w-[354px]',
        isLatestFollowUp ? 'border-t-[12px] border-t-blue-50' : 'pt-9',
      )}
      onClick={() => navigate(`/client/follow-ups/${programFollowUp.id}`)}
    >
      <div className="flex flex-col items-start justify-start">
        <div className="text-category font-bold text-neutral-150">
          {`${getTimeStamp(
            programFollowUp?.createdAt as string,
            false,
            false,
            false,
            true,
          )}`}
        </div>
        <div className="min my-2 text-caption text-neutral-125 line-clamp-1">
          {programFollowUp?.providerNote}
        </div>
        <div className="mt-2 flex flex-row items-start justify-start">
          {programFollowUp.providerVoiceNote && (
            <div className="mr-2 flex flex-row items-center justify-center rounded-full bg-blue-25 py-1 px-2 text-blue-150">
              <MicrophoneIcon className="mr-1 h-4 w-4 text-blue-150" />
              <div className="text-small-caption font-medium">
                {durationMSS(programFollowUp.providerVoiceNote.duration)}
              </div>
            </div>
          )}
          {Boolean(programFollowUp.programActivities.length) && (
            <div className="mr-2 flex flex-row items-center justify-center rounded-full bg-green-25 py-1 px-2 text-green-150">
              <PaperClipIcon className="mr-1 h-4 w-4 text-green-150" />
              <div className="text-small-caption font-medium">
                {programFollowUp.programActivities.length}
              </div>
            </div>
          )}
          {Boolean(programFollowUp.commentCount) && (
            <div className="mr-2 flex flex-row items-center justify-center rounded-full bg-neutral-50 py-1 px-2 text-neutral-150">
              <ChatIcon className="mr-1 h-4 w-4 text-neutral-150" />
              <div className="text-small-caption font-medium">
                {programFollowUp.commentCount}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientMailCard;
