import { SvgIconComponent } from '../types/svgs';

const Bolt: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g id="bolt">
        <path
          id="Vector"
          d="M2.5 9L9.5 1.5L8 7H13.5L6.5 14.5L8 9H2.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Bolt;
