import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useProgramAssessmentScheduleQuery } from '../../../../../generated/graphql';

import ToastAlert from '../../../../components/ToastAlert';
import Slideover from '../../../../components/Slideover';
import SlideoverSection from '../../../../components/SlideoverSection';

import Pills from './Pills';
import ScheduleItem from './ScheduleItem';

export enum PillOptions {
  Scheduled = 'Scheduled',
  Delivered = 'Delivered',
}

export const pillList = [PillOptions.Scheduled, PillOptions.Delivered];

const AssessmentsScheduleSlideover: FC<{
  isOpen: boolean;
  programId: string;
  patientFirstName: string;
  onClose: () => void;
}> = ({ isOpen, programId, patientFirstName, onClose }) => {
  const {
    data: programAssessmentScheduleData,
    error: programAssessmentScheduleError,
    loading: isLoadingProgramAssessmentSchedule,
  } = useProgramAssessmentScheduleQuery({
    variables: {
      programId,
    },
  });

  useEffect(() => {
    if (programAssessmentScheduleError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch assessment schedule."
          level="error"
        />
      ));
    }
  }, [programAssessmentScheduleError]);

  const scheduledProgramAssessments =
    programAssessmentScheduleData?.programAssessmentSchedule
      ?.scheduledProgramAssessments;
  let deliveredProgramAssessments =
    programAssessmentScheduleData?.programAssessmentSchedule
      ?.deliveredProgramAssessments;

  const notifiedAt = deliveredProgramAssessments?.find(
    (deliveredProgramAssessment) => {
      return deliveredProgramAssessment.notifiedAt;
    },
  )?.notifiedAt;

  if (notifiedAt) {
    deliveredProgramAssessments = deliveredProgramAssessments?.map(
      (deliveredProgramAssessment) => {
        if (
          !deliveredProgramAssessment.completedAt &&
          !deliveredProgramAssessment.dismissedAt &&
          !deliveredProgramAssessment.hasExpired
        ) {
          return {
            ...deliveredProgramAssessment,
            notifiedAt,
          };
        } else {
          return deliveredProgramAssessment;
        }
      },
    );
  }

  const [activePill, setActivePill] = useState(PillOptions.Scheduled);

  return (
    <Slideover
      onClose={onClose}
      isOpen={isOpen}
      title={`${patientFirstName}'s assessments`}
      containerClassName="pb-44"
      titleComponent={
        <Pills
          activePill={activePill}
          setActivePill={setActivePill}
          containerClassName="mt-3"
        />
      }
      showContent={
        !isLoadingProgramAssessmentSchedule &&
        !programAssessmentScheduleError &&
        Boolean(programAssessmentScheduleData)
      }
    >
      {activePill === PillOptions.Scheduled && (
        <>
          {Boolean(scheduledProgramAssessments?.length) ? (
            <SlideoverSection>
              {scheduledProgramAssessments?.map(
                (
                  scheduledProgramAssessment,
                  scheduledProgramAssessmentIndex,
                ) => (
                  <ScheduleItem
                    key={scheduledProgramAssessmentIndex}
                    shortName={scheduledProgramAssessment.assessment.shortName}
                    startDate={scheduledProgramAssessment.startDate}
                    isFirstAssessment={scheduledProgramAssessmentIndex === 0}
                    isLastAssessment={
                      scheduledProgramAssessmentIndex ===
                      scheduledProgramAssessments.length - 1
                    }
                  />
                ),
              )}
            </SlideoverSection>
          ) : (
            <span className="text-extra-small text-neutral-125">
              No scheduled assessments to show...
            </span>
          )}
        </>
      )}
      {activePill === PillOptions.Delivered && (
        <>
          {Boolean(deliveredProgramAssessments?.length) ? (
            <SlideoverSection>
              {deliveredProgramAssessments?.map(
                (
                  deliveredProgramAssessment,
                  deliveredProgramAssessmentIndex,
                ) => (
                  <ScheduleItem
                    key={deliveredProgramAssessmentIndex}
                    shortName={deliveredProgramAssessment.assessment.shortName}
                    startDate={deliveredProgramAssessment.startDate}
                    completedAt={deliveredProgramAssessment.completedAt}
                    notifiedAt={deliveredProgramAssessment.notifiedAt}
                    dismissedAt={deliveredProgramAssessment.dismissedAt}
                    isFirstAssessment={deliveredProgramAssessmentIndex === 0}
                    isLastAssessment={
                      (deliveredProgramAssessments &&
                        deliveredProgramAssessments?.length - 1 ===
                          deliveredProgramAssessmentIndex) ||
                      false
                    }
                  />
                ),
              )}
            </SlideoverSection>
          ) : (
            <span className="text-extra-small text-neutral-125">
              No delivered assessments to show...
            </span>
          )}
        </>
      )}
    </Slideover>
  );
};

export default AssessmentsScheduleSlideover;
