import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  useCompleteStorefrontCalendlyBookingMutation,
  useCompleteStorefrontIntakeMutation,
  useIntakePackageQuery,
} from '../../../../generated/graphql';
import ToastAlert from '../../../components/ToastAlert';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import { useNavigate } from 'react-router-dom';
import ProgramAssessmentForm from '../PatientFollowUpDetails/ProgramAssessmentForm';
import PageContainer from '../../../components/Containers/PageContainer';
import UnauthedHeader from '../../../components/Headers/UnauthedHeader';
import useCalendly from '../../../hooks/useCalendly';
import classNames from 'classnames';

enum IntakeStepType {
  IntakeForm = 'IntakeForm',
  DiscoveryCall = 'DiscoveryCall',
}
interface IntakeStep {
  stepType: IntakeStepType;
  completedAt: Date | null;
}

interface IntakeState {
  stepsLoaded: boolean;
  steps: IntakeStep[];
  currentStep: IntakeStepType | null;
}

enum IntakeActionType {
  SetSteps = 'SetSteps',
  CompleteStep = 'CompleteStep',
}

type IntakeAction =
  | { type: IntakeActionType.SetSteps; steps: IntakeStep[] }
  | { type: IntakeActionType.CompleteStep; step: IntakeStepType };

const intakeReducer = (
  state: IntakeState,
  action: IntakeAction,
): IntakeState => {
  switch (action.type) {
    case IntakeActionType.SetSteps:
      // Set currentStep to the first incomplete step
      const currentStep = action.steps.find((step) => !step.completedAt);
      return {
        ...state,
        steps: action.steps,
        currentStep: currentStep?.stepType,
        stepsLoaded: true,
      };
    case IntakeActionType.CompleteStep:
      // Find the next step if there is one
      const currentStepIndex = state.steps.findIndex(
        (step) => step.stepType === action.step,
      );
      const nextStep =
        currentStepIndex !== -1 && currentStepIndex < state.steps.length - 1
          ? state.steps[currentStepIndex + 1]
          : null;
      return {
        ...state,
        // Complete the step in front-end state optimistically
        // (a call to update on the server should be called before this)
        steps: state.steps.map((step) =>
          step.stepType === action.step
            ? { ...step, completedAt: new Date() }
            : step,
        ),
        currentStep: nextStep?.stepType || null,
      };
    default:
      return state;
  }
};

const PatientIntake = () => {
  const navigate = useNavigate();
  const { authedPatient } = usePatientAuth();

  const { data: intakePackageData, loading: intakePackageLoading } =
    useIntakePackageQuery({
      onError: (error) => {
        console.log('Error fetching intake package:', error);
        navigate('/client', { replace: true });
      },
    });

  const [completedStorefrontCalendlyBookingMutation] =
    useCompleteStorefrontCalendlyBookingMutation();

  const [completeStorefrontIntakeMutation] =
    useCompleteStorefrontIntakeMutation();

  useEffect(() => {
    if (intakePackageData) {
      const intakePackage = intakePackageData.intakePackage;
      dispatch({
        type: IntakeActionType.SetSteps,
        steps: [
          ...(intakePackage.calendlyEventType
            ? [
                {
                  stepType: IntakeStepType.DiscoveryCall,
                  completedAt:
                    intakePackage.completedStorefrontCalendlyBookingAt,
                },
              ]
            : []),
          ...(intakePackage.intakeFormProgramActivity
            ? [
                {
                  stepType: IntakeStepType.IntakeForm,
                  completedAt:
                    intakePackage.intakeFormProgramActivity.completedAt,
                },
              ]
            : []),
        ],
      });
    }
  }, [intakePackageData]);

  const intakePackage = intakePackageData?.intakePackage;

  const [intakeState, dispatch] = useReducer(intakeReducer, {
    steps: [],
    currentStep: null,
    stepsLoaded: false,
  });

  const intakeStepsComplete =
    intakeState.stepsLoaded &&
    intakeState.steps.every((step) => step.completedAt !== null);

  const redirectToStorefrontOnComplete = (intakeSuccess: boolean) => {
    navigate(`/p/${intakePackage?.storefrontSlug}`, {
      state: { fromIntakeSuccess: intakeSuccess },
      replace: true,
    });
  };

  const calendlyEmbedRef = useRef<HTMLDivElement>(null);
  const [hasCalendlyWidgetLoaded, setHasCalendlyWidgetLoaded] = useState(false);

  const onCalendlyLoaded = useCallback(async () => {
    setHasCalendlyWidgetLoaded(true);
  }, []);

  const onCalendlyBooked = useCallback(async () => {
    await completedStorefrontCalendlyBookingMutation();
    return onIntakeStepComplete(IntakeStepType.DiscoveryCall);
  }, []);

  const { initCalendlyWidget } = useCalendly(
    calendlyEmbedRef,
    onCalendlyLoaded,
    onCalendlyBooked,
  );

  // Main controller for the side effects that need to happen on step completion.
  // Keeping all of them in one effect makes it easier to reason about the control logic.
  useEffect(() => {
    const handleStepSideEffects = async () => {
      // Only run side effects if the intake package, steps, and patient are loaded
      if (intakePackage && intakeState.stepsLoaded && authedPatient) {
        if (authedPatient.completedStorefrontIntakeAt) {
          // If patient has already completed intake, redirect to client app
          redirectToStorefrontOnComplete(true);
        } else if (intakeStepsComplete) {
          // If all steps are complete, complete the intake on the server
          try {
            await completeStorefrontIntakeMutation();
            redirectToStorefrontOnComplete(true);
          } catch (error) {
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message="Failed to complete intake."
                level="error"
              />
            ));
          }
        } else if (
          intakeState.currentStep === IntakeStepType.DiscoveryCall &&
          intakePackage.calendlyEventType
        ) {
          // If there is a calendly event, render Calendly widget
          initCalendlyWidget(intakePackage.calendlyEventType.schedulingUrl, {
            name: authedPatient.name,
            email: authedPatient.email,
          });
        }
      }
    };

    handleStepSideEffects();
  }, [intakePackage, intakeState, authedPatient]);

  const onIntakeStepComplete = async (intakeStepType: IntakeStepType) => {
    dispatch({
      type: IntakeActionType.CompleteStep,
      step: intakeStepType,
    });
  };

  return (
    <>
      <UnauthedHeader />
      <PageContainer
        noPadding
        containerClassName="pt-16"
        loading={!intakePackageData || intakePackageLoading}
      >
        {intakeState.currentStep === IntakeStepType.DiscoveryCall && (
          <>
            <div
              className={classNames(
                'h-[calc(100vh-var(--top-nav-height))] w-full',
                !hasCalendlyWidgetLoaded && 'pt-64', // This pushes the Calendly-loading spinner down
              )}
              ref={calendlyEmbedRef}
            />
          </>
        )}
        {intakeState.currentStep === IntakeStepType.IntakeForm &&
          intakePackage.intakeFormProgramActivity && (
            <ProgramAssessmentForm
              programActivity={intakePackage.intakeFormProgramActivity}
              onComplete={() => {
                onIntakeStepComplete(IntakeStepType.IntakeForm);
              }}
            />
          )}
      </PageContainer>
    </>
  );
};

export default PatientIntake;
