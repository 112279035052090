import { FC } from 'react';
import {
  ActivityType,
  ProgramActivityDataFragment,
  SignatureUser,
  UserType,
} from '../../generated/graphql';
import classNames from 'classnames';

export enum ProgramActivityStatusOption {
  Completed = 'Completed',
  Delivered = 'Delivered',
  Draft = 'Draft',
  Seen = 'Seen',
  Signed = 'Signed',
  Submitted = 'Submitted',
  Todo = 'To do',
  PendingSignature = 'Pending',
}

const programActivityStatusBgColorMap = {
  [ProgramActivityStatusOption.Completed]: 'bg-green-25',
  [ProgramActivityStatusOption.Delivered]: 'bg-neutral-50',
  [ProgramActivityStatusOption.Draft]: 'bg-neutral-50',
  [ProgramActivityStatusOption.Seen]: 'bg-blue-25',
  [ProgramActivityStatusOption.Signed]: 'bg-green-25',
  [ProgramActivityStatusOption.Submitted]: 'bg-green-25',
  [ProgramActivityStatusOption.Todo]: 'bg-orange-25',
  [ProgramActivityStatusOption.PendingSignature]: 'bg-orange-25',
};

export const getProgramActivityStatus = (
  programActivity: ProgramActivityDataFragment,
  userType: UserType = UserType.ProviderUser,
): ProgramActivityStatusOption | null => {
  let programActivityStatus;
  // Patient status order: Todo -> (null if dismissed) OR Completed/Signed/Submitted
  // ProviderUser status order: Delivered -> Seen -> Completed/Signed/Submitted

  if (programActivity.isDraft) {
    programActivityStatus = ProgramActivityStatusOption.Draft;
  } else if (programActivity.completedAt) {
    if (programActivity.activityType === ActivityType.PdfForm) {
      programActivityStatus = ProgramActivityStatusOption.Submitted;
    } else if (programActivity.activityType === ActivityType.Esign) {
      programActivityStatus = ProgramActivityStatusOption.Signed;
    } else {
      programActivityStatus = ProgramActivityStatusOption.Completed;
    }
  } else if (programActivity.isCompletable) {
    if (programActivity.dismissedAt) {
      if (userType === UserType.ProviderUser) {
        programActivityStatus = ProgramActivityStatusOption.Seen;
      } else {
        // Dismissals remove status for clients
        programActivityStatus = null;
      }
    } else if (programActivity.seenAt) {
      if (userType === UserType.ProviderUser) {
        programActivityStatus = ProgramActivityStatusOption.Seen;
      } else {
        // Don't expose "Seen" status to clients
        programActivityStatus = ProgramActivityStatusOption.Todo;
      }
    } else {
      if (userType === UserType.ProviderUser) {
        programActivityStatus = ProgramActivityStatusOption.Delivered;
      } else {
        programActivityStatus = ProgramActivityStatusOption.Todo;
      }
    }
  } else {
    // Deprecated: non-completable "Resource". Starting 2/6, all resources are completable.
    programActivityStatus = ProgramActivityStatusOption.Delivered;
  }

  if (
    programActivity.programAssessment &&
    programActivity.programAssessment.programAssessmentSignatureUsers.length > 0
  ) {
    const { programAssessmentSignatureUsers, currentSigner } =
      programActivity.programAssessment;

    const allSigned = programAssessmentSignatureUsers.every(
      (user) => user.signedAt !== null,
    );

    const currentSignerIsPatient =
      currentSigner?.assessmentSignatureUser.signatureUserType ===
      SignatureUser.PatientUser;

    if (allSigned) {
      programActivityStatus = ProgramActivityStatusOption.Completed;
    } else if (currentSignerIsPatient) {
      programActivityStatus = ProgramActivityStatusOption.Todo;
    } else {
      programActivityStatus = ProgramActivityStatusOption.PendingSignature;
    }
  }

  return programActivityStatus;
};

export const ProgramActivityStatus: FC<{
  programActivity: ProgramActivityDataFragment;
  className?: string;
  userType?: UserType;
}> = ({ programActivity, className, userType = UserType.ProviderUser }) => {
  const programActivityStatus = getProgramActivityStatus(
    programActivity,
    userType,
  );

  return (
    programActivityStatus && (
      <div
        className={classNames(
          'inline-flex items-center justify-center rounded-full px-2 py-0.5 text-caption text-neutral-150',
          programActivityStatusBgColorMap[programActivityStatus],
          className,
        )}
      >
        {programActivityStatus}
      </div>
    )
  );
};
