export const HELP_CENTER_URL =
  'https://homecoming-health.notion.site/Homecoming-for-Practitioners-ee7686ed7f73426bb5faa43f2fe426e8';

export const RELEASE_NOTES_URL =
  'https://homecoming-health.notion.site/What-s-new-9c25819e2a2e47afb3635c10173418f8';

export const DATA_USE_URL = 'https://www.homecoming.health/data-use-policy';
export const TERMS_OF_SERVICE_URL =
  'https://www.homecoming.health/terms-of-service';
export const PRIVACY_POLICY_URL =
  'https://www.homecoming.health/privacy-policy';

export const CALENDLY_GET_STARTED_URL =
  'https://calendly.com/d/zzd-w89-gwc/homecoming-getting-started';

export const CALENDLY_ENTERPRISE_PLAN_URL =
  'https://calendly.com/yuriy-homecoming1/enterprise-plan-discovery';

export const DEMO_CLIENT_NOTION_URL =
  'https://homecoming-health.notion.site/Set-up-your-demo-client-f0aef6e6dd104d52b2bccec403f016f3';

export const HOMEPAGE_URL = 'https://homecoming.health';
export const BOOK_A_CALL_URL =
  'https://calendly.com/homecoming-onboarding/onboarding-call?back=1&month=2023-10';

export const DEFAULT_FREE_CLIENT_COUNT = 2;
