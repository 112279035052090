import classNames from 'classnames';
import { FC } from 'react';
import { useSignUpDeepLinkQuery } from '../../generated/graphql';
import { CommonAnalyticsEvent, trackPatientEvent } from '../../lib/analytics';

interface AppStoreLinksProps {
  centered?: boolean;
  fullWidth?: boolean;
}

const DEFAULT_APP_STORE_URL =
  'https://apps.apple.com/us/app/homecoming-companion/id1574364465?itsct=apps_box_badge&amp;itscg=30200';

const DEFAULT_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.axismundilabs.homecoming&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

const AppStoreLinks: FC<AppStoreLinksProps> = ({
  centered = true,
  fullWidth = true,
}) => {
  const conditionalClasses = {
    'justify-start': !centered,
    'justify-center': centered,
    'flex-1': fullWidth,
  };

  const { data: signUpDeepLink } = useSignUpDeepLinkQuery();

  const trackDownloadClick = () => {
    trackPatientEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: 'Download App',
    });
  };

  return (
    <div className="my-5 flex flex-row gap-x-3">
      <div className={classNames('flex flex-row', conditionalClasses)}>
        <a
          href={signUpDeepLink?.signUpDeepLink ?? DEFAULT_APP_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackDownloadClick}
        >
          <img
            className="h-[50px]"
            src="http://cdn.mcauto-images-production.sendgrid.net/b13f9cfe9f8142b5/a5756242-f478-416c-94ca-f123901a8b93/593x192.png"
            alt="Download Homecoming the App Store"
          />
        </a>
      </div>
      <div className={classNames('flex flex-row', conditionalClasses)}>
        <a
          href={signUpDeepLink?.signUpDeepLink ?? DEFAULT_PLAY_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackDownloadClick}
        >
          <img
            className="h-[50px]"
            alt="Download Homecoming on Google Play"
            src="http://cdn.mcauto-images-production.sendgrid.net/b13f9cfe9f8142b5/3e4f2f8c-6060-4a14-9284-b4c87588b064/594x192.png"
          />
        </a>
      </div>
    </div>
  );
};

export default AppStoreLinks;
