import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import {
  ProviderCollection,
  useDeleteProviderCollectionMutation,
} from '../../../../generated/graphql';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import ToastAlert from '../../ToastAlert';

type ConfirmDeleteProviderCollectionModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  selectedProviderCollection: ProviderCollection | undefined;
  onDeleted?: () => Promise<unknown>;
  setSelectedProviderCollection: React.Dispatch<
    React.SetStateAction<ProviderCollection | undefined>
  >;
};

const ConfirmDeleteProviderCollectionModal: FC<
  ConfirmDeleteProviderCollectionModalProps
> = ({
  isModalOpen,
  setClosed,
  selectedProviderCollection,
  onDeleted,
  setSelectedProviderCollection,
}) => {
  const [deleteProviderCollectionMutation] =
    useDeleteProviderCollectionMutation();

  const [isDeletingProviderCollection, setIsDeletingProviderCollection] =
    useState(false);

  const deleteProviderCollection = async () => {
    if (!selectedProviderCollection) return;

    try {
      setIsDeletingProviderCollection(true);

      await deleteProviderCollectionMutation({
        variables: {
          providerCollectionId: selectedProviderCollection?.id,
        },
      });

      setIsDeletingProviderCollection(false);
      setClosed();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="success"
          message={`Successfully deleted ${selectedProviderCollection.name}.`}
        />
      ));
      await onDeleted?.();
      setSelectedProviderCollection(undefined);
    } catch (error) {
      setIsDeletingProviderCollection(false);
      setClosed();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Unable to delete ${selectedProviderCollection.name}.`}
          level="error"
        />
      ));
    }
  };

  return (
    <Modal isOpen={isModalOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to delete this collection?
          </div>
          <div className="text-center text-body text-green-125">
            The collection will disappear but you will still be able to access
            all associated resources.
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title="Delete"
            theme="primary"
            onClick={deleteProviderCollection}
            disabled={isDeletingProviderCollection}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteProviderCollectionModal;
