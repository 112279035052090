import React from 'react';
import classNames from 'classnames';

const SignatureInputField: React.FC<{
  isSignatureUserInQuestion: boolean;
  placeholder: string;
  isRoleField?: boolean;
}> = ({ isSignatureUserInQuestion, placeholder, isRoleField = false }) => {
  return (
    <input
      className={classNames(
        'block w-full resize-none rounded border-2 border-transparent px-4 py-2 text-body focus:outline-none focus:ring-0',
        isSignatureUserInQuestion
          ? 'bg-neutral-50/70 placeholder:text-neutral-125/40 read-only:text-neutral-125/40'
          : 'bg-neutral-25 placeholder:text-neutral-100 read-only:text-neutral-100',
        isRoleField && 'mt-3',
      )}
      disabled
      placeholder={placeholder}
    />
  );
};

export default SignatureInputField;
