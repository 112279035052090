import { SvgIconComponent } from '../types/svgs';

const ArrowRightLong: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11H2V13H18.17Z" />
    </svg>
  );
};

export default ArrowRightLong;
