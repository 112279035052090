import { Transition as HistoryTransition } from 'history';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import { MicrophoneIcon, TrashIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import {
  CommentDataFragment,
  PatientDataFragment,
  useAddCommentMutation,
} from '../../../generated/graphql';
import useBlocker from '../../hooks/useBlocker';
import { resizeTextArea } from '../../lib/form';
import Avatar from '../Avatar';
import Button from '../Button';
import IconButton from '../IconButton';
import RecordVoiceNoteModal from '../Modals/RecordVoiceNoteModal';
import UnsavedChangesModal from '../Modals/UnsavedChangesModal';
import TextAreaGroup from '../TextAreaGroup';
import ToastAlert from '../ToastAlert';
import ConversationContainer from './ConversationContainer';
import ImageUploadButton, { MediaInfo } from './ImageUploadButton';

interface CommentForm {
  comment: string;
}

interface ChatProps {
  patient: PatientDataFragment;
  comments: CommentDataFragment[];
  chatHeader?: string | null;
  programFollowUpId?: string;
  onCommentAdded?: (commentData?: CommentDataFragment) => void;
  onCommentDeleted?: (commentId?: string) => void;
  getNextChunkOfComments?: () => Promise<void>;
  areCommentsLoading?: boolean;
  className?: string;
}

const Chat: FC<ChatProps> = ({
  patient,
  comments,
  chatHeader = 'Conversation',
  programFollowUpId,
  onCommentAdded,
  onCommentDeleted,
  getNextChunkOfComments,
  areCommentsLoading,
  className,
}) => {
  const [isRecordVoiceNoteModalOpen, setIsRecordVoiceNoteModalOpen] =
    useState(false);

  const commentRef = useRef<HTMLTextAreaElement | null>(null);
  const commentRefRegistered = Boolean(commentRef.current);

  const [attachedImageMedia, setAttachedImageMedia] =
    useState<MediaInfo | null>(null);

  useEffect(() => {
    resizeTextArea(commentRef);
  }, [commentRefRegistered]);

  const [addCommentMutation, { loading: addCommentMutationLoading }] =
    useAddCommentMutation();

  const {
    reset: resetForm,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useForm<CommentForm>({
    mode: 'onSubmit',
    defaultValues: {
      comment: '',
    },
  });

  const { ref: commentRegisterRef, ...commentRegister } = register('comment', {
    required: {
      value: true,
      message: 'Please enter a reply',
    },
    onChange: () => resizeTextArea(commentRef),
    onBlur: () => {
      setValue('comment', watchComment.trim());
      resizeTextArea(commentRef);
    },
  });

  const watchComment = watch('comment');

  const hasUnsavedChanges = Boolean(isDirty);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const [navTransition, setNavTransition] = useState<HistoryTransition>();

  const shouldTriggerUnsavedChangesModal = Boolean(
    hasUnsavedChanges && !addCommentMutationLoading,
  );

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [shouldTriggerUnsavedChangesModal],
  );

  useBlocker(blocker, shouldTriggerUnsavedChangesModal);

  const sendComment = async (
    text: string | undefined,
    voiceNoteId: string | undefined,
    imageMediaId: string | undefined,
  ) => {
    text = text?.trim();

    if (!(text || voiceNoteId)) {
      return;
    }

    try {
      const { data } = await addCommentMutation({
        variables: {
          input: {
            ...(programFollowUpId
              ? { programFollowUpId }
              : { programId: patient.programInstanceId }),
            ...(voiceNoteId ? { voiceNoteId } : { text }),
            imageMediaId,
          },
        },
      });

      const newComment = data?.addComment;

      if (newComment) {
        onCommentAdded?.(newComment);
      }
      resetForm();

      // Wait for the form to reset
      setTimeout(() => {
        resizeTextArea(commentRef);
        setAttachedImageMedia(null);
      }, 200);

      if (data?.addComment) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully sent message!`}
          />
        ));
      }
    } catch (err) {
      console.error('Errors submitting:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong - try again later."
          level="warning"
        />
      ));
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSubmit((formData) =>
          sendComment(formData.comment, undefined, attachedImageMedia?.id),
        )();
      }
    },
    [handleSubmit, attachedImageMedia],
  );

  return (
    <>
      <div className="relative flex h-full w-full flex-grow flex-col px-5 py-6">
        {chatHeader && (
          <div className="mb-4 text-category font-medium">{chatHeader}</div>
        )}

        <ConversationContainer
          patientFirstName={patient.firstName ?? ''}
          comments={comments}
          onCommentDeleted={onCommentDeleted}
          fullSizeEmptyState={!programFollowUpId}
          getNextChunkOfComments={getNextChunkOfComments}
          areCommentsLoading={areCommentsLoading}
          className={className}
        />

        {/* Chat input row */}
        <div className="flex w-full flex-row items-center gap-x-3">
          <Avatar size="medium" />

          <div className="min-h-12 group relative flex w-full flex-row items-end justify-between rounded-xl bg-neutral-50 focus-within:ring-2 focus-within:ring-green-100">
            <div className="flex w-full flex-col">
              {attachedImageMedia && (
                <div className="relative w-full p-4">
                  <img
                    className="max-h-[100px]"
                    src={attachedImageMedia.url}
                    alt="Attachment"
                  />
                  <TrashIcon
                    className={
                      'absolute top-2 left-0 ml-2 h-6 w-6 cursor-pointer rounded-full bg-white p-1 text-red-100 hover:bg-red-100 hover:text-white'
                    }
                    onClick={() => setAttachedImageMedia(null)}
                  />
                </div>
              )}
              <TextAreaGroup
                label="Comment"
                placeholder={`Write a message to ${patient.firstName}...`}
                labelHidden
                autoFocus
                backgroundHidden
                rows={1}
                inputSize="small"
                containerClassName="w-full"
                className="w-full rounded-xl !border-transparent !text-caption"
                value={watchComment}
                {...commentRegister}
                ref={(event) => {
                  commentRegisterRef(event);
                  commentRef.current = event;
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
            {watchComment && (
              <Button
                title="Post"
                theme="none"
                type="submit"
                size="small"
                className="h-12 pr-5 font-bold text-neutral-150 disabled:text-neutral-100"
                disabled={!isDirty || addCommentMutationLoading}
                noBackground
                noOutline
                noFocus
                onClick={handleSubmit((formData) =>
                  sendComment(
                    formData.comment,
                    undefined,
                    attachedImageMedia?.id,
                  ),
                )}
              />
            )}
          </div>

          <ImageUploadButton onSuccess={setAttachedImageMedia} />

          {!watchComment && (
            <IconButton
              aria-label="Record voice note"
              className="h-10 w-10 text-neutral-125"
              IconComponent={MicrophoneIcon}
              onClick={() => setIsRecordVoiceNoteModalOpen(true)}
            />
          )}
        </div>
      </div>

      <RecordVoiceNoteModal
        isModalOpen={isRecordVoiceNoteModalOpen}
        setClosed={() => setIsRecordVoiceNoteModalOpen(false)}
        onPost={(voiceNoteId) =>
          sendComment(undefined, voiceNoteId, attachedImageMedia?.id)
        }
      />
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => {
          navTransition?.retry();
          resetForm();
          setIsUnsavedChangesModalOpen(false);
        }}
      />
    </>
  );
};

export default Chat;
