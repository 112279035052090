import Button from '../components/Button';
import { useAuth } from '../../contexts/AuthContext';
import {
  ProgramTemplateStatus,
  useProgramTemplatesQuery,
} from '../../generated/graphql';
import { OnboardingPageProps, OnboardingStepName } from '../types/onboarding';
import { markOnboardingStepCompleted } from '../lib/storage';
import Spinner from '../svgs/Spinner';
import ArrowRightLong from '../svgs/ArrowRightLong';

export default function OnboardingPrograms({
  stepIndex,
  steps,
  goToNextStep,
}: OnboardingPageProps) {
  const auth = useAuth();
  const providerUserEmail = auth.authedProviderUser!.email;

  const { data: programsData, loading } = useProgramTemplatesQuery({});

  const programs =
    (programsData?.programTemplates &&
      programsData?.programTemplates.filter(
        (program) => program.status === ProgramTemplateStatus.Active,
      )) ??
    [];

  const hasPrograms = programs.length > 0;

  const markProgramsCompleteAndGoToNextStep = () => {
    markOnboardingStepCompleted(providerUserEmail, OnboardingStepName.Programs);
    goToNextStep(stepIndex);
  };

  return loading ? (
    <div className="flex h-screen items-center justify-center">
      <Spinner className="h-8 w-8" />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center py-8">
      <h1 className="mb-3 text-subtitle text-green-150">
        {hasPrograms && 'Your programs are ready to go!'}
        {!hasPrograms && 'You need to set up a program'}
      </h1>
      <p className="text-base mb-6 text-green-125">
        {hasPrograms && 'Here are the programs we’ve already set up'}
        {!hasPrograms && 'Please contact us to set up a program with us'}
      </p>
      <div className="mt-5 mb-5 flex space-x-6">
        {hasPrograms &&
          programs.map((program) => (
            <div
              key={program.id}
              className="flex h-[500px] w-[406px] flex-col justify-center rounded-xl bg-[url('assets/images/program-card-bg.jpeg')] bg-cover shadow-400"
            >
              <div className="flex h-[346px] w-[406px] flex-col content-between items-center justify-center bg-white">
                <div className="rounded-full bg-blue-25 px-3 py-1 text-small-caption text-blue-150">
                  {program.programLengthInMonths} months
                </div>
                <div className="my-6 text-center font-serif text-subtitle text-green-100">
                  {program.name}
                </div>
                <div>Ready for clients</div>
              </div>
            </div>
          ))}
      </div>
      <Button
        className="mt-5"
        title="Next"
        onClick={markProgramsCompleteAndGoToNextStep}
        IconComponent={ArrowRightLong}
      />
    </div>
  );
}
