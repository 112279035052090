import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ToastAlert from '../../components/ToastAlert';
import PageContainer from '../../components/Containers/PageContainer';
import {
  EssentialAssessmentDataFragment,
  useProviderStorefrontLazyQuery,
  useUpdateProviderStorefrontMutation,
} from '../../../generated/graphql';
import TopBanner from './TopBanner';
import {
  CalendarIcon,
  DocumentTextIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';
import WebpageModuleContainer from './WebpageModuleContainer';
import Tag from '../../svgs/Tag';
import InformationModule from './Modules/InformationModule';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import TagModule from './Modules/TagModule';
import { StagedProgramTagDataFragment } from '../../components/ProgramTag/ProgramTagManager';
import IntakeFormModule from './Modules/IntakeFormModule';
import DiscoveryCallModule from './Modules/DiscoveryCallModule';

export interface StorefrontFormData {
  isEnabled: boolean;
  roleTitle: string;
  tagline: string;
  about: string;
  services: string;
  intakeTags: StagedProgramTagDataFragment[];
  intakeFormAssessment: EssentialAssessmentDataFragment;
  calendlyEventTypeUri: string;
}

export const ROLE_TITLE_MAX_LENGTH = 50;
export const TAGLINE_MAX_LENGTH = 50;
export const ABOUT_MAX_LENGTH = 2000;
export const SERVICES_MAX_LENGTH = 2000;

const MyWebpage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // State to communicate that user is coming back from the form builder, having successfully
  // created a new form to attach, so we open up the intake form module and auto-attach it.
  const intakeFormAssessmentIdToAttach = (
    location.state as { intakeFormAssessmentIdToAttach?: string }
  )?.intakeFormAssessmentIdToAttach;

  const [
    getProviderStorefront,
    {
      data: storefrontData,
      error: storefrontError,
      refetch: refetchStorefront,
    },
  ] = useProviderStorefrontLazyQuery();

  useEffect(() => {
    if (storefrontError) {
      navigate('/home', { replace: true });
    }
  }, [storefrontError]);

  const storefront = storefrontData?.providerStorefront;

  const [updateProviderStorefront] = useUpdateProviderStorefrontMutation();

  const storefrontForm = useForm<StorefrontFormData>({
    mode: 'onSubmit',
    defaultValues: async () => {
      const response = await getProviderStorefront();
      const storefront = response.data?.providerStorefront;
      return {
        isEnabled: storefront?.isEnabled ?? false,
        roleTitle: storefront?.roleTitle ?? '',
        tagline: storefront?.tagline ?? '',
        about: storefront?.about ?? '',
        services: storefront?.services ?? '',
        intakeTags: storefront?.intakeTags ?? [],
        intakeFormAssessment: storefront?.intakeFormAssessment ?? null,
        calendlyEventTypeUri: storefront?.calendlyEventTypeUri ?? null,
      };
    },
  });

  useEffect(() => {
    if (
      intakeFormAssessmentIdToAttach &&
      storefront &&
      intakeFormAssessmentIdToAttach !== storefront.intakeFormAssessment?.id
    ) {
      addIntakeForm(intakeFormAssessmentIdToAttach);
    }
  }, [intakeFormAssessmentIdToAttach, storefront]);

  const onSubmit: SubmitHandler<StorefrontFormData> = async (
    formData: StorefrontFormData,
  ) => {
    try {
      const response = await updateProviderStorefront({
        variables: {
          input: {
            roleTitle: formData.roleTitle,
            tagline: formData.tagline,
            about: formData.about,
            services: formData.services,
          },
        },
      });

      if (response.data?.updateProviderStorefront) {
        const updatedStorefront = response.data.updateProviderStorefront;
        // Resetting vs. setting the form fields to the updated values means that
        // later "resets" when the user discards changes will revert to the updated values,
        // not the originally loaded ones.
        storefrontForm.resetField('roleTitle', {
          defaultValue: updatedStorefront.roleTitle,
        });
        storefrontForm.resetField('tagline', {
          defaultValue: updatedStorefront.tagline,
        });
        storefrontForm.resetField('about', {
          defaultValue: updatedStorefront.about,
        });
        storefrontForm.resetField('services', {
          defaultValue: updatedStorefront.services,
        });
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={'Webpage information updated.'}
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update webpage information."
          level="error"
        />
      ));
    }
  };

  const onErrors: SubmitErrorHandler<StorefrontFormData> = (errors) => {
    console.error(errors);
  };

  const updateStorefrontEnabled = async (isEnabled: boolean) => {
    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            isEnabled,
          },
        },
      });
      if (data) {
        storefrontForm.setValue(
          'isEnabled',
          data.updateProviderStorefront.isEnabled,
        );
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={
              isEnabled
                ? 'Your webpage is now live.'
                : 'Your webpage is now offline.'
            }
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update webpage."
          level="error"
        />
      ));
    }
  };

  const addIntakeForm = async (intakeFormAssessmentId: string) => {
    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            intakeFormAssessmentId,
          },
        },
      });
      if (data) {
        storefrontForm.setValue(
          'intakeFormAssessment',
          data.updateProviderStorefront.intakeFormAssessment,
        );
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message="Client intake form added."
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to add client intake form."
          level="error"
        />
      ));
    }
  };

  const removeIntakeForm = async () => {
    if (storefrontForm.getValues('intakeFormAssessment') == null) {
      // No need to remove the intake form on the server if there already is none
      return;
    }

    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            intakeFormAssessmentId: null,
          },
        },
      });
      if (data) {
        storefrontForm.setValue('intakeFormAssessment', null);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message="Client intake form removed."
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to remove client intake form."
          level="error"
        />
      ));
    }
  };

  const setDiscoveryCallCalendlyEventTypeUri = async (
    calendlyEventTypeUri: string,
  ) => {
    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            calendlyEventTypeUri,
          },
        },
      });
      if (data) {
        storefrontForm.setValue(
          'calendlyEventTypeUri',
          data.updateProviderStorefront.calendlyEventTypeUri,
        );
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message="Discovery call updated."
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update discovery call."
          level="error"
        />
      ));
    }
  };

  const removeDiscoveryCallCalendlyEventTypeUri = async () => {
    if (storefrontForm.getValues('calendlyEventTypeUri') == null) {
      // No need to remove the discovery call on the server if there already is none
      return;
    }

    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            calendlyEventTypeUri: null,
          },
        },
      });
      if (data) {
        storefrontForm.setValue('calendlyEventTypeUri', null);
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message="Discovery call removed."
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to remove discovery call."
          level="error"
        />
      ));
    }
  };

  return (
    <PageContainer containerClassName="pt-6 pb-64 max-w-[1280px] mx-auto">
      {storefront && (
        <FormProvider {...storefrontForm}>
          <TopBanner
            storefrontSlug={storefront.slug}
            updateStorefrontEnabled={updateStorefrontEnabled}
          />
          <div className="mt-6 space-y-6">
            <WebpageModuleContainer
              title="Webpage information"
              Icon={GlobeAltIcon}
              defaultOpen={!Boolean(intakeFormAssessmentIdToAttach)}
            >
              <InformationModule
                onSubmit={onSubmit}
                onErrors={onErrors}
                bannerImageUrl={storefront.bannerImageUrl}
                onBannerImageChange={async () => {
                  await refetchStorefront();
                }}
              />
            </WebpageModuleContainer>
            <WebpageModuleContainer title="Prospect client tag" Icon={Tag}>
              <TagModule />
            </WebpageModuleContainer>
            <WebpageModuleContainer
              title="Client intake form"
              Icon={DocumentTextIcon}
              defaultOpen={Boolean(intakeFormAssessmentIdToAttach)}
            >
              <IntakeFormModule
                addIntakeForm={addIntakeForm}
                removeIntakeForm={removeIntakeForm}
              />
            </WebpageModuleContainer>
            <WebpageModuleContainer
              title="Discovery call settings"
              Icon={CalendarIcon}
            >
              <DiscoveryCallModule
                setDiscoveryCallCalendlyEventTypeUri={
                  setDiscoveryCallCalendlyEventTypeUri
                }
                removeDiscoveryCallCalendlyEventTypeUri={
                  removeDiscoveryCallCalendlyEventTypeUri
                }
              />
            </WebpageModuleContainer>
          </div>
        </FormProvider>
      )}
    </PageContainer>
  );
};

export default MyWebpage;
