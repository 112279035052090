import { Transition } from '@headlessui/react';
import {
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

import { AlertLevel } from '../types/alerts';
import { SvgIconComponent } from '../types/svgs';

const iconComponents: Record<AlertLevel, SvgIconComponent> = {
  error: XCircleIcon,
  warning: ExclamationCircleIcon,
  success: CheckCircleIcon,
  info: ExclamationCircleIcon,
};

type ToastAlertProps = {
  isVisible: boolean;
  className?: string;
  message: React.ReactNode;
  level?: AlertLevel;
  children?: React.ReactNode;
};

const ToastAlert: React.FunctionComponent<ToastAlertProps> = ({
  isVisible,
  className,
  message,
  level = 'success',
  children,
}) => {
  const IconComponent = iconComponents[level];

  return (
    <Transition
      show={isVisible}
      className={classNames(
        'flex rounded-lg bg-white px-6 py-4 text-caption shadow-lg ring-1 ring-black ring-opacity-5',
        level === 'error' && 'text-red-125',
        level === 'warning' && 'text-orange-125',
        level === 'success' && 'text-green-125',
        level === 'info' && 'text-blue-125',
        className,
      )}
      leave="transform ease-in duration-200 transition"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <IconComponent
        className={classNames(
          'h-5 w-5',
          level === 'error' && 'text-red-100',
          level === 'warning' && 'text-orange-100',
          level === 'success' && 'text-green-100',
          level === 'info' && 'text-blue-100',
        )}
        aria-hidden="true"
      />
      <div className="ml-3 text-caption text-neutral-125">
        <h3 className="font-sans font-medium">{message}</h3>
        {children}
      </div>
    </Transition>
  );
};

export default ToastAlert;
