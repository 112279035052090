import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { clearItem, retrieveItem, storeItem } from '../lib/storage';

enum UtmParam {
  Source = 'utm_source',
  Medium = 'utm_medium',
  Campaign = 'utm_campaign',
  Term = 'utm_term',
  Content = 'utm_content',
}

export type UtmParams = Partial<Record<UtmParam, string>>;

const UTM_PARAMS_STORAGE_KEY = 'utmParams';

export default function useUtmTracking(): [UtmParams | undefined, () => void] {
  const [searchParams] = useSearchParams();
  const [utmParams, setUtmParams] = useState<UtmParams | undefined>();

  useEffect(() => {
    const params: UtmParams = {};

    // Build a map of UTM parameters from the URL
    Object.values(UtmParam).forEach((param) => {
      const value = searchParams.get(param);
      if (value !== null) {
        params[param] = value;
      }
    });

    // If we have any UTM parameters in the URL, update localStorage and state
    if (Object.keys(params).length > 0) {
      storeItem(UTM_PARAMS_STORAGE_KEY, params);
      setUtmParams(params);
    } else {
      // Otherwise, check localStorage
      const storedParams = retrieveItem<UtmParams>(UTM_PARAMS_STORAGE_KEY);
      if (storedParams) {
        setUtmParams(storedParams);
      }
    }
  }, []);

  const clearUtmParams = useCallback(() => {
    clearItem(UTM_PARAMS_STORAGE_KEY);
    setUtmParams(undefined);
  }, []);

  return [utmParams, clearUtmParams];
}
