import {
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';

import classNames from 'classnames';

import { AlertLevel } from '../types/alerts';
import { SvgIconComponent } from '../types/svgs';

const iconComponents: Record<AlertLevel, SvgIconComponent> = {
  error: XCircleIcon,
  warning: ExclamationCircleIcon,
  success: CheckCircleIcon,
  info: ExclamationCircleIcon,
};

type AlertProps = {
  className?: string;
  message: string;
  level?: AlertLevel;
  children?: React.ReactNode;
};

const Alert: React.FunctionComponent<AlertProps> = ({
  className,
  message,
  level = 'error',
  children,
}) => {
  const IconComponent = iconComponents[level];

  return (
    <div
      className={classNames(
        'flex rounded-md p-4',
        level === 'error' && 'bg-red-25/30 text-red-125',
        level === 'warning' && 'bg-orange-25/30 text-orange-125',
        level === 'success' && 'bg-green-25/30 text-green-125',
        level === 'info' && 'bg-green-25/30 text-blue-125',
        className,
      )}
    >
      <IconComponent
        className={classNames(
          'h-5 w-5',
          level === 'error' && 'text-red-100',
          level === 'warning' && 'text-orange-100',
          level === 'success' && 'text-green-100',
          level === 'info' && 'text-blue-100',
        )}
        aria-hidden="true"
      />
      <div className="ml-3 text-caption">
        <h3 className="font-sans font-medium">{message}</h3>
        {children}
      </div>
    </div>
  );
};

export default Alert;
