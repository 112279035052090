import { type EffectCallback, useEffect, useRef } from 'react';
import Config from '../../lib/config';
import { Environment } from '../types/config';

/**
 * Ensures an effect is only run once on mount
 * Workaround for useEffect being called twice in development using React strict mode.
 * src: https://github.com/facebook/react/issues/24502#issuecomment-1132877723
 * @param effect effect callback
 */
export const useMount =
  Config.REACT_APP_ENVIRONMENT === Environment.Development
    ? (effect: EffectCallback) => {
        const mounted = useRef(false);

        useEffect(() => {
          if (mounted.current) {
            return effect();
          }

          mounted.current = true;

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          return () => {};
        }, [mounted, effect]);
      }
    : useEffect;
