import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ProgramTemplateStatus } from '../../generated/graphql';
import Button from './Button';
import ProgramStatus from './ProgramStatus';

const ProgramItem: React.FC<{
  programTemplateId: string;
  programTemplateName?: string;
  programTemplateStatus: ProgramTemplateStatus;
  isEmpty?: boolean;
  hasCloseX?: boolean;
  disableClick?: boolean;
  disableStatus?: boolean;
  className?: string;
  onClick?: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const {
    programTemplateId,
    programTemplateName,
    programTemplateStatus,
    isEmpty,
    disableClick = false,
    disableStatus = false,
    hasCloseX = false,
    className,
    onClick = () => {
      navigate(`/programs/${programTemplateId}`);
    },
  } = props;

  if (isEmpty) {
    return (
      <span className="block truncate text-caption font-normal text-green-150">
        No program assigned
      </span>
    );
  }

  let iconProps = {};
  if (hasCloseX) {
    iconProps = {
      IconComponent: XIcon,
      iconClassName: 'h-[12px] w-[12px] text-blue-150 hover:text-blue-150/70',
      iconPosition: 'right',
    };
  }
  return (
    <div className="flex flex-row items-center justify-start">
      <Button
        theme="secondary"
        size="small"
        {...iconProps}
        title={programTemplateName ?? ''}
        disabled={disableClick}
        className={classNames(
          'mr-3 h-[30px] bg-blue-25 text-caption font-normal text-blue-150',
          'disabled:cursor-pointer disabled:bg-blue-25 disabled:text-caption',
          'disabled:font-normal disabled:text-blue-150',
          'hover:bg-blue-50',
          className,
        )}
        onClick={(event) => {
          onClick();
          if (!disableClick) event.stopPropagation();
        }}
      />
      {!disableStatus &&
        programTemplateStatus === ProgramTemplateStatus.InDevelopment && (
          <ProgramStatus status={programTemplateStatus} />
        )}
    </div>
  );
};

export default ProgramItem;
