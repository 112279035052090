import { FC } from 'react';
import {
  AssessmentType,
  LatestCompletedProgramAssessmentsQuery,
} from '../../../../generated/graphql';
import { AssessmentCompletionDataTypename } from '../../../lib/assessments';
import { getTimeStamp } from '../../../lib/date';
import { SelectedProgramAssessment } from '../helpers';
import LatestAssessmentCard from './LatestAssessmentCard';

const LatestAssessmentCards: FC<{
  latestCompletedProgramAssessments: LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'];
  setSelectedProgramAssessment: React.Dispatch<
    React.SetStateAction<SelectedProgramAssessment | undefined>
  >;
  setIsAssessmentsCompletedSlideoverOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  latestCompletedProgramAssessments,
  setSelectedProgramAssessment,
  setIsAssessmentsCompletedSlideoverOpen,
}) => {
  return (
    <div className="mt-3 flex flex-wrap gap-7 sm:w-[340px] xl:w-full">
      {latestCompletedProgramAssessments?.map(
        (latestCompletedProgramAssessment) => {
          const {
            id,
            completedAt,
            assessment: {
              id: assessmentId,
              type,
              name,
              shortName,
              description,
              scoreRanges,
              increasingScoreTowardsHealth,
            },
          } = latestCompletedProgramAssessment;

          if (
            latestCompletedProgramAssessment?.completionData?.__typename ===
            AssessmentCompletionDataTypename.MultipleChoiceSumScoredAssessmentCompletionData
          ) {
            const {
              completionData: { score, color, categorization },
            } = latestCompletedProgramAssessment;

            let scoreDifference;

            if (
              latestCompletedProgramAssessment
                ?.previouslyCompletedProgramAssessmentOfSameType?.completionData
                ?.__typename ===
              AssessmentCompletionDataTypename.MultipleChoiceSumScoredAssessmentCompletionData
            ) {
              scoreDifference =
                score -
                latestCompletedProgramAssessment
                  .previouslyCompletedProgramAssessmentOfSameType
                  ?.completionData.score;
            }

            return (
              <LatestAssessmentCard
                key={id}
                shortName={shortName}
                color={color ?? 'neutral'}
                increasingScoreTowardsHealth={
                  increasingScoreTowardsHealth || false
                }
                completedDate={getTimeStamp(completedAt, false)}
                primaryScore={{
                  score,
                  scoreDifference,
                  categorization,
                }}
                onClick={() => {
                  setSelectedProgramAssessment({
                    id: assessmentId,
                    type,
                    name,
                    shortName,
                    description,
                    scoreRanges,
                  });
                  setIsAssessmentsCompletedSlideoverOpen(true);
                }}
                hasPercentage={type === AssessmentType.Meq30}
              />
            );
          }

          if (
            latestCompletedProgramAssessment?.completionData?.__typename ===
            AssessmentCompletionDataTypename.HADSAssessmentCompletionData
          ) {
            const {
              completionData: { subscores },
            } = latestCompletedProgramAssessment;

            const anxietyCompletionData = subscores[0];
            let anxietyScoreDifference;

            const depressionCompletionData = subscores[1];
            let depressionScoreDifference;

            if (
              latestCompletedProgramAssessment
                ?.previouslyCompletedProgramAssessmentOfSameType?.completionData
                ?.__typename ===
              AssessmentCompletionDataTypename.HADSAssessmentCompletionData
            ) {
              anxietyScoreDifference =
                anxietyCompletionData.score -
                latestCompletedProgramAssessment
                  ?.previouslyCompletedProgramAssessmentOfSameType
                  ?.completionData.subscores[0].score;
              depressionScoreDifference =
                depressionCompletionData.score -
                latestCompletedProgramAssessment
                  ?.previouslyCompletedProgramAssessmentOfSameType
                  ?.completionData.subscores[1].score;
            }

            return (
              <LatestAssessmentCard
                key={id}
                shortName={shortName}
                completedDate={getTimeStamp(completedAt, false)}
                color={'neutral'}
                increasingScoreTowardsHealth={
                  increasingScoreTowardsHealth || false
                }
                primaryScore={{
                  score: anxietyCompletionData.score,
                  scoreDifference: anxietyScoreDifference,
                  categorization: anxietyCompletionData.categorization,
                }}
                secondaryScore={{
                  score: depressionCompletionData.score,
                  scoreDifference: depressionScoreDifference,
                  categorization: depressionCompletionData.categorization,
                }}
                onClick={() => {
                  setSelectedProgramAssessment({
                    id: assessmentId,
                    type,
                    name,
                    shortName,
                    description,
                    scoreRanges,
                  });
                  setIsAssessmentsCompletedSlideoverOpen(true);
                }}
              />
            );
          }
          return null;
        },
      )}
    </div>
  );
};

export default LatestAssessmentCards;
