import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  useRef,
  useReducer,
} from 'react';
import {
  Outlet,
  useOutletContext,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import {
  Exact,
  PatientsCount,
  usePatientsV2Query,
  usePatientsCountV2Query,
  PatientLifecycleStatusV2,
  ProgramTagDataFragment,
  MeProviderProgramTagsQuery,
  useReactivatePatientsMutation,
  useMeProviderProgramTagsWithCountQuery,
  PatientTableDataFragment,
  ProviderUserRole,
  PatientDataPermission,
  usePatientsV2LazyQuery,
  useMarkProgramSeenMutation,
} from '../../../generated/graphql';

import { ColumnOrder } from '../../types/tables';

import { ArchiveIcon, PlusSmIcon, XIcon } from '@heroicons/react/outline';
import Search from '../../svgs/Search';
import CloseX from '../../svgs/CloseX';

import Button from '../../components/Button';
import InputGroup from '../../components/InputGroup';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import PageContainer from '../../components/Containers/PageContainer';

import AddPatientsModal from '../../components/Modals/AddPatientsModal';
import ResendInvitesModal from '../../components/Modals/ResendInvitesModal';

import {
  sortOptions,
  PatientsObject,
  getPatientsList,
  getPatientsObject,
  columnOrderToProgramSortOrder,
  getRestrictedPatientsList,
} from './helpers';
import { DEFAULT_TRANSITION_DURATION } from '../../lib/animation';
import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';
import { useAuth } from '../../../contexts/AuthContext';
import { AnalyticsPage } from '../../../lib/analytics';
import SelectMenu from '../../components/SelectMenu';
import { ApolloQueryResult } from '@apollo/client';
import ToastAlert from '../../components/ToastAlert';
import toast from 'react-hot-toast';
import Tag from '../../svgs/Tag';
import TagManagerModal from '../../components/Modals/TagManagerModal';
import Spinner from '../../svgs/Spinner';
import { PATIENT_MANAGEMENT_COLUMN_ORDER } from '../../lib/storage';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import TrialEndDeactivatePatientsModal from './TrialEndDeactivatePatientsModal';
import omit from 'lodash.omit';
import UpgradeModal from '../../components/Modals/UpgradeModal';

type TableContext = {
  nextStepsButtonsDisabled: boolean;
  patientLifecycleStatus: PatientLifecycleStatusV2;
  hasPatientsList: boolean;
  patientsCount: PatientsCount;
  patientOrder: ColumnOrder;
  tableLoading: boolean;
  patientsList: PatientTableDataFragment[] | null;
  restrictedPatientsList: PatientTableDataFragment[] | null;
  clearFilters: () => void;
  handleSortOrderChange: (newSortOrder: ColumnOrder) => void;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientTableDataFragment | null>
  >;
  addPatients: () => void;
  openAssignToProgramModal: () => void;
  openResendInvitesModal: () => void;
  setIsSendAssessmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openFirstAppointmentDateModal: () => void;
  providerProgramTags: ProgramTagDataFragment[];
  refetchMeProviderProgramTags: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<MeProviderProgramTagsQuery>>;
  handleProgramTagsUpdate: (
    programId: string,
    tags: ProgramTagDataFragment[],
  ) => void;
  handleUnarchiveClick: (programId: string) => Promise<void>;
  handleAddStorefrontClient: (programId: string) => Promise<void>;
};

export function useTableContext() {
  return useOutletContext<TableContext>();
}

export type PatientManagementState = {
  searchTerm: string;
  tagFilter: ProgramTagDataFragment | null;
  sortOrder: ColumnOrder;
  currentPage: number;
};

const initialPatientManagementState: PatientManagementState = {
  searchTerm: '',
  tagFilter: null,
  sortOrder: sortOptions.latestUnreadThenAlphabetical,
  currentPage: 1,
};

export enum PatientManagementActionType {
  SetSearchTerm = 'SetSearchTerm',
  SetTagFilter = 'SetTagFilter',
  SetSortOrder = 'SetSortOrder',
  ResetPage = 'ResetPage',
  IncrementPage = 'IncrementPage',
}

type PatientManagementAction =
  | { type: PatientManagementActionType.SetSearchTerm; payload: string }
  | {
      type: PatientManagementActionType.SetTagFilter;
      payload: ProgramTagDataFragment | null;
    }
  | {
      type: PatientManagementActionType.SetSortOrder;
      payload: ColumnOrder;
    }
  | {
      type: PatientManagementActionType.ResetPage;
      payload?: Partial<PatientManagementState>;
    }
  | { type: PatientManagementActionType.IncrementPage };

const patientManagementReducer = (
  state: PatientManagementState,
  action: PatientManagementAction,
): PatientManagementState => {
  switch (action.type) {
    case PatientManagementActionType.SetSearchTerm:
      return {
        ...state,
        searchTerm: action.payload,
        currentPage: 1,
      };
    case PatientManagementActionType.SetTagFilter:
      return {
        ...state,
        tagFilter: action.payload,
        currentPage: 1,
      };
    case PatientManagementActionType.SetSortOrder:
      return {
        ...state,
        sortOrder: action.payload,
        currentPage: 1,
      };
    case PatientManagementActionType.ResetPage:
      return {
        ...state,
        searchTerm: '',
        tagFilter: null,
        sortOrder: sortOptions.latestUnreadThenAlphabetical,
        currentPage: 1,
        ...(action.payload || {}),
      };
    case PatientManagementActionType.IncrementPage:
      return { ...state, currentPage: state.currentPage + 1 };
    default:
      return state;
  }
};

const TagFilterOption: FC<{
  value: ProgramTagDataFragment;
}> = ({ value }) => <div className="truncate">{value.name}</div>;

export const PATIENTS_ACTIVE_BASE_PATH = '/clients/active';
export const PATIENTS_ARCHIVED_BASE_PATH = '/clients/archived';

export const PATIENT_PAGE_SIZE = 20;
export const PAGINATION_DEBOUNCE_DELAY = 500;
export const INFINITE_SCROLL_LOADER_MARGIN = '96px';

const PatientManagement = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    authedProviderUser,
    willHitClientLimit,
    refreshAuthedProviderUserSubscriptionState,
  } = useAuth();
  const { showUpgradeModal } = useGlobalModalsContext();

  const noPatientDataPermission =
    authedProviderUser.patientDataPermission === PatientDataPermission.None;

  const { data: patientsCount, refetch: refetchPatientsCount } =
    usePatientsCountV2Query();

  const [reactivatePatientsMutation] = useReactivatePatientsMutation();
  const [markProgramSeenMutation] = useMarkProgramSeenMutation();

  // Provider-level Program Tags (for filter menu)

  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsWithCountQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const hasProviderProgramTags = providerProgramTags.length > 0;

  const [isAddPatientsModalOpen, setIsAddPatientsModalOpen] = useState(false);
  const [isResendInvitesModalOpen, setIsResendInvitesModalOpen] =
    useState(false);
  const [isSendAssessmentModalOpen, setIsSendAssessmentModalOpen] =
    useState(false);
  const [isTagManagerModalOpen, setIsTagManagerModalOpen] = useState(false);
  const [isBlockingUpgradeModalOpen, setIsBlockingUpgradeModalOpen] =
    useState(false);
  const [
    isTrialEndDeactivatePatientsModalOpen,
    setIsTrialEndDeactivatePatientsModalOpen,
  ] = useState(false);

  // Enforces client downgrade flow
  useEffect(() => {
    if (willHitClientLimit(0)) {
      // Client limit is surpassed
      setIsBlockingUpgradeModalOpen(true);
    } else {
      setIsBlockingUpgradeModalOpen(false);
    }
  }, [willHitClientLimit]);

  // cleanup function for lingering single patient state
  useEffect(() => {
    if (!isResendInvitesModalOpen && !isSendAssessmentModalOpen) {
      setTimeout(() => {
        setSinglePatient(null);
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isResendInvitesModalOpen, isSendAssessmentModalOpen]);

  // Patient lifecycle status / routing

  let patientLifecycleStatus: PatientLifecycleStatusV2 | null = null;
  if (pathname.includes(PATIENTS_ACTIVE_BASE_PATH)) {
    patientLifecycleStatus = PatientLifecycleStatusV2.Active;
  } else if (pathname.includes(PATIENTS_ARCHIVED_BASE_PATH)) {
    patientLifecycleStatus = PatientLifecycleStatusV2.Archived;
  }

  const isInActiveMode =
    patientLifecycleStatus === PatientLifecycleStatusV2.Active;
  const isInArchivedMode =
    patientLifecycleStatus === PatientLifecycleStatusV2.Archived;

  useEffect(() => {
    // Refetching here ensures we have the latest count when we switch lifecycle status
    // tabs or the Clients page is loaded on nav click.
    refetchPatientsCount();
  }, [patientLifecycleStatus]);

  // Search, filters, ordering

  const [storedColumnOrder, setStoredColumnOrder] =
    useLocalStorageValue<ColumnOrder>(
      PATIENT_MANAGEMENT_COLUMN_ORDER,
      authedProviderUser.id,
    );

  const [patientManagementState, dispatch] = useReducer(
    patientManagementReducer,
    {
      ...initialPatientManagementState,
      ...(storedColumnOrder && { sortOrder: storedColumnOrder }),
    },
  );

  const { searchTerm, tagFilter, sortOrder, currentPage } =
    patientManagementState;

  const [searchTermValue, setSearchTermValue] = useState('');
  const debouncedSearch = useCallback(
    debounce(
      (e) =>
        dispatch({
          type: PatientManagementActionType.SetSearchTerm,
          payload: e.target.value.toLowerCase().trim(),
        }),
      DEFAULT_TRANSITION_DURATION,
    ),
    [],
  );

  const hasTagFilter = tagFilter !== null;

  const programSortOrder = columnOrderToProgramSortOrder(sortOrder);

  const handleSortOrderChange = (newSortOrder: ColumnOrder) => {
    setStoredColumnOrder(newSortOrder);
    dispatch({
      type: PatientManagementActionType.SetSortOrder,
      payload: newSortOrder,
    });
  };

  const clearFilters = (stateOverride?: Partial<PatientManagementState>) => {
    setSearchTermValue('');
    dispatch({
      type: PatientManagementActionType.ResetPage,
      payload: stateOverride,
    });
  };

  const { loading: tableLoading, refetch: refetchPatientsTable } =
    usePatientsV2Query({
      variables: {
        patientLifecycleStatuses: [patientLifecycleStatus!],
        searchQuery: searchTerm,
        ...(tagFilter && { programTagId: tagFilter.id }),
        sortOrder: programSortOrder,
        pageSize: PATIENT_PAGE_SIZE,
        page: 1,
      },
      skip: !patientLifecycleStatus,
      onCompleted: (data) => {
        if (data) {
          const { patientsV2 } = data;
          firstPageLoadedRef.current = true;
          endReachedRef.current = patientsV2.length < PATIENT_PAGE_SIZE;
          setPatientsObject(getPatientsObject(patientsV2));
        }
      },
    });

  const [fetchMorePatients, { loading: morePatientsLoading }] =
    usePatientsV2LazyQuery({
      variables: {
        patientLifecycleStatuses: [patientLifecycleStatus!],
        searchQuery: searchTerm,
        ...(tagFilter && { programTagId: tagFilter.id }),
        sortOrder: programSortOrder,
        pageSize: PATIENT_PAGE_SIZE,
        page: currentPage,
      },
      fetchPolicy: 'network-only', // Ensure fresh data is fetched
    });

  // Found these necessary to properly control race conditions. There could potentially be other
  // ways, and I wish I didn't need two refs, but due to the async and split nature of the two patient
  // loading calls, I couldn't guard against edge cases well enough without them.
  const firstPageLoadedRef = useRef(false);
  const endReachedRef = useRef(false);

  const handleLoadMorePatients = async () => {
    if (endReachedRef.current) {
      return;
    }
    dispatch({ type: PatientManagementActionType.IncrementPage });
    const { data } = await fetchMorePatients();
    if (data) {
      const paginationEndReached = data.patientsV2.length < PATIENT_PAGE_SIZE;
      if (paginationEndReached) {
        endReachedRef.current = true;
      }
      setPatientsObject((prevPatientsObject) => ({
        ...prevPatientsObject,
        ...getPatientsObject(data.patientsV2),
      }));
    }
  };

  const debouncedHandleLoadMorePatients = useCallback(
    debounce(handleLoadMorePatients, PAGINATION_DEBOUNCE_DELAY, {
      leading: true,
      trailing: false,
    }),
    [],
  );

  const observer = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = async (entries: IntersectionObserverEntry[]) => {
    const sentinelEntry = entries[0];
    if (
      sentinelEntry.isIntersecting &&
      firstPageLoadedRef.current &&
      !tableLoading &&
      !morePatientsLoading
    ) {
      debouncedHandleLoadMorePatients();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(handleIntersection, {
      // Trigger the callback INFINITE_SCROLL_LOADER_MARGIN px before the element is in view
      rootMargin: INFINITE_SCROLL_LOADER_MARGIN,
    });

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => observer.current?.disconnect();
  }, [tableLoading, morePatientsLoading]);

  const [patientsObject, setPatientsObject] = useState<PatientsObject | null>(
    null,
  );
  const patientsList = patientsObject
    ? getPatientsList(patientsObject, authedProviderUser?.hasPremiumAccess)
    : null;
  const restrictedPatientsList = patientsObject
    ? getRestrictedPatientsList(
        patientsObject,
        authedProviderUser?.hasPremiumAccess,
      )
    : null;

  const hasPatientsList =
    patientsList && patientsList?.length > 0 && !tableLoading;

  const [singlePatient, setSinglePatient] =
    useState<PatientTableDataFragment | null>(null);

  const addPatients = () => {
    if (willHitClientLimit()) {
      showUpgradeModal(AnalyticsPage.ClientsTable);
      return;
    }
    setIsAddPatientsModalOpen(true);
  };

  const handleProgramTagsUpdate = (
    programId: string,
    tags: ProgramTagDataFragment[],
  ) => {
    const updatedPatientsObject = {
      ...patientsObject,
      [programId]: {
        ...patientsObject[programId],
        tags,
      },
    };
    setPatientsObject(updatedPatientsObject);
  };

  const handleUnarchiveClick = async (programId: string) => {
    if (willHitClientLimit()) {
      showUpgradeModal(AnalyticsPage.ClientsTable);
      return;
    }
    try {
      const response = await reactivatePatientsMutation({
        variables: {
          input: {
            programIds: [programId],
          },
        },
      });

      if (response.data?.reactivatePatients.reactivatedPatients?.length) {
        await refetchPatientsCount();
        await refreshAuthedProviderUserSubscriptionState();
        setPatientsObject((prevPatientsObject) => {
          const { [programId]: _, ...rest } = prevPatientsObject;
          return rest;
        });
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to unarchive client."
          level="error"
        />
      ));
    }
  };

  const handleAddStorefrontClient = async (programId: string) => {
    // It's a bit hacky, but new storefront clients are categorized as such and restricted
    // based on their "last seen" date. The only other place where the client is marked "seen"
    // is when visiting their profile page, and these clients are blocked from being accessed
    // on the back-end, preventing that.
    // Therefore, marking the program as seen here "accepts" the client, claiming a client seat
    // and making them accessible.
    try {
      const response = await markProgramSeenMutation({
        variables: {
          programId,
        },
      });
      if (response.data?.markProgramSeen) {
        const updatedPatient = response.data.markProgramSeen;
        setPatientsObject({
          ...patientsObject,
          [programId]: {
            ...updatedPatient,
          },
        });
      }
      // Refresh the subscription state to update the client seat count
      await refreshAuthedProviderUserSubscriptionState();
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to add client."
          level="error"
        />
      ));
    }
  };

  const handlePostDeactivation = async (deactivatedProgramIds: string[]) => {
    await refetchPatientsCount();
    await refreshAuthedProviderUserSubscriptionState();
    const remainingPatients = omit(patientsObject, deactivatedProgramIds);
    setPatientsObject(remainingPatients);
    clearFilters();
  };

  const shouldShowPage = patientsCount;

  return (
    <PageContainer>
      <div
        className={classNames(
          'h-full transition duration-500',
          !shouldShowPage && 'opacity-0',
          shouldShowPage && 'opacity-100',
        )}
      >
        <div className="mb-4 flex h-[48px] flex-row justify-between">
          <div className="flex flex-row items-center">
            <div className="font-serif text-subtitle text-green-150">
              {isInArchivedMode && 'Archived '}
              Clients
            </div>
            {/* {hasPatientsList && !showingAllPatients && (
              <div className="ml-4">
                <span className="text-caption font-medium text-neutral-125">
                  {patientsList.length} shown
                </span>
              </div>
            )} */}
          </div>
          <div className="flex flex-row items-center gap-x-5">
            {!authedProviderUser.hasPremiumAccess && (
              <div className="flex items-center gap-x-2">
                <div className="text-small-caption text-neutral-110">
                  Available client seats
                </div>
                <div className="rounded-full bg-neutral-25 px-2 py-1 text-caption font-medium text-neutral-125">
                  {authedProviderUser.provider.usedClientSeats} /{' '}
                  {authedProviderUser.provider.clientSeats}
                </div>
              </div>
            )}
            {!isInArchivedMode && (
              <Button
                title="Add clients"
                IconComponent={PlusSmIcon}
                iconPosition="left"
                onClick={addPatients}
              />
            )}
          </div>
        </div>
        <div className="mb-4 flex h-[56px] w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start">
            <InputGroup
              labelHidden
              label="Search"
              placeholder="Search..."
              className="h-[44px] w-80 rounded-full pl-12 disabled:cursor-not-allowed"
              containerClassName="mr-4"
              iconLeftClassName="text-neutral-125 ml-2"
              IconLeft={Search}
              IconRight={searchTermValue.length > 0 ? CloseX : null}
              iconRightOnClick={() => {
                setSearchTermValue('');
                dispatch({
                  type: PatientManagementActionType.SetSearchTerm,
                  payload: '',
                });
              }}
              iconRightClassName="text-neutral-125 mr-1"
              value={searchTermValue}
              onChange={(e) => {
                setSearchTermValue(e.target.value);
                debouncedSearch(e);
              }}
            />
            <div className="flex flex-row items-center justify-start">
              {patientLifecycleStatus !== PatientLifecycleStatusV2.Archived &&
                hasProviderProgramTags && (
                  <>
                    <div
                      className={classNames(
                        'relative z-10 transition',
                        !hasTagFilter && 'opacity-0',
                        hasTagFilter && 'opacity-100',
                      )}
                    >
                      <Button
                        className="h-[24px] w-[24px] rounded-full"
                        iconClassName="min-h-[14px] min-w-[14px] ml-0"
                        size="extra-small"
                        aria-label="Clear tag filters"
                        IconComponent={XIcon}
                        onClick={() =>
                          dispatch({
                            type: PatientManagementActionType.SetTagFilter,
                            payload: null,
                          })
                        }
                      />
                    </div>
                    <div
                      className={classNames(
                        'relative z-20 flex flex-row items-center justify-start transition',
                        !hasTagFilter && '-translate-x-6',
                        hasTagFilter && 'translate-x-[10px]',
                      )}
                    >
                      <SelectMenu
                        fieldValue={tagFilter}
                        placeholder="Filter by tag..."
                        label=""
                        hideLabel
                        fieldOptions={providerProgramTags}
                        onChange={(value: ProgramTagDataFragment) =>
                          dispatch({
                            type: PatientManagementActionType.SetTagFilter,
                            payload: value,
                          })
                        }
                        buttonClassName="!py-2"
                        containerClassName="w-[200px]"
                        SelectOptionComponent={TagFilterOption}
                      />
                    </div>
                  </>
                )}
              {patientLifecycleStatus !== PatientLifecycleStatusV2.Archived &&
                authedProviderUser.role !== ProviderUserRole.Practitioner && (
                  <Button
                    title="Manage Tags"
                    theme="secondary"
                    size="small"
                    aria-label="Manage Tags"
                    IconComponent={Tag}
                    className={classNames(
                      'relative z-20 ml-4 flex transition',
                      !hasTagFilter && '-translate-x-6',
                      hasTagFilter && 'translate-x-[10px]',
                    )}
                    onClick={() => setIsTagManagerModalOpen(true)}
                  />
                )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <button
              className={classNames(
                'flex flex-row items-center justify-start rounded-full pl-3.5 pr-2 text-caption text-neutral-125',
                isInActiveMode ? 'bg-neutral-50' : 'hover:bg-neutral-25',
              )}
              onClick={(event) => {
                event.stopPropagation();
                clearFilters();
                if (!isInActiveMode) {
                  navigate(PATIENTS_ACTIVE_BASE_PATH);
                }
              }}
            >
              <div>Active</div>
              <div className="ml-2 rounded-full bg-neutral-50 px-2.5 py-1 font-medium">
                {patientsCount?.activePatientsCount}
              </div>
            </button>
            <button
              className={classNames(
                'ml-3 flex flex-row items-center justify-start rounded-full pl-3.5 pr-2 text-caption text-neutral-125',
                isInArchivedMode ? 'bg-neutral-50' : 'hover:bg-neutral-25',
              )}
              onClick={(event) => {
                event.stopPropagation();
                clearFilters();
                if (!isInArchivedMode) {
                  navigate(PATIENTS_ARCHIVED_BASE_PATH);
                }
              }}
            >
              <ArchiveIcon className="mr-2 h-5 w-5 text-neutral-125" />
              <div>Archived</div>
              <div className="ml-2 rounded-full bg-neutral-50 px-2.5 py-1 font-medium">
                {patientsCount?.archivedPatientsCount}
              </div>
            </button>
          </div>
        </div>
        <Outlet
          context={{
            nextStepsButtonsDisabled: true,
            patientLifecycleStatus,
            hasPatientsList,
            patientsCount,
            tableLoading,
            patientOrder: sortOrder,
            patientsList,
            restrictedPatientsList,
            clearFilters,
            handleSortOrderChange,
            setSinglePatient,
            setIsSendAssessmentModalOpen,
            addPatients,
            openResendInvitesModal: () => setIsResendInvitesModalOpen(true),
            providerProgramTags,
            refetchMeProviderProgramTags,
            handleProgramTagsUpdate,
            handleUnarchiveClick,
            handleAddStorefrontClient,
          }}
        />
        <div
          ref={sentinelRef}
          className={classNames(
            `flex h-24 w-full items-center justify-center`,
            morePatientsLoading && !tableLoading ? 'opacity-100' : 'opacity-0',
          )}
        >
          <Spinner className="h-4 w-4" />
        </div>
      </div>

      <AddPatientsModal
        patientsObject={patientsObject}
        isModalOpen={isAddPatientsModalOpen}
        setPatientsObject={setPatientsObject}
        setClosed={() => setIsAddPatientsModalOpen(false)}
        clearFilters={clearFilters}
        providerProgramTags={providerProgramTags}
        refetchMeProviderProgramTags={refetchMeProviderProgramTags}
        noPatientDataPermission={noPatientDataPermission}
        refetchPatientsCount={refetchPatientsCount}
      />
      <ResendInvitesModal
        isPatientManagement
        patientsObject={patientsObject}
        singlePatient={singlePatient}
        isModalOpen={isResendInvitesModalOpen}
        patientLifecycleStatus={patientLifecycleStatus}
        setClosed={() => setIsResendInvitesModalOpen(false)}
        setPatientsObject={setPatientsObject}
        clearFilters={clearFilters}
      />
      <TagManagerModal
        isModalOpen={isTagManagerModalOpen}
        setClosed={() => setIsTagManagerModalOpen(false)}
        initialProviderProgramTags={providerProgramTags}
        onClose={async () => {
          await refetchMeProviderProgramTags();
          await refetchPatientsTable();
        }}
      />
      <UpgradeModal
        isModalOpen={isBlockingUpgradeModalOpen}
        setClosed={() => {
          setIsBlockingUpgradeModalOpen(false);
        }}
        originPage={AnalyticsPage.ClientsTable}
        isBlocking
        handleSelectActiveClients={() => {
          setIsTrialEndDeactivatePatientsModalOpen(true);
        }}
      />
      <TrialEndDeactivatePatientsModal
        isOpen={isTrialEndDeactivatePatientsModalOpen}
        setClosed={() => setIsTrialEndDeactivatePatientsModalOpen(false)}
        patientsObject={patientsObject}
        onDeactivation={handlePostDeactivation}
      />
    </PageContainer>
  );
};

export default PatientManagement;
