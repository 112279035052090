import classNames from 'classnames';

type PanelContainerProps = {
  children?: React.ReactNode;
  containerClassName?: string;
};

export default function PanelContainer({
  children,
  containerClassName,
}: PanelContainerProps) {
  return (
    <div
      className={classNames(
        'fixed flex h-screen w-[400px] flex-col items-start justify-start bg-white pt-8 pr-4 pl-14',
        containerClassName,
      )}
    >
      {children}
    </div>
  );
}
