import { getFirstName, getLastName } from '../../../lib/copy';

import {
  PatientRow,
  PatientRowValues,
  NewPatientLeadType,
} from '../../../types/PatientManagement';
interface FormattedValidatedData {
  [key: string]: NewPatientLeadType;
}

export const formatValidatedPatientLeads = (
  validatedData: PatientRowValues,
) => {
  const formatRow = (row: PatientRow, rowIndex: number) => {
    return {
      inputId: rowIndex,
      email: row.email.trim().toLowerCase(),
      phoneNumber: row.phoneNumber?.trim().toLowerCase(),
      firstName: getFirstName(row.name.trim()),
      lastName: getLastName(row.name.trim()),
      tags: row.tags?.map((tag) => {
        const { name, color, description, id } = tag;
        return { name, color, description, id };
      }),
    };
  };

  const formattedValidatedData: FormattedValidatedData = {};

  if (validatedData.patientRows?.length > 0)
    validatedData.patientRows.forEach((patientRow, index) => {
      formattedValidatedData[patientRow.email] = formatRow(patientRow, index);
    });

  return Object.values(formattedValidatedData);
};
