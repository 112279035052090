import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Config from './config';
import { MeProviderDataFragment } from '../generated/graphql';
import { Environment } from '../v2/types/config';

const initSentry = () => {
  if (Config.REACT_APP_ENVIRONMENT !== Environment.Development)
    Sentry.init({
      dsn: 'https://cb5ee4416aba4a8b90ed325f3cf43a53@o1261196.ingest.sentry.io/6438612',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      environment: Config.REACT_APP_ENVIRONMENT,
      release: Config.REACT_APP_VERSION,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate:
        Config.REACT_APP_ENVIRONMENT === 'production' ? 0.5 : 1.0,
    });
};

const setUserScope = (meProvider: MeProviderDataFragment) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: meProvider.id,
    });
    scope.setContext('authInfo', {
      role: meProvider.role,
      providerId: meProvider.provider.id,
    });
  });
};

const clearUserScope = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
};

const SentryHelpers = {
  initSentry,
  setUserScope,
  clearUserScope,
};

export default SentryHelpers;
