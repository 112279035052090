import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Outlet, useOutletContext, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import {
  useProgramTemplatesQuery,
  usePatientsQuery,
  usePatientsCountQuery,
  PatientLifecycleStatus,
  PatientsCount,
  ProgramOnboardingStatus,
  PatientsQuery,
  Feature,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';

import { ColumnOrder } from '../../types/tables';

import Plus from '../../svgs/Plus';
import Search from '../../svgs/Search';
import CloseX from '../../svgs/CloseX';

import Tabs from '../../components/Tabs';
import Button from '../../components/Button';
import TableTitle from '../../components/TableTitle';
import InputGroup from '../../components/InputGroup';
import ProgramItem from '../../components/ProgramItem';
import BottomButtons from '../../components/BottomButtons';
import PageContainer from '../../components/Containers/PageContainer';
import ProgramOnboardingStatusItem from '../../components/ProgramOnboardingStatusItem';

import AddPatientsModal from './Modals/AddPatientsModal';
import AssignToProgramModal from './Modals/AssignToProgramModal';
import InviteToProgramModal from './Modals/InviteToProgramModal';

import {
  sortOptions,
  sortPatients,
  filtersObject,
  PatientsObject,
  getPatientsList,
  getPatientCount,
  getProgramsObject,
  getPatientsObject,
  getEligibleFilters,
  VALID_ONBOARDING_STATUSES,
} from './helpers';

import FilterMenu from './FilterMenu';
import FirstAppointmentDateModal from './Modals/FirstAppointmentDateModal';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';

type TableContext = {
  nextStepsButtonsDisabled: boolean;
  patientLifecycleStatus: PatientLifecycleStatus;
  selectedPatientsObject: PatientsObject;
  selectedPatientsList: PatientsQuery['patients'];
  isPatientsSelected: boolean;
  patientsCount: PatientsCount;
  patientOrder: ColumnOrder;
  tableLoading: boolean;
  patientsList: PatientsQuery['patients'] | null;
  clearFilters: () => void;
  setPatientOrder: React.Dispatch<React.SetStateAction<ColumnOrder>>;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientsQuery['patients'][number] | null>
  >;
  openAddPatientsModal: () => void;
  openAssignToProgramModal: () => void;
  openInviteToProgramModal: () => void;
  openFirstAppointmentDateModal: () => void;
  setSelectedPatientsObject: React.Dispatch<
    React.SetStateAction<PatientsObject>
  >;
};

export function useTableContext() {
  return useOutletContext<TableContext>();
}

export const PATIENTS_ONBOARDING_BASE_PATH = '/clients/onboarding';
export const PATIENTS_INVITED_BASE_PATH = '/clients/invited';
const PATIENTS_ACTIVE_BASE_PATH = '/clients/active';
const PATIENTS_ARCHIVED_BASE_PATH = '/clients/archived';

const PatientManagement = () => {
  const { pathname } = useLocation();

  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsEnabled = isFeatureEnabled(Feature.Assessments);

  const { authedProviderUser } = useAuth();
  const providerId = authedProviderUser.provider.id;

  const {
    data: patientsCount,
    loading: patientCountLoading,
    refetch: refetchPatientsCount,
  } = usePatientsCountQuery({
    variables: {
      providerId,
    },
  });

  const tabList = [
    {
      route: 'onboarding',
      name: 'Onboarding',
      count: patientsCount?.patientsCount.onboardingPatientsCount,
    },
    {
      route: 'invited',
      name: 'Invited',
      count: patientsCount?.patientsCount.invitedPatientsCount,
    },
    {
      route: 'active',
      name: 'Active',
      count: patientsCount?.patientsCount.activePatientsCount,
    },
    {
      route: 'archived',
      name: 'Archived',
      count: patientsCount?.patientsCount.archivedPatientsCount,
    },
  ];

  const [patientsObject, setPatientsObject] = useState<PatientsObject | null>(
    null,
  );
  const [patientsList, setPatientsList] = useState<
    PatientsQuery['patients'] | null
  >(null);
  const [patientOrder, setPatientOrder] = useState<ColumnOrder | null>(null);

  const [selectedPatientsObject, setSelectedPatientsObject] =
    useState<PatientsObject>({});
  const [singlePatient, setSinglePatient] = useState<
    PatientsQuery['patients'][number] | null
  >(null);

  const [isAddPatientsModalOpen, setIsAddPatientsModalOpen] = useState(false);
  const [isAssignToProgramModalOpen, setIsAssignToProgramModalOpen] =
    useState(false);
  const [isInviteToProgramModalOpen, setIsInviteToProgramModalOpen] =
    useState(false);
  const [isFirstAppointmentDateModalOpen, setIsFirstAppointmentDateModalOpen] =
    useState(false);

  // cleanup function for lingering single patient state
  useEffect(() => {
    if (
      !isAssignToProgramModalOpen &&
      !isInviteToProgramModalOpen &&
      !isFirstAppointmentDateModalOpen
    ) {
      setTimeout(() => {
        setSinglePatient(null);
      }, MODAL_TRANSITION_DURATION);
    }
  }, [
    isAssignToProgramModalOpen,
    isInviteToProgramModalOpen,
    isFirstAppointmentDateModalOpen,
  ]);

  const [patientLifecycleStatus, setPatientLifecycleStatus] =
    useState<PatientLifecycleStatus | null>(null);

  const [filters, setFilters] = useState(filtersObject);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermValue, setSearchTermValue] = useState('');

  const { data: programsData } = useProgramTemplatesQuery({});
  const programs = programsData?.programTemplates || [];
  const programsObject = getProgramsObject(programs);

  const { programFilter, statusFilter } = getEligibleFilters(filters);
  const hasFilters = programFilter.length > 0 || statusFilter.length > 0;

  const clearFilters = () => {
    setSearchTerm('');
    setSearchTermValue('');
    setFilters(filtersObject);
  };

  useEffect(() => {
    clearFilters();
    if (pathname.includes(PATIENTS_ONBOARDING_BASE_PATH)) {
      setPatientLifecycleStatus(PatientLifecycleStatus.Onboarding);
    } else if (pathname.includes(PATIENTS_INVITED_BASE_PATH)) {
      setPatientLifecycleStatus(PatientLifecycleStatus.Invited);
    } else if (pathname.includes(PATIENTS_ACTIVE_BASE_PATH)) {
      setPatientLifecycleStatus(PatientLifecycleStatus.Active);
    } else if (pathname.includes(PATIENTS_ARCHIVED_BASE_PATH)) {
      setPatientLifecycleStatus(PatientLifecycleStatus.Archived);
    }
    setSelectedPatientsObject({});
  }, [pathname]);

  const { data: patientsData, loading: tableLoading } = usePatientsQuery({
    variables: {
      providerId,
      patientLifecycleStatus: patientLifecycleStatus,
    },
  });

  useEffect(() => {
    if (patientsData) {
      const { patients } = patientsData;
      setPatientOrder(sortOptions.newestCreated);
      setPatientsObject(getPatientsObject(patients));
    }
  }, [patientsData]);

  // update patient list on patientObject or patientOrder updated
  useEffect(() => {
    if (patientsObject && getPatientCount(patientsObject) >= 0) {
      setPatientsList(
        sortPatients(
          getPatientsList(patientsObject).filter((patient) => {
            const patientFullName =
              `${patient?.firstName} ${patient?.lastName}`.toLowerCase();
            if (
              programFilter.length > 0 &&
              (!patient?.programTemplate?.id ||
                !programFilter.includes(patient?.programTemplate?.id))
            ) {
              return false;
            } else if (
              statusFilter.length > 0 &&
              !statusFilter.includes(patient?.onboardingStatus)
            ) {
              return false;
            } else if (
              !patientFullName.includes(searchTerm) &&
              !patient.email.includes(searchTerm)
            ) {
              return false;
            }
            return true;
          }),
          patientOrder !== null ? patientOrder : sortOptions.newestCreated,
        ),
      );
    }
    refetchPatientsCount();
  }, [patientsObject, patientOrder, filters, searchTerm, refetchPatientsCount]);

  const selectedPatientsList = getPatientsList(selectedPatientsObject);
  const isPatientsSelected = selectedPatientsList.length > 1;
  const showBottomButtons =
    isPatientsSelected &&
    (patientLifecycleStatus === PatientLifecycleStatus.Onboarding ||
      patientLifecycleStatus === PatientLifecycleStatus.Invited);

  const assignToProgramEnabled = useMemo(() => {
    return selectedPatientsList.some(
      (patient) =>
        VALID_ONBOARDING_STATUSES.assignToProgram[patient.onboardingStatus],
    );
  }, [selectedPatientsList]);

  const sendInvitesEnabled = useMemo(() => {
    return selectedPatientsList.some(
      (patient) =>
        VALID_ONBOARDING_STATUSES.inviteToProgram[patient.onboardingStatus],
    );
  }, [selectedPatientsList]);

  const buttonActions = [
    {
      title: 'Assign to program',
      disabled: !assignToProgramEnabled,
      onClick: () => setIsAssignToProgramModalOpen(true),
    },
    {
      title:
        patientLifecycleStatus === PatientLifecycleStatus.Onboarding
          ? 'Send invite email'
          : 'Re-send invite',
      disabled: !sendInvitesEnabled,
      onClick: () => setIsInviteToProgramModalOpen(true),
    },
  ];

  const debouncedSearch = useCallback(
    debounce((e) => setSearchTerm(e.target.value.toLowerCase().trim()), 300),
    [],
  );

  return (
    <PageContainer>
      <div
        className={classNames(
          'transition duration-500',
          patientCountLoading && 'opacity-0',
          !patientCountLoading && 'opacity-100',
        )}
      >
        <TableTitle
          title="Your clients"
          shownRowsCount={patientsList?.length}
          selectedRowsCount={selectedPatientsList.length}
        >
          {patientsCount?.meProvider?.provider.subscriptionTier &&
            Boolean(patientsCount?.meProvider?.provider.usedClientSeats) &&
            patientsCount?.meProvider?.provider.clientSeats && (
              <div className="mr-4">
                <span className="text-caption text-neutral-125">
                  <span className="font-bold">
                    {patientsCount?.meProvider?.provider.usedClientSeats}
                  </span>
                  {' of '}
                  <span className="font-bold">
                    {patientsCount?.meProvider?.provider.clientSeats}
                  </span>
                  {' client seats used'}
                </span>
              </div>
            )}
          <Button
            title="Add clients"
            IconComponent={Plus}
            onClick={() => setIsAddPatientsModalOpen(true)}
          />
        </TableTitle>
        <div className="mt-3 mb-5 flex h-[48px] w-full flex-row items-center justify-between">
          <Tabs tabList={tabList} fullWidth />
        </div>
        <div className="flex h-[48px] w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start">
            <FilterMenu
              patientLifecycleStatus={patientLifecycleStatus}
              programTemplates={programs}
              filters={filters}
              setFilters={setFilters}
            />
            <div className="w-3" />
            {hasFilters && (
              <>
                {programFilter.map((programId) => {
                  return (
                    <div key={programId}>
                      <ProgramItem
                        programTemplateId={programsObject[programId].id}
                        programTemplateName={
                          programsObject[programId].name ?? undefined
                        }
                        programTemplateStatus={programsObject[programId].status}
                        className="h-[30px]"
                        hasCloseX
                        disableStatus
                        onClick={() => {
                          setFilters({
                            ...filters,
                            programs: {},
                          });
                        }}
                      />
                      <div className="w-2" />
                    </div>
                  );
                })}
                {statusFilter.map((status) => {
                  return (
                    <div key={status}>
                      <ProgramOnboardingStatusItem
                        onboardingStatus={status as ProgramOnboardingStatus}
                        className="h-[30px]"
                        isCloseablePill
                        clearFilter={() => {
                          setFilters({
                            ...filters,
                            statuses: {},
                          });
                        }}
                      />
                      <div className="w-2" />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <InputGroup
            labelHidden
            label="Search"
            placeholder="Find by name or email"
            className="h-[44px] w-80 rounded-full pl-11"
            IconLeft={Search}
            iconLeftClassName="text-neutral-125 ml-1"
            IconRight={searchTerm.length > 0 ? CloseX : null}
            iconRightOnClick={() => {
              setSearchTermValue('');
              setSearchTerm('');
            }}
            iconRightClassName="text-neutral-125"
            value={searchTermValue}
            onChange={(e) => {
              setSearchTermValue(e.target.value);
              debouncedSearch(e);
            }}
          />
        </div>
      </div>
      <Outlet
        context={{
          nextStepsButtonsDisabled: showBottomButtons,
          patientLifecycleStatus,
          selectedPatientsObject,
          selectedPatientsList,
          isPatientsSelected,
          patientsCount: patientsCount?.patientsCount,
          tableLoading,
          patientOrder,
          patientsList,
          clearFilters,
          setPatientOrder,
          setSinglePatient,
          setSelectedPatientsObject,
          openAddPatientsModal: () => setIsAddPatientsModalOpen(true),
          openAssignToProgramModal: () => setIsAssignToProgramModalOpen(true),
          openInviteToProgramModal: () => setIsInviteToProgramModalOpen(true),
          openFirstAppointmentDateModal: () =>
            setIsFirstAppointmentDateModalOpen(true),
        }}
      />
      <BottomButtons isVisible={showBottomButtons} actions={buttonActions} />
      <AddPatientsModal
        patientsObject={patientsObject}
        isModalOpen={isAddPatientsModalOpen}
        setPatientsObject={setPatientsObject}
        setSelectedPatientsObject={setSelectedPatientsObject}
        setPatientOrder={setPatientOrder}
        setClosed={() => setIsAddPatientsModalOpen(false)}
        clearFilters={clearFilters}
      />
      <AssignToProgramModal
        programTemplates={programs}
        patientsObject={patientsObject}
        singlePatient={singlePatient}
        selectedPatientsList={selectedPatientsList}
        isModalOpen={isAssignToProgramModalOpen}
        setPatientsObject={setPatientsObject}
        setSelectedPatientsObject={setSelectedPatientsObject}
        setClosed={() => setIsAssignToProgramModalOpen(false)}
        clearFilters={clearFilters}
      />
      <InviteToProgramModal
        isPatientManagement
        patientsObject={patientsObject}
        singlePatient={singlePatient}
        selectedPatientsList={selectedPatientsList}
        isModalOpen={isInviteToProgramModalOpen}
        patientLifecycleStatus={patientLifecycleStatus}
        setClosed={() => setIsInviteToProgramModalOpen(false)}
        setPatientsObject={setPatientsObject}
        setSelectedPatientsObject={setSelectedPatientsObject}
        clearFilters={clearFilters}
      />
      {isAssessmentsEnabled && (
        <FirstAppointmentDateModal
          patient={singlePatient}
          setSinglePatient={setSinglePatient}
          isModalOpen={isFirstAppointmentDateModalOpen}
          patientsObject={patientsObject}
          isPatientManagement
          patientLifecycleStatus={patientLifecycleStatus}
          setClosed={() => setIsFirstAppointmentDateModalOpen(false)}
          setPatientsObject={setPatientsObject}
        />
      )}
    </PageContainer>
  );
};

export default PatientManagement;
