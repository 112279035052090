import { Fragment, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { toast } from 'react-hot-toast';

import IconButton from './IconButton';
import Spinner from '../svgs/Spinner';
import {
  ProviderUserMemberDataFragment,
  useResendProviderUserInviteMutation,
} from '../../generated/graphql';
import { CustomGraphQLError } from '../../lib/errors';
import ToastAlert from './ToastAlert';

type MemberActionsMenuProps = {
  isOpen: boolean;
  providerUserMember: ProviderUserMemberDataFragment;
  setIsOpen: (isOpen: boolean) => void;
  refetchMembers: () => Promise<unknown>;
};

export default function MemberActionsMenu({
  isOpen,
  providerUserMember,
  setIsOpen,
  refetchMembers,
}: MemberActionsMenuProps) {
  const [isResendLoading, setIsResendLoading] = useState(false);

  const [resendProviderInviteMutation] = useResendProviderUserInviteMutation();

  const handleResendItemClick = async (event: Event) => {
    // Don't close dropdown menu until resend is complete.
    event.preventDefault();

    setIsResendLoading(true);
    try {
      await resendProviderInviteMutation({
        variables: { providerUserId: providerUserMember.id },
      });
      await refetchMembers();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Successfully
        resent invite email!"
        />
      ));
    } catch (err) {
      alert((err as CustomGraphQLError).message);
    }

    setIsResendLoading(false);
    setIsOpen(false);
  };

  const canResendInvite =
    !providerUserMember.registeredAt && providerUserMember.lastInviteSentAt;

  // TODO: create a reusable dropdown menu component
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild disabled={!canResendInvite}>
        <IconButton
          aria-label="Actions menu"
          IconComponent={DotsHorizontalIcon}
          iconClassName="h-4 w-4"
          disabled={!canResendInvite}
        />
      </DropdownMenu.Trigger>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <DropdownMenu.Content
          forceMount
          className="mt-2 w-56 rounded-lg bg-white py-2 shadow-100 ring-1 ring-black ring-opacity-5 focus:outline-none"
          side="bottom"
          align="start"
        >
          <DropdownMenu.Item
            onSelect={handleResendItemClick}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-caption text-neutral-125 focus:bg-neutral-50 focus:outline-none"
          >
            <div>Resend email invite</div>
            {isResendLoading && <Spinner className="h-4 w-4" />}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </Transition>
    </DropdownMenu.Root>
  );
}
