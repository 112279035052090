import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';
import {
  getOnboardingSteps,
  getHasCompletedOnboarding,
} from '../lib/onboarding';
import { OnboardingStep, OnboardingStepName } from '../types/onboarding';
import OnboardingBilling from '../pages/OnboardingBilling';
import OnboardingPrograms from '../pages/OnboardingPrograms';
import OnboardingConfirmation from '../pages/OnboardingConfirmation';
import OnboardingMembers from '../pages/OnboardingMembers';

/** Nested route grouping for onboarding flow. */
export default function OnboardingRoutes() {
  const [onboardingSteps, setOnboardingSteps] = useState<OnboardingStep[]>([]);

  const { authedProviderUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Generate onboarding steps once on init, so previous steps don't disappear as they are completed and the data is updated.
    const shouldInitialize = authedProviderUser && onboardingSteps.length === 0;
    if (shouldInitialize) {
      setOnboardingSteps(getOnboardingSteps(authedProviderUser));
    }
  }, [authedProviderUser, onboardingSteps]);

  if (getHasCompletedOnboarding(authedProviderUser)) {
    return <Navigate to="/" replace />;
  }

  const currentStepIndex = onboardingSteps.findIndex((step) =>
    location.pathname.includes(step.name),
  );

  const isAnyPreviousStepIncomplete =
    currentStepIndex > 0 &&
    !onboardingSteps.every(
      (step, index) =>
        index >= currentStepIndex ||
        !step.getIsComplete ||
        step.getIsComplete(authedProviderUser),
    );

  if (isAnyPreviousStepIncomplete) {
    const previousStepName = onboardingSteps[currentStepIndex - 1].name;
    return <Navigate to={`${previousStepName}`} replace />;
  }

  const goToNextStep = async (stepIndex: number) => {
    const isFinalStep = stepIndex === onboardingSteps.length - 1;
    if (isFinalStep) {
      return;
    }

    const nextStepName = onboardingSteps[stepIndex + 1].name;
    navigate(nextStepName);
  };

  const pageProps = {
    stepIndex: currentStepIndex,
    steps: onboardingSteps,
    goToNextStep,
  };
  const onboardingStepPages: Record<OnboardingStepName, JSX.Element> = {
    members: <OnboardingMembers {...pageProps} />,
    programs: <OnboardingPrograms {...pageProps} />,
    billing: <OnboardingBilling {...pageProps} />,
    confirmation: <OnboardingConfirmation {...pageProps} />,
  };

  return (
    <Routes>
      {onboardingSteps.map((step, index) => (
        <Route
          key={index}
          path={step.name}
          element={onboardingStepPages[step.name]}
        />
      ))}
      {/* Navigating to an invalid step takes you to the first step. */}
      {onboardingSteps.length > 0 && (
        <Route
          path="*"
          element={<Navigate to={`${onboardingSteps[0].name}`} />}
        />
      )}
    </Routes>
  );
}
