import { SvgIconComponent } from '../types/svgs';

const ThumbnailText: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="36" height="36" rx="8" fill="#D8DEFF" />
      <path
        d="M23.625 19.6875V17.7188C23.625 17.0474 23.3583 16.4036 22.8836 15.9289C22.4089 15.4542 21.7651 15.1875 21.0938 15.1875H19.9688C19.745 15.1875 19.5304 15.0986 19.3721 14.9404C19.2139 14.7821 19.125 14.5675 19.125 14.3438V13.2188C19.125 12.5474 18.8583 11.9036 18.3836 11.4289C17.9089 10.9542 17.2651 10.6875 16.5938 10.6875H15.1875M16.875 10.6875H13.2188C12.753 10.6875 12.375 11.0655 12.375 11.5312V24.4688C12.375 24.9345 12.753 25.3125 13.2188 25.3125H22.7812C23.247 25.3125 23.625 24.9345 23.625 24.4688V17.4375C23.625 15.6473 22.9138 13.9304 21.648 12.6645C20.3821 11.3987 18.6652 10.6875 16.875 10.6875Z"
        stroke="#2D4BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailText;
