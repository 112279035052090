import { SvgIconComponent } from '../types/svgs';

const Headphones: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M19 11C19 7.13401 15.866 4 12 4C8.13401 4 5 7.13401 5 11M16 14.5V16.5C16 16.9647 16 17.197 16.0384 17.3902C16.1962 18.1836 16.816 18.8037 17.6094 18.9615C17.8026 19 18.0353 19 18.5 19C18.9647 19 19.197 19 19.3902 18.9615C20.1836 18.8037 20.8041 18.1836 20.9619 17.3902C21.0003 17.197 21 16.9647 21 16.5V14.5C21 14.0353 21.0003 13.8031 20.9619 13.6099C20.8041 12.8165 20.1836 12.1962 19.3902 12.0384C19.197 12 18.9647 12 18.5 12C18.0353 12 17.8026 12 17.6094 12.0384C16.816 12.1962 16.1962 12.8165 16.0384 13.6099C16 13.8031 16 14.0353 16 14.5ZM8 14.5V16.5C8 16.9647 8.00034 17.197 7.96191 17.3902C7.8041 18.1836 7.18356 18.8037 6.39018 18.9615C6.19698 19 5.96421 19 5.49956 19C5.0349 19 4.80257 19 4.60938 18.9615C3.81599 18.8037 3.19624 18.1836 3.03843 17.3902C3 17.197 3 16.9647 3 16.5V14.5C3 14.0353 3 13.8031 3.03843 13.6099C3.19624 12.8165 3.81599 12.1962 4.60938 12.0384C4.80257 12 5.0349 12 5.49956 12C5.96421 12 6.19698 12 6.39018 12.0384C7.18356 12.1962 7.8041 12.8165 7.96191 13.6099C8.00034 13.8031 8 14.0353 8 14.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Headphones;
