import PageContainer from '../../components/Containers/PageContainer';
import { useAuth } from '../../../contexts/AuthContext';
import useFixPage from '../../hooks/useFixPage';
import Calendar from './Calendar';
import EmptyIntegration from '../../components/EmptyIntegration';
import IllustrationCalendar from '../../svgs/IllustrationCalendar';
import usePaintScreen from '../../hooks/usePaintScreen';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import classNames from 'classnames';

const Schedule: React.FC = () => {
  useFixPage();
  const showPage = usePaintScreen(MODAL_TRANSITION_DURATION);
  const { authedProviderUser, showUpgradeBanner } = useAuth();

  const canSetupIntegrations =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  return (
    <PageContainer
      show={showPage}
      containerClassName={classNames(
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      <h1 className="mb-6 font-serif text-subtitle text-green-150">Schedule</h1>
      {authedProviderUser?.provider.calendlyIntegration ? (
        <Calendar />
      ) : (
        <EmptyIntegration
          title={
            canSetupIntegrations
              ? 'Set up your schedule'
              : 'Seamless client bookings'
          }
          description={
            canSetupIntegrations
              ? 'Connect your Calendly account with Homecoming to keep your schedule in sync and allow your clients to schedule sessions more easily.'
              : 'Your Calendly account needs to be connected by an administrator to keep your schedule in sync and allow clients to seamlessly book sessions through Homecoming. Please contact your team members to enable these features.'
          }
          ctaPath="/integrations/calendly"
          illustration={
            <IllustrationCalendar className="h-[80px] text-blue-100" />
          }
        />
      )}
    </PageContainer>
  );
};

export default Schedule;
