import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lottie from 'lottie-web';

import { useAuth } from '../../../contexts/AuthContext';

import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import { getFirstName } from '../../lib/copy';

import homecomingLoaderAnimation from '../../../assets/lotties/homecoming-loader.json';
import introStep1Image from '../../../assets/images/intro/step-1-client-table.png';
import introStep2Image from '../../../assets/images/intro/step-2-library.png';
import introStep3Image from '../../../assets/images/intro/step-3-follow-up.png';
import introStep4Image from '../../../assets/images/intro/step-4-launch-webpage.png';
import introStep5Image from '../../../assets/images/intro/step-5-schedule.png';

import WelcomeStep from './WelcomeStep';
import IntroStep, { IntroStepProps } from './IntroStep';

type IntroStepConfigProps = Omit<IntroStepProps, 'nextStep' | 'prevStep'>;

export const introStepConfig: IntroStepConfigProps[] = [
  {
    stepCount: 1,
    stepTitle: 'Add your clients',
    stepDescription: (
      <span>
        Add all of your clients to Homecoming to organize your notes, share
        resources and assessments, and communicate privately between sessions.
        <br />
        <b>
          Best of all: your clients don't need to sign-up or download the app
        </b>
      </span>
    ),
    stepImage: introStep1Image,
  },
  {
    stepCount: 2,
    stepTitle: 'Add your resources',
    stepDescription:
      'Homecoming gives you a place to store all of the resources that you use in your work with clients. Add links, PDFs, audio and image files, then group them into collections to stay organized.',
    stepImage: introStep2Image,
  },
  {
    stepCount: 3,
    stepTitle: 'Follow up on sessions',
    stepDescription:
      'We make it easy for you to give your clients something concrete to refer to after a session. Capture top insights and takeaways in a written or voice note, then attach resources and activities to keep the work going.',
    stepImage: introStep3Image,
  },
  {
    stepCount: 4,
    stepTitle: 'Launch your webpage in a flash',
    stepDescription:
      'Effortlessly create your online space, streamline client acquisition—from discovery calls to intake forms—and enable easy session bookings. Simplify your setup, focus on your practice.',
    stepImage: introStep4Image,
    imageClassNames: 'gradient-mask-b-60',
  },
  {
    stepCount: 5,
    stepTitle: 'Manage your sessions',
    stepDescription:
      'With our Calendly integration, clients can book sessions with minimal back-and-forth, and you can track and manage scheduled events in one place.',
    stepImage: introStep5Image,
    isFinalStep: true,
  },
  // {
  //   stepCount: 6,
  //   stepTitle: 'Book a call with us',
  //   stepDescription:
  //     "We offer every new member the opportunity to book a call with the Homecoming team. We'll help you understand out how to best use the software in your practice.",
  //   buttonElement: (nextStep) => (
  //     <div className="flex flex-col items-center">
  //       <Button
  //         theme="primary"
  //         title="Book a call"
  //         className="mb-2 sm:mb-4"
  //         onClick={() => window.open(BOOK_A_CALL_URL, '_blank')?.focus()}
  //       />
  //       <Button
  //         theme="secondary-white"
  //         title="Explore the platform"
  //         className="mb-6 sm:mb-12"
  //         onClick={nextStep}
  //         IconComponent={ArrowRightIcon}
  //       />
  //     </div>
  //   ),
  //   isFinalStep: true,
  // },
];

const Intro: FC = () => {
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();
  const location = useLocation();
  const skipLoader =
    (location.state as { skipLoader?: boolean })?.skipLoader ?? false;
  const goToStep = (location.state as { goToStep?: number })?.goToStep;

  const homecomingLoaderContainerRef = useRef(null);
  const [shouldShowLoader, setShouldShowLoader] = useState(!skipLoader);

  const [introStep, setIntroStep] = useState(goToStep ?? 0);
  const currentIntroStepConfigProps = introStepConfig[introStep - 1];

  const nextStep = () => {
    if (currentIntroStepConfigProps?.isFinalStep) {
      navigate('/home', { state: { fromIntro: true } });
      return;
    }
    setIntroStep((prevState) => prevState + 1);
  };

  const prevStep = () => {
    setIntroStep((prevState) => prevState - 1);
  };

  const CurrentStep = () => {
    if (introStep === 0) {
      return (
        <WelcomeStep
          nextStep={nextStep}
          firstName={getFirstName(authedProviderUser?.name ?? '')}
        />
      );
    } else if (introStep <= introStepConfig.length) {
      return (
        <IntroStep
          nextStep={nextStep}
          prevStep={prevStep}
          {...currentIntroStepConfigProps}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    const homecomingLoaderContainer = homecomingLoaderContainerRef.current;
    if (homecomingLoaderContainer) {
      const animation = lottie.loadAnimation({
        container: homecomingLoaderContainer,
        animationData: homecomingLoaderAnimation,
        loop: false,
        autoplay: true,
      });

      animation.setSpeed(1.25);

      animation.addEventListener('complete', () => {
        setShouldShowLoader(false);
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);

  const HomecomingLoaderScreen = (
    <div className="flex h-screen items-center justify-center">
      <div className="h-24 w-24" ref={homecomingLoaderContainerRef}></div>
    </div>
  );

  // Tracking
  useEffect(() => {
    if (!shouldShowLoader) {
      if (introStep === 0) {
        trackProviderEvent(ProviderAnalyticsEvent.IntroStepViewed, {
          step: 'Welcome',
        });
      } else if (introStep <= introStepConfig.length) {
        trackProviderEvent(ProviderAnalyticsEvent.IntroStepViewed, {
          step: introStep,
        });
      }
    }
  }, [introStep, shouldShowLoader]);

  return <>{shouldShowLoader ? HomecomingLoaderScreen : <CurrentStep />}</>;
};

export default Intro;
