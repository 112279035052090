import toast from 'react-hot-toast';
import { FC, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import isEmpty from 'lodash.isempty';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import {
  AssessmentType,
  CompletedProgramAssessmentsDataQuery,
  useCompletedProgramAssessmentsDataLazyQuery,
} from '../../../../../generated/graphql';

import { getTimeStamp } from '../../../../lib/date';
import { defaultEnterTransitionProps } from '../../../../lib/animation';

import ChevronRight from '../../../../svgs/ChevronRight';

import Slideover, {
  SLIDEOVER_TRANSITION_DURATION,
} from '../../../../components/Slideover';
import ToastAlert from '../../../../components/ToastAlert';
import SlideoverSection from '../../../../components/SlideoverSection';
import QuestionSection from '../../../../components/Assessments/QuestionsSection';
import InterpretationSection from '../../../../components/Assessments/InterpretationSection';
import ScoreSection from '../../../../components/Assessments/QuestionsSection/ScoreSection';
import Score from '../../../../components/Assessments/QuestionsSection/Score';
import {
  getCompletedAssessmentGraphData,
  getScoreProps,
} from '../../../../components/Assessments/helpers';
import {
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../../../lib/analytics';
import { colors } from '../../../../lib/colors';
import { CustomAxisTick, CustomizedDot, CustomTooltip } from './CustomGraph';

const AssessmentsCompletedSlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  title: string;
  subtitle?: string;
  patientFirstName?: string;
  programId: string;
  selectedProgramAssessmentAssessmentId: string | undefined;
  selectedProgramAssessmentType: AssessmentType | undefined;
}> = ({
  onClose,
  isOpen,
  title,
  subtitle = '',
  patientFirstName,
  programId,
  selectedProgramAssessmentAssessmentId,
  selectedProgramAssessmentType,
}) => {
  const [shownAssessmentQandAIndex, setShownAssessmentQandAIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setShownAssessmentQandAIndex(null);
      }, SLIDEOVER_TRANSITION_DURATION);
    }
  }, [isOpen]);

  const [
    getCompletedProgramAssessmentsData,
    {
      data: completedProgramAssessmentsData,
      error: completedProgramAssessmentsError,
      loading: isLoadingCompletedProgramAssessments,
    },
  ] = useCompletedProgramAssessmentsDataLazyQuery();

  useEffect(() => {
    if (
      selectedProgramAssessmentAssessmentId &&
      selectedProgramAssessmentType
    ) {
      getCompletedProgramAssessmentsData({
        variables: {
          programId,
          assessmentId: selectedProgramAssessmentAssessmentId,
        },
      });
      trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
        component: 'Assessments Slideover',
        originPage: 'Patient Profile',
        assessmentType: selectedProgramAssessmentType,
      });
    }
  }, [selectedProgramAssessmentAssessmentId, selectedProgramAssessmentType]);

  useEffect(() => {
    if (completedProgramAssessmentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch completed assessments."
          level="error"
        />
      ));
    }
  }, [completedProgramAssessmentsError]);

  const completedProgramAssessments =
    completedProgramAssessmentsData?.completedProgramAssessmentsByAssessment as CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'];

  const assessmentQandAShown = Boolean(shownAssessmentQandAIndex !== null);

  const assessmentTimestampTitle =
    shownAssessmentQandAIndex !== null
      ? getTimeStamp(
          completedProgramAssessments[shownAssessmentQandAIndex].completedAt,
          false,
        )
      : '';

  const { graphData, hasSecondaryScore, graphTitle } =
    getCompletedAssessmentGraphData(completedProgramAssessments);

  return (
    <Slideover
      isOpen={isOpen}
      fetching={isLoadingCompletedProgramAssessments}
      title={title}
      subtitle={!assessmentQandAShown ? subtitle : assessmentTimestampTitle}
      onClose={onClose}
      backButtonActive={assessmentQandAShown}
      onBackButtonClick={() => setShownAssessmentQandAIndex(null)}
      showContent={
        !isLoadingCompletedProgramAssessments &&
        !completedProgramAssessmentsError &&
        Boolean(completedProgramAssessmentsData)
      }
    >
      <Transition
        show={shownAssessmentQandAIndex === null}
        {...defaultEnterTransitionProps}
      >
        <SlideoverSection
          title={graphTitle}
          className="h-80 pl-0"
          titleClassName="px-6"
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={graphData}
              margin={{ top: 5, left: -8, right: 15, bottom: 5 }}
              width={3}
              height={4}
            >
              <CartesianGrid stroke={colors.neutral[50]} vertical={false} />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                axisLine={false}
                tick={<CustomAxisTick isXAxis />}
                dy={10}
              />
              <YAxis
                dx={-20}
                axisLine={false}
                tickLine={false}
                tick={<CustomAxisTick isYAxis />}
                domain={[
                  completedProgramAssessmentsData?.assessment?.scoreRange
                    ?.min ?? 0,
                  completedProgramAssessmentsData?.assessment?.scoreRange
                    ?.max ?? 100,
                ]}
              />
              <Line
                type="linear"
                dataKey="primaryScore"
                stroke={colors.neutral[100]}
                dot={<CustomizedDot />}
                activeDot={
                  <CustomizedDot
                    isActiveDot
                    setShownAssessmentQandAIndex={setShownAssessmentQandAIndex}
                  />
                }
                isAnimationActive={false}
              />
              {hasSecondaryScore && (
                <Line
                  type="linear"
                  dataKey="secondaryScore"
                  stroke={colors.neutral[100]}
                  strokeDasharray="4 4"
                  dot={<CustomizedDot isSecondaryScore />}
                  activeDot={
                    <CustomizedDot
                      isActiveDot
                      setShownAssessmentQandAIndex={
                        setShownAssessmentQandAIndex
                      }
                      isSecondaryScore
                    />
                  }
                  isAnimationActive={false}
                />
              )}
              <Tooltip cursor={false} content={<CustomTooltip />} />
            </LineChart>
          </ResponsiveContainer>
        </SlideoverSection>
        <InterpretationSection
          assessment={completedProgramAssessmentsData?.assessment}
        />
        <SlideoverSection
          title="History"
          className="px-0"
          titleClassName="px-6"
        >
          <div className="flex w-full flex-col items-center justify-start">
            {completedProgramAssessments?.map(
              (completedProgramAssessment, completedProgramAssessmentIndex) => {
                const { primary, secondary } = getScoreProps(
                  completedProgramAssessment,
                  selectedProgramAssessmentType,
                );

                return (
                  <div
                    key={completedProgramAssessmentIndex}
                    className="mb-2 w-full focus:ring-2 focus:ring-green-100"
                  >
                    <span className="w-full px-6 text-left text-small-caption">
                      {getTimeStamp(
                        completedProgramAssessment.completedAt,
                        false,
                      )}
                    </span>
                    <button
                      onClick={() =>
                        setShownAssessmentQandAIndex(
                          completedProgramAssessmentIndex,
                        )
                      }
                      className="mt-2 flex w-full flex-row items-center justify-between px-6 text-caption font-normal text-green-150 hover:bg-neutral-25 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50"
                    >
                      <div className="flex flex-col items-start justify-start">
                        <Score {...primary} feedItem />
                        {!isEmpty(secondary) && (
                          <Score {...secondary} feedItem />
                        )}
                      </div>
                      <ChevronRight className="h-6 w-6 text-green-150" />
                    </button>
                  </div>
                );
              },
            )}
          </div>
        </SlideoverSection>
        <SlideoverSection
          title="About"
          textContent={completedProgramAssessmentsData?.assessment?.description}
        />
      </Transition>
      <Transition show={assessmentQandAShown} {...defaultEnterTransitionProps}>
        {shownAssessmentQandAIndex !== null && (
          <>
            <ScoreSection
              assessmentType={selectedProgramAssessmentType}
              title="Score"
              completedAssessment={
                completedProgramAssessments[shownAssessmentQandAIndex]
              }
            />
            {Boolean(
              completedProgramAssessments[shownAssessmentQandAIndex]
                .additionalThoughts,
            ) && (
              <SlideoverSection
                title={`Notes from ${patientFirstName}`}
                textContent={
                  `"${completedProgramAssessments[shownAssessmentQandAIndex].additionalThoughts}"` ??
                  undefined
                }
              />
            )}
            <QuestionSection
              title="Responses"
              questionFrame={
                completedProgramAssessmentsData?.assessment?.questionFrame
              }
              completedAssessment={
                completedProgramAssessments[shownAssessmentQandAIndex]
              }
            />
          </>
        )}
      </Transition>
    </Slideover>
  );
};

export default AssessmentsCompletedSlideover;
