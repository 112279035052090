import { FC, Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { MailIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';

import MenuButton from '../../../components/MenuButton';
import IconButton from '../../../components/IconButton';

type ProviderCollectionMenuProps = {
  onClickRenameCollection?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDeleteCollection?: React.MouseEventHandler<HTMLButtonElement>;
  onClickSendToClient: React.MouseEventHandler<HTMLButtonElement>;
};

const ProviderCollectionMenu: FC<ProviderCollectionMenuProps> = ({
  onClickRenameCollection,
  onClickDeleteCollection,
  onClickSendToClient,
}) => {
  return (
    <div className="z-20 flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <IconButton
            aria-label="Resource actions"
            IconComponent={DotsHorizontalIcon}
            iconClassName="h-4 w-4"
            className="hover:bg-neutral-75"
            onClick={(e) => e.stopPropagation()}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-20 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md border border-neutral-100 bg-white shadow-lg focus:outline-none">
            <MenuButton
              titleText="Send to client"
              iconClassName="text-green-150"
              className="rounded-t-md"
              IconComponent={MailIcon}
              onClick={(e) => {
                onClickSendToClient(e);
                e.stopPropagation();
              }}
            />
            <MenuButton
              titleText="Rename collection"
              iconClassName="text-green-150"
              className="rounded-t-md"
              IconComponent={PencilAltIcon}
              onClick={(e) => {
                onClickRenameCollection?.(e);
                e.stopPropagation();
              }}
            />
            <MenuButton
              titleText="Delete collection"
              iconClassName="text-red-100"
              className="rounded-b-md text-red-100"
              activeText="text-red-100"
              IconComponent={TrashIcon}
              onClick={(e) => {
                onClickDeleteCollection?.(e);
                e.stopPropagation();
              }}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ProviderCollectionMenu;
