import isEmpty from 'lodash.isempty';
import { FC } from 'react';

import {
  AssessmentSchemaType,
  AssessmentType,
  CompletedProgramAssessmentsDataQuery,
} from '../../../generated/graphql';
import { getTimeStamp } from '../../lib/time';
import ChevronRight from '../../svgs/ChevronRight';
import SlideoverSection from '../SlideoverSection';
import InlineScore from './InlineScore';
import { getScoreProps } from './helpers';

type CompletedProgramAssessments =
  CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'];

const HistorySection: FC<{
  completedProgramAssessments?: CompletedProgramAssessments;
  assessmentType?: AssessmentType;
  assessmentSchemaType?: AssessmentSchemaType;
  setShownAssessmentQandAIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}> = ({
  completedProgramAssessments,
  assessmentType,
  setShownAssessmentQandAIndex,
}) => {
  return (
    <SlideoverSection title="History" className="px-0" titleClassName="px-6">
      <div className="flex w-full flex-col items-center justify-start divide-y divide-neutral-100/25">
        {completedProgramAssessments?.map(
          (completedProgramAssessment, completedProgramAssessmentIndex) => {
            const { primary, secondary } = getScoreProps(
              completedProgramAssessment,
              assessmentType,
            );

            return (
              <button
                key={completedProgramAssessmentIndex}
                onClick={() =>
                  setShownAssessmentQandAIndex(completedProgramAssessmentIndex)
                }
                className="focus:ring-green-5 flex w-full flex-row items-center justify-between px-6 py-3 hover:bg-neutral-25 focus:rounded-lg focus:outline-none focus:ring-2"
              >
                <div className="flex flex-col items-start">
                  <div className="text-small-caption text-green-150">
                    Sent{' '}
                    {getTimeStamp(completedProgramAssessment.startDate, false)}
                  </div>
                  {!isEmpty(primary) && (
                    <div className="my-1.5 w-full">
                      <div className="flex flex-col items-start justify-start">
                        <InlineScore {...primary} />
                        {!isEmpty(secondary) && <InlineScore {...secondary} />}
                      </div>
                    </div>
                  )}
                  <div className="text-small-caption font-medium text-neutral-125">
                    Completed{' '}
                    {getTimeStamp(
                      completedProgramAssessment.completedAt,
                      false,
                      true,
                    )}
                  </div>
                </div>
                <ChevronRight className="h-6 w-6 text-green-150" />
              </button>
            );
          },
        )}
      </div>
    </SlideoverSection>
  );
};

export default HistorySection;
