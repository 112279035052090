import { FC, useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

import { useAddPatientsV2Mutation } from '../../../../generated/graphql';

import { PatientRow } from '../../../types/PatientManagement';
import { RefetchScheduledEventsData } from '../../../types/scheduled-event';

import ArrowRightLong from '../../../svgs/ArrowRightLong';

import {
  emailRegisterOptions,
  fullNameRegisterOptions,
  phoneRegisterOptions,
} from '../../../lib/register-options';
import { getFirstName, getLastName } from '../../../lib/copy';

import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import InputGroup from '../../../components/InputGroup';
import { HC_ADD_PATIENTS_TEMPLATE_CSV } from '../../../components/Modals/AddPatientsModal/UseASpreadsheetMenu';
import ToastAlert from '../../../components/ToastAlert';
import Button from '../../../components/Button';

type InviteCalendlyInviteeToHomecomingModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  selectedInviteeData: PatientRow;
  refetchScheduledEventsData: RefetchScheduledEventsData;
};

const InviteCalendlyInviteeToHomecomingModal: FC<
  InviteCalendlyInviteeToHomecomingModalProps
> = ({
  isModalOpen,
  setClosed,
  selectedInviteeData,
  refetchScheduledEventsData,
}) => {
  const {
    register,
    setError,
    setValue,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<PatientRow>({
    mode: 'onSubmit',
    defaultValues: { name: '', email: '', phoneNumber: '' },
  });

  useEffect(() => {
    setValue('name', selectedInviteeData.name);
    setValue('email', selectedInviteeData.email);
    setValue('phoneNumber', selectedInviteeData.phoneNumber);
  }, [selectedInviteeData]);

  const [addPatientsMutation] = useAddPatientsV2Mutation();

  const [loading, setLoading] = useState(false);

  const nameRegister = register(`name`, fullNameRegisterOptions);
  const emailRegister = register(`email`, emailRegisterOptions);
  const phoneRegister = register(`phoneNumber`, phoneRegisterOptions);

  const handleErrors: SubmitErrorHandler<PatientRow> = (errors) => {
    console.log({ errors });
  };

  const onSubmit: SubmitHandler<PatientRow> = async (formData) => {
    const addPatientsInput = {
      newPatientLeads: [
        {
          inputId: 0,
          phoneNumber: '',
          email: formData.email!.toLowerCase(),
          firstName: getFirstName(formData.name!.trim()),
          lastName: getLastName(formData.name!.trim()),
        },
      ],
    };

    try {
      setLoading(true);
      const response = await addPatientsMutation({
        variables: {
          input: addPatientsInput,
        },
      });

      const addPatientsData = response?.data?.addPatientsV2;

      if (addPatientsData?.validationErrors) {
        addPatientsData?.validationErrors.forEach((fieldErrors) => {
          const {
            isDuplicate,
            emailInvalid,
            nameInvalid,
            phoneNumberInvalid,
            tagsInvalid,
          } = fieldErrors;
          if (isDuplicate) {
            setError(`email`, {
              type: 'Duplicate email',
              message: 'Email already registered',
            });
          }
          if (emailInvalid) {
            setError(`email`, {
              type: 'Invalid email',
              message: 'Email is invalid',
            });
          }
          if (nameInvalid) {
            setError(`name`, {
              type: 'Invalid name',
              message: 'Name is invalid',
            });
          }
          if (phoneNumberInvalid) {
            setError(`phoneNumber`, {
              type: 'Invalid phoneNumber',
              message: 'Phone number is invalid',
            });
          }
          if (tagsInvalid) {
            setError(`tags`, {
              type: 'Invalid tags',
              message: 'Tags are invalid',
            });
          }
        });
        setLoading(false);
        return;
      }

      const newPatients = addPatientsData?.newPatients;

      if (newPatients && newPatients?.length > 0) {
        await refetchScheduledEventsData();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Invitation sent to ${formData.name}.`}
          />
        ));
      }
      closeAndResetInviteCalendlyInviteeToHomecomingModal();
    } catch (err) {
      closeAndResetInviteCalendlyInviteeToHomecomingModal();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Couldn't invite ${formData.name}. Please try again later.`}
        />
      ));
    }
  };

  const closeAndResetInviteCalendlyInviteeToHomecomingModal = () => {
    setClosed();
    setTimeout(() => {
      setLoading(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      name="InviteCalendlyInviteeToHomecoming"
      isOpen={isModalOpen}
      setClosed={closeAndResetInviteCalendlyInviteeToHomecomingModal}
      fetching={loading}
      width="small"
    >
      <>
        <div className="mt-3 mb-6 flex flex-col items-center justify-center pr-16 pl-10">
          <div className="font-serif text-subtitle-small text-green-150">
            Invite your client to Homecoming
          </div>
        </div>
        <form className="flex h-auto w-full flex-col items-center justify-between px-9 pb-4">
          <div className="mb-4 flex h-full w-full flex-col items-center justify-between">
            <InputGroup
              label="Email"
              placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[1]?.[1] ?? ''}
              required
              readOnly
              type="email"
              useNaturalLettering
              containerClassName="mb-5 w-full"
              inputSize="small"
              errorMessage={errors?.email?.message}
              errorLocation="right"
              {...emailRegister}
            />
            <InputGroup
              label="Full name"
              placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[1]?.[0] ?? ''}
              required
              useNaturalLettering
              containerClassName="mb-5 w-full"
              inputSize="small"
              errorMessage={errors?.name?.message}
              errorLocation="right"
              {...nameRegister}
            />
            <InputGroup
              label="Phone number (optional)"
              placeholder={HC_ADD_PATIENTS_TEMPLATE_CSV?.[1]?.[2] ?? ''}
              containerClassName="mb-5 w-full"
              inputSize="small"
              useNaturalLettering
              errorMessage={errors?.phoneNumber?.message}
              {...phoneRegister}
            />
          </div>
          <div className="mt-3 flex w-full flex-col items-center justify-center">
            <div className="flex w-full flex-row items-center justify-center">
              <Button
                title="Invite your client"
                theme="primary"
                IconComponent={ArrowRightLong}
                onClick={() => {
                  handleSubmit(onSubmit, handleErrors)();
                }}
              />
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default InviteCalendlyInviteeToHomecomingModal;
