import { $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  $getRoot,
  $getSelection,
  CLEAR_EDITOR_COMMAND,
  LexicalEditor,
  RangeSelection,
} from 'lexical';
import { useEffect } from 'react';

import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';

import './index.css';
import EditorTheme from './theme';

const TEXT_HTML_MIME_TYPE = 'text/html';

type EditorProps = {
  innerRef?: React.MutableRefObject<LexicalEditor | null>;
  readOnly: boolean;
  initialContentHtml: string;
  onError?: (error: Error) => never | void;
};

function Placeholder() {
  return <div className="editor-placeholder">Write something beautiful...</div>;
}

// Using a helper to access the editor which requires the LexicalComposerContext provided by the LexicalComposer
// So `editor` can only be accessed from inside the LexicalComposer element
function EditorHelper({ initialContentHtml, readOnly, innerRef }: EditorProps) {
  const [editor] = useLexicalComposerContext();

  // Focus the editor when the editor is mounted and set the reference
  useEffect(() => {
    editor.focus();
    if (innerRef) innerRef.current = editor;
  }, [editor]);

  // After an editor is created, the readOnly mode can only be changed imperatively
  // See https://lexical.dev/docs/concepts/read-only
  useEffect(() => {
    editor.setEditable(!readOnly);
  }, [readOnly]);

  // Load the HTML into the editor once the initialContentHtml is set
  useEffect(() => {
    if (!initialContentHtml) return;
    // Clear editor before loading the HTML
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    // Load the HTML
    editor.update(() => {
      // Using native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(
        initialContentHtml,
        TEXT_HTML_MIME_TYPE,
      );

      // Generate LexicalNodes from the DOM instance
      const nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Append the HTML
      const selection = $getSelection() as RangeSelection;
      selection?.insertNodes(nodes);
    });
  }, [initialContentHtml, editor]);

  return null;
}

export default function Editor(props: EditorProps) {
  const onError = props.onError
    ? props.onError
    : (error: Error) => {
        throw error;
      };

  const initialConfig = {
    // The editor theme
    namespace: 'lexical-editor',
    theme: EditorTheme,
    // Handling of errors during update
    onError,
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="editor editor-container">
        {!props.readOnly && <ToolbarPlugin showJustificationButtons={false} />}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ClearEditorPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <EditorHelper {...props} />
        </div>
      </div>
    </LexicalComposer>
  );
}
