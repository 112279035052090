import {
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';
import DatePicker from 'react-datepicker';

import { ArrowNarrowLeftIcon, PlusSmIcon } from '@heroicons/react/outline';

import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { Transition as HistoryTransition } from 'history';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  usePatientsV2NamesQuery,
  PatientLifecycleStatusV2,
  LineItemInput,
  useSendInvoiceMutation,
  useSearchStripeProductsQuery,
  useProgramHasDefaultPaymentMethodQuery,
} from '../../../generated/graphql';

import Spinner from '../../svgs/Spinner';

import { useAuth } from '../../../contexts/AuthContext';

import { defaultTransitionProps } from '../../lib/animation';

import useFixPage from '../../hooks/useFixPage';
import useBlocker from '../../hooks/useBlocker';
import usePaintScreen from '../../hooks/usePaintScreen';

import PatientItem, {
  PatientOrPatientNameData,
  sortPatientsByAlphabeticalAscending,
} from '../../components/PatientItem';
import '../../components/Editor/index.css';
import Button from '../../components/Button';
import ToastAlert from '../../components/ToastAlert';
import IconButton from '../../components/IconButton';
import UnsavedChangesModal from '../../components/Modals/UnsavedChangesModal';
import SectionLabel from '../../components/SectionLabel';
import SelectMenu from '../../components/SelectMenu';
import { isAfterDate } from '../../lib/time';
import ErrorMessage from '../../components/ErrorMessage';
import TextAreaGroup from '../../components/TextAreaGroup';
import ModalCloseX from '../../svgs/ModalCloseX';
import { formatCurrency } from '../../lib/currency';
import ProductSearchbox from './ProductSearchbox';
import debounce from 'lodash.debounce';
import {
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
} from '../../../lib/analytics';
import classNames from 'classnames';
import Checkbox from '../../components/Checkbox';
import Tooltip from '../../components/Tooltip';

// This is a Stripe API limit
const MAX_STRIPE_INVOICE_NOTE_LENGTH = 500;

type InvoiceForm = {
  patient: PatientOrPatientNameData;
  dueDate: Date;
  note: string;
  lineItems: LineItemInput[];
  chargeAutomatically: boolean;
};

const InvoiceBuilder = () => {
  useFixPage();
  const navigate = useNavigate();
  const showPage = usePaintScreen();
  const { authedProviderUser } = useAuth();

  const location = useLocation();
  const initialProgramId = (location.state as { programId?: string })
    ?.programId;

  const [navTransition, setNavTransition] = useState<HistoryTransition>();
  const [submitting, setSubmitting] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const [productSearchQuery, setProductSearchQuery] = useState('');
  const { data: productSearchData } = useSearchStripeProductsQuery({
    variables: {
      query: productSearchQuery,
    },
    skip: !productSearchQuery,
  });
  const debouncedProductSearch = useCallback(
    debounce((query) => setProductSearchQuery(query.trim()), 300),
    [],
  );

  const {
    data: patientsData,
    loading: patientsLoading,
    error: patientsError,
  } = usePatientsV2NamesQuery({
    variables: {
      patientLifecycleStatuses: [
        PatientLifecycleStatusV2.Invited,
        PatientLifecycleStatusV2.Active,
      ],
    },
  });

  const patientsList = useMemo(() => {
    return sortPatientsByAlphabeticalAscending(
      patientsData?.patientNames?.filter((patient) =>
        // If doesn't have premium access, can't send invoice to existing patients, but can send to test patients
        // so filter out real patients here.
        authedProviderUser?.hasPremiumAccess ? true : patient.isTestProgram,
      ),
    );
  }, [patientsData, authedProviderUser]);

  useEffect(() => {
    if (patientsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch clients."
          level="error"
        />
      ));
    }
  }, [patientsError]);

  const [sendInvoiceMutation] = useSendInvoiceMutation();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState: { isDirty, errors },
  } = useForm<InvoiceForm>({
    mode: 'onSubmit',
    defaultValues: {},
  });
  const watchPatient = watch('patient');
  const watchLineItems = watch('lineItems');
  const watchNote = watch('note');

  const { data: programHasDefaultPaymentMethodData } =
    useProgramHasDefaultPaymentMethodQuery({
      variables: { programId: watchPatient?.programInstanceId },
      skip: !watchPatient?.programInstanceId,
    });

  const programHasDefaultPaymentMethod =
    programHasDefaultPaymentMethodData?.programHasDefaultPaymentMethod ?? false;

  useEffect(() => {
    // Make sure to reset to false if the program doesn't have a default payment method
    if (!programHasDefaultPaymentMethod) {
      setValue('chargeAutomatically', false);
    }
  }, [programHasDefaultPaymentMethod, setValue]);

  // Use initialProgramId to auto-select the patient once loaded
  useEffect(() => {
    if (initialProgramId && patientsList) {
      const patient = patientsList.find(
        (patient) => patient.programInstanceId === initialProgramId,
      );
      if (patient) {
        setValue('patient', patient);
      }
    }
  }, [patientsList, initialProgramId]);

  const hasUnsavedChanges = Boolean(isDirty);
  const triggerUnsavedChangesModal = Boolean(hasUnsavedChanges && !submitting);
  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [triggerUnsavedChangesModal],
  );
  useBlocker(blocker, triggerUnsavedChangesModal);

  const onSendInvoice: SubmitHandler<InvoiceForm> = async ({
    patient,
    note,
    dueDate,
    lineItems,
    chargeAutomatically,
  }) => {
    if (!patient?.programInstanceId) return;

    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      originPage: AnalyticsPage.InvoiceBuilder,
      buttonName: 'Send invoice',
    });

    setSubmitting(true);

    try {
      const { data } = await sendInvoiceMutation({
        variables: {
          input: {
            programId: patient.programInstanceId,
            note,
            dueDate,
            lineItems: lineItems.map((item) => ({
              ...item,
              price: Number(item.price),
              quantity: Number(item.quantity),
            })),
            chargeAutomatically:
              chargeAutomatically && programHasDefaultPaymentMethod,
          },
        },
      });

      if (data?.sendInvoice) {
        resetForm();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully sent an invoice to ${watchPatient.firstName}`}
          />
        ));
        navigate(-1);
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      setSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<InvoiceForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  return (
    <>
      <div className="relative">
        <Transition show={showPage} {...defaultTransitionProps}>
          <div className="fixed z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-3 px-20">
            <div className="flex w-full flex-row items-center justify-between">
              <IconButton
                IconComponent={ArrowNarrowLeftIcon}
                iconClassName="text-green-100 w-6"
                aria-label="Back"
                onClick={() => navigate(-1)}
              />
              <div className="ml-3 flex flex-row items-center justify-start truncate text-category text-green-150 ">
                <div className="font-medium">
                  Invoice {watchPatient ? `for ${watchPatient.firstName}` : ''}
                </div>
              </div>

              <div className="flex flex-row items-center justify-end">
                {Boolean(submitting) && (
                  <Spinner className="mr-3 h-5 w-5 text-neutral-125" />
                )}
                <Button
                  title="Send"
                  size="small"
                  className="px-6 py-1.5"
                  onClick={handleSubmit(onSendInvoice, handleErrors)}
                  disabled={submitting}
                />
              </div>
            </div>
          </div>
        </Transition>
        <div className="flex h-[calc(100vh-65px)] w-full flex-1 items-center justify-center overflow-y-scroll pt-24">
          <div className="flex h-full w-9/12 max-w-[720px] flex-col gap-8">
            <div className="flex flex-row gap-5">
              <div className="flex flex-1 flex-col">
                <SectionLabel title="Client" />
                <Controller
                  name="patient"
                  control={control}
                  defaultValue={undefined}
                  rules={{
                    required: {
                      value: true,
                      message: 'Choose your client',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <SelectMenu
                      autofocus
                      label="Choose a client"
                      hideLabel
                      buttonClassName="max-h-12"
                      onChange={onChange}
                      fieldValue={value}
                      disabled={Boolean(patientsLoading)}
                      fieldOptions={patientsList ?? []}
                      SelectOptionComponent={PatientItem}
                      placeholder={
                        patientsList ? 'Find your client' : 'Loading clients...'
                      }
                    />
                  )}
                />
                <ErrorMessage className="mt-2">
                  {errors.patient?.message}
                </ErrorMessage>
              </div>
              <div className="flex w-72 flex-col">
                <SectionLabel title="Due date" />
                <Controller
                  name="dueDate"
                  control={control}
                  defaultValue={undefined}
                  rules={{
                    required: {
                      value: true,
                      message: 'Pick a due date',
                    },
                    validate: (value) =>
                      (value && isAfterDate(value, new Date())) ||
                      'Pick a date in the future',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selectsStart
                      selected={value}
                      filterDate={(date) => isAfterDate(date)}
                      previousMonthButtonLabel="←"
                      nextMonthButtonLabel="→"
                      placeholderText="MM/DD/YYYY"
                      popperClassName="mt-2"
                      onChange={(date: Date) => date && onChange(date)}
                    />
                  )}
                />
                <ErrorMessage className="mt-2">
                  {errors.dueDate?.message}
                </ErrorMessage>
              </div>
            </div>
            <div className="flex flex-col">
              <SectionLabel title="Line items" />
              <Controller
                name="lineItems"
                control={control}
                defaultValue={[{ name: '', quantity: 1 }]}
                rules={{
                  required: {
                    value: true,
                    message: 'Add at least one line item',
                  },
                  validate: (value) => {
                    if (value.length === 0) {
                      return 'Add at least one line item';
                    }

                    for (const item of value) {
                      if (!item.name) {
                        return 'Line item name is required';
                      }
                      if (item.quantity <= 0) {
                        return 'Line item quantity is required';
                      }
                      if (!item.price) {
                        return 'Line item price is required';
                      }
                      if (item.price < 0) {
                        return 'Line item price must be non-negative';
                      }
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <table className="table-auto text-left text-neutral-125">
                      <thead className="rounded-md border border-neutral-50 bg-neutral-25 text-caption">
                        <tr>
                          <th>Service</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="text-left text-caption">
                        {value.map((item, index) => {
                          const lineItemInput = (
                            prop: keyof LineItemInput,
                            type: HTMLInputTypeAttribute = 'text',
                            props = {},
                          ) => (
                            <input
                              className="w-full border-none p-0 py-1 text-caption focus:outline-none focus:ring-0"
                              type={type}
                              value={item[prop] ?? ''}
                              onChange={(event) => {
                                const newValue = event.target.value as never;
                                if (value[index][prop] === newValue) return;

                                const newItems = [...value];
                                newItems[index][prop] = newValue;
                                // Create a new product if the price for an existing product is modified
                                if (
                                  newItems[index].existingStripePriceId &&
                                  prop === 'price'
                                ) {
                                  newItems[index].existingStripePriceId = null;
                                }
                                onChange(newItems);
                              }}
                              {...props}
                            />
                          );

                          return (
                            <tr
                              className="border border-neutral-50"
                              key={index}
                            >
                              <td className="px-0">
                                <ProductSearchbox
                                  name={item.name ?? ''}
                                  setName={(name) => {
                                    if (value[index].name === name) return;
                                    const newItems = [...value];
                                    newItems[index].name = name;
                                    // Create a new product if the name for an existing product is modified
                                    if (newItems[index].existingStripePriceId) {
                                      newItems[index].existingStripePriceId =
                                        null;
                                    }
                                    onChange(newItems);
                                    debouncedProductSearch(name);
                                  }}
                                  searchResults={
                                    productSearchData?.searchStripeProducts ??
                                    []
                                  }
                                  selectedProduct={
                                    item.name
                                      ? {
                                          name: item.name,
                                        }
                                      : null
                                  }
                                  setSelectedProduct={(product) => {
                                    if (!product) return;
                                    const newItems = [...value];
                                    newItems[index].name = product.name;
                                    newItems[index].price = product.price;
                                    newItems[index].existingStripePriceId =
                                      product.stripePriceId;
                                    onChange(newItems);
                                  }}
                                  className="px-4"
                                />
                              </td>
                              <td className="w-16 align-middle">
                                {lineItemInput('quantity', 'number', {
                                  placeholder: 0,
                                  min: 0,
                                })}
                              </td>
                              <td className="flex w-28 flex-row items-center align-middle">
                                $
                                {lineItemInput('price', 'number', {
                                  min: 0.01,
                                  step: 0.01,
                                  placeholder: formatCurrency(0).slice(1),
                                })}
                              </td>
                              <td className="w-36 align-middle">
                                {formatCurrency(
                                  item.quantity * (item.price ?? 0),
                                )}
                              </td>
                              <td
                                className={classNames(
                                  'w-6 align-middle',
                                  value.length <= 1 && 'invisible',
                                )}
                              >
                                <IconButton
                                  aria-label="Close"
                                  IconComponent={ModalCloseX}
                                  iconClassName="h-5 w-5 text-neutral-125"
                                  onClick={() => {
                                    const newItems = [...value];
                                    newItems.splice(index, 1);
                                    onChange(newItems);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                        <tr
                          className="cursor-pointer border border-neutral-50 hover:bg-neutral-25"
                          onClick={() => {
                            const newItems = [...value];
                            newItems.push({
                              name: '',
                              quantity: 1,
                            });
                            onChange(newItems);
                          }}
                        >
                          <td colSpan={5}>
                            <div className="flex flex-row gap-1 text-neutral-110">
                              Add item <PlusSmIcon className="h-4 w-4" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border border-neutral-50">
                          <td colSpan={2}></td>
                          <td className="font-bold">Total due</td>
                          <td colSpan={2}>
                            {formatCurrency(
                              watchLineItems
                                ? watchLineItems.reduce(
                                    (acc, item) =>
                                      acc + item.quantity * (item?.price ?? 0),
                                    0,
                                  )
                                : 0,
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorMessage className="mt-2">
                      {errors.lineItems?.message}
                    </ErrorMessage>
                  </>
                )}
              />
            </div>
            <div className="flex flex-col">
              <Controller
                name="chargeAutomatically"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Tooltip
                      content="This option is only available for clients that have saved default payment methods."
                      enabled={!programHasDefaultPaymentMethod}
                    >
                      <Checkbox
                        id="chargeAutomatically"
                        checked={value}
                        onChange={onChange}
                        className="mr-4"
                        labelContent="Attempt to charge automatically using client's default payment method"
                        disabled={!programHasDefaultPaymentMethod}
                      />
                    </Tooltip>
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <SectionLabel title="Note for client" />
              <Controller
                name="note"
                control={control}
                defaultValue={undefined}
                rules={{
                  maxLength: {
                    value: MAX_STRIPE_INVOICE_NOTE_LENGTH,
                    message: `Note must be at most ${MAX_STRIPE_INVOICE_NOTE_LENGTH} characters`,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextAreaGroup
                    label="note"
                    value={value}
                    rows={3}
                    labelHidden
                    inputSize="small"
                    className="focused:border-green-50 mb-8 h-auto"
                    containerClassName="w-full"
                    errorMessage={errors.note?.message}
                    onChange={onChange}
                    characterCounter
                    maxLength={MAX_STRIPE_INVOICE_NOTE_LENGTH}
                    maxLengthValue={MAX_STRIPE_INVOICE_NOTE_LENGTH}
                    currentLengthValue={watchNote?.length ?? 0}
                  />
                )}
              />
              <span className="text-caption text-neutral-125">
                Want to customize the look of your invoices? Open your{' '}
                <a
                  href="https://dashboard.stripe.com/settings/branding"
                  target="_blank"
                  rel="noreferrer"
                >
                  branding settings
                </a>
                .
              </span>
            </div>
            <div className="flex flex-col pb-4"></div>
          </div>
        </div>
      </div>
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => navTransition?.retry()}
      />
      {Boolean(!showPage) && <Spinner className="mx-auto mt-24" />}
    </>
  );
};

export default InvoiceBuilder;
