import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUpdateStripeSubscriptionMutation } from '../../../generated/graphql';
import { useAuth } from '../../../contexts/AuthContext';
import Loading from '../../pages/Loading';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import GtmHelpers from '../../../lib/gtm';

const SubscriptionUpdated: FC = () => {
  const [updateStripeSubscription] = useUpdateStripeSubscriptionMutation();

  const navigate = useNavigate();
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const redirect = () => {
    navigate('/settings/billing');
  };

  const updateSubscription = async () => {
    try {
      await updateStripeSubscription();
      try {
        GtmHelpers.trackEvent(
          ProviderAnalyticsEvent.ProviderSubscriptionUpdated,
          authedProviderUser?.id ?? '',
        );
      } catch (err) {
        console.error(err);
      }
      await refreshAuthedProviderUser();
      redirect();
    } catch (err) {
      trackProviderEvent(
        ProviderAnalyticsEvent.ProviderSubscriptionUpdateFailed,
        {
          reason: 'Server error',
        },
      );
      alert(
        'Failed to update your subscription preferences. Please refresh to try again or contact support@homecoming.health',
      );
      throw err;
    }
  };

  useEffect(() => {
    // Can't update until the authedProviderUser is loaded
    if (!authedProviderUser) {
      return;
    }

    // Clear, update subscription
    updateSubscription();
  }, [authedProviderUser]);

  return <Loading />;
};

export default SubscriptionUpdated;
