import { SvgIconComponent } from '../types/svgs';

const Help: SvgIconComponent = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill="#00B20A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5537 10.032C4.89786 10.6144 5.38547 11.102 5.96791 11.4462L4.52334 12.8907C3.97682 12.5016 3.49832 12.0231 3.10913 11.4765L4.5537 10.032ZM5.96791 4.55382C5.38547 4.89798 4.89786 5.3856 4.5537 5.96803L3.10913 4.52347C3.49832 3.97695 3.97682 3.49844 4.52334 3.10925L5.96791 4.55382ZM12.8906 11.4765C12.5014 12.0231 12.0229 12.5016 11.4764 12.8907L10.0318 11.4462C10.6143 11.102 11.1019 10.6144 11.4461 10.032L12.8906 11.4765ZM11.4764 3.10925C12.0229 3.49844 12.5014 3.97695 12.8906 4.52347L11.4461 5.96803C11.1019 5.3856 10.6143 4.89798 10.0318 4.55382L11.4764 3.10925Z"
        fill="#E2FBE5"
      />
    </svg>
  );
};

export default Help;
