import { FC } from 'react';

import {
  useStripeCheckoutPortalLazyQuery,
  useStripeCustomerPortalLazyQuery,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';

import Spinner from '../../svgs/Spinner';

import { Transition } from '@headlessui/react';
import { defaultTransitionProps } from '../../lib/animation';
import SettingsItem, { SettingsRow } from '../../pages/Settings/SettingsItem';
import toast from 'react-hot-toast';
import ToastAlert from '../ToastAlert';
import { getTimeStamp } from '../../lib/time';
import { useLocation, useNavigate } from 'react-router-dom';
import { stripLeadingSlash } from '../../lib/string';

export enum BillingState {
  Trialing = 'Trialing',
  Unsubscribed = 'Unsubscribed',
  Subscribed = 'Subscribed',
  SubscriptionEnding = 'SubscriptionEnding', // Canceled but still currently subscribed until the end of the billing period
}

const BillingSection: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authedProviderUser, isAuthedProviderUserLoading } = useAuth();

  const [getStripeCustomerPortal, { loading: stripeCustomerPortalLoading }] =
    useStripeCustomerPortalLazyQuery();

  const goToStripeCustomerPortal = async () => {
    try {
      const { data } = await getStripeCustomerPortal();

      if (data?.stripeCustomerPortal.url) {
        window.location.href = data.stripeCustomerPortal.url;
      }
    } catch (err) {
      console.error('Error getting Stripe Customer Portal:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong retrieving your billing info. Please contact us at support@homecoming.health."
          level="error"
        />
      ));
    }
  };

  const [getStripeCheckoutPortal, { loading: stripeCheckoutPortalLoading }] =
    useStripeCheckoutPortalLazyQuery();

  const goToStripeCheckoutPortal = async () => {
    try {
      const { data } = await getStripeCheckoutPortal();

      if (data?.stripeCheckoutPortal.url) {
        window.location.href = data.stripeCheckoutPortal.url;
      }
    } catch (err) {
      console.error('Error getting Stripe Checkout Portal:', err);
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong. Please contact us at support@homecoming.health."
          level="error"
        />
      ));
    }
  };

  const ctaLoading = stripeCustomerPortalLoading || stripeCheckoutPortalLoading;

  const isSubscribed = authedProviderUser?.isProviderSubscribed ?? false;
  const hasBeenSubscribed = authedProviderUser?.hasBeenSubscribed ?? false;
  const isTrialing = authedProviderUser?.isTrialing ?? false;
  const trialEndsAt =
    isTrialing && authedProviderUser ? authedProviderUser.trialEndsAt : null;
  const subscriptionEndsAt =
    authedProviderUser?.provider.subscriptionEndsAt ?? null;

  const billingState =
    isSubscribed && !subscriptionEndsAt
      ? BillingState.Subscribed
      : isSubscribed && subscriptionEndsAt
      ? BillingState.SubscriptionEnding
      : isTrialing
      ? BillingState.Trialing
      : BillingState.Unsubscribed;

  const subscriptionStatus =
    billingState === BillingState.Subscribed
      ? `Subscribed`
      : billingState === BillingState.SubscriptionEnding
      ? `Subscribed until ${getTimeStamp(
          subscriptionEndsAt,
          false,
          false,
          true,
        )}`
      : billingState === BillingState.Trialing
      ? `Trialing until ${getTimeStamp(trialEndsAt, false, false, true)}`
      : 'Not subscribed';

  const stripeCtaText =
    billingState === BillingState.Subscribed
      ? 'Manage your subscription'
      : hasBeenSubscribed
      ? 'Reactivate your subscription'
      : 'Activate your subscription';

  const stripeCtaOnClick =
    billingState === BillingState.Trialing ||
    billingState === BillingState.Unsubscribed
      ? goToStripeCheckoutPortal
      : goToStripeCustomerPortal;

  const settingsItems: SettingsRow[] = [
    { label: 'Subscription status', data: subscriptionStatus },
    {
      label: '',
      data: stripeCtaText,
      onClick: stripeCtaOnClick,
      loading: ctaLoading,
    },
    {
      label: '',
      data: 'Explore other plans',
      onClick: () => {
        navigate('/subscribe', {
          state: {
            stripeCheckoutPortalWebRedirectPath: stripLeadingSlash(
              location.pathname,
            ),
          },
        });
      },
    },
  ];

  return (
    <div className="mt-2 flex w-[650px] flex-col">
      <Transition
        show={!isAuthedProviderUserLoading && Boolean(authedProviderUser)}
        {...defaultTransitionProps}
        className="w-full"
      >
        {settingsItems.map((settingsItem, index) => (
          <SettingsItem
            key={`settingsItem_${index}`}
            settingsRow={settingsItem}
          />
        ))}
      </Transition>

      {isAuthedProviderUserLoading && <Spinner className="mx-auto mt-12" />}
    </div>
  );
};

export default BillingSection;
