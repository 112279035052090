import classNames from 'classnames';

// TODO: make a generic progress bar component
const ContentCompletionProgress: React.FC<{
  contentCompletedPercentage: number;
}> = ({ contentCompletedPercentage }) => {
  if (contentCompletedPercentage === 0) {
    return (
      <span className="block truncate text-caption text-green-150">
        Content not started
      </span>
    );
  }

  const completionValue = contentCompletedPercentage;

  const completionPercentage = `${Math.round(completionValue) ?? 0}%`;

  const completionWidthStyle = {
    width: completionPercentage,
  };
  return (
    <div className="flex flex-row items-center justify-start">
      <span className="mr-6 w-5 text-caption text-green-150">
        {completionPercentage}
      </span>
      <div className="flex h-1 w-[150px] flex-row items-center justify-start rounded bg-green-50">
        <div
          style={completionWidthStyle}
          className={classNames(
            'flex h-1 flex-row items-center justify-start rounded bg-green-125',
          )}
        />
      </div>
    </div>
  );
};

export default ContentCompletionProgress;
