import classNames from 'classnames';
import { FC } from 'react';

import padlock from '../../../assets/svgs/padlock.svg';
import { RefreshIcon } from '@heroicons/react/outline';

const FollowUpTitle: FC<{
  isSaving?: boolean;
  lastSavedAt?: string;
  className?: string;
}> = ({ isSaving, lastSavedAt, className }) => {
  return (
    <div
      className={classNames(
        'flex flex-shrink-0 flex-col items-center overflow-hidden',
        className,
      )}
    >
      <div className="flex flex-row items-center justify-center gap-x-1 text-center text-small-caption text-neutral-125">
        {lastSavedAt &&
          (isSaving ? (
            <>
              <RefreshIcon className="h-4 w-4" />
              Saving...
            </>
          ) : (
            <>
              Draft saved
              <img src={padlock} alt="auto-saved" className="h-4 w-4" />
            </>
          ))}
      </div>
    </div>
  );
};

export default FollowUpTitle;
