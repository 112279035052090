// TODO: See if we can share lib functions with the mobile repo.

import { DateTime } from 'luxon';

export function pluralize(
  count: number,
  singular: string,
  plural: string,
  shouldShowCount = true,
): string {
  const noun = `${count === 1 ? singular : plural}`;

  return shouldShowCount ? `${count} ${noun}` : noun;
}

/** Return str with the first character of each word to upper case. */
export function capitalizeWords(str: string): string {
  if (str.length === 0) {
    return str;
  }

  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

/** Return str with the first character of the entire string in upper case, and the rest lower case.
 *  e.g. "let's GO to the beach" -> "Let's go to the beach"
 */
export function capitalizeSentence(str: string | undefined): string {
  if (!str) {
    return '';
  }

  if (str.length === 0) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

/** Return str with the first name from a fullName string
 *  e.g. "Michelle Smith" -> "Michelle"
 *  e.g. "Jose Alvarez Rodriguez" -> "Jose Alvarez"
 */
export const getFirstName = (fullName: string) => {
  if (!fullName) return '';
  const names = capitalizeWords(fullName).split(' ');
  if (names.length === 1) {
    return names[0];
  }
  return names.slice(0, names.length - 1).join(' ');
};

/** Return str with the last name from a fullName string
 *  e.g. "Michelle Smith" -> "Smith"
 *  e.g. "Jose Alvarez Rodriguez" -> "Rodriguez"
 */
export const getLastName = (fullName: string) => {
  const names = capitalizeWords(fullName).split(' ');
  if (names.length < 2) return '';
  return names[names.length - 1];
};

/** Return names array to sentence
 *  e.g. ["Rick Doblin", "Mike Tyson", "Terrence McKenna"] -> "Rick Doblin, Mike Tyson, and Terrence McKenna"
 */
export const namesToOxfordCommaList = (names: string[]) => {
  if (names.length === 0) return '';
  if (names.length === 1) return names[0];
  const last = names.pop();
  return names.join(', ') + ' and ' + last;
};

export const NUMBER_TO_WORD = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
];

/**
 * Return clock time for given dateObj in standard digital clock format by default
 */

export function displayTime(date: DateTime, dateFormat = 'hh:mm a'): string {
  return date.toFormat(dateFormat).toLowerCase();
}

export const removeFileExtension = (file: File | undefined) => {
  return file?.name.split('.')[0] ?? '';
};
