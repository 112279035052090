import { Combobox, Transition } from '@headlessui/react';
import {
  XIcon,
  ChevronDownIcon,
  TrashIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import _ from 'lodash';
import { FC, useState, useEffect, Fragment } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  ProviderUserMemberDataFragment,
  useProviderUserPatientsQuery,
  useAddPatientsToProviderUsersMutation,
  useRemovePatientsFromProviderUsersMutation,
  usePatientsV2NamesQuery,
  PatientLifecycleStatusV2,
} from '../../../../../generated/graphql';
import { RefetchTeamMembers } from '../../../../pages/Settings/TeamSection/TeamMembersTable';
import { AssignPatientsFormData } from '../../../../types/teamMembers';
import Avatar from '../../../Avatar';
import Button from '../../../Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../Modal';
import { sortPatientsByAlphabeticalAscending } from '../../../PatientItem';
import ToastAlert from '../../../ToastAlert';
import AssignedPatientsTable from './AssignedPatientsTable';

type AssignPatientsModalProps = {
  selectedProviderUser?: ProviderUserMemberDataFragment;
  isOpen: boolean;
  setClosed: () => void;
  refetchTeamMembers: RefetchTeamMembers;
};

const AssignPatientsModal: FC<AssignPatientsModalProps> = ({
  selectedProviderUser,
  isOpen,
  setClosed,
  refetchTeamMembers,
}) => {
  const assignPatientsForm = useForm<AssignPatientsFormData>({
    mode: 'onSubmit',
    defaultValues: {
      patients: [],
    },
  });

  const { reset: resetForm, control, watch, setValue } = assignPatientsForm;

  const watchPatients = watch('patients');

  // Filtering the client selector by search query
  const [patientQuery, setPatientQuery] = useState('');

  const {
    data: providerUserPatientsData,
    refetch: refetchProviderUserPatients,
  } = useProviderUserPatientsQuery({
    variables: { providerUserId: selectedProviderUser?.id },
    skip: !Boolean(selectedProviderUser?.id),
  });

  useEffect(() => {
    if (isOpen) refetchProviderUserPatients();
  }, [isOpen]);

  const [addPatientsToProviderUsers] = useAddPatientsToProviderUsersMutation();

  const [removePatientsFromProviderUsers] =
    useRemovePatientsFromProviderUsersMutation();

  const providerUserPatients = providerUserPatientsData?.providerUserPatients;

  const { data: patientsData } = usePatientsV2NamesQuery({
    variables: {
      patientLifecycleStatuses: [
        PatientLifecycleStatusV2.Invited,
        PatientLifecycleStatusV2.Active,
      ],
    },
  });

  const patientsList = sortPatientsByAlphabeticalAscending(
    patientsData?.patientNames,
  );

  const watchedProgramInstanceIds = watchPatients
    .map(
      (patient) =>
        patient.__typename === 'PatientName' && patient.programInstanceId,
    )
    .filter(Boolean);

  const providerUserPatientsInstanceIds =
    providerUserPatients?.map((patient) => patient.programInstanceId) || [];

  const filteredPatients = patientsList
    .filter((patient) => {
      return (
        !watchedProgramInstanceIds.includes(patient.programInstanceId) &&
        !providerUserPatientsInstanceIds.includes(patient.programInstanceId)
      );
    })
    .filter((patient) => {
      return (
        patient?.firstName
          ?.toLowerCase()
          .includes(patientQuery.toLowerCase()) ||
        patient?.lastName?.toLowerCase().includes(patientQuery.toLowerCase())
      );
    });

  const handleAssignPatients = async () => {
    try {
      await addPatientsToProviderUsers({
        variables: {
          input: {
            providerUserIds: [selectedProviderUser?.id],
            programIds: watchedProgramInstanceIds,
          },
        },
      });

      setValue('patients', []);

      await refetchProviderUserPatients();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully assigned ${
            watchedProgramInstanceIds.length
          } client${
            Boolean(watchedProgramInstanceIds.length > 1) ? 's' : ''
          } to ${selectedProviderUser.name}`}
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Error assigning ${watchedProgramInstanceIds.length} clients`}
        />
      ));
    }
  };

  const handleRemovePatientFromProviderUser = async (programId: string) => {
    try {
      await removePatientsFromProviderUsers({
        variables: {
          input: {
            providerUserIds: [selectedProviderUser?.id],
            programIds: [programId],
          },
        },
      });

      await refetchProviderUserPatients();

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={`Successfully removed client from ${selectedProviderUser.name}`}
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Error removing client from ${selectedProviderUser.name}`}
        />
      ));
    }
  };

  const closeAndResetModal = async () => {
    setClosed();
    setTimeout(() => {
      resetForm();
    }, MODAL_TRANSITION_DURATION);
    await refetchTeamMembers();
  };

  return (
    <Modal
      width="large"
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      hasPadding={false}
      hasCloseRow={false}
    >
      <FormProvider {...assignPatientsForm}>
        <form
          className="flex min-h-[80vh] w-full flex-row items-stretch"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex min-h-full w-1/2 flex-col items-start p-8">
            <div className="mt-11 mb-5 flex w-full flex-row items-center justify-between">
              <div className="font-serif text-subtitle-small">
                {selectedProviderUser?.name}'s clients
              </div>
              <div className="flex flex-row items-center justify-start text-caption text-neutral-125">
                <div>Assigned</div>
                <div className="ml-2 rounded-full bg-neutral-25 px-2 py-[2px] font-medium">
                  {providerUserPatients?.length}
                </div>
              </div>
            </div>
            <AssignedPatientsTable
              patients={providerUserPatients}
              handleRemovePatientFromProviderUser={
                handleRemovePatientFromProviderUser
              }
            />
          </div>
          <div className="flex min-h-full w-1/2 flex-col items-start justify-between bg-neutral-50 p-8 pt-4">
            <div className="flex w-full flex-col items-start justify-start">
              <div className="mb-6 flex h-[40px] w-full flex-row items-center justify-end">
                <Button
                  onClick={setClosed}
                  size="small"
                  title="Close"
                  iconPosition="left"
                  theme="secondary-white"
                  IconComponent={XIcon}
                />
              </div>
              <div className="mb-4 flex flex-row items-center justify-between">
                <div className="font-serif text-small">
                  Assign new clients{' '}
                  {Boolean(watchPatients.length) && (
                    <span>({watchPatients.length})</span>
                  )}
                </div>
              </div>
              <Controller
                name="patients"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: {
                    value: true,
                    message: 'Choose your clients',
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <Combobox
                        as="div"
                        className="relative mb-5 w-full text-body"
                        value={value}
                        onChange={(patients) => {
                          setPatientQuery('');
                          onChange(patients);
                        }}
                        immediate
                        multiple
                      >
                        <Combobox.Input
                          className={classNames(
                            'relative flex w-full cursor-default flex-row items-center justify-start text-body placeholder:text-body',
                            'max-h-12 rounded-md bg-white p-4 text-left text-green-150 shadow-sm sm:text-caption',
                            'border-0 placeholder:text-neutral-125/75 focus:border-green-50 focus:outline-none focus:ring-2 focus:ring-green-50',
                          )}
                          onChange={(event) =>
                            setPatientQuery(event.target.value)
                          }
                          placeholder="Search for a client"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-neutral-125"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setPatientQuery('')}
                        >
                          <Combobox.Options className="no-scrollbar absolute z-10 mt-1 max-h-60 w-full divide-y divide-neutral-75 overflow-auto rounded-lg border border-neutral-75  bg-white py-1 text-caption shadow-lg focus:outline-none focus:ring-0">
                            {filteredPatients.map((patient) => (
                              <Combobox.Option
                                key={patient.programInstanceId}
                                value={patient}
                              >
                                {({ active, selected }) => {
                                  const patientName = `${patient?.firstName} ${patient?.lastName}`;
                                  return (
                                    <div
                                      className={classNames(
                                        'relative flex cursor-pointer select-none flex-row truncate py-3 px-5 text-green-150 hover:bg-neutral-25',
                                        {
                                          'bg-neutral-25': selected || active,
                                        },
                                      )}
                                    >
                                      <Avatar
                                        size="small"
                                        name={patientName}
                                        imageUrl={
                                          patient?.profileImageMedia?.url
                                        }
                                        className="mr-2"
                                      />
                                      {patientName}
                                    </div>
                                  );
                                }}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </Transition>
                      </Combobox>

                      {Boolean(watchPatients.length) && (
                        <ul className="no-scrollbar mb-4 flex max-h-[450px] w-full flex-row flex-wrap gap-2 overflow-y-scroll">
                          {watchPatients.map((patient) => {
                            if (patient.__typename === 'PatientName') {
                              const name = `${patient.firstName} ${patient.lastName}`;

                              return (
                                <li
                                  key={patient.programInstanceId}
                                  className="relative flex w-full flex-row justify-between gap-x-2 rounded-full bg-neutral-50 py-1.5"
                                >
                                  <div className="flex flex-row items-center justify-start">
                                    <Avatar
                                      size="medium"
                                      name={name}
                                      imageUrl={patient?.profileImageMedia?.url}
                                    />
                                    <div className="relative ml-3 font-serif text-category">
                                      {name}
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      onChange(
                                        _.without(watchPatients, patient),
                                      );
                                    }}
                                  >
                                    <TrashIcon
                                      className={
                                        'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                                      }
                                    />
                                  </div>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div className="flex w-full flex-col items-end justify-start">
              <Button
                title="Confirm"
                disabled={!Boolean(watchPatients.length)}
                className="mb-4 w-36"
                onClick={handleAssignPatients}
              />
              <div className="flex flex-row items-center justify-start text-small-caption text-neutral-125">
                <SparklesIcon className="mr-3 h-10 w-10" />
                <div>
                  When you confirm a new client assignment, Homecoming notifies
                  the practitioner they've been paired.
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AssignPatientsModal;
