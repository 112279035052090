import { SvgIconComponent } from '../types/svgs';

const CalendlyLogo: SvgIconComponent = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138"
      height="36"
      fill="none"
      viewBox="0 0 149 36"
      className={className}
    >
      <g clipPath="url(#clip0_5877_3795)">
        <path
          fill="#006BFF"
          d="M88.552 14.205c2.314 0 4.505 1.415 4.968 4.473H83.216c.43-2.683 2.437-4.473 5.337-4.473zm4.638 8.998c-.787 1.227-2.308 2.17-4.39 2.17-2.87 0-5.06-1.573-5.584-4.35h12.927c.062-.398.094-.8.094-1.203 0-4.319-3.02-8.145-7.683-8.145-4.812 0-8.082 3.548-8.082 8.115 0 4.624 3.302 8.115 8.267 8.115 3.086 0 5.43-1.389 6.788-3.491l-2.337-1.211zM79.201 4.98h-2.683v22.523H79.2V4.98zM111.17 18.031v9.471h-2.685v-9.317c0-2.53-1.449-3.949-3.825-3.949-2.469 0-4.474 1.45-4.474 5.09v8.176h-2.684V12.078h2.684v2.221c1.142-1.82 2.838-2.622 5.001-2.622 3.609 0 5.985 2.406 5.985 6.356M125.97 19.79c0-3.148-2.376-5.524-5.492-5.524-3.085 0-5.46 2.376-5.46 5.523s2.375 5.523 5.46 5.523c3.114 0 5.492-2.376 5.492-5.523zm2.683-14.81v22.523h-2.683v-2.654c-1.234 1.887-3.208 3.055-5.832 3.055-4.288 0-7.805-3.549-7.805-8.115 0-4.566 3.517-8.115 7.805-8.115 2.623 0 4.598 1.172 5.832 3.055V4.98h2.683zM133.502 4.98h-2.684v22.523h2.684V4.98zM71.734 19.789c0-3.147-2.375-5.523-5.492-5.523-3.085 0-5.46 2.376-5.46 5.523s2.376 5.523 5.46 5.523c3.114 0 5.492-2.376 5.492-5.523zm2.684-7.711v15.424h-2.684v-2.653c-1.234 1.887-3.208 3.055-5.83 3.055-4.29 0-7.807-3.549-7.807-8.115 0-4.566 3.518-8.115 7.806-8.115 2.623 0 4.597 1.172 5.83 3.055v-2.651h2.685z"
        ></path>
        <path
          fill="#006BFF"
          d="M54.116 24.534a8.354 8.354 0 112.416-14.288c.471.389.898.83 1.272 1.313l2.322-1.698a11.245 11.245 0 10-2.05 15.725l-1.545-2.444a8.429 8.429 0 01-2.415 1.392zM146.457 12.078v8.755c0 2.83-1.646 4.623-4.16 4.623-2.514 0-4.334-1.793-4.334-4.623v-8.755h-2.716v8.492c0 4.45 2.773 7.34 7.05 7.34 3.699 0 4.276-2.34 4.276-2.399v3.15c0 3.12-1.358 4.883-4.189 4.883a4.171 4.171 0 01-4.152-3.605l-2.428.85A6.745 6.745 0 00142.388 36c4.392 0 6.794-2.89 6.794-7.34V12.079h-2.725zM21.852 21.064c-1.032.915-2.32 2.054-4.655 2.054H15.8c-1.69 0-3.227-.614-4.327-1.728-1.075-1.088-1.666-2.577-1.666-4.193v-1.91c0-1.617.591-3.106 1.666-4.194 1.1-1.114 2.637-1.727 4.327-1.727h1.397c2.338 0 3.623 1.138 4.655 2.053 1.07.944 1.994 1.769 4.458 1.769.376 0 .751-.03 1.122-.09l-.008-.022a8.431 8.431 0 00-.518-1.064l-1.647-2.853a8.459 8.459 0 00-7.326-4.229h-3.294a8.459 8.459 0 00-7.326 4.23l-1.647 2.852a8.46 8.46 0 000 8.46l1.647 2.852a8.46 8.46 0 007.326 4.229h3.294a8.46 8.46 0 007.326-4.23l1.647-2.847c.197-.342.37-.698.518-1.064l.008-.021a6.982 6.982 0 00-1.122-.094c-2.463 0-3.387.82-4.458 1.768"
        ></path>
        <path
          fill="#006BFF"
          d="M17.197 11.098H15.8c-2.572 0-4.262 1.837-4.262 4.188v1.911c0 2.352 1.69 4.189 4.262 4.189h1.397c3.748 0 3.454-3.822 9.113-3.822.536 0 1.072.049 1.599.147a8.446 8.446 0 000-2.939 8.727 8.727 0 01-1.599.147c-5.661 0-5.365-3.821-9.113-3.821z"
        ></path>
        <path
          fill="#006BFF"
          d="M31.16 19.108a7.891 7.891 0 00-3.25-1.398v.029a8.468 8.468 0 01-.472 1.646c.97.15 1.892.53 2.687 1.107 0 .008-.005.017-.007.025a14.491 14.491 0 110-8.551c0 .008.004.018.007.025a6.174 6.174 0 01-2.687 1.106c.212.532.37 1.085.472 1.649v.026a7.889 7.889 0 003.25-1.396c.927-.686.748-1.461.607-1.92a16.242 16.242 0 100 9.571c.14-.459.32-1.233-.607-1.92z"
        ></path>
        <path
          fill="#0AE8F0"
          d="M27.432 13.097c-.37.062-.746.093-1.122.095-2.463 0-3.387-.82-4.457-1.769-1.033-.915-2.318-2.054-4.656-2.054h-1.396c-1.691 0-3.228.614-4.328 1.728-1.075 1.088-1.666 2.577-1.666 4.193v1.91c0 1.617.591 3.106 1.666 4.194 1.1 1.114 2.637 1.727 4.327 1.727h1.397c2.338 0 3.623-1.138 4.656-2.053 1.07-.944 1.993-1.769 4.457-1.769.376 0 .751.03 1.122.09a8.409 8.409 0 00.472-1.647v-.028a8.737 8.737 0 00-1.599-.146c-5.661 0-5.365 3.822-9.113 3.822h-1.396c-2.572 0-4.262-1.838-4.262-4.19v-1.914c0-2.351 1.69-4.188 4.262-4.188h1.396c3.748 0 3.454 3.82 9.113 3.82a8.65 8.65 0 001.599-.146v-.026a8.524 8.524 0 00-.472-1.649z"
        ></path>
        <path
          fill="#0AE8F0"
          d="M27.432 13.097c-.37.062-.746.093-1.122.095-2.463 0-3.387-.82-4.457-1.769-1.033-.915-2.318-2.054-4.656-2.054h-1.396c-1.691 0-3.228.614-4.328 1.728-1.075 1.088-1.666 2.577-1.666 4.193v1.91c0 1.617.591 3.106 1.666 4.194 1.1 1.114 2.637 1.727 4.327 1.727h1.397c2.338 0 3.623-1.138 4.656-2.053 1.07-.944 1.993-1.769 4.457-1.769.376 0 .751.03 1.122.09a8.409 8.409 0 00.472-1.647v-.028a8.737 8.737 0 00-1.599-.146c-5.661 0-5.365 3.822-9.113 3.822h-1.396c-2.572 0-4.262-1.838-4.262-4.19v-1.914c0-2.351 1.69-4.188 4.262-4.188h1.396c3.748 0 3.454 3.82 9.113 3.82a8.65 8.65 0 001.599-.146v-.026a8.524 8.524 0 00-.472-1.649z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5877_3795">
          <path fill="#fff" d="M0 0H149V36H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendlyLogo;
