import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Transition } from '@headlessui/react';

import logout from '../../assets/svgs/logout.svg';
import { useAuth } from '../../contexts/AuthContext';
import IconButton from './IconButton';
import Spinner from '../svgs/Spinner';
import Menu from '../svgs/Menu';
import { UserGroupIcon, CogIcon, CashIcon } from '@heroicons/react/outline';

type SettingsMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const SettingsMenu: React.FC<SettingsMenuProps> = ({ isOpen, setIsOpen }) => {
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const handleItemClick = (path: string) => {
    navigate(path);
  };

  const handleLogoutItemClick = async (event: Event) => {
    // Don't close dropdown menu until logout is complete.
    event.preventDefault();

    setIsLogoutLoading(true);
    await auth.logout();
  };

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <IconButton
          aria-label="Settings menu"
          IconComponent={Menu}
          className="hover:bg-neutral-50"
        />
      </DropdownMenu.Trigger>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <DropdownMenu.Content
          forceMount
          className="absolute left-3 mt-2 w-56 rounded-lg bg-white py-2 shadow-100 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <DropdownMenu.Item
            onSelect={() => handleItemClick('settings/team')}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-left text-caption text-neutral-125 focus:bg-neutral-50 focus:outline-none"
          >
            <span>Team members</span>
            <UserGroupIcon className="h-5 w-5" />
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => handleItemClick('settings/billing')}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-left text-caption text-neutral-125 focus:bg-neutral-50 focus:outline-none"
          >
            <span>Billing</span>
            <CashIcon className="h-5 w-5" />
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => handleItemClick('settings')}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-left text-caption text-neutral-125 focus:bg-neutral-50 focus:outline-none"
          >
            <span>Settings</span>
            <CogIcon className="h-5 w-5" />
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={handleLogoutItemClick}
            className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-caption text-red-100 focus:bg-neutral-50 focus:outline-none"
          >
            <div>Log out</div>
            {isLogoutLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <img src={logout} alt="" />
            )}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </Transition>
    </DropdownMenu.Root>
  );
};

export default SettingsMenu;
