import React, { FC } from 'react';

const FeedStat: FC<{
  title: string;
  label: string;
  value?: string;
  element?: React.ReactNode;
}> = ({ title, label, value, element }) => {
  return (
    <div className="flex w-full flex-col items-start justify-start">
      <span className="mb-1 text-caption font-bold">{title}</span>
      <span className="mb-4 text-small-caption text-neutral-150">{label}</span>
      {value && <span className="text-big-label">{value}</span>}
      {element && element}
    </div>
  );
};

export default FeedStat;
