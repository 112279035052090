import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import toast from 'react-hot-toast';
import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
  UseFieldArrayPrepend,
  FormProvider,
} from 'react-hook-form';

import {
  ActivityFileCompletionType,
  ActivityUseType,
  useAddActivityPdfMutation,
  useIsFilePdfEditableMutation,
} from '../../../../generated/graphql';

import CloseX from '../../../svgs/CloseX';
import ThumbnailPDF from '../../../svgs/ThumbnailPDF';

import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';

import {
  MIN_TEXT_LENGTH,
  MAX_TITLE_LENGTH,
} from '../../../lib/form-validators';
import { onEnterKeyDown } from '../../../lib/form';

import UploadField from '../../UploadField';
import Button from '../../Button';
import ToastAlert from '../../ToastAlert';
import InputGroup from '../../InputGroup';
import Spinner from '../../../svgs/Spinner';
import ErrorMessage from '../../ErrorMessage';
import {
  MAX_UPLOAD_SIZE_BYTES,
  acceptedDocumentFileExtensions,
  acceptedPdfFileExtensions,
} from '../../../lib/media';
import { RefetchProviderActivities } from '../../../types/activity';

import { FollowUpForm } from '../../../lib/followUp';
import { formatProviderActivityForFollowUp } from '../../../lib/providerActivity';
import ThumbnailPdfForm from '../../../svgs/ThumbnailPdfForm';
import ThumbnailPDFESign from '../../../svgs/ThumbnailPDFESign';

import ActivityUseSettings from '../FileSettingsModal/ActivityUseSettings';
import FileCompletionSettings from '../FileSettingsModal/FileCompletionSettings';

type AddPDFModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  refetchProviderActivities?: RefetchProviderActivities;
  followUpMode?: boolean;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  onAdded?: (id: string) => void;
};

export interface AddPDFModalFormData {
  newPDFFile: FileList | undefined;
  pdfFileTitle: string;
  completionType: ActivityFileCompletionType;
  isOneTimeUse: boolean;
}

const AddPDFModal: FC<AddPDFModalProps> = ({
  isModalOpen,
  setClosed,
  refetchProviderActivities,
  followUpMode = false,
  prependToFollowUp,
  onAdded,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [isFilePdfEditable, setIsFilePdfEditable] = useState<boolean | null>(
    null,
  );

  const [addActivityPDFMutation, { loading: addActivityPDFMutationLoading }] =
    useAddActivityPdfMutation();
  const [isFilePdfEditableMutation] = useIsFilePdfEditableMutation();

  const addPDFModalForm = useForm<AddPDFModalFormData>({
    mode: 'onChange',
    defaultValues: {
      newPDFFile: undefined,
      pdfFileTitle: '',
      isOneTimeUse: followUpMode,
      completionType: ActivityFileCompletionType.View,
    },
  });

  const watchNewPDFFile = addPDFModalForm.watch('newPDFFile');
  const watchPDFFileTitle = addPDFModalForm.watch('pdfFileTitle');
  const watchCompletionType = addPDFModalForm.watch('completionType');

  const onSubmit: SubmitHandler<AddPDFModalFormData> = async (
    formData: AddPDFModalFormData,
  ) => {
    setSubmitting(true);

    const addActivityPDFInput = {
      file: newPDFFile,
      title: formData.pdfFileTitle,
      activityUseType: formData.isOneTimeUse
        ? ActivityUseType.OneTimeUse
        : ActivityUseType.Reusable,
      completionType: formData.completionType,
    };

    try {
      const { data } = await addActivityPDFMutation({
        variables: {
          input: addActivityPDFInput,
        },
      });

      if (data?.addActivityPDF) {
        const newActivityPDF = data?.addActivityPDF;
        closeAndResetAddPDFModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully added ${newActivityPDF.title} to your ${
              followUpMode ? 'follow-up' : 'library'
            }`}
          />
        ));

        if (refetchProviderActivities) await refetchProviderActivities();

        if (followUpMode && prependToFollowUp) {
          prependToFollowUp(formatProviderActivityForFollowUp(newActivityPDF));
        }

        onAdded?.(data?.addActivityPDF?.id);
      }
    } catch (err) {
      setSubmitting(false);
      closeAndResetAddPDFModal();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={(err as Error).message}
          level="error"
        />
      ));
    }
  };

  const handleErrors: SubmitErrorHandler<AddPDFModalFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetAddPDFModal = async () => {
    setClosed();
    setTimeout(() => {
      addPDFModalForm.reset();
      setSubmitting(false);
      setIsFilePdfEditable(null);
    }, MODAL_TRANSITION_DURATION);
  };

  const newPDFFile = watchNewPDFFile?.[0];
  const hasNewPDFFile = Boolean(newPDFFile);
  const hasValidPDFFile =
    hasNewPDFFile && !addPDFModalForm.getFieldState('newPDFFile').error;
  const isActuallyPdfFile = acceptedPdfFileExtensions.includes(
    newPDFFile?.type,
  );
  const isDocumentFile = acceptedDocumentFileExtensions.includes(
    newPDFFile?.type,
  );

  useEffect(() => {
    if (hasValidPDFFile && newPDFFile) {
      addPDFModalForm.setValue('pdfFileTitle', newPDFFile.name);
    }
  }, [hasValidPDFFile, newPDFFile]);

  useEffect(() => {
    if (newPDFFile && hasValidPDFFile && isActuallyPdfFile) {
      isFilePdfEditableMutation({
        variables: {
          file: newPDFFile,
        },
      }).then(({ data }) => {
        setIsFilePdfEditable(data?.isFilePdfEditable ?? false);
      });
    }
  }, [newPDFFile, hasValidPDFFile, isActuallyPdfFile]);

  const ActivityThumbnail =
    watchCompletionType === ActivityFileCompletionType.Upload
      ? ThumbnailPdfForm
      : watchCompletionType === ActivityFileCompletionType.Esign
      ? ThumbnailPDFESign
      : ThumbnailPDF;

  return (
    <Modal
      name="AddPdfModal"
      isOpen={isModalOpen}
      setClosed={closeAndResetAddPDFModal}
      width="small"
      overflow="auto"
    >
      <div className="px-10">
        <div className="mb-6 flex w-full flex-col items-center justify-center">
          <span className="font-serif text-subtitle text-green-150">
            Add a file
          </span>
          <span className="text-caption text-green-125">
            Upload a file and give it a title
          </span>
        </div>

        <form className="flex w-full flex-col items-start justify-start">
          <>
            <div
              className={classNames(
                'flex h-[68px] w-[460px] items-center justify-center rounded-md bg-neutral-25 text-caption text-neutral-125 transition-colors',
                !hasValidPDFFile &&
                  'border border-dashed border-neutral-100 hover:border-green-100 hover:bg-green-25/30',
              )}
            >
              {!hasValidPDFFile ? (
                <UploadField
                  readOnly
                  acceptedFileExtensions="*"
                  inputId="newPDFFile"
                  title="Upload a file"
                  {...addPDFModalForm.register('newPDFFile', {
                    required: 'Please select a file to upload',
                    validate: (fileList) => {
                      if (fileList) {
                        const PDFFile = fileList?.[0];
                        if (PDFFile.size > MAX_UPLOAD_SIZE_BYTES) {
                          return "That file is a bit too big. Try one that's smaller than 100 MB";
                        }
                      }
                    },
                  })}
                />
              ) : (
                <div className="flex w-full flex-row items-start justify-between p-4">
                  <div className="flex w-11/12 flex-row items-center justify-start">
                    <ActivityThumbnail className="mr-4 h-[42px] w-[42px] rounded-md" />
                    <div className="flex w-[calc(100%-58px)] flex-col items-start justify-center">
                      <span className="w-full text-caption font-medium text-green-150 line-clamp-2">
                        {Boolean(watchPDFFileTitle)
                          ? watchPDFFileTitle
                          : newPDFFile?.name}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      addPDFModalForm.reset();
                      setIsFilePdfEditable(null);
                    }}
                  >
                    <CloseX className="cursor-pointer" />
                  </div>
                </div>
              )}
            </div>
            <ErrorMessage className="mt-2">
              {addPDFModalForm.formState.errors.newPDFFile?.message}
            </ErrorMessage>
            {hasNewPDFFile && (
              <Controller
                name="pdfFileTitle"
                control={addPDFModalForm.control}
                rules={{
                  required: false,
                  minLength: {
                    value: MIN_TEXT_LENGTH,
                    message: `Title should be at least ${MIN_TEXT_LENGTH} characters long`,
                  },
                  maxLength: {
                    value: MAX_TITLE_LENGTH,
                    message: 'Title is too long',
                  },
                }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <InputGroup
                    label="Title"
                    useNaturalLettering
                    inputSize="small"
                    containerClassName="mt-3"
                    className="w-[460px] overflow-hidden"
                    onKeyDown={onEnterKeyDown}
                    onBlur={onBlur}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    value={value}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          </>

          {hasValidPDFFile && (
            <FormProvider {...addPDFModalForm}>
              {followUpMode && <ActivityUseSettings />}
              <FileCompletionSettings
                watchCompletionType={watchCompletionType}
                isFilePdfEditable={isFilePdfEditable}
                isDocumentFile={isDocumentFile}
                isActuallyPdfFile={isActuallyPdfFile}
              />
            </FormProvider>
          )}

          <div className="mb-8 mt-10 flex w-full flex-row justify-center">
            <Button
              title={
                !submitting
                  ? `Add to ${followUpMode ? 'follow-up' : 'library'}`
                  : 'Adding...'
              }
              onClick={addPDFModalForm.handleSubmit(onSubmit, handleErrors)}
              {...(submitting && {
                IconComponent: Spinner,
                iconClassName: 'h-[16px] w-[16px]',
              })}
              disabled={Boolean(
                addActivityPDFMutationLoading ||
                  addPDFModalForm.formState.errors.pdfFileTitle ||
                  addPDFModalForm.formState.errors.newPDFFile ||
                  !watchNewPDFFile ||
                  !watchPDFFileTitle,
              )}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddPDFModal;
