import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ProviderAssessmentsQuery } from '../../../../generated/graphql';
import Button from '../../../components/Button';
import TableHeader from '../../../components/TableHeader';
import ChevronRight from '../../../svgs/ChevronRight';
import { ColumnHeaderType } from '../../../types/tables';
import {
  getRecurrenceTextFromAssessmentScheduleItem,
  getScheduleTextFromAssessmentScheduleItem,
} from '../../ScheduleAssessment/helpers';

const columnHeaders: ColumnHeaderType[] = [
  {
    field: 'name',
    headerName: 'Included assessments',
  },
  {
    field: 'scheduledEvents',
    headerName: 'Scheduled events',
  },
];

interface AssessmentTableProps {
  assessmentsWithScheduleItems: ProviderAssessmentsQuery['providerAssessments'];
}

const AssessmentTable: React.FC<AssessmentTableProps> = ({
  assessmentsWithScheduleItems,
}) => {
  const navigate = useNavigate();

  const maxScheduleItems = 2;

  return (
    <div className="relative">
      <table className="-ml-4 -mr-16 min-w-[calc(100%+2rem)] table-fixed divide-y divide-neutral-25">
        <thead>
          <tr>
            {columnHeaders.map((column, i) => {
              const isLastColumn = i === columnHeaders.length - 1;

              return (
                <TableHeader
                  key={`tableHeader_${column.field}`}
                  column={column}
                  isLastColumn={isLastColumn}
                  className="text-neutral-125"
                />
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-neutral-25">
          {assessmentsWithScheduleItems.map((assessment, index) => (
            <tr
              key={assessment.id}
              className={classNames(
                'h-20 cursor-pointer whitespace-nowrap hover:bg-neutral-25',
              )}
              onClick={() => navigate(`${assessment.id}/schedule`)}
            >
              <td className="text-green-150">
                <div className="font-serif text-extra-small">
                  {assessment.shortName}
                </div>
                <div className="text-small-label">{assessment.name}</div>
              </td>
              <td className="text-caption text-neutral-125">
                {assessment
                  .programTemplateAssessment!.scheduleItems.slice(
                    0,
                    maxScheduleItems,
                  )
                  .map((scheduleItem, i) => (
                    <div key={i}>
                      {`${getScheduleTextFromAssessmentScheduleItem(
                        scheduleItem,
                      )}. ${getRecurrenceTextFromAssessmentScheduleItem(
                        scheduleItem,
                      )}.`}
                    </div>
                  ))}
                {assessment.programTemplateAssessment!.scheduleItems.length >
                  maxScheduleItems && (
                  <Button
                    title={`${
                      assessment.programTemplateAssessment!.scheduleItems
                        .length - maxScheduleItems
                    } more...`}
                    noBackground
                    noOutline
                    size="small"
                    theme="secondary"
                    className="mt-1 text-caption font-bold"
                  />
                )}
              </td>
              <td className="pr-0">
                <ChevronRight className="text-green-150" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssessmentTable;
