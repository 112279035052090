import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PageContainer from '../components/Containers/PageContainer';
import X from '../svgs/X';
import WordmarkLogo from '../svgs/WordmarkLogo';
import Button from '../components/Button';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';

const CommunityLanding = () => {
  const navigate = useNavigate();

  const { authedProviderUser, saveToken } = useAuth();
  const { loginWithPopup, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const launchReAuth = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          prompt: 'login',
          login_hint: authedProviderUser.email,
        },
      });
      const accessToken = await getAccessTokenSilently();
      saveToken(accessToken);
      navigate('/community');
    } catch (error) {
      console.error(error);
    }
  };

  const handleTakeMeThere = async () => {
    if (isAuthenticated) {
      navigate('/community');
    } else {
      await launchReAuth();
    }
  };

  const isCommunityHub = authedProviderUser?.signUpConfig?.isCommunityHub;
  const partnerName = authedProviderUser?.signUpConfig?.partnerName;
  const logoUrl = authedProviderUser?.signUpConfig?.additionalLogoMedia?.url;
  const postSignUpSplashBgUrl =
    authedProviderUser?.signUpConfig?.postSignUpSplashBgMedia?.url;

  return (
    <PageContainer noPadding containerClassName="w-screen h-[calc(100vh-64px)]">
      <div
        className="flex h-full w-full flex-col items-center justify-center bg-cover"
        style={
          isCommunityHub && postSignUpSplashBgUrl
            ? { backgroundImage: `url(${postSignUpSplashBgUrl})` }
            : {}
        }
      >
        <div className="mb-8 font-serif text-subtitle-small text-green-150">
          Community Partnership
        </div>
        <div className="flex flex-row items-center">
          {logoUrl && (
            <div>
              <img alt="Partner logo" src={logoUrl} className="h-[48px]" />
            </div>
          )}
          <X className="mx-10 h-[48px]" />
          <WordmarkLogo className="flex" />
        </div>
        <div className="mt-8 mb-12 w-[700px] text-center text-body text-green-150">
          Join the exclusive {partnerName} Community Hub, powered by Homecoming.
          Connect with fellow practitioners and experience our integrated
          practice management system designed specifically for holistic
          practitioners.
        </div>
        <Button
          title="Take me there"
          IconComponent={ArrowRightIcon}
          iconPosition="right"
          theme="primary"
          onClick={handleTakeMeThere}
        />
      </div>
    </PageContainer>
  );
};

export default CommunityLanding;
