import { ZxcvbnResult } from '@zxcvbn-ts/core';

import { minPasswordStrengthScore } from './password-strength';

export function getPasswordErrorMessage({
  password,
  score,
  feedback,
}: ZxcvbnResult) {
  if (!password) {
    return 'Password is required';
  }

  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  if (score >= minPasswordStrengthScore) {
    return;
  }

  return feedback.warning || 'Password is too weak';
}
