import React from 'react';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import { SvgIconComponent } from '../../types/svgs';
import { ChevronDownIcon } from '@heroicons/react/outline';

const WebpageModuleContainer: React.FC<{
  title: string;
  Icon?: SvgIconComponent;
  children: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
}> = ({ title, Icon, children, className, defaultOpen }) => {
  return (
    <Disclosure
      as="div"
      className={classNames(
        'relative overflow-hidden rounded-xl border border-neutral-75 bg-white',
        className,
      )}
      defaultOpen={defaultOpen}
    >
      <Disclosure.Button className="flex w-full items-center justify-between p-5">
        <div className="flex items-center space-x-3">
          {Icon && <Icon className="h-5 w-5 text-neutral-125" />}
          <div className="text-body font-medium text-neutral-150">{title}</div>
        </div>
        <ChevronDownIcon
          className={classNames(
            'ui-open:rotate-180 ui-open:transform',
            'text-purple-500 h-5 w-5',
          )}
        />
      </Disclosure.Button>
      <Disclosure.Panel>{children}</Disclosure.Panel>
    </Disclosure>
  );
};

export default WebpageModuleContainer;
