import { FC, useState, useEffect, useRef } from 'react';

import {
  useForm,
  useFieldArray,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAddPatientsMutation } from '../../../../../generated/graphql';

import {
  PatientRowValues,
  SpreadsheetData,
} from '../../../../types/PatientManagement';

import { useAuth } from '../../../../../contexts/AuthContext';
import { formatValidatedPatientLeads } from './helpers';
import { PatientsObject, getPatientsObject } from '../../helpers';

import Button from '../../../../components/Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../../components/Modal';
import ArrowRightLong from '../../../../svgs/ArrowRightLong';
import PatientInputRow from './PatientInputRow';
import UseASpreadsheetMenu from './UseASpreadsheetMenu';
import ValidationWarning from '../../ValidationWarning';

import { PATIENTS_ONBOARDING_BASE_PATH } from '../..';

import { sortOptions } from '../../helpers';
import InputLabel from '../../../../components/InputLabel';
import AddRowButton from '../../../../components/AddRowButton';
import { removeEmptyFields } from '../../../../lib/form';
import { ColumnOrder } from '../../../../types/tables';

type AddPatientsModalProps = {
  isModalOpen: boolean;
  patientsObject: PatientsObject | null;
  setClosed: () => void;
  setPatientsObject: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
  setSelectedPatientsObject: React.Dispatch<
    React.SetStateAction<PatientsObject>
  >;
  setPatientOrder: React.Dispatch<React.SetStateAction<ColumnOrder | null>>;
  clearFilters: () => void;
};

const AddPatientsModal: FC<AddPatientsModalProps> = ({
  patientsObject,
  isModalOpen,
  setClosed,
  setPatientsObject,
  setSelectedPatientsObject,
  setPatientOrder,
  clearFilters,
}) => {
  const navigate = useNavigate();
  const {
    watch,
    register,
    setFocus,
    setError,
    setValue,
    handleSubmit,
    clearErrors: clearFormErrors,
    reset: resetForm,
    formState: { errors, isSubmitted },
    control,
  } = useForm<PatientRowValues>({
    mode: 'onSubmit',
    defaultValues: {
      patientRows: [{ name: '', email: '', firstAppointmentDate: null }],
    },
  });

  const { authedProviderUser } = useAuth();
  const [addPatientsMutation] = useAddPatientsMutation();

  const [loading, setLoading] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState<SpreadsheetData>([]);
  const [showValidationWarning, setShowValidationWarning] = useState(false);

  const [validationWarningText, setValidationWarningText] = useState(
    'Duplicate emails removed',
  );

  const overflowContainerRef =
    useRef() as React.MutableRefObject<HTMLDivElement>;

  const { fields, append, insert, remove, replace } = useFieldArray({
    name: 'patientRows',
    control,
  });

  useEffect(() => {
    if (spreadsheetData.length > 1) {
      resetForm();
      replace(
        spreadsheetData
          .slice(0)
          .map((row) => ({ ...row, firstAppointmentDate: null })),
      );
    }
  }, [spreadsheetData, resetForm, replace]);

  const patientRows = watch('patientRows');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...patientRows[index],
    };
  });

  const providerId = authedProviderUser.provider.id;

  const handleErrors: SubmitErrorHandler<PatientRowValues> = (errors) => {
    console.log({ errors });
  };

  const onSubmit: SubmitHandler<PatientRowValues> = async (validatedData) => {
    clearFormErrors();

    const formattedAndValidatedPatientLeads =
      formatValidatedPatientLeads(validatedData);

    try {
      const addPatientsInput = {
        providerId,
        newPatientLeads: formattedAndValidatedPatientLeads,
      };
      setLoading(true);
      const response = await addPatientsMutation({
        variables: {
          input: addPatientsInput,
        },
      });

      const addPatientsData = response?.data?.addPatients;

      if (addPatientsData?.validationErrors) {
        addPatientsData?.validationErrors.forEach((fieldErrors) => {
          const { inputId, isDuplicate, emailInvalid, nameInvalid } =
            fieldErrors;
          if (isDuplicate) {
            setError(`patientRows.${inputId}.email`, {
              type: 'Duplicate email',
              message: 'Email already registered',
            });
          }
          if (emailInvalid) {
            setError(`patientRows.${inputId}.email`, {
              type: 'Invalid email',
              message: 'Email is invalid',
            });
          }
          if (nameInvalid) {
            setError(`patientRows.${inputId}.name`, {
              type: 'Invalid name',
              message: 'Name is invalid',
            });
          }
        });
        setLoading(false);
        return;
      }

      const newPatients = addPatientsData?.newPatients;

      if (newPatients && newPatients?.length > 0) {
        const newPatientsObject = getPatientsObject(newPatients);

        const updatedPatientsObject = {
          ...patientsObject,
          ...newPatientsObject,
        };

        navigate(PATIENTS_ONBOARDING_BASE_PATH);
        setPatientsObject(updatedPatientsObject);
        setPatientOrder(sortOptions.newestCreated);
        setSelectedPatientsObject(newPatientsObject);
        clearFilters();
      }
      closeAndResetAddPatientsModal();
    } catch (err) {
      closeAndResetAddPatientsModal();
    }
  };

  const closeAndResetAddPatientsModal = () => {
    setClosed();
    setTimeout(() => {
      setShowValidationWarning(false);
      setLoading(false);
      resetForm();
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetAddPatientsModal}
      fetching={loading}
    >
      <div>
        <div className="mb-10 flex flex-row items-center justify-between pl-10 pr-20">
          <div className="font-serif text-subtitle text-green-150">
            Add your clients
          </div>
          <UseASpreadsheetMenu
            setSpreadsheetData={setSpreadsheetData}
            setShowValidationWarning={setShowValidationWarning}
            setValidationWarningText={setValidationWarningText}
          />
        </div>
        <div className="flex flex-row items-end justify-between px-20 pl-10">
          <InputLabel label="Name" inputRequired containerClassName="w-2/5" />
          <div className="w-4" />
          <InputLabel label="Email" inputRequired containerClassName="w-2/5" />
          <div className="w-4" />
          <InputLabel label="First session date" containerClassName="w-1/5" />
        </div>
        <form className="flex h-[60vh] w-full flex-col items-center justify-between px-9 pb-4">
          <div
            ref={overflowContainerRef}
            className="flex max-h-fit w-full flex-col justify-start overflow-y-scroll"
          >
            <div className="flex flex-col items-center justify-start pl-1 pr-2 pt-1">
              {controlledFields.map((field, index) => {
                const hasManyFields = controlledFields.length > 1;
                return (
                  <PatientInputRow
                    key={`patientInputRow_${index}`}
                    hasManyFields={hasManyFields}
                    register={register}
                    rowIndex={index}
                    field={field}
                    showValidation={isSubmitted}
                    errors={errors?.patientRows?.[index]}
                    insert={insert}
                    remove={remove}
                    setFocus={setFocus}
                    setValue={setValue}
                    overflowContainerRef={overflowContainerRef}
                    disabled={loading}
                  />
                );
              })}
            </div>
          </div>
          <div className="mt-6 flex w-full flex-row items-center justify-between">
            <div className="flex w-72 flex-row items-center justify-between">
              <AddRowButton
                title="Add another client"
                onClick={() =>
                  append({
                    name: '',
                    email: '',
                    firstAppointmentDate: null,
                  })
                }
              />
            </div>
            <ValidationWarning
              fadeOut
              isOpen={showValidationWarning}
              warningText={validationWarningText}
              setShowValidationWarning={setShowValidationWarning}
            />
            <div className="flex w-72 flex-row items-center justify-end">
              <Button
                title="Finish adding clients"
                theme="primary"
                IconComponent={ArrowRightLong}
                onClick={() => {
                  removeEmptyFields(controlledFields, remove);
                  handleSubmit(onSubmit, handleErrors)();
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddPatientsModal;
