import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../../lib/analytics';
import { useAddStripeIntegrationMutation } from '../../../../../generated/graphql';
import Loading from '../../../Loading';
import { useMount } from '../../../../hooks/useMount';

const STRIPE_INTEGRATION_PAGE_PATH = '/integrations/stripe';
const STRIPE_CONNECT_CANCEL_ERROR = 'access_denied';

export const PARAM_CONNECT_SUCCESS = 'success';
export const PARAM_CONNECT_ERROR = 'error';

const GENERIC_ERROR_MESSAGE = 'Failed to connect your Stripe account';

/** By using a separate page for Stripe to redirect to, we have more control over how we receive the redirect.
 *
 *  Most notably, we can track whether the user canceled the integration flow.
 *  And generally we can differentiate between the user refreshing and the user being redirected
 *  with or without an authorization code.
 */
const StripeIntegrationCallback: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  const [
    addStripeIntegrationMutation,
    { loading: addStripeIntegrationLoading },
  ] = useAddStripeIntegrationMutation();

  const navigateToIntegrationPage = (queryKey?: string, queryValue?: string) =>
    navigate(
      queryKey && queryValue
        ? `${STRIPE_INTEGRATION_PAGE_PATH}?${queryKey}=${encodeURIComponent(
            queryValue,
          )}`
        : STRIPE_INTEGRATION_PAGE_PATH,
      { replace: true },
    );

  const handleAuthorizationCode = async (stripeAuthorizationCode: string) => {
    try {
      const res = await addStripeIntegrationMutation({
        variables: {
          authorizationCode: stripeAuthorizationCode,
        },
      });

      if (res.data?.addStripeIntegration) {
        navigateToIntegrationPage(PARAM_CONNECT_SUCCESS, '1');
      } else {
        navigateToIntegrationPage(PARAM_CONNECT_ERROR, GENERIC_ERROR_MESSAGE);
      }
    } catch (error) {
      navigateToIntegrationPage(
        PARAM_CONNECT_ERROR,
        `${GENERIC_ERROR_MESSAGE}: ` + (error as Error).message,
      );
    }
  };

  useMount(() => {
    if (addStripeIntegrationLoading) return;

    if (authorizationCode) {
      handleAuthorizationCode(authorizationCode);
    } else if (error && error === STRIPE_CONNECT_CANCEL_ERROR) {
      trackProviderEvent(ProviderAnalyticsEvent.AddStripeIntegrationCancelled);
      navigateToIntegrationPage();
    } else if (error) {
      navigateToIntegrationPage(
        PARAM_CONNECT_ERROR,
        errorDescription ?? GENERIC_ERROR_MESSAGE,
      );
    } else {
      navigateToIntegrationPage();
    }
  }, []);

  return <Loading />;
};

export default StripeIntegrationCallback;
