import {
  ActivityType,
  ActivityUseType,
  ActivityPdfInput,
  ActivityLinkInput,
  ActivityTextInput,
  ActivityAudioInput,
  ActivityImageInput,
  AssessmentSchemaType,
  AssessmentSignatureUser,
  PatientNameDataFragment,
  ProgramTagWithCountDataFragment,
  SendFollowUpActivityInput,
} from '../../generated/graphql';

export enum ContentType {
  Audio = 'Audio',
  Esign = 'Esign',
  Image = 'Image',
  Link = 'Link',
  PDF = 'PDF',
  PdfForm = 'PdfForm',
  Action = 'Action',
  Form = 'Form',
  Assessment = 'Assessment',
  Text = 'Text',
}

export enum ActivityOrResourceOptions {
  Activity = 'Activity',
  Resource = 'Resource',
}

export const activityTypeToContentType = (
  activityType: ActivityType,
  assessmentSchemaType?: AssessmentSchemaType,
): ContentType => {
  switch (activityType) {
    case ActivityType.Action:
      return ContentType.Action;
    case ActivityType.Assessment:
      if (assessmentSchemaType === AssessmentSchemaType.Custom) {
        return ContentType.Form;
      }
      return ContentType.Assessment;
    case ActivityType.Audio:
      return ContentType.Audio;
    case ActivityType.Esign:
      return ContentType.Esign;
    case ActivityType.Pdf:
      return ContentType.PDF;
    case ActivityType.PdfForm:
      return ContentType.PdfForm;
    case ActivityType.Image:
      return ContentType.Image;
    case ActivityType.Link:
      return ContentType.Link;
    case ActivityType.Text:
      return ContentType.Text;
    default:
      return ContentType.Action;
  }
};

export type FollowUpDecorators = {
  dateCreated: number;
  isSubmittable: boolean;
  activityLinkInput?: ActivityLinkInput;
  activityAudioInput?: ActivityAudioInput;
  activityImageInput?: ActivityImageInput;
  activityPDFInput?: ActivityPdfInput;
  activityTextInput?: ActivityTextInput;
};

export const FollowUpDecoratorKeys: Array<keyof FollowUpDecorators> = [
  'dateCreated',
  'isSubmittable',
  'activityLinkInput',
  'activityAudioInput',
  'activityImageInput',
  'activityPDFInput',
  'activityTextInput',
];

export type SavedFollowUpActivityInput = SendFollowUpActivityInput &
  FollowUpDecorators;

type ActivityPreviewCardProps = {
  contentType: ContentType;
  activityOrResourceOption?: ActivityOrResourceOptions;
  activityUseType?: ActivityUseType;
  showProviderNote?: boolean;
  title: string;
  description?: string;
  providerNote?: string;
  previewLinkUrl?: string;
  previewThumbnailImageUrl?: string;
  isActuallyPdfFile?: boolean;
  onClickCloseX?: () => void;
  hasLoader?: boolean;
  className?: string;
};

export type SavedActivityInputPreview = {
  sendFollowUpActivityInputRaw: SavedFollowUpActivityInput;
  activityPreviewCardProps: ActivityPreviewCardProps;
  multiSignatureAssessmentConfig?: {
    assessmentSignatureUsers: AssessmentSignatureUser[];
  };
};

export const defaultActivitySchema: SavedActivityInputPreview = {
  sendFollowUpActivityInputRaw: {
    isSubmittable: true,
    isCompletable: true,
    activityType: ActivityType.Action,
    dateCreated: Date.now(),
    providerNote: '',
  },
  activityPreviewCardProps: {
    contentType: ContentType.Action,
    activityUseType: ActivityUseType.OneTimeUse,
    title: ActivityType.Action,
    activityOrResourceOption: ActivityOrResourceOptions.Activity,
  },
};

export type PatientOrTag =
  | PatientNameDataFragment
  | ProgramTagWithCountDataFragment;

export type FollowUpForm = {
  subject: string;
  patientsOrTags: PatientOrTag[];
  note: string;
  providerVoiceNoteId: string | null;
  activities: SavedActivityInputPreview[];
};
