import { FC } from 'react';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

type ConfirmResendTeamMemberInviteModalProps = {
  isOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  isResending: boolean;
};

const ConfirmResendTeamMemberInviteModal: FC<
  ConfirmResendTeamMemberInviteModalProps
> = ({ isOpen, setClosed, onConfirm, isResending }) => {
  return (
    <Modal isOpen={isOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to resend this team member an invite activation
            email?
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title="Send another invite"
            theme="primary"
            onClick={onConfirm}
            disabled={isResending}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmResendTeamMemberInviteModal;
