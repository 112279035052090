import { FC } from 'react';
import classNames from 'classnames';

import { getTimeStamp } from '../../../../lib/time';
import TestClientPill from '../../../../components/TestClientPill';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import { PlusIcon } from '@heroicons/react/solid';
import { PatientTableDataFragment } from '../../../../../generated/graphql';

type ArchivedRowProps = {
  isChecked: boolean;
  patient: PatientTableDataFragment;
  handleUnarchiveClick: (programId: string) => Promise<void>;
};

const ArchivedRow: FC<ArchivedRowProps> = ({
  isChecked,
  patient,
  handleUnarchiveClick,
}) => {
  return (
    <tr
      key={patient.email}
      className={classNames(
        'group whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
      )}
    >
      <td className="w-[3.75rem]" />
      <td className="min-w-1/2 py-4 px-0">
        <div className="flex flex-row items-center gap-x-4">
          <Avatar
            imageUrl={patient.profileImageMedia?.url}
            name={patient.firstName ?? ''}
            size="medium"
          />
          <div className="flex flex-col items-start justify-start font-serif text-extra-small">
            <div>{patient.firstName + ' ' + patient.lastName}</div>
            <div className="font-sans text-small-caption">{patient.email}</div>
          </div>
          {patient.isTestProgram && <TestClientPill />}
        </div>
      </td>

      <td className="min-w-1/2">
        {patient.deactivatedAt && (
          <div className="mt-2 text-small-caption">
            {getTimeStamp(patient?.deactivatedAt, false, true)}
          </div>
        )}
      </td>

      <td className="w-1/4">
        {patient.deactivatedAt && (
          <Button
            theme="secondary"
            title="Unarchive"
            size="small"
            iconPosition="left"
            IconComponent={PlusIcon}
            className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
            onClick={() => {
              handleUnarchiveClick(patient.programInstanceId);
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default ArchivedRow;
