import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import {
  useForm,
  FieldValues,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';

import {
  PatientLifecycleStatus,
  PatientQuery,
  PatientsQuery,
  useSetPatientFirstAppointmentDateMutation,
} from '../../../../generated/graphql';

import Button from '../../../components/Button';
import InputLabel from '../../../components/InputLabel';
import Modal, { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import { getTimeStamp } from '../../../lib/date';
import ToastAlert from '../../../components/ToastAlert';
import toast from 'react-hot-toast';
import { getPatientsObject, PatientsObject } from '../helpers';

type FirstAppointmentDateProps = {
  patient: PatientQuery['patient'] | null;
  isModalOpen: boolean;
  patientsObject?: PatientsObject | null;
  isPatientManagement?: boolean;
  patientLifecycleStatus?: PatientLifecycleStatus | null;
  setClosed: () => void;
  refetchPatient?: () => Promise<unknown>;
  setSinglePatient?: React.Dispatch<
    React.SetStateAction<PatientsQuery['patients'][number] | null>
  >;
  setPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
};

const FirstAppointmentDateModal: FC<FirstAppointmentDateProps> = ({
  patient,
  isModalOpen,
  patientsObject,
  isPatientManagement,
  patientLifecycleStatus,
  setClosed,
  refetchPatient,
  setSinglePatient,
  setPatientsObject,
}) => {
  const { handleSubmit } = useForm({
    mode: 'onSubmit',
  });

  const [warningText, setWarningText] = useState<string | null>(null);
  const [firstAppointmentDate, setFirstAppointmentDate] = useState<Date | null>(
    null,
  );

  useEffect(() => {
    if (patient?.firstAppointmentAt)
      setFirstAppointmentDate(new Date(patient.firstAppointmentAt));
  }, [patient]);

  const [
    setPatientFirstAppointmentDateMutation,
    { loading: patientFirstAppointmentDateMutationLoading },
  ] = useSetPatientFirstAppointmentDateMutation();

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (!patient) return;

    if (firstAppointmentDate === null) {
      setWarningText('Please enter a date before saving.');
      return;
    }

    // editable before client activation, so must check if patient endTime exists
    if (patient.endTime && firstAppointmentDate > new Date(patient.endTime)) {
      setWarningText(
        `Please enter a date before ${getTimeStamp(patient.endTime, false)} (${
          patient?.firstName
        }'s program end date).`,
      );
      return;
    }

    try {
      const response = await setPatientFirstAppointmentDateMutation({
        variables: {
          programId: patient.programInstanceId,
          firstAppointmentDate,
        },
      });

      const updatedPatient = response?.data?.setPatientFirstAppointmentDate;

      if (updatedPatient) {
        if (!isPatientManagement) {
          refetchPatient?.();
        } else if (
          patientsObject &&
          patientLifecycleStatus &&
          (patientLifecycleStatus === PatientLifecycleStatus.Invited ||
            patientLifecycleStatus === PatientLifecycleStatus.Active)
        ) {
          const updatedPatientsObject = {
            ...patientsObject,
            ...getPatientsObject([updatedPatient]),
          };

          if (isPatientManagement && setPatientsObject)
            setPatientsObject(updatedPatientsObject);
        }
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to save first session date."
          level="error"
        />
      ));
    } finally {
      closeAndResetFirstAppointmentDateModal();
    }
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetFirstAppointmentDateModal = () => {
    setClosed();
    setTimeout(() => {
      setFirstAppointmentDate(null);
      setWarningText('');
      setSinglePatient?.(null);
    }, MODAL_TRANSITION_DURATION);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClosed={closeAndResetFirstAppointmentDateModal}
      fetching={patientFirstAppointmentDateMutationLoading}
      width="small"
      overflow="auto"
    >
      <div className="flex flex-col items-center justify-between px-10 pb-4">
        <div className="flex flex-col items-center justify-start">
          <div className="flex flex-col items-center justify-start">
            <div className="mb-2 flex flex-col items-center justify-start text-center font-serif text-subtitle text-green-150">
              <span>Add first session date</span>
            </div>
            <div className="text-center text-body text-green-125">
              Add a first session date to enable all scheduled assessments.
            </div>
          </div>
          <div className="mt-8 mb-4 flex w-72 flex-col items-start justify-start">
            <InputLabel
              inputRequired
              label={`${patient?.firstName}'s first session date`}
            />
            <div className="w-72">
              <DatePicker
                selectsStart
                selected={firstAppointmentDate}
                startDate={firstAppointmentDate}
                previousMonthButtonLabel="←"
                nextMonthButtonLabel="→"
                placeholderText={'MM/DD/YYYY'}
                popperClassName="mt-3"
                onChange={(date: Date) => date && setFirstAppointmentDate(date)}
              />
            </div>
            <span className="mt-2 w-full text-center text-caption text-red-150">
              {warningText}
            </span>
          </div>
        </div>
        <form className="mt-10 mb-4 flex w-full flex-row items-center justify-center">
          <Button
            type="submit"
            title={`Save ${patient?.firstName}'s first session date`}
            theme="primary"
            onClick={handleSubmit(onSubmit, handleErrors)}
          />
        </form>
      </div>
    </Modal>
  );
};

export default FirstAppointmentDateModal;
