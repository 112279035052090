import React, { forwardRef, useEffect, useState } from 'react';
import {
  MediaCategory,
  TaskAudioData,
  useCreateMediaFileMutation,
} from '../../../generated/graphql';

import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useForm, useWatch } from 'react-hook-form';
import Pencil from '../../svgs/Pencil';
import Plus from '../../svgs/Plus';
import Trash from '../../svgs/Trash';
import Upload from '../../svgs/Upload';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import InputGroup from '../InputGroup';
import InputLabel from '../InputLabel';
import Modal from '../Modal';

// 100 MB
const MAX_UPLOAD_SIZE_BYTES = 100 * 1000 * 1000;

interface AudioUploadAreaProps {
  audioData: Maybe<TaskAudioData>;
  onSuccess: (result: TaskAudioData) => void;
  onRemove: () => void;
  isReadOnly: boolean;
}

interface UploadModalProps {
  isOpen: boolean;
  setClosed: () => void;
  onSuccess: (result: TaskAudioData) => void;
  audioData: Maybe<TaskAudioData>;
}

interface UploadAudioFormData {
  file: FileList;
  label: string;
  // Errors
  apiError?: string;
}

type UploadFieldProps = {
  label: string;
  readOnly: boolean;
  errorMessage?: string;
  inputId: string;
  fileName?: string;
} & React.ComponentProps<'input'>;

async function getAudioDuration(url: string): Promise<number> {
  return new Promise((resolve) => {
    const audio = new Audio();
    audio.src = url;

    audio.addEventListener('loadedmetadata', () => {
      resolve(Math.round(audio.duration));
    });
  });
}

const UploadField = forwardRef<HTMLInputElement, UploadFieldProps>(
  ({ label, errorMessage, inputId, fileName, readOnly, ...rest }, ref) => {
    return (
      <div>
        <InputLabel
          label={label}
          inputRequired
          className={
            Boolean(errorMessage) ? 'group-focus-within:text-red-125' : ''
          }
          htmlFor={inputId}
        ></InputLabel>
        <label
          className={classNames(
            'flex w-full justify-between rounded border-2 border-transparent bg-neutral-50 py-3 px-4 text-body text-green-150 transition-colors placeholder:text-neutral-125/75 read-only:text-neutral-125/75  focus:outline-none focus:ring-0',
            {
              'cursor-pointer hover:border-green-100': !readOnly,
            },
          )}
          htmlFor={inputId}
        >
          <span>{fileName || 'Upload a file'}</span>
          <span>
            <Upload className="text-green-100" />
          </span>
        </label>
        <input
          ref={ref}
          id={inputId}
          type="file"
          className="hidden"
          accept=".mp3"
          {...rest}
        />
        <ErrorMessage className="mt-2">{errorMessage}</ErrorMessage>
      </div>
    );
  },
);

const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  setClosed,
  onSuccess,
  audioData,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors: formErrors },
    reset,
  } = useForm<UploadAudioFormData>({
    defaultValues: { label: audioData?.label },
    mode: 'onChange',
  });

  // This is kind of annoying, but we need to explicitly set the label value
  // in case the file gets removed
  useEffect(() => {
    setValue('label', audioData?.label ?? '');
  }, [audioData]);

  const [createMediaFile] = useCreateMediaFileMutation();
  const isEditing = Boolean(audioData);

  const selectedFile = useWatch({ control, name: 'file' });
  const fileName = selectedFile?.[0]?.name;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (submittedData: UploadAudioFormData) => {
    setIsLoading(true);

    try {
      const { file, label } = submittedData;
      if (isEditing) {
        onSuccess({
          ...audioData!,
          label,
        });
      } else {
        const media = await createMediaFile({
          variables: {
            input: {
              category: MediaCategory.ModuleAudio,
              file: file[0],
            },
          },
        });

        const mediaId = media.data?.createMediaFile.id;
        const url = media.data?.createMediaFile.url;

        if (mediaId && url) {
          const duration = await getAudioDuration(url);

          onSuccess({
            fileName: file[0].name,
            duration,
            label,
            mediaId,
            url,
          });
        }
      }

      setIsLoading(false);
      setClosed();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError('apiError', {
        message: err.toString(),
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setClosed={() => {
        setClosed();
        reset();
      }}
      width="small"
      fetching={isLoading}
    >
      <div>
        <div className="mb-10 px-10">
          <div className="mb-3 w-full text-center font-serif text-subtitle text-green-150">
            Add an audio file
          </div>
          <div className="font-sans-serif w-full text-center text-extra-small text-green-125">
            Upload audio content to attach to this article
          </div>
        </div>
        <form className="w-full px-9 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <UploadField
              readOnly={Boolean(audioData)}
              inputId="audio-file"
              label="Upload File"
              fileName={audioData?.fileName || fileName}
              errorMessage={formErrors.file?.message}
              {...register('file', {
                required: 'Please select a file to upload',
                disabled: Boolean(audioData?.fileName),
                validate: (fileList) => {
                  if (fileList?.[0].size > MAX_UPLOAD_SIZE_BYTES) {
                    return "That file is a bit too big. Try one that's smaller than 100 MB";
                  }
                },
              })}
            />
            <div className="mt-2 text-small-caption text-neutral-125">
              We support files formatted as MP3s (max 100 MB)
            </div>
          </div>

          <div className="mb-5">
            <InputGroup
              label="Label"
              containerClassName="w-full"
              inputSize="small"
              required
              errorMessage={formErrors.label?.message}
              {...register('label', {
                required: 'Please enter a label for this file',
              })}
            />
          </div>

          <div className="mt-10 flex flex-col items-center justify-center">
            <Button
              title="Save audio file"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

const AudioUploadArea: React.FC<AudioUploadAreaProps> = ({
  audioData,
  onSuccess,
  onRemove,
  isReadOnly,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isAudioSet = Boolean(audioData);
  const isPlayable = isAudioSet && isReadOnly;

  if (!isAudioSet && isReadOnly) {
    return null;
  }

  return (
    <div className="relative">
      <UploadModal
        isOpen={isUploadModalOpen}
        setClosed={() => setIsUploadModalOpen(false)}
        onSuccess={onSuccess}
        audioData={audioData}
      />
      <div
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        tabIndex={-1}
        className={classNames(
          'w-full cursor-pointer rounded-lg border text-center transition-colors focus:!ring-0',
          {
            'border-dashed border-neutral-100 bg-neutral-50/25  hover:bg-green-25/50':
              !isAudioSet,
            'border-2 border-neutral-50 bg-neutral-50': isAudioSet,
            'border-green-50': isFocused,
            'p-6': !isPlayable,
            'p-4': isPlayable,
          },
        )}
      >
        {isAudioSet && (
          <div className="flex justify-center">
            <div className="h-full w-full">
              <div className="mb-2 text-body font-bold text-green-150">
                {audioData!.label}
              </div>
              {isReadOnly ? (
                <div className="flex justify-center">
                  <audio controls>
                    <source src={audioData!.url} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                </div>
              ) : (
                <div className="text-body text-neutral-125">
                  {audioData!.fileName}
                </div>
              )}
            </div>
            {!isReadOnly && isFocused && (
              <div className="absolute -right-32 flex justify-between rounded-full py-4 px-6 shadow-400">
                <div onClick={() => setIsUploadModalOpen(true)}>
                  <Pencil className="mr-6 h-5 w-5 cursor-pointer text-neutral-125 hover:text-neutral-125/75" />
                </div>
                <div onClick={onRemove}>
                  <Trash className="h-5 w-5 cursor-pointer text-red-100 hover:text-red-100/75" />
                </div>
              </div>
            )}
          </div>
        )}
        {!isAudioSet && !isReadOnly && (
          <div
            className="flex justify-center"
            onClick={() => {
              setIsUploadModalOpen(true);
            }}
          >
            <Plus className="h-8 w-8 text-green-100" />
            <span className="relative top-1.5">Add audio file</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioUploadArea;
