import { FC, useMemo } from 'react';
import classNames from 'classnames';

import { PatientsQuery } from '../../../../../generated/graphql';

import Button from '../../../../components/Button';
import ProgramItem from '../../../../components/ProgramItem';
import { EmptyProgramItem } from '../../../../components/EmptyProgramItem';

import ArrowRightLong from '../../../../svgs/ArrowRightLong';
import ChevronRight from '../../../../svgs/ChevronRight';
import FirstTreatmentDatePopover from '../../FirstTreatmentDatePopover';

import { getOnboardingStatusText } from '../helpers';

type InvitedRowProps = {
  isChecked: boolean;
  patient: PatientsQuery['patients'][number];
  patientIndex: number;
  isCreateEditingProgramTaskEnabled?: boolean;
  isPatientProfileEnabled?: boolean;
  isAssessmentsEnabled?: boolean;
  nextStepsButtonsDisabled: boolean;
  openAssignToProgramModal: () => void;
  openInviteToProgramModal: () => void;
  openFirstAppointmentDateModal: () => void;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientsQuery['patients'][number] | null>
  >;
  navigateToPatient: (patient: PatientsQuery['patients'][number]) => void;
  handleCheckboxInteraction: (
    event: React.ChangeEvent<HTMLInputElement>,
    patient: PatientsQuery['patients'][number],
    patientIndex: number,
  ) => void;
};

const InvitedRow: FC<InvitedRowProps> = ({
  isChecked,
  patient,
  patientIndex,
  nextStepsButtonsDisabled,
  isPatientProfileEnabled,
  isAssessmentsEnabled,
  openInviteToProgramModal,
  handleCheckboxInteraction,
  openFirstAppointmentDateModal,
  setSinglePatient,
  navigateToPatient,
}) => {
  const { timestamp } = useMemo(
    () => getOnboardingStatusText(patient),
    [patient],
  );

  return (
    <tr
      key={patient.programInstanceId}
      className={classNames(
        'group whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
        isPatientProfileEnabled && 'cursor-pointer',
      )}
      onClick={(event) => {
        navigateToPatient?.(patient);
        // Prevent the click from bubbling up to the row.
        event.stopPropagation();
      }}
    >
      <td
        className="relative px-6 py-0 sm:w-16 sm:px-8"
        onClick={(event) => {
          // Prevent the click from bubbling up to the row.
          event.stopPropagation();
        }}
      >
        {isChecked && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-white" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125 sm:left-6"
          value={patient.email}
          checked={isChecked}
          onChange={(event) =>
            handleCheckboxInteraction(event, patient, patientIndex)
          }
        />
      </td>
      <td className="w-1/4 py-4 px-0">
        <div
          className={classNames(
            'font-serif text-extra-small',
            !isPatientProfileEnabled && 'mb-1',
          )}
        >
          {patient?.firstName + ' ' + patient?.lastName}
        </div>
        {!isPatientProfileEnabled && (
          <div className="text-small-caption">{patient?.email}</div>
        )}
      </td>
      <td className="w-1/4">
        {patient?.programTemplate ? (
          <div className="flex flex-row items-center justify-start">
            <ProgramItem
              programTemplateId={patient.programTemplate.id}
              programTemplateName={patient.programTemplate.name ?? undefined}
              programTemplateStatus={patient.programTemplate.status}
              className="mr-1"
            />
            {isAssessmentsEnabled &&
              !patient.firstAppointmentAt &&
              patient.firstName && (
                <FirstTreatmentDatePopover
                  patientFirstName={patient.firstName}
                  onClick={(event) => {
                    setSinglePatient(patient);
                    openFirstAppointmentDateModal();
                    event.stopPropagation();
                  }}
                />
              )}
          </div>
        ) : (
          <EmptyProgramItem />
        )}
      </td>
      <td className="w-1/4">
        {timestamp && (
          <div className="text-caption text-green-150">{timestamp}</div>
        )}
      </td>
      <td className="w-1/4">
        <Button
          IconComponent={ArrowRightLong}
          title="Re-send invite"
          theme="secondary"
          size="small"
          disabled={nextStepsButtonsDisabled}
          onClick={(event) => {
            setSinglePatient(patient);
            openInviteToProgramModal();
            event.stopPropagation();
          }}
        />
      </td>
      <td className="flex flex-row items-center justify-end">
        <Button
          noOutline
          noBackground
          theme="secondary"
          className="pt-1"
          IconComponent={ChevronRight}
        />
      </td>
    </tr>
  );
};

export default InvitedRow;
