import classNames from 'classnames';
import { useState } from 'react';

import PadlockIcon from '../../assets/svgs/padlock.svg';
import { ProgramNoteDataFragment } from '../../generated/graphql';
import Pin from '../svgs/Pin';
import { getTimeStamp } from '../lib/time';
import Button from './Button';

type NoteCardProps = {
  programNote: ProgramNoteDataFragment;
  className?: string;
};

const CLAMP_LINE_COUNT = 3;

const NoteCard = ({ programNote, className }: NoteCardProps) => {
  const [isClamped, setIsClamped] = useState(true);
  const isClampable = programNote.note.split('\n').length > CLAMP_LINE_COUNT;

  return (
    <div
      className={classNames(
        'flex flex-col items-start rounded-lg bg-white p-5 shadow-100',
        className,
      )}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <Pin className="invisible" />
        <div className="flex flex-row justify-center self-center text-center text-small-caption text-neutral-125">
          {getTimeStamp(programNote.updatedAt)}
          <img src={PadlockIcon} alt="private note" className="ml-1 h-4 w-4" />
        </div>
        <Pin
          className={classNames(
            programNote?.isPinnedNote ? 'text-green-100 ' : 'invisible',
          )}
        />
      </div>
      <div className="mt-2 flex flex-row items-center text-small text-neutral-150">
        {programNote.title}
      </div>
      <div
        className={classNames(
          'editor mt-4 flex flex-col justify-center whitespace-pre-line text-caption text-neutral-150',
          isClamped && 'line-clamp-[25]',
        )}
        dangerouslySetInnerHTML={{ __html: programNote.note }}
      />
      {isClampable && (
        <Button
          title={isClamped ? 'See more' : 'See less'}
          className="mt-5 text-caption font-medium text-green-100"
          type="button"
          theme="none"
          noBackground
          noFocus
          noOutline
          onClick={() => setIsClamped(!isClamped)}
        />
      )}
    </div>
  );
};

export default NoteCard;
