import Config from '../../lib/config';

export const HOMECOMING_PARTNER_HUB_BASE_URL = Config.REACT_APP_CIRCLE_BASE_URL;

export const SIGN_OUT_ENDPOINT = 'users/sign_out';

export const signOutOfCircle = async () => {
  // Underneath the hood, this is a regular navigation to the Circle logout endpoint to sign the
  // user out of their session there. There isn't another method documented in Circle, but is the
  // sign out endpoint I found in the Circle website, and this iframe navigation is hacky but
  // the only way I was able to find to sign the user out of Circle.
  try {
    // Create an iframe and navigate to the Circle logout URL
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
    iframe.src = `${HOMECOMING_PARTNER_HUB_BASE_URL}${SIGN_OUT_ENDPOINT}`;
    document.body.appendChild(iframe);

    // Remove the iframe after a short delay to allow the logout to complete
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        document.body.removeChild(iframe);
        resolve();
      }, 500);
    });

    console.log('Successfully signed out of Circle');
  } catch (error) {
    console.error('Error signing out of Circle:', error);
  }
};
