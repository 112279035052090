import { FC } from 'react';
import classNames from 'classnames';
import Tab, { TabProps } from './Tab';

const Tabs: FC<{
  tabList: TabProps[];
  fullWidth?: boolean;
  containerClassName?: string;
  isPills?: boolean;
}> = ({ tabList, fullWidth = false, containerClassName, isPills }) => {
  return (
    <div
      className={classNames(
        'flex items-center border-b border-neutral-75',
        fullWidth ? 'w-full justify-start' : 'justify-center',
        isPills && 'border-none',
        containerClassName,
      )}
    >
      {tabList.map((tabInfo, index) => {
        const isLastTab = tabList.length - 1 === index;
        return (
          <Tab
            key={tabInfo.name}
            route={tabInfo.route}
            name={tabInfo.name}
            count={tabInfo.count}
            isLastTab={isLastTab}
            isPills={isPills}
          />
        );
      })}
    </div>
  );
};

export default Tabs;
