import {
  AssessmentQuestionType,
  AssessmentSignatureUserInput,
  CustomAssessmentQuery,
  CustomAssessmentQuestionInput,
  SignatureUser,
} from '../../../generated/graphql';

import {
  isFreeTextAssessmentQuestion,
  isMultiSelectAssessmentQuestion,
  isMultiSignatureAssessmentQuestion,
  isMultipleChoiceAssessmentQuestion,
  isRichTextAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isStatementAssessmentQuestion,
} from '../../lib/assessments';

import { Question, QuestionForm } from './QuestionContainer/helpers';
import { UseFormSetError } from 'react-hook-form';
import { customToast } from '../../components/ToastAlert/customToast';

export const defaultQuestionSchema: Question = {
  questionType: AssessmentQuestionType.MultipleChoice,
  isRequired: true,
  question: '',
  answerOptions: ['', ''],
  assessmentUserOrderIndices: [],
};

export const defaultAssessmentSignatureUserSchema: AssessmentSignatureUserInput =
  {
    signatureUserType: SignatureUser.PatientUser,
    orderIndex: 0,
  };

type CustomAssessmentQuestions =
  CustomAssessmentQuery['assessment']['questions'];

export const formatQuestionFormData = (
  questions: Question[],
): CustomAssessmentQuestionInput[] => {
  return questions?.map((question) => ({
    questionType: question.questionType,
    isRequired: question.isRequired,
    ...(isMultipleChoiceAssessmentQuestion(question) && {
      multipleChoiceQuestion: {
        question: question.question,
        answerOptions: question.answerOptions,
      },
    }),
    ...(isMultiSelectAssessmentQuestion(question) && {
      multiSelectQuestion: {
        question: question.question,
        answerOptions: question.answerOptions,
      },
    }),
    ...(isFreeTextAssessmentQuestion(question) && {
      freeTextQuestion: {
        question: question.question,
      },
    }),
    ...(isRichTextAssessmentQuestion(question) && {
      richTextQuestion: {
        question: question.question,
      },
    }),
    ...(isSignatureAssessmentQuestion(question) && {
      signatureQuestion: {
        question: question.question,
      },
    }),
    ...(isMultiSignatureAssessmentQuestion(question) && {
      multiSignatureQuestion: {
        question: question.question,
        assessmentUserOrderIndices: question.assessmentUserOrderIndices,
      },
    }),
    ...(isStatementAssessmentQuestion(question) && {
      statement: {
        statement: question.question,
      },
    }),
  }));
};

export const formatQuestionDataForForm = (
  questions: CustomAssessmentQuestions,
): Question[] => {
  return questions?.map((question) => ({
    ...defaultQuestionSchema,
    questionType: question.questionType,
    ...(isMultipleChoiceAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
      answerOptions: question?.answerOptions?.map(
        (answerOption) => answerOption?.answerOption,
      ),
    }),
    ...(isMultiSelectAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
      answerOptions: question?.answerOptions?.map(
        (answerOption) => answerOption?.answerOption,
      ),
    }),
    ...(isFreeTextAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
    }),
    ...(isRichTextAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
    }),
    ...(isSignatureAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
    }),
    ...(isMultiSignatureAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
      assessmentUserOrderIndices: question?.assessmentSignatureOrderIndices,
    }),
    ...(isStatementAssessmentQuestion(question) && {
      isRequired: question.isRequired,
      question: question?.question,
    }),
  }));
};

export const isMultiSignatureValid = (
  questions: CustomAssessmentQuestionInput[],
  assessmentSignatureUsers: AssessmentSignatureUserInput[],
  setError: UseFormSetError<QuestionForm>,
) => {
  const validationErrors = [];
  const usedSignatureUsers = new Set<number>();

  questions.forEach((question, index) => {
    if (question.questionType === AssessmentQuestionType.MultiSignature) {
      const questionSignatures =
        question.multiSignatureQuestion?.assessmentUserOrderIndices || [];

      if (questionSignatures.length < 1) {
        validationErrors.push({
          field: `questions.${index}.question`,
          message: `Each multi-signature question must have at least one signer.`,
        });
      }

      questionSignatures.forEach((orderIndex) =>
        usedSignatureUsers.add(orderIndex),
      );
    }
  });

  // New validation step
  const inactiveUsers = assessmentSignatureUsers.filter(
    (user) =>
      !usedSignatureUsers.has(user.orderIndex) &&
      questions.some(
        (question) =>
          question.questionType === AssessmentQuestionType.MultiSignature &&
          question.multiSignatureQuestion?.assessmentUserOrderIndices.includes(
            user.orderIndex,
          ),
      ),
  );

  if (inactiveUsers.length > 0) {
    customToast.warning(
      `Each signer must be active in at least one multi-signature question.`,
    );
    return false;
  }

  if (validationErrors.length > 0) {
    validationErrors.forEach(({ field, message }) =>
      setError(field, { message }),
    );
    return false;
  }

  return true;
};
