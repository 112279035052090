import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { ProgramTemplatesQuery } from '../../../../generated/graphql';
import ProgramStatus from '../../../components/ProgramStatus';
import Button from '../../../components/Button';
import ChevronRight from '../../../svgs/ChevronRight';

type TableRowProps = {
  programTemplate: ProgramTemplatesQuery['programTemplates'][number];
  programIndex: number;
};

const TableRow: FC<TableRowProps> = ({ programTemplate }) => {
  const navigate = useNavigate();
  const navigateToProgram = () => {
    navigate(`/programs/${programTemplate.id}`);
  };

  return (
    <tr
      key={programTemplate.id}
      className={classNames(
        'h-20 whitespace-nowrap text-green-150',
        'cursor-pointer hover:bg-neutral-25',
      )}
      onClick={navigateToProgram}
    >
      <td className="font-serif text-extra-small">{programTemplate?.name}</td>
      <td>
        <ProgramStatus status={programTemplate.status} />
      </td>
      <td>{programTemplate.activePatientCount}</td>
      <td>{programTemplate.programLengthInMonths} months</td>
      <td className="flex flex-row items-center justify-end">
        <Button
          theme="secondary"
          IconComponent={ChevronRight}
          noBackground
          onClick={navigateToProgram}
        />
      </td>
    </tr>
  );
};

export default TableRow;
