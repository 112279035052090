import { FC, useState, useEffect, useRef } from 'react';

import {
  useForm,
  useFieldArray,
  SubmitHandler,
  SubmitErrorHandler,
  FormProvider,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Exact,
  MeProviderProgramTagsQuery,
  ProgramTagDataFragment,
  useAddPatientsV2Mutation,
} from '../../../../generated/graphql';

import {
  PatientRow,
  PatientRowValues,
  SpreadsheetData,
} from '../../../types/PatientManagement';

import { formatValidatedPatientLeads } from './helpers';
import {
  PatientsObject,
  getPatientsObject,
} from '../../../pages/PatientManagement/helpers';

import Button from '../../Button';
import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';
import ArrowRightLong from '../../../svgs/ArrowRightLong';
import PatientInputRow from './PatientInputRow';
import UseASpreadsheetMenu from './UseASpreadsheetMenu';
import ValidationWarning from '../../../pages/PatientManagement/ValidationWarning';

import {
  PATIENTS_ACTIVE_BASE_PATH,
  PatientManagementState,
} from '../../../pages/PatientManagement';

import InputLabel from '../../InputLabel';
import AddRowButton from '../../AddRowButton';
import { removeEmptyFields } from '../../../lib/form';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';
import ToastAlert from '../../ToastAlert';
import toast from 'react-hot-toast';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useAuth } from '../../../../contexts/AuthContext';
import { HomecomingError, matchHomecomingError } from '../../../../lib/errors';

type RefetchMeProviderProgramTags = (
  variables?:
    | Partial<
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<MeProviderProgramTagsQuery>>;

type AddPatientsModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  isClientsPage?: boolean;
  patientsObject?: PatientsObject | null;
  setPatientsObject?: React.Dispatch<
    React.SetStateAction<PatientsObject | null>
  >;
  clearFilters?: (stateOverride?: Partial<PatientManagementState>) => void;
  prefilledPatientsList?: PatientRow[];
  isFromCalendly?: boolean;
  providerProgramTags: ProgramTagDataFragment[];
  refetchMeProviderProgramTags: RefetchMeProviderProgramTags;
  noPatientDataPermission?: boolean;
  refetchPatientsCount?: () => void;
};

const AddPatientsModal: FC<AddPatientsModalProps> = ({
  patientsObject,
  isModalOpen,
  isClientsPage = true,
  setClosed,
  setPatientsObject,
  clearFilters,
  prefilledPatientsList,
  isFromCalendly = false,
  providerProgramTags,
  refetchMeProviderProgramTags,
  noPatientDataPermission = false,
  refetchPatientsCount,
}) => {
  const navigate = useNavigate();
  const { willHitClientLimit, refreshAuthedProviderUserSubscriptionState } =
    useAuth();

  const addPatientsForm = useForm<PatientRowValues>({
    mode: 'onSubmit',
    defaultValues: {
      patientRows: [{ name: '', email: '', phoneNumber: '', tags: [] }],
    },
  });

  useEffect(() => {
    if (prefilledPatientsList)
      addPatientsForm.setValue('patientRows', prefilledPatientsList);
  }, [prefilledPatientsList]);

  const [addPatientsMutation] = useAddPatientsV2Mutation();

  const [loading, setLoading] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState<SpreadsheetData>([]);
  const [showValidationWarning, setShowValidationWarning] = useState(false);

  const [validationWarningText, setValidationWarningText] = useState(
    'Duplicate emails removed',
  );

  const overflowContainerRef = useRef<HTMLDivElement>();

  const { fields, append, insert, remove, replace } = useFieldArray({
    name: 'patientRows',
    control: addPatientsForm.control,
  });

  useEffect(() => {
    if (spreadsheetData.length > 1) {
      addPatientsForm.reset();
      replace(spreadsheetData.slice(0));
    }
  }, [spreadsheetData, addPatientsForm.reset, replace]);

  const patientRows = addPatientsForm.watch('patientRows');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...patientRows[index],
    };
  });

  const handleErrors: SubmitErrorHandler<PatientRowValues> = (errors) => {
    console.log({ errors });
  };

  const onSubmit: SubmitHandler<PatientRowValues> = async (validatedData) => {
    addPatientsForm.clearErrors();

    const formattedAndValidatedPatientLeads =
      formatValidatedPatientLeads(validatedData);

    try {
      const addPatientsInput = {
        newPatientLeads: formattedAndValidatedPatientLeads,
      };
      setLoading(true);
      const response = await addPatientsMutation({
        variables: {
          input: addPatientsInput,
        },
      });

      const addPatientsData = response?.data?.addPatientsV2;

      if (addPatientsData?.validationErrors) {
        addPatientsData?.validationErrors.forEach((fieldErrors) => {
          const {
            inputId,
            isDuplicate,
            emailInvalid,
            nameInvalid,
            phoneNumberInvalid,
            tagsInvalid,
          } = fieldErrors;
          if (isDuplicate) {
            addPatientsForm.setError(`patientRows.${inputId}.email`, {
              type: 'Duplicate email',
              message: 'Email already registered',
            });
          }
          if (emailInvalid) {
            addPatientsForm.setError(`patientRows.${inputId}.email`, {
              type: 'Invalid email',
              message: 'Email is invalid',
            });
          }
          if (nameInvalid) {
            addPatientsForm.setError(`patientRows.${inputId}.name`, {
              type: 'Invalid name',
              message: 'Name is invalid',
            });
          }
          if (phoneNumberInvalid) {
            addPatientsForm.setError(`patientRows.${inputId}.phoneNumber`, {
              type: 'Invalid phoneNumber',
              message: 'Phone number is invalid',
            });
          }
          if (tagsInvalid) {
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message="Invalid tags."
                level="error"
              />
            ));
          }
        });
        setLoading(false);

        return;
      }

      const newPatients = addPatientsData?.newPatients;

      if (newPatients && newPatients?.length > 0) {
        if (isFromCalendly) {
          trackProviderEvent(ProviderAnalyticsEvent.AddClientsFromCalendly, {
            originPage: 'Home',
            component: 'AddPatientsModal',
          });
        }

        await refreshAuthedProviderUserSubscriptionState();

        if (!isClientsPage) {
          navigate(PATIENTS_ACTIVE_BASE_PATH);
          return;
        }

        navigate(PATIENTS_ACTIVE_BASE_PATH);

        if (!noPatientDataPermission) {
          setPatientsObject?.({
            ...getPatientsObject(newPatients),
            ...patientsObject,
          });
          // clearFilters?.();
          await refetchPatientsCount?.();
        }
      }

      await refetchMeProviderProgramTags();

      closeAndResetAddPatientsModal();
    } catch (err) {
      console.log(err);
      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        toast.custom(({ visible }) => (
          <ToastAlert isVisible={visible} message={err.message} level="error" />
        ));
      } else {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="An error occurred while adding patients. Please try again."
            level="error"
          />
        ));
      }
      closeAndResetAddPatientsModal();
    }
  };

  const closeAndResetAddPatientsModal = () => {
    setClosed();
    setTimeout(() => {
      setShowValidationWarning(false);
      setLoading(false);
      addPatientsForm.reset();
    }, MODAL_TRANSITION_DURATION);
  };

  const hasManyFields = controlledFields.length > 1;

  return (
    <Modal
      name="AddPatients"
      isOpen={isModalOpen}
      setClosed={closeAndResetAddPatientsModal}
      fetching={loading}
    >
      <>
        <div className="mt-6 mb-10 flex flex-row items-center justify-between pr-16 pl-10">
          <div className="font-serif text-subtitle text-green-150">
            {`Add clients${isFromCalendly ? ' from Calendly' : ''}`}
          </div>
          {!isFromCalendly && (
            <UseASpreadsheetMenu
              setSpreadsheetData={setSpreadsheetData}
              setShowValidationWarning={setShowValidationWarning}
              setValidationWarningText={setValidationWarningText}
            />
          )}
        </div>
        <div className="flex w-full flex-row items-start justify-between pr-16 pl-10">
          <InputLabel
            label="Name"
            useNaturalLettering
            containerClassName="w-1/4"
          />
          <InputLabel
            label="Email"
            useNaturalLettering
            containerClassName="w-1/4"
          />
          <InputLabel
            label="Phone number (optional)"
            useNaturalLettering
            containerClassName="w-1/4"
          />
          <InputLabel
            label="Tag(s)"
            useNaturalLettering
            containerClassName="w-1/4"
          />
        </div>
        <FormProvider {...addPatientsForm}>
          <form className="flex h-[60vh] w-full flex-col items-center justify-between px-9 pb-4">
            <div
              ref={overflowContainerRef}
              className="flex h-full w-full flex-col justify-start overflow-y-scroll"
            >
              <div className="flex flex-col items-center justify-start pl-1 pr-2 pt-1">
                {controlledFields.map((field, index) => {
                  const lastRowIndex = controlledFields.length - 1;
                  return (
                    <PatientInputRow
                      key={field.id}
                      field={field}
                      rowIndex={index}
                      lastRowIndex={lastRowIndex}
                      hasManyFields={hasManyFields}
                      insert={insert}
                      remove={remove}
                      overflowContainerRef={overflowContainerRef}
                      disabled={loading}
                      providerProgramTags={providerProgramTags}
                      refetchProviderProgramTags={refetchMeProviderProgramTags}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-6 flex w-full flex-row items-center justify-between pr-6">
              <div className="flex w-72 flex-row items-center justify-between">
                <AddRowButton
                  title="Add another client"
                  onClick={() => {
                    if (willHitClientLimit(patientRows.length + 1)) {
                      toast.custom(({ visible }) => (
                        <ToastAlert
                          isVisible={visible}
                          message="Another client will exceed your client limit. Please upgrade to add more clients."
                          level="error"
                        />
                      ));
                      return;
                    }
                    append({
                      name: '',
                      email: '',
                      phoneNumber: '',
                    });
                  }}
                />
              </div>
              <ValidationWarning
                fadeOut
                isOpen={showValidationWarning}
                warningText={validationWarningText}
                setShowValidationWarning={setShowValidationWarning}
              />
              <div className="flex w-72 flex-row items-center justify-end">
                <Button
                  title={`Add client${hasManyFields ? 's' : ''}`}
                  theme="primary"
                  IconComponent={ArrowRightLong}
                  onClick={() => {
                    removeEmptyFields(controlledFields, remove);
                    addPatientsForm.handleSubmit(onSubmit, handleErrors)();
                  }}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </>
    </Modal>
  );
};

export default AddPatientsModal;
