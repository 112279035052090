import { FC } from 'react';

import {
  PatientLifecycleStatus,
  PatientsCount,
} from '../../../../generated/graphql';
import Button from '../../../components/Button';
import butterflyImage from '../../../../assets/images/hc-butterfly.png';

import ClearFiltersButton from '../../../components/ClearFiltersButton';
import Plus from '../../../svgs/Plus';

const EmptyTable: FC<{
  tableType: PatientLifecycleStatus;
  patientsCount: PatientsCount;
  openAddPatientsModal?: () => void;
  clearFilters: () => void;
}> = ({ tableType, patientsCount, openAddPatientsModal, clearFilters }) => {
  const showClearFiltersButton =
    (tableType === PatientLifecycleStatus.Onboarding &&
      patientsCount?.onboardingPatientsCount > 0) ||
    (tableType === PatientLifecycleStatus.Invited &&
      patientsCount?.invitedPatientsCount > 0) ||
    (tableType === PatientLifecycleStatus.Active &&
      patientsCount?.activePatientsCount > 0) ||
    (tableType === PatientLifecycleStatus.Archived &&
      patientsCount?.archivedPatientsCount > 0);

  return (
    <div className="flex w-full flex-col items-center justify-center p-1">
      <img src={butterflyImage} className="m-3 mt-6 h-[64px] w-[79px]" alt="" />
      <h3 className="mb-4 font-serif text-subtitle-small text-green-150">
        Hmm... it's quiet in here
      </h3>
      <p className="text-caption text-neutral-125">
        {tableType === PatientLifecycleStatus.Onboarding && (
          <>
            {patientsCount?.onboardingPatientsCount === 0 &&
              'Add your clients to get started'}
            {patientsCount?.onboardingPatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
        {tableType === PatientLifecycleStatus.Invited && (
          <>
            {patientsCount?.invitedPatientsCount === 0 &&
              'Your invited clients will appear here'}
            {patientsCount?.invitedPatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
        {tableType === PatientLifecycleStatus.Active && (
          <>
            {patientsCount?.activePatientsCount === 0 &&
              'Your active clients will appear here'}
            {patientsCount?.activePatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
        {tableType === PatientLifecycleStatus.Archived && (
          <>
            {patientsCount?.archivedPatientsCount === 0 &&
              'Your archived clients will appear here'}
            {patientsCount?.archivedPatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
      </p>
      <div className="mt-6">
        {!showClearFiltersButton ? (
          <Button
            theme="secondary"
            size="small"
            title={'Add clients'}
            onClick={() => openAddPatientsModal?.()}
            IconComponent={Plus}
          />
        ) : (
          <ClearFiltersButton
            hasFilters={showClearFiltersButton}
            clearFilters={clearFilters}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyTable;
