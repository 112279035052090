import { SvgIconComponent } from '../types/svgs';

const Tag: SvgIconComponent = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="tag">
        <path
          id="Vector"
          d="M7.97333 2.5H4.375C3.87772 2.5 3.40081 2.69754 3.04917 3.04917C2.69754 3.40081 2.5 3.87772 2.5 4.375V7.97333C2.5 8.47083 2.6975 8.94833 3.04917 9.29917L11.0333 17.2833C11.6158 17.8658 12.5167 18.01 13.2058 17.5583C14.9405 16.4222 16.4222 14.9405 17.5583 13.2058C18.01 12.5167 17.8658 11.6158 17.2833 11.0333L9.3 3.05C9.12586 2.87564 8.91905 2.73732 8.69141 2.64295C8.46377 2.54857 8.21976 2.5 7.97333 2.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M5 5H5.00667V5.00667H5V5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Tag;
