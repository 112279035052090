import { FC, Fragment, useState } from 'react';
import Button from '../../components/Button';
import { usePopper } from 'react-popper';
import { Menu } from '@headlessui/react';
import MenuButton from '../../components/MenuButton';
import { ClipboardIcon } from '@heroicons/react/outline';
import { FollowUpTemplateDataFragment } from '../../../generated/graphql';
import {
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';

const SaveTemplateButton: FC<{
  disabled: boolean;
  existingTemplates: FollowUpTemplateDataFragment[];
  onClickSaveAsNewTemplate: () => Promise<void>;
  onClickUpdateExistingTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
}> = ({
  disabled,
  existingTemplates,
  onClickSaveAsNewTemplate,
  onClickUpdateExistingTemplate,
}) => {
  const [mainMenuReferenceElement, setMainMenuReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [mainMenuPopperElement, setMainMenuPopperElement] =
    useState<HTMLDivElement | null>(null);
  const [subMenuReferenceElement, setSubMenuReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [subMenuPopperElement, setSubMenuPopperElement] =
    useState<HTMLDivElement | null>(null);

  const { styles: mainMenuStyles, attributes: mainMenuAttributes } = usePopper(
    mainMenuReferenceElement,
    mainMenuPopperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 2] } }],
    },
  );

  const { styles: subMenuStyles, attributes: subMenuAttributes } = usePopper(
    subMenuReferenceElement,
    subMenuPopperElement,
    {
      placement: 'left-start',
      strategy: 'fixed',
    },
  );

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <Menu>
      {({ close: closeMainMenu }) => (
        <>
          <Menu.Button as={Fragment}>
            <Button
              title="Save template"
              theme="secondary"
              size="small"
              className="mr-3 py-1.5"
              disabled={disabled}
              ref={setMainMenuReferenceElement}
              onClick={() => {
                setIsSubMenuOpen(false); // Ensures submenu state is reset on menu open
                trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                  originPage: AnalyticsPage.FollowUpBuilder,
                  buttonName: 'Save template',
                });
              }}
            />
          </Menu.Button>
          <Menu.Items
            as="div"
            ref={setMainMenuPopperElement}
            style={mainMenuStyles.popper}
            {...mainMenuAttributes.popper}
            className="w-64 divide-y divide-neutral-100 rounded-md bg-white shadow-400 focus:outline-none"
          >
            <div
              // Emulating OS X behavior where the submenu stays open after the mouse hovers over it,
              // and only closes when another sibling element is hovered over.
              onMouseEnter={() => setIsSubMenuOpen(false)}
            >
              <MenuButton
                titleText="Save as new template"
                className="rounded-t-md"
                onClick={(e) => {
                  e.stopPropagation();
                  trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                    originPage: AnalyticsPage.FollowUpBuilder,
                    buttonName: 'Save as new template',
                  });
                  onClickSaveAsNewTemplate();
                }}
              />
            </div>
            {existingTemplates.length > 0 && (
              <Menu>
                <Menu.Button
                  as="div"
                  ref={setSubMenuReferenceElement}
                  // Emulating OS X behavior where the submenu stays open after the mouse hovers over it,
                  // and only closes when another sibling element is hovered over.
                  onMouseEnter={() => setIsSubMenuOpen(true)}
                >
                  <MenuButton
                    titleText="Update existing template"
                    onClick={() => {
                      trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                        originPage: AnalyticsPage.FollowUpBuilder,
                        buttonName: 'Update existing template',
                      });
                    }}
                  />
                </Menu.Button>
                {isSubMenuOpen && (
                  <Menu.Items
                    as="div"
                    static
                    ref={setSubMenuPopperElement}
                    style={subMenuStyles.popper}
                    {...subMenuAttributes.popper}
                    className="max-h-[60vh] w-64 divide-y divide-neutral-100 overflow-y-auto rounded-md !border-t-0 bg-white shadow-400 focus:outline-none"
                  >
                    {existingTemplates.map((template) => (
                      <MenuButton
                        key={`saveExistingTemplateButton-${template.id}`}
                        titleText={template.name}
                        IconComponent={ClipboardIcon}
                        iconPosition="left"
                        onClick={async () => {
                          closeMainMenu();
                          await onClickUpdateExistingTemplate(template);
                        }}
                      />
                    ))}
                  </Menu.Items>
                )}
              </Menu>
            )}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default SaveTemplateButton;
