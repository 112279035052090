import { FC } from 'react';

import { AssessmentQuestionDataFragment } from '../../../generated/graphql';
import { AssessmentQuestionTypename } from '../../lib/assessments';
import SlideoverSection from '../SlideoverSection';

const ResponseOptionsSection: FC<{
  title?: string;
  questions?: AssessmentQuestionDataFragment[];
}> = ({ title = 'Response options', questions }) => {
  // Only compatible with multiple choice scored assessment questions (base assessments)
  const assessmentResponseOptions =
    questions?.[0]?.__typename ===
    AssessmentQuestionTypename.MultipleChoiceScoredAssessmentQuestion
      ? questions[0]?.answerOptions.sort((answerOptionA, answerOptionB) => {
          if (answerOptionA.score == null || answerOptionB.score == null) {
            return 0;
          }
          return answerOptionA.score - answerOptionB.score;
        })
      : [];

  return assessmentResponseOptions.length > 0 ? (
    <SlideoverSection title={title}>
      <div className="font-sans text-caption text-neutral-125">
        {assessmentResponseOptions.map((responseOption, index) => {
          if (
            responseOption?.__typename ===
            AssessmentQuestionTypename.MultipleChoiceScoredAssessmentQuestionAnswerOption
          ) {
            return (
              <div
                key={index}
                className="flex flex-row items-center justify-start"
              >
                <span className="w-2">
                  {Boolean(responseOption?.score) && '+'}
                </span>
                <span className="mr-2 w-4 text-center">
                  {responseOption.score != null && responseOption.score}
                </span>
                <span>{responseOption.answerOption}</span>
              </div>
            );
          }
          return null;
        })}
      </div>
    </SlideoverSection>
  ) : null;
};

export default ResponseOptionsSection;
