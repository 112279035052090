import {
  ProviderUserRole,
  PatientDataPermission,
} from '../../../../../generated/graphql';
import { ColumnHeaderType } from '../../../../types/tables';

export const columns: ColumnHeaderType[] = [
  {
    field: 'name',
    headerName: 'Team member',
  },
  {
    field: 'access',
    headerName: 'Role',
  },
  {
    field: 'patientDataPermission',
    headerName: 'Client health data',
  },
  {
    field: 'assignedPatients',
    headerName: 'Assigned clients',
  },
  {
    field: 'actions',
    headerName: 'Actions',
  },
];

export interface ProviderUserRoleOption {
  role: ProviderUserRole;
  description: string;
}

export const ProviderUserRoleOptions: ProviderUserRoleOption[] = [
  {
    role: ProviderUserRole.Administrator,
    description:
      'Can assign clients to practitioners, manage payments and scheduling integrations, update your webpage, and more.',
  },
  {
    role: ProviderUserRole.Practitioner,
    description:
      "Can view and work with clients they've been assigned to only, limited control over Homecoming settings.",
  },
];
export interface PatientDataPermissionOption {
  patientDataPermission: PatientDataPermission;
  label: string;
  description: string;
}

export function getLabelFromPatientDataPermission(
  patientDataPermission: PatientDataPermission,
): { label: string; textColor: string } {
  switch (patientDataPermission) {
    case PatientDataPermission.None:
      return { label: 'No access', textColor: 'text-purple-100' };
    case PatientDataPermission.Assigned:
      return { label: 'Assigned clients', textColor: 'text-blue-100' };
    case PatientDataPermission.All:
      return { label: 'All clients', textColor: 'text-green-100' };
    default:
      return { label: 'Unknown access level', textColor: 'text-gray-100' };
  }
}

export const PatientDataPermissionOptions: PatientDataPermissionOption[] = [
  {
    patientDataPermission: PatientDataPermission.None,
    label: getLabelFromPatientDataPermission(PatientDataPermission.None).label,
    description: 'No access to client records.',
  },
  {
    patientDataPermission: PatientDataPermission.Assigned,
    label: getLabelFromPatientDataPermission(PatientDataPermission.Assigned)
      .label,
    description:
      'Access to client records only from explicitly assigned clients.',
  },
  {
    patientDataPermission: PatientDataPermission.All,
    label: getLabelFromPatientDataPermission(PatientDataPermission.All).label,
    description: 'Has access to all client records',
  },
];
