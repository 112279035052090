import { BOOK_A_CALL_URL } from './constants';
import { CommonAnalyticsEvent, trackProviderEvent } from '../../lib/analytics';

export const SUPPORT_EMAIL = 'support@homecoming.health';
export const SUPPORT_MAIL_TO_URL = `mailto:${SUPPORT_EMAIL}`;

export function withSearchParams(
  url: string,
  params: Record<string, string>,
): string {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });
  return urlObj.toString();
}

export const getShortLink = (link: string) => {
  try {
    return new URL(link).hostname.replace('www.', '');
  } catch (error) {
    return link;
  }
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank')?.focus();
};

export const openBookACallLink = () => {
  openInNewTab(BOOK_A_CALL_URL);
  trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
    buttonName: 'Book a Call Button',
  });
};

export const isClientUrl = () => {
  const pathName = window.location.pathname;

  return (
    ['/client', '/login/client'].includes(pathName) ||
    pathName.startsWith('/client/')
  );
};
