import { FC } from 'react';
import classNames from 'classnames';
import { SvgIconComponent } from '../types/svgs';

const PillButton: FC<{
  title: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  IconComponent?: SvgIconComponent;
  containerClassName?: string;
  disabled?: boolean;
}> = ({
  onClick,
  title,
  IconComponent,
  containerClassName,
  disabled = false,
}) => {
  return (
    <button
      className={classNames(
        'relative flex w-full flex-row items-center justify-start',
        'rounded-lg border border-neutral-50 px-4 py-3',
        'focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
        containerClassName,
        !disabled && 'hover:bg-neutral-25',
        disabled && 'cursor-not-allowed',
      )}
      disabled={disabled}
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
    >
      {IconComponent && (
        <IconComponent
          className={classNames(
            'mr-[9px] h-[22px] w-[22px] text-neutral-125',
            disabled && 'text-neutral-100',
          )}
        />
      )}
      <span
        className={classNames(
          'text-caption font-medium text-neutral-125',
          disabled && 'text-neutral-100',
        )}
      >
        {title}
      </span>
    </button>
  );
};

export default PillButton;
