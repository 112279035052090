import classNames from 'classnames';

interface PillProps {
  /** Make sure to provide the background color. */
  className?: string;
}

const Pill: React.FC<PillProps> = ({ className }) => {
  return (
    <div className={classNames('h-[2px] w-[6px] rounded-[1px]', className)} />
  );
};

export default Pill;
