import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useFixPage from '../../hooks/useFixPage';

import PageContainer from '../../components/Containers/PageContainer';
import Tabs from '../../components/Tabs';
import PageTitle from '../../components/PageTitle';
import { useAuth } from '../../../contexts/AuthContext';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

const Settings = () => {
  useFixPage();

  const { authedProviderUser } = useAuth();
  const location = useLocation();

  const ownerAdminRoutes = ['team', 'billing', 'integrations', 'tags'];

  if (
    !isProviderUserOwnerOrAdminAuthorized(authedProviderUser) &&
    ownerAdminRoutes.some((route) => location.pathname.includes(route))
  ) {
    return <Navigate to="/settings" state={{ from: location }} replace />;
  }

  const tabList = [
    {
      route: '.',
      name: 'Account',
    },
    {
      route: 'notifications',
      name: 'Notifications',
    },
    ...(isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
      ? [
          {
            route: 'team',
            name: 'Team',
          },
          {
            route: 'billing',
            name: 'Subscription',
          },
          {
            route: 'integrations',
            name: 'Integrations',
          },
          {
            route: 'tags',
            name: 'Tags',
          },
        ]
      : []),
  ];

  return (
    <PageContainer>
      <PageTitle title="Settings" />
      <Tabs tabList={tabList} fullWidth />
      <Outlet />
    </PageContainer>
  );
};

export default Settings;
