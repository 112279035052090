import { ClipboardListIcon, MailIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { getWeekdayDate } from '../lib/time';

interface FollowUpSelectorProps {
  id: string;
  isIntake: boolean;
  subject?: string;
  providerNote?: string;
  createdAt: string;
  unreadCommentCount: number;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
}

const FollowUpSelector: React.FC<FollowUpSelectorProps> = ({
  isIntake,
  subject,
  providerNote,
  createdAt,
  unreadCommentCount,
  isSelected,
  onClick,
  className,
}) => {
  const Icon = isIntake ? ClipboardListIcon : MailIcon;

  return (
    <div
      className={classNames(
        'flex min-h-[40px] w-full cursor-pointer items-center justify-between gap-x-1 overflow-y-auto rounded-md px-3 hover:rounded-md hover:bg-neutral-25',
        isSelected && 'bg-green-25/80 font-medium hover:bg-green-25',
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-x-2">
        <Icon
          className={classNames('h-4 w-4 shrink-0', {
            'text-green-100': !isIntake,
            'text-blue-100': isIntake,
          })}
        />
        <div className="flex flex-col justify-center">
          <div className="text-caption text-neutral-150 line-clamp-1">
            {subject || providerNote || getWeekdayDate(createdAt)}
          </div>
          {(subject || providerNote) && (
            <div className="text-small-label text-neutral-110">
              {getWeekdayDate(createdAt)}
            </div>
          )}
        </div>
      </div>
      {unreadCommentCount > 0 && (
        <div className="flex h-2 w-2 shrink-0 flex-row items-center justify-center rounded-xl bg-green-100" />
      )}
    </div>
  );
};

export default FollowUpSelector;
