const TimeLabel: React.FC<{
  hour: string;
  isAM?: boolean;
  isPM?: boolean;
}> = ({ hour, isAM, isPM }) => {
  return (
    <>
      <div>
        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-small-caption leading-5 text-neutral-125">
          {hour} {isAM && 'AM'}
          {isPM && 'PM'}
        </div>
      </div>
      <div />
    </>
  );
};

export default TimeLabel;
