import {
  ActivityType,
  AssessmentSchemaType,
  ProgramActivityDataFragment,
} from '../../generated/graphql';

export const programActivityTitle = (
  programActivity: ProgramActivityDataFragment,
): string => {
  switch (programActivity.activityType) {
    case ActivityType.Action:
      return 'Action';
    case ActivityType.Assessment:
      const isForm =
        programActivity.programAssessment!.assessment!.schemaType ===
        AssessmentSchemaType.Custom;
      return isForm
        ? programActivity.programAssessment!.assessment!.name
        : `${programActivity.programAssessment!.assessment!.name}${
            programActivity.programAssessment!.assessment!.shortName
              ? ` (${programActivity.programAssessment!.assessment!.shortName})`
              : ''
          }`;
    case ActivityType.Audio:
      return programActivity.activityAudio!.title;
    case ActivityType.Image:
      return programActivity.activityImage!.title;
    case ActivityType.Link:
      return programActivity.activityLink!.title;
    case ActivityType.Pdf:
    case ActivityType.PdfForm:
    case ActivityType.Esign:
      return programActivity.activityPDF!.title;
    case ActivityType.Text:
      return programActivity.activityText!.title;
    default:
      return programActivity.name;
  }
};

export const programActivityOpen = (
  programActivity: ProgramActivityDataFragment,
  onAssessmentOpen?: (programAssessmentId: string) => void,
  onTextOpen?: (
    activityText: ProgramActivityDataFragment['activityText'],
  ) => void,
): {
  onOpen?: () => void;
  openText?: string;
  secondaryOnOpen?: () => void;
  secondaryOpenText?: string;
} => {
  switch (programActivity.activityType) {
    case ActivityType.Audio:
      return {
        onOpen: () => {
          window.open(
            programActivity.activityAudio?.media?.url,
            '_blank',
            'noopener,noreferrer',
          );
        },
        openText: 'Open in new tab',
      };
    case ActivityType.Esign:
      if (programActivity.pdfSubmission?.media?.url) {
        return {
          onOpen: () => {
            window.open(
              programActivity.pdfSubmission?.media?.url,
              '_blank',
              'noopener,noreferrer',
            );
          },
          openText: 'Open signed submission in new tab',
        };
      } else {
        return {
          onOpen: () => {
            window.open(
              programActivity.activityPDF?.media.url,
              '_blank',
              'noopener,noreferrer',
            );
          },
          openText: 'Open original document in new tab',
        };
      }
    case ActivityType.Image:
      return {
        onOpen: () => {
          window.open(
            programActivity.activityImage?.media.url,
            '_blank',
            'noopener,noreferrer',
          );
        },
        openText: 'View in new tab',
      };
    case ActivityType.Link:
      return {
        onOpen: () => {
          window.open(
            programActivity.activityLink?.url,
            '_blank',
            'noopener,noreferrer',
          );
        },
        openText: 'Open in new tab',
      };
    case ActivityType.Pdf:
      return {
        onOpen: () => {
          window.open(
            programActivity.activityPDF?.media.url,
            '_blank',
            'noopener,noreferrer',
          );
        },
        openText: 'Open in new tab',
      };
    case ActivityType.PdfForm:
      if (programActivity.pdfSubmission?.media?.url) {
        return {
          onOpen: () => {
            window.open(
              programActivity.pdfSubmission?.media?.url,
              '_blank',
              'noopener,noreferrer',
            );
          },
          openText: 'Open submission in new tab',
          secondaryOnOpen: () => {
            window.open(
              programActivity.activityPDF?.media.url,
              '_blank',
              'noopener,noreferrer',
            );
          },
          secondaryOpenText: 'Open original in new tab',
        };
      } else {
        return {
          onOpen: () => {
            window.open(
              programActivity.activityPDF?.media.url,
              '_blank',
              'noopener,noreferrer',
            );
          },
          openText: 'Open in new tab',
        };
      }
    case ActivityType.Assessment:
      if (
        onAssessmentOpen &&
        programActivity.programAssessment &&
        programActivity.programAssessment.assessment.schemaType !==
          AssessmentSchemaType.Custom
      ) {
        return {
          onOpen: onAssessmentOpen.bind(
            null,
            programActivity.programAssessment.id,
          ),
          openText: 'Open assessment',
        };
      }
      return { onOpen: undefined, openText: undefined };
    case ActivityType.Text:
      return {
        onOpen: onTextOpen?.bind(null, programActivity.activityText),
        openText: 'Read',
      };
    case ActivityType.Action:
    default:
      return { onOpen: undefined, openText: undefined };
  }
};
