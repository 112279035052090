import { FC } from 'react';
import classNames from 'classnames';
import { ColumnHeaderType } from '../../types/tables';
import {
  ProgramActivitiesQuery,
  ProgramActivityDataFragment,
} from '../../../generated/graphql';
import TableHeader from '../TableHeader';
import ActivityRow from './ActivityRow';

const columns: ColumnHeaderType[] = [
  {
    field: 'name',
    headerName: 'Name',
    isSortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    isSortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    isSortable: false,
  },
  {
    field: 'sent',
    headerName: 'Sent',
    isSortable: false,
  },
  {
    field: 'completed',
    headerName: 'Client completed',
    isSortable: false,
  },
];

export type ProgramActivities = ProgramActivitiesQuery['programActivities'];

const ActivityTable: FC<{
  programActivities: ProgramActivities | undefined;
  programActivitiesLoading: boolean;
  onClickProgramActivity: (
    programActivity: ProgramActivityDataFragment,
  ) => void;
  patientResourcesMode?: boolean;
}> = ({
  programActivities,
  programActivitiesLoading,
  onClickProgramActivity,
  patientResourcesMode = false,
}) => {
  const showProgramActivities =
    !programActivitiesLoading &&
    programActivities &&
    programActivities?.length > 0;

  return (
    <div className="max-h-[calc(100vh-220px)] overflow-y-scroll pb-4">
      <table
        className={classNames(
          'min-w-full transition-all duration-150',
          !showProgramActivities && 'opacity-0',
          showProgramActivities && 'opacity-100',
        )}
      >
        <thead className="sticky top-0 z-10 h-[40px] w-full">
          <tr className="bg-white">
            {columns.map((column, i) => {
              const isLastColumn = i === columns.length - 1;

              return (
                <TableHeader
                  key={`activityTableColumnHeader_${column.field}`}
                  className="font-bold text-neutral-125"
                  column={column}
                  isLastColumn={isLastColumn}
                />
              );
            })}
          </tr>
        </thead>
        <tbody>
          {programActivities?.map((programActivity, index) => {
            return (
              <ActivityRow
                key={`activityTableRow_${index}`}
                programActivity={programActivity}
                onClickProgramActivity={onClickProgramActivity}
                patientResourcesMode={patientResourcesMode}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityTable;
